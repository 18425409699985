
const LeadSource = [
    // 'Email', 'Existing Customer', 'Networking', 'Parent Organization', 'Self Generated','Telecalling','Tradeindia'
    {
        value: 'Email',
        label: 'Email',
    },
    {
        value: 'ExistingCustomer',
        label: 'Existing Customer',
    },
    {
        value: 'Networking',
        label: 'Networking',
    },
    {
        value: 'ParentOrganization',
        label: 'Parent Organization',
    },
    {
        value: 'SelfGenerated',
        label: 'Self Generated',
    }, 
    {
        value: 'Telecalling',
        label: 'Telecalling',
    },
    {
        value: 'Tradeindia',
        label: 'Tradeindia',
    }, 
]

const TypeList =[
    {
        value: 'Hot',
        label: 'Hot',
    },
    {
        value: 'Warm',
        label: 'Warm',
    },
    {
        value: 'Cold',
        label: 'Cold',
    }
]




export {LeadSource, TypeList} 