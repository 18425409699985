import React,{useEffect,useState} from 'react';
import { Navbar, DropDown, CustemBtn, CompanyName, ComapanyName} from '../../components/custom';
import {Grid, Box, styled, Card, Avatar, Typography, TextField,} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getAllCompanyName} from "../../Redux/Thunk/createcompanythunk";
import ContactForm from './ContactForm';

const MainContainer = styled('div')({
    width:'100%',
    maxHeight:'100%',
    height:'auto',
})

const Container = styled('div')({
    background:'#F5F4FF',
    borderRadius:'10px',
    padding:'20px' ,
})

const CardBody = styled('div')({
    backgroundColor:'#F0EFFD',
    padding:'20px',
    borderRadius:'10px',
 })

////   Create New Contact Form  //
const CreateContact = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {allCompanyName, update} = useSelector((state)=> state?.company)
    const [companyNames, setCompanyNames]= useState([])
    const [dropdownValues, setDropdownValues]= useState(undefined)
    const [buttonStatus, setButtonStatus] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [editContactDetails, setEditContactDetails] = React.useState({});
    const title ="CreateFromFloatingButton"

    useEffect(()=>{
        dispatch(getAllCompanyName()).then((res)=>{
            setCompanyNames(res?.payload)
        })
    },[])

    const handleCreateNewContact = () => {
       setOpenModal(true)
    //    console.log(dropdownValues)
        // navigate('/dashboard/createcontact',{state: dropdownValues})
    }

    return(
        <>
            <Grid sx={{ borderBottom:'1px solid #e4e4e4',mt:2, ml:{xs:2,sm:2,xl:6,lg:6}, mr:{xs:2,sm:2,xl:6,lg:6}, display:"flex",flexDirection:{lg:'row',sm:'row',xs:'column-reverse'}, justifyContent:{lg:'space-between',sm:'space-between',xs:'start'},alignItems:{lg:'flex-end',md:'flex-end',xs:'center'}}}>
                <Typography variant='h6' sx={{fontSize:{lg:'22px',md:'20px',sm:'18px'}, fontWeight:600}}>Create New Contact</Typography>
            </Grid>

            <CardBody sx={{mt:2, pb:6, ml:{xs:1, sm:1, xl:6, lg:6}, mr:{xs:1, sm:1, xl:6, lg:6} }}>
                <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                    <Grid item xs={12} md={2} sm={2} lg={2}>
                        <Typography sx={{fontWeight: {xs:600, lg:400, md:600}}}>Company</Typography>  
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={4}>
                        <Typography>Name</Typography>  
                    {/* label = 'Mobigic Technologies Private Limited' */}
                        <DropDown sx={{background:'#FFFFFF',mt:1}} 
                            placeholder = 'Select Company'
                            options ={companyNames?.map((name)=>{return {label:`${name?.company_name} ${name?.company_type}`, value:`${name?.company_name} ${name?.company_type}`, id: name?.id}})}
                            onChange = {(e, value, reason) => {
                                // console.log(value)
                                if(value===null){
                                    setDropdownValues('')
                                    setButtonStatus(false)
                                }else{
                                    setButtonStatus(true)
                                    setDropdownValues(value)
                                }  
                            }}
                            getOptionLabel={(option) =>(option.label ? option.label :"")  }
                            // isOptionEqualToValue={(option, value) => option?.label === value?.label}
                            value = {dropdownValues}
                    /></Grid>
                </Grid>
            </CardBody>

            <Box sx={{mt:4,mr:{xs:1,sm:1,xl:6,lg:6},mb:2,display:'flex',justifyContent:'end' }}>
                <CustemBtn onClick={handleCreateNewContact} disabled={!buttonStatus} variant='contained' sx={{borderRadius:"10px",pl:5,pr:5,fontSize:'16px'}} text="Proceed"/>
            </Box>

            {openModal && (<ContactForm
                open = {openModal}
                setOpenModal= {setOpenModal}
                title={title}
                dropdownValues= {dropdownValues}
                editContactDetails={editContactDetails}
                setEditContactDetails={setEditContactDetails}
            />)}
        </>

       
    )
}

export default CreateContact
