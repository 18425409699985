
import React, { useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import OpportunityCard from '../../components/custom/OpportunityCard'
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { AddPlusIcon, AssignIcon, DisableAssignIcon, EditIcon, Navbar, TaskIcon } from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { displayLeadList, leadAssignedToUser, leadValidate, leaddetails } from '../../Redux/Thunk/createleadthunk';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import EditLeadpopup from '../EditLeadpopup/EditLeadpopup';
import PopperFilter from '../../components/custom/PopperFilter';
import FilterCloseIcon from '../../components/custom/FilterCloseIcon';
import AssignModal from '../../components/custom/AssignModal';
import swal from 'sweetalert';
import { FetchFollowupList } from '../../Redux/Thunk/createsettinglist';
import { headCells } from './headcells';
import FollowUpDetails from './FollowUpDetails';
import { opportunityAssignedForUser } from '../../Redux/Thunk/createopportunitythunk';
import TableListTitle from '../../components/custom/TableListTitle';
import TableListSearch from '../../components/custom/TableListSearch';
import { TailSpin} from 'react-loader-spinner';
import { followupAssigned } from '../../Redux/Thunk/createTaskthunk';
import moment from 'moment';
import CallIcon from '../../components/custom/CallIcon';
import MeetingIcon from '../../components/custom/MeetingIcon';
import EmailIcon from '../../components/custom/EmailIcon';
import DemoIcon from '../../components/custom/DemoIcon';
import FollowupTaskIcon from '../../components/custom/FollowupTaskIcon';

const Item = styled('div')({
  display:'flex',
  justifyContent:'center'
})

const CardBody = styled('div')({
   backgroundColor:'#F0EFFD',
   padding:'10px',
   borderRadius:'10px',
})

const Search = styled('div')({
  display:'flex',
  padding:'0px 10px',
  borderRadius:'10px',
  justifyItems:'center',
   border:'1px solid #B0AEFF ',
  flexDirection:'row',
  backgroundColor:'#ffffff',
})

export default function FollowUpList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {user} = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user?.role
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [open, setOpen] = useState(false);
  // const { fetchFollowupList } = useSelector ((state) => state.lead)
  const [fetchFollowupList, setFetchFollowupList]= useState([]);
  const [update, setUpdate] = useState(false);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenopper] = React.useState(false);
  const [openFollowupPopup, setOpenFollowupPopup]= useState(false)
  const [editFollowupDetails, setEditFollowupDetails] = useState({});
  const [placement, setPlacement] = React.useState();
  const [user_ids, setUser_ids] = useState([])
  const [companyName, setCompanyName] = useState('')
  const [openAssignModal,setOpenAssignModal] = useState(false)
  const [filterfollowup, setFilterfollowup] = useState('')
  const [followupassigned, setFollowupAssigned]= useState({})
  const [followupClosed, setFollowupClosed] = useState(false)
  const [loader, setLoader] = useState(false)
  const Title= "Follow-Up List"
  const followupName = 'Follow-up'
  const tooltipTitle = "New Follow Up"
  const placeholderTitle = "Search follow-up"

  React.useEffect(()=> {
    setLoader(true)
    dispatch(FetchFollowupList({start: page*rowsPerPage, length:rowsPerPage, search:search, filterfollowup:filterfollowup, col_sort:orderBy.split(' ').join(''), order:order.toUpperCase()})).then((res)=>{
      setFetchFollowupList(res?.payload)
      setUser_ids(res.payload.all_user_id)
      setLoader(false)
    })
  },[update])

  const handleCreateFollowup=()=>{
     navigate('/dashboard/createfollowup', {state: {title:Title, operation:'createFollowup'}})
  }

  const handleClickOpenLeadDetailsPage =(followup)=>{
    if(permissionsDetails?.permissions?.perm_lead_view){
      dispatch(leaddetails(followup?.responseFollowup?.id)).then((res)=>{
        if(res.payload) {
        navigate('/dashboard/leaddetails', {state: {leadDetails: followup?.responseFollowup, title:"FollowupLeadDetails", loader:true}})
        }
      })
    }else{
      sweetalert('error', "You have no permissions to access this. Please contact your administrator for more information.")
    }
  }

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setUpdate(!update)
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUpdate(!update)
  };

  const handleAssign = (row) => {
    setFollowupAssigned(row)
    setOpenAssignModal(true)
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
  
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const findIds= (id1, id2) => {
    if (user_ids.includes(id1) || user_ids.includes(id2)) {
      return true 
    } else {
      return false
    }
  }

  
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    
    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => ( 
            <TableCell
              sx ={{fontWeight:600, fontSize:'13px'}}
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'normal' : 'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>      
          ))}
          
            <TableCell align='left' sx = {{zIndex:0, padding:'0px', fontWeight:600, fontSize:'13px'}}>Schedule Date & Time</TableCell>
            <TableCell align='left' sx = {{zIndex:0, padding:'0px', fontWeight:600, fontSize:'13px'}}>Status</TableCell>
          {/* {permissionsDetails?.permissions?.perm_assign_edit ? <TableCell align='left' sx ={{ fontWeight:600, fontSize:'13px' }}>Assign</TableCell> :<></>} */}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setUpdate(!update)
  };
  
  const handleSearch = (e) => {
    setSearch(e.target.value)
    setUpdate(!update)
  }

  const handleFilter = (e)=> {
    setPage(0)
    if (e.target.value == "All followup") {
      setFilterfollowup('')
      setOpenopper(false)
      setUpdate(!update)
    } else {
      setFilterfollowup(e.target.value)
      setOpenopper(false)
      setUpdate(!update)
    }  
  }
  
  const handleCloseFilter = () => {
    setFilterfollowup('')
    setUpdate(!update)
  }
   
  const openFollowupPage =(followup) =>{
      if(followup?.closed_on === 0){  
        if(permissionsDetails?.permissions?.perm_followup_edit){
          setEditFollowupDetails(followup)
          setOpenFollowupPopup(true)
          setFollowupClosed(false)
        }else{
          sweetalert('error', "You have no permissions to access this. Please contact your administrator for more information.")
        }
      }else{
        setEditFollowupDetails(followup)
        setFollowupClosed(true)
        setOpenFollowupPopup(true)
        // sweetalert("error", "This followup is closed. Create new followup")
      }
  }

  const openCompanyProfilePage= (company)=>{
    navigate('/dashboard/profile',{state: company?.responseFollowup?.company?.id})
  }

  const saveAssignedManagerOrExecutiveName = (managerList, selectManagerName)=>{
    if(selectManagerName ===''){
      sweetalert ('error', "Please Select Name") 
    }else{
      let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectManagerName)
          dispatch(followupAssigned({followupid:followupassigned?.id, user_id:findUser?.id})).then((res)=>{
            if(res?.payload?.errormsg){
              sweetalert ('error', res?.payload?.errormsg) 
              setOpenAssignModal(false)
            }else{
              sweetalert ('success', "Follow-up Assigned Successfully.") 
              setOpenAssignModal(false)
              setUpdate(!update)
            }
      })
    }
  }

  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchFollowupList?.data?.length) : 0;
  
  
  return (
    <>
    <PopperFilter filtervalues ={[{name:'All followup'}, {name:'Open'},{name:'Close'}]} open={openPopper} anchorEl={anchorEl} placement={placement} onClick={handleFilter} />
    
   {/* Display Followup Lists   */}
    <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
      <TableListTitle title={Title} />

      <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
      <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={permissionsDetails?.permissions?.perm_followup_create} onClick ={handleCreateFollowup} onchange={handleSearch} />     
        <Tooltip title="Filter By Status" placement="top-start">
          <IconButton onClick={handleClick("bottom-start")} sx={{mr: {lg:4, xs:1}}}>
            {!openPopper ? <FilterIcon /> : <FilterCloseIcon />}
          </IconButton>
        </Tooltip>
       </Grid>
    </Grid>
    {
      filterfollowup ? <Box sx={{ml:4, display:'flex', justifyContent:'space-between', fontSize:'10px',width:'24%',p:0.5,mt:0.5, border:'1px solid black', borderRadius:'10px'}}>
        <Typography sx={{fontSize:'12px'}}>
       Showing Follow-up for status  <span style={{color:'blue', marginLeft:'10px'}}>{filterfollowup}</span> 
      </Typography>
      <Typography onClick={handleCloseFilter} sx={{color:"red", fontSize:'12px', cursor:'pointer', fontWeight:600, mr:0.5}}>
         Clear Filter X
      </Typography>
      </Box>:""
    }
    <CardBody sx={{mt:1, ml:{xs:1, sm:1, md:1, xl:4, lg:4}, mr:{xs:1, sm:1, md:1, xl:4, lg:4}}}> 
      <Paper sx={{padding:'10px'}}>
        <TableContainer sx={{ maxHeight: 440}}>
          <Table
            sx={{ minWidth: 750 }}
            size= 'small'
            stickyHeader aria-label="sticky table">

            <EnhancedTableHead
              numSelected={fetchFollowupList?.data?.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={fetchFollowupList?.data?.length}
            />
            { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
              <TailSpin
                height="30"
                width="30"
                color="blue"
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              /> </Box>:<>
                <TableBody>
                {fetchFollowupList?.data?.length === 0? <TableRow>
                      <TableCell colSpan={10} style={{textAlign: 'center', color:'red', fontWeight:600}}>
                        No follow-ups available
                      </TableCell>
                    </TableRow>:(  
                    fetchFollowupList?.data?.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                    
                      return (
                        <TableRow
                          hover
                        // onClick={(event) => handleClick(event, row.name)}
                          tabIndex={-1}
                          key={row?.followup_no} 
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            align='left'
                            sx={{padding:'3px'}}
                          >
                            <Typography sx={{color:'#4F709C',fontWeight: 600, fontSize:'14px', "&:hover": {cursor:'pointer'}}} onClick={findIds(row.created_by.id, row.assigned_to.id) ? ()=> openFollowupPage(row) :''} >{row.followup_no}</Typography>
                          </TableCell>
                          <TableCell align="left" sx={{padding:'3px', fontWeight: 600, fontSize:'14px', color:'#C72424', "&:hover": {cursor:'pointer'}}} onClick={()=> handleClickOpenLeadDetailsPage(row)}>{row?.responseFollowup?.lead_no}</TableCell>
                          <TableCell align="left" sx={{padding:'3px',fontWeight: 600, fontSize: '12px',color:'#3107AA', maxWidth:150, "&:hover": {cursor:'pointer'}}} onClick={()=> openCompanyProfilePage(row)}>{row?.responseFollowup?.company?.company_type ==="NGO" ||row?.responseFollowup?.company?.company_type==="OTHERS"|| row?.responseFollowup?.company?.company_type==="TRUST" || row?.responseFollowup?.company?.company_type==="PROPRIETOR" || row?.responseFollowup?.company?.company_type==="FOREIGN ENTITY" ? `${row?.responseFollowup?.company?.company_name} (${row?.responseFollowup?.company?.company_type})`: `${row?.responseFollowup?.company?.company_name} ${row?.responseFollowup?.company?.company_type}`}</TableCell>
                          <TableCell align="left" sx={{padding:'3px',fontSize: '12px'}}>{row?.responseFollowup?.contact?.first_name} {row?.responseFollowup?.contact?.last_name}</TableCell>
                          <TableCell align="left" sx={{padding:'3px', fontSize: '12px'}}>{row?.created_by?.firstname} {row?.created_by?.lastname} </TableCell>
                          <TableCell align="left" sx={{padding: '3px', fontSize: '12px', maxWidth:120, "&:hover": row?.closed_on != 0 ? {cursor:'default'}:{cursor:'pointer'}}} onClick={()=> row?.closed_on != 0 ? "" :handleAssign(row)}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '4px', whiteSpace: 'pre-line', flex: 1}}>{`${row?.assigned_to?.firstname} ${row?.assigned_to?.lastname}`}</span>
                              {/* <span style={{ marginRight: '4px',fontSize: '12px'}}>{row?.assigned_to?.lastname}</span> */}
                              <span style={{marginRight:'4px'}}>
                                {permissionsDetails?.permissions?.perm_assign_edit ? <>
                                  <Tooltip title="Assign Follow-up to" placement="top-start">
                                  <IconButton>
                                  {row?.closed_on != 0 ? <DisableAssignIcon/>: findIds(row.created_by.id, row.assigned_to.id) ? <AssignIcon /> :  <DisableAssignIcon/> } 
                                </IconButton> 
                                </Tooltip></>: " "}
                              </span>
                            </div>
                          </TableCell> 
                          <TableCell align="left" sx={{padding:'3px'}}>
                            <Tooltip title={row?.followup_type ==="Call" ?
                              "Call": row?.followup_type ==="Meeting"? "Meeting":row?.followup_type ==="Email"? "Email":row?.followup_type  === "Demo" ? "Demo": "Task" } placement="top-start">
                              <IconButton>
                                {row?.followup_type ==="Call" ?
                                <CallIcon/> : row?.followup_type ==="Meeting"? <MeetingIcon/>:row?.followup_type ==="Email"? <EmailIcon/>:row?.followup_type  === "Demo" ? <DemoIcon/>:<FollowupTaskIcon/> }
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left" sx={{padding:'3px',fontSize: '12px'}}>{moment(row?.followup_date).format('D/M/YYYY hh:mm A')}</TableCell>
                          <TableCell align="left" sx={{padding:'3px',fontSize: '12px', color: row?.closed_on === 0 ?'#3C8AFF' : '#049E01'}}>{row?.closed_on === 0 ? "Open" :"Closed"}</TableCell> 
                          {/* {permissionsDetails?.permissions?.perm_assign_edit ?<>
                            <TableCell align="left" sx={{padding:'3px'}}><CustemBtn 
                                    sx={{backgroundColor: '#FFC700' ,color:'white',borderRadius:'20px', color:'#000000',
                                    "&:hover": {
                                      backgroundColor: row?.status == 'Assigned'?'#007500' : '#FFC700' 
                                    }}} variant='contained' disabled={row?.closed_on != 0} onClick={()=> handleAssign(row)} text='Assign'
                                  /> 
                            </TableCell> </> :<></>}             */}
                        </TableRow>
                      );
                    }))}
                </TableBody>
            </>}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={fetchFollowupList?.recordsTotal ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </CardBody>
    
    {/* open Assign modal for followup assign for that user  and open modal for closing the followup  */}
    {openAssignModal && <AssignModal title={followupName} taskNumber={followupassigned?.followup_no} taskAssignedFromUser={followupassigned} setUpdate={setUpdate} update={update} openAssignModal={openAssignModal} setOpenAssignModal= {setOpenAssignModal} saveAssignedManagerOrExecutiveName={saveAssignedManagerOrExecutiveName}/>}
    {openFollowupPopup && <FollowUpDetails title={Title} followupClosed ={followupClosed} setUpdate={setUpdate} update={update} open={openFollowupPopup} setOpenFollowupPopup={setOpenFollowupPopup} editFollowupDetails={editFollowupDetails} setEditFollowupDetails={setEditFollowupDetails}/>}
    </>
  );
}
