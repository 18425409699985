import React,{useState, useEffect} from 'react'
import Header from './Header'
import { Box, Divider, Grid, Typography, styled} from '@mui/material'
import CafseridDetails from './CafseridDetails'
import { Sidebar } from './CAF.Ser.IDcss'
import { IFChild1, IFChild2, IFIconBG, IFParent } from '../NewOppoDetailscss'
import { Identified, QualifiedPending, Rejected } from '../Icons'
import { useSelector } from 'react-redux'
import ExecutiveReq from '../ExecutiveReq'
import ManagerActions from '../ManagerActions'
import OppStageName from '../OppStageName'
import PreviewDoc from '../PreviewDoc'
import { ContentPasteSearchOutlined } from '@mui/icons-material'
import { H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T1ButtonText, T2popupSubText } from '../../../Theme/theme';
import themeColor from '../../../Theme/themeColor'
import moment from 'moment'
import AdminRole from '../../../adminrole/Adminrole';

const ChildCard = styled('div')({
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
})

const CardInfo = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
})

const CafSerIDFlow = ({oppo_stages, For}) => {
  const { user } = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user.role.permissions
  const [hierchy, setHierachy] = useState(false)
  const [user_ids] = useState(oppo_stages?.ids)
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  console.log(oppo_stages.oppo_details, 'dfdf')

  useEffect(() => {
     findIds(oppo_stages?.opp_stage?.assigned_to?.id)
  }, [oppo_stages?.opp_stage?.opportunity_id])

  const findIds= (id1, id2) => {
     if (user_ids?.includes(id1) || user_ids?.includes(id2)) {
        setHierachy(true)
     } else {
        setHierachy(false)
     }
 }

  return (
    <div>
    
    { oppo_stages?.oppo_stages?.map((stage) => {
      console.log(stage,"stageeeeeeeeee")
      switch (stage?.for_stage) {

        case 'COF': {
                  return <>
                      <Box>
                      
                          <IFParent sx={{ flexDirection: `${permissionsDetails.perm_approve_edit ? 'column' : 'column'}`, justifyContent:'end' }}>
                              {/* <IFChild1>
                                <IFIconBG>
                                    {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending/>:<Identified /> }
                                </IFIconBG> */}
                                <OppStageName stage={stage} opp_stage={oppo_stages?.oppo_details}/>  
                            {/* </IFChild1> */}
                            {/* <IFChild1> */}
                            {(stage?.for_stage == 'COF') ? 
                                 <> 
                                 <ChildCard>
                                 <Box sx={{display:'flex', justifyContent:'space-between', mt:2}}>
                                 <Box>
                                 <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                      COF ID 
                                  </H7TablePrimeContent>
                                  <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                      {oppo_stages?.oppo_details?.cofdetails?.cof_id}
                                  </T5Normaltext>
                                 </Box>

                                 <Box>
                                 {oppo_stages?.oppo_details?.commercials?.billed_by !== "Mobigic"? <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                      Third Party COF ID
                                  </H7TablePrimeContent>:''}
                                  {oppo_stages?.oppo_details?.commercials?.billed_by !== "Mobigic"?<T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                    {stage?.third_party_id}
                                  </T5Normaltext> :''}
                                 </Box>

                                   <Box sx={{pr:6}}>
                                   {stage?.status == 'Approved' ? <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                      Service ID
                                  </H7TablePrimeContent> :''}
                                  {stage?.status == 'Approved' ? <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                      {oppo_stages?.oppo_details?.servicedetails?.service_id}
                                  </T5Normaltext>:''}
                                   </Box>
                                  </Box>
                              </ChildCard>

                                 {/* <T5Normaltext sx={{ mt:1, flex: 1, fontWeight:600, color: themeColor.palette.primarytext2 }}>COF ID : {oppo_stages?.oppo_details?.cofdetails?.cof_id}</T5Normaltext> 
                                   {oppo_stages?.oppo_details?.commercials?.billed_by !== "Mobigic" ? <T5Normaltext sx={{ flex: 1, mt:1, fontWeight:600, color: themeColor.palette.primarytext2 }}> Third Party COF ID : {stage?.third_party_id}</T5Normaltext> :''} 
                                   {stage?.status == 'Approved' ? <T5Normaltext sx={{ mt:1, flex: 1, fontWeight:600, color: themeColor.palette.primarytext2 }}>Service ID : {oppo_stages?.oppo_details?.servicedetails?.service_id}</T5Normaltext>:''}  */}
                                  </> :''}  
                            {/* </IFChild1> */}
                          </IFParent>
                          <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>Document</H7TablePrimeContent>
                          <PreviewDoc stage ={stage} />

                          {oppo_stages?.oppo_stages?.length > 0 && stage?.req_remark !== 'undefined' ? <> 
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel }}>
                              {stage?.req_remark ? 'Request Remark' : ''}
                            </H7TablePrimeContent>
                            <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt:0.5, mb:0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                              {stage?.req_remark}
                            </T5Normaltext></>:<></>}

                          <IFChild2>
                            {
                                (checkAdminRole && permissionsDetails?.perm_approve_edit) || permissionsDetails.perm_approve_edit ? <ManagerActions name='CafService' For='COF' hierchy={hierchy} stage={stage} opportunity_id={oppo_stages?.oppo_details?.id} opportunity_stage={oppo_stages?.oppo_details?.opportunity_stage} />
                                  : <ExecutiveReq stage={stage} />
                            }
                          </IFChild2>
                          <Divider sx={{mt: 2, mb: 3}}/>
                      </Box>
                    </>
                }
                    break;
        

        case "Service": {
          return <>
              {
               
                <Box>
            
                <IFParent sx={{ flexDirection: 'column'}}>
                    {/* <IFChild1>
                      <IFIconBG>
                          {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending/>:<Identified /> }
                      </IFIconBG> */}
                      <OppStageName stage={stage} opp_stage={oppo_stages?.oppo_details}/>   
                  {/* </IFChild1> */}
                  {/* <IFChild1> */}
                    {(stage?.for_stage == 'Service') ? 
                          <> 
                          <ChildCard sx={{ pt:1}}>
                           
                                <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                    Service ID
                                </H7TablePrimeContent>
                                <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                    {oppo_stages?.oppo_details?.servicedetails?.service_id}
                                </T5Normaltext>

                                {oppo_stages?.oppo_details?.commercials?.billed_by !== "Mobigic"? <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                    Third Party Service ID
                                </H7TablePrimeContent> :''}
                                {oppo_stages?.oppo_details?.commercials?.billed_by !== "Mobigic"? <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                  {stage?.third_party_id}
                                </T5Normaltext> :''}
                                 
                                 <Box sx={{display:'flex', justifyContent:'space-between', mt:2}}>
                                 <Box>
                                 <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                    Billing Start Date
                                </H7TablePrimeContent>
                                <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                  {moment(stage?.billing_start_date).format('D/M/YYYY')}
                                </T5Normaltext>
                                 </Box>

                                 <Box sx={{pr:6}}>
                                 <H7TablePrimeContent style={{ flex: 1,mt:1, color: themeColor.palette.InputLabel }}>
                                    Billing End Date
                                </H7TablePrimeContent>
                                <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                  {moment(stage?.billing_end_date).format('D/M/YYYY')}
                                </T5Normaltext>
                                </Box>
                                 </Box>
                               
                               
                           
                            </ChildCard>
                          {/* <T5Normaltext sx={{ mt:1, flex: 1, fontWeight:600, color: themeColor.palette.primarytext2 }}>Service ID : {oppo_stages?.oppo_details?.servicedetails?.service_id}</T5Normaltext> 
                            {oppo_stages?.oppo_details?.commercials?.billed_by !== "Mobigic" ? <T5Normaltext sx={{ flex: 1, mt:1, fontWeight:600, color: themeColor.palette.primarytext2 }}> Third Party Service ID : {stage?.third_party_id}</T5Normaltext> :''} 
                            <T5Normaltext sx={{ mt:1, flex: 1, fontWeight:600, color: themeColor.palette.primarytext2 }}>Billing Start Date : {moment(stage?.billing_start_date).format('D/M/YYYY')}</T5Normaltext>
                                 <T5Normaltext sx={{ mt:1, flex: 1, fontWeight:600, color: themeColor.palette.primarytext2 }}>Billing End Date : {moment(stage?.billing_end_date).format('D/M/YYYY')}</T5Normaltext> */}
                          {/* <T5Normaltext sx={{ mt:1, flex: 1, fontWeight:600, color: themeColor.palette.primarytext2 }}>Service ID : {oppo_stages?.oppo_details?.servicedetails?.service_id}</T5Normaltext>  */}
                          </> :''}  
                  {/* </IFChild1> */}
                </IFParent>
                <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel, pb:1 }}>Document</H7TablePrimeContent>
                <PreviewDoc stage ={stage} />

                {oppo_stages?.oppo_stages?.length > 0 && stage?.req_remark !== 'undefined' ? <><H7TablePrimeContent sx={{color: themeColor.palette.InputLabel }}>
                      {stage?.req_remark ? 'Request Remark' : ''}
                  </H7TablePrimeContent>
                  <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt:0.5, mb:0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                      {stage?.req_remark}
                  </T5Normaltext></>:<></>}

                  <IFChild2>
                    {
                        (checkAdminRole && permissionsDetails?.perm_approve_edit) || permissionsDetails.perm_approve_edit ? <ManagerActions name='CafService' For='Service' hierchy={hierchy} stage={stage} opportunity_id={oppo_stages?.oppo_details?.id} opportunity_stage={oppo_stages?.oppo_details?.opportunity_stage} />
                          : <ExecutiveReq stage={stage} />
                    }
                  </IFChild2>
                  <Divider sx={{mt: 2, mb: 3}}/>
              </Box>
        }
            </>
        }
            break;
        }
      })
    }
    </div>
  )
}

export default CafSerIDFlow
