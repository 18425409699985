import React,{useState, useEffect} from 'react';
import { Typography, Box, Card, Button, IconButton, Grid, CircularProgress, Tooltip} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import { CompanyName, CustemBtn, EditIcon } from '../../components/custom';
import CreateAddressPopup from '../Addresspopup/CreateAddresspopup';
import { TailSpin} from 'react-loader-spinner';
import { storeCompayName } from '../../Redux/slices/createCompanySlice';
import AdminRole from '../../adminrole/Adminrole';
import ContactForm from '../ContactForm/ContactForm';
import { enableDisableContact } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';
import {H1FormTitle, T6TableText, H3SubHeader, H2POPupMainText, T4MedNormalText, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import moment from 'moment';
import TwitterIcon from '../../components/custom/TwitterIcon';
import LinkedinIcon from '../../components/custom/LinkedinIcon';
import EmailIcon from '../../components/custom/EmailIcon';

 const CardHeaders = styled('div')({
     display:'flex',
     justifyContent:'space-between',
     borderBottom:'0.5px solid #CDCDCD',
 })

 const ChildCard = styled('div')({
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
    boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)'
 })

const CardInfo = styled('div')({
    display:'flex',
    justifyContent:'space-between',
})

export default function NewSeeAllContacts () {
    const dispatch = useDispatch();
    const { state } = useLocation()
    const {user} = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const {updateEditCompany} = useSelector(state => state.address)
    const {updateContact, updateStatusforContact, displayCreateContact} = useSelector(state => state?.contact)
    const [allContactsDetails, setAllContactsDetails]= useState([])
    const [openModal ,setOpenModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [editContactDetails, setEditContactDetails] = React.useState({});
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const [buttonDisabledLoader, setButtonDisabledLoader] = useState(false)
    const title ="CreateFromProfilePage"

    console.log(state,"state")
    useEffect(() => {
        fetchAddressDetails();
        setAllContactsDetails([]);  
    },[updateContact, updateStatusforContact])
    
    const fetchAddressDetails = () =>{
        setLoader(true)
        dispatch(displayCompanyDetails(state?.id)).then((res)=>{
            setAllContactsDetails(res?.payload?.data)
            setLoader(false)
        })
    }
    
    const handleLinkedInClick = (contact) => {
        if (contact?.linkdin?.length > 0 && contact?.enabled === true) {
            window.open(contact?.linkdin, '_blank');
        }
    }
    
    const handleTwitterInClick =(contact) =>{
        if (contact?.twitter?.length > 0 && contact?.enabled === true) {
            window.open(contact?.twitter, '_blank');
        }
    }

    const contactStatusChanges=(contact)=>{
        setButtonDisabledLoader(true)
        swal({
            buttons: {
              Cancel: {
                text: "Cancel",
                value: "Cancel",
                className: "popup-blue",
              },
              Delete: {
                text: "Ok",
                value: "Ok",
                className: "popup-gray",
              },
            },
            title: `Do you want to ${contact?.enabled === false ?"Enable" : "Disable"} ${contact?.first_name} ${contact?.last_name} ?`,
            className: "custom-modal-user",
            closeOnClickOutside: false,
          }).then((value)=>{
            if(value=='Ok'){
                dispatch(enableDisableContact({contactid: contact?.id, set_contact_status: !contact.enabled})).then((res) => {
                    fetchAddressDetails()
                    setButtonDisabledLoader(false)
                })  
            }else{
              console.log("helllo")
              setButtonDisabledLoader(false)
                // fetchCompanyDetails()
            }
          })  
      }

console.log(allContactsDetails,"allContactsDetails")
  return(
    <>
    { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
        <TailSpin
          height="30"
          width="30"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        /> </Box>:<>
         <CompanyName style={{borderRadius:'6px', mb:1, "&:hover": {cursor:'pointer'} }} label={`${allContactsDetails?.company?.company_name} ${allContactsDetails?.company?.company_type}`} companyID={allContactsDetails?.company?.id} />  
            <Box sx={{ padding:'10px'}}>
                <CardHeaders>
                    <H1FormTitle sx={{color: themeColor.palette.Primarytext1, pl:2}}>
                        Contacts
                    </H1FormTitle>
                </CardHeaders>
            
                {allContactsDetails?.contacts?.length === 0 ? <Typography style={{ marginTop:'10px', fontWeight:600, display:'flex', justifyContent:'center',flexDirection:'row', color:'red'}}>No office addresses available</Typography> :<>
                    <Box sx={{ flexGrow: 1, mb:2 }}>
                            <Grid container spacing={{ xs: 2, md: 1, lg:1}} >   
                                {allContactsDetails?.contacts?.map((contact) =>( 
                                    <Grid item xs={2} sm={4} md={4} lg={4}>  
                                        <ChildCard sx={{p:1, mt:2}}>
                                            <Box sx={{display:'flex', p:0, justifyContent:'space-between', borderBottom:'0.5px solid #CDCDCD', alignItems:'end'}}>
                                                <H3SubHeader sx={{color: themeColor.palette.TableButton, flex:15, fontWeight:600}}>
                                                    {contact?.first_name} {contact?.last_name}
                                                </H3SubHeader>
                                                {(checkAdminRole && permissionsDetails?.perm_contact_edit) || permissionsDetails?.perm_contact_edit ?<>
                                                <Typography sx={{color:'#3C8AFF',fontSize:'18px',flex:1}} >
                                                <IconButton onClick={() => {setOpenModal(true)
                                                    dispatch(storeCompayName())
                                                    setEditContactDetails(contact)}}>
                                                    <EditIcon w={20} h={20} />
                                                </IconButton>
                                                </Typography> </>:<></>}   
                                            </Box>
                                        
                                            <CardInfo sx={{ mt:1, pt:1, pl: 1, pr: 2, pb: 0.2}}>
                                                <T4MedNormalText style={{color: themeColor.palette.InputLabel,flex:2}}>
                                                   Designation
                                                </T4MedNormalText>
                                            </CardInfo>

                                            <CardInfo sx={{ pl: 1, pr: 2, pb: 2,}}>
                                                <T4MedNormalText sx={{color: themeColor.palette.primarytext2 }}>{contact?.designation}</T4MedNormalText>                           
                                            </CardInfo>

                                            <CardInfo sx={{ pt:1, pl: 1, pr: 2, pb: 0.2}}>
                                                <T4MedNormalText style={{color: themeColor.palette.InputLabel, flex:2.5, fontWeight: 500}}>
                                                    Email
                                                </T4MedNormalText>
                                                <T4MedNormalText style={{color: themeColor.palette.InputLabel, flex:2, fontWeight: 500}}>
                                                  Mobile No.
                                                </T4MedNormalText>   
                                            </CardInfo>

                                            <CardInfo sx={{ pl: 1, pr: 2, pb: 2}}>
                                                <Tooltip title={contact?.email} placement="top-start" sx={{cursor:'pointer'}}>
                                                    <T4MedNormalText style={{flex:2.5, color: themeColor.palette.primarytext2,
                                                        maxWidth: 'auto', 
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        // transition: 'max-width 0.3s ease',
                                                        "&:hover": {
                                                             maxWidth: 'auto', 
                                                        }}}>
                                                         {contact?.email}
                                                    </T4MedNormalText>
                                                </Tooltip>
                                                <T4MedNormalText style={{flex:2, marginLeft:'0px', color: themeColor.palette.primarytext2}}>
                                                   {contact?.phone}
                                                </T4MedNormalText>
                                            </CardInfo>

                                            <CardInfo sx={{ pt:1, pl: 1, pr: 2, pb: 0.2}}>
                                                <T4MedNormalText style={{color: themeColor.palette.InputLabel,flex:2.5, fontWeight: 500}}>
                                                 Social Media
                                                </T4MedNormalText>
                                                <T4MedNormalText style={{color: themeColor.palette.InputLabel,flex:2, fontWeight: 500}}>
                                                    Date of Birth 
                                                </T4MedNormalText>  
                                            </CardInfo>

                                            <CardInfo sx={{ pl: 1, pr: 2, pb: 2,}}>
                                                <T4MedNormalText style={{flex:2.5, color: themeColor.palette.primarytext2}}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ marginRight: '4px' }}>
                                                    <Tooltip title={contact?.linkdin?.length === 0 ? 'LinkedIn Link Unavailable':'LinkedIn Profile Link'} placement="top-start">
                                                        <IconButton onClick={()=>handleLinkedInClick(contact)}>
                                                            <LinkedinIcon linkedin={contact?.linkdin?.length} enabled={contact?.enabled}/> 
                                                        </IconButton>
                                                    </Tooltip>
                                                    </span>
                                                    <span style={{ marginRight: '4px' }}>
                                                    <Tooltip title={contact?.twitter?.length === 0 ? 'Twitter Link Unavailable':'Twitter Profile Link'}placement="top-start">
                                                        <IconButton onClick={()=>handleTwitterInClick(contact)}>
                                                            <TwitterIcon twitter={contact?.twitter?.length} enabled={contact?.enabled} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    </span>
                                                </div>
                                                </T4MedNormalText>
                                                <T4MedNormalText style={{flex:2, marginTop:'5px', color: themeColor.palette.primarytext2}}>
                                                    {contact?.dob === 0 ? 'XX/XX/XXXX': moment(contact?.dob).format('D/M/YYYY')}
                                                </T4MedNormalText>     
                                            </CardInfo>

                                            <Grid sx={{display: 'flex', justifyContent:'end'}}>
                                
                                {(checkAdminRole && permissionsDetails?.perm_contact_edit) || permissionsDetails?.perm_contact_edit ?<>
                                    {/* <Button
                                        id="validate"
                                        variant="contained"
                                        disabled={buttonDisabledLoader}
                                        onClick={()=> contactStatusChanges(contact)} sx={{mt:1, mr:1, mb:2, height:'22px', textTransform:'none', width:'80px', borderRadius:"24px", fontSize:'12px', backgroundColor:contact?.enabled === false?'#9D9D9D':'#3107AA', "&:hover": {
                                            textTransform:'none', cursor:'pointer', backgroundColor: contact?.enabled === false ? '#9D9D9D':'#3107AA'}}}
                                        >
                                        {buttonDisabledLoader ? <CircularProgress size={20} color="inherit" /> : contact?.enabled === false ? "Inactive" :"Active"}
                                    </Button> */}
                                    
                                    <CustemBtn variant="contained" onClick={()=> contactStatusChanges(contact)} sx={{mt:1, mr:1, mb:1, height:'25px',width:'auto', borderRadius:"24px", fontSize:'12px', backgroundColor:contact?.enabled === false?'#9D9D9D':'#3107AA', "&:hover": {
                                        cursor:'pointer', backgroundColor:contact?.enabled === false ? '#9D9D9D':'#3107AA'}}} text={contact?.enabled === false ? "Disabled" :"Enable"}
                                    />
                                </>:<></>}
                                </Grid>
                                        </ChildCard>
                                       
                                    </Grid> 
                                ))}
                            </Grid>
                    </Box>   
                </>}    
            </Box>
    </>}

     {/* =================== open modal for contact details form ================= */}
     {openModal && <ContactForm open ={openModal} setOpenModal={setOpenModal}  
            title={title} dropdownValues={allContactsDetails?.company} editContactDetails={editContactDetails} setEditContactDetails= {setEditContactDetails} />}
    
    </>
  )
}
