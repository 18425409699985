import { Button } from '@mui/material'
import React from 'react'

const CustemBtn = ({sx, id, text, disabled, variant, onClick, type,value,name,onChange}) => {
  return (
    <Button onChange={onChange} id={id} type={type} name={name} variant={variant} value={value} disabled={disabled} onClick={onClick} sx={{textTransform: 'none',  "&:hover": {backgroundColor:'#3107AA'}, backgroundColor:'#3107AA', boxShadow:'0px 4px 6px #e1e1e1',...sx}} >{text}</Button>
  )
}

// const CustemBtn = ({style,text ,onClick}) => {
//   return (
//     <Button onClick={onClick} sx={{borderRadius:'30px',width:'35%',textTransform: 'none', padding:'8px 60px'}}  style={style} >{text}</Button>
//   )

export default CustemBtn
