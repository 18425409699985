// import * as React from 'react';
// import dayjs from 'dayjs';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

// export default function DateRangePickerValue({onChange, value}) {  
//       return (
//         <LocalizationProvider dateAdapter={AdapterDayjs}>
         
//               <DateRangePicker
//                 value={value}
//                 onChange={onChange}
//               />
           
//         </LocalizationProvider>
//       );
// }

import * as React from 'react';
import dayjs from 'dayjs';
import {Stack ,TextField,Box} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

export default function DatePicker({minDate, style,disabled, readOnly, sx, onChange, value, name, defaultValue, maxDate}) {
  //const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  return (
    <Box style={{...style}} >
      
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <Stack spacing={1}> */}
        {/* <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={onChange}
          name={name}
          defaultValue={defaultValue}
          maxDate={maxDate} 
          sx={sx}
          renderInput={(params) => <TextField {...params} sx={{
            "& .MuiInputBase-input": {
              height: "7px" ,// Set your height here.
            },
            '& fieldset': {
              borderRadius: '8px',
            },
          }} />}
        /> */} 

      <MobileDatePicker 
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={onChange}
          name={name}
          readOnly={readOnly}
          defaultValue={defaultValue}
          maxDate={maxDate} 
          minDate={minDate}
          disabled={disabled}
          sx={sx}
          renderInput={(params) => <TextField {...params} sx={{
            "& .MuiInputBase-input": {
              height: "8px" ,// Set your height here.
            },
            '& fieldset': {
              borderRadius: '8px',
              border:"1px solid #B0AEFF"
            },
          }} />}
        />
          {/* <TimePicker
          label="Time"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
          <DatePicker
          label="Controlled picker"
          value={value}
          onChange={(newValue) => setValue(newValue)}
        />
        */}
      {/* </Stack> */}
    </LocalizationProvider>
    </Box>
  );
}


