import * as React from 'react';
import { styled, Box, InputBase, Button, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ProgressBar from './ProgressBar';
import EditIcon from './EditIcon';
import CustemBtn from './CustemBtn';
import InputBox from './InputBox';
import moment from 'moment';
import { Link } from 'react-router-dom';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const CardHeaders = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #EFEFEF'
})

const CardBody = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: "10px"
})

const CardData = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex:1
})

export default function OrganizationDetails({getCompanyDetails}) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
console.log(getCompanyDetails?.data?.company?.company_website,"getCompanyDetails?.data?.company?.company_website")
    return (
        <Card sx={{borderRadius: '10px', boxShadow: '0px 4px 20px #e4e4e4' }}>
            <Box sx={{pl:2.5,pr:2.5,pt:1.5,pb:2}}>
                <CardHeaders>
                    <Typography sx={{ color: '#7673F6', fontSize: '20px', fontWeight: 600, letterSpacing:'0.04rem'}}>
                        Company
                    </Typography>
                </CardHeaders>

                <CardBody>
                    <CardData>
                        <Typography sx={{ color: "#444444" }}>
                            Type
                        </Typography>
                        <Typography sx={{ color: "#3107AA", fontSize: "16px", padding: '5px 0px' }}>
                            {getCompanyDetails?.data?.company?.company_type}
                        </Typography>
                    </CardData>
                    <CardData>
                        <Typography sx={{ color: "#444444" }}>
                            Industry Type
                        </Typography>
                        <Typography sx={{ color: "#3107AA", fontSize: "16px", padding: '5px 0px' }}>
                            {getCompanyDetails?.data?.company?.industry_type}
                        </Typography>
                    </CardData>
                </CardBody>
                <CardBody>
                    <CardData>
                        <Typography sx={{ color: "#444444" }}>
                            Website 
                         </Typography>
                         {/* <Typography sx={{ color: "#3107AA", fontSize: "16px", fontWeight: "600", padding: '5px 0px' }}>
                                {getCompanyDetails?.data?.company?.company_website?.length === 0 ? (
                                    " "
                                ) : (
                                    <Link to={getCompanyDetails?.data?.company?.company_website} target="_blank">
                                        Click here
                                    </Link>
                                )}
                                </Typography> */}
                                <Typography sx={{ color: "#7673F6", fontSize: "16px", padding: '5px 0px'}}>
                                    {getCompanyDetails?.data?.company?.company_website?.length === 0 ? " " :
                                        <a href={getCompanyDetails?.data?.company?.company_website} style={{fontWeight:600, color: "#7673F6", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                            Click here
                                        </a>  
                                    }
                                </Typography>
                    </CardData>
                    <CardData>
                        <Typography sx={{ color: "#444444" }}>
                            Date of Incorportion
                        </Typography>
                        <Typography sx={{ color: "#3107AA", fontSize: "16px", padding: '5px 0px' }}>
                            {moment(getCompanyDetails?.data?.company?.company_doi).format('D/M/YYYY')}
                        </Typography>
                    </CardData>
                </CardBody>
            </Box>
        </Card>
    );
}