import { Card, CardContent, styled, Typography } from '@mui/material';
import { Box } from '@mui/material';

const CardBody = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
})


export default function RenewalCard({ renewal_data }) {
    return (
        <>
            <Card sx={{
                width: "210px",
                background: "#FFFFFF",
                borderRadius: "6px",
                boxShadow:"0px 4px 20px #efefef"
            }}>
                <Box sx={{pt:2, pb:2, pl:1.1, pr:1.1}}>
                    <CardBody >
                                <Typography sx={{flex:1.5,color:"#3107AA",fontWeight:600,fontSize:'10px'}}> {`${renewal_data.company?.company_name} ${renewal_data.company?.company_type}`}</Typography>
                                <Typography sx={{flex:1,color:"#ADB000",fontWeight:"600",fontSize:'10px',textAlign:'right'}}>{ new Date(renewal_data?.date_of_renewal).toLocaleDateString()}</Typography>
                    </CardBody>
                    <CardBody sx={{mt:0.5}}>
                        <Typography sx={{flex:1.5,color:"#FE8731",fontWeight:"600",fontSize:'12px'}}>{renewal_data?.opportunity_no}</Typography>
                        <Typography sx={{flex:1,color:"#444444",fontWeight:"600",fontSize:'10px',textAlign:'right'}}>{renewal_data.product}</Typography>
                    </CardBody>
                </Box>
            </Card>
        </>
    )
}