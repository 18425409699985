import axios from 'axios'

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {"Content-Type":"application/json"}
})

export const createAxiosResponseInterceptor = async () => {
   
const interceptor = axiosInstance.interceptors.response.use(
    response => response,

    async (error) => {
// alert(error)
// Reject promise if usual error
 if (error.response.status != 444) {
 return Promise.reject(error);
}
 axios.interceptors.response.eject(interceptor);

try {
    const refreshToken = localStorage.getItem('refreshToken')
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/refreshToken`, {
        refreshToken: refreshToken
    }).then((res) =>{
        // alert(JSON.stringify(res))
        saveToken(res);
        console.log(res,"responsehnbg")
        // setHeaders('Authorization', 'Bearer '+  res.data.data[0].accessToken); 
        // error.response.config.headers["Authorization"] =
        // "Bearer " + res.data.data[0].accessToken;
        // return axios(error.response.config);
        window.location.reload()
    })
    console.log(response)
 } catch(error2) {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
        // localStorage.removeItem('user');
        window.location.href = '/';
        return await Promise.reject(error2);
    } finally {createAxiosResponseInterceptor() } // Re-attach the interceptor by running the method
}
)
}

const saveToken = async (res)=>{
 localStorage.setItem('refreshToken', res.data.data[0].refreshToken)
 localStorage.setItem('accessToken', res.data.data[0].accessToken);
 setHeaders('Authorization', 'Bearer '+ localStorage.getItem('accessToken'));
//  localStorage.setItem('user',JSON.stringify( { ...res.data, isSocialLogin: false }));
}

export const setHeaders = (key, value)=>{

     
    axiosInstance.defaults.headers.common[key]=value
}





// import axios from "axios";

// export const axiosInstance = axios.create({
//     baseURL: process.env.REACT_APP_BASE_URL,
//     headers: {"Content-Type":"application/json"}
// })

// axiosInstance.interceptors.response.use(
//     response => {
//         return response},
//             function (error) {
//                 alert(error)
//             if (error.response.status === 444) {
//                 const refreshToken = localStorage.getItem("refreshToken");
//                   axios.post(`${process.env.REACT_APP_BASE_URL}/refreshToken`, { refreshToken: refreshToken}).then((res) =>{ 
//                        console.log(res,"Console log responce")
//                     // if(res?.data?.status== 200){
//                     //     localStorage.setItem("accessToken",res?.data?.data?.accessToken)
//                     //     localStorage.setItem("refreshToken",res?.data?.data?.refreshToken) 
//                     //     setHeaders('Authorization', 'Bearer '+ res?.data?.data?.accessToken); 
//                     //     //  return window.location.reload(); 
//                     // }else{
//                     //      localStorage.removeItem('refreshToken');
//                     //     localStorage.removeItem('accessToken');
//                     //     localStorage.removeItem('user'); 
//                     //     window.location.href = '/'
//                     //  }
//                     // if (res?.data?.data[0]?.status === true) {
//                     //     localStorage.setItem("accessToken",res?.data?.data[0]?.accessToken)
//                     //     localStorage.setItem("refreshToken",res?.data?.data[0]?.refreshToken)
//                     //    setHeaders('Authorization', 'Bearer '+ res?.data?.data[0]?.accessToken); 
//                     //     window.location.reload();
//                     // }

//             //     if (res.status === 200) {
//             //         var data = res.data
//             //         localStorage.setItem("accessToken",data.data.accessToken)
//             //         localStorage.setItem("refreshToken",data.data.refreshToken)
//             //          window.location.reload(); 
//             //     }else {
//             //         localStorage.removeItem('refreshToken');
//             //         localStorage.removeItem('accessToken');
//             //         localStorage.removeItem('user'); 
//             //         // localStorage.setItem("accessToken","");
//             //         // localStorage.setItem("refreshToken","");
//             //         window.location.href = '/'
//             // }
//         })
//     }
//     return Promise.reject(error)
// })

// export const setHeaders = (key, value)=>{
//     axiosInstance.defaults.headers.common[key]=value
// }

