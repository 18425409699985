import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";

// Create Opportunity
export const createOpportunity = createAsyncThunk('data/createopportunity',
    async(data)=>{
    const res = await axiosInstance.post('/createopportunity',data);
     return res.data
    }
)

// company leads
export const fetchComanyLeads = createAsyncThunk('data/fetchComanyLeads',
    async(data)=>{
    const res = await axiosInstance.get(`/getcompanyleads/${data}`);
     return res.data.data
    }
)

// Display Opportunity list
export const fetchopportunitylist = createAsyncThunk('data/fetchopportunitylist',
    async(data)=>{
        const res = await axiosInstance.post(`/fetchopportunitylist`,data);
        return res.data
    }
)

// Edit Opportunity
export const opportunityedit = createAsyncThunk('data/opportunityedit',
    async(data)=>{
        const res = await axiosInstance.post(`/opportunityedit`,data);
        return res.data
    } 
)

// Assigned Opportunity
export const opportunityAssignedForUser = createAsyncThunk('data/opportunityAssign',
    async(data)=>{
        const res = await axiosInstance.post(`/opportunityAssigned`,data);
        return res.data
    } 
)

export const companyOpportunity = createAsyncThunk('data/companyOpportunity',
    async(data)=>{
        const res = await axiosInstance.post(`/companyOpportunityList`,data);
        return res.data
    } 
)

export const opportunityApprovedForUser = createAsyncThunk('data/opportunityapproved',
    async(data)=>{
        const res = await axiosInstance.post(`/opportunityApproved`, data);
        return res.data
    } 
)

export const createFeasibility = createAsyncThunk('data/createfeasibility',
    async(data)=>{
        const res = await axiosInstance.post(`/createfeasibility`, data);
        return res.data
    } 
)

export const uploadstagedocument = createAsyncThunk('data/uploadstagedocument',
    async(data)=>{
        const res = await axiosInstance.post(`/uploadstagedocument`, data);
        return res.data
    } 
)

export const opportunityRejectForUser = createAsyncThunk('data/opportunityRejectForUser',
    async(data)=>{
        const res = await axiosInstance.post(`/opportunityRejected`,data);
        return res.data
    } 
)

//get oppo details 
export const oppodetails = createAsyncThunk('data/oppodetails',
    async(data)=>{
    const res = await axiosInstance.get(`/oppodetails/${data}`);
     return res.data
    }
)

//download file
export const downloaddocument = createAsyncThunk('data/downloaddocument',
    async(data)=>{
    const res = await axiosInstance.get(`/downloaddocument/${data}`);
     return res.data
    }
)

// createcommercials
export const createcommercials = createAsyncThunk('data/createcommercials',
    async(data)=>{
        const res = await axiosInstance.post(`/createcommercials`,data);
        return res.data
    } 
)

// request sent for approval executive to manager
export const sendrequsetforapproval = createAsyncThunk('data/sendrequsetforapproval',
    async(data)=>{
        const res = await axiosInstance.post(`/sendreqforapproval`, data);
        return res.data
    } 
) 

// Opportunity lost.
export const opportunitylost = createAsyncThunk('data/opportunitylost',
    async(data)=>{
        const res = await axiosInstance.post(`/opportunitylost`, data);
        return res.data
    } 
) 

// Upload COF and service id Document upload
export const uploadCOFServiceID = createAsyncThunk('data/UploadCOFServiceID',
    async(data)=>{
        const res = await axiosInstance.post(`/uploadpdf`, data);
        return res.data
    } 
) 

export const getCofDetails = createAsyncThunk('data/getCofDetails',
    async(data)=>{
    const res = await axiosInstance.get(`/cofdetails/${data}`);
     return res.data
    }
)

// Caf and service Id approval for manager
export const cafServiceApproval = createAsyncThunk('data/cafServiceApproval',
    async(data)=>{
        const res = await axiosInstance.post(`/cafserviceapproval`, data);
        return res.data
    } 
)

// Caf and serviceId rejected
export const cafServiceReject = createAsyncThunk('data/cafServiceReject',
    async(data)=>{
        const res = await axiosInstance.post(`/cafservicereject`, data);
        return res.data
    } 
)

export const getCafList = createAsyncThunk('data/getCafList',
    async(data)=>{
        const res = await axiosInstance.post(`/fetchcaflist`, data);
        return res.data
    } 
)

export const getServiceList = createAsyncThunk('data/fetchservicelist',
    async(data)=>{
        const res = await axiosInstance.post(`/fetchservicelist`, data);
        return res.data
    } 
)

export const getServiceDetails = createAsyncThunk('data/getServiceDetails',
    async(data)=>{
    const res = await axiosInstance.get(`/serviceiddetails/${data}`);
     return res.data
    }
)

//  Check third party id for service and cof
export const validateThirdPartyId = createAsyncThunk('data/validateThirdPartyId',
    async(data)=>{
    const res = await axiosInstance.post(`/checkthirdpartyid`, data);
     return res.data
    }
)

//get oppo details 
// export const oppodetails = createAsyncThunk('data/oppodetails',
//     async(data)=>{
//     const res = await axiosInstance.get(`/oppodetails/${data}`);
//      return res.data.data
//     }
// )