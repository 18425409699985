import React, { useEffect, useState } from 'react'
import { BlackColorNum, BrownColorTypo, PagiLayout, CallIconCss, CallNameCss, CardLayout, CompanyName, CountCard, CountCard2, CountLayOut, DateLayout, DateTypo, ExpiringLeadLayout, FollowupIcon, FollowupName, FollowupType, GreenTypo, GreyTypo, Header, Header2, LeadCard, LeadIconBox, LeadNo, LeadNoText, OppoIconBox, Parent, RedTypo, SkyBlueTypo, Text, TodayLeadLayOut, TodayLeadParent, WishesLayout, FilledDotSelected, CountCard3, PendingCofheader, PendingCofCard } from './DashStyledCss'
import { Box, Grid, Typography, Tooltip, Menu, MenuItem, Button} from '@mui/material'
import { CalenderCard, CalenderIcon, CompanyIcon, FloatingButton, LeadIcon, NotificationIcon, OpportunityIcon, PaginationFooter, TaskIcon, UserIcon } from '../../components/custom'
import ThermometerIcon from '../../components/custom/ThermometerIcon'
import AlarmIcon from '../../components/custom/AlarmIcon'
import CallIcon from '../../components/custom/CallIcon'
import CallIconWhite from '../../components/custom/CallIconWhite'
import DashboardCard from './DashboardCard'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ContactIcon from '../../components/custom/ContactIcon'
import NotificationModal from '../../components/custom/NotificationsModal';
import themeColor from '../../Theme/themeColor'
import { H3SubHeader, T2popupSubText } from '../../Theme/theme'
import BackwardIcon from '../../components/custom/BackwardIcon'
import ForwardIcon from '../../components/custom/ForwardIcon'
import PaginationDot from '../../components/custom/PaginationDot'
import dayjs from 'dayjs'
import { dash_notification, dashboardcount, dashboardthunk, expiryleadthunk, todayleadthunk } from '../../Redux/Thunk/dashboardthunk'
import moment from 'moment'
import FloatingListModal from '../../components/custom/FloatingListModal';
import TableIcon from '../../components/custom/TableIcon'
import { TailSpin } from 'react-loader-spinner'
import AdminRole from '../../adminrole/Adminrole';
import ResetIcon from '../../components/custom/ResetIcon'
let dash = {
    For : "dashboard",
    view : "expiring_lead"
}

const Dashboardnew = () => {
    const { user } = useSelector((state) => state.auth)
    const [todayLeadPage, settodayLeadPage] = useState(1);
    const [expiryLeadPage, setExpiryLeadPage] = useState(1);
    const permissionsDetails = user?.data?.user?.role?.permissions
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [start_date, setStart_Date] = useState(dayjs().startOf('month'));
    const [end_date, setEnd_Date] = useState(dayjs().endOf('day'));
    const [opennotificationmodal, setOpenNotificationModal] = useState(false)
    const dashboard_count = useSelector((state) => state?.dashboard?.dash_count?.data)
    const today_lead = useSelector((state) => state?.dashboard?.today_lead?.data)
    const expiry_lead = useSelector((state) => state?.dashboard?.expiry_lead?.data)
    const [openCalender, setOpenCalender] = useState(false)
    const [loader, setLoader] = useState(false)
    const notification_data = useSelector((state) => state?.dashboard?.notifications?.data)
    const [days, setDays] = useState('')
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth();
    var startDate = new Date(year, month, 1);
    startDate.setHours(0, 0, 0, 0);

    var endDate = new Date(year, month + 1, 0);
    endDate.setHours(23, 59, 59, 999);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const openNotificationModal = () => {
        setOpenNotificationModal(true)
    }

    const handlecloseNotificationModal =() => {
        setOpenNotificationModal(false)
      }

    useEffect(() => {
        dispatch(dashboardcount({ start_date, end_date }))
        dispatch(dash_notification())
    }, [start_date, end_date])

    useEffect(() => {
        setEnd_Date(dayjs().endOf('day'))
    }, [start_date])

    const handleResetDate =() => {
        setStart_Date(dayjs().startOf('month'))
        setEnd_Date(dayjs().endOf('month'))
      }

    useEffect(() => {
        setLoader(true)
        dispatch(todayleadthunk({ start: (todayLeadPage * 4 - 4), length: 4 })).then((res) => {
            setLoader(false)
        }).catch((err) =>{
            setLoader(false)
        })
    }, [todayLeadPage])

    useEffect(() => {
        dispatch(expiryleadthunk({ start: (expiryLeadPage * 4 - 4), length: 4 }))
    }, [expiryLeadPage])

    const handleTodayLeadNext = () => {
        if (todayLeadPage > 3) {
            settodayLeadPage(1)
        } else if(todayLeadPage == 2 && today_lead.totalRecords > 8) {
            settodayLeadPage(3)
        } else if(todayLeadPage == 1 && today_lead.totalRecords > 4) {
            settodayLeadPage(2)
        }
    }

    const handleTodayLeadBack = () => {
        if (todayLeadPage < 1) {
            settodayLeadPage(3)
        }  else if(todayLeadPage == 2) {
            settodayLeadPage(1)
        } else if(todayLeadPage == 3) {
            settodayLeadPage(2)
        }
    }

    const handleExpiryLeadBack = () => {
        if (expiryLeadPage < 1) {
            setExpiryLeadPage(3)
        }
         else if(expiryLeadPage == 2) {
            setExpiryLeadPage(1)
        } else if(expiryLeadPage == 3) {
            setExpiryLeadPage(2)
        }
    }

    const handleExpiryLeadNext = () => {
        if (expiryLeadPage > 3) {
            setExpiryLeadPage(1)
        }  else if(expiryLeadPage == 2 && expiry_lead.totalRecords > 8) {
            setExpiryLeadPage(3)
        } else if(expiryLeadPage == 1 && expiry_lead.totalRecords > 4) {
            setExpiryLeadPage(2)
        }
    }
    const openCalenderCard = () => {
        setOpenCalender(true)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openForMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseForMenu = () => {
        console.log("HEllo")
        setAnchorEl(null);
    };

    const noti_data_unread_count = notification_data?.filter((noti) => noti.isread == false)

    return (
        <> 
        {
            loader ? <Box sx={{height:'300px', alignItems:'center', display:'flex', mt:0.5, justifyContent:"center", borderRadius:'10px'}}>
            <TailSpin
              height="30"
              width="30"
              color="blue"
              ariaLabel="tail-spin-loading"
              radius="3"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            /> </Box> :   <Parent>
            <Grid container spacing={{ xs: 2, md: 3, lg: 4, xl: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                <CountLayOut item xs={1.5} sm={1.5} md={1.5}>
                    <CardLayout item xs={1.5} sm={1.5} md={1.5}>
                        <Text>
                            <Tooltip title="Calender" placement="top-start">
                                <Typography sx={{ cursor: 'pointer', marginRight:'5px' }} onClick={openCalenderCard}><CalenderIcon /></Typography>
                            </Tooltip>
                            {moment(Number(new Date(start_date).getTime())).format('DD MMM')} to {moment(Number(new Date(end_date).getTime())).format('DD MMM')}
                        </Text>
                        <Typography ml={1} sx={{ cursor:'pointer'}} onClick={handleResetDate}><ResetIcon/></Typography>
                    </CardLayout>
                    <Link to={permissionsDetails?.perm_lead_view ? '/dashboard/leadlist':''} style={{ textDecoration: 'none' }} >
                        <CountCard>
                            <Header2>
                                Leads
                            </Header2>
                            <LeadIconBox>
                                <LeadIcon height={40} width={40} />
                            </LeadIconBox>
                            <CountCard2>
                                <BrownColorTypo>
                                    Open
                                </BrownColorTypo>
                                <BlackColorNum>
                                    {permissionsDetails?.perm_lead_view ? dashboard_count?.opened_lead : 0}
                                </BlackColorNum>
                            </CountCard2>
                            <CountCard2>
                                <SkyBlueTypo>
                                    Open Follow-up
                                </SkyBlueTypo>
                                <BlackColorNum>
                                    {permissionsDetails?.perm_lead_view ? dashboard_count?.followup_count :0}
                                </BlackColorNum>
                            </CountCard2>
                            <CountCard2>
                                <GreenTypo>
                                    Converted
                                </GreenTypo>
                                <BlackColorNum>
                                    {permissionsDetails?.perm_lead_view ? dashboard_count?.converted_lead :0}
                                </BlackColorNum>
                            </CountCard2>
                            <CountCard2>
                                <RedTypo>
                                    Lost
                                </RedTypo>
                                <BlackColorNum>
                                    {permissionsDetails?.perm_lead_view ? dashboard_count?.lost_lead : 0}
                                </BlackColorNum>
                            </CountCard2>
                            <CountCard2>
                                <GreyTypo>
                                    Closed
                                </GreyTypo>
                                <BlackColorNum>
                                    {permissionsDetails?.perm_lead_view ? dashboard_count?.closed_lead : 0}
                                </BlackColorNum>
                            </CountCard2>

                        </CountCard>
                    </Link>
                    <Link to={permissionsDetails?.perm_opportunity_view ? '/dashboard/opportunitylist': ''} style={{ textDecoration: 'none' }}>
                    <CountCard>
                        <Header2>
                            Opportunity
                        </Header2>
                        <OppoIconBox>
                            <OpportunityIcon height={40} width={40} />
                        </OppoIconBox>
                        <CountCard2>
                            <SkyBlueTypo>
                                Open
                            </SkyBlueTypo>
                            <BlackColorNum>
                            {permissionsDetails?.perm_opportunity_view ? dashboard_count?.opened_oppo : 0}
                            </BlackColorNum>
                        </CountCard2>
                        <CountCard2>
                            <GreenTypo>
                                won
                            </GreenTypo>
                            <BlackColorNum>
                            {permissionsDetails?.perm_opportunity_view ? dashboard_count?.won_oppo : 0}
                            </BlackColorNum>
                        </CountCard2>
                        <CountCard2>
                            <RedTypo>
                                Lost
                            </RedTypo>
                            <BlackColorNum>
                            {permissionsDetails?.perm_opportunity_view ? dashboard_count?.lost_oppo : 0}
                            </BlackColorNum>
                        </CountCard2>

                    </CountCard>
                    </Link>
                    <Link to={permissionsDetails?.perm_task_view ? '/dashboard/tasklist':''} style={{ textDecoration: 'none' }}>
                    <CountCard>
                        <Header2>
                            Tasks
                        </Header2>
                        <OppoIconBox sx={{ backgroundColor: '#9b9e04' }}>
                            <TaskIcon height={40} width={40} />
                        </OppoIconBox>
                        <CountCard2>
                            <SkyBlueTypo>
                                Open
                            </SkyBlueTypo>
                            <BlackColorNum>
                            {permissionsDetails?.perm_task_view ? dashboard_count?.task_opened : 0}
                            </BlackColorNum>
                        </CountCard2>
                        <CountCard2>
                            <GreenTypo>
                                Closed
                            </GreenTypo>
                            <BlackColorNum>
                            {permissionsDetails?.perm_task_view ? dashboard_count?.task_closed : 0}
                            </BlackColorNum>
                        </CountCard2>
                        <CountCard2>
                            <GreyTypo>
                                Expired
                            </GreyTypo>
                            <BlackColorNum>
                                0
                            </BlackColorNum>
                        </CountCard2>

                    </CountCard>
                    </Link>
                    {/* todays leads */}
                </CountLayOut>
                <TodayLeadLayOut item xs={2} sm={4.250} md={4.250}>
                    <CardLayout>
                       
                       <Box sx={{display:'flex'}}>
                       <T2popupSubText>
                            Todays Leads - 
                        </T2popupSubText>
                        <CountCard3>
                        <T2popupSubText>
                            {today_lead?.totalRecords}
                        </T2popupSubText>
                            </CountCard3>
                       </Box>
                        
                        <Header>
                            <PagiLayout>
                                <Typography onClick={handleTodayLeadBack} ><BackwardIcon /></Typography>
                                {todayLeadPage == 1 ? <FilledDotSelected /> : <PaginationDot />}
                                {/* <FilledDotSelected/> */}
                                {today_lead?.totalRecords > 4 ? todayLeadPage == 2 ? <FilledDotSelected /> : <PaginationDot />:''}
                                {today_lead?.totalRecords > 8 ? todayLeadPage == 3 ? <FilledDotSelected /> : <PaginationDot />:''}
                                <Typography onClick={handleTodayLeadNext}><ForwardIcon /></Typography>
                                    {permissionsDetails?.perm_lead_view ? <Link to = 'leadlist' state = {dash = {
                                        For : "dashboard",
                                        view : "todays_lead"
                                    }} >
                                    <TableIcon/>
                                    </Link> :''}
                            </PagiLayout>
                            {/* <PaginationFooter number={4} list={16} currentPage={currentPage} setCurrentPage={setCurrentPage} /> */}
                        </Header>
                    </CardLayout>
                    <Box sx={{ flexGrow: 1 }}>
                           {
                                 today_lead?.today_followups.length > 0 ? <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 2, sm: 8, md: 8, lg: 12 }}>
                                { today_lead?.today_followups?.map((followup) => {
                                    return  <DashboardCard setLoader={setLoader} followup={followup} /> 
                                }) }</Grid> : <Box sx={{height:'300px', background: '#CECECE', alignItems:'center', display:'flex', mt:0.5, justifyContent:"center", borderRadius:'10px'}}>
                                    <Typography sx={{textAlign:'center',mt:2, color:'#1F78FF'}}> No Leads Available </Typography>
                                </Box>
                           }
                    </Box>
                   
                </TodayLeadLayOut>

                {/* expiring leads */}
                <ExpiringLeadLayout item xs={2} sm={4.250} md={4.250}>
                    <CardLayout>
                    <Box sx={{display:'flex'}}>
                       <T2popupSubText>
                            Expiring Leads - 
                        </T2popupSubText>
                        <CountCard3>
                        <T2popupSubText>
                            {expiry_lead?.totalRecords}
                        </T2popupSubText>
                            </CountCard3>
                       </Box>
                        <Header>
                            <PagiLayout>
                                <Typography onClick={handleExpiryLeadBack} ><BackwardIcon /></Typography>
                                {expiryLeadPage == 1 ? <FilledDotSelected /> : <PaginationDot />}
                                {/* <FilledDotSelected/> */}
                                {expiry_lead?.totalRecords > 4 ? expiryLeadPage == 2 ? <FilledDotSelected /> : <PaginationDot />:''}
                                {expiry_lead?.totalRecords > 8 ? expiryLeadPage == 3 ? <FilledDotSelected /> : <PaginationDot />:''}
                                <Typography onClick={handleExpiryLeadNext}><ForwardIcon /></Typography>
                                {permissionsDetails?.perm_lead_view ? <Link to='leadlist' state =  {dash = {
                                        For : "dashboard",
                                        view : "expiring_lead"
                                    }} >
                                    <TableIcon/>
                                    </Link> : '' }
                                {/* <PaginationFooter number={4} list={16} currentPage={currentPage} setCurrentPage={setCurrentPage} /> */}
                            </PagiLayout>
                        </Header>

                    </CardLayout>
                    
                    <Box sx={{ flexGrow: 1 }}>
                    {
                         expiry_lead?.follow_up_near_to_expire?.length > 0 ? <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 2, sm: 8, md: 8, lg: 12 }}>
                            {expiry_lead?.follow_up_near_to_expire?.map((followup) => {
                                    return   <DashboardCard setLoader={setLoader} For='expiry' followup={followup} />
                                       
                                })} </Grid> : <Box sx={{height:'300px',  background: '#CECECE', alignItems:'center', display:'flex', mt:0.5, justifyContent:"center", borderRadius:'10px'}}>
                                <Typography sx={{textAlign:'center', mt:2, color:'#1F78FF'}}> No Leads Available. </Typography>
                            </Box>
                    }
    
                    </Box>
                </ExpiringLeadLayout>

                {/* birthday wish card */}
                <WishesLayout item xs={2} sm={2} md={2}>

                    <Header>
                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap:'10px' }}>
                            {/* User Icon */}
                            {(checkAdminRole && permissionsDetails?.perm_user_view)|| permissionsDetails?.perm_user_view ? <>
                                <Tooltip title="Users" placement="top-start">  
                                    <Typography sx={{ pr: 1 }} onClick={() => navigate('/dashboard/userlist')}><UserIcon /></Typography>
                                    {/* <Button
                                        id="basic-button"
                                        aria-controls={openForMenu ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openForMenu ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <UserIcon />
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={openForMenu}
                                        onClose={handleCloseForMenu}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleCloseForMenu}>User List</MenuItem>
                                        <MenuItem onClick={handleCloseForMenu}>Create Role</MenuItem>
                                    </Menu> */} 
                                </Tooltip>
                                </> : <></>} 

                            {/* Contact Icon  */}
                            {(checkAdminRole && permissionsDetails?.perm_contact_view)|| permissionsDetails?.perm_contact_view ? <>
                                <Tooltip title="Contacts" placement="top-start">
                                    <Typography sx={{ pr: 1 }} onClick={() => navigate('/dashboard/contactlist')}><ContactIcon /></Typography>
                                </Tooltip></> : <></>}

                            {/* Company icon */}
                            {(checkAdminRole && permissionsDetails?.perm_company_view)|| permissionsDetails?.perm_company_view ? <>
                                <Tooltip title="Companies" placement="top-start">
                                    <Typography sx={{ pr: 1 }} onClick={() => navigate('/dashboard/companylist')}><CompanyIcon /></Typography>
                                </Tooltip></> : <></>}

                            {/* Notification */}

                            {
                                    /*user?.data?.user?.role.role == 'god' ? '' :*/ <>
                                    <Tooltip title="Notification" placement="top-start">
                                        <Typography onClick={openNotificationModal} sx={{ pr: 1 }} >
                                            <NotificationIcon count={noti_data_unread_count?.length == 0 ? '' :noti_data_unread_count?.length } w={32} h={32} /> </Typography> 
                                    </Tooltip>
                                </>
                            }
                          

                            {openCalender && <CalenderCard days={days} setDays={setDays} start_date={start_date} end_date={end_date} setStart_Date={setStart_Date} setEnd_Date={setEnd_Date} open={openCalender} setOpenCalender={setOpenCalender} />}
                        </Grid>
                    </Header>
                    <Grid container>
                        
                        <PendingCofCard item lg={12}>
                        <Link to={permissionsDetails?.perm_cof_view ?'/dashboard/coflist' :''} style={{ textDecoration: 'none', color:'inherit' }}>
                        <Header2 sx={{textAlign:'center', marginBottom:'5px'}}>
                            COF Details 
                        </Header2>
                             <Box sx={{display:'flex', justifyContent:'center', padding:'10px', gap:'10px'}} >
                             <T2popupSubText sx={{color:themeColor.palette.BrandColor, fontSize:'14px'}}>
                                Pending  : 
                             </T2popupSubText>
                             <H3SubHeader sx={{ml:0.5}}>
                             {!permissionsDetails?.perm_cof_view ? 0 : dashboard_count?.pending_cof < 10 ? `0${dashboard_count?.pending_cof}` :dashboard_count?.pending_cof }
                            </H3SubHeader>    
                             </Box>
                             <Box sx={{display:'flex', justifyContent:'center', gap:'5px'}} >
                             <T2popupSubText sx={{color:'#088F05', fontSize:'14px'}}>
                                Approved  : 
                             </T2popupSubText>
                             <H3SubHeader sx={{ml:0.5}}>
                                 {!permissionsDetails?.perm_cof_view ? 0 : dashboard_count?.approved_cof < 10 ? `0${dashboard_count?.approved_cof}` :dashboard_count?.approved_cof }
                            </H3SubHeader>    
                             </Box>
                            </Link>
                        </PendingCofCard>
                       
                        <PendingCofCard item lg={12}>
                          <Link to={permissionsDetails?.perm_service_view ? '/dashboard/servicelist' :''} style={{ textDecoration: 'none',  color:'inherit' }}>
                            <Header2 sx={{textAlign:'center',  marginBottom:'5px'}}>
                                Service ID Details 
                            </Header2>
                             <Box sx={{display:'flex', justifyContent:'center', padding:'10px', gap:'10px'}} >
                                <T2popupSubText sx={{color:themeColor.palette.BrandColor, fontSize:'14px'}}>
                                    Pending  : 
                                </T2popupSubText>
                                <H3SubHeader sx={{ml:0.5}}>
                                    {!permissionsDetails?.perm_service_view ? 0 : dashboard_count?.pending_service_id < 10 ? `0${dashboard_count?.pending_service_id}` :dashboard_count?.pending_service_id }
                                </H3SubHeader>    
                             </Box>
                             <Box sx={{display:'flex', justifyContent:'center', gap:'5px'}} >
                                <T2popupSubText sx={{color:'#088F05', fontSize:'14px'}}>
                                    Approved  : 
                                </T2popupSubText>
                                <H3SubHeader sx={{ml:0.5}}>
                                    {!permissionsDetails?.perm_service_view ? 0 : dashboard_count?.approved_service_id < 10 ? `0${dashboard_count?.approved_service_id}` :dashboard_count?.approved_service_id }
                                </H3SubHeader>    
                             </Box>
                            </Link>
                        </PendingCofCard>
                     </Grid>
                </WishesLayout>


            </Grid>
            {/* <Grid spacing={2} container>
                <CountLayOut item  sx={{ background: 'red' }} className="custom-grid-item">
                    <Text>
                        20/Nov/2022 to Today
                    </Text>
                </CountLayOut>
                <TodayLeadLayOut item xl={4} className="custom-grid-item">
                    <CardLayout>
                        <Header>
                            Todays Leads
                        </Header>
                        <Header>
                            Pagination
                        </Header>
                    </CardLayout>
                </TodayLeadLayOut>
                <ExpiringLeadLayout item xl={4} className="custom-grid-item">
                    <CardLayout>
                        <Header>
                            Expiring Leads
                        </Header>
                        <Header>
                            Pagination
                        </Header>
                    </CardLayout>
                </ExpiringLeadLayout>
                <WishesLayout item xl={2} >
                    <Header>
                        Birthday Wish
                    </Header>
                </WishesLayout>
            </Grid> */}
                    <FloatingListModal open={open} setOpen={setOpen} handleClose={handleClose} />
                    <NotificationModal notification_data={notification_data} open = {opennotificationmodal} setOpen={setOpenNotificationModal} handleClose={handlecloseNotificationModal} />
                    <FloatingButton onClick={handleClickOpen} />
        </Parent>
        }
       
      
        </>
    )
}

export default Dashboardnew
