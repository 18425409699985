import { createSlice } from '@reduxjs/toolkit'
import { dash_notification, dashboardcount, dashboardthunk, expiryleadthunk, getperformance, getwishlistthunk, main_search, sendcompanywishthunk, sendwishemailthunk, todayleadthunk, upcomingrenewal } from '../Thunk/dashboardthunk'

const initialState = {
    dashboard_data : [],
    wishlist_data : [],
    sendwishmail:'',
    upcoming_renewal:[],
    performance_report:[],
    notifications:[],
    search_result:[],
    dash_count:{},
    today_lead:[],
    expiry_lead:[]
}

export const getDashboardData = createSlice({
          name:'dashboard',
          initialState,
          extraReducers:(builder)=>{
            builder.addCase(dashboardthunk.fulfilled,(state,action)=>{
                 state.dashboard_data = action.payload
            })

            builder.addCase(dashboardcount.fulfilled,(state,action)=>{
                state.dash_count = action.payload
           })

           builder.addCase(todayleadthunk.fulfilled,(state,action)=>{
            state.today_lead = action.payload
           })

           builder.addCase(expiryleadthunk.fulfilled,(state,action)=>{
                state.expiry_lead = action.payload
           })

            builder.addCase(getwishlistthunk.fulfilled,(state,action)=>{
                state.wishlist_data = action.payload
            })

            builder.addCase(sendwishemailthunk.fulfilled,(state,action)=>{
                state.sendwishmail = action.payload
            })
            
            builder.addCase(sendcompanywishthunk.fulfilled,(state,action)=>{
                state.sendwishmail = action.payload
            })

            builder.addCase(upcomingrenewal.fulfilled,(state,action)=>{
                state.upcoming_renewal = action.payload
            })

            builder.addCase(getperformance.fulfilled,(state,action)=>{
                state.performance_report = action.payload
            })

            builder.addCase(dash_notification.fulfilled,(state,action)=>{
                state.notifications = action.payload
            })

            builder.addCase(main_search.fulfilled,(state, action)=>{
                state.search_result = action.payload
            })
            
        }
})

export default getDashboardData.reducer
