import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navbar, DropDown, CustemBtn, CompanyName, InputBox, DatePicker, TextArea, SelectDropDown } from '../../components/custom';
import { Grid, Box, styled, IconButton,Card, Avatar, Typography, TextField, DialogContent, DialogActions, Select, MenuItem, FormControl, ListSubheader } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { Months, BillType,Order, Business, OrderType, OrderNew, BillingFrequency, StatusList, LostReasons } from './OpportunityValues'
import validate from "../../validation/validation";
import { useDispatch, useSelector } from 'react-redux';
import { createOpportunity, oppodetails, opportunityedit } from '../../Redux/Thunk/createopportunitythunk';
import dayjs from 'dayjs';
import { CreateEditFollowup, getOpportunityList, getOrderTypesList, getProductFamilyTypesList, getProductTypesList, getServiceProviderTypesList } from '../../Redux/Thunk/createsettinglist';
import DisplayError from '../../validation/DisplayError';
import { displayContactList } from '../../Redux/Thunk/createcontact';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import { TailSpin} from 'react-loader-spinner';
import DialogHeaderCompanyName from '../../components/custom/DialogHeaderCompanyName';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '../../components/custom/CloseIcon';
import {H1FormTitle, T6TableText, H5FormLabel} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const CardBody = styled('div')({
    // backgroundColor: '#F0EFFD',
    padding: '20px',
    borderRadius: '10px',
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, title, editCompany, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p:2}} {...other}>
        {children}
        {/* {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              display:'flex', justifyContent:'center', 
              right: 5,
              // top: 11,
            }}
          >
           <CloseIcon w={35} h={35}/>
          </IconButton>
        ) : null} */}
      </DialogTitle>
    );
}
  
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



/**OpportunityForm
 * 
 * @description 
 * - This component shows the opportunity details.
 */
const CreateNewOpportunityForm = ({MissingDeadLine, title, editOpportunityDetails, closingRemark, callResponce, setOpenOpportunityModel, openOpportunityModel, closedFollowupDate}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { state } = useLocation()
    let leaddetails = useSelector((state) => state.lead.leaddetail)
    const { getServiceProviderTypes, getproductFamilyList, getOrderTypes, getOpportunityTypesList } = useSelector(state => state.settingList)
    // const service_provider_list = [...getServiceProviderTypes?.value, editOpportunityDetails?.service_provider, leaddetails?.leaddetails?.service_provider]
     //let dropdownValuesForServiceProvider = getServiceProviderTypes?.value?.map((item) => { return { label: item, value: item } })
    let dropdownValuesForOrderType = getOrderTypes?.value?.map((item) => { return { label: item, value: item } })
    let dropdownValuesForOpportunity = getOpportunityTypesList?.value?.map((item) => { return { label: item, value: item } })
     
    const [dropdownValuesForServiceProvider, setdropdownValuesForServiceProvider] = useState(getServiceProviderTypes?.value?.length === 0 || getServiceProviderTypes === undefined || Object.keys(getServiceProviderTypes).length ==0  ? [] : getServiceProviderTypes?.value?.map((item) => { return { label: item, value: item } }))
    
    // if (!(title == "OpportunityDetailsPage" || title == "changerequest")) {
    //     Object.keys(editOpportunityDetails?.length > 0) ? dropdownValuesForServiceProvider?.push({value:leaddetails?.leaddetails?.service_provider, label:leaddetails?.leaddetails?.service_provider}) : dropdownValuesForServiceProvider
    // }
    // let dropdownValuesForProductFamily = getproductFamilyList?.value?.map((item) => { return { label: item, value: item } })
    // const [editOpportunityDetails, setEditOpportunityDetails] = useState(state.operation == 'editOpp' ? state?.opportunity : {})
    const [contactList, setContactList] = React.useState([])
    const [closure_date, setClosure_Date] = React.useState(moment().add(60, 'days'));
    // const [date_of_billing, setDate_of_Billing] = React.useState(state?.operation == 'editOpp' ? state?.opportunity?.date_of_billing=== 0 ?'' : state?.opportunity?.date_of_billing : dayjs(Date.now()));
   // const [do_renewal, setDo_Renewal] = React.useState(state?.operation == 'editOpp' ? state?.opportunity?.date_of_renewal=== 0 ?'' : state?.opportunity?.date_of_renewal : dayjs(Date.now()));
    const [error, setError] = useState([]);
    const [orderType, setOrderType] = useState([]);
    const [productFamily, setProductFamily] = useState([])
    const [product, setProduct] = useState([]);
    const [disableButton, setDisableButton] = useState(false)    
    const [serviceProvider, setServiceProvider] = useState(leaddetails ? state?.leadDetails?.service_provider : '') //state?.operation == 'createOpp' ? state?.leadDetails?.service_provider : '')
    const [productType, setProductType]= useState(leaddetails ? state?.leadDetails?.product : '') //state?.operation == 'createOpp' ? state?.leadDetails?.product : '')
    const [productFamilyType, setProductFamilyType]= useState(leaddetails ? state?.leadDetails?.product_family:'') //state?.operation == 'createOpp' ? state?.leadDetails?.product_family : '')
    const [loader, setLoader] = useState(false) 
    const [productFamilyLoader, setProductFamilyLoader] = useState(true)
    const [productLoader, setProductLoader]= useState(true)
    const [show, setShow] = useState(true)
    const [contactloader, setcontactloader] = useState(false)
    const getCompanyDetails = useSelector((state)=> state.company?.getCompanyDetails)

    // console.log(getServiceProviderTypes,"getServiceProviderTypes")
    // console.log(leaddetails, dropdownValuesForServiceProvider, "dropdownValuesForServiceProvider")

    const [opportunityDetails, setOpportunityDetails] = useState({
        opportunity_name: leaddetails ? leaddetails?.leaddetails?.lead_name : '',
        opportunity_type: '',     
        contact_id: leaddetails ? leaddetails?.leaddetails?.contact?.id :'',     
        // contact_id: (state?.operation == 'createOpp' && state?.title ==="LeadDetailsPage" || state?.title === "Create followup") ? state?.leadDetails?.contact?.id :(state?.operation == 'createOpp') ? state?.leadDetails?.contact :'',
        order_type: '',
        business_line: '',
        // product_family: state?.operation == 'createOpp' ? state?.leadDetails?.product_family : '',
        // product: state?.operation == 'createOpp' ? state?.leadDetails?.product : '',
        // service_provider: state?.operation == 'createOpp' ? state?.leadDetails?.service_provider : '',
        service_opportunityid:'',
        third_party_service_id : '',
        // otc: 0,
        // arc: 0,
        // months:0,
        // bill_frequency: '',
        // bill_type: '',
        // service_id: '',
      
        expected_nrc: '',
        summary: leaddetails ? leaddetails?.leaddetails?.summary : '',
        branch: leaddetails ? leaddetails?.leaddetails?.branch?.id:'',
        expected_mrc: '',
        // lost_reason: '',
        // detailreason: '',
    })
   
    useEffect(() => {
        setLoader(true)
          dispatch(getServiceProviderTypesList())
          dispatch(getOpportunityList())
        if (Object.keys(editOpportunityDetails)?.length > 0) {
            let closure_date =  (editOpportunityDetails?.closure_date < moment().valueOf()) ? moment().add(60, 'days') : editOpportunityDetails?.closure_date
            setOpportunityDetails({
                opportunity_name: editOpportunityDetails ? editOpportunityDetails?.opportunity_name : '',
                opportunity_type: title == 'changerequest' ? '' : editOpportunityDetails ? editOpportunityDetails?.opportunity_type : '',
                contact_id: editOpportunityDetails ? editOpportunityDetails?.contact?.id : '',
                order_type:  editOpportunityDetails ? editOpportunityDetails?.order_type : '',
                business_line: editOpportunityDetails ? editOpportunityDetails?.lineofbusiness : '',
                // product_family: editOpportunityDetails ? editOpportunityDetails?.product_family : '',
                // product: editOpportunityDetails ? editOpportunityDetails?.product : '',
                // service_provider: editOpportunityDetails ? editOpportunityDetails?.service_provider : '',
                service_opportunityid: editOpportunityDetails ? editOpportunityDetails?.service_opportunityid : '',
                third_party_service_id:'',
                expected_mrc:  title == 'changerequest' ? '' : editOpportunityDetails ? editOpportunityDetails?.expected_mrc : '',
                expected_nrc:  title == 'changerequest' ? '':  editOpportunityDetails ? editOpportunityDetails?.expected_nrc : '',
                // otc: editOpportunityDetails ? editOpportunityDetails?.otc : '',
                // arc: editOpportunityDetails ? editOpportunityDetails?.arc : '',
                // months: editOpportunityDetails ? editOpportunityDetails?.months : '',
                // bill_frequency: editOpportunityDetails ? editOpportunityDetails?.bill_frequency : '',
                // bill_type: editOpportunityDetails ? editOpportunityDetails?.bill_type : '',
                // service_id: editOpportunityDetails ? editOpportunityDetails?.service_id : '',
                summary: editOpportunityDetails ? editOpportunityDetails?.summary : '',
                branch:  editOpportunityDetails ? editOpportunityDetails?.branch?.id : '',
                // detailreason: editOpportunityDetails ? editOpportunityDetails?.reasonindetail : '',
                // lost_reason: editOpportunityDetails ? editOpportunityDetails?.lost_reason : '',
            })
            setClosure_Date( editOpportunityDetails ? closure_date :'' )
            setProductFamilyType( editOpportunityDetails ? editOpportunityDetails?.product_family:'')
            setServiceProvider(editOpportunityDetails ? editOpportunityDetails?.service_provider:'')
            setProductType(editOpportunityDetails ? editOpportunityDetails?.product:'')
        }
    }, [])
 
    useEffect(() => {
        // setLoader(true)
        if (((title !== "OpportunityDetailsPage" || title !== "changerequest") && Object.keys(editOpportunityDetails)?.length === 0)) {
             if (getServiceProviderTypes?.value) {
                let obj = {value:leaddetails?.leaddetails?.service_provider, label:leaddetails?.leaddetails?.service_provider}
                let check_service_provider = dropdownValuesForServiceProvider?.find((provider) => provider.value == obj.value)
                if(!check_service_provider) {
                  setdropdownValuesForServiceProvider([...dropdownValuesForServiceProvider, obj])
                }
                // setdropdownValuesForServiceProvider([...dropdownValuesForServiceProvider, obj])
                // Object.keys(editOpportunityDetails?.length > 0) ? dropdownValuesForServiceProvider?.push({value:leaddetails?.leaddetails?.service_provider, label:leaddetails?.leaddetails?.service_provider}) : dropdownValuesForServiceProvider
            }
        } else {
                let obj = {value:editOpportunityDetails?.service_provider, label:editOpportunityDetails?.service_provider}
                let check_service_provider = dropdownValuesForServiceProvider?.find((provider) => provider.value == obj.value)
                if(!check_service_provider) {
                  setdropdownValuesForServiceProvider([...dropdownValuesForServiceProvider, obj])
                }
        }
    }, [])

    useEffect(() => {
        setcontactloader(true)
        if (title == "OpportunityDetailsPage" || title == "changerequest") {
            leaddetails = {}
        }
    
        const getContactList = () => {
            dispatch(displayContactList(Object.keys(leaddetails).length > 0 ? leaddetails?.leaddetails?.company?.id : editOpportunityDetails?.company?.id)).then((data) => {
                setContactList(data?.payload?.filter((status) => status?.enabled == true))
                setcontactloader(false)
            })
        }
        getContactList()
    }, [editOpportunityDetails?.id])

    useEffect(() => {
        //Get Display Opportunity type names and display order types
        if(opportunityDetails?.opportunity_type){
        dispatch(getOrderTypesList({ selected_opportunitytype: opportunityDetails?.opportunity_type })).then((res) => {
            setOrderType(res?.payload?.value)
            // setLoader(false)
        })} 
        
        if(serviceProvider){
            setProductFamilyLoader(true)
            dispatch(getProductFamilyTypesList({selected_serviceprovider:serviceProvider})).then((res)=>{
                if(res?.payload?.value?.length === 0){
                    setProductFamily([])
                    setProductFamilyLoader(false)
                    sweetalert('error', `Add Product family for ${serviceProvider}. Please contact to Admin.`)
                }else{
                    console.log(res.payload, "deatatatatattatatatat")
                    if (title == "OpportunityDetailsPage" || title == "changerequest") {
                        leaddetails = {}  
                    } else {
                        editOpportunityDetails ={}
                    }
                    if(Object.keys(editOpportunityDetails).length > 0 ) {
                        let get_family = res?.payload?.value.find((providerFamily) => providerFamily === editOpportunityDetails?.product_family)
                        if(!get_family) {
                            let prod_family = [...res?.payload?.value, editOpportunityDetails?.product_family] 
                            return setProductFamily(prod_family) 
                        }else{
                            return setProductFamily(res?.payload?.value) 
                        }
                                        // let prod_family = [...res?.payload?.value, editOpportunityDetails?.product_family, leaddetails?.leaddetails?.product_family]
                        // console.log(res?.payload?.value, get_family, editOpportunityDetails?.product_family, leaddetails?.leaddetails?.product_family," edit opppp")
                        // setProductFamily(prod_family)  
                    }
                    // if( Object.keys(leaddetails).length > 0) {
                    //     let prod_family = [...res?.payload?.value, leaddetails?.leaddetails?.product_family]
                    //     console.log(prod_family, editOpportunityDetails?.product_family, leaddetails?.leaddetails?.product_family,"createeeeee")
                    //     setProductFamily(prod_family)   
                    //     return
                    // }
                    setProductFamily(res?.payload?.value)
                    setProductFamilyLoader(false)
                } 
            })
        }
        if(productFamilyType && serviceProvider){
            if(Object.keys(editOpportunityDetails).length > 0 && show ) {
                setProduct([editOpportunityDetails?.product])  
                setLoader(false)
                setProductLoader(false)
                setShow(false)
              return
            }
            if(Object.keys(leaddetails).length > 0 && show ) {
                setProduct([leaddetails?.leaddetails.product])  
                setLoader(false)
                setProductLoader(false)
                setShow(false)
              return
            }
            const concatenated = serviceProvider?.concat(productFamilyType );
            setProductLoader(true) 
            dispatch(getProductTypesList({selected_productfamily: concatenated??''})).then((res)=>{
                if(res?.payload?.value?.length === 0){
                    setProduct([])
                    setProductLoader(false)
                    sweetalert('error', `No product exists for this ${productFamilyType} under ${serviceProvider}. Please contact the admin about adding a product.`)
                    // sweetalert('error', `Add Product for ${productFamilyType}. Please contact to Admin.`)
                }else{
                    setProduct(res?.payload?.value)
                    setLoader(false)
                    setProductLoader(false)
                }   
            })
        }
    }, [productFamilyType, serviceProvider]) //opportunityDetails?.opportunity_type
    
    // console.log(opportunityDetails, productFamily, "opportunityDetails")
    
    let validation_input = [
        {tagid: 'opportunity_name', text:opportunityDetails?.opportunity_name?.trim(), required: true, regex_name: 'free_text', errmsg: 'Please Enter Name.', min:1, max:100},
        title == 'changerequest' ? {tagid: 'opportunity_type', text:opportunityDetails?.opportunity_type, required: true, regex_name: 'text',errmsg: 'Please Select Order Type.'} : '',
        // {tagid: 'order_type', text:opportunityDetails?.order_type, regex_name:'text', required: true, errmsg: 'Please Select Order Type.'}: '',
        opportunityDetails.opportunity_type == 'Shifting' ? {tagid: 'branch', text:opportunityDetails?.branch, regex_name:'free_text', required: true, errmsg: 'Please Select Address.'}:'',
        {tagid: 'contact_id', text:opportunityDetails?.contact_id, regex_name:'free_text', required: true, errmsg: 'Please Select Contact Name.'},
        {tagid: 'business_line', text:opportunityDetails?.business_line, regex_name:'text', required: true, errmsg: 'Please Select Line of Busieness.'},
        {tagid:'product_family', text:productFamilyType, required: true, regex_name: 'free_text', errmsg: 'Please Select Product Family'},
        { tagid: 'product', text: productType, required: true, regex_name: 'free_text', errmsg: 'Please Select Product.' },
        {tagid: 'service_provider', text: serviceProvider, required: true, regex_name: 'free_text', errmsg: 'Please Select Service Provider'},
        (opportunityDetails.expected_mrc == '' && opportunityDetails.expected_mrc !== 0  ) ?  {tagid: 'expected_mrc', text: opportunityDetails.expected_mrc, required: true, regex_name: 'text',allow_numbers: true, errmsg: 'Please Enter value'}: '',
        (opportunityDetails.expected_nrc == '' && opportunityDetails.expected_nrc !== 0 ) ?  {tagid: 'expected_nrc', text: opportunityDetails.expected_nrc, required: true, regex_name: 'text', allow_numbers: true, errmsg: 'Please Enter value'} : '',
        //  ( opportunityDetails.expected_mrc  ==  0 && opportunityDetails.expected_mrc != '' ) ? {tagid: 'expected_nrc', text: opportunityDetails.expected_nrc, required: true, regex_name: 'min_number',min:1,   errmsg: 'Expected NRC should not be 0.'} : '',  
        //  ( opportunityDetails.expected_nrc  ==  0 && opportunityDetails.expected_nrc != '' ) ? {tagid: 'expected_mrc', text: opportunityDetails.expected_mrc, required: true, regex_name: 'min_number',min:1,   errmsg: 'Expected MRC should not be 0.'} : '',
        // ((opportunityDetails.expected_nrc +  opportunityDetails.expected_mrc) > 0 ) ? '': {tagid: 'expected_mrc', text: opportunityDetails.expected_mrc, required: true, regex_name: 'min_number',min:1,   errmsg: 'Expected MRC should be 0.'},
        // opportunityDetails.expected_mrc > 0 ? {tagid: 'expected_nrc', text: opportunityDetails.expected_nrc, required: true, regex_name: 'max_number',max:0, errmsg: 'Expected NRC should be 0.'} :  {tagid: 'expected_nrc', text: opportunityDetails.expected_nrc, required: true, regex_name: 'min_number',min:1,  errmsg: 'Please Enter Expected NRC value. if no NRC value should be greater than 0.'},
        // (opportunityDetails.expected_nrc > 0 ) ?{tagid: 'expected_mrc', text: opportunityDetails.expected_mrc, required: true, regex_name: 'max_number',max:0,   errmsg: 'Expected MRC should be 0.'} : {tagid: 'expected_mrc', text: opportunityDetails.expected_mrc, required: true, regex_name: 'min_number',min:1,   errmsg: 'Please Enter Expected MRC value. if no MRC value should be greater than 0.'},
        // opportunityDetails.expected_mrc > 0 ? {tagid: 'expected_nrc', text: opportunityDetails.expected_nrc, required: true, regex_name: 'max_number',max:0, errmsg: 'Expected NRC should be 0.'} :  {tagid: 'expected_nrc', text: opportunityDetails.expected_nrc, required: true, regex_name: 'min_number',min:1,  errmsg: 'Please Enter Expected NRC value. if no NRC value should be greater than 0.'},
         {tagid: 'service_opportunityid', text:opportunityDetails?.service_opportunityid.trim(), regex_name: 'free_text', errmsg: 'Please Enter Service Provider Opportunity ID.', min:1, max:50},
        // (title == 'changerequest' && serviceProvider != 'mobigic') ? {tagid: 'third_party_service_id', text:opportunityDetails?.third_party_service_id.trim(), required: true, regex_name: 'text', allow_numbers:true, other_chars:true, errmsg: 'Please Enter third_party_service_id'} : '',
        // {tagid: 'otc', text:opportunityDetails.otc, regex_name: 'min_number',min:1,  errmsg: 'Please Enter One Time Charges.'},
        // {tagid: 'arc', text:opportunityDetails.arc, regex_name: 'min_number',min:1,  errmsg: 'Please Enter Recurring Charges.'},
        //{tagid: 'months', text:opportunityDetails.months, regex_name: 'min_number',min:1, errmsg: 'Please Select Contract(period).', required: true},
        // { tagid: 'bill_frequency', text: opportunityDetails.bill_frequency, regex_name: 'text', required: true, errmsg: 'Please Select Bill Frequency.' },
        // { tagid: 'bill_type', text: opportunityDetails.bill_type, regex_name: 'text', required: true, errmsg: 'Please Select Type.' },
        // {tagid: 'service_id', text:opportunityDetails.service_id, regex_name: 'text', allow_numbers:true, errmsg: 'Please Enter Service ID.'},
        {tagid: 'summary', text: opportunityDetails?.summary, required: true, regex_name: 'free_text', errmsg: 'Please add the summary between 100 to 2000 characters', min:100, max:2000 },
    ]
    
    const handleOfCreateOpportunityDetails = (event) => {
        setOpportunityDetails({ ...opportunityDetails, [event.target.name]: event.target.value })
    }

    const handleOfCreateServiceProvider=(event)=>{
        setServiceProvider(event.target.value)
        setProduct([])
        setProductFamilyType('')
        setProductType('')
    }

    const handleOfCreateProductFamilyType=(event)=>{
        setProductFamilyType(event.target.value)
        setProductType('')
    }

    const handleOfCreateProductType =(event) =>{
        setProductType(event.target.value)
    }

    const changeClosureDate = (newValue) => {
        setClosure_Date(newValue.$d)
    }

    // const changeDateOfBilling = (newValue) => {
    //     setDate_of_Billing(newValue.$d)
    // }

    // const changeDateOfRenewal = (newValue) => {
    //     setDo_Renewal(newValue.$d)
    // }

    const emptyState=()=>{
        setOpportunityDetails({
            opportunity_name: '',
            opportunity_type:'',                    
            contact_id: '',
            order_type: '',
            business_line: '',
            // product_family: '',
            // product: '',
            // service_provider:'',
            service_opportunityid:'',
            summary:'',
            expected_mrc: '',
            expected_nrc: '',
        })
    }

    const handleSaveOpportunityDetails = () => {   
        if (validate(validation_input).length == 0) {
            if (Number(opportunityDetails.expected_mrc + opportunityDetails.expected_nrc) <= 0) {
              setError([])
              return  sweetalert('error', 'Either expected MRC or expected NRC should be greater than zero')
           } else if (opportunityDetails.expected_mrc == '' && opportunityDetails.expected_nrc =='' ) {
            setError([])
            return  sweetalert('error', 'Either expected MRC or expected NRC should be greater than zero')
           }
           
            setDisableButton(true)
            if (Object.keys(editOpportunityDetails).length == 0 || title == 'changerequest' ) {
                setError([])
                
                 opportunityDetails.closure_date = Number(closure_date)
                // opportunityDetails.date_of_billing = date_of_billing
                // opportunityDetails.do_renewal = dayjs(date_of_billing).add(opportunityDetails.months, 'months')
                opportunityDetails.service_provider = serviceProvider
                opportunityDetails.product_family = productFamilyType
                opportunityDetails.product = productType
                opportunityDetails.company_id = leaddetails?.leaddetails?.company?.id
                opportunityDetails.lead = leaddetails?.leaddetails?.id
                if (title == 'changerequest') {
                    opportunityDetails.service_id = editOpportunityDetails.service_id
                    opportunityDetails.company_id = editOpportunityDetails?.company?.id
                  
                } else {
                    opportunityDetails.opportunity_type = 'New'
                }
              
                dispatch(createOpportunity(opportunityDetails)).then((res) => {
                    console.log(res?.payload?.data,"res?.payload?.data")
                    let opportunityID = res?.payload?.data?.opportunity_id
                    if(res?.payload?.errormsg){
                      sweetalert('error', res?.payload?.errormsg)
                      setDisableButton(false)
                    }else if(state?.title?.title ==='companyProfilePageOpportunityDetails'){
                        sweetalert('success', res?.payload?.data?.msg)
                        setDisableButton(false)
                        emptyState()
                        navigate('/dashboard/profile/companyOpportunity', {state :state?.company})
                    }else if(state?.title?.title==="Opportunity List"){
                        sweetalert('success', res?.payload?.data?.msg)
                        setDisableButton(false)
                        emptyState()
                        navigate('/dashboard/opportunitylist')
                    }else if(state?.title ==="LeadDetailsPage"){
                        sweetalert('success', res?.payload?.data?.msg)
                        setDisableButton(false)
                        emptyState()
                        navigate('/dashboard/leaddetails', {state: {leadDetails: state?.leadDetails, title:"Lead List", loader:true}})
                    }else{
                        emptyState()
                        const myJson= {
                            create :{
                                // companyid:checked === true ? (title==="Alert List" )? editFollowupDetails?.company?.id :editFollowupDetails?.company :(title ==="Opportunity List"|| title==='companyProfilePageOpportunityDetails'||title==="OpportunityDetailsPage") ? selectedOpportunityDetails?.company?.id :(title ==="Lead List"|| title ==="companyProfilePageLeadDetails" || title==="LeadDetailsPage" ||title==="DashboardLeadDetails")? selectedLeadDetails?.company?.id : selectedLeadDetails?.company,
                                companyid: '',
                                // followup_for_id:createNewFollowup === true ? editFollowupDetails?.followup_for_id : selectedOpportunityDetails?.id ? selectedOpportunityDetails?.id :selectedLeadDetails?.id ,
                                // followup_for_id: leadDetails?.leaddetails?.id,  //Lead id
                                followup_for_id:'',  
                                operationmode:'create',
                                followup_date: '',
                                followup_type: '',   //type
                                followup_for: 'lead' ,
                            },
                            edit:{
                                followupid: leaddetails?.getrecentfollowp?.id, 
                                operationmode:'edit',
                                summary: closingRemark,
                                missing_deadline_mark: MissingDeadLine, 
                                call_response: callResponce ,
                                lead_action: 'Convert to Opportunity'
                            }
                        }
                        {closingRemark?.length > 0 ? dispatch(CreateEditFollowup(myJson)).then((res)=>{
                            console.log(res.payload)
                        }):<></>}
                        // navigate('/dashboard/opportunitydetails', {state: {oppDetails: opportunityID}})
                        swal({
                            buttons: {
                                Cancel: {
                                    text: "Opportunity Details",
                                    value: "profile",
                                    className: "popup-blue",
                                },
                                Delete: {
                                    text: "Opportunity List",
                                    value: "Cancel",
                                    className: 'popup-gray',
                                },
                            },
                            title: closingRemark?.length === 0 ? `Opportunity Successfully Created for Lead (${state?.leadDetails?.lead_no}).`: title == 'changerequest' ?  res?.payload?.data?.msg : `Followup (${leaddetails?.getrecentfollowp?.followup_no}) Closed And Opportunity Successfully Created for Lead (${state?.leadDetails?.lead_no}).`,
                            className: "custom-modal",
                            closeOnClickOutside: false,
                        }).then((value) => {
                            if (value == 'profile') {
                                navigate('/dashboard/opportunitydetails', {state: {oppDetails: opportunityID}})
                            } else {
                                navigate('/dashboard/opportunitylist')
                                setDisableButton(false)
                            }
                        })
                    }
                })
            } else {
                 opportunityDetails.closure_date = Number(closure_date)
                // opportunityDetails.date_of_billing = date_of_billing;
                // opportunityDetails.do_renewal = dayjs(date_of_billing).add(opportunityDetails.months, 'months');
                 opportunityDetails.company_id = editOpportunityDetails?.company?.id;
                opportunityDetails.opportunity_id = editOpportunityDetails?.id
                opportunityDetails.service_provider = serviceProvider
                opportunityDetails.product_family = productFamilyType
                opportunityDetails.product = productType
              
                // opportunityDetails.status= status
                dispatch(opportunityedit(opportunityDetails)).then((res) => {
                    if (res?.payload?.errormsg) {
                        sweetalert('success', res?.payload?.errormsg)
                        setDisableButton(false)
                    } else {
                        emptyState()
                        if(title === "OpportunityDetailsPage"){
                            sweetalert('success', res?.payload?.data?.msg)
                            setDisableButton(false)
                            dispatch(oppodetails(editOpportunityDetails?.id)).then((res)=>{
                                if(res?.payload){
                                    navigate('/dashboard/opportunitydetails', {state: {oppDetails: editOpportunityDetails?.id, title:title}})
                                    setOpenOpportunityModel(false)
                                }
                            })
                        }else if(state?.title === 'companyProfilePageOpportunityDetails'){
                            sweetalert('success', res?.payload?.data?.msg)
                            setDisableButton(false)
                            navigate('/dashboard/profile/companyOpportunity', {state :editOpportunityDetails?.company})
                        }else{
                            navigate('/dashboard/opportunitylist')
                            sweetalert('success', res?.payload?.data?.msg)
                            setDisableButton(false)
                        }
                        console.log( opportunityDetails, 'oppodetailsedfecedcecefdef')
                    }
                })
            }
        } else {
            setError(validate(validation_input))
        }
    }

    const handleClose = () => {
        setOpenOpportunityModel(false)
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };

    return (
        <>

        <BootstrapDialog
            // onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openOpportunityModel}
            fullWidth
            maxWidth="lg"
        >
        {/* Display Company name */}
        <DialogHeaderCompanyName label={ Object.keys(editOpportunityDetails).length === 0 ? `${leaddetails?.leaddetails?.company?.company_name} ${leaddetails?.leaddetails?.company?.company_type}` : `${editOpportunityDetails?.company?.company_name} ${editOpportunityDetails?.company?.company_type}`}  onClose={handleClose} />
        <BootstrapDialogTitle sx={{display:'flex', pt: 1, pb:0.5, pl:2, pr:2, justifyContent:'start', borderRadius:'10px', fontWeight:600, color:'#333333', fontSize:'24px', letterSpacing: "0.04em"}} id="customized-dialog-title" onClose={handleClose}>{Object.keys(editOpportunityDetails).length > 0 ? title == 'changerequest' ? `Modify Opportunity For (${editOpportunityDetails?.servicedetails?.service_id})`:`Edit Opportunity For (${editOpportunityDetails?.opportunity_no})` :`Create Opportunity For Lead (${leaddetails?.leaddetails?.lead_no})`}</BootstrapDialogTitle> 
        <DialogContent dividers>
        { (loader || contactloader) ? 
            <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
            <TailSpin
                height="30"
                width="30"
                color="blue"
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            /> 
            
            </Box>:<>
                {/*  Nav bar */}
                {/* <CompanyName label={(state?.title?.title === 'companyProfilePageOpportunityDetails' || state?.title ==="LeadDetailsPage" || state?.title === "Create followup")? `${state?.company?.company_name} ${state?.company?.company_type}`: (state?.title ==="CreateFromDashboard" || state?.title?.title==="Opportunity List")? state?.company?.label :`${editOpportunityDetails?.company?.company_name} ${editOpportunityDetails?.company?.company_type}`} /> */}
                {/* <Grid sx={{ borderBottom: '1px solid #e4e4e4', mt: 2, ml:2 , mr: 2, display: "flex", flexDirection: { lg: 'row', sm: 'row', xs: 'column-reverse' }, justifyContent: { lg: 'space-between', sm: 'space-between', xs: 'start' }, alignItems: { lg: 'flex-end', md: 'flex-end', xs: 'center' } }}>
                    <Typography variant='h6' sx={{ fontSize: { lg: '22px', md: '20px', sm: '18px' }, fontWeight: 600 }}>{Object.keys(editOpportunityDetails).length > 0 ? state?.title==="OpportunityDetailsPage" ?`(Edit Opportunity For ${state?.opportunity_no})` :`Edit Opportunity For (${editOpportunityDetails?.opportunity_no})` :`Create Opportunity For Lead (${leaddetails?.leaddetails?.lead_no})`}</Typography>
                </Grid> */}

                <Box  sx={{pb: 1, ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl:1, lg: 1}, }}>
                    {/*    Company      */}
                    <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }} >
                        {/* <Grid item xs={12} md={0} sm={0} lg={2}>
                            <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Company</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} lg={4}>
                            <Typography>Name</Typography>
                            <InputBox value={state?.operation === 'createOpp' ? state?.company?.value : `${editOpportunityDetails?.company?.company_name} ${editOpportunityDetails?.company?.company_type}`} Default='Mobigic' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.5, mt: 1, pl: 1 }} placeholder="LD-01245" />
                        </Grid> */}
                        <Grid item xs={12} md={4} sm={4} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Opportunity Name<span style={{ color: 'red' }}>*</span></H5FormLabel>
                            <InputBox disable={state.for === 'tasklist'} name='opportunity_name' value={opportunityDetails?.opportunity_name?.replace(/\s\s+/g, " ")} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Vodafone limited Mr, Ramesh Jadhav" />
                            <DisplayError error={error} fortagid='opportunity_name' />
                        </Grid>
                        {/* {Object.keys(editOpportunityDetails).length == 0 ? <><Grid item xs={12} md={4} sm={4} lg={3}>
                            <Typography>Lead ID</Typography>
                            <InputBox value={state.operation == 'createOpp' ? state?.leadDetails?.lead_no : ''} onchange={handleOfCreateOpportunityDetails} sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.5, mt: 1, pl: 1 }} placeholder="LD-01245" />
                        </Grid></> : <></>} */}
                        <Grid item xs={12} md={4} sm={4} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Contact Name<span style={{ color: 'red' }}>*</span></H5FormLabel>
                            <SelectDropDown
                                id='contact_name'
                                disabled={state.for === 'tasklist'}
                                name='contact_id'
                                options={contactList?.map((contact) => { return { value: contact?.id, label: `${contact?.first_name} ${contact?.last_name}` } })}
                                onchange={handleOfCreateOpportunityDetails}
                                placeholder={'Select Name'}
                                value={opportunityDetails?.contact_id}
                                sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                            />
                            <DisplayError error={error} fortagid='contact_id' />
                        </Grid>
                        {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Address<span style={{ color: 'red' }}>*</span></H5FormLabel>
                            <SelectDropDown
                                disabled={state.for === 'tasklist'}
                                name='branch'
                                options={contactList?.map((contact) => { return { value: contact?.id, label: `${contact?.first_name} ${contact?.last_name}` } })}
                                onchange={handleOfCreateOpportunityDetails}
                                placeholder='--Select Address--'
                                value={opportunityDetails?.branch}
                                sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                            />
                            <DisplayError error={error} fortagid='branch' />
                        </Grid> */}
                          {
                            title == 'changerequest' ?   <Grid item xs={12} md={4} sm={4} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Order Type<span style={{ color: 'red' }}>*</span></H5FormLabel>
                            <SelectDropDown
                                id='order_type'
                                disabled={state.for === 'tasklist'}
                                name='opportunity_type'
                                placeholder='Select Order Type'
                                options={OrderType}
                                onchange={handleOfCreateOpportunityDetails}
                                value={opportunityDetails?.opportunity_type}
                                sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                            />
                            <DisplayError error={error} fortagid='opportunity_type' />
                        </Grid> : ''
                         }
                        {
                          ((title == 'changerequest' && opportunityDetails.opportunity_type == 'Shifting') || (title == "OpportunityDetailsPage") || !title) ? <Grid item xs={12} md={4} sm={4} lg={3}>
                          <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Address<span style={{ color: 'red' }}>*</span></H5FormLabel>
                          <Box sx={{minWidth: 120 }}>
                              <FormControl fullWidth>    
                              {/* <InputLabel id="demo-multiple-name-label">Name</InputLabel>      */}
                              <Select id="select_address" 
                                  displayEmpty
                                  value={opportunityDetails.branch}
                                  name='branch'
                                  onChange={handleOfCreateOpportunityDetails}
                                  MenuProps={MenuProps}
                                  sx={{mt: 1, width:'100%', height:'40px', backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, color:`${state?.for ==='tasklist' ?'#444444':''}`, borderRadius: "10px" }}>
                                   <MenuItem disabled value="">
                                      <span>Select Address</span>
                                  </MenuItem>
                                  {getCompanyDetails?.data?.branches?.map((item, i)=>{
                                const menuItems = [];

                                if (item.register_address === true) {
                                  return (
                                      [
                                          <ListSubheader key={`register-subheader-${i}`} style={{fontWeight:'bold'}}>Registered Office Address</ListSubheader>,
                                          <MenuItem key={`register-item-${i}`} style={{ whiteSpace: 'normal', width:'fit-content' }} value={item.id}>{item.address}</MenuItem>,
                                      ]
                                  );
                                }
                        
                                if (item.head_office === true) {
                                  return (
                                      [
                                          <ListSubheader key={`head-subheader-${i}`} style={{fontWeight:'bold'}}> Head Office Address</ListSubheader>,
                                          <MenuItem key={`head-item-${i}`} value={item.id} style={{ whiteSpace: 'normal', width:'fit-content' }}>{item.address}</MenuItem>,
                                      ]
                                  );
                                }
                        
                                return menuItems;
                              })}
                              {getCompanyDetails?.data?.branches?.map((item, i) => {
                                  if (!item.head_office && !item.register_address) {
                                      return (
                                          [
                                              <ListSubheader key={`branch-subheader-${i}`} style={{fontWeight:'bold'}}> Branch Office Address</ListSubheader>,
                                              <MenuItem key={`branch-item-${i}`} style={{ whiteSpace: 'normal',  width:'fit-content'}} value={item.id}>{item.address}</MenuItem>,
                                          ]
                                      );
                                  }
                                  return null;
                              })}
                              </Select>       
                              </FormControl>
                          </Box> 
                          <DisplayError error={error} fortagid='branch' />
                      </Grid> : ''
                        }

                       
                            {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Order Type<span style={{color:'red' }}>*</span></H5FormLabel>
                                <SelectDropDown
                                    name='order_type'
                                    disabled={state.for === 'tasklist'}
                                    placeholder='Select Order Type'
                                    options={opportunityDetails?.opportunity_type === 'New' ? OrderNew : OrderType}
                                    onchange={handleOfCreateOpportunityDetails}
                                    value={opportunityDetails?.order_type}
                                    // Default={orderType?.map((item)=> {return {label:item[0], value:item[0]}})}
                                    // label='Select industry'
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='order_type' />
                            </Grid> */}
                    </Grid>

                    {/*        Opportunity     */}
                    <Box style={{ marginTop: '20px', display: 'flex', flexDirection: { sm: 'column', xs: 'column', lg: 'row' } }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            {/* <Grid item xs={12} md={0} sm={0} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Opportunity</Typography>
                            </Grid> */}
                            {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Opportunity Name</Typography>
                                <InputBox disable={state.for === 'tasklist'} name='opportunity_name' value={opportunityDetails?.opportunity_name} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Vodafone limited Mr, Ramesh Jadhav" />
                                <DisplayError error={error} fortagid='opportunity_name' />
                            </Grid> */}

                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Expected Date of Closure<span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <DatePickerOpenTo
                                    name ='value'
                                    openTo="day"
                                    minDate = {moment().add(60, 'days')}
                                    views={['year', 'month', 'day']}
                                    value={closure_date} 
                                    onChange={changeClosureDate} 
                                    disabled={state.for === 'tasklist'}
                                    style={{ backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, fontSize:'14px',marginTop:'8px'}}
                                   
                                />
                                {/* <DatePickerOpenTo
                                    name ='value'
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={closure_date} 
                                    onChange={changeClosureDate} 
                                    style={{background:'#FFFFFF',fontSize:'14px',marginTop:'8px'}}
                                    minDate={new Date()}
                                /> */}
                            </Grid>

                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Line Of Business<span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <SelectDropDown
                                   id='business_line'
                                    disabled={state.for === 'tasklist' || title == 'changerequest' || (title == "OpportunityDetailsPage" && editOpportunityDetails?.opportunity_type !=='New')}
                                    name='business_line'
                                    options={Business}
                                    placeholder='Select Business Line'
                                    onchange={handleOfCreateOpportunityDetails}
                                    value={opportunityDetails?.business_line}
                                    // label='Select industry'
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${(state.for === 'tasklist' || (title == "OpportunityDetailsPage" && editOpportunityDetails?.opportunity_type !=='New') || title == 'changerequest') ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                />
                                <DisplayError error={error} fortagid='business_line' />
                            </Grid>

                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Service Provider<span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <SelectDropDown
                                    id='service_provider'
                                    disabled={state.for === 'tasklist' || title == 'changerequest' ||  (title == "OpportunityDetailsPage" && editOpportunityDetails?.opportunity_type !=='New')}
                                    name='service_provider'
                                    options={dropdownValuesForServiceProvider}
                                    // options={title == 'changerequest' ? getServiceProviderTypes?.value?.map((item) => { return { label: item, value: item } }) : dropdownValuesForServiceProvider}
                                    onchange={handleOfCreateServiceProvider}
                                    value={serviceProvider}
                                    // label='Select industry'
                                    MenuProps={MenuProps}
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${(state.for === 'tasklist' || title == 'changerequest' ||  (title == "OpportunityDetailsPage" && editOpportunityDetails?.opportunity_type !=='New')) ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                />
                                <DisplayError error={error} fortagid='service_provider'/>
                            </Grid>

                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Product Family<span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <SelectDropDown
                                    id='product_family'
                                    disabled={state.for === 'tasklist' || productFamily?.length === 0 || title == 'changerequest' || (title == "OpportunityDetailsPage" && editOpportunityDetails?.opportunity_type !=='New')}
                                    name='product_family'
                                    placeholder= { 'Select Product Family'}
                                    options={productFamily?.map((item) => { return { label: item, value: item } })}
                                    onchange={handleOfCreateProductFamilyType}
                                    value={productFamilyType}
                                    // label='Select industry'
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${(state.for === 'tasklist' || title == 'changerequest' || (title == "OpportunityDetailsPage" && editOpportunityDetails?.opportunity_type !=='New')) ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='product_family' />
                            </Grid>
                        </Grid>
                    </Box>

                    {/* {status === "Closed-Lost" ? <>
                        <Box style={{ marginTop: '20px' }}>
                            <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                                <Grid item xs={12} md={4} sm={4} lg={4}>
                                    <Typography>Closed Lost Reasons<span style={{ color: 'red' }}>*</span></Typography>
                                    <SelectDropDown
                                        disabled={state.for === 'tasklist'}
                                        placeholder='Select Field'
                                        name='lost_reason'
                                        options={LostReasons}
                                        onchange={handleOfCreateOpportunityDetails}
                                        value={opportunityDetails?.lost_reason}
                                        sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    />
                                    <DisplayError error={error} fortagid='lost_reason' />
                                </Grid>

                                <Grid item xs={12} md={12} sm={12} lg={6}>   
                                    <Typography>Mention Reason in Detail<span style={{ color: 'red' }}>*</span></Typography> 
                                    <TextArea disabled={state.for === 'tasklist'} name='detailreason' value={opportunityDetails?.detailreason} onChange={handleOfCreateOpportunityDetails} style={{ width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '5px' }} placeholder="Enter the reason."></TextArea>
                                    <DisplayError error={error} fortagid='detailreason' />
                                </Grid>
                            </Grid>
                        </Box>
                        </> : <></>}            */}

                        {/* <Box style={{marginTop:'20px'}}>
                            <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                                <Grid item xs={12} md={4} sm={4} lg={3}>
                                    <Typography>Expected Date of Closure</Typography>
                                    <DatePickerOpenTo
                                        name ='value'
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={closure_date} 
                                        onChange={changeClosureDate} 
                                        style={{background:'#FFFFFF',fontSize:'14px',marginTop:'8px'}}
                                        minDate={new Date()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} lg={3}>
                                    <Typography>Line Of Business</Typography>
                                    <SelectDropDown 
                                        name='business_line'
                                        options={Business} 
                                        placeholder='--Select Business--'
                                        onchange={handleOfCreateOpportunityDetails}
                                        value={opportunityDetails?.business_line}
                                        // label='Select industry'
                                        sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                                    />
                                    <DisplayError error={error} fortagid='business_line'/>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} lg={3}>
                                    <Typography>Product Family</Typography>
                                    <SelectDropDown 
                                        name='product_family'
                                        placeholder='--Select Familly--'
                                        options={dropdownValuesForProductFamily} 
                                        onchange={handleOfCreateOpportunityDetails}
                                        value={opportunityDetails?.product_family}
                                        // label='Select industry'
                                        sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                                        MenuProps={MenuProps}
                                    />
                                    <DisplayError error={error} fortagid='product_family'/>
                                </Grid>
                            </Grid>
                        </Box> */}
                

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            {/* <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid> */}
                            {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Expected Date of Closure</Typography>
                                <DatePickerOpenTo
                                        name ='value'
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={closure_date} 
                                        onChange={changeClosureDate} 
                                        disabled={state.for === 'tasklist'}
                                        style={{ backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, fontSize:'14px',marginTop:'8px'}}
                                        minDate={new Date()}
                                    /> 
                            </Grid> */}
                                {/* <DatePicker
                                    disabled={state.for === 'tasklist'}
                                    value={closure_date}
                                    onChange={changeClosureDate}
                                    style={{  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, fontSize: '14px', marginTop: '8px' }} >DD-MM-YY</DatePicker> */}

                            {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                                g<Typography>Line Of Business</Typography>
                                <SelectDropDown
                                    disabled={state.for === 'tasklist'}
                                    name='business_line'
                                    options={Business}
                                    placeholder='--Select Business--'
                                    onchange={handleOfCreateOpportunityDetails}
                                    value={opportunityDetails?.business_line}
                                    // label='Select industry'
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                />
                                <DisplayError error={error} fortagid='business_line' />
                            </Grid> */}
                            

                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Product<span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <SelectDropDown
                                    id='product'
                                    disabled={state.for === 'tasklist'|| productLoader || title == 'changerequest' ||  (title == "OpportunityDetailsPage" && editOpportunityDetails?.opportunity_type !=='New')}
                                    name='product'
                                    placeholder={productLoader ? 'Loading...' :'Select Product'}
                                    options={product?.map((item) => { return { label: item, value: item } })}
                                    onchange={handleOfCreateProductType}
                                    value={productType}
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${(state.for === 'tasklist' || title == 'changerequest' ||  (title == "OpportunityDetailsPage" && editOpportunityDetails?.opportunity_type !=='New')) ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='product' />
                            </Grid>

                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Service Provider Opportunity ID</H5FormLabel>
                                <InputBox disable={state.for === 'tasklist'} name='service_opportunityid' value={opportunityDetails?.service_opportunityid} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="123456789" />
                                <DisplayError error={error} fortagid='service_opportunityid' />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}> expected MRC <span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <InputBox type='number' disable={state.for === 'tasklist'} name='expected_mrc' value={opportunityDetails?.expected_mrc} onchange={(e) => {
                                    if(e.target.value < 0) {
                                        e.preventDefault()
                                      } else {
                                        handleOfCreateOpportunityDetails(e)
                                      }
                                }} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="123456789" />
                                <DisplayError error={error} fortagid='expected_mrc' />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}> expected NRC <span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <InputBox type='number' disable={state.for === 'tasklist'} name='expected_nrc' value={opportunityDetails?.expected_nrc} onchange={(e) => {
                                  if(e.target.value < 0) {
                                    e.preventDefault()
                                  } else {
                                    handleOfCreateOpportunityDetails(e)
                                  }
                                }} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="123456789" />
                                <DisplayError error={error} fortagid='expected_nrc' />
                            </Grid>
                            {/* {
                                (title == 'changerequest' && serviceProvider != 'mobigic') ?   <Grid item xs={12} md={4} sm={4} lg={3}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Third Party Service ID<span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <InputBox disable={state.for === 'tasklist'} name='third_party_service_id' value={opportunityDetails?.third_party_service_id} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="123456789" />
                                <DisplayError error={error} fortagid='third_party_service_id' />
                            </Grid> : ''
                            } */}
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            {/* <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid> */}
                            {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Product</Typography>
                                <SelectDropDown
                                    disabled={state.for === 'tasklist'}
                                    name='product'
                                    placeholder='--Select Product--'
                                    options={product?.map((item) => { return { label: item, value: item } })}
                                    onchange={handleOfCreateOpportunityDetails}
                                    // label='Select industry'
                                    value={opportunityDetails?.product}
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='product' />
                            </Grid> */}
                            {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Service Provider</Typography>
                                <SelectDropDown
                                    disabled={state.for === 'tasklist'}
                                    name='service_provider'
                                    options={dropdownValuesForServiceProvider}
                                    // Default={dropdownValuesForServiceProvider[0]?.value}
                                    onchange={handleOfCreateOpportunityDetails}
                                    value={opportunityDetails?.service_provider}
                                    // label='Select industry'
                                    MenuProps={MenuProps}
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                />
                                // <DisplayError error={error} fortagid='service_provider'/> 
                            </Grid> */}
                            {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Service Provider Oppr. ID</Typography>
                                <InputBox disable={state.for === 'tasklist'} name='service_opportunityid' value={Number(opportunityDetails?.service_opportunityid)} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="123456789" />
                                <DisplayError error={error} fortagid='service_opportunityid' />
                            </Grid> */}
                        </Grid>
                    </Box>

                    {/* ..........  Commercial not useable now   ..... */}
                    {/* <Box style={{ marginTop: '30px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Commercial</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>One Time Charges</Typography>
                                <InputBox disable={state.for === 'tasklist'} name='otc' value={opportunityDetails?.otc} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="1000" />
                                <DisplayError error={error} fortagid='otc' />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <Typography>Annual Reccuring Charges</Typography>
                                <InputBox disable={state.for === 'tasklist'} name='arc' value={opportunityDetails?.arc} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="1000" />
                                <DisplayError error={error} fortagid='arc' />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Contract Period(months)<span style={{color:'red'}}>*</span> </Typography>
                                <SelectDropDown
                                    disabled={state.for === 'tasklist'}
                                    placeholder='--Select months--'
                                    name='months'
                                    options={Months}
                                    onchange={handleOfCreateOpportunityDetails}
                                    value={opportunityDetails?.months}
                                    // label='Select industry'
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                />
                                <DisplayError error={error} fortagid='months' />
                            </Grid>
                        </Grid>
                    </Box> */}

                    {/* <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Billing Frequency<span style={{ color: 'red' }}>*</span></Typography>
                                <SelectDropDown
                                    disabled={state.for === 'tasklist'}
                                    placeholder='--Select Frequency--'
                                    name='bill_frequency'
                                    options={BillingFrequency}
                                    onchange={handleOfCreateOpportunityDetails}
                                    value={opportunityDetails?.bill_frequency}
                                    // label='Select industry'
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='bill_frequency' />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <Typography>Billing Type<span style={{ color: 'red' }}>*</span></Typography>
                                <SelectDropDown
                                disabled={state.for === 'tasklist'}
                                    name='bill_type'
                                    placeholder='--Select Type--'
                                    options={BillType}
                                    onchange={handleOfCreateOpportunityDetails}
                                    // label='Select industry'
                                    value={opportunityDetails?.bill_type}
                                    sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='bill_type' />
                            </Grid>
                        </Grid>
                    </Box> */}

                    {/* <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Date of Billing</Typography>
                                <DatePickerOpenTo
                                    name ='value'
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    disabled={state.for === 'tasklist'}
                                    style={{backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, marginTop: '5px' }}
                                    onChange={changeDateOfBilling}
                                    value={date_of_billing}
                                    minDate={new Date()}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Date of Renewal </Typography>
                                <DatePickerOpenTo
                                    name ='value'
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    disabled={state.for === 'tasklist'}
                                    style={{ backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, marginTop: '5px' }}
                                // onChange={changeDateOfRenewal}
                                    value={dayjs(date_of_billing).add(opportunityDetails.months, 'months')}
                                    minDate={new Date()}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Service ID</Typography>
                                <InputBox disable={state.for === 'tasklist'} name='service_id' value={opportunityDetails?.service_id} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="007" />
                                <DisplayError error={error} fortagid='service_id' />
                            </Grid>
                        </Grid>
                    </Box> */}

                    {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                        <Typography>Billed By</Typography>
                        <SelectDropDown
                            disabled={state.for === 'tasklist'}
                            name='billedBy'
                            // options={dropdownValuesForOpportunity}
                            // onchange={handleOfCreateOpportunityDetails}
                            // value={opportunityDetails?.opportunity_type}
                            sx={{ p: 0.1, mt: 1, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                        />
                        <DisplayError error={error} fortagid='billedBy' />
                    </Grid> */}

                    {/*         summary           */}
                    <Box style={{ marginTop: '20px',paddingRight:'15px'}}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            {/* <Grid item xs={12} md={12} sm={12} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Summary</Typography>
                            </Grid> */}
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Summary<span style={{ color: 'red' }}>*</span></H5FormLabel>
                                <TextArea disabled={state?.for === 'tasklist'} name='summary' value={opportunityDetails?.summary?.replace(/\s\s+/g, " ")} onChange={handleOfCreateOpportunityDetails} style={{ width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '5px',marginTop:'5px'}} placeholder="Write a note about Opportunity."></TextArea>
                                  <DisplayError error={error} fortagid='summary' />
                            </Grid>
                            {Object.keys(editOpportunityDetails)?.length == 0 ? <div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                <span>{opportunityDetails?.summary?.length}/2000</span>
                            </div> :<></>}
                        </Grid>
                    </Box>

                </Box>

                {
                    state?.for == 'tasklist' ? '':<Box sx={{ mt: 4, mr: { xs: 1, sm: 1, xl: 1, lg:1}, mb: 2, display: 'flex', justifyContent: 'end' }}>
                    <CustemBtn id='save' disabled={disableButton} onClick={handleSaveOpportunityDetails} variant="contained" sx={{ borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px'}} text="Save" />
                </Box>
                }

        </>}
        </DialogContent>
        </BootstrapDialog>
       
        </>
    )
}

export default CreateNewOpportunityForm
