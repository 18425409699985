import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectDropDown({style, id , disabled,options,value,onchange,name,label,sx,Default,MenuProps,defaultlabel, placeholder}) {
  return (
    <Box sx={{ minWidth: 120}}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{defaultlabel}</InputLabel>
        <Select
          id={id}
          sx={{...sx}}
          labelId="demo-simple-select-label"
          // id="demo-simple-select"
          value={value}
          onChange={onchange}
          name={name}
          label={label}
          size='small'
          displayEmpty
          defaultValue={Default}
          disabled ={disabled}
          MenuProps={MenuProps}
        >
           <MenuItem disabled value="">
           {/* <em>Placeholder</em> */}
            <span >{placeholder}</span>
          </MenuItem>
          {
            options?.map((item)=>{
                return <MenuItem value={item.value}>{item.label}</MenuItem>
            })
          }
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
    </Box>
  );
}