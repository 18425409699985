
import React from 'react'

const WarningIcon = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2586_74477)">
    <path d="M9.54688 16.231C13.2748 16.231 16.2969 13.2089 16.2969 9.48096C16.2969 5.75303 13.2748 2.73096 9.54688 2.73096C5.81895 2.73096 2.79688 5.75303 2.79688 9.48096C2.79688 13.2089 5.81895 16.231 9.54688 16.231Z" fill="#FFC700" stroke="#FFC700" stroke-width="1.125" stroke-miterlimit="10"/>
    <path d="M9.54688 10.0435V6.10596" stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.54688 13.4185C10.0129 13.4185 10.3906 13.0407 10.3906 12.5747C10.3906 12.1087 10.0129 11.731 9.54688 11.731C9.08088 11.731 8.70312 12.1087 8.70312 12.5747C8.70312 13.0407 9.08088 13.4185 9.54688 13.4185Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_2586_74477">
    <rect width="18" height="18" fill="white" transform="translate(0.546875 0.480957)"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default WarningIcon