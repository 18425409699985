import React,{useState, useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import { experimentalStyled as styled} from '@mui/material/styles';
import { Typography, Box, Card, IconButton, Tooltip, Snackbar} from '@mui/material';
import { ComapanyName, ContactCard, CustemBtn, DeleteIcon, EditIcon} from '../../components/custom';
import AddIcon from '../../components/custom/AddIcon';
import ContactForm from '../ContactForm/ContactForm';
import { useDispatch, useSelector} from 'react-redux';
import { enableDisableContact } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import moment from 'moment';
import { CheckCircle } from '@mui/icons-material';
import AdminRole from '../../adminrole/Adminrole';
import { storeCompayName } from '../../Redux/slices/createCompanySlice';

const useStyles = makeStyles(theme => ({ 
    tooltip: {
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
        maxWidth: '100px', /* Adjust the max-width as needed */
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
      
    'tooltip::before': {
        content: 'attr(data-text)',
        position: 'absolute',
        backgroundColor: ' #333',
        color: '#fff',
        padding: '5px',
        borderRadius: '4px',
        zIndex: 1,
        opacity: 0,
        transition: 'opacity 0.3s',
        bottom: '120%',
        left: '50%',
        transform: 'translateX(-50%)',
        whiteSpace: 'normal',
        width: 'auto',
        maxWidth: '200px',
    }
}));


const CardBody = styled('div')({
    backgroundColor:'#F0EFFD',
    padding:'20px',
    borderRadius:'10px',
 })
 
 const ChildCard = styled('div')({
     backgroundColor:'#FFFFFF',
     borderRadius:'10px',
     boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)'
  })
 
 const CardInfo = styled('div')({
     display:'flex',
     justifyContent:'space-between',
    //  marginTop:"10px",
 })
 
 const CardHeaders = styled('div')({
     display:'flex',
     justifyContent:'space-between',
     borderBottom:'0.5px solid #BBBBBB',
 })

const dateFormatter = (date) => {
    let formattedDate = new Date(date);
    return formattedDate.getDate() + '/' + (formattedDate.getMonth() + 1) + '/' + formattedDate.getFullYear();
};

export const ProfileContact = ({contacts, permissionsDetails, buttonStatus, setButtonStatus,contactStatusChangesFromProfilePage}) =>{
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [openModal ,setOpenModal] = useState(false)
    const [editContactDetails, setEditContactDetails] = React.useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const title ="CreateFromProfilePage"
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())

    const contactStatusChanges = (contact) => {
        contactStatusChangesFromProfilePage(contact)    
    } 

    const handleLinkedInClick = (contact) => {
        if (contact?.linkdin?.length > 0 && contact?.enabled === true) {
            window.open(contact?.linkdin, '_blank');
        }
    }
    
    const handleTwitterInClick =(contact) =>{
        if (contact?.twitter?.length > 0 && contact?.enabled === true) {
            window.open(contact?.twitter, '_blank');
        }
    }

    const handleCopyEmail = (email) => {
        navigator.clipboard.writeText(email)
          .then(() => {
            setSnackbarOpen(true)
          })
          .catch((error) => {
            setSnackbarOpen(false)
          });
    }

    const sweetalert = (variant, text) => {
        swal({
          icon: variant==='error' ? 'error' :'success',
          title: text , 
          button: "Ok!",
        }); 
    };

    return ( 
        <>
           <Box>
                <CardHeaders>
                    <Typography variant='h6' sx={{color:'#444444s',fontSize:'22px',fontWeight:600, letterSpacing:'0.04rem'}}>
                        Contacts
                    </Typography>
                    {permissionsDetails?.perm_contact_create ?<> 
                    <Typography sx={{pt:0.1}}>
                        <IconButton onClick={() => {
                            setOpenModal(true)
                            setEditContactDetails({})}}>
                            <AddIcon/>
                        </IconButton>
                    </Typography></> :<></>}
                </CardHeaders>

                {(checkAdminRole && permissionsDetails?.perm_contact_view) || permissionsDetails?.perm_contact_view ? <>
                    {contacts?.data?.contacts?.map((contact) => (
                    <ChildCard sx={{p:2, mt:1}}>
                    <Box sx={{display:'flex', mt:0.5, justifyContent:'space-between',borderBottom:'1px solid #EFEFEF',alignItems:'end'}}>
                        <Typography variant='h6' sx={{fontSize:'20px',color:'#8F9200',flex:15, fontWeight:600}}>
                            {contact?.first_name} {contact?.last_name}
                        </Typography>
                        {(checkAdminRole && permissionsDetails?.perm_contact_edit) || permissionsDetails?.perm_contact_edit ?<>
                        <Typography sx={{color:'#3C8AFF',fontSize:'18px',flex:1}} >
                        <IconButton onClick={() => {setOpenModal(true)
                            dispatch(storeCompayName())
                            setEditContactDetails(contact)}}>
                            <EditIcon w={20} h={20} />
                        </IconButton>
                        </Typography> </>:<></>}
                        {/* <Typography sx={{color:'#3C8AFF',fontSize:'18px',flex:1}} >
                            <DeleteIcon w={20} h={20} />
                        </Typography> */}
                    </Box>

                    <CardInfo sx={{mt:2}}>
                    <Typography sx={{ flex: 2, fontWeight: 600 }}>
                        <span style={{ marginRight: '4px' }}> Email</span>
                        <span style={{ marginRight: '4px' }}>
                            <Tooltip title="Copy Email" placement="top-start">
                                <IconButton onClick={() => handleCopyEmail(contact?.email)}>
                                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.41653 2.99967V0.999674C3.41653 0.631488 3.71501 0.333008 4.0832 0.333008H12.0832C12.4514 0.333008 12.7499 0.631488 12.7499 0.999674V10.333C12.7499 10.7012 12.4514 10.9997 12.0832 10.9997H10.0832V12.9991C10.0832 13.3676 9.78327 13.6663 9.412 13.6663H1.42111C1.05039 13.6663 0.75 13.3699 0.75 12.9991L0.751733 3.66692C0.7518 3.29841 1.05176 2.99967 1.42295 2.99967H3.41653ZM2.08495 4.33301L2.08346 12.333H8.74987V4.33301H2.08495ZM4.74987 2.99967H10.0832V9.66634H11.4165V1.66634H4.74987V2.99967Z"
                                            fill="#676666"
                                        />
                                    </svg>
                                </IconButton>
                            </Tooltip>
                        </span>
                    </Typography>
                        <Typography sx={{flex:2, fontWeight:600}}>
                            Mobile No
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight:600}}>
                            Designation
                        </Typography>
                    </CardInfo>
                    <CardInfo sx={{mt:1}}>
                        <Typography sx={{flex:2, fontSize:'14px', color:'#444444', letterSpacing:'0.04rem', ":hover":{cursor:'pointer'}}}>
                        <span className="tooltip" data-text={contact?.email}>
                            {contact?.email.slice(0, 10)} {/* Display the first 10 characters */}
                        </span>
                        </Typography>
                        <Typography sx={{flex:2,fontSize:'14px',color:'#444444'}}>
                            {contact?.phone}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {contact?.designation}
                        </Typography>
                    </CardInfo>
                    <CardInfo sx={{mt:3}}>
                        <Typography sx={{flex:2, fontWeight:600}}>
                            Social Media
                        </Typography>
                        <Typography sx={{flex:2,fontWeight:600}}>
                            Date of Birth 
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight:600}}>
                            Created By
                        </Typography>
                    </CardInfo>
                    <CardInfo> 
                        <Typography sx={{flex:2, fontSize:'14px', color:'#7673F6', fontWeight:600}}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '4px' }}>
                                  <Tooltip title={contact?.linkdin?.length === 0 ? 'Unavailable':'LinkedIn Profile Link'} placement="top-start">
                                    <IconButton onClick={()=>handleLinkedInClick(contact)}>
                                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5872 18.339H15.9217V14.1622C15.9217 13.1662 15.9015 11.8845 14.5327 11.8845C13.143 11.8845 12.9307 12.9683 12.9307 14.0887V18.339H10.2652V9.75H12.8257V10.9207H12.8602C13.218 10.2457 14.088 9.53325 15.3877 9.53325C18.0885 9.53325 18.588 11.3108 18.588 13.6245L18.5872 18.339ZM7.2547 8.57475C6.3967 8.57475 5.70745 7.88025 5.70745 7.026C5.70745 6.1725 6.39745 5.47875 7.2547 5.47875C8.1097 5.47875 8.8027 6.1725 8.8027 7.026C8.8027 7.88025 8.10895 8.57475 7.2547 8.57475ZM8.5912 18.339H5.9182V9.75H8.5912V18.339ZM19.9207 3H4.5802C3.84595 3 3.25195 3.5805 3.25195 4.29675V19.7033C3.25195 20.4202 3.84595 21 4.5802 21H19.9185C20.652 21 21.252 20.4202 21.252 19.7033V4.29675C21.252 3.5805 20.652 3 19.9185 3H19.9207Z" fill={contact?.linkdin?.length === 0 || contact?.enabled === false ?"#E6E6E6": "#0177B5"}/>
                                      </svg>
                                    </IconButton>
                                  </Tooltip>
                                  </span>
                                <span style={{ marginRight: '4px' }}>
                                  <Tooltip title={contact?.twitter?.length === 0 ? 'Unavailable':'Twitter Profile Link'}placement="top-start">
                                    <IconButton onClick={()=>handleTwitterInClick(contact)}>
                                      <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M20.4625 2.65605C19.6991 2.99375 18.8895 3.21555 18.0606 3.31411C18.9339 2.79132 19.5874 1.9689 19.8993 1.00005C19.0787 1.48761 18.1805 1.83077 17.2438 2.01461C16.4531 1.17106 15.348 0.693029 14.1918 0.694339C11.8826 0.694339 10.0097 2.56661 10.0097 4.87683C10.0097 5.20458 10.0473 5.52242 10.1176 5.82909C6.64048 5.65404 3.56008 3.99005 1.49678 1.45941C1.12529 2.09767 0.930055 2.82318 0.931045 3.56167C0.931045 5.01259 1.66961 6.29324 2.7915 7.043C2.12738 7.022 1.47789 6.84264 0.897185 6.51973C0.896545 6.5373 0.896545 6.55487 0.896545 6.57148C0.896545 8.5984 2.3382 10.2892 4.252 10.6731C3.89281 10.7703 3.52232 10.8194 3.15022 10.8191C2.87997 10.8191 2.61772 10.7942 2.3628 10.7453C2.89532 12.4065 4.43886 13.6159 6.2696 13.6491C4.78813 14.8118 2.95869 15.4426 1.07543 15.4399C0.742125 15.4402 0.409095 15.4205 0.078125 15.3811C1.99004 16.6102 4.21552 17.2625 6.48842 17.2601C14.1816 17.2601 18.388 10.8875 18.388 5.36111C18.388 5.1803 18.3836 4.99886 18.3756 4.81997C19.1943 4.22845 19.901 3.49567 20.4625 2.65605Z" fill={contact?.twitter?.length === 0 || contact?.enabled === false ?"#E6E6E6": "#0A93E2"}/>
                                      </svg>
                                    </IconButton>
                                  </Tooltip>
                                </span>
                              </div>
                            {/* {contact?.linkdin?.length === 0 ? "Not avaliable" :
                                <a href={contact?.linkdin} style={{ color: "#7673F6", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                    Click Here
                                </a>
                            } */}
                        </Typography>
                        <Typography sx={{flex:2,fontSize:'14px',color:'#444444', mt:1}}>  
                            {contact?.dob === 0 ? '': moment(contact?.dob).format('D/M/YYYY')}
                        </Typography>
                        <Typography sx={{flex:1.5, fontSize:'14px', color:'#444444', mt:1}}>
                            {contact?.created_by?.firstname} {contact?.created_by?.lastname}
                        </Typography>
                    </CardInfo>

                    {(checkAdminRole && permissionsDetails?.perm_contact_edit)|| permissionsDetails?.perm_contact_edit ?<>
                        <Box sx={{mt:4,ml:{xs:1,sm:1,xl:4},mr:{xs:1,sm:1,xl:4},display:'flex',justifyContent:'end' }}>
                            <CustemBtn variant="contained" onClick={()=> contactStatusChanges(contact)} sx={{borderRadius:"40px",pl:4,pr:4, fontSize:'16px', backgroundColor:contact?.enabled === false?'#9D9D9D':'#6aa81e', mb:1.5,"&:hover": {
                                backgroundColor:contact?.enabled === false ? '#9D9D9D':'#6aa81e'}}} text={contact?.enabled === false ? "Disabled" :"Enabled"} 
                            />
                        </Box>
                    </> :<></>}
                    </ChildCard>
                    ))}
                </> :<><Typography style={{color:'#f75d4f', margin:'10px'}}>You have no permissions to access this. Please contact your administrator for more information.</Typography></>}
        {/* =================== open modal for contact details form ================= */}
                 {openModal && <ContactForm open ={openModal} setOpenModal={setOpenModal}  
                 title={title} dropdownValues={contacts?.data?.company} editContactDetails={editContactDetails} setEditContactDetails= {setEditContactDetails} />}
           </Box>

        {/*  Display Msg */}
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000} 
            onClose={() => setSnackbarOpen(false)}
            message="Email copied"
            action={
            <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
                <CheckCircle />
            </IconButton>
            }
        />
            </>
    )
}