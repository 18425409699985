import { experimentalStyled as styled } from '@mui/material/styles';

const LoginContainer = styled('div')({
    minHeight:'100vh',
    overflowY: 'auto',
    // marginTop: "0.2px",
    // marginBottom:'0.2px',
     marginRight:'20px',
    // padding: "10px",
    background: '#F8FCFF',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  })
  
  const Image = styled('div')({
   '@media (max-width: 992px)': {
       display: 'none', 
       alignItems:'center',
     },
  })
  
  const LogoBox = styled('div')({
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
    margin:'50px 0px 20px 0px',
    '@media (max-width: 768px)': {
      margin:'20px 20px', 
    },
  })
  
  const LoginDetails =styled('div')({
    background: '#FFFFFF',
    width: 'auto',
    height: '280px',
    borderRadius:'10px',
    padding: "20px 20px 32px",
    margin:'40px 50px',
    '@media (max-width: 768px)': {
      margin:'40px 20px', 
      alignItems:'center',
      fontSize:'18px',
    },
  })

  const ForgotPasswordDetails =styled('div')({
    background: '#FFFFFF',
    width: 'auto',
    height: '260px',
    borderRadius:'10px',
    padding: "20px 20px 32px",
    margin:'40px 50px',
    '@media (max-width: 992px)': {
      margin:'40px 20px', 
      alignItems:'center',
    },
  })

  const ResetPasswordDetails =styled('div')({
    background: '#FFFFFF',
    width: 'auto',
    height: '400px',
    borderRadius:'10px',
    padding: "20px 20px 32px",
    margin:'40px 50px',
    '@media (max-width: 992px)': {
      margin:'40px 20px', 
      alignItems:'center',
    },
  })
  
  export {LoginContainer, LogoBox, LoginDetails, ForgotPasswordDetails, ResetPasswordDetails}