import React from 'react'

const DisableAssignIcon = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.37132 9.49893H11.25V10.4989H2.25V8.37763L7.19975 3.42789L9.32105 5.54918L5.37132 9.49893ZM7.90685 2.72078L8.9675 1.66012C9.1628 1.46486 9.47935 1.46486 9.6746 1.66012L11.0889 3.07433C11.2841 3.26959 11.2841 3.58618 11.0889 3.78144L10.0281 4.8421L7.90685 2.72078Z" fill="#E1E1E1"/>
    </svg>

  )
}

export default DisableAssignIcon