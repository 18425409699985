export default function displayname(list=[]) {
   let allnames = []
 

  list?.map((name) => {
    let obj = {}
        obj.id = name.id
    if(name.company_type == 'PRIVATE LIMITED' || name.company_type =='LIMITED' || name.company_type == 'LLP') {
         obj.label =  `${name.company_name} ${name.company_type}`
         obj.value =  `${name.company_name} ${name.company_type}`
         obj.branch = name?.branch
         obj.headOffice = name?.head_office
         obj.registeredAddress = name?.registered_address
      } else {
        obj.label =  `${name.company_name} (${name.company_type})`
        obj.value = `${name.company_name} (${name.company_type})`
        obj.branch = name?.branch
        obj.headOffice = name?.head_office
        obj.registeredAddress = name?.registered_address
      }
      allnames.push(obj)
  })
  return allnames
}