import React ,{useState, useEffect}from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Box, Grid} from "@mui/material"
import { InputBox, SelectDropDown } from '../../components/custom';
import CustemBtn from '../../components/custom/CustemBtn'
import { useDispatch, useSelector } from 'react-redux';
import { userList } from '../../Redux/Thunk/createuserthunk';
import{leadAssignedToUser}from '../../Redux/Thunk/createleadthunk';
import swal from 'sweetalert';
import {getUserRole} from '../../Redux/Thunk/createrolethunk'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  // '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper':{
  //   maxWidth:'1000px',
  //   minWidth:'600px'
  // }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AssignModal({title, taskNumber, taskAssignedFromUser, openAssignModal, setOpenAssignModal,update, setUpdate, saveAssignedManagerOrExecutiveName}) {
  const dispatch = useDispatch()
  const {user} = useSelector((state) => state.auth)
  const [managerList, setManagerList]= useState([]);
  let userName =`${taskAssignedFromUser?.assigned_to?.firstname} ${taskAssignedFromUser?.assigned_to?.lastname}`
  const [selectUserName , setSelectUserName]= useState(userName)

  const handleClose = () => {
    setOpenAssignModal(false);
  };

  useEffect(()=>{
     console.log(user?.data?.user?.role.role == 'god')
        let id = user?.data?.user?.role.role == 'god' ? '' : user?.data?.user?.id
        if(title === 'Task'){
          dispatch(userList({For:'task'})).then((res)=>{
            setManagerList(res?.payload?.data)
          })
        }else{
          dispatch(userList({id:id})).then((res)=>{
            setManagerList(res?.payload?.data)
          })
        }
  },[])

  const handleChangeManagerName =(event) =>{
    setSelectUserName(event.target.value)
  }
  
  const saveAssignedName =()=>{
     saveAssignedManagerOrExecutiveName(managerList, selectUserName)
    // if(selectUserName ===''){
    //     sweetalert ('error', "Please Select Name") 
    // }else{
    //     let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectUserName)
    //     dispatch(leadAssignedToUser({lead_id:assigned?.id, user_id:findUser?.id})).then((res)=>{
    //     sweetalert ('success', res.payload.data) 
    //     setOpenAssignModal(false)
    //     setUpdate(!update)
    //   })
    // }
  }
  // let findRole = getallroles.find((item)=>item.role ==='Manager')
  

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openAssignModal}
        fullWidth
        maxWidth="sm"
      >  
        <BootstrapDialogTitle sx={{p:1, fontWeight:'600'}} id="customized-dialog-title" onClose={handleClose}>
          {title} ({taskNumber}) Assign
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <Box style={{marginTop:'5px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Grid container sx={{mb:3,justifyContent:'space-between'}} spacing={2} >
                  {/* <Typography sx={{pl:2}}>{title} Name : {taskAssignedFromUser?.lead_name}</Typography> */}
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                            {/* <Typography>{user?.data?.user?.role?.role === 'god' ? "Manager Name" :"Executive Name"}<span style={{color:'#FF0000'}}>*</span></Typography>   */}
                             <Typography>Assign To <span style={{color:'#FF0000'}}>*</span></Typography>
                              <SelectDropDown 
                                name='manager'
                                options={managerList?.map((name)=>{ return {label:`${name.firstname} ${name.lastname}`, value:`${name.firstname} ${name.lastname}`}})}
                                placeholder={managerList.length > 0 ? 'Select Name' : 'No Option to Assign'}
                                value={selectUserName}
                                onchange={handleChangeManagerName}
                                sx={{p:0.1, mt:1, pl:1, backgroundColor:'#ffffff',borderRadius:"10px"}}
                                MenuProps={MenuProps}
                             />
                        </Grid>
                </Grid>
            </Box>
        </DialogContent>
          <DialogActions sx={{mt:1,mb:1}}>
            <CustemBtn variant="contained" sx={{borderRadius:"10px", pl:3, pr:3}} text="Save" onClick={saveAssignedName} />
          </DialogActions>
        </BootstrapDialog>
    </div>
  );
}