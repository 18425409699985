import { useState,useEffect } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles'; 
import ManagerRoute from './Routing/ManagerRoute'
import Routing from "./Routing/Routing";
import { axiosInstance, createAxiosResponseInterceptor, setHeaders } from "./Redux/Thunk/axioshelper";
import { useNavigate } from 'react-router-dom';
import {theme} from './Theme/theme'; 

axiosInstance.interceptors.request.use(function (config) {
  // if(typeof window !== 'undefined'){
  var token = localStorage.getItem('accessToken')
  // }
  config.headers= { Authorization: `Bearer ${token}` };
    return config;
  }, function (error) {
  // Do something with request error
    return Promise.reject(error);
  });

  createAxiosResponseInterceptor()

function App() {
  const accessToken = localStorage.getItem("accessToken") 
  const [flag, setFlag] = useState(false)
  const navigate = useNavigate()
  // const isAuthenticated = !! localStorage.getItem("accessToken");
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("accessToken")); 
  console.log(isAuthenticated, accessToken, "isAuthenticated")


  useEffect(() => {
    console.log("Access Token:", accessToken);
    if (!accessToken) {
      setIsAuthenticated(false);
      navigate('/'); // Redirect to the login page
    } else {
      setIsAuthenticated(true);
      setHeaders("Authorization", `Bearer ${accessToken}`);
    }
  }, [accessToken]);

  // useEffect(() => {
  //   if(accessToken) {
  //     setFlag(true)
  //      setHeaders('Authorization',`Bearer ${accessToken}`);
  //   }else{
  //     setFlag(false) 
  //   }
  // }, [accessToken])

  return (
    <>
    {/* {flag ? <ThemeProvider theme={THEME} >
              <Routing isAuthenticated={isAuthenticated}/>
           </ThemeProvider>
            : 
           <ThemeProvider theme={THEME} >
             <ManagerRoute />
           </ThemeProvider>
    } */}
     
      <ThemeProvider theme={theme} >
          {isAuthenticated ? <Routing isAuthenticated={isAuthenticated} /> : <ManagerRoute />}
        {/* <Routing isAuthenticated={isAuthenticated}/> */}
      </ThemeProvider>
     </>
    
  );
}



export default App;

