const headCells = [
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'Role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
  },
  
  {
    id: 'Reporting To',
    numeric: false,
    disablePadding: false,
    label: 'Reporting To',
  },
  {
    id: 'Creation Date',
    numeric: false,
    disablePadding: false,
    label: 'Creation Date',
  },
  // {
  //   id: 'Designation',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Designation',
  // },
 
  {
    id: 'Email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  // {
  //   id: 'Mobile No',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Mobile No',
  // },
];

  export { headCells }