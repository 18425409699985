import React, { useState, useEffect } from 'react';
import { Navbar, DropDown, CustemBtn, CompanyName, InputBox, TextArea, DatePicker, SelectDropDown } from '../../components/custom';
import { Grid, Box, styled, Card, Avatar, Typography, TextField, Autocomplete } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllCompanyName } from '../../Redux/Thunk/createcompanythunk';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComanyLeads } from '../../Redux/Thunk/createopportunitythunk';
import { displayContactList } from '../../Redux/Thunk/createcontact';
import FollowUpDetails from './FollowUpDetails';
import Swal from 'sweetalert2';
import { main_search } from '../../Redux/Thunk/dashboardthunk';
import displayname from '../../displaycompanyname/displaycompany';

const MainContainer = styled('div')({
    width: '100%',
    maxHeight: '100%',
    height: 'auto',
})

const CardBody = styled('div')({
    backgroundColor:'#F0EFFD',
    padding:'20px',
    borderRadius:'10px',
})

const followUpLists = [
    {
        value: 'lead',
        label: 'Lead',
    },
    {
        value: 'opportunity',
        label: 'Opportunity',
    }
]
////   Create New follow Up  //
const CreateFollowUp = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {state} = useLocation()
    const { getContactList } = useSelector((state) => state?.contact)
    const {companyleads} = useSelector((state)=> state?.opportunity)
    const [displaySelectId, setDisplaySelectId] = useState('');
    const [selectedId, setSelectedId] = useState("");
    const [companyList, setCompanyList] = useState([]);
    const [followUpFor, setFollowUpFor] = useState({
        value: 'lead',
        label: 'Lead',
    })
    const [companyName, setCompanyName] = useState('');
    const [contactName, setContactName] = useState({})
    const [leadList, setLeadList] = useState([]);
    const [searchinput, setSearchInput] = useState('') 
    const [companyDetails, setCompanyDetails]= useState({})
    const [opportunityList, setOppList]= useState([])
    const [showLeadDetails, setShowLeadDetails]= useState(false);
    const [showOpportunityDetails, setShowOpportunityDetails] = useState(false)
    const [selectedLeadDetails, setSelectedLeadDetails] = useState('')
    const [selectedOpportunityDetails, setSelectedOpportunityDetails] = useState('')
    const [openFollowupPopup, setOpenFollowupPopup] = useState(false)
    const [editFollowupDetails, setEditFollowupDetails] = useState({});
   
    console.log(state?.title,"satttttttt")
    useEffect(() => {
        // dispatch(getAllCompanyName()).then((res) => {
        //     setCompanyList(res?.payload)
        // })

        if(state?.operation==="createFollowup") {
            dispatch(fetchComanyLeads(state?.companyName?.id)).then((res)=>{
                if(res?.payload){
                    setOppList(res.payload?.lead?.filter((status)=>status?.status === "Open" || status?.status === "WIP"));
                }
            }) 
        }

    }, [state?.operation])

    useEffect(() => {
        if(searchinput.length > 1) {
         let timeout_func = setTimeout(() => {
          dispatch(main_search({searchinput, selectedoption:"Company"})).then((res) => {
            setCompanyList(res.payload.data) 
          })
        }, 1000);
        
        return () => {
            clearTimeout(timeout_func)
        }

        } else {
         setCompanyList([]) 
        }
      }, [searchinput])

    const handleCreateNewFollowup = () => {
        setOpenFollowupPopup(true)
    }

    const handleChangeForFollowup = (event) => {
        // setCreateNewFollowUp({...createNewFollowUp , [event.target.name]: event.target.value})
        // console.log({...createNewFollowUp , [event.target.name]:event.target.value})
    }

    const handleChangeCompanyName = (e, value, reason) => {
        if(value === null){
            setShowLeadDetails(false)
            setShowOpportunityDetails(false)
            setFollowUpFor('')
            setSelectedId('')
            setSearchInput('')
            setCompanyList([])
            setCompanyName('')
            setOppList([])
        }else{
            setCompanyName(value) 
            dispatch(fetchComanyLeads(value.id)).then((res)=>{
                if(res?.payload){
                    setFollowUpFor('')
                    setSelectedId('')
                    setShowLeadDetails(false)
                    setShowOpportunityDetails(false)
                    setOppList(res.payload?.lead?.filter((status)=>status?.status === "Open" || status?.status === "WIP"));
                }
            })
            dispatch(displayContactList(value?.id))
        }
    }
     console.log(followUpFor, 'list')
    const handleChangefollowupFor = (e, value, reason)=>{
        if(Object.keys(companyleads).length > 0 || Object.keys(companyName).length >0){
            if(value === null){
                setShowLeadDetails(false)
                setShowOpportunityDetails(false)
                setFollowUpFor('')
                setSelectedId('')
            }else{
                setFollowUpFor(value);
                if(value.value === "opportunity"){
                    setOppList(companyleads?.opportunity)
                    setShowLeadDetails(false) 
                    setSelectedId('')             
                }else{
                    setOppList(companyleads?.lead?.filter((status)=>status?.status === "Open" || status?.status === "WIP"));
                    setShowOpportunityDetails(false)
                    setSelectedId('')
                }  
            }   
        }else{
            sweetalert("error", "Please Select Company Name")
        }
    }

    const sweetalert = (variant, text) => {  
        Swal.fire({
          icon: variant==='error' ? 'error' : variant==='success'? 'success' :'warning',
          title: text , 
          button: "Ok!",
        }); 
    };

    return (

        <MainContainer>
            {((state?.title ==="Follow-Up List" && state?.operation==="createFollowup")||(state==="CreateFromDashboard") || state?.title==="Alert List")? <></>:<CompanyName style={{ background: '#F3F3F3' }} sx={{ color: '#3107AA', letterSpacing: "0.04em" }} label={state?.title === "companyProfilePage" || state?.title ==="LeadDetailsPage" ? `${state?.companyName?.company_name} ${state?.companyName?.company_type}`:""} />}
            <Grid sx={{borderBottom:'1px solid #e4e4e4', mt:2, ml:{xs:1,sm:1,xl:4,lg:4}, mr:{xs:1,sm:1,xl:4,lg:4}, display:"flex",flexDirection:{lg:'row',sm:'row',xs:'column-reverse'}, justifyContent:{lg:'space-between',sm:'space-between',xs:'start'},alignItems:{lg:'flex-end',md:'flex-end',xs:'center'}}}>    
                <Typography variant='h6' sx={{fontSize:{lg:'22px',md:'20px',sm:'18px'}, fontWeight:600}}>Create Follow-up</Typography>
            </Grid>
          
            <CardBody sx={{mt:showLeadDetails ? 2 :2, pb:4, ml:{xs:1,sm:1,xl:4,lg:4}, mr:{xs:1,sm:1,xl:4,lg:4} }}>
                <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                    {/* <Grid item xs={12} md={2} sm={2} lg={2}>
                        <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Company</Typography>
                    </Grid> */}
                     {state?.title ==="Follow-Up List" || state ==="CreateFromDashboard" || state?.title==="Alert List"?<>
                        <Grid item xs={12} md={4} sm={4} lg={3}>
                            <Typography>Company Name</Typography>
                            <DropDown sx={{ background: '#FFFFFF', fontWeight: '400', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', mt: 1 }}
                                placeholder='search Company'
                                onChange={handleChangeCompanyName}
                                onSearch={(e) =>{
                                    if(e.target.value == '') {
                                        e.preventDefault()
                                        setCompanyList([])
                                    } else {
                                        setSearchInput(e.target.value.trim())
                                    }
                                  }}
                                //value= ''
                                clearOnBlur={true}
                                options={displayname(companyList)}
                                //options={companyList?.map((name) => { return { label: `${name?.company_name} ${name?.company_type}`, value: `${name?.company_name} ${name?.company_type}`, id: name?.id } })}
                                getOptionLabel={(option) => (option.label ? option.label : "")}
                                isOptionEqualToValue = {(option, value) => option.label === value}
                                value={companyName}
                            />
                        </Grid>
                    </>:<></>}
                    {/* <Grid item xs={12} md={3} sm={3} lg={3}>
                        <Typography>Follow-up for</Typography>
                        <DropDown sx={{ background: '#FFFFFF', fontWeight: '400', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', mt: 1 }}
                            id="combo-box-demo"
                            placeholder='Select for'
                            onChange={handleChangefollowupFor}
                            options={followUpLists}
                            getOptionLabel={(option) => (option.label ? option.label : "")}
                            isOptionEqualToValue = {(option, value) => option.label === value}
                            value={followUpFor}
                        />
                    </Grid> */}

                
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <Typography>Select Lead ID</Typography>
                            <DropDown sx={{ background: '#FFFFFF', fontWeight: '400', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', mt: 1 }}
                                placeholder='Select Lead ID '
                                onChange={(e, value, reason) => {
                                    if (value === null) {
                                        setShowLeadDetails(false)
                                        setShowOpportunityDetails(false)
                                        setSelectedId('')  
                                    }else{
                                         console.log(value, 'value')
                                            const LeadDetails = opportunityList?.find((lead)=> lead?.id == value?.value)
                                            setSelectedLeadDetails(LeadDetails)
                                            const findContactName = getContactList?.find((name) =>name?.id == LeadDetails?.contact)
                                            setContactName(findContactName)
                                            const findFollowupStatusForLead = companyleads?.followup?.filter((id) =>id?.followup_for_id === LeadDetails?.id) 
                                            if(findFollowupStatusForLead?.find((status)=> status.closed_on === 0)){ 
                                                sweetalert('warning',`Following lead ${LeadDetails?.lead_no} has open followup, Please close followup then create new.`)
                                                setShowLeadDetails(false)
                                                setSelectedId('')
                                            }else{
                                                setShowLeadDetails(true)
                                                setShowOpportunityDetails(false) 
                                                setSelectedId(value)  
                                                // setSelectedOpportunityDetails('')
                                            }
                                        
                                            // const OpportunityDetails = opportunityList?.find((opportunity)=> opportunity?.opportunity_no == value?.value)
                                            // setSelectedOpportunityDetails(OpportunityDetails)
                                            // console.log(OpportunityDetails,"OpportunityDetails")
                                            // const findContactName = getContactList?.find((name) => name?.id == OpportunityDetails?.contact)
                                            // setContactName(findContactName)
                                            // const findFollowupStatusForOpp = companyleads?.followup?.filter((id) =>id?.followup_for_id === OpportunityDetails?.id) 
                                            // if(findFollowupStatusForOpp?.find((status)=> status.closed_on === 0)){ 
                                            //     sweetalert('warning',`Following Opportunity ${OpportunityDetails?.opportunity_no} has open followup, Please close followup then create new.`)
                                            //     setShowOpportunityDetails(false) 
                                            //     setSelectedId('')
                                            // }else{
                                            //     setSelectedId(value) 
                                            //     setShowOpportunityDetails(true) 
                                            //     setShowLeadDetails(false)
                                            //     // setSelectedLeadDetails('')
                                            // }
                                             
                                    }}}
                                options = {opportunityList?.map((lead) => {return {value: lead?.id , label:lead?.lead_no}})}
                                getOptionLabel={(option) =>(option.label ? option.label :"")}
                                isOptionEqualToValue = {(option, value) => option.label === value}
                                value ={selectedId}   
                            />
                        </Grid>
               

                    {/*    Display Contact name    */}
                    {showLeadDetails ? <>
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <Typography>Contact Name</Typography>
                            <InputBox value={`${contactName?.first_name ?? ''} ${contactName?.last_name ?? ''}`} disabled={true} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} />
                        </Grid></> : <></>}
                </Grid>
              
                {showLeadDetails  ? <>
                    <Box sx={{borderBottom:'1px solid #e4e4e4'}}>
                        <Typography sx={{ fontSize: { lg: '20px', md: '18px', sm: '16px' }, fontWeight: 600, mt:2}}>Lead Details</Typography>
                    </Box></>:<></>}

                {/*    Display Lead Details    */}

                {showLeadDetails ? <>
                    {/*        Lead    */}
                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2} sm={2} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Lead</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Name</Typography>
                                <InputBox value={selectedLeadDetails?.lead_name} disabled={true} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Vodafone limited Mr, Ramesh Jadhav" />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Source</Typography>
                                <InputBox value={selectedLeadDetails?.lead_source} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Parent Organization " />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} l lg={3}>
                                <Typography>Data Source</Typography>
                                <InputBox value={selectedLeadDetails?.data_source} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Mobigic Batabase " />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={0} md={2} sm={2} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Service Provider</Typography>
                                <InputBox value={selectedLeadDetails?.service_provider} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="TTML" />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Product Family</Typography>
                                <InputBox value={selectedLeadDetails?.product_family} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Network Service" />
                                {/* <Typography>{errors.email_address ? errors.email_address : ""}</Typography> */}
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Product</Typography>
                                <InputBox value={selectedLeadDetails?.product} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="ILL" />
                            </Grid>
                        </Grid>
                    </Box>

                    {/*         summary           */}
                    <Box style={{ marginTop: '30px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={2} sm={2} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Summary</Typography>
                            </Grid>
                            <Grid item xs={12} md={10} sm={10} lg={10}>
                                <TextArea value={selectedLeadDetails?.summary} style={{ width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal', background: '#E9E9FF', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }} placeholder="Write a note about Lead."></TextArea>
                            </Grid>
                        </Grid>
                    </Box>
                </>
                    : <></>}

                {/*   Display Opportunity Details    */}
                {showOpportunityDetails ? <>
                    <Box style={{ marginTop: '30px', display: 'flex', flexDirection: { sm: 'column', xs: 'column', lg: 'row' } }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Opportunity</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Name</Typography>
                                <InputBox name='opportunity_name' value={selectedOpportunityDetails?.opportunity_name} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Vodafone limited Mr, Ramesh Jadhav" />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Type</Typography>
                                <InputBox
                                    name='opportunity_type'
                                    value={selectedOpportunityDetails?.opportunity_type}
                                    disabled={true}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Order Type</Typography>
                                <InputBox
                                    name='order_type'
                                    placeholder='--Select Type--'
                                    value={selectedOpportunityDetails?.order_type}
                                    disabled={true}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Expected Date of Closure</Typography>
                                <InputBox
                                    value={selectedOpportunityDetails?.closure_date}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Line Of Business</Typography>
                                <InputBox
                                    name='business_line'
                                    //  options={Business} 
                                    placeholder='--Select Business--'
                                    value={selectedOpportunityDetails?.lineofbusiness}
                                    // label='Select industry'
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Service Provider</Typography>
                                <InputBox
                                    name='service_provider'
                                    value={selectedOpportunityDetails?.service_provider}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                           
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Product Family</Typography>
                                <InputBox
                                    name='product_family'
                                    placeholder='--Select Familly--'
                                    value={selectedOpportunityDetails?.product_family}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Product</Typography>
                                <InputBox
                                    name='product'
                                    placeholder='--Select Product--'
                                    value={selectedOpportunityDetails?.product}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                          
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Service Provider Oppr. ID</Typography>
                                <InputBox name='service_opportunityid' value={Number(selectedOpportunityDetails?.service_opportunityid)} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="123456789" />
                            </Grid>
                        </Grid>
                    </Box>

                    {/*       Commercial        */}
                    {/* <Box style={{ marginTop: '30px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Commercial</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>One Time Charges</Typography>
                                <InputBox name='otc' value={selectedLeadDetails?.otc} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="1000" />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <Typography>Annual Reccuring Charges</Typography>
                                <InputBox name='arc' value={selectedLeadDetails?.arc} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="1000" />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Contract Period(months)</Typography>
                                <InputBox
                                    placeholder='--Select months--'
                                    name='months'
                                    value={selectedLeadDetails?.months}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Billing Frequency<span style={{ color: 'red' }}>*</span></Typography>
                                <InputBox
                                    placeholder='--Select Frequency--'
                                    name='bill_frequency'
                                    value={selectedLeadDetails?.bill_frequency}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <Typography>Billing Type<span style={{ color: 'red' }}>*</span></Typography>
                                <InputBox
                                    name='bill_type'
                                    placeholder='--Select Type--'
                                    value={selectedLeadDetails?.bill_type}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Date of Billing</Typography>
                                <InputBox
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                    value={selectedLeadDetails?.date_of_billing}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Date of Renewal </Typography>
                                <InputBox
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                    value={selectedLeadDetails?.date_of_renewal}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Service ID</Typography>
                                <InputBox name='service_id' value={selectedLeadDetails?.service_id} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="007" />
                            </Grid>
                        </Grid>
                    </Box> */}

                    {/*         summary           */}
                    <Box style={{ marginTop: '30px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={12} sm={12} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Summary</Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} lg={10}>
                                <TextArea name='summary' value={selectedLeadDetails?.summary} style={{ width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal', background: '#E9E9FF', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '5px' }} placeholder="Write a note about Opportunity."></TextArea>
                            </Grid>
                        </Grid>
                    </Box>
                </> : <></>}

            </CardBody>

            {/* <Box sx={{mt:4, mr:{xs:1,sm:1,xl:6,lg:6}, mb:2, display:'flex', justifyContent:'end' }}>
                <CustemBtn onClick={handleCreateNewFollowup} disabled={!selectedId || openFollowupPopup} sx={{borderRadius:"10px",pl:5,pr:5,fontSize:'16px'}} text ='Proceed'/>
            </Box>
            <Box sx={{mt:4, mr:{xs:1,sm:1,xl:6,lg:6}, mb:2, display:'flex', justifyContent:'end' }}>
                <CustemBtn onClick={handleProceed} disabled={!buttonStatus} variant='contained' sx={{borderRadius:"10px",pl:5,pr:5,fontSize:'16px'}} text ='Proceed'/>
            </Box> */}
            {showLeadDetails || showOpportunityDetails ? <>
                <Box sx={{mt:4, mr:{xs:1,sm:1,xl:6,lg:6}, mb:2, display:'flex', justifyContent:'end' }}>
                    <CustemBtn onClick={handleCreateNewFollowup} disabled={!selectedId || openFollowupPopup} variant='contained' sx={{ borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px', backgroundColor: '#7673F6' }} text="Proceed" />
                </Box></> :<></>
            }
            

            {openFollowupPopup && <FollowUpDetails title={state} editFollowupDetails={editFollowupDetails} selectedOpportunityDetails={selectedOpportunityDetails} selectedLeadDetails={selectedLeadDetails} followUpFor={followUpFor} open={openFollowupPopup} setOpenFollowupPopup={setOpenFollowupPopup}/>}
        </MainContainer>
    )
}

export default CreateFollowUp
