import React,{useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Box, Card, Grid, IconButton, Tooltip, Snackbar, CircularProgress, Button} from '@mui/material';
import { ComapanyName, ContactCard, CustemBtn, DeleteIcon, EditIcon} from '../../components/custom';
import AddIcon from '../../components/custom/AddIcon';
import ContactForm from '../ContactForm/ContactForm';
import { useDispatch, useSelector} from 'react-redux';
import { enableDisableContact } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import moment from 'moment';
import { CheckCircle } from '@mui/icons-material';
import {H1FormTitle, T1ButtonText, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import CallIcon from '../../components/custom/CallIcon';
import EmailIcon from '../../components/custom/EmailIcon';
import LeadDetailsContactIcon from '../LeadDetails/LeadDetailsContactIcon';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles } from '@mui/styles';
import AdminRole from '../../adminrole/Adminrole';
import { storeCompayName } from '../../Redux/slices/createCompanySlice';
import LinkedinIcon from '../../components/custom/LinkedinIcon';
import TwitterIcon from '../../components/custom/TwitterIcon';

 const ChildCard = styled('div')({
     backgroundColor:'#FFFFFF',
     borderRadius:'10px',
     boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)',
     display:'flex',
     flexDirection:'row',
     justifyContent:'space-between'
  })
 
const CardInfo = styled('div')({
     display:'flex',
     justifyContent:'space-between',
    //  marginTop:"10px",
})
 
const CardHeaders = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'0.5px solid #BBBBBB',
})

const AddAddressContainer = styled('div')({
    padding: '5px',
    borderRadius: '10px',
    width: 'auto',
    height: '80%', // This ensures the border covers the entire height
    border: '2px dotted var(--dark-grey, #858585)',
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center',
    margin:'5px'
 })

 const responsiveSettings = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3, // Number of cards to display in desktop view
        slidesToSlide: 3 // Number of cards to slide per click
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2, // Number of cards to display in tablet view
        slidesToSlide: 2 // Number of cards to slide per click
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1, // Number of cards to display in mobile view
        slidesToSlide: 1 // Number of cards to slide per click
    }
};


export default function NewProfileContact ({contacts, permissionsDetails, buttonStatus, setButtonStatus,contactStatusChangesFromProfilePage}) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [openModal ,setOpenModal] = useState(false)
    const [editContactDetails, setEditContactDetails] = React.useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const [buttonDisable, setButtonDisable] = useState(false)
    const [displayMsg, setDisplayMsg] = useState('')
    const title ="CreateFromProfilePage"
    
    console.log(contacts,"contactscontacts")

    const contactStatusChanges = (contact) => {
        contactStatusChangesFromProfilePage(contact, setButtonDisable)    
    } 

    const handleLinkedInClick = (contact) => {
        if (contact?.linkdin?.length > 0 && contact?.enabled === true) {
            window.open(contact?.linkdin, '_blank');
        }
    }
    
    const handleTwitterInClick =(contact) =>{
        if (contact?.twitter?.length > 0 && contact?.enabled === true) {
            window.open(contact?.twitter, '_blank');
        }
    }

    const handleCopyEmail = (email) => {
        navigator.clipboard.writeText(email)
          .then(() => {
            setSnackbarOpen(true)
            setDisplayMsg("Email copied")
          })
          .catch((error) => {
            setSnackbarOpen(false)
          });
    }
    
    const handleCopyPhoneNumber = (phone) =>{
        navigator.clipboard.writeText(phone)
        .then(() => {
          setSnackbarOpen(true)
          setDisplayMsg("Phone Number copied")
        })
        .catch((error) => {
          setSnackbarOpen(false)
        });
    }

    // const handleChangeEditContactDetails =(contact) =>{
    //     setOpenModal(true)
    //     setEditContactDetails(contact)
    // }

    const sweetalert = (variant, text) => {
        swal({
          icon: variant==='error' ? 'error' :'success',
          title: text , 
          button: "Ok!",
        }); 
    };
    const data = [
        { name: 'John Doe', age: 30 },
        { name: 'Jane Smith', age: 25 },
        { name: 'John Doe', age: 56 },
        { name: 'Jane Smith', age: 75 },
        { name: 'John Doe', age: 389 },
        { name: 'John Doe', age: 39 },
        { name: 'John Doe', age: 65 },
        { name: 'Jane Smith', age: 15 }
      ];

    return ( 
        <>
           <Box sx={{ padding:'2px', borderRadius:'10px'}}>
                <Grid sx={{ borderBottom: '1px solid #D9D9D9', mt: contacts?.data?.contacts?.length === 0 ? 2:1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Grid item xs={10}>
                        <H3SubHeader sx={{color: themeColor.palette.Primarytext1}}>
                            Contacts : <span style={{color: themeColor.palette.InputLabel}}>{contacts?.data?.contacts?.length}</span>
                        </H3SubHeader>
                    </Grid>
                    <Grid item xs={2} sx={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end'}} >
                        {contacts?.data?.contacts?.length > 0  && ((checkAdminRole && permissionsDetails?.perm_contact_view) || permissionsDetails?.perm_contact_view)? <>
                            <IconButton><Link style={{textDecoration:'none', color:'green', "&:hover": {cursor:'pointer'}}}
                                state={contacts?.data?.company}
                                to="/dashboard/allcontacts"
                            >
                                 <T6TableText sx={{color: themeColor.palette.BrandColor, ":hover":{cursor:'pointer'}}}>See All</T6TableText>
                            </Link></IconButton>
                        </>:<></>}
                        {((checkAdminRole && permissionsDetails?.perm_contact_create) || permissionsDetails?.perm_contact_create) && contacts?.data?.contacts?.length > 0 ?  
                        <Tooltip title="Create Contact" placement="top-start">
                            <IconButton id='create_contact2' onClick={() => {
                            setOpenModal(true)
                            dispatch(storeCompayName())
                            setEditContactDetails({})}}> <svg width="24" height="24"  viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 28.5C22.6274 28.5 28 23.1274 28 16.5C28 9.87258 22.6274 4.5 16 4.5C9.37258 4.5 4 9.87258 4 16.5C4 23.1274 9.37258 28.5 16 28.5Z" stroke="#049E01" stroke-width="2" stroke-miterlimit="10"/>
                            <path d="M11 16.5H21" stroke="#049E01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16 11.5V21.5" stroke="#049E01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> 
                         </IconButton></Tooltip> :<></>}    
                    </Grid>
                </Grid>    
                    
                {/* Create new contact button */}
                {(checkAdminRole && permissionsDetails?.perm_contact_view) || permissionsDetails?.perm_contact_view ? <>
                    {contacts?.data?.contacts?.length === 0 ? 
                        ((checkAdminRole && permissionsDetails?.perm_contact_create) || permissionsDetails?.perm_contact_create) ? 
                        <div style={{marginTop:'10px', width:'auto', padding:'2px', marginight:'2px', background:'#FFFFFF', borderRadius:'10px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', display: 'flex', flexDirection: 'column'}}>
                            <AddAddressContainer>
                                <Grid item xs={12} md={10} sm={10} lg={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>  
                                    <IconButton id='create_contact1' onClick={() => {
                                        setOpenModal(true)
                                        setEditContactDetails({})}}>
                                        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.0011 45.0725C35.3624 45.0725 44.5725 35.8624 44.5725 24.5011C44.5725 13.1398 35.3624 3.92969 24.0011 3.92969C12.6398 3.92969 3.42969 13.1398 3.42969 24.5011C3.42969 35.8624 12.6398 45.0725 24.0011 45.0725Z" stroke="#088F05" stroke-width="3.42857" stroke-miterlimit="10" />
                                            <path d="M15.4297 24.5H32.5725" stroke="#088F05" stroke-width="3.42857" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M24 15.9297V33.0725" stroke="#088F05" stroke-width="3.42857" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </IconButton>  
                                </Grid>
                                <Grid item xs={12} md={10} sm={10} lg={12} >
                                    <T4MedNormalText sx={{color: themeColor.palette.Primarytext1}}>Please Add Contact. </T4MedNormalText>
                                </Grid>
                            </AddAddressContainer>
                        </div>  
                        :<></>
                    : <>
                    {/* Contact is created...    <Grid container spacing={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' */}
                        <div>
                            <Grid container spacing={1} sx={{ display: 'flex',}}>  
                                {contacts?.data?.contacts?.slice(0, 3).map((contact, index) => (
                                    <Grid key={contact.id} item xs={12} sm={6} md={4} lg={4}>
                                        <div key={index} style={{ marginTop: '10px', width: 'auto', padding: '2px', marginRight: '2px', background: '#FFFFFF', borderRadius: '10px', boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)' }}>
                                            <Grid container >
                                                <Grid item xs={2} sx={{p:1}}>
                                                    <LeadDetailsContactIcon />
                                                </Grid>
                                                <Grid item xs={9} sx={{p:1}}>
                                                    <Grid item xs={12}>
                                                        <Tooltip title={`${contact?.first_name} ${contact?.last_name}`} placement="top-start">
                                                            <T1ButtonText sx={{ color: themeColor.palette.primarytext2,
                                                                maxWidth: '250px', 
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                ":hover":{cursor:'pointer'},
                                                                "&:hover": {
                                                                    maxWidth: 'none', 
                                                                } }}>
                                                                    {contact?.first_name} {contact?.last_name}
                                                            </T1ButtonText>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Tooltip title={contact?.designation} placement="top-start">
                                                        <T4MedNormalText sx={{ mt:1, color: themeColor.palette.InputLabel, maxWidth: '250px', 
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            ":hover":{cursor:'pointer'},
                                                            "&:hover": {
                                                                maxWidth: 'none', 
                                                            } }}>{contact?.designation}</T4MedNormalText>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                                {(checkAdminRole && permissionsDetails?.perm_contact_edit) || permissionsDetails?.perm_contact_edit ?<>
                                                    {/* <Typography sx={{color:'#3C8AFF', flex:1, mr:1}} > */}
                                                        <IconButton id='edit_contact1' sx={{p: 0}} onClick={() => {setOpenModal(true)
                                                            dispatch(storeCompayName())
                                                            setEditContactDetails(contact)}}>
                                                            <EditIcon w={20} h={20} />
                                                        </IconButton>
                                                    {/* </Typography>  */}
                                                    </>:<></>}     
                                                </Grid>
                                            </Grid>

                                            <Grid sx={{ml:{ xs: 1, sm: 1, xl: 5, lg: 5}, mr: { xs: 1, sm: 1, xl: 5, lg: 5},display: 'flex', justifyContent: 'space-around' }}>
                                            {/* call icon */}
                                            <Tooltip title={contact?.phone} placement="top-start">
                                                <IconButton onClick={()=> handleCopyPhoneNumber(contact?.phone)}>
                                                    <CallIcon />
                                                </IconButton>
                                            </Tooltip>

                                            {/* email icon */}
                                            <Tooltip title={contact?.email} placement="top-start">
                                                <IconButton onClick={()=> handleCopyEmail(contact?.email)}>
                                                    <EmailIcon />
                                                </IconButton>
                                            </Tooltip>

                                            {/* linkdin icon */}
                                            <Tooltip title={contact?.linkdin?.length === 0 ? 'LinkedIn Link Unavailable':'LinkedIn Profile Link'} placement="top-start">
                                                <IconButton onClick={()=>handleLinkedInClick(contact)}>
                                                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18.5872 18.339H15.9217V14.1622C15.9217 13.1662 15.9015 11.8845 14.5327 11.8845C13.143 11.8845 12.9307 12.9683 12.9307 14.0887V18.339H10.2652V9.75H12.8257V10.9207H12.8602C13.218 10.2457 14.088 9.53325 15.3877 9.53325C18.0885 9.53325 18.588 11.3108 18.588 13.6245L18.5872 18.339ZM7.2547 8.57475C6.3967 8.57475 5.70745 7.88025 5.70745 7.026C5.70745 6.1725 6.39745 5.47875 7.2547 5.47875C8.1097 5.47875 8.8027 6.1725 8.8027 7.026C8.8027 7.88025 8.10895 8.57475 7.2547 8.57475ZM8.5912 18.339H5.9182V9.75H8.5912V18.339ZM19.9207 3H4.5802C3.84595 3 3.25195 3.5805 3.25195 4.29675V19.7033C3.25195 20.4202 3.84595 21 4.5802 21H19.9185C20.652 21 21.252 20.4202 21.252 19.7033V4.29675C21.252 3.5805 20.652 3 19.9185 3H19.9207Z" fill={contact?.linkdin?.length === 0 || contact?.enabled === false ?"#E6E6E6": "#0177B5"}/>
                                                    </svg> */}
                                                    <LinkedinIcon linkedin={contact?.linkdin?.length} enabled={contact?.enabled}/>      
                                                </IconButton>
                                            </Tooltip>

                                            {/* Twitter icon */}
                                            <Tooltip title={contact?.twitter?.length === 0 ? 'Twitter Link Unavailable':'Twitter Profile Link'} placement="top-start">
                                                <IconButton onClick={()=>handleTwitterInClick(contact)}>  
                                                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.4625 2.65605C19.6991 2.99375 18.8895 3.21555 18.0606 3.31411C18.9339 2.79132 19.5874 1.9689 19.8993 1.00005C19.0787 1.48761 18.1805 1.83077 17.2438 2.01461C16.4531 1.17106 15.348 0.693029 14.1918 0.694339C11.8826 0.694339 10.0097 2.56661 10.0097 4.87683C10.0097 5.20458 10.0473 5.52242 10.1176 5.82909C6.64048 5.65404 3.56008 3.99005 1.49678 1.45941C1.12529 2.09767 0.930055 2.82318 0.931045 3.56167C0.931045 5.01259 1.66961 6.29324 2.7915 7.043C2.12738 7.022 1.47789 6.84264 0.897185 6.51973C0.896545 6.5373 0.896545 6.55487 0.896545 6.57148C0.896545 8.5984 2.3382 10.2892 4.252 10.6731C3.89281 10.7703 3.52232 10.8194 3.15022 10.8191C2.87997 10.8191 2.61772 10.7942 2.3628 10.7453C2.89532 12.4065 4.43886 13.6159 6.2696 13.6491C4.78813 14.8118 2.95869 15.4426 1.07543 15.4399C0.742125 15.4402 0.409095 15.4205 0.078125 15.3811C1.99004 16.6102 4.21552 17.2625 6.48842 17.2601C14.1816 17.2601 18.388 10.8875 18.388 5.36111C18.388 5.1803 18.3836 4.99886 18.3756 4.81997C19.1943 4.22845 19.901 3.49567 20.4625 2.65605Z" fill={contact?.twitter?.length === 0 || contact?.enabled === false ?"#E6E6E6": "#0A93E2"}/>
                                                    </svg>  */}
                                                    <TwitterIcon twitter={contact?.twitter?.length} enabled={contact?.enabled} />
                                                </IconButton>
                                            </Tooltip>
                                            </Grid>

                                            {/* <Box paddingLeft ='10px' display="flex" flexDirection="row"  marginTop='0px'> */}
                                            <Grid sx={{display: 'flex', justifyContent: 'end'}}>
                                                {/* <Grid item={8}>
                                                    <H7TablePrimeContent style={{padding:'10px', color: themeColor.palette.InputLabel, marginLeft: '2px' }}>
                                                        Created by: <span style={{ color: themeColor.palette.primarytext2}}>{`${contact?.created_by?.firstname} ${contact?.created_by?.lastname}`}</span>
                                                    </H7TablePrimeContent>
                                                </Grid> */}
                                            
                                                <Grid>
                                                {(checkAdminRole && permissionsDetails?.perm_contact_edit) || permissionsDetails?.perm_contact_edit ?<>
                                                    <Button
                                                        id="validate"
                                                        variant="contained"
                                                        disabled={buttonDisable}
                                                        onClick={()=> contactStatusChanges(contact)} sx={{mt:1, boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)', mr:1, mb:2, height:'22px', textTransform:'none', width:'80px', borderRadius:"24px", fontSize:'12px', backgroundColor:contact?.enabled === false?'#9D9D9D':'#3107AA', "&:hover": {
                                                            boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)',textTransform:'none', cursor:'pointer', backgroundColor: contact?.enabled === false ? '#9D9D9D':'#3107AA'}}}
                                                        >
                                                            {buttonDisable ? <CircularProgress size={20} color="inherit" /> : contact?.enabled === false ? "Disable" :"Enable"}
                                                    </Button>
                                                    
                                                    {/* <CustemBtn variant="contained" onClick={()=> contactStatusChanges(contact)} sx={{mt:1, mr:1, mb:2, height:'22px',width:'80px', borderRadius:"24px", fontSize:'12px', backgroundColor:contact?.enabled === false?'#9D9D9D':'#3107AA', "&:hover": {
                                                        cursor:'pointer', backgroundColor:contact?.enabled === false ? '#9D9D9D':'#3107AA'}}} text={contact?.enabled === false ? "Inactive" :"Active"} 
                                                    /> */}
                                                </>:<></>}
                                                </Grid>
                                            </Grid>
                                            {/* </Box>  */}
                                        </div>
                                
                                    </Grid>                 
                                ))}
                            </Grid>
                        </div>   
                    </>}
                   {/* </Grid>  */}
                </> :<><Typography style={{color:'#f75d4f', margin:'10px'}}>You have no permissions to access this. Please contact your administrator for more information.</Typography></>} 

        {/* =================== open modal for contact details form ================= */}
        {openModal && <ContactForm open ={openModal} setOpenModal={setOpenModal}  
                 title={title} dropdownValues={contacts?.data?.company} editContactDetails={editContactDetails} setEditContactDetails= {setEditContactDetails} />}
            </Box>

        {/*  Display Msg */}
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000} 
            onClose={() => setSnackbarOpen(false)}
            message= {displayMsg}
            action={
            <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
                <CheckCircle />
            </IconButton>
            }
        />
            </>
    )
}

