import React,{useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Card, Grid, Box, IconButton} from '@mui/material';
import { CompanyName, CountCard, ContactCard,AddIcon, DeleteIcon, EditIcon, FollowupCard, Navbar, OpportunityCard, OpportunityIcon, FollowUpIcon, TaskIcon, LeadIcon, OrganizationDetails} from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useLocation , Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import Identification from './Identification';
import Health from './Health';
import { ProfileContact } from './ProfileContact';
import { ProfileAddress } from './ProfileAddress';
import swal from 'sweetalert';
import { enableDisableContact } from '../../Redux/Thunk/createcontact';
import { TailSpin} from 'react-loader-spinner';
import CreateCompanyForm from '../Company/CreateCompanyForm';
import {H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import LeadDetailsCompanyIcon from '../LeadDetails/LeadDetailsCompanyIcon';
import CompanyNameDetails from '../../components/custom/CompanyNameDetails';
import moment from 'moment';
import CompanyCountCard from './CompanyCountCard';
import NewProfileContact from './NewProfileContact';
import NewProfileAddress from './NewProfileAddress';
import AdminRole from '../../adminrole/Adminrole';
import { getIndustryTypesList } from '../../Redux/Thunk/createsettinglist';

const LoginContainer = styled('div')({
    minHeight:'100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    // marginTop: "0.2px",
    // marginBottom:'0.2px',
     marginRight:'10px',
    // padding: "10px",
    // background: '#F9F9F9',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
})

const CardBody = styled('div')({
    backgroundColor:'#FFFFFF',
    padding:'10px',
    borderRadius:'10px',
})

const ChildCard = styled('div')({
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
})

const CardInfo = styled('div')({
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'space-between',  
})

export default function NewCompanyProfile() {
 const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const[getCompanyDetails ,setGetCompanyDetails] = useState({})
  //  const {getCompanyDetails} = useSelector(state => state.company);
  const {refresh, updateEditCompany} = useSelector(state => state.address)
  const {updateContact, updateStatusforContact, displayCreateContact} = useSelector(state => state?.contact)
  const [displayCompanyName ,setDisplayCompanyName]= useState('')
  const [buttonStatus ,setButtonStatus] = useState(false);
  const {updateStatus} = useSelector(state=> state.contact)
  const [openModalForCompanyDetails, setOpenModalForCompanyDetails] = useState(false)
  const [loader, setLoader] = useState(true)
  const [open, setOpen] = useState(false)
  const [openCompanyModel, setOpenCompanyModel] = useState(false)
  const [editCompanyDetails, setEditCompanyDetails] = React.useState({});
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const [update, setUpdate] = useState(false);
  const title = 'CompanyProfilePage'
  // `${getCompanyDetails?.data?.company?.company_name} ${getCompanyDetails?.data?.company?.company_type}`
 
  useEffect(() => {

    fetchCompanyDetails();
    // setGetCompanyDetails({});
    // setButtonStatus(true)
  },[refresh, open, state, updateContact, updateStatus, updateEditCompany, update, updateStatusforContact, displayCreateContact])
  
  console.log(state,"state")
  const fetchCompanyDetails = () =>{
    if(!state || state === 'undefined') {
      navigate('/dashboard')
      return
    } else {
      (open && updateStatus ) ? setLoader(false) : setLoader(true)
      dispatch(displayCompanyDetails(state)).then((res)=>{
        setGetCompanyDetails(res?.payload)
        setDisplayCompanyName(`${res.payload?.data?.company?.company_name} ${res.payload?.data?.company?.company_type}`)
        open ? setLoader(false) : setLoader(false)
        setOpen(false)
      })
    }
  }
  
  const contactStatusChangesFromProfilePage=(contact, setButtonDisable)=>{
    setButtonDisable(true)
    swal({
        buttons: {
          Cancel: {
            text: "Cancel",
            value: "Cancel",
            className: "popup-blue",
          },
          Delete: {
            text: "Ok",
            value: "Ok",
            className: "popup-gray",
          },
        },
        title: `Do you want to ${contact?.enabled === false ?"Enable" : "Disable"} ${contact?.first_name} ${contact?.last_name} ?`,
        className: "custom-modal-user",
        closeOnClickOutside: false,
      }).then((value)=>{
        if(value =='Ok'){
            dispatch(enableDisableContact({contactid: contact?.id, set_contact_status: !contact.enabled})).then((res) => {
              setOpen(true)
              setButtonDisable(false)
            })  
        }else{
          setButtonDisable(false)
            // fetchCompanyDetails()
        }
      })  
  }

  const handleOpenCompanyDetails = ()=>{
    dispatch(getIndustryTypesList())
    setOpenCompanyModel(true)
    setEditCompanyDetails(getCompanyDetails?.data?.company)
  }

    return (
        <>
      { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
        <TailSpin
          height="30"
          width="30"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        /> </Box>:<>
        <LoginContainer>
            {/* Display Image */}
            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                {/* Grid Item 1 */}
                <Grid item xs={12} md={3} sm={3} lg={3}>
                    <CardBody sx={{ mr: { xs: 1, sm: 1, xl: 1, lg: 1 } }}>
                        <CompanyNameDetails getCompanyDetails ={getCompanyDetails?.data} title={title}/>
                        <Box>
                            <Grid sx={{ borderBottom: '1px solid #D9D9D9', mt:2, mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <Grid item xs={10}>
                                    <H3SubHeader sx={{color: themeColor.palette.Primarytext1, LineHeight :"21.94px"}}>
                                        Company Info
                                    </H3SubHeader>
                                </Grid>
                                <Grid item xs={2} sx={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end'}} >
                                    {(checkAdminRole && permissionsDetails?.perm_company_edit) || permissionsDetails?.perm_company_edit ? <IconButton id='edit_company' onClick={handleOpenCompanyDetails} ><EditIcon w={20} h={20} /> </IconButton> :<></>}
                                </Grid>
                            </Grid>

                            <ChildCard sx={{ml:1, mr:1}}>
                                <CardInfo sx={{ pt:1, pl: 1, pr: 2, pb: 0.2}}>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel}}>
                                        CIN
                                    </H7TablePrimeContent>
                                    </CardInfo>

                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2}}>
                                        {getCompanyDetails?.data?.company?.company_cin === "" ? <span style={{color:"#D5D5D5"}}>XXXXXXXXXXXXXXXXXXXXX</span>: getCompanyDetails?.data?.company?.company_cin} 
                                    </T5Normaltext>
                                    </CardInfo>
                                    
                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2}}>
                                    <H7TablePrimeContent style={{flex:1,color: themeColor.palette.InputLabel}}>
                                        TAN
                                    </H7TablePrimeContent>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel }}>
                                        PAN
                                    </H7TablePrimeContent>
                                    </CardInfo>

                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex:1,color: themeColor.palette.primarytext2}}>
                                        {getCompanyDetails?.data?.company?.company_tan=== "" ? <span style={{color:"#D5D5D5"}}>XXXXXXXXXX</span>: getCompanyDetails?.data?.company?.company_tan}
                                    </T5Normaltext>
                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2}}>
                                        {getCompanyDetails?.data?.company?.company_pan===""? <span style={{color:"#D5D5D5"}}>XXXXXXXXXX</span>: getCompanyDetails?.data?.company?.company_pan} 
                                    </T5Normaltext>
                                    </CardInfo>

                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2}}>
                                    <H7TablePrimeContent style={{flex:1,color: themeColor.palette.InputLabel}}>
                                        Turnover (In Cr.)
                                    </H7TablePrimeContent>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel }}>
                                        Turnover Date
                                    </H7TablePrimeContent>
                                    </CardInfo>

                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex:1,color: themeColor.palette.primarytext2}}>
                                        {getCompanyDetails?.data?.company?.company_turnover}
                                    </T5Normaltext>
                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2}}>
                                        {moment(getCompanyDetails?.data?.company?.company_turnoverondate).format('D/M/YYYY')}
                                    </T5Normaltext>
                                    </CardInfo>

                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2}}>
                                    <H7TablePrimeContent style={{flex:1,color: themeColor.palette.InputLabel}}>
                                        Health
                                    </H7TablePrimeContent>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel }}>
                                        Blacklist
                                    </H7TablePrimeContent>
                                    </CardInfo>

                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex:1,color: themeColor.palette.primarytext2}}>
                                        {getCompanyDetails?.data?.company?.company_health}
                                    </T5Normaltext>
                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2}}>
                                        {getCompanyDetails?.data?.company?.company_blacklisted === false? 'NO' : 'Yes'}
                                    </T5Normaltext>
                                    </CardInfo>

                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2}}>
                                        <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel, marginLeft: '2px' }}>
                                            Company Summary
                                        </H7TablePrimeContent>
                                    </CardInfo>
                                    <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                        <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak:'break-all'}}>
                                            {getCompanyDetails?.data?.company?.company_summary}
                                        </T5Normaltext>
                                    </CardInfo>
                            </ChildCard>                  
                        </Box>
                    </CardBody>
                </Grid>

                {/* Grid Item 2 */}
                <Grid item xs={12} md={9} sm={9} lg={9}>
                    <CompanyCountCard getCompanyDetails={getCompanyDetails}/>
                    <NewProfileContact contacts={getCompanyDetails} permissionsDetails={permissionsDetails} contactStatusChangesFromProfilePage={contactStatusChangesFromProfilePage}/>
                    <NewProfileAddress addresses={getCompanyDetails} permissionsDetails={permissionsDetails}/>
                </Grid>

            </Grid>
        </LoginContainer>
       </>}
    
     {/* ===========  create company ======  */} 
        {openCompanyModel && <CreateCompanyForm openCompanyModel ={openCompanyModel} setOpenCompanyModel={setOpenCompanyModel}  
         setUpdate={setUpdate} update={update} setEditCompanyDetails={setEditCompanyDetails} editCompanyDetails={editCompanyDetails} title='companyProfile' />}
        </> 
    )
}