const headCells = [
    {
      id: 'Company Name',
      numeric: false,
      disablePadding: false,
      label: 'Company Name',
    },
    {
      id: 'Company Type',
      numeric: false,
      disablePadding: false,
      label: 'Company Type',
    },
    {
      id: 'Creation Date',
      numeric: false,
      disablePadding: false,
      label: 'Creation Date',
    },
    {
      id: 'Industry Type',
      numeric: false,
      disablePadding: false,
      label: 'Industry Type',
    },
    {
      id: 'Turnover (in Cr)',
      numeric: false,
      disablePadding: false,
      label: 'Turnover (in Cr)',
    },
    {
      id: 'Website',
      numeric: false,
      disablePadding: false,
      label: 'Website',
    },
    {
      id: 'City',
      numeric: false,
      disablePadding: false,
      label: 'City',   
    }
  ];

  export { headCells }