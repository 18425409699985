import { configureStore } from '@reduxjs/toolkit'
import userSlice from '../slices/userSlice'
import createUserSlice from '../slices/createUserSlice'
import createCompanySlice from '../slices/createCompanySlice'
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createContactSlice from '../slices/createContactSlice';
import createLeadSlice from '../slices/createLeadSlice';
import { combineReducers } from '@reduxjs/toolkit';
import createOpportunitySlice from '../slices/createOpportunitySlice';
import createRoleSlice from '../slices/createRoleSlice';
import createAdressSlice from '../slices/createAdressSlice';
import createSettingListSlice from '../slices/createSettingListSlice';
import createTaskSlice from '../slices/createTaskSlice';
import getDashboardData from '../slices/dashboardSlice';
import AlertSlice from '../slices/AlertSlice';

const persistConfig = {
    key: "root",
    storage
}

const reducer = combineReducers({
    auth: userSlice,
    user: createUserSlice,
    company: createCompanySlice,
    contact: createContactSlice,
    lead: createLeadSlice,
    opportunity: createOpportunitySlice,
    role: createRoleSlice,
    address: createAdressSlice,
    settingList: createSettingListSlice,
    task:createTaskSlice,
    dashboard:getDashboardData,
    alert:AlertSlice
})

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
    reducer: persistedReducer
    // {
    //     auth:userSlice,
    //     user:createUserSlice,
    //     company:createCompanySlice,
    //     contact:createContactSlice,
    //     lead: createLeadSlice,
    // }
})
