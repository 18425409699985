import React ,{useState} from "react";
import {Grid, Box, styled, Card, Avatar, Typography, IconButton, InputAdornment, TextField} from '@mui/material';
import {InputBox, Label, Logo, CustemBtn, EyeCloseIcon, EyeOpenIcon} from '../../components/custom';

const LoginContainer = styled('div')({
  // minHeight:'100vh',
  margin: "10px",
  padding: "10px",
  background: '#F8FCFF',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})

const LogoBox = styled('div')({
 marginTop:'30px',
 display:'flex',
 flexDirection:'row',
 alignItems:'center',
 justifyContent:'space-around',
})

const LoginDetails =styled('div')({
  background: '#FFFFFF',
  width: 'auto',
  height: 'auto',
  borderRadius:'10px',
  padding: "20px 20px 32px",
  margin:'20px 50px',
})

const Image = styled('div')({
  '@media (max-width: 992px)': {
      display: 'none', 
      alignItems:'center',
    },
 })
 
const CreatePassword = () =>{
  const [showNewPassword, setShowNewPassword]= useState(true)
  const [showConfirmPassword, setShowConfirmPassword]= useState(true)
  const [password, setPassword] = useState({
    currentPassword:'',
    newPassword:'',
    reEnterPassword:''
  })

  const handleClickShowPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword =() =>{
    setShowConfirmPassword(!showConfirmPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleResetPassword =(event) => {
    setPassword({...password, [event.target.name]:event.target.value.trim()})
  }

  const UpdatePassword = ()=>{

  }

  return(
      <LoginContainer>
        {/* Display Image */}
        <Grid container style={{display:'flex', justifyContent:"center"}}>
        {/* <Grid item xs={12} md={10} sm={10} lg={8}>
            <Image>
              <img  alt="The house from the offer."
                src="/login_image.png" style={{width: '100%', 
                height:'auto',
                maxWidth:'100%'}} />
            </Image>
          </Grid> */}

          {/* Employee Login Details */}
          <Grid item xs={12} md={10} sm={10} lg={4} style={{background:'#EDEDFF', borderBottomRightRadius:'10px', borderTopRightRadius:'10px', width: '100%',height:'auto', maxWidth:'100%'}}>
              <LogoBox>
                <Logo width={172} height={45}/>
              </LogoBox>
              
              <LoginDetails>
                  <Typography sx={{color:'#7673F6', fontWeight:600, fontSize:'20px', letterSpacing:0.5}}>
                    Update Password
                  </Typography>
                  <Typography sx={{color:'#000000', fontSize:'12px', letterSpacing:0.5, mt:2}}>
                    Current Password
                  </Typography>
                  <InputBox type={showNewPassword ?'password': 'text'} value={password?.currentPassword} onchange={handleResetPassword} sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:1, mb:2, pl:1}} placeholder ="Password" endAdornment={
                      <InputAdornment position="end">
                        <IconButton style={{ml:1}}  aria-label="toggle password visibility"
                          onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {showNewPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                       </IconButton> 
                    </InputAdornment>
                  }/>

                  <Typography sx={{color:'#000000', fontSize:'12px', letterSpacing:0.5, mt:1}}>
                    New Password
                  </Typography>
                  <InputBox sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:1, mb:2, pl:1}} placeholder ="Password" endAdornment={
                     <InputAdornment position="end">
                     <IconButton style={{ml:1}} aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword}>
                        {showConfirmPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                    </IconButton> 
                  </InputAdornment> 
                  }/>
                  <Typography sx={{color:'#000000', fontSize:'12px', letterSpacing:0.5, mt:1}}>
                    Re-enter Password 
                  </Typography>
                  <InputBox sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:1,mb:2, pl:1}} placeholder ="Password" endAdornment={
                      <InputAdornment position="end">
                          <IconButton style={{ml:1}}>
                            <EyeCloseIcon />
                          </IconButton>
                      </InputAdornment>
                  }/>
                    <Grid container style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                      <CustemBtn variant='contained' onClick={UpdatePassword} sx={{color:'#FFFFFF', mr:3, fontSize:'16px', fontWeight:'500', borderRadius:'10px', marginTop:'15px', background:'#FF0000',"&:hover": {backgroundColor:"red"}}} text="Cancel"/>
                      <CustemBtn variant='contained' sx={{color:'#FFFFFF', fontSize:'16px', fontWeight:'500', borderRadius:'10px', marginTop:'15px'}} text="Update"/>
                    </Grid>
                
              </LoginDetails> 
          </Grid>  
        </Grid>
      </LoginContainer>
  )}


export default CreatePassword