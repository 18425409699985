import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "./axioshelper";
import axios from "axios";

export const createCompanyAddress = createAsyncThunk('data/createCompanyAddress',
    async(data) => {
    const res= await axiosInstance.post('/createbranch', data);
        return(res.data)
    }
)

export const getCompanyAddress = createAsyncThunk('data/CompanyAddress',
    async(data) => {
    const res= await axiosInstance.post('/createbranch', data);
        return(res.data.data)
    }
)

export const editCompanyAddress = createAsyncThunk('data/editCompanyAddress',
    async(data) => {
    const res= await axiosInstance.post('/branchedit', data);
        return(res.data)
    }
)

export const pinCodeData = createAsyncThunk('data/pincode',
    async(data) => {
    const res= await axios.get(`https://api.postalpincode.in/pincode/${data}`)
        return(res.data)
    }
)

 
