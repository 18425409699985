import React,{useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Box, Card, IconButton, Tooltip} from '@mui/material';
import { ComapanyName,ContactCard, DeleteIcon, EditIcon, FollowupCard, Navbar, OpportunityCard, OpportunityIcon, OrganizationDetails} from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import CountCard from '../../components/custom/CountCard';
import AddIcon from '../../components/custom/AddIcon';
import { Link, useNavigate } from 'react-router-dom';
import CreateAddressPopup from '../Addresspopup/CreateAddresspopup';
import CreateAddressDropdown from '../Addresspopup/CreateAddressDropDown';
import { useDispatch } from 'react-redux';
import { storeCompanyData } from '../../Redux/slices/createCompanySlice';
import AdminRole from '../../adminrole/Adminrole';

const CardBody = styled('div')({
    // backgroundColor:'#F0EFFD',
    padding:'20px',
    borderRadius:'10px',
 })
 
 const ChildCard = styled('div')({
     backgroundColor:'#FFFFFF',
     borderRadius:'10px',
     boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)'
  })
 
 const CardInfo = styled('div')({
     display:'flex',
     justifyContent:'space-between',
     marginTop:"10px",
 })
 
 const CardHeaders = styled('div')({
     display:'flex',
     justifyContent:'space-between',
     borderBottom:'0.5px solid #BBBBBB',
 })

export const ProfileAddress = ({addresses, permissionsDetails}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] =useState(false)
    const [openAddressModel, setOpenAddressModel] = useState(false)
    const [editCompanyDetails, setEditCompanyDetails] = React.useState({});
    const [address, setAddress] = useState({
        label:'',
        value:''
    });
    const route ="CompanyProfilePage"
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
console.log(addresses, 'addresses')
    const seeAllAddressDetails =()=>{
        //  navigate('/dashboard/officeaddress',{state: addresses?.data?.company?.id})
    }

    return ( 
        <>
         {/* backgroundColor:'#FFFCF3' */}
          <div sx={{ padding:'15px', borderRadius:'10px', marginBottom:'30px'}}>   
            <CardHeaders>
                <Typography variant='h6' sx={{color:'#444444s', mb:1, fontSize:'22px', fontWeight:600, letterSpacing:'0.04rem'}}>
                    Addresses
                </Typography>
                {(checkAdminRole && permissionsDetails?.perm_address_create) || permissionsDetails?.perm_address_create ? <Typography sx={{pt:0.1}}>
                    <IconButton onClick={() => {
                         setOpenAddressModel(true)
                        //  setAddress({label:"Registered Office", value:"Registered Office"},{value: 'Head Office', label: 'Head Office'})
                         setEditCompanyDetails({})
                         dispatch(storeCompanyData({})) 
                        }}>
                        <AddIcon/>
                    </IconButton>
                </Typography> :<></> }
            </CardHeaders>
            {(checkAdminRole && permissionsDetails?.perm_address_view) || permissionsDetails?.perm_address_view ? <>
                <ChildCard sx={{pr:1,pl:2, pb:2,pt:2, mt:1}}>
                    <Box sx={{display:'flex',justifyContent:'space-between', borderBottom:'1px solid #EFEFEF',alignItems:'end'}}>
                        <Typography variant='h6' sx={{fontSize:'20px',color:'#7673F6',flex:15,fontWeight:600}}>
                            Registered Office Address
                        </Typography>
                        {addresses?.data?.company?.registered_address === null || undefined  ? <>
                            {(checkAdminRole && permissionsDetails?.perm_address_create) || permissionsDetails?.perm_address_create ? <>
                                {/* <Tooltip title="Add Address" placement="top-start"> */}
                                <Typography sx={{pt:0.2}} onClick={() => {
                                    setOpen(true)
                                    setAddress({label:"Registered Office", value:"Registered Office"})}}>
                                    <AddIcon />
                                </Typography> 
                                {/* </Tooltip>  */}
                                </>:<></>}
                        </> :<>
                        {(checkAdminRole && permissionsDetails?.perm_address_edit) || permissionsDetails?.perm_address_edit ?  
                            <Typography sx={{color:'#3C8AFF',fontSize:'18px',flex:1}} >
                                <IconButton onClick={() => {
                                    setOpen(true)
                                    setEditCompanyDetails(addresses?.data?.company?.registered_address)
                                    setAddress({label:"Registered Office", value:"Registered Office"})}}>
                                    <EditIcon w={20} h={20} />
                                </IconButton>
                            </Typography> :<></>}
                        </>
                        }
                    </Box>
                    {addresses?.data?.company?.registered_address === null|| undefined ? '' :<>
                    <CardInfo sx={{mt:1}}>
                        <Typography sx={{flex:2, fontWeight: 600}}>
                            Address 
                        </Typography>
                    </CardInfo>
                    <CardInfo>
                        <Typography sx={{flex:2, fontSize:'14px', color:'#444444', whiteSpace: 'pre-line', wordBreak:'break-all'}}>
                        {addresses?.data?.company?.registered_address?.address}
                        </Typography>
                    </CardInfo>
                    <CardInfo sx={{mt:2}}>
                        <Typography sx={{flex:2.5, fontWeight: 600}}>
                            Email
                        </Typography>
                        <Typography sx={{flex:2, fontWeight: 600}}>
                            Mobile No.
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            GST No.
                        </Typography>
                    </CardInfo>
                    <CardInfo>
                        <Typography sx={{flex:2.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.registered_address?.email}
                        </Typography>
                        <Typography sx={{flex:2,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.registered_address?.phone}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.registered_address?.gstno}
                        </Typography>
                    </CardInfo>
                    <CardInfo sx={{mt:2}}>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            Country
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            State
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            City
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            Pin Code.
                        </Typography>
                    </CardInfo>
                    <CardInfo >
                        <Typography sx={{flex:1.5,fontSize:'14px',flexWrap:'wrap',color:'#444444'}}>
                            {addresses?.data?.company?.registered_address?.country}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.registered_address?.state}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.registered_address?.city}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.registered_address?.pincode}
                        </Typography>
                    </CardInfo>
                </>}
                </ChildCard>
        
    {/* =================== Head Office ============== */}
    
                <ChildCard sx={{ pl:2, pb:2, pt:2, mt:1}}>
                    <Box sx={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid #EFEFEF',alignItems:'end'}}>
                    <Typography variant='h6' sx={{fontSize:'20px',color:'#7673F6',flex:15,fontWeight:600}}>
                        Head Office Address
                    </Typography>
                        {addresses?.data?.company?.head_office === null || undefined ? <>
                            {(checkAdminRole && permissionsDetails?.perm_address_create) || permissionsDetails?.perm_address_create ? 
                                <Typography sx={{pt:0.2}}> 
                                    <IconButton onClick= {() => {
                                        setOpen(true)
                                        setAddress({value: 'Head Office', label: 'Head Office'})}}>
                                        <AddIcon/>
                                    </IconButton>
                                </Typography>
                                :<></>}
                        </> :<>
                        {(checkAdminRole && permissionsDetails?.perm_address_edit) || permissionsDetails?.perm_address_edit ?  
                        <Typography sx={{color:'#3C8AFF',fontSize:'18px',flex:1}} >
                        <IconButton onClick={() => {
                            setOpen(true)
                            setEditCompanyDetails(addresses?.data?.company?.head_office)
                            setAddress({value: 'Head Office', label: 'Head Office'})}}>
                            <EditIcon w={20} h={20} />
                        </IconButton>
                        </Typography> :<></>}
                        </>
                        }
                    </Box>
                    {addresses?.data?.company?.head_office === null || undefined  ? '' :<>
                    <CardInfo sx={{mt:1}}>
                        <Typography sx={{flex:2, fontWeight: 600}}>
                            Address 
                        </Typography>
                    </CardInfo>
                    <CardInfo>
                        <Typography sx={{flex:2, fontSize:'14px', color:'#444444', whiteSpace: 'pre-line', wordBreak:'break-all'}}> 
                        {addresses?.data?.company?.head_office?.address}
                        </Typography>
                    </CardInfo>
                    <CardInfo sx={{mt:2}}>
                        <Typography sx={{flex:2.5, fontWeight: 600}}>
                            Email
                        </Typography>
                        <Typography sx={{flex:2, fontWeight: 600}}>
                            Mobile No.
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            GST No.
                        </Typography>
                    </CardInfo>
                    <CardInfo>
                    <Typography sx={{flex:2.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.head_office?.email}
                        </Typography>
                        <Typography sx={{flex:2,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.head_office?.phone}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.head_office?.gstno}
                        </Typography>
                    </CardInfo>
                    <CardInfo sx={{mt:2}}>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            Country
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            State
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            City
                        </Typography>
                        <Typography sx={{flex:1.5, fontWeight: 600}}>
                            Pin Code.
                        </Typography>
                    </CardInfo>
                    <CardInfo >
                    <Typography sx={{flex:1.5,fontSize:'14px',flexWrap:'wrap',color:'#444444'}}>
                            {addresses?.data?.company?.head_office?.country}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.head_office?.state}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.head_office?.city}
                        </Typography>
                        <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                            {addresses?.data?.company?.head_office?.pincode}
                        </Typography>
                    </CardInfo> 
                    </>}
                </ChildCard>
            
    {/* ======================== Office address (Branch address) ================== */}
                <ChildCard sx={{p:2, mt:1}}>
                    <Box sx={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid #EFEFEF',alignItems:'end'}}>
                        <Typography variant='h6' sx={{fontSize:'20px',color:'#7673F6',flex:15,fontWeight:600}}>
                            All Office Addresses
                        </Typography>
                        <Box> 
                            <Link style={{textDecoration:'none', color:'green'}}
                                state={addresses?.data?.company}
                                to= '/dashboard/officeaddress'
                            >
                                <Typography fontSize='14px' alignItems='center'>See All</Typography>
                            </Link>
                        </Box>
                    </Box> 
                </ChildCard>
                </> :<><Typography style={{color:'#f75d4f', margin:'10px'}}>You have no permissions to access this. Please contact your administrator for more information.</Typography></>}
         </div>  

        {open && <CreateAddressPopup openAddressModel={open} setOpenAddressModel={setOpen} companyDetails={addresses?.data?.company} 
            title ={route} setAddress={setAddress} addressType={address?.value} setEditCompanyDetails={setEditCompanyDetails} editCompanyDetails={editCompanyDetails } /> 
        }

        {openAddressModel && <CreateAddressDropdown open = {openAddressModel} setOpen = {setOpenAddressModel} companyDetails={addresses?.data?.company} title ={route}/>}
        </>
    )
}
