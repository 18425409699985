import React, { useState, useEffect } from 'react';
import { experimentalStyled as styled, alpha } from '@mui/material/styles';
import {
  Box, Paper, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel,
  Tooltip,
  IconButton, Snackbar
} from '@mui/material';
import CustemBtn from '../../components/custom/CustemBtn';
import { EditIcon, Navbar, InputBox, FilterIcon, OpportunityCard } from '../../components/custom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyList } from '../../Redux/Thunk/createcompanythunk';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { headCells } from './headcells';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Link, useNavigate } from 'react-router-dom';
import FilterCloseIcon from '../../components/custom/FilterCloseIcon';
import PopperFilter from '../../components/custom/PopperFilter';
import { enableDisableContact, getContactList } from '../../Redux/Thunk/createcontact';
import ContactForm from './ContactForm';
import swal from 'sweetalert';
import TableListTitle from '../../components/custom/TableListTitle';
import TableListSearch from '../../components/custom/TableListSearch';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import { CheckCircle } from '@mui/icons-material';
import { H6TableCompany, H7TablePrimeContent, T6TableText } from '../../Theme/theme';
import { storeCompayName } from '../../Redux/slices/createCompanySlice';
import AdminRole from '../../adminrole/Adminrole';

const Item = styled('div')({
  display: 'flex',
  justifyContent: 'center'
})

const CardBody = styled('div')({
  //backgroundColor: '#F0EFFD',
  //padding: '10px',
  //borderRadius: '10px',
})

const Search = styled('div')({
  display: 'flex',
  padding: '0px 10px',
  borderRadius: '10px',
  justifyItems: 'center',
  border: '1px solid #B0AEFF ',
  flexDirection: 'row',
  backgroundColor: '#ffffff',
})

export default function ContactList() {
  const { user } = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch()
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const { updateContact, displayCreateContact } = useSelector(state => state?.contact)
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [contactList, setContactList] = useState([])
  const [openContactModel, setOpenContactModel] = useState(false)
  const [editContactDetails, setEditContactDetails] = React.useState({});
  const [loader, setLoader] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const Title = "Contact List"
  const tooltipTitle = "New Contact"
  const placeholderTitle = "Search Contact"

  useEffect(() => {
    setLoader(true)
    dispatch(getContactList({ start: page * rowsPerPage, search: search, length: rowsPerPage, col_sort: orderBy.split(' ').join(''), order: order.toUpperCase() })).then((res) => {
      // if(res?.payload?.data)
      setContactList(res?.payload?.data)
      setLoader(false)
    })   // search:search
  }, [update, updateContact, displayCreateContact, order])

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setUpdate(!update)
  }

  const CreateNewContact = () => {
    setOpenContactModel(true)
    dispatch(storeCompayName({}))
    setEditContactDetails({})
  }

  const openCompanyProfilePage = (company) => {
    navigate('/dashboard/profile', { state: company?.company?.id })
  }

  // This function is used for edit company details using create company component.
  const handleClickEditContact = (contact) => {
    setOpenContactModel(true)
    dispatch(storeCompayName({}))
    setEditContactDetails(contact)
  };

  const sweetalert = (variant, text) => {
    swal({
      icon: variant === 'error' ? 'error' : 'success',
      title: text,
      button: "Ok!",
    });
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => (
            <>
              <TableCell
                sx={{ fontWeight: 700, fontSize: '12px' }}
                key={headCell.id}
                align={headCell.numeric ? 'center' : 'left'}
                padding={headCell.disablePadding ? 'normal' : 'none'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </>
          ))}

          {(checkAdminRole && permissionsDetails?.perm_contact_edit)|| permissionsDetails?.perm_contact_edit ? <><TableCell sx={{ fontWeight: 600, fontSize: '13px', textAlign: 'left' }}> Edit</TableCell>
            <TableCell sx={{ fontWeight: 600, fontSize: '13px', textAlign: 'left' }}> Access</TableCell></> : <></>}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setUpdate(!update)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUpdate(!update)
  };

  const contactStatusChanges = (contact) => {
    swal({
      buttons: {
        Cancel: {
          text: "Cancel",
          value: "Cancel",
          className: "popup-blue",
        },
        Delete: {
          text: "Ok",
          value: "Ok",
          className: "popup-gray",
        },
      },
      title: `Do You Want to ${contact?.enabled === false ? "Enable" : "Disable"} ${contact?.first_name} ${contact?.last_name} ?`,
      className: "custom-modal-user",
      closeOnClickOutside: false,
    }).then((value) => {
      if (value == 'Ok') {
        dispatch(enableDisableContact({ contactid: contact?.id, set_contact_status: !contact.enabled })).then((res) => {
          setUpdate(!update)
        })
      } else {
        console.log('hell')
      }
    })
  }

  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => {
        setSnackbarOpen(true)
      })
      .catch((error) => {
        setSnackbarOpen(false)
      });
  }

  const handleLinkedInClick = (contact) => {
    if (contact?.linkdin?.length > 0 && contact?.enabled === true) {
      window.open(contact?.linkdin, '_blank');
    }
  }

  const handleTwitterInClick = (contact) => {
    if (contact?.twitter?.length > 0 && contact?.enabled === true) {
      window.open(contact?.twitter, '_blank');
    }
  }

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const dateFormatter = (date) => {
    let formattedDate = new Date(date);
    return formattedDate.getDate() + '/' + formattedDate.getMonth() + '/' + formattedDate.getFullYear();
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt: 1 }}>
        {/* Contact Title */}
        <TableListTitle title={Title} />

        {/* Create new Contact, search bar and filter  */}
        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mb: 0.5, mr: { lg: 4, xs: 1 } }}>
          <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={(checkAdminRole && permissionsDetails?.perm_contact_create)|| permissionsDetails?.perm_contact_create} onClick={CreateNewContact} onchange={handleSearch} />
        </Grid>
      </Grid>

      <CardBody sx={{ mt: 1, ml: { xs: 1, sm: 1, xl: 4, lg: 4 }, mr: { xs: 1, sm: 1, xl: 4, lg: 4 } }}>
        <Paper sx={{ padding: '10px' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              sx={{ minWidth: 750 }}
              size='small'
              stickyHeader aria-label="sticky table">

              <EnhancedTableHead
                numSelected={contactList?.data?.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={contactList?.data?.length}
              />
              {loader ? <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
                <TailSpin
                  height="30"
                  width="30"
                  color="blue"
                  ariaLabel="tail-spin-loading"
                  radius="3"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                /> </Box> : <>
                <TableBody>
                  {/*stableSort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/
                    contactList?.data?.map((contact, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={contact?.first_name}
                          align='left'
                        >
                          <TableCell component="th" id={labelId} scope="row" padding="none" align='left' sx={{ padding: '3px', maxWidth: 160, whiteSpace: 'pre-line', wordBreak:'break-word', fontSize: '12px', fontWeight: 600 }}>
                            <H7TablePrimeContent>
                              {`${contact?.first_name} ${contact?.last_name}`}
                            </H7TablePrimeContent>
                          </TableCell>
                          {/* "&:hover": {overflow: "visible", whiteSpace: "normal", maxWidth:100 },overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap", */}
                          <TableCell align="left" sx={{ padding: '3px', fontWeight: 600, fontSize: '12px', maxWidth: 160, whiteSpace: 'pre-line', color: '#3107AA', "&:hover": { cursor: 'pointer' } }} onClick={() => openCompanyProfilePage(contact)}>
                            <H6TableCompany>
                              {contact?.company?.company_type === "NGO" || contact?.company?.company_type === "OTHERS" || contact?.company?.company_type === "TRUST" || contact?.company?.company_type === "PROPRIETOR" || contact?.company?.company_type === "FOREIGN ENTITY" ? `${contact?.company?.company_name} (${contact?.company?.company_type})` : `${contact?.company?.company_name} ${contact?.company?.company_type}`}
                            </H6TableCompany>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: '3px', fontSize: '12px', maxWidth: 150 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ marginRight: '4px', whiteSpace: 'pre-line', wordBreak: 'break-all', flex: 1 }}>
                                <T6TableText>
                                  {contact?.email}
                                </T6TableText>
                              </span>
                              <span style={{ flexShrink: 0 }}>
                                <Tooltip title="Copy Email" placement="top-start">
                                  <IconButton onClick={() => handleCopyEmail(contact?.email)}>
                                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M3.41653 2.99967V0.999674C3.41653 0.631488 3.71501 0.333008 4.0832 0.333008H12.0832C12.4514 0.333008 12.7499 0.631488 12.7499 0.999674V10.333C12.7499 10.7012 12.4514 10.9997 12.0832 10.9997H10.0832V12.9991C10.0832 13.3676 9.78327 13.6663 9.412 13.6663H1.42111C1.05039 13.6663 0.75 13.3699 0.75 12.9991L0.751733 3.66692C0.7518 3.29841 1.05176 2.99967 1.42295 2.99967H3.41653ZM2.08495 4.33301L2.08346 12.333H8.74987V4.33301H2.08495ZM4.74987 2.99967H10.0832V9.66634H11.4165V1.66634H4.74987V2.99967Z" fill="#676666" />
                                    </svg>
                                  </IconButton>
                                </Tooltip>
                              </span>
                            </div>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: '3px', fontSize: '12px' }}>
                            <T6TableText>
                              {contact?.phone}
                            </T6TableText>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: '3px', fontSize: '12px' }}>
                            <T6TableText>
                              {contact?.dob === 0 ? '-' : moment(contact?.dob).format('D/M/YYYY')}
                            </T6TableText>
                          </TableCell>
                          {/*  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", "&:hover": { cursor: 'pointer', overflow: "visible", whiteSpace: "normal", maxWidth: 100 } */}
                          <TableCell align="left" sx={{ padding: '3px', fontSize: '12px', maxWidth: 160, whiteSpace: 'pre-line'}}>  
                            <T6TableText>
                              {contact?.extendeddesignation?.length > 0 ? `${contact?.designation} (${contact?.extendeddesignation})` : contact?.designation}
                            </T6TableText>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: '3px', fontSize: '12px', maxWidth: 160, whiteSpace: 'pre-line'}}>
                            <T6TableText>
                              {contact?.created_by?.firstname} {contact?.created_by?.lastname}
                            </T6TableText>
                          </TableCell>
                          <TableCell align="left" sx={{ padding: '3px', fontSize: '12px', color: '#7673F6', cursor: contact?.linkdin?.length === 0 ? '' : 'pointer', fontWeight: 600 }}>
                            {/* {contact?.linkdin?.length === 0 ?" ":<Link style={{textDecoration:'none', color:'#2490FF'}} to='/dashboard/contactlist'>Click Here</Link>} */}
                            {/* {contact?.linkdin?.length === 0 ? " " :
                                <a href={contact?.linkdin} style={{ color: "#7673F6", textDecoration: "none" }} target="_blank" rel="noopener noreferrer">
                                    Click Here
                                </a>
                            } */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ marginRight: '4px' }}>
                                <Tooltip title={contact?.linkdin?.length === 0 ? 'Unavailable' : 'LinkedIn Profile Link'} placement="top-start">
                                  <IconButton onClick={() => handleLinkedInClick(contact)}>
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M18.5872 18.339H15.9217V14.1622C15.9217 13.1662 15.9015 11.8845 14.5327 11.8845C13.143 11.8845 12.9307 12.9683 12.9307 14.0887V18.339H10.2652V9.75H12.8257V10.9207H12.8602C13.218 10.2457 14.088 9.53325 15.3877 9.53325C18.0885 9.53325 18.588 11.3108 18.588 13.6245L18.5872 18.339ZM7.2547 8.57475C6.3967 8.57475 5.70745 7.88025 5.70745 7.026C5.70745 6.1725 6.39745 5.47875 7.2547 5.47875C8.1097 5.47875 8.8027 6.1725 8.8027 7.026C8.8027 7.88025 8.10895 8.57475 7.2547 8.57475ZM8.5912 18.339H5.9182V9.75H8.5912V18.339ZM19.9207 3H4.5802C3.84595 3 3.25195 3.5805 3.25195 4.29675V19.7033C3.25195 20.4202 3.84595 21 4.5802 21H19.9185C20.652 21 21.252 20.4202 21.252 19.7033V4.29675C21.252 3.5805 20.652 3 19.9185 3H19.9207Z" fill={contact?.linkdin?.length === 0 || contact?.enabled === false ? "#E6E6E6" : "#0177B5"} />
                                    </svg>
                                  </IconButton>
                                </Tooltip>
                              </span>
                              <span style={{ marginRight: '4px' }}>
                                <Tooltip title={contact?.twitter?.length === 0 ? 'Unavailable' : 'Twitter Profile Link'} placement="top-start">
                                  <IconButton onClick={() => handleTwitterInClick(contact)}>
                                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M20.4625 2.65605C19.6991 2.99375 18.8895 3.21555 18.0606 3.31411C18.9339 2.79132 19.5874 1.9689 19.8993 1.00005C19.0787 1.48761 18.1805 1.83077 17.2438 2.01461C16.4531 1.17106 15.348 0.693029 14.1918 0.694339C11.8826 0.694339 10.0097 2.56661 10.0097 4.87683C10.0097 5.20458 10.0473 5.52242 10.1176 5.82909C6.64048 5.65404 3.56008 3.99005 1.49678 1.45941C1.12529 2.09767 0.930055 2.82318 0.931045 3.56167C0.931045 5.01259 1.66961 6.29324 2.7915 7.043C2.12738 7.022 1.47789 6.84264 0.897185 6.51973C0.896545 6.5373 0.896545 6.55487 0.896545 6.57148C0.896545 8.5984 2.3382 10.2892 4.252 10.6731C3.89281 10.7703 3.52232 10.8194 3.15022 10.8191C2.87997 10.8191 2.61772 10.7942 2.3628 10.7453C2.89532 12.4065 4.43886 13.6159 6.2696 13.6491C4.78813 14.8118 2.95869 15.4426 1.07543 15.4399C0.742125 15.4402 0.409095 15.4205 0.078125 15.3811C1.99004 16.6102 4.21552 17.2625 6.48842 17.2601C14.1816 17.2601 18.388 10.8875 18.388 5.36111C18.388 5.1803 18.3836 4.99886 18.3756 4.81997C19.1943 4.22845 19.901 3.49567 20.4625 2.65605Z" fill={contact?.twitter?.length === 0 || contact?.enabled === false ? "#E6E6E6" : "#0A93E2"} />
                                    </svg>
                                  </IconButton>
                                </Tooltip>
                              </span>
                            </div>
                          </TableCell>

                          {/* Edit permission for edit button and contact enable /disable  */}
                          {(checkAdminRole && permissionsDetails?.perm_contact_edit)|| permissionsDetails?.perm_contact_edit ? <>
                            <TableCell onClick={() => handleClickEditContact(contact)} align="left"><EditIcon w={24} h={24} /></TableCell>
                            <TableCell align="left" sx={{ padding: '3px', fontSize: '12px' }}>
                              <CustemBtn variant="contained" onClick={() => contactStatusChanges(contact)} sx={{
                                borderRadius: "20px", pl: 2, pr: 2, fontSize: '14px', backgroundColor: contact?.enabled === false ? '#9D9D9D':'#3107AA', "&:hover": {
                                  backgroundColor: contact?.enabled === false ? '#9D9D9D':'#3107AA'
                                }
                              }} text={contact?.enabled === false ? "Disabled" : "Enabled"} />
                            </TableCell> </> : <></>}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </>}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={contactList?.recordsTotal ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

      </CardBody>

      {/* Display Msg*/}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message="Email copied"
        action={
          <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
            <CheckCircle />
          </IconButton>
        }
      />

      {/* =================== open modal for contact details form ================= */}
      {openContactModel && <ContactForm open={openContactModel} setOpenModal={setOpenContactModel} title={Title}
        dropdownValues={editContactDetails?.company} editContactDetails={editContactDetails} setEditContactDetails={setEditContactDetails} />}

    </>
  );
}
