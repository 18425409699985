import React from 'react'

const DisplayError = ({error,fortagid}) => {
  return <> 
            {
             error?.map ( (err) => {
                if (err.tagid==fortagid){
                    return <div>
                            <small style={{color:'red'}} > { err.errmsg } </small>
                           </div>
                }
             })
            }
         </>
}

export default DisplayError
