import { Box } from '@mui/material'
import React from 'react'
import { H7TablePrimeContent } from '../../Theme/theme'
import { PreviewIcon } from './Icons'
import themeColor from '../../Theme/themeColor'

const PreviewDoc = ({stage}) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection:'column'}}>
            <a target='_blank' href={`${process.env.REACT_APP_BASE_URL}//${stage?.document?.url}`} >
                <PreviewIcon />
            </a>
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight: 500, fontSize: '10px', mt: 0 }}>
                Preview
            </H7TablePrimeContent>

            <H7TablePrimeContent sx={{ fontWeight: 500, mb:0.5, color: themeColor.palette.Primarytext1 }}>
                {stage?.document?.filename}
            </H7TablePrimeContent>
        </Box>
    )
}

export default PreviewDoc
