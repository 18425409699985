import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";

//Create contact
export const createContactForm = createAsyncThunk('data/createContact',
    async(data) => {
        const res = await axiosInstance.post('/createcontact', data);
         //console.log(res.data)   
         return res.data
    }
)
/**
*  The user selects the company name under display contact names.
*/
export const displayContactList = createAsyncThunk('data/displayContact',

   async (data) => {
    const res= await axiosInstance.get(`/contactlist/${data}`);
    return res.data.data
   }
)

//Edit contact
export const editContact = createAsyncThunk('data/editContact',
   async (data) => {
    const res = await axiosInstance.post(`/contactedit`, data);
    return res.data
   }
)

//contact is enable or disable
export const enableDisableContact = createAsyncThunk('data/contact',
  async (data) =>{
     const res = await axiosInstance.post('/contactstatuschange', data);
     return res.data.data
  }
)

// Display all contact list
export const getContactList = createAsyncThunk('data/contactList',
  async (data) =>{
     const res = await axiosInstance.post('/fetchcontactlist', data);
     return res.data
  }
)
