import React, { useState } from 'react'
import { H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T1ButtonText, T2popupSubText } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor'
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { uploadCOFServiceID, uploadstagedocument } from '../../Redux/Thunk/createopportunitythunk';
import { ApproveBtn, IFParent } from './NewOppoDetailscss';
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, InputBase, TextField, Typography, styled } from '@mui/material';
import { CustemBtn, InputBox, TextArea } from '../../components/custom';
import { Identified, PreviewIcon, QualifiedPending, DragDropIcon, Rejected } from './Icons';
import { DisplayErrorMsgBilling, DisplayErrorMsgCAF, storeBillingStartDate, storeOtherCAFID } from '../../Redux/slices/createOpportunitySlice';
import DisplayError from '../../validation/DisplayError';
import validate from '../../validation/validation';


const CardInfo = styled('div')({
    display:'flex',
  })

const DocumentUpload = ({ name, setRemarkError, opportunity_id, opportunity_stage, disableButton, For, opp_stage, Forreject, req_remark }) => {
    const dispatch = useDispatch()
    const [file, setFile] = useState('')
    const [disabledButton, setDisabledButton] = useState(false)
    const { storeOtherCafID, billingStartDate } = useSelector((state) => state?.opportunity)
    const [loader, setLoader] = useState(false)
    const [reqremark, setReqRemark] = useState('');
    const [error, setError] = useState([]);
    // document upload api  
    console.log(opp_stage, "opp_stage")

    let validation_input = [
        {tagid: 'reqremark', text: Forreject == 'reject' ? req_remark : reqremark , required: true, regex_name: 'free_text', errmsg: 'Please add the request remark between 30 to 1000 characters.', min:30, max:1000 },
    ]

    const handleSolutionDoc = () => {
        if ( (For == 'reject') ? validate(validation_input).length == 0 : true) {
            const formDataObj = new FormData();
            formDataObj.append('opportunity_id', opportunity_id);
            formDataObj.append('opportunity_stage', opportunity_stage);
            formDataObj.append('req_remark', reqremark)
            formDataObj.append('file', file);
            console.log(formDataObj)
            setDisabledButton(true)
            dispatch(uploadstagedocument(formDataObj)).then((res) => {
                if (res.payload.errormsg) {
                    setDisabledButton(false)
                    sweetalert('error', res.payload.errormsg)
                } else {
                    setDisabledButton(false)
                    sweetalert('success', res.payload.data)
                }
            })
        } else {
            setError(validate(validation_input))
        }
    }


    // handling file upload

    const handleFileUpload = (e) => {
        
        const { type, value, files } = e.target;

        let check_format = value.split('.').pop()

         

        if ((For == 'COF' || For == 'Service') ? check_format == 'pdf' : (check_format == 'pdf' || check_format == 'jpg' || check_format == 'jpeg' || check_format == 'png')) {
            const newValue = type === 'file' ? files[0] : value;
            if (newValue.size > 2 * 1024 * 1024) {
                sweetalert('error', 'File size should be less than 2 MB')
            }
            setFile(newValue)
        } else {
            if ((For == 'COF' || For == 'Service')) {
               return sweetalert('error', 'Invalid File Format. Please Upload pdf file.') 
            }
            sweetalert('error', 'Invalid File Format. Please Upload pdf, jpg, jpeg, png files.')
        }
    }

    const handleClickUploadPdf = () => {
       
        if (Forreject == 'reject' ? (validate(validation_input).length != 0) : '') {
          return  setRemarkError(validate(validation_input))
        } 
        const formDataObj = new FormData();
        formDataObj.append('opportunity_id', opportunity_id);
        formDataObj.append('For', For);
        formDataObj.append(For === 'COF' ? 'third_party_cof_id' : 'third_party_service_id', opp_stage?.oppo_details?.commercials?.billed_by !== 'Mobigic' ? storeOtherCafID : For === 'COF' ? '' : opp_stage?.oppo_details?.servicedetails?.service_id)
        formDataObj.append(For === 'Service' ? 'billing_start_date' : '', For === 'Service' ? billingStartDate?.startDate : '');
        formDataObj.append(For === 'Service' ? 'billing_end_date' : '', For === 'Service' ? billingStartDate?.endDate : '');
        formDataObj.append('req_remark', req_remark)
        formDataObj.append('file', file);

        // console.log(formDataObj, "formDataObj")
        setDisabledButton(true)

        if (For === 'Service') {
            if (billingStartDate?.startDate === undefined || billingStartDate?.endDate === undefined || billingStartDate?.startDate?.length === 0 || billingStartDate?.endDate?.length === 0) {
                setDisabledButton(false)
                return dispatch(DisplayErrorMsgBilling({ value: true }))
            }
        }

        if (opp_stage?.oppo_details?.commercials?.billed_by !== "Mobigic") {
            if ((storeOtherCafID === undefined || storeOtherCafID?.length === 0)) {    //&& opp_stage?.oppo_stages?.length === 0
                setDisabledButton(false)
                return dispatch(DisplayErrorMsgCAF({ value: true }))
            }
        }

        setLoader(true)     
  

        dispatch(uploadCOFServiceID(formDataObj)).then((res) => {
            if (res.payload.errormsg) {
                sweetalert('error', res.payload.errormsg)
                // dispatch(storeOtherCAFID(''))
                // dispatch(storeBillingStartDate({}))
                setDisabledButton(false)
                dispatch(DisplayErrorMsgCAF({ value: false }))
                dispatch(DisplayErrorMsgBilling({ value: false }))
                setLoader(false)
            } else {
                sweetalert('success', res.payload.data)
                dispatch(storeOtherCAFID(''))
                dispatch(storeBillingStartDate({}))
                setDisabledButton(false)
                setLoader(false)
            }
        })
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };

    const handleRequestRemark =(e) => {
        setError([])
        setReqRemark(e.target.value)
    } 

    return (
        <IFParent sx={{ justifyContent: 'start', flexDirection: 'column', borderBottom: disableButton === 'ServiceID' ? '' : '0.5px solid #AAAA' }}>
            {/* <Box sx={{display:'flex'}}>
<H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight:500}}>
Feasibility Id :
</H7TablePrimeContent>
<InputBox placeholder='add ID' sx={{height:'20px',border:'0.5px solid #333333', marginLeft:'20px'}} name='id' />
</Box> */}
            <T2popupSubText>
                {name}
            </T2popupSubText>
            <Box>
                {/* <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight: 500 }}>
                <InputBox type='file' >
                    <DragDropIcon  type="file"
                    onChange={handleFileUpload} /> 
                </InputBox>      */}

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="fileInput" id='input' onChange={handleFileUpload}>
                        <IconButton component="span" >
                            <DragDropIcon />
                        </IconButton>
                        <InputBase
                            type="file"
                            id="fileInput"
                            style={{visibility: 'hidden'}}
                            // onChange={handleFileUpload}   
                         ></InputBase>
                    </label>
                    {file && <Typography>{file?.name}</Typography>}
                    
                </Box>
                {/* </H7TablePrimeContent > */}
            </Box>
            {
                For == 'reject' ? <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                            Request Remark<span style={{ color: 'red' }}>*</span>
                        </H7TablePrimeContent>
                    </Box>
                    <CardInfo sx={{ pr: 1, pb: 0.2 }}>
                        <TextArea onChange={handleRequestRemark} name='reqremark' value={reqremark?.replace(/\s\s+/g, " ")} style={{ fontFamily: 'Montserrat', marginTop: '5px', fontStyle: 'normal', width: '100%', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', border: '0.5px solid #B0AEFF', background: '#FEFEFE', p: 0.1, paddingLeft: '10px' }} placeholder="Write a note about reject remark."></TextArea>
                    </CardInfo>
                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginLeft: 'auto', fontSize: '12px' }}>
                        <span>{reqremark.length}/1000</span>
                    </div>
                    <DisplayError error={error} fortagid='reqremark' />
                </>:''
            }
            {/* <Button
                id="validate" variant="contained" onClick={() => disabledButton ? '': disableButton === 'ServiceID' ? handleClickUploadPdf() : handleSolutionDoc()}
                sx={{
                    color:themeColor.palette.BrandColor, 
                    backgroundColor:'#FFFFFF',
                    ":hover":{cursor:'pointer', boxShadow:'none', backgroundColor:'#FFFFFF'},
                    display: 'flex',
                    padding: '4px 0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                    flex: '1 0 0',
                    borderRadius: '8px',
                    border: '1px solid #3107AA',
                    boxShadow:'none',
                    textTransform: 'none', 
                    marginBottom: '5px'
                }}
                >
                {disabledButton ? <CircularProgress size={20} color="inherit" /> : 'Upload Doc & send Req.for Approval'}
            </Button> */}
            {disableButton === 'ServiceID' ? <>
                <Box>
                    <Button
                        id="validate" variant="contained" disabled={disabledButton} onClick={() => disabledButton ? '' : handleClickUploadPdf()}
                        sx={{
                            backgroundColor: disabledButton ? themeColor.palette.backgroundColor : '',
                            borderRadius: "10px", pl: 1, pr: 1, fontSize: '14px',
                            textTransform: 'none',
                        }}
                    >
                        {loader ? <CircularProgress size={20} color="inherit" /> : 'Upload Doc & send Req.for Approval'}
                    </Button>
                </Box>
            </> :
                <ApproveBtn onClick={() => disabledButton ? '' : handleSolutionDoc()} sx={{ ":hover": { cursor: 'pointer' }, backgroundColor: disabledButton ? themeColor.palette.backgroundColor : '', color: themeColor.palette.BrandColor, marginBottom: '5px' }}>
                    {disabledButton ? <CircularProgress size={20} color="inherit" /> : 'Upload Doc & send Req.for Approval'}
                </ApproveBtn>}

        </IFParent>
    )
}

export default DocumentUpload
