import React from 'react'

const DeleteIcon = ({w,h}) => {
  return (
    <svg width={w} height={h} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5938 5.96875H4.40625" stroke="#FF0000" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6562 10.6562V16.9062" stroke="#FF0000" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.3438 10.6562V16.9062" stroke="#FF0000" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.9316 6.18164V21.7292C19.9316 21.9284 19.9316 22.2754 19.9316 22.2754C19.9316 22.2754 19.5733 22.2754 19.3675 22.2754H6.88677C6.88677 22.2754 6.74574 22.2754 6.18164 22.2754M6.18164 22.2754C6.52811 22.2754 6.39318 22.2754 6.18164 22.2754ZM6.18164 22.2754L6.18164 6.18164" stroke="#FF0000" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.1451 5.96821V4.71067C16.1451 4.37715 16.0126 4.05729 15.7768 3.82145C15.541 3.58562 15.2211 3.45313 14.8876 3.45312H11.115C10.7814 3.45313 10.4616 3.58562 10.2257 3.82145C9.98991 4.05729 9.85742 4.37715 9.85742 4.71067V5.96821" stroke="#FF0000" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default DeleteIcon
