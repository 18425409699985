import * as React from 'react';
import { styled, Box, InputBase, Button } from '@mui/material';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));


const CardHeaders = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #EFEFEF'
})

const CardBody = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: "10px"
})

const CardData = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex:1,
})

export default function Identification({getCompanyDetails}) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{borderRadius: '10px', boxShadow: '0px 4px 20px #e4e4e4'}}>
            <Box sx={{  pl:2.5,pr:2.5,pt:1.5,pb:2}}>
                <CardHeaders>
                    <Typography sx={{ color: '#F16500', fontSize: '20px',fontWeight:"600", letterSpacing:'0.04rem' }}>
                       Identification
                    </Typography>
                </CardHeaders>

                <CardBody>
                    <CardData>
                        <Typography sx={{ color: "#444444" }}>
                            TAN
                        </Typography>
                        <Typography sx={{ color: "#3107AA", fontSize: "16px", padding: '5px 0px' }}>
                            {getCompanyDetails?.data?.company?.company_tan===""? <span style={{color:"#D5D5D5"}}>XXXXX1234X</span>: getCompanyDetails?.data?.company?.company_tan}  
                        </Typography>
                    </CardData>
                    <CardData>
                        <Typography sx={{ color: "#444444" }}>
                            PAN
                        </Typography>
                        <Typography sx={{ color: "#3107AA", fontSize: "16px", padding: '5px 0px' }}>
                            {getCompanyDetails?.data?.company?.company_pan===""? <span style={{color:"#D5D5D5"}}>XXXXX1234X</span>: getCompanyDetails?.data?.company?.company_pan} 
                        </Typography>
                    </CardData>
                </CardBody>
                <CardBody>
                    <CardData>
                        <Typography sx={{ color: "#444444" }}>
                           CIN
                        </Typography>
                        <Typography sx={{ color: "#3107AA", fontSize: "16px", padding: '5px 0px' }}>
                            {getCompanyDetails?.data?.company?.company_cin===""? <span style={{color:"#D5D5D5"}}>U21091KA2019OPC141331</span>: getCompanyDetails?.data?.company?.company_cin} 
                        </Typography>
                    </CardData>
                </CardBody>
            </Box>
        </Card>
    );
}