import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Stack ,TextField,Box} from '@mui/material';

export default function DatePickerOpenTo({onBlur, onClose, onFocus, disabled, disableOpenPicker, sx,open, openTo,read_only, onChange, maxDate, minDate, name, defaultValue, value, style, views}) {
  return (
    <Box style={{...style}} >
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          open={open}
          openTo={openTo}
          views={views}
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={onChange}
          name={name}
          sx={sx}
          defaultValue={defaultValue}
          disabled={disabled}
          onClose={onClose}
          maxDate={maxDate} 
          minDate={minDate}
          renderInput={(params) => <TextField  {...params}
          sx={{
            "& .MuiInputBase-input": {
              height: "8px" ,// Set your height here.
            },
            width:'-webkit-fill-available',
            '& fieldset': {
              borderRadius: '8px',
              border:"1px solid #B0AEFF",
              
            },   
          }}
            inputProps={{ ...params.inputProps, readOnly: true }}
          />}
        />
    </LocalizationProvider>
    </Box>
  );
}