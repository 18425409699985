import React, { useState, useEffect } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Box, Grid, Card, IconButton, Tooltip } from '@mui/material';
import { CustemBtn, InputBox, SelectDropDown, TaskIcon, TextArea } from '../../components/custom';
import { useDispatch, useSelector } from 'react-redux';
import { displayContactList, enableDisableContact } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import { CreateEditFollowup } from '../../Redux/Thunk/createsettinglist';
import DisplayError from '../../validation/DisplayError';
import validate from '../../validation/validation';
import FollowUpDetails from '../FollowUp/FollowUpDetails';
import { LeadFollowupDetails } from './LeadFollowupDetails';
import { leaddetails } from '../../Redux/Thunk/createleadthunk';
import moment from 'moment';
import CallIcon from '../../components/custom/CallIcon';
import MeetingIcon from '../../components/custom/MeetingIcon';
import EmailIcon from '../../components/custom/EmailIcon';
import DemoIcon from '../../components/custom/DemoIcon';
import FollowupTaskIcon from '../../components/custom/FollowupTaskIcon';
import {H1FormTitle, T6TableText, H5FormLabel, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'

const ChildCard = styled('div')({
    // backgroundColor: '#f7fdff',
    padding:'10px 20px 20px',
    borderRadius:'10px',
    marginBottom:'10px',
    marginTop:'2px'
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

const callResponces =[
    { value:'Contacted Call Back',label:"Contacted Call Back"}, { value:'Contacted Discussed',label:"Contacted Discussed"}, { value:'Next',label:"Next"},{ value:'No Response',label:"No Response"},{ value:'No Valid Number',label:"No Valid Number"},{ value:'Not Contactable',label:"Not Contactable"}
    ,{ value:'Not Reachable',label:"Not Reachable"}, { value:'Phone Switched Off',label: "Phone Switched Off"}
]

export const FollowupDetail = ({title, followupInformation, displayLeadDetails, CompanyName}) => {
    const [refresh, setRefresh] = useState(false)
    // const [followup, setFollowup] = useState(Followup)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [error, setError] = useState([])
    const [callResponce, setCallResponce] = useState('')
    const leaddetails = useSelector((state) => state.lead.leaddetail)
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    // const followup = leaddetails?.getfollowup?.map((followup) =>followup)
    const followup = (leaddetails?.getallClosedfollowup??[])?.map((followup) =>followup)
   console.log(displayLeadDetails,"displayLeadDetails")
   console.log(followup,"followup")
    const momentObj = moment(followup?.followup_date);
    const currentDate = new Date()

    const openOpportunityDetailsPage = () =>{
        navigate('/dashboard/opportunitydetails', {state: {oppDetails: leaddetails?.getoppodetails?.id}})
    }

    return (
        <>
        {followup?.map((followup)=>(
             <>
            <Card style={{ flex: 1, marginTop: '10px', marginBottom:'10px', borderRadius: '10px', backgroundColor:'rgba(195, 199, 36, 0.10)' }}>
            {/* {followup?.length > 0 ? <> */}
            
                <ChildCard sx={{ p: 2}}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={{ lg: 2, md: 2, sm: 2, xs: 2 }}>
                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}> Follow-up ID</H7TablePrimeContent> 
                            <H5FormLabel sx={{mt:0.5, color: themeColor.palette.FollowupID}}>{followup?.followup_no}</H5FormLabel>  
                        </Grid>
                        {/* <Grid item xs={12} md={4} sm={2} lg={2}>
                            <Typography sx={{fontWeight:600,fontSize:'12px'}}>Lead ID</Typography> 
                            <Typography sx={{mt:0.2, color:'#4E3636', fontSize:'16px'}}>{displayLeadDetails?.leaddetails?.lead_no}</Typography>  
                        </Grid> */}

                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}> Scheduled Date & Time</H7TablePrimeContent> 
                            <T5Normaltext sx={{mt:0.5, color: themeColor.palette.primarytext2}}>{moment(followup?.followup_date).format('dddd DD MMM YYYY')} {moment(followup?.followup_date).format("hh:mm A")}</T5Normaltext>  
                        </Grid>
                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}>Type</H7TablePrimeContent> 
                            <T5Normaltext sx={{ mt:0.2}}>
                                    <Tooltip title={followup?.followup_type ==="Call" ?
                                        "Call": followup?.followup_type ==="Meeting"? "Meeting": followup?.followup_type ==="Email"? "Email":"Demo" } placement="top-start">
                                        <IconButton id='followup_type'>
                                        {followup?.followup_type === "Call" ?
                                        <CallIcon/> : followup?.followup_type ==="Meeting"? <MeetingIcon/>:followup?.followup_type ==="Email"? <EmailIcon/>: <DemoIcon/>}
                                        </IconButton>
                                    </Tooltip><span style={{color: themeColor.palette.primarytext2}}>{followup?.followup_type ==="Call" ?
                                        "Call": followup?.followup_type ==="Meeting"? "Meeting": followup?.followup_type ==="Email"? "Email":"Demo" }</span>
                            </T5Normaltext> 
                              
                        </Grid>
                    </Grid>

                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={{ lg: 2, md: 2, sm: 2, xs: 2 }}>
                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}> Created By</H7TablePrimeContent> 
                            <T5Normaltext sx={{mt:0.5, color: themeColor.palette.primarytext2}}>{followup?.created_by?.firstname} {followup?.created_by?.lastname}</T5Normaltext>  
                        </Grid>
                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}> Assigned To</H7TablePrimeContent> 
                            <T5Normaltext sx={{mt:0.5, color: themeColor.palette.primarytext2}}>{followup?.assigned_to?.firstname} {followup?.assigned_to?.lastname}</T5Normaltext>  
                        </Grid>
                        <Grid item xs={12} md={4} sm={2} lg={4}></Grid>
                    </Grid>

                        {followup?.followup_type ==="Call" && followup?.summary !=='' ? <>
                        <Box style={{ marginTop: '0px', display: 'flex', flexDirection: 'row' }}>
                            <Grid item xs={12} md={4} sm={4} lg={6}>
                                <H7TablePrimeContent sx={{mt:1, color: themeColor.palette.InputLabel}}>Call response</H7TablePrimeContent>
                                <T5Normaltext sx={{mt:0.5, color: themeColor.palette.primarytext2}}>{followup?.call_response}</T5Normaltext>
                            </Grid>
                        </Box>
                        </>:<></>}

                        {followup?.summary ==='' ?<></> :<>   
                        {((currentDate > new Date(followup?.followup_date) && followup?.summary ==='') || (followup?.missing_deadline_mark !=='' && followup?.summary !=='')) ? <Box style={{display: 'flex', flexDirection: 'row', paddingRight:'15px',}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <H7TablePrimeContent sx={{mt:2, color: themeColor.palette.InputLabel}}>Missing Deadline Remark</H7TablePrimeContent>
                                <T5Normaltext sx={{mt:0.5, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak:'break-all'}}>{followup?.missing_deadline_mark}</T5Normaltext>  
                            </Grid>
                            </Grid>
                        </Box> : ''
                        }    
                
                        <Grid container sx={{mt:2, display: 'flex',justifyContent: 'space-between' }} spacing={{ lg: 2, md: 2, sm: 2, xs: 2 }}>
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <H7TablePrimeContent sx={{mt:1, color: themeColor.palette.InputLabel}}>{followup?.lead_action === 'Lost' ? "Lost Remark" :"Closing Remark"}</H7TablePrimeContent>
                                <T5Normaltext sx={{mt:0.5, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak:'break-all'}}>{followup?.summary}</T5Normaltext>  
                            </Grid>
                        </Grid>
                    </>}

                <Grid item xs={12} md={12} sm={12} lg={12}>
                    <H7TablePrimeContent sx={{mt:2, color: themeColor.palette.InputLabel}}>Follow-up was Closed on <span style={{color: themeColor.palette.FollowupID}}>{moment(followup?.closed_on).format('dddd DD MMM YYYY')}</span> with option <span id='convert' onClick={()=> followup?.lead_action === 'Convert to Opportunity'? openOpportunityDetailsPage() :''} style={{cursor: followup?.lead_action === 'Convert to Opportunity'? 'pointer' :'', color: followup?.lead_action === 'Lost'? themeColor.palette.PrimaryRed: themeColor.palette.FollowupID}}>{followup?.lead_action === 'Lost' ? 'Lost': followup?.lead_action === 'New Followup Create' ? 'New Followup Create':`Convert to Opportunity (${leaddetails?.getoppodetails?.opportunity_no}).`}</span></H7TablePrimeContent>
                </Grid>
                </ChildCard>

            </Card>
            </>))}
        </>
    )
}