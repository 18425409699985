import React from 'react'

const WhiteContactIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.75 13.5C14.4069 13.5 15.75 12.1569 15.75 10.5C15.75 8.84315 14.4069 7.5 12.75 7.5C11.0931 7.5 9.75 8.84315 9.75 10.5C9.75 12.1569 11.0931 13.5 12.75 13.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 10.125H5.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 6.375H5.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 13.875H5.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3 17.625H5.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.25 15.75C8.77395 15.0514 9.45336 14.4844 10.2344 14.0938C11.0155 13.7033 11.8767 13.5 12.75 13.5C13.6233 13.5 14.4845 13.7033 15.2656 14.0938C16.0466 14.4844 16.726 15.0514 17.25 15.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.25 20.25V3.75C20.25 3.33579 19.9142 3 19.5 3L6 3C5.58579 3 5.25 3.33579 5.25 3.75L5.25 20.25C5.25 20.6642 5.58579 21 6 21H19.5C19.9142 21 20.25 20.6642 20.25 20.25Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default WhiteContactIcon
