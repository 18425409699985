import { Typography } from "@mui/material";

export default function NotificationIcon({ w, h, count }) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="50" viewBox="0 0 32 32" fill="none">
                <path d="M7.02538 13.0001C7.02372 11.8146 7.25662 10.6405 7.71067 9.54534C8.16472 8.45021 8.83094 7.45573 9.67098 6.61918C10.511 5.78263 11.5083 5.12054 12.6053 4.67105C13.7023 4.22156 14.8774 3.99354 16.0629 4.00014C21.0129 4.03764 24.9754 8.15014 24.9754 13.1126V14.0001C24.9754 18.4751 25.9129 21.0751 26.7379 22.5001C26.8255 22.6519 26.8717 22.824 26.8719 22.9993C26.872 23.1745 26.8261 23.3467 26.7388 23.4986C26.6514 23.6505 26.5257 23.7768 26.3741 23.8648C26.2226 23.9529 26.0506 23.9995 25.8754 24.0001H6.12538C5.95015 23.9995 5.77815 23.9529 5.62662 23.8648C5.47509 23.7768 5.34935 23.6505 5.262 23.4986C5.17465 23.3467 5.12875 23.1745 5.12891 22.9993C5.12906 22.824 5.17526 22.6519 5.26288 22.5001C6.08788 21.0751 7.02538 18.4751 7.02538 14.0001V13.0001Z" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                
                <path d="M12 24V25C12 26.0609 12.4214 27.0783 13.1716 27.8284C13.9217 28.5786 14.9391 29 16 29C17.0609 29 18.0783 28.5786 18.8284 27.8284C19.5786 27.0783 20 26.0609 20 25V24" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
               
               
                <text x="15" y="3" font-family="Arial" font-size="16" fill="#3107AA" fontWeight ={600} >
                    {count}
                </text>
               
            </svg>

        </>
    )
}