import { createSlice } from '@reduxjs/toolkit'
import { createCompanyAddress, editCompanyAddress } from '../Thunk/createcompanyaddressthunk'

const initialState = {
    companyaddress : null,
    refresh: false,
    editCompanyDetails:{},
    updateEditCompany: false,
    createAddressDetails:{},
   
}

export const createUserSlice = createSlice({
          name:'createduser',
          initialState,
          reducers:{
           
          },
          extraReducers:(builder)=>{
            builder.addCase(createCompanyAddress.fulfilled,(state,action)=>{
                 state.createduser = action.payload
                 if(!action.payload.errormsg) {
                  state.refresh = ! state.refresh
                 }
            })

            builder.addCase(editCompanyAddress.fulfilled,(state,action)=>{
              state.editCompanyDetails = action.payload
               if(!action.payload.errormsg) {
                state.updateEditCompany = ! state.updateEditCompany
               }
            })
         },
})

export default createUserSlice.reducer
