import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box, Grid, Typography } from '@mui/material';


const IFParent = styled(Typography)(({ theme }) => ({
    display:'flex',
     justifyContent:'space-between',
    alignItems:'end',
    paddingLeft:'10px'
}));

const Sidebar = styled(Grid)(({ theme }) => ({
//    background:'#ffffff',
   padding:'10px',
   marginTop:'15px',
//    height:'200px',
   borderRadius:'10px'
}));

const SidebarContent = styled(Grid)(({ theme }) => ({
  
 }));


 export { IFParent, Sidebar, SidebarContent }
