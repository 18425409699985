import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";


export const createRole = createAsyncThunk('data/createrole',
    async(data)=>{
    const res = await axiosInstance.post('/createrole', data);
     return res.data
    } 
)

export const getUserRole = createAsyncThunk('data/getUserRole',
    async(data)=>{
    const res = await axiosInstance.get(`/getsinglerole/${data}`);
     return res.data
    } 
)

export const getAllRoles = createAsyncThunk('data/getallRole',
    async()=>{
    const res = await axiosInstance.get(`/getallroles`);
     return res.data.data
    }  
)

