import React,{useState, useEffect} from 'react';
import { experimentalStyled as styled, alpha } from '@mui/material/styles';
import {Box, Paper, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, } from '@mui/material';
import CustemBtn from '../../components/custom/CustemBtn';
import { EditIcon, Navbar, InputBox, FilterIcon, OpportunityCard } from '../../components/custom';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyList } from '../../Redux/Thunk/createcompanythunk';
import EditCompanypopup from '../Editcompany/EditCompanypopup';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { headCells } from './headcells';
import CreateCompanyForm from './CreateCompanyForm';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import TableListSearch from '../../components/custom/TableListSearch';
import TableListTitle from '../../components/custom/TableListTitle';
import { TailSpin} from 'react-loader-spinner';
import moment from 'moment';
import { H6TableCompany, T6TableText } from '../../Theme/theme';
import AdminRole from '../../adminrole/Adminrole';
import { getIndustryTypesList } from '../../Redux/Thunk/createsettinglist';

const Item = styled('div')({
  display:'flex',
  justifyContent:'center'
})

const CardBody = styled('div')({
  //  backgroundColor:'#F0EFFD',
  //  padding:'10px',
  //  borderRadius:'10px',
})

const Search = styled('div')({
  display:'flex',
  padding:'0px 10px',
  borderRadius:'10px',
  justifyItems:'center',
  border:'1px solid #B0AEFF ',
  flexDirection:'row',
  backgroundColor:'#ffffff',
})

export default function CompanyList() {
  const {user} = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch()
  const { getcompanylist } = useSelector((state)=>state?.company)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('')
  const [loader, setLoader] = useState(false)
  const [openCompanyModel, setOpenCompanyModel] = useState(false)
  const [editCompanyDetails, setEditCompanyDetails] = React.useState({});
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const Title= "Company List"
  const tooltipTitle = "New Company"
  const placeholderTitle = "Search Company"

  useEffect(() => {
    getCompanyData()
  },[update, order])

  const handleSearch = (e) => {
    setSearch (e.target.value)
    setUpdate(!update)
  }
  
  function getCompanyData(){
    setLoader(true)
    dispatch(getCompanyList({start:page*rowsPerPage, length:rowsPerPage , search:search, col_sort:orderBy.split(' ').join(''), order:order.toUpperCase() })).then((res) =>{
      setLoader(false)
    })
  }

  const CreateNewCompany = () => {
    setOpenCompanyModel(true)
    setEditCompanyDetails({})
    // navigate('/dashboard/company')
  }

  // This function is used for edit company details using create company component.
  const handleClickEditCompany = (company) => {
    // dispatch(getIndustryTypesList())
    setOpenCompanyModel(true)
    setEditCompanyDetails(company)
    // navigate('/dashboard/company',{state: {company:company, operation:'companyList'}})
  };

  const openCompanyProfilePage= (company)=>{
    navigate('/dashboard/profile',{state: company?.id})
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const {user} = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => (
            <>
            <TableCell
              sx ={{ fontWeight:700, fontSize:'13px' }}
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'normal' : 'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            </>
          ))}
          {(checkAdminRole && permissionsDetails?.perm_company_edit) || permissionsDetails?.perm_company_edit ? <TableCell align ='left' sx ={{fontWeight:600, fontSize:'13px', padding:'0px'}}>Edit</TableCell> :<></>}
        </TableRow>
      </TableHead>
    );
  }

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

const handleChangePage = (event, newPage) => {
  setPage(newPage);
  setUpdate(!update)
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
  setUpdate(!update)
};

const handleClickCompanyWebsite = (website)=>{
  if (website?.length > 0) {
    window.open(website, '_blank');
  }
}

const isSelected = (name) => selected.indexOf(name) !== -1;

// Avoid a layout jump when reaching the last page with empty rows.
const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getcompanylist.data.length) : 0;

  const dateFormatter = (date) => {
    let formattedDate = new Date(date);
    return formattedDate.getDate() + '/' + formattedDate.getMonth() + '/' + formattedDate.getFullYear();
};

return (
    <>
    <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
       {/* Company Title */}
        <TableListTitle title={Title} />

     {/* Create new Company, search bar and filter  */}
        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mb:0.5, mr: {lg:4, xs:1}}}>
          <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={(checkAdminRole && permissionsDetails?.perm_company_create) || permissionsDetails?.perm_company_create} onClick ={CreateNewCompany} onchange={handleSearch} />    
        </Grid>
      </Grid>

    {/* <Grid sx={{mt:1,ml:5,mr:5, display:"flex", flexDirection:{xl:'row',md:'row',sm:'column',xs:'column'} ,justifyContent:'space-between',alignItems:'center'}} >
      <Grid>
        <CustemBtn sx={{borderRadius:'8px',pl:4,pr:4}} text="Create New Company" variant="contained" onClick={CreateNewCompany}/>
      </Grid>
      <Grid sx={{marginTop:{xl:'none',xs:'10px',sm:'10px'}}} >
       <Search> 
            <InputBox onchange={handleSearch} placeholder="Search Company"/>
              <Typography sx={{pl:4,pt:1}}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#7673f6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.7051 13.6978L17.502 17.4946" stroke="#7673f6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </Typography>
        </Search>
      </Grid>
    </Grid>
  
    <Grid sx={{ borderBottom:'1px solid #e4e4e4',mt:3,ml:{lg:5,md:3,sm:1,xs:1},mr:{lg:5,md:3,sm:1,xs:1},display:"flex",justifyContent:'space-between',alignItems:'flex-end'}}>
      <Grid item xs={10}>
        <Typography variant='h6' sx={{fontSize:{sm:"20px",xs:'20px',md:'28px',xl:'28px'},fontWeight:600}}>Company List</Typography>
      </Grid>
      
    </Grid> */}
   
   <CardBody sx={{mt:1, ml:{xs:1, sm:1, xl:4, lg:4}, mr:{xs:1, sm:1, xl:4, lg:4}}}>
      <Paper sx={{padding:'10px'}}>
        <TableContainer sx={{ maxHeight: 440}}>
          <Table
            sx={{ minWidth: 750 }}
            size= 'small'
            stickyHeader aria-label="sticky table">  
             
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={getcompanylist?.data?.length}
              />
              { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                <TailSpin
                  height="30"
                  width="30"
                  color="blue"
                  ariaLabel="tail-spin-loading"
                  radius="3"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                /> </Box>:<>
                  <TableBody>
                    {/*stableSort(getcompanylist.data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/
                      getcompanylist?.data?.map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                          // onClick={(event) => handleClick(event, row.name)}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row?.name}
                            selected={isItemSelected}
                          >
                            <TableCell component="th" id={labelId} scope="row" padding="none" align='left' sx={{padding:'3px', color:'#3107AA', maxWidth: 160, whiteSpace: 'pre-line', "&:hover": {cursor:'pointer'}, fontSize:'14px', fontWeight:600}} onClick={()=> openCompanyProfilePage(row)} >
                            <H6TableCompany>
                              {row?.company_name}
                            </H6TableCompany>
                            </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'14px', color:'#3107AA', fontWeight:600}}>
                            <H6TableCompany>
                              {row?.company_type}
                              </H6TableCompany>
                              </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>
                            <T6TableText>
                              {moment(row?.createdAt).format('D/M/YYYY')}
                              </T6TableText>
                              </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>
                             <T6TableText>
                              {row?.industry_type}
                              </T6TableText>
                              </TableCell>
                            <TableCell aalign="left" sx={{padding:'3px', fontSize:'12px'}}>
                            <T6TableText>
                              {row?.company_turnover}
                              </T6TableText>
                              </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px', color:'#3107AA', "&:hover": {cursor:'pointer'}}} onClick={()=>handleClickCompanyWebsite(row?.company_website)}>
                            <T6TableText>
                              {row?.company_website} 
                              </T6TableText>
                              </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px', height:'40px'}}>
                            <T6TableText>
                              {row?.head_office?.city ? row?.head_office?.city : '__'}
                              </T6TableText>
                              </TableCell>
                            {(checkAdminRole && permissionsDetails?.perm_company_edit) ||permissionsDetails?.perm_company_edit ? <TableCell onClick={()=>handleClickEditCompany(row)} align="left" sx={{padding:'3px', fontSize:'12px'}}><EditIcon w={18} h={18}/> </TableCell> :<></>}               
                          </TableRow>
                        );
                      })}
                  </TableBody>
              </>}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={getcompanylist?.recordsTotal ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </CardBody>

     {/* ===========  create company ======  */} 
     {openCompanyModel && <CreateCompanyForm openCompanyModel ={openCompanyModel} setOpenCompanyModel={setOpenCompanyModel}  
        setUpdate={setUpdate} update={update} setEditCompanyDetails={setEditCompanyDetails} editCompanyDetails={editCompanyDetails} title={Title} />}
   
    {/* <EditCompanypopup open={open} setOpen={setOpen} state={state} setState={setState} update={update} setUpdate={setUpdate} /> */}
    </>
  );
}
