import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import WhiteTaskIcon from './WhiteTaskIcon';
import WhiteContactIcon from './WhiteContactIcon';
import WhiteOppoIcon from './WhiteOppoIcon';
import WhiteLeadIcon from './WhiteLeadIcon';
import WhiteCompanyIcon from './WhiteCompanyIcon';
import WhiteUserIcon from './WhiteUserIcon';
import WhiteFollowupIcon from './WhiteFollowupIcon';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CreateAddressPopup from '../../pages/Addresspopup/CreateAddresspopup';
import { Box, Divider } from '@mui/material';
import WhiteAddressIcon from './WhiteAddressIcon';
import ContactForm from '../../pages/ContactForm/ContactForm';
import WhiteBugReportIcon from './WhiteBugReport';
import NotificationIcon from './NotificationIcon';
import CloseIcon from './CloseIcon';
import { clearnotifications, dash_notification, deletenotification } from '../../Redux/Thunk/dashboardthunk';
import { theme } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor';

// const useStyles = makeStyles({
//   paper: {
//     position: 'absolute',
//     right: 20,
//     top:100,
//     backgroundColor:'transparent'
//   },
// });

const dialogStyle = {
  position: 'fixed',
  right: 20,
  top:100,
  backgroundColor:'transparent'
  // width: '100%',
  // maxWidth: '100%',
  // maxHeight: '70%',
  //borderRadius: '8px 8px 0 0',
};
function SimpleDialog(props) {
  const {user} = useSelector(state => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const { onClose, selectedValue, open } = props;
  const [notifications, setNotifications] = useState([])
  const dispatch = useDispatch()
  const [update, setUpdate] = useState(false)

  // s
  const route = "CreateFromDashboard"

  const handleClose = () => {
    onClose(selectedValue);
  };

  useEffect(() => {
    dispatch(dash_notification()).then((res) => {
      setNotifications(res.payload.data)
    })
  }, [update])

  const handleDeleteNotification = (id) => {
    dispatch(deletenotification(id)).then((res) => {
      console.log(res.payload)
      setUpdate(!update)
    })
  
  }

  const handleClear =() => {
    dispatch(clearnotifications()).then((res) =>{
      setUpdate(!update)
    })
   
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
         PaperProps={{ style: dialogStyle }}
        //classes={{ paper: classes.paper }}
      >
      <Box>
      <Box sx={{backgroundColor:'#ffffff',color:'#444444',width:'330px', mt:1, borderRadius: "10px", overflowY: 'scroll', maxHeight: "400px", mb: "4px"}} disableGutters>
        {
          notifications?.length == 0 ? <Box sx={{pb:1,pt:1, alignItems:'center', justifyContent:'center'}}>
               <Typography sx={{color:'red', textAlign:'center'}}>No Notifications Available !</Typography>
               
            </Box>: <>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                    <Typography onClick={handleClear} sx={{fontWeight:600, cursor:'pointer', color:themeColor.palette.BrandColor}} >Mark as Read All</Typography>
                  
                      </Box>
                      <Divider/>
               {
                notifications?.map((noti) => {
                              return <>
                                
                                <Box onClick={() => handleDeleteNotification(noti.id)} sx={{display:'flex',pb:1,pt:1,mb:1, alignItems:'center', justifyContent:'center', borderBottom:'1px solid lightgrey', background:`${!noti.isread ? themeColor.palette.backgroundColor : ''}`, cursor:'pointer'}}>
                                <Typography sx={{flex:1, pl:1}}><NotificationIcon w={25} h={25}/></Typography>
                                <Typography sx={{flex:4}}><span style={{color:'#049E01', fontWeight:600}}>{noti.created_user?.firstname}</span> - <span style={{color:'#7673F6', fontSize:'15px'}}>{noti?.title} </span> </Typography>
                                <Typography  sx={{p:1.5, cursor:'pointer'}} > 
                                <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.74789 5.85463C4.79433 5.90112 4.84947 5.938 4.91017 5.96316C4.97087 5.98832 5.03594 6.00128 5.10164 6.00128C5.16735 6.00128 5.23241 5.98832 5.29311 5.96316C5.35381 5.938 5.40896 5.90112 5.45539 5.85463L11.2479 0.147132C11.2943 0.100643 11.3495 0.063764 11.4102 0.0386019C11.4709 0.0134397 11.5359 0.000488281 11.6016 0.000488281C11.6674 0.000488281 11.7324 0.0134397 11.7931 0.0386019C11.8538 0.063764 11.909 0.100643 11.9554 0.147132L13.4554 1.62213C13.5019 1.66857 13.5388 1.72371 13.5639 1.78441C13.5891 1.84511 13.602 1.91017 13.602 1.97588C13.602 2.04159 13.5891 2.10665 13.5639 2.16735C13.5388 2.22805 13.5019 2.2832 13.4554 2.32963L5.43039 10.3546C5.38396 10.4011 5.32881 10.438 5.26811 10.4632C5.20741 10.4883 5.14235 10.5013 5.07664 10.5013C5.01094 10.5013 4.94587 10.4883 4.88517 10.4632C4.82447 10.438 4.76933 10.4011 4.72289 10.3546L0.247893 5.85463C0.154196 5.76087 0.101562 5.63375 0.101562 5.50119C0.101562 5.36864 0.154196 5.24152 0.247893 5.14776L1.74789 3.64776C1.79433 3.60127 1.84947 3.56439 1.91017 3.53923C1.97087 3.51407 2.03594 3.50111 2.10164 3.50111C2.16735 3.50111 2.23241 3.51407 2.29311 3.53923C2.35381 3.56439 2.40896 3.60127 2.45539 3.64776L4.74789 5.85463Z" fill={`${noti.isread == true ? "#03A500":'lightgrey'}`}/>
                                </svg> 
                                </Typography>
                                </Box> 
                              </>
                          })
               }
            </> 
             
        }
      </Box>
      </Box>
      </Dialog>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function NotificationModal({open,setOpen,handleClose, notification_data}) {
 // const [open, setOpen] = React.useState(false);
//   const [selectedValue, setSelectedValue] = React.useState(emails[1]);


  return (
    <div>
      <Typography variant="subtitle1" component="div">
      
      </Typography>
      <br />

      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open simple dialog
      </Button> */}
      <SimpleDialog
        open={open}
        onClose={handleClose}
        notification_data={notification_data}
      />
    </div>
  );
}
