import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";

export const createCompany = createAsyncThunk('data/createCompany',
    async(data)=>{
        const res = await axiosInstance.post('/createCompany',data);
        return res.data
    }
)
 
// Display Company List
export const getCompanyList = createAsyncThunk('data/company',
    async(data)=>{
        const res = await axiosInstance.post('/fetchcompanylist',data);
        return res.data
    }
)

// Verified company name 
export const verifyCompanyName = createAsyncThunk('data/verifyCompany',
    async(data) => {
        const res= await axiosInstance.post('/verifycompanyname', data);
        return res.data.data
    }
)

/**
 * Display All Company Name
*/
export const getAllCompanyName = createAsyncThunk('data/getCompanyName',
    async() => {
        const res = await axiosInstance.get('/companylist');
        return(res.data.data)
    }
)

//Edit company 
export const editCompany = createAsyncThunk('data/companyedit',
    async(data) => {
        const res= await axiosInstance.post('/companyedit', data);
        return(res.data)
    }
)

// export const createCompanyAddress = createAsyncThunk('data/createCompanyAddress',
//     async(data) => {
//     const res= await axiosInstance.post('/createbranch', data);
//     return(res.data.data)
//     }
// )

//Display Company details ,(lead ,opp ,task, contact, address, followup)
 export const displayCompanyDetails = createAsyncThunk('data/displayCompany',
    async(data) =>{
        const res = await axiosInstance.get(`/companyprofile/${data}`)
        return (res.data)
    }
 )

