import React, {useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import OpportunityCard from '../../components/custom/OpportunityCard'
import { Typography,Card,CardContent, Grid, Box, CircularProgress, Button} from '@mui/material';
import { CustemBtn, DeleteIcon, EditIcon, InputBox, Navbar, SettingMasterCard, FilterIcon} from '../../components/custom';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useDispatch, useSelector } from 'react-redux';
import { createIndustryTypesList, getIndustryTypesList } from '../../Redux/Thunk/createsettinglist';
import swal from 'sweetalert';
import {CardBody, Search, CardHeader, CardInfo} from './SettingMasterList.style'
import { TailSpin } from 'react-loader-spinner';

export default function IndustryTypesList({permissionsDetails}) {
    const dispatch = useDispatch();
    const {update} = useSelector(state => state.settingList)
    const [industryTypesList ,setIndustryTypesList] = useState([]) 
    const [values, setValues] = useState({
      value:'',
      index:''
    })
    const [openButton, setOpenButton] = useState(false)
    const [indexValue, setIndexValue] = useState('')
    const [buttonStatus, setButtonStatus] = useState(false);
    const [inputFieldStatus, setInputFieldstatus] = useState(false)
    const [flag ,setFlag]= useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [buttonDisabledForSave, setButtonDisabledForSave] = useState(false)
    const [loader, setLoader] = useState(true)
  
    useEffect(()=>{
      dispatch(getIndustryTypesList()).then((res)=>{
          setIndustryTypesList(res?.payload?.value) 
          setButtonStatus(false)    
          setInputFieldstatus(false) 
          setLoader(false)
      })
    },[flag])
   
    const createIndustryType =(event) =>{
      setButtonStatus(true)
      setValues({value:event.target.value?.trimStart()})
    }
  
    const addIndustryType = () =>{  
        if(industryTypesList?.find(item =>item?.toUpperCase()=== values?.value?.toUpperCase())){
          sweetAlert("error", "This field is already exit")
        }else if(values?.value === ''){
          sweetAlert("error", "Not valid.")
        }else{
            setButtonDisabled(true)
            setLoader(true)
            const setValue = [...industryTypesList, values?.value?.trim()]
            dispatch(createIndustryTypesList({setting_industrytype_list :setValue})).then((res)=>{
              if(res?.payload?.errormsg){
                sweetAlert("error", res?.payload?.errormsg)
                setButtonDisabled(false)
                setLoader(false)
              }else{
                sweetAlert("success", res?.payload)
                setValues({ value:'', index:'' })
                setButtonStatus(false)
                setButtonDisabled(false)
                setFlag(!flag)
              }
            }) 
      }   
    }
  
    const sweetAlert = (variant, text) => {
      swal({
          icon: variant === 'error' ? 'error' : 'success',
          title: text,
      })
  };

    const editIndustryType = (index) =>{
      setIndexValue(index)
      setOpenButton(true) 
      setButtonStatus(false)
      setInputFieldstatus(true)
    }
  
    const handleChangeIndustryType =(event, index)=>{
      setValues({value:event.target.value?.trimStart(), index:index})
    }
  
    const saveIndustryType =() =>{
      const data = industryTypesList.slice();
      data.splice(values.index, 1, values.value);
    
      const isDuplicate = data.some(
        (item, index) =>
          item.toUpperCase() === values.value.toUpperCase() && index !== values.index
      );
    
      if (isDuplicate) {
        sweetAlert("error", "This Industry type is already exit")
      }else if(values?.value === ''){
         sweetAlert("error", "Please Enter Industry type.")
      }else{
        swal({
          buttons: {
          Cancel: {
              text: "Cancel",
              value: "Cancel",
              className: "popup-blue",
          },
          Delete: {
              text: "Confirm",
              value: "Confirm",
              className: "popup-gray",
          },
          },
          title: `Do you want to Save the Industry type option ${values.value} ?`,
          className: "custom-modal",
          closeOnClickOutside: false,
        }).then((value)=>{
            if(value=="Confirm"){
              setButtonDisabledForSave(true)
              dispatch(createIndustryTypesList({setting_industrytype_list :data})).then((res)=>{
                sweetAlert("success", "Industry Type Updated Successfully.")
                setButtonStatus(true)
                setButtonDisabledForSave(false)
                setValues({ value:'', index:'' })
                setOpenButton(false)
                setFlag(!flag)
              }) 
            }else{
              setOpenButton(false)
              setInputFieldstatus(false)
              setValues({ value:'', index:'' })
            }
        })    
      }  
    }
  
    const deleteIndustryType =(index, type) =>{
        swal({
          buttons: {
          Cancel: {
              text: "Cancel",
              value: "Cancel",
              className: "popup-blue",
          },
          Delete: {
              text: "Confirm",
              value: "Confirm",
              className: "popup-gray",
          },
          },
          title: `Do you want to delete the Industry Type option ${type} ?`,
          className: "custom-modal",
          closeOnClickOutside: false,
        }).then((value)=>{
            if(value=="Confirm"){
              setLoader(true)
              const data=industryTypesList.slice();
              data.splice(index,1);
              dispatch(createIndustryTypesList({setting_industrytype_list :data})).then((res)=>{
                if(res?.payload?.errormsg){
                  sweetAlert("error", res?.payload?.errormsg)
                  setLoader(false)
                }else{
                    sweetAlert("success", "Industry Type Deleted Successfully.")
                    setFlag(!flag)
                    // setLoader(false)
                    setValues({ value:'', index:'' })
                }
              })
            }
        })
    }

    return(
        <Card sx={{ maxWidth: '100%',borderRadius:'10px',boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
                <CardHeader>
                    <Typography sx={{color:'#444444', fontSize:'18px', fontWeight:600}}>
                        Industry Type
                    </Typography>
                </CardHeader>
                <CardContent style={{ display: "flex",
                  flexDirection: "column",
                  height: 300,
                  overflow: "hidden",
                  overflowY: "scroll"}}>
                {permissionsDetails?.perm_settings_create ? <><CardInfo>
                    <Typography>
                        Add Industry Type
                    </Typography> 
                    <Typography sx={{pr:2}}>
                        Action
                    </Typography> 
                </CardInfo></>:<></>}
                {permissionsDetails?.perm_settings_create ? <><CardInfo>
                    <InputBox sx={{width:'78%', border:"2px solid #B0AEFF", borderRadius:'10px', pl:1, backgroundColor:!inputFieldStatus?'#FFFFF' :'#C1C1C1'}} name='industry' disable={inputFieldStatus} value={values.value} onchange ={createIndustryType} placeholder='Industry Type'/>
                    {/* <Typography> */}
                    <Button
                      id="validate" variant="contained" disabled={!buttonStatus} onClick={() => buttonDisabled ? '' : addIndustryType()}
                      sx={{borderRadius:"10px", pl:2, pr:2, fontSize:'16px'}}
                      >
                      {buttonDisabled ? <CircularProgress size={20} color="inherit" /> : 'ADD'}
                    </Button>
                    {/* <CustemBtn variant="contained" sx={{backgroundColor:'#84c75a'}} text="ADD" disabled={!buttonStatus} onClick={addIndustryType}/>  */}
                    {/* </Typography> */}
                </CardInfo></>:<></>}
                <Box sx={{ flexGrow: 1 }}>
                    { loader ? <Box sx={{height:'300px', alignItems:'center', display:'flex', mt:0.5, justifyContent:"center", borderRadius:'10px'}}>
                        <TailSpin
                          height="30"
                          width="30"
                          color="blue"
                          ariaLabel="tail-spin-loading"
                          radius="3"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        /> </Box>:<>
                {industryTypesList?.map((types, index) =>( <>
                <CardInfo sx={{mt:2}} key={index}>
                    {openButton && indexValue === index ? <><InputBox sx={{width:'78%', border:"1px solid #B0AEFF", borderRadius:'10px', pl:1}} Default={types} onchange={(event)=>handleChangeIndustryType(event, index)}/>
                    <Button
                      id="validate" variant="contained" onClick={() => buttonDisabledForSave ? '' : saveIndustryType()}
                      sx={{textTransform: 'none', borderRadius:"10px", pl:2, pr:2, fontSize:'16px'}}
                      >
                      {buttonDisabledForSave ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                    </Button>
                    {/* <CustemBtn variant="contained" text="Save" onClick={saveIndustryType}/> */}
                    </> :<> 
                    <InputBox sx={{width:'78%', borderBottom:"0.5px solid #F3F3F3", pl:1}} value={types}/>
                    {permissionsDetails?.perm_settings_edit ? <>
                      <Typography sx={{pl:1, pt:1}} onClick={()=> {editIndustryType(index); setValues({value:types, index:index})}}>
                          <EditIcon w={20} h={20} />
                      </Typography>
                      <Typography sx={{pr:1, pt:1}} onClick={() => deleteIndustryType(index, types)}>
                        <DeleteIcon w={20} h={20} />
                      </Typography>
                    </> :<></>}
                    </>}
                </CardInfo> </>  
                ))}
              </>}</Box>
            </CardContent>
        </Card>
    )
}