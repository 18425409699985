import React, {useState} from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ForwardIcon from './ForwardIcon';
import BackwardIcon from './BackwardIcon';

export default function PaginationFooter({currentPage, setCurrentPage, list, number}) {
 
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  
  return (
    <Stack spacing={2}>
      <Pagination
       color="primary"
        onChange={handleChange}
        page={currentPage}
        count={Math.ceil(list/number)}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: BackwardIcon , next: ForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}