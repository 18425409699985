import React,{useState, useEffect} from 'react';
import { Typography, Box, Card, IconButton, Grid, Divider} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import { CompanyName, EditIcon } from '../../components/custom';
import CreateAddressPopup from '../Addresspopup/CreateAddresspopup';
import { TailSpin} from 'react-loader-spinner';
import AdminRole from '../../adminrole/Adminrole';

 const CardHeaders = styled('div')({
     display:'flex',
     justifyContent:'space-between',
     borderBottom:'0.5px solid #BBBBBB',
 })

 const ChildCard = styled('div')({
    // backgroundColor:'#FFFFFF',
    borderRadius:'10px',
    boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)'
 })

const CardInfo = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    marginTop:"10px",
})

export const OfficeAddress = () => {
    const dispatch = useDispatch();
    const { state } = useLocation()
    const {user} = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const {updateEditCompany} = useSelector(state => state.address)
    const [getAddressDetails, setGetAddressDetails]= useState([])
    const [open ,setOpen] =useState(false)
    const [editCompanyDetails, setEditCompanyDetails] = React.useState({});
    const [address, setAddress] = useState({
        label:'',
        value:''
    })
    const [loader, setLoader] = useState(false)
    const title = "CompanyProfilePage"

    useEffect(() => {
        fetchAddressDetails();
        setGetAddressDetails([]);  
    },[updateEditCompany])
    
    const fetchAddressDetails = () =>{
        setLoader(true)
        dispatch(displayCompanyDetails(state?.id)).then((res)=>{
            setGetAddressDetails(res?.payload?.data)
            setLoader(false)
        })
    }

  return(
    <>
    { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
        <TailSpin
          height="30"
          width="30"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        /> </Box>:<>
         <CompanyName style={{borderRadius:'6px', mb:1, "&:hover": {cursor:'pointer'} }} label={`${getAddressDetails?.company?.company_name} ${getAddressDetails?.company?.company_type}`} companyID={getAddressDetails?.company?.id} />  
            <Box sx={{ padding:'10px'}}>
                <CardHeaders>
                    <Typography variant='h6' sx={{pl:1, color:'#444444s',fontSize:'22px',fontWeight:600}}>
                        Office Addresses
                    </Typography>
                </CardHeaders>
            
                {getAddressDetails?.branches?.length === 0 ? <Typography style={{backgroundColor:'#F7F1FF', marginTop:'10px', fontWeight:600, display:'flex', justifyContent:'center',flexDirection:'row', color:'red'}}>No office addresses available</Typography> :<>
                <Box sx={{mt:2,ml:{xs:1,sm:1,xl:5}, mr:{xs:1,sm:1,xl:5}}}>
                    <Box sx={{ flexGrow: 1, mb:2 }}>
                            <Grid container spacing={{ xs: 2, md: 1, lg:1}} >   
                                {getAddressDetails?.branches?.map((address) =>( 
                                    <Grid item xs={2} sm={4} md={6} lg={6}>  
                                        <ChildCard sx={{p:2, mt:2, ml:2, mr:2}}>
                                            <Box sx={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid #BBBBBB',alignItems:'end'}}>
                                                <Typography variant='h6' sx={{fontSize:'20px',color:'#7673F6', flex:15, fontWeight:600}}>
                                                    {
                                                        (
                                                            () => {
                                                                if(address?.register_address) {
                                                                    return "Registered Office Address" 
                                                                }
                                                                if(address?.head_office) {
                                                                    return "Head Office Address" 
                                                                }
                                                                if(!address?.register_address && !address?.head_office ) {
                                                                    return "Branch Office Address"
                                                                }
                                                            }
                                                        )()
                                                    }
                                                    {/* {address?.register_address == true ? "Registered Office Address" : "" || address?.head_office == true? "Head Office Address" :'' || 
                                                    (address?.register_address == false && address?.head_office == false ) ? "Branch Office Address" :'' } */}
                                                </Typography>
                                                {(checkAdminRole && permissionsDetails?.perm_address_edit) || permissionsDetails?.perm_address_edit ? <>
                                                    <Typography sx={{color:'#3C8AFF',fontSize:'18px',flex:1}} >
                                                        <IconButton onClick={() => {
                                                            setOpen(true)
                                                            setEditCompanyDetails(address)
                                                            setAddress({label:address?.register_address == true ? "Register Address" : "" || address?.head_office == true? "Head Office Address" :''
                                                            (address?.register_address == false && address?.head_office == false ) ? "Branch Address" :'' , value: address?.register_address == true ? "Register Address" : "" || address?.head_office == true? "Head Office Address" :'' 
                                                            (address?.register_address == false && address?.head_office == false ) ? "Branch Address" :'' })}}>
                                                            <EditIcon w={20} h={20} />
                                                        </IconButton>
                                                    </Typography></>
                                                :<></>}  
                                            </Box>
                                        
                                            <CardInfo sx={{mt:2}}>
                                                <Typography sx={{flex:2, fontWeight: 600}}>
                                                    Address
                                                </Typography>
                                            </CardInfo>
                                            <CardInfo>
                                                <Typography sx={{flex:2, fontSize:'14px', color:'#444444', whiteSpace: 'pre-line', wordBreak:'break-all'}}>
                                                {address?.address}
                                                </Typography>
                                            </CardInfo>
                                            <CardInfo sx={{mt:2}}>
                                                <Typography sx={{flex:2.5, fontWeight: 600}}>
                                                    Email
                                                </Typography>
                                                <Typography sx={{flex:2, fontWeight: 600}}>
                                                  Mobile No.
                                                </Typography>
                                                <Typography sx={{flex:1.5, fontWeight: 600}}>
                                                    GST No.
                                                </Typography>
                                            </CardInfo>
                                            <CardInfo>
                                                <Typography sx={{flex:2.5,fontSize:'14px',color:'#444444'}}>
                                                {address?.email}
                                                </Typography>
                                                <Typography sx={{flex:2,fontSize:'14px',color:'#444444'}}>
                                                {address?.phone}
                                                </Typography>
                                                <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                                                {address?.gstno}
                                                </Typography>
                                            </CardInfo>
                                            <CardInfo sx={{mt:2}}>
                                                <Typography sx={{flex:1.5, fontWeight: 600}}>
                                                    Country
                                                </Typography>
                                                <Typography sx={{flex:1.5, fontWeight: 600}}>
                                                    State
                                                </Typography>
                                                <Typography sx={{flex:1.5, fontWeight: 600}}>
                                                    City
                                                </Typography>
                                                <Typography sx={{flex:1.5, fontWeight: 600}}>
                                                    Pin Code.
                                                </Typography>
                                            </CardInfo>
                                            <CardInfo >
                                                <Typography sx={{flex:1.5,fontSize:'14px',flexWrap:'wrap',color:'#444444'}}>
                                                {address?.country}
                                                </Typography>
                                                <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                                                {address?.state}
                                                </Typography>
                                                <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                                                {address?.city}
                                                </Typography>
                                                <Typography sx={{flex:1.5,fontSize:'14px',color:'#444444'}}>
                                                {address?.pincode}
                                                </Typography>
                                        </CardInfo>
                                        </ChildCard>
                                    </Grid> 
                                ))}
                            </Grid>
                    </Box> 
                </Box>   
                </>}    
            </Box>
    </>}
    {open && <CreateAddressPopup openAddressModel={open} setOpenAddressModel={setOpen} addressType={address?.value} setAddress={setAddress} title={title}
         companyDetails={state} setEditCompanyDetails = {setEditCompanyDetails} editCompanyDetails={editCompanyDetails } /> 
    }
    </>
  )
}
