import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Box, Grid} from "@mui/material"
import { ComapanyName, DropDown, InputBox, SelectDropDown } from '../../components/custom';
import CustemBtn from '../../components/custom/CustemBtn'
import SwitchBtn from '../../components/custom/Switch';
import TextArea from '../../components/custom/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import validate from '../../validation/validation';
import DisplayError from '../../validation/DisplayError';
import { editlead } from '../../Redux/Thunk/createleadthunk';
import { displayContactList } from '../../Redux/Thunk/createcontact';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper':{
    maxWidth:'1500px'
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditLeadpopup({open,update,setUpdate, setOpen, leadDetails,setLeadDetails, companyName}) {
  const dispatch = useDispatch()
  const [getContactList, setContactList] = React.useState([])
  const [error,setError] = React.useState([])
  const contactDetails = useSelector((state)=> state.contact.getContactList)
  
  console.log('contactDetails',contactDetails)

  const handleClose = () => {
    setOpen(false);
    setError([])
    setContactList([])
    setLeadDetails({})
  };

  const handleChangeLead = (e) => {
    setLeadDetails({...leadDetails,[e.target.name]:e.target.value})
  }

  React.useEffect(()=>{
        const getContactList = () => {
          dispatch(displayContactList(companyName?.id)).then((data)=>{
            setContactList(data.payload)
          })
      }

      getContactList()
     
  },[leadDetails.lead_id])


console.log("companyName",companyName)

  let validation_input = [
    // {tagid:'contact_name', text:leadDetails.contact_id, regex_name: 'text', required: true, errmsg: 'Please Select Contact Name.'},
    {tagid:'source', text:leadDetails.lead_source, regex_name: 'text', required: true, errmsg: 'Please Select Source.'},
    {tagid:'data_source', text:leadDetails.data_source, regex_name: 'text', required: true, errmsg: 'Please Enter Data Source.'},
    {tagid:'product', text:leadDetails.product, required: true, regex_name:'text', errmsg: 'Please Select Product.'},
    {tagid:'lead_name', text:leadDetails.lead_name, regex_name: 'text', required: true, errmsg: 'Please Enter Name.'},
    {tagid: 'service_provider', text: leadDetails.service_provider, required: true, regex_name:'text', errmsg: 'Please Select Service Provider'},
    {tagid:'summary', text:leadDetails.summary, required: true, regex_name:'free_text', errmsg: 'Please add the summary',allow_numbers: true, min:1, max: 300},  //min:1, max: 300
    {tagid:'product_family', text:leadDetails.product_family, required: true, regex_name:'text', errmsg: 'Please Select Service Provider'},
  ]

    const handleLeadEdit = () => {
    if(validate(validation_input).length == 0){
      dispatch(editlead(leadDetails))
      setUpdate(!update)
    } else {
      setError(validate(validation_input))
    }    
    }
    console.log(error)

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >  
        <BootstrapDialogTitle sx={{p:3, fontWeight:'600'}} id="customized-dialog-title" onClose={handleClose}>
          Lead Details
        </BootstrapDialogTitle>
        <DialogContent dividers style={{backgroundColor:'#F5F4FF'}}>
            <Box style={{marginTop:'20px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Box sx={{flex:1}} >
                    <Typography style={{fontWeight:'600'}}>Organization </Typography>  
                </Box>
                <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={2} >
                        <Grid item xs={12} md={6} sm={6} lg={6}>
                            <Typography>Name<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox  value={`${companyName?.company_name} ${companyName?.company_type}`} sx={{border:"1px solid #B0AEFF",background:'#E9E9FF',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="Mobigic Technologies"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6}>
                            <Typography>Contact Name<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                name='contact_id'
                                options={getContactList?.map((contact) => { return { value:contact.id , label:`${contact.first_name} ${contact.last_name}`} } )} 
                                onchange={handleChangeLead}
                                defaultlabel='--Select Contact--'
                                value ={leadDetails.contact_id}
                                // label='Select industry'
                                sx={{p:0.1,width:'100%', mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                             />
                        </Grid>
                </Grid>
            </Box>
            <Box style={{marginTop:'30px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Box sx={{flex:1}} >
                    <Typography style={{fontWeight:'600'}}>Lead </Typography>  
                </Box>
                <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={2} >
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>Name<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox  onchange={handleChangeLead} value={leadDetails.lead_name} sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="JIO Limited"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>Source<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                onchange={handleChangeLead}
                                Default= "Select lead_source"
                                options={[{value:leadDetails.lead_source,label:leadDetails.lead_source}]}
                                value = {leadDetails.lead_source}
                                name='lead_source'
                                sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                                />
                             <DisplayError error={error} fortagid='source' />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>Data source<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox name='data_source' onchange={handleChangeLead} value={leadDetails.data_source} sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="Mobigic database"/>
                            <DisplayError error={error} fortagid='data_source' />
                        </Grid>
                </Grid>
            </Box>
            <Box style={{marginTop:'30px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Box sx={{flex:1}} >
                   
                </Box>
                <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={2} >
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>Service Provider<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                onchange={handleChangeLead}
                                Default= "Select service_provider"
                                options={[{value:leadDetails.service_provider,label:leadDetails.service_provider}]}
                                value = {leadDetails.service_provider}
                                name='service_provider'
                                sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                                />
                              <DisplayError error={error} fortagid='service_provider' />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>Product Familly<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                onchange={handleChangeLead}
                                Default= "Select product_family"
                                options={[{value:leadDetails.product_family,label:leadDetails.product_family}]}
                                value = {leadDetails.product_family}
                                name='product_family'
                                sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                                />
                            <DisplayError error={error} fortagid='product_family' />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>Product<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                onchange={handleChangeLead}
                                Default= "Select product"
                                options={[{value:leadDetails.product,label:leadDetails.product}]}
                                value = {leadDetails.product}
                                name='product'
                                sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                                />
                            <DisplayError error={error} fortagid='product' />
                        </Grid>
                </Grid>
            </Box>
            <Box style={{marginTop:'30px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Box sx={{flex:1}} >
                    <Typography style={{fontWeight:'600'}}>Summary <span sx={{color:"red"}}>*</span> </Typography>  
                </Box>
                <Grid container sx={{flex:3}} spacing={2}>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                    <TextArea value={leadDetails.summary} name='summary' onChange={handleChangeLead} placeholder="Write Address Details" style={{width:'100%', height:60}} />
                    <DisplayError error={error} fortagid='summary' />
                </Grid>
                </Grid>
            </Box>
        </DialogContent>
          <DialogActions sx={{mt:2,mb:2}}>
            <CustemBtn onClick={handleLeadEdit} variant="contained" sx={{borderRadius:"10px",pl:3,pr:3,backgroundColor:'#7673F6'}} text="Save" />
          </DialogActions>
        </BootstrapDialog>
    </div>
  );
}