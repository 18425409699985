import React, { useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { headCells } from './headcells';
import swal from 'sweetalert';
import { TailSpin} from 'react-loader-spinner';
import { getTaskList } from '../../../Redux/Thunk/createTaskthunk';
import TableListTitle from '../../../components/custom/TableListTitle';
import TableListSearch from '../../../components/custom/TableListSearch';
import CreateTaskForm from '../../Task/CreateTaskForm';
import { AssignIcon, CompanyName, CustemBtn, FilterCloseIcon, FilterIcon, LessThanIcon, PopperFilter } from '../../../components/custom';
import { fetchComanyLeads } from '../../../Redux/Thunk/createopportunitythunk';
import { displayContactList } from '../../../Redux/Thunk/createcontact';
import moment from 'moment';
import { H4TableID, H6TableCompany, T6TableText } from '../../../Theme/theme';
import themeColor from '../../../Theme/themeColor';
import AdminRole from '../../../adminrole/Adminrole';

const Item = styled('div')({
  display:'flex',
  justifyContent:'center'
})

const CardBody = styled('div')({
  //  backgroundColor:'#F0EFFD',
  //  padding:'10px',
  //  borderRadius:'10px',
})

const Search = styled('div')({
  display:'flex',
  padding:'0px 10px',
  borderRadius:'10px',
  justifyItems:'center',
   border:'1px solid #B0AEFF ',
  flexDirection:'row',
  backgroundColor:'#ffffff',
})

export default function CompanyWiseTask() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {state} = useLocation()
  const {user} = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user.role?.permissions
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [open, setOpen] = useState(false);
  const [user_ids, setUserId] = useState([])
  // const { fetchtasklist } = useSelector ((state) => state.lead)
  const [fetchtasklist, setTasklist]= useState([]);
  const [update, setUpdate] = useState(false);
  const [orderBy, setOrderBy] = useState('calories');
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [companyName, setCompanyName] = useState('')
  const [filtertask, setfiltertask] = useState('')
  const [taskedit, setTaskEdit]= useState({})
  const [For, setFor] = useState('')
  const [ openTaskPopup, setOpenTaskPopup] = useState(false)
  const [loader, setLoader] = useState(false)
  const route ="companyProfilePage"
  const Title= "Task List"
  const leadName = 'Lead'
  const tooltipTitle = "New Task"
  const placeholderTitle = "Search Task"
  
console.log(state,"state")
  React.useEffect(()=> {
    setLoader(true)
    setTasklist([])
    dispatch(getTaskList({start:page*rowsPerPage, length:rowsPerPage ,company_id:state?.id, search:search, filtertask:filtertask, col_sort: orderBy.split(' ').join(''), order: order.toUpperCase()})).then((res)=>{
      setTasklist(res?.payload)
      setUserId(res?.payload.all_user_id)
      setLoader(false)
    })
  },[update])

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget)
    setOpenopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setUpdate(!update)
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUpdate(!update)
  };

  const handlAssignTask = (task) => {
    setFor('update')
    setTaskEdit(task)
    setOpenTaskPopup(true)
    // setLeadAssigned(row)
    // setOpenAssignModal(true)
  }

  const openOpportunityDetails = (row) => {
    navigate('/dashboard/opportunitydetails', { state: { oppDetails: row?.lead?.id, title: Title } })
  }

  const handleClickOpen = (task) => {
    setFor('edit')
    console.log(task)
    setTaskEdit(task)
    setOpenTaskPopup(true)
    // navigate('/dashboard/lead', {state: {lead :lead, operation:'editLead'}})
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
  
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    
    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => ( 
            <TableCell
              sx ={{fontWeight:600, fontSize:'13px', zIndex:0}}
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'center'}
              padding={headCell.disablePadding ? 'normal' : 'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>      
          ))}

          {/* {permissionsDetails?.perm_assign_edit ? <TableCell align="left" sx ={{zIndex:0, fontWeight:600, fontSize:'13px'}}>Assign</TableCell> :<></>} */}
           {/* {permissionsDetails?.perm_lead_edit ? <TableCell sx ={{ fontWeight:600, fontSize:'16px' }}>Action</TableCell> :<></>} */}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setUpdate(!update)
  };
  
  const handleSearch = (e) => {
    setSearch(e.target.value)
    setUpdate(!update)
  }

  const handleFilter = (e)=> {
    setPage(0)
    if (e.target.value == "All Task") {
      setfiltertask('')
      setOpenopper(false)
      setUpdate(!update)
    } else {
      setfiltertask(e.target.value)
      setOpenopper(false)
      setUpdate(!update)
    }  
  }
  
  const handleCloseFilter = () => {
    setfiltertask('')
    setUpdate(!update)
  }

  const createNewTask = () =>{
    navigate('/dashboard/createtask', {state: {title:route, companyName:state, operation:'createTask'}})
    dispatch(fetchComanyLeads(state?.id))
    dispatch(displayContactList(state?.id))
  }

  const handleLeadOppoOpen =(row) =>{
    if (row?.lead_no) {
      navigate('/dashboard/lead', {state: {lead :row, operation:'editLead', for:'tasklist'}})
    } else {
      navigate('/dashboard/opportunity', {state: {opportunity: row, operation:'editOpp', for:'tasklist'}})
    }
  }
  
  const companyProfile= (company)=>{
    navigate('/dashboard/profile',{state: company?.lead.company?.id})
  }

  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };
  
  const findIds= (id1, id2) => {
    if (user_ids?.includes(id1) || user_ids?.includes(id2)) {
      return true 
    } else {
      return false
    }
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchtasklist?.data?.length) : 0;
  
  const dateFormatter = (date) => {
      let formattedDate = new Date(date);
      return formattedDate.getDate() + '/' + (formattedDate.getMonth() + 1) + '/' + formattedDate.getFullYear();
  };
  
  return (
    <>
    <CompanyName sx={{"&:hover": {cursor:'pointer'} }} label={`${state?.company_name} ${state?.company_type}`} companyID={state?.id}/> 
    <PopperFilter filtervalues ={[{name:'All Task'},{name:'Assigned'}, {name:'Completed'},{name:'Customer Side Pending'},{name:'Inprocess'},{name:'Pending Input'},{name:"Roll Over"}] } open={openPopper} anchorEl={anchorEl} placement={placement} onClick={handleFilter} />
    
    <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
         {/* Task Title */}
        <TableListTitle title={Title} />

        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
           {/* Search component and create new task */}
          <TableListSearch For = 'Oppo' tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={permissionsDetails?.perm_task_create || (checkAdminRole && permissionsDetails?.perm_task_create)} onClick ={createNewTask} onchange={handleSearch} />    
            <Tooltip title="Filter By Stage" placement="top-start">
              <IconButton onClick={handleClick("bottom-start")} sx={{mr: {lg:4, xs:1}}}>
                {!openPopper ? <FilterIcon /> : <FilterCloseIcon />}
              </IconButton>
            </Tooltip>
        </Grid>
    </Grid>

    {
      filtertask ? <Box sx={{ml:4, display:'flex', justifyContent:'space-between', fontSize:'10px',width:'25%',p:0.5,mt:0.5, border:'1px solid black', borderRadius:'10px'}}>
        <Typography sx={{fontSize:'12px'}}>
       Showing Task for status  <span style={{color:'blue', marginLeft:'10px'}}>{filtertask}</span> 
      </Typography>
      <Typography onClick={handleCloseFilter} sx={{color:"red", fontSize:'12px', cursor:'pointer', fontWeight:600, mr:0.5}}>
         Clear Filter X
      </Typography>
      </Box>:""
    }

    <CardBody sx={{mt:1, ml:{xs:1, sm:1, md:1, xl:4, lg:4}, mr:{xs:1, sm:1, md:1, xl:4, lg:4}}}>
      <Paper sx={{padding:'10px'}}>
          <TableContainer sx={{ maxHeight: 440}}>
            <Table
              sx={{ minWidth: 750 }}
              size= 'small'
              stickyHeader aria-label="sticky table">

            <EnhancedTableHead
              numSelected={fetchtasklist?.data?.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={fetchtasklist?.data?.length}
            />
            { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
              <TailSpin
                height="30"
                width="30"
                color="blue"
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              /> </Box>:<>
                <TableBody>
                  {fetchtasklist?.data?.length === 0? <TableRow>
                      <TableCell colSpan={10} style={{textAlign: 'center', color:'red', fontWeight:600}}>
                        No tasks available
                      </TableCell>
                      </TableRow>:( 
                        fetchtasklist?.data?.map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.name)}
                            tabIndex={-1}
                            key={row.task_no}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              align='center'
                              sx={{ padding: '3px', fontSize: '14px' }}
                            >
                              <Typography sx={{ color: '#7673F6', fontWeight: 600, fontSize: '14px', cursor: 'pointer' }} onClick={() => handleClickOpen(row)}>
                                <H4TableID>
                                  {row.task_no}
                                </H4TableID>
                              </Typography>
                            </TableCell>
                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px' }}>
                              <H4TableID onClick={() => openOpportunityDetails(row)} sx={{ color: "#FE8731", fontSize: '14px', fontWeight: 600, "&:hover": { cursor: 'pointer' } }}>
                                {row?.lead?.opportunity_no}
                              </H4TableID>
                            </TableCell>
                            {/* <TableCell align="center" onClick={() => companyProfile(row)} sx={{ padding: '3px', fontSize: '14px' }}>
                              <H6TableCompany sx={{ color: themeColor.palette.BrandColor }} >
                                {row?.lead?.company?.company_type === "NGO" || row?.lead?.company?.company_type === "OTHERS" || row?.lead?.company?.company_type === "TRUST" || row?.lead?.company?.company_type === "PROPRIETOR" || row?.lead?.company?.company_type === "FOREIGN ENTITY" ? `${row?.lead?.company?.company_name} (${row?.lead?.company?.company_type})` : `${row?.lead?.company?.company_name} ${row?.lead?.company?.company_type}`}
                              </H6TableCompany>
                            </TableCell> */}
                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px', height: '40px' }}>
                              <T6TableText>
                                {row?.lead?.contact?.first_name} {row?.lead?.contact?.last_name}
                              </T6TableText>
  
                            </TableCell>
                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px',maxWidth:150 }}>
                              
                              <T6TableText>
                              {row?.created_by?.firstname} {row?.created_by?.lastname}
                              </T6TableText>
                              </TableCell>

                            {/*    assigned_to  */}
                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px',maxWidth:150}}>
                            <T6TableText>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '4px', whiteSpace: 'pre-line', flex: 1}}>
                                <T6TableText>
                                {row?.assigned_to?.firstname} {row?.assigned_to?.lastname}
                                 </T6TableText>
                                  </span>
                                {/* <span style={{whiteSpace: 'pre-line', flex: 1 }}>{row?.assigned_to?.lastname}</span>  row?.closed_on == 0 */}
                                <span style={{flexShrink: 0}}>
                                {(checkAdminRole && permissionsDetails?.perm_taskAssign_edit) || (permissionsDetails?.perm_taskAssign_edit && findIds(row?.assigned_to?.id)) ? <>
                                    <Tooltip title="Assign Task to" placement="top-start">
                                      <IconButton>
                                        <Typography onClick={() => row?.closing_remark !== '' ? '' :handlAssignTask(row)}>
                                           {row?.closing_remark !== '' ? '' :<AssignIcon />}
                                        </Typography> 
                                      </IconButton> 
                                    </Tooltip></>: " "}
                                </span>
                              </div>
                            </T6TableText>
                          </TableCell>

                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px' }}>
                              <T6TableText>
                                {row?.task_type}
                              </T6TableText>
                            </TableCell>

                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px' }}>
                              <T6TableText>
                                {moment(row?.start_date).format('D/M/YYYY')}
                              </T6TableText>
                            </TableCell>

                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px' }}>
                            <T6TableText>
                            {moment(row?.end_date).format('D/M/YYYY')}
                              </T6TableText>
                             
                              </TableCell>
                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px', }}>
                            <T6TableText sx={{fontWeight:600, fontSize:'14px'}}>
                            {row?.status}
                              </T6TableText>
                             
                              </TableCell>
                            <TableCell align="center" sx={{ padding: '3px', fontSize: '14px', color: row?.closing_remark === '' ? '#007500' : '#BC0003' }}>
                            <T6TableText sx={{fontWeight:600, fontSize:'14px'}}>
                            {row?.closing_remark === '' ? 'Open' : 'Closed'}
                              </T6TableText>
                            
                              </TableCell>
                            {/* {(checkAdminRole && permissionsDetails?.perm_assign_view) || permissionsDetails?.perm_assign_view ? <> <TableCell align="center" sx={{ padding: '3px', fontSize: '14px' }}><CustemBtn
                              sx={{
                                backgroundColor: row?.closing_remark !== '' ? '#85706e' : '#FFC700', borderRadius: '20px', color: 'white',
                                "&:hover": {
                                  backgroundColor: row?.closing_remark !== '' ? '#85706e' : '#FFC700'
                                }
                              }} variant='contained' onClick={() => handlAssignTask(row)} disabled={row?.closing_remark !== ''} text={row?.closing_remark === '' ? 'Assign' : 'Assign'}
                            /> </TableCell> </> : <></>
                            } */}
                            {/* {permissionsDetails?.perm_lead_edit ?
                              <><TableCell align="center">     
                                <Typography  onClick={()=>handleClickOpen(row)} >
                                    <EditIcon w={25} h={25} />
                                </Typography>
                              </TableCell> </> 
                          : <></>}               */}
                          </TableRow>
                      );
                    }))}
                </TableBody>
              </>}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={fetchtasklist?.recordsTotal ??0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* <Link to='/dashboard/profile' state={state?.id} style={{textDecoration:'none'}} >
            <Typography display='flex' justifyContent='start' sx={{color:'#000000', fontWeight:600, fontSize:'13px', letterSpacing:0.5, mt:3,textAlign:'start'}}>
              <LessThanIcon/> Back to Company Profile
            </Typography>
          </Link> */}
    </CardBody>
    
    {openTaskPopup? <CreateTaskForm For={For} title={route} taskedit={taskedit} openTaskPopup={openTaskPopup} setOpenTaskPopup={setOpenTaskPopup} update={update} setUpdate={setUpdate} />:<></> }
    </>
  );
}