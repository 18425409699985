import React,{useState, useEffect}from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Grid, IconButton, Typography, Button, Tooltip, CircularProgress } from '@mui/material';
import {T1ButtonText, T6TableText, H5FormLabel, H2POPupMainText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import { followupAssigned, getAllWorkingDays } from '../../Redux/Thunk/createTaskthunk';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import { addDays } from 'date-fns';
import MeetingIcon from '../../components/custom/MeetingIcon';
import { CustemBtn, TaskIcon,SelectDropDown, TextArea, DisableAssignIcon, AssignIcon} from '../../components/custom';
import CallIcon from '../../components/custom/CallIcon';
import EmailIcon from '../../components/custom/EmailIcon';
import validate  from "../../validation/validation";
import DisplayError from '../../validation/DisplayError';
import DemoIcon from '../../components/custom/DemoIcon';
import swal from 'sweetalert';
import { TailSpin} from 'react-loader-spinner';
import { CreateEditFollowup, getServiceProviderTypesList } from '../../Redux/Thunk/createsettinglist';
import { leadLost, leaddetails } from '../../Redux/Thunk/createleadthunk';
import { FollowupDetail } from './FollowupDetail';
import { useNavigate } from 'react-router-dom';
import OpportunityForm from '../Opportunity/OpportunityForm';
import AssignModal from '../../components/custom/AssignModal';
import CreateNewFollowup from './CreateNewFollowup';
import CloseIcon from '../../components/custom/CloseIcon';
import AdminRole from '../../adminrole/Adminrole';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';

const callResponces =[
    { value:'Contacted Call Back',label:"Contacted Call Back"}, { value:'Contacted Discussed',label:"Contacted Discussed"}, { value:'No Response',label:"No Response"},{ value:'No Valid Number',label:"No Valid Number"},{ value:'Not Contactable',label:"Not Contactable"}
    ,{ value:'Not Reachable',label:"Not Reachable"}, { value:'Phone Switched Off',label: "Phone Switched Off"}
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

const CardBody = styled('div')({
    backgroundColor:'#FFFFFF',
    padding:'20px',
    borderRadius:'10px',
    height:'200px',
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
})

const Container = styled('div')({
    padding: '5px',
    borderRadius: '10px',
    width: '100%',
    height: '100%', // This ensures the border covers the entire height
    border: '2px dotted var(--dark-grey, #858585)',
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
});

export default function CreateFollowup({leadDetails, user_ids, setUpdate, update}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const [openCreateFollowup, setOpenCreateFollowup] = useState(false)
    const [displayWorkingDays, setDisplayWorkingDays] = useState([])
    const [startDate, setStartDate] = useState('')
    const [showcalender, setShowCalender] = useState(false)
    const [selectedDate, setSelectedDate] = useState('');
    const [createNewFollowup, setCreateNewFollowup] = useState(false)
    const [createNewFollowupDate, setCreateNewFollowupDate] = useState('');
    const [createNewFollowupHour, setCreateNewFollowupHour] = useState('');
    const [createNewFollowupMinute, setCreateNewFollowupMinute] = useState(''); 
    const [selectedMinute, setSelectedMinute] = useState('');
    const [selectedHour, setSelectedHour] =useState('')
    const [selectedAmPm, setSelectedAmPm] = useState('AM')
    const [selectedType, setSelectedType]= useState('')
    const [createNewFollowupAmPm, setCreateNewFollowupAmPm] = useState('AM')
    const [error, setError] = useState([])
    const [createFollowUpType, setCreateFollowUpType] = useState('')
    const [createNewFollowUpType, setCreateNewFollowUpType] = useState('')
    const [displayErrorMsg, setDisplayErrorMsg] = useState(false)
    const [loader, setLoader]= useState(false)
    const [MissingDeadLine, setMissingDeadLine] = useState('')
    const [closingRemark, setclosingRemark] = useState('');
    const [callResponce, setCallResponce] = useState('')
    const [followupCreateClosed, setFollowupCreateClosed] = useState(false)
    const [leadLostButton, setLeadLostButton] = useState(false)
    const [leadLostReason, setLeadLostReason] = useState('')
    const [convertToOpportunity, setConvertToOpportunity]= useState(false)
    const [createNewFollowupPage, setCreateNewFollowupPage] =useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [openAssignModal,setOpenAssignModal] = useState(false)
    const [followupassigned, setFollowupAssigned]= useState({})
    const [createNewDisplayErrorMsg, setCreateNewDisplayErrorMsg] = useState(false)
    const [followupClosed, setfollowupClosed] = useState(Object.keys(leadDetails?.getrecentfollowp).length > 0 ? leadDetails?.getrecentfollowp :{})
    const currentDate = new Date()
    const tomorrowDate = new Date(followupClosed?.followup_date) 
    tomorrowDate.setDate(tomorrowDate.getDate() + 1)
    const [closedFollowupDate, setClosedFollowupDate] = useState('')
    const [openOpportunityModel, setOpenOpportunityModel] = useState(false)
    const [editOpportunityDetails, setEditOpportunityDetails] = useState({})
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [loaderConvertToOpportunity, setLoaderConvertToOpportunity] = useState(false)

    const route ="Create followup"
    const title = 'Follow-up'

    useEffect(()=>{
        dispatch(getAllWorkingDays({workingdays:7})).then((res)=>{
         setDisplayWorkingDays(res?.payload?.data)
        }) 
    },[])

    let validation_input = [
        Object.keys(followupClosed)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ?'value':'date', text:createNewFollowup === true ? startDate ? '1233':createNewFollowupDate :startDate ? '1233': selectedDate, regex_name: 'free_text', required: true, errmsg: 'Please Select Date.'} :<></>,
        Object.keys(followupClosed)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ? "createMin":'min', text: createNewFollowup === true ? createNewFollowupMinute: selectedMinute, regex_name: 'any_number', required: true, errmsg: 'Please Select Minute.', min:1} :<></>,
        Object.keys(followupClosed)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ? "createHr":'hr', text: createNewFollowup === true ? createNewFollowupHour?.toString(): selectedHour?.toString(), regex_name: 'any_number', required: true, errmsg: 'Please Select Hour.', min:1} :<></>,
        // {tagid:createNewFollowup === true ? "createampm":'am_pm', text: createNewFollowup === true ? createNewFollowupAmPm: selectedAmPm, regex_name: 'text', required: true, errmsg: 'Please Select AM/PM'},
        Object.keys(followupClosed)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ? 'createType':'type', text: createNewFollowup === true ? createNewFollowUpType : selectedType, required: true, regex_name:'text', errmsg: 'Please Select Type.'}:<></>,
        currentDate >  tomorrowDate ? {tagid:'missingDeadLineRemark', text: MissingDeadLine, required: true, regex_name: 'free_text', errmsg: 'Please add the missing dead line remark between 100 to 2000 characters', min:100, max:2000 } :<></>,
        Object.keys(followupClosed)?.length > 0? {tagid:'closingRemark', text: closingRemark, required: true,regex_name:'free_text', errmsg: 'Please add the closing Remark between 100 to 2000 characters', min:100, max:2000 } :<></>,
        followupClosed?.followup_type ==="Call" ? {tagid:'callResponce', text: callResponce, required: true, regex_name:'text', allow_numbers: true, other_chars: true, errmsg:'Please Select Type.'} :<></>,
    ]

    const handleClickCreateFollowup = () =>{
        if(permissionsDetails?.perm_followup_create){
            setOpenCreateFollowup(true)
        }else{     
        }  
    }

    const changeStartDate = (newValue) => {
        setShowCalender(false)
        if(createNewFollowup) {
         setCreateNewFollowupDate(newValue?.$d.toString());
        } else {
            setSelectedDate(newValue?.$d.toString())
        }
        setStartDate(newValue?.$d);
    }

    const handleButtonClick = (number) => {
        // Handle button click event
       if(createNewFollowup === true){
           setCreateNewFollowupHour(number)
           selectedHour('')
       }else{
           setSelectedHour(number,"numbeeeeeee")
           setCreateNewFollowupHour('')
       }
    };

    const handleOfCallResponce =(event)=>{
        setCallResponce(event.target.value)
    }

    const handleOfMissingDeadLine =(event)=>{
        setMissingDeadLine(event.target.value)
    }

    const handleOfLeadLost =(event)=>{
        setLeadLostReason(event.target.value)
    }

    const handleOfClosingRemark=(event)=>{
        setclosingRemark(event.target.value)
    }

    const handleCalender =() => {
        setSelectedDate('')
        setCreateNewFollowupDate('')
        setShowCalender(true)
        setDisableButton(true)
    }

    const sumbitDate = (label) => {
        if(createNewFollowup === true){
            setShowCalender(false)
            setStartDate('')
            setCreateNewFollowupDate(label?.date);
            setCreateNewFollowupHour('')
            setCreateNewFollowupMinute('')
            // setDisableButton(true)
        }else{
            setShowCalender(false)
            setStartDate('')
            setSelectedDate(label?.date)
            setSelectedHour('')
            // setSelectedAmPm('')
            setSelectedMinute('')
            setDisableButton(true)
        }
    };

    const renderHoursButtons = () => {
        const buttons = [];
        for (let i = 1; i <= 12; i++) {
          buttons.push(
            <Button
              id='hours'
              variant={selectedHour === i || createNewFollowupHour === i ? 'contained' : 'outlined'}
              // disabled={moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? selectedHour >= i :''}
              onClick={() => handleButtonClick(i)}
              sx={{
                padding: '5px 5px',
                minWidth:'50px',
                color: selectedHour === i || createNewFollowupHour === i ? '#FFFFFF' : '#444444',
                background: selectedHour === i || createNewFollowupHour === i ? '#4F709C' : 'transparent',
                marginLeft: '10px',
                "&:hover": {
                  background: selectedHour === i || createNewFollowupHour === i ? '#4F709C' : 'transparent',
                }
              }}
            >
              {i}
            </Button>
          );
        }
        return buttons;
    };
console.log(followupClosed,"followupClosed")
    const checkValidation = ()=>{
        if(createNewFollowup === true && (createNewFollowupMinute?.length === 0 || createNewFollowupAmPm?.length === 0)){
            setCreateNewDisplayErrorMsg(true)
            setDisplayErrorMsg(false)
        }else if(selectedAmPm?.length === 0 || selectedMinute?.length === 0 ){
            setDisplayErrorMsg(true)
            setCreateNewDisplayErrorMsg(false)
        }else{
            setDisplayErrorMsg(false)
            setCreateNewDisplayErrorMsg(false)
        }
    }

    const callFollowupAPI=(timestamp)=>{
        const myJson= {
            create :{
                // companyid:checked === true ? (title==="Alert List" )? editFollowupDetails?.company?.id :editFollowupDetails?.company :(title ==="Opportunity List"|| title==='companyProfilePageOpportunityDetails'||title==="OpportunityDetailsPage") ? selectedOpportunityDetails?.company?.id :(title ==="Lead List"|| title ==="companyProfilePageLeadDetails" || title==="LeadDetailsPage" ||title==="DashboardLeadDetails")? selectedLeadDetails?.company?.id : selectedLeadDetails?.company,
                companyid: leadDetails?.leaddetails?.company?.id,
                // followup_for_id:createNewFollowup === true ? editFollowupDetails?.followup_for_id : selectedOpportunityDetails?.id ? selectedOpportunityDetails?.id :selectedLeadDetails?.id ,
                // followup_for_id: leadDetails?.leaddetails?.id,  //Lead id
                followup_for_id:createNewFollowup === true ? followupClosed?.followup_for_id : Object.keys(followupClosed).length > 0 && (leadLostButton === true || convertToOpportunity === true)?'' : leadDetails?.leaddetails?.id,  
                operationmode:'create',
                followup_date: timestamp,
                followup_type: createNewFollowup === true ? createNewFollowUpType :selectedType,   //type
                followup_for: 'lead' ,
            },
            edit:{
                followupid: followupClosed?.id, 
                operationmode:'edit',
                summary: closingRemark,
                missing_deadline_mark: MissingDeadLine, 
                call_response: callResponce ,
                lead_action: createNewFollowup === true ? 'New Followup Create' : leadLostButton === true ? 'Lost' :'Convert to Opportunity'
            }
        }

        setDisableButton(false)
        console.log(myJson,"jsonvalueeeeeeeeeee")

        dispatch(CreateEditFollowup(myJson)).then((res)=>{
              setDisableButton(false)
                if(res?.payload?.errormsg){
                    sweetalert("error", res?.payload?.errormsg)
                    setLoader(false)
                }else{
                    setLoader(false)
                    //  setDisableButton(false)
                    if(myJson.create?.followup_for_id && myJson.edit?.followupid){
                        setFollowupCreateClosed(true)
                        setOpenCreateFollowup(false)
                        setUpdate(!update)
                        sweetalert('success',res?.payload?.data)
                    }else if(myJson.create?.followup_for_id){ 
                        setFollowupCreateClosed(true)
                        setUpdate(!update)
                        setOpenCreateFollowup(false)
                        sweetalert('success', `Follow-up (${res?.payload?.data?.followupid}) Successfully Created for the Lead (${leadDetails?.leaddetails?.lead_no}).`)
                    }else{
                        // if(convertToOpportunity === true){
                        //     console.log('hellllllpppppppppppp')
                        //     setOpenOpportunityModel(true)
                            // swal({
                            //     buttons: {
                            //         Delete: {
                            //             text: "Convert to Opportunity",
                            //             value: "Yes",
                            //             className: "popup-gray1",
                            //         },
                            //     },
                            //     title: `Followup ${followupClosed?.followup_no} for Lead ${leadDetails?.leaddetails?.lead_no} is Successfully Closed. Please Convert this Lead into Opportunity.`,
                            //     className: "custom-modal",
                            //     closeOnClickOutside: false,
                            // }).then((value) => {
                            //     if (value == 'Yes') {
                            //         setOpenOpportunityModel(true)
                            //         // navigate('/dashboard/opportunity', {state :{title: route, company: leadDetails?.company, leadDetails:leadDetails, operation:'createOpp'}})
                            //     }
                            // })
                        // }
                    }
                }
        })
    }

    const submitNewFollowup =() =>{
        if(validate(validation_input).length === 0){
            const date = new Date(selectedDate);
            date.setHours(
            parseInt(selectedHour) + (selectedAmPm === 'PM' && selectedHour !== '12' ? 12 : 0),
            parseInt(selectedMinute),  0, 0 );
            const timestamp = date?.getTime();
            const createdate = new Date(createNewFollowupDate);
            createdate.setHours(
            parseInt(createNewFollowupHour) + (createNewFollowupAmPm === 'PM' && createNewFollowupHour !== '12' ? 12 : 0),
            parseInt(createNewFollowupMinute),  0, 0 );
            const timestamp1 = createdate?.getTime();
            const now = moment()
            if(timestamp <= now.valueOf() || timestamp1 <= now.valueOf()){
                sweetalert("error","Time selection can not be for the past, please select future time.")
                setLoader(false)
            }else{
              if(leadLostButton === true){
                swal({
                    buttons: {
                        Delete: {
                            text: "No",
                            value: "No",
                            className: "popup-gray",
                        },
                        Cancel: {
                            text: "Yes",
                            value: "Yes",
                            className: "popup-blue",
                        }
                    },
                    title: `Do you want close the Follow-up (${followupClosed?.followup_no}) with LOST status for Lead (${leadDetails?.leaddetails?.lead_no})`,
                    className: "custom-modal",
                    closeOnClickOutside: false,
                }).then((value) => {
                    if (value == 'Yes') {
                        callFollowupAPI(timestamp)
                        dispatch(leadLost({lead_id: leadDetails?.leaddetails?.id , lost_reason:closingRemark })).then((res)=>{
                            if(res?.payload?.errormsg){
                                sweetalert("error", res?.payload?.errormsg)
                            }else{
                                sweetalert('success', res?.payload?.data?.msg)
                            }
                        })
                    } else {
                      console.log("HEllooo")
                      setLoader(false)
                    }
                })
              }else if(createNewFollowup === true){
                callFollowupAPI(timestamp1)
              }else if(convertToOpportunity === true){
                setOpenOpportunityModel(true)
                dispatch(leaddetails(leadDetails?.leaddetails?.id))
                setEditOpportunityDetails({})
                dispatch(displayCompanyDetails(leadDetails?.leaddetails?.company?.id))
                setClosedFollowupDate(timestamp)
              }else{
                callFollowupAPI(timestamp)
              } 
        }    
        }else{
            setError(validate(validation_input))
            // checkValidation()
        }
    }

    const sweetalert = (variant, text) => {
        swal({
          icon: variant==='error' ? 'error' :'success',
          title: text , 
          button: "Ok!",
        }); 
    }

console.log(selectedAmPm?.length, selectedType?.length, selectedDate?.length, selectedMinute?.length, "selectedAmPm?.length")
    // Handler for selecting minute
    const sumbitMinute = (label) => {
        if(createNewFollowup === true){
            setCreateNewFollowupMinute(label)
            setSelectedMinute('')
        }else{
            setSelectedMinute(label);
            setCreateNewFollowupMinute('')
        }
    };

    const renderMinutesButtons = () => {
        const numbers = ['00', '15', '30', '45'];
        return numbers.map((number) => (
          <Grid item key={number} xs={1}>
            <Button
                id='minutes'
                variant={selectedMinute === number || createNewFollowupMinute === number ? "contained" : "outlined"}
                onClick={() => sumbitMinute(number)}
                fullWidth
                // disabled={moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? selectedMinute >= number :''}
                sx={{
                    // borderRadius: '5px',
                     //marginLeft: '0px',
                    padding: '5px 5px',
                    background: selectedMinute === number || createNewFollowupMinute === number ? '#4F709C' : 'transparent',
                    color: selectedMinute === number ||createNewFollowupMinute === number ? '#FFFFFF' : '#444444',
                    // fontWeight: selectedMinute === number || createNewFollowupMinute === number ? 600 : 400,
                    minWidth:'50px',
                    "&:hover": {
                        background: selectedMinute === number || createNewFollowupMinute === number ? '#4F709C' : 'transparent', 
                    }
                }}
                >
                {number}
            </Button>
          </Grid>
        ));
    };

    const submitAmPm=(number, buttonType)=>{
        if(buttonType === "AM"){
            if(createNewFollowup === true){
                setCreateNewFollowupAmPm(buttonType)
            }else{
                setSelectedAmPm(buttonType)
                console.log(buttonType,"buttttttttt")
            }
        }else{
            if(createNewFollowup === true){
                setCreateNewFollowupAmPm(buttonType)
            }else{
                setSelectedAmPm(buttonType)
            }       
        }
    }

    const submitIcon = (number)=>{
        if(createNewFollowup === true){
            setCreateNewFollowUpType(number?.label)
        }else{
            setSelectedType(number?.label)
        }
    }

    const renderIconsButtons =() =>{
        const numbers = [
        {   
            label :'Meeting',
            value : <MeetingIcon name={selectedType === 'Meeting' || createNewFollowUpType === 'Meeting'}/>
        }, 
        {   
            label :'Demo',
            value : <DemoIcon name={selectedType === 'Demo' || createNewFollowUpType === 'Demo'} />
        },
        {   
            label :'Call',
            value : <CallIcon name={selectedType === 'Call' || createNewFollowUpType === 'Call'}/>
        },
        {   
            label :'Email',
            value : <EmailIcon name={selectedType === 'Email' || createNewFollowUpType === 'Email'}/> 
        } 
     ];
        return numbers.map((number) => (
          <Grid item key={number} xs={1}>
            <Tooltip title={number?.label === 'Email'? 'Email' : number?.label === 'Call' ? 'Call' : number?.label === 'Demo'?'Demo': 'Metting'} placement="top-start">
            <Button
                id='followupType'
                variant={selectedType === number?.label || createNewFollowUpType === number?.label ? "contained" : "outlined"}
                onClick={() => submitIcon(number)}
                fullWidth
                // disabled={moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? selectedMinute >= number :''}
                sx={{
                    // borderRadius: '5px',
                     //marginLeft: '0px',
                    padding: '5px 5px',
                    background: selectedType === number?.label || createNewFollowUpType === number?.label ? '#4F709C' : 'transparent',
                    color: selectedType === number?.label ||createNewFollowUpType === number?.label ? '#FFFFFF' : '#444444',
                    // fontWeight: selectedType === number?.label || createNewFollowUpType === number?.label ? 600 : 400,
                    minWidth:'50px',
                    "&:hover": {
                        background: selectedType === number?.label || createNewFollowUpType === number?.label ? '#4F709C' : 'transparent', 
                    }
                }}
                >
                {number?.value}
            </Button>
            </Tooltip>
          </Grid>
        ));
    }

    const handleClickCreateNewFollowup = ()=>{
        setConvertToOpportunity(false) 
        setLeadLostButton(false)
        setDisableButton(true)
        setCreateNewFollowupPage(true)
        setCreateNewFollowup(true)
    }
    
    const handleClickConvertToOpportunity = ()=>{
        setConvertToOpportunity(true) 
        setLeadLostButton(false)
        setCreateNewFollowupPage(false)
        setDisableButton(true)
        setCreateNewFollowup(false)
    }

    const findIds= (id1, id2) => {
        if (user_ids.includes(id1) || user_ids.includes(id2)) {
          return true 
        } else {
          return false
        }
    }

    const handleAssign = (row) => {
        setFollowupAssigned(row)
        setOpenAssignModal(true)
    }

    const saveAssignedManagerOrExecutiveName = (managerList, selectManagerName)=>{
        if(selectManagerName ===''){
          sweetalert ('error', "Please Select Name") 
        }else{
          let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectManagerName)
              dispatch(followupAssigned({followupid:followupClosed?.id, user_id:findUser?.id})).then((res)=>{
                if(res?.payload?.errormsg){
                  sweetalert ('error', res?.payload?.errormsg) 
                  setOpenAssignModal(false)
                }else{
                  sweetalert ('success', "Follow-up Assigned Successfully.") 
                  setOpenAssignModal(false)
                  setUpdate(!update)
                }
          })
        }
    }

    const handleChangedClosed =() =>{
        setOpenCreateFollowup(false)
    }

    const handleChangedCreateOpportuntiy = (id)=>{
        setLoaderConvertToOpportunity(true)
        dispatch(leaddetails(leadDetails?.leaddetails?.id)).then((res) =>{
            dispatch(displayCompanyDetails(id))
            dispatch(getServiceProviderTypesList())
            setLoaderConvertToOpportunity(false)
            setOpenOpportunityModel(true)
            setEditOpportunityDetails({})
        })
        // setEditOpportunityDetails({})
        // setUpdate(!update)
    }

    const handleInputBlur = () => {
      setIsDatePickerOpen(false);
    };

    const openOpportunityDetailsPage = () =>{
        navigate('/dashboard/opportunitydetails', {state: {oppDetails: leadDetails?.getoppodetails?.id}})
    }

    return(
    <>
    <Grid sx={{ borderBottom: '1px solid #858585', mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Grid item xs={8}>
            <T1ButtonText sx={{color: themeColor.palette.Primarytext1}}>
                Activities
            </T1ButtonText>
        </Grid>
        {/* {leadDetails?.leaddetails?.status === 'Validated'?
        <Grid sx={{display:'flex', alignItems:'end'}}> */}
           {leadDetails?.leaddetails?.status  === 'Lost' || leadDetails?.leaddetails?.status  === 'Closed-No-Action'|| leadDetails?.leaddetails?.status  === 'Converted'
            ? <></> : leadDetails?.getfollowup?.length === 0 && (leadDetails?.leaddetails?.validated_status ==="Validated") && (permissionsDetails?.perm_opportunity_create && (user?.data?.user?.id == leadDetails?.leaddetails.assigned_to?.id) || (checkAdminRole && permissionsDetails?.perm_opportunity_create)) ?     
                <Grid sx={{ display: 'flex', ml:1, justifyContent: 'end' }} item xs={4}>
                    <Button
                         id= 'convert_to_opportunity' sx={{ mb:1,  textTransform: 'none', 
                            fontSize: '12px', padding: '1px 20px', alignItems: 'center', gap: '5px', fontWeight: 600, backgroundColor: '#FFFFFF', color: 'black', border: '1px solid black', borderRadius: '5px',
                            "&:hover": { backgroundColor: "#FFFFFF" }
                        }} variant='contained' onClick={() => handleChangedCreateOpportuntiy(leadDetails?.leaddetails?.company?.id)}
                         >
                         {loaderConvertToOpportunity ? <CircularProgress size={20} color="inherit" /> : 'Convert To Opportunity'}
                       </Button>
              


                    {/* <CustemBtn id= 'convert_to_opportunity' sx={{ mb:1, 
                        fontSize: '12px',padding: '1px 20px', alignItems: 'center',gap: '5px', fontWeight: 600, backgroundColor: '#FFFFFF', color: 'black', border: '1px solid black', borderRadius: '5px',
                        "&:hover": { backgroundColor: "#FFFFFF" }
                    }}
                        variant='contained' text="Convert To Opportunity" onClick={() => handleChangedCreateOpportuntiy(leadDetails?.leaddetails?.company?.id)}/>             */}
                </Grid>
                :<></>} 
        {/* </Grid> :<></>} */}
    </Grid>
   
    {(!openCreateFollowup && leadDetails?.getfollowup?.length === 0) ?
     <>
        <CardBody>
        <Container>
        {leadDetails?.leaddetails?.status === "Lost" || leadDetails?.leaddetails?.status === "Closed-No-Action" || leadDetails?.leaddetails?.status === "Converted" || !permissionsDetails?.perm_followup_create || (checkAdminRole && !permissionsDetails?.perm_followup_create)? <> 
            <H2POPupMainText sx={{color: themeColor.palette.Primarytext1}}>{(checkAdminRole && !permissionsDetails?.perm_followup_create)|| !permissionsDetails?.perm_followup_create && (leadDetails?.leaddetails?.status === "Open" || leadDetails?.leaddetails?.status === "WIP") ? 'No Follow-up Created yet.' : leadDetails?.leaddetails?.status === "Lost" || leadDetails?.leaddetails?.status === "Closed-No-Action" ? `This lead has been Lost` :(
                <span>
                    This lead has been Converted to Opportunity <span id='convert' style={{ color: '#FE8731', cursor:'pointer'}} onClick={openOpportunityDetailsPage}>({leadDetails?.getoppodetails?.opportunity_no ?? ''})</span>.
                </span>
            )}</H2POPupMainText>   
        </>:<>
        {
            (user?.data?.user?.id == leadDetails?.leaddetails.assigned_to?.id || (checkAdminRole && permissionsDetails?.perm_followup_create)) ? <>
            <Grid item xs={12} md={10} sm={10} lg={12} sx={{display: 'flex', mt:1, justifyContent: 'center', alignItems: 'center'}}>  
            <IconButton id= 'create_followup' onClick={handleClickCreateFollowup}>
              <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.0011 45.0725C35.3624 45.0725 44.5725 35.8624 44.5725 24.5011C44.5725 13.1398 35.3624 3.92969 24.0011 3.92969C12.6398 3.92969 3.42969 13.1398 3.42969 24.5011C3.42969 35.8624 12.6398 45.0725 24.0011 45.0725Z" stroke="#088F05" stroke-width="3.42857" stroke-miterlimit="10" />
                <path d="M15.4297 24.5H32.5725" stroke="#088F05" stroke-width="3.42857" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M24 15.9297V33.0725" stroke="#088F05" stroke-width="3.42857" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </IconButton>
          </Grid>
          <Grid item xs={12} md={10} sm={10} lg={12} >
            <H2POPupMainText sx={{color: themeColor.palette.Primarytext1}}>Create Follow Up</H2POPupMainText>
          </Grid>
            
            </> :  <H2POPupMainText sx={{color: themeColor.palette.Primarytext1}}>No Followup Created Yet</H2POPupMainText>
        }
          
          </>}
        </Container>
        </CardBody>
        </> : <Box sx={{backgroundColor:'#FFFFFF', paddingLeft:'10px', paddingRight:'10px',}}>
         <Box style={{ display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
            {openCreateFollowup ? <><div>
                {/* <CreateNewFollowup displayWorkingDays={displayWorkingDays} leadDetails={leadDetails} disableButton={disableButton} setDisableButton={setDisableButton}
                selectedMinute={selectedMinute} selectedHour={selectedHour} selectedType={selectedType} selectedAmPm={selectedAmPm}/> */}
                <Grid container spacing={2}>
                    
                    <Grid item xs={10}>
                        <T6TableText sx={{ mt: 1, color: '#ABABAB' }}>
                        Scheduled Date & Time <span style={{ color: '#FF0000' }}>*</span>
                        </T6TableText>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', mt:0.5,justifyContent: 'flex-end' }}>
                        <span onClick={handleChangedClosed} id='close_button' style={{ cursor: 'pointer' }}>
                            <CloseIcon w={25} h={25} />
                        </span>
                    </Grid>
                   
                    {displayWorkingDays.map((label, index) => (
                        <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                            <Button
                              id='working_days'
                                variant={selectedDate === label?.date? 'contained' : 'outlined'}
                                onClick={() => sumbitDate(label)}
                                fullWidth
                                name='date'
                                sx={{
                                    borderRadius: '8px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '10px 20px',
                                    border:label?.holiday ===true ?'1px solid #E70707':'1px solid #4F709C',
                                    bgcolor: selectedDate === label?.date ? '#4F709C' :'transparent',
                                    // color: selectedDate === label?.day? '#FFFFFF' : '#6E7100',
                                    fontWeight: selectedDate === label?.date ? 600 : 400,
                                    "&:hover": {
                                        bgcolor: selectedDate === label?.date ? '#4F709C' :'transparent'}
                                }}
                        >
                        <T6TableText  sx={{ lineHeight: 1, mb:0.5, color: selectedDate === label?.date ? themeColor.palette.White: themeColor.palette.FollowupID }}>{moment(label?.date).format("dddd")}</T6TableText>
                        <T6TableText sx={{ lineHeight: 1, color: selectedDate === label?.date ? themeColor.palette.White: themeColor.palette.primarytext2, mt:0.5 }}>{moment(label?.date).format(" Do MMMM ")}</T6TableText>
                            </Button>   
                        </Grid>
                    ))} 
                    
                    <Grid item xs={6} sm={4} md={3} lg={2} sx={{ paddingTop: '10px' }}>
                        <Button   
                            variant={startDate ? 'outlined' : 'contained'}
                            // onClick={() => sumbitDate(label)}
                            fullWidth
                            id='date'
                            name='date'
                            sx={{
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border:'1px solid #4F709C',
                                padding: '10px 20px',
                                bgcolor: startDate ? '#4F709C' :'transparent',
                                // bgcolor: startDate ? '#8AA000' :'#344feb',
                                // color: selectedDate === label?.day? '#FFFFFF' : '#6E7100',
                                fontWeight: startDate ? 600 : 400,
                                "&:hover": {
                                    bgcolor: startDate? '#4F709C' :'transparent'}
                            }}
                            >
                        {
                        (
                        () => {
                        if(showcalender) {
                            return (
                            <>
                                <T6TableText sx={{lineHeight: 1, mb:0.5, color: themeColor.palette.FollowupID}}>See More</T6TableText>
                                <T6TableText sx={{lineHeight: 1, color: themeColor.palette.primarytext2, mt:0.5 }}>Click Here</T6TableText>
                        <DatePickerOpenTo
                            id='datepicker'
                            open={true}
                            name='startDate'
                            openTo="day"
                            views={['year', 'month', 'day']}
                            value={startDate}
                            onChange={changeStartDate}
                            onClose={() => setShowCalender(false)}
                            // onFocus={() => setIsDatePickerOpen(true)}
                            // onBlur={handleInputBlur}
                            minDate={addDays(new Date(displayWorkingDays[displayWorkingDays?.length - 1].date), 1)}
                            maxDate={addDays(new Date(displayWorkingDays[displayWorkingDays?.length - 1].date), 23)}
                            style={{ height:'0px', visibility:'hidden',backgroundColor:  '#ffffff', fontSize: '14px', lineHeight: '17px', width: '100%', p: 0.1, mt: 1, pl: 1 }} 
                        />
                            </>
                            )
                        } else if(startDate) {
                            return (
                                <>
                                    <T6TableText sx={{ lineHeight: 1, mb:0.5, color: startDate ? themeColor.palette.White: themeColor.palette.FollowupID}}>{moment(startDate).format("dddd")}</T6TableText>
                                    <T6TableText sx={{lineHeight: 1, color: startDate ? themeColor.palette.White : themeColor.palette.primarytext2, mt:0.5 }}>{moment(startDate).format(" Do MMMM ")}</T6TableText>
                                </>
                            )
                        } 
                        else {
                            return (
                                <Box onClick={() => handleCalender()}>
                                    <T6TableText sx={{lineHeight: 1, mb:0.5, color: themeColor.palette.FollowupID}}>See More</T6TableText>
                                    <T6TableText sx={{lineHeight: 1, color: themeColor.palette.primarytext2, mt:1 }}>Click Here</T6TableText>
                                </Box>
                            )
                        }
                        }
                        )()
                        }
                        </Button> 
                        <DisplayError error={error} fortagid='date' />    
                    </Grid>
                     

                    {/* <Box style={{ marginTop: "30px", display: 'flex', flexDirection: { sm: 'column', xs: 'column', lg: 'row' } }}> */}
                    <Grid container spacing={2} marginTop= "10px" marginLeft='0.1px' alignItems="center"> {/* Added alignItems="center" */}
                        <Grid item xs={12} md={6} sm={12} lg={1}>
                        <T6TableText style={{ textAlign: 'center', color: themeColor.palette.FollowupID}}>Hours</T6TableText>
                        </Grid>
                        <Grid item lg={11} container spacing={1} justifyContent="space-between"> 
                            {renderHoursButtons()}
                        </Grid>
                        <div style={{marginLeft:'100px'}}><DisplayError error={error} fortagid='hr'/></div>
                    </Grid>
                    {/* </Box> */}
                    
                    {/* <Box style={{marginTop:"30px", display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}> */}
                    <Grid container spacing={2} marginTop="10px" marginLeft="0.1px" alignItems= 'center'>
                        <Grid item xs={12} md={6} sm={12} lg={1}>
                            <T6TableText sx={{textAlign: 'center', color: themeColor.palette.FollowupID}}>Minutes</T6TableText>
                        </Grid>
                        <Grid item xs={6} container spacing={1} justifyContent="space-between">
                            {renderMinutesButtons()}
                            <Button id='am' variant="outlined" style={{ marginLeft: '10px', padding: '5px 5px', minWidth: '50px', background: selectedAmPm === 'AM' ? themeColor.palette.FollowupID : 'transparent', marginTop: 'auto', color: selectedAmPm === 'AM' ? '#FFFFFF' : '#444444' }} onClick={() => submitAmPm(null, 'AM')}>
                                AM
                            </Button>
                            <Button id='pm' variant="outlined" style={{minWidth: '50px', padding: '5px 5px', marginTop: 'auto', background: selectedAmPm === 'PM' ? themeColor.palette.FollowupID : '#FFFFFF', color: selectedAmPm === 'PM' ? '#FFFFFF' : '#444444' }} onClick={() => submitAmPm(null, 'PM')}>
                                PM
                            </Button>   
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                        <div style={{marginLeft:'90px'}}><DisplayError error={error} fortagid='min'/></div>
                        {/* { displayErrorMsg ? <Typography style={{marginTop:'5px',color:'red', fontSize:'13px',marginLeft:'100px'}}>Please Select Minutes and AM/PM</Typography> :<></>} */}
                    </Grid>   
                        
                   {/* </Box> */}
      
`                  <Grid container spacing={2} marginLeft='0.1px' alignItems="center">
                        <Grid item xs={12} md={6} sm={12} lg={1}>
                            <T6TableText style={{ textAlign:'start', color:'#ABABAB'}}>Type</T6TableText>
                        </Grid>
                        <Grid item lg={3} container spacing={1} justifyContent="space-between">
                             {renderIconsButtons()}
                        </Grid>   
                   </Grid> 
                   <div style={{marginLeft:'100px'}}><DisplayError error={error} fortagid='type' /></div>

                   <Grid container spacing={2} marginTop='10px' marginLeft='0.1px'>
                        <Grid item xs={12} md={6} sm={12} lg={12}>
                            <T6TableText style={{color:'#ABABAB'}}>Summary <span style={{marginLeft:'12px', color: themeColor.palette.primarytext2}}>{(selectedType?.length === 0 || selectedHour?.length === 0 || selectedMinute?.length===  0 || selectedDate?.length ===  0 || selectedAmPm?.length === 0) ? '': `${selectedType} scheduling at ${selectedHour} : ${selectedMinute} ${selectedAmPm} on ${moment(selectedDate).format('D/M/YYYY')}.`}</span></T6TableText>
                        </Grid>
                    </Grid>  
                </Grid>  
                </div>        
                </> : <>
                {leadDetails?.leaddetails?.status === "Lost" || leadDetails?.leaddetails?.status === "Converted" || leadDetails?.leaddetails?.status === "Closed-No-Action" ?<></> : <>
                    <Grid container sx={{display:'flex', mt:1, flexDirection:'row', flexWrap:'wrap', justifyContent:'space-evenly'}} >
                        <Grid container sx={{ display: 'flex', mb:1,flexDirection:'row', justifyContent: 'space-between', paddingRight:'10px'}}>
                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}>Follow-up ID</H7TablePrimeContent> 
                            <H7TablePrimeContent sx={{color: themeColor.palette.FollowupID, mt:0.5 }}>{followupClosed?.followup_no}</H7TablePrimeContent>  
                        </Grid>
                    
                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}> Scheduled Date & Time</H7TablePrimeContent> 
                            <H7TablePrimeContent sx={{mt:0.5, color: themeColor.palette.primarytext2 }}>{moment(followupClosed?.followup_date).format('dddd DD MMM')} {moment(followupClosed?.followup_date).format("hh:mm A")}</H7TablePrimeContent>  
                        </Grid>
                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}>Type</H7TablePrimeContent> 
                            <H7TablePrimeContent sx={{mt:0.2, color: themeColor.palette.primarytext2}}> 
                                <Tooltip title={followupClosed?.followup_type ==="Call" ?"Call": 
                                    followupClosed?.followup_type ==="Meeting"? "Meeting": followupClosed?.followup_type ==="Email"? "Email":"Demo" } placement="top-start">
                                    <IconButton>
                                        {followupClosed?.followup_type ==="Call" ? <CallIcon/> :followupClosed?.followup_type ==="Meeting"? <MeetingIcon/>:followupClosed?.followup_type ==="Email"? <EmailIcon/> : <DemoIcon/>}
                                    </IconButton>
                                </Tooltip><span style={{color: themeColor.palette.primarytext2}}>{followupClosed?.followup_type ==="Call" ?"Call": 
                                    followupClosed?.followup_type ==="Meeting"? "Meeting": followupClosed?.followup_type ==="Email"? "Email":"Demo" }</span>
                            </H7TablePrimeContent>
                        </Grid>
                        <Grid item xs={12} md={4} sm={2} lg={4}>
                        <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}>Created By</H7TablePrimeContent> 
                        <H7TablePrimeContent sx={{color: themeColor.palette.primarytext2, mt:0.5}}>{followupClosed?.created_by?.firstname} {followupClosed?.created_by?.lastname}</H7TablePrimeContent>  
                        </Grid>

                        <Grid item xs={12} md={4} sm={2} lg={4}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}>Assigned To </H7TablePrimeContent> 
                            <H7TablePrimeContent sx={{color: themeColor.palette.primarytext2, mt:0.5}}><span>{followupClosed?.assigned_to?.firstname} {followupClosed?.assigned_to?.lastname}</span>{permissionsDetails?.perm_assign_edit || (checkAdminRole && permissionsDetails?.perm_assign_edit) ? '' :<></>}
                             {/* <Tooltip title="Assign Follow-up" placement="top-start">
                                   <IconButton onClick={()=> (!permissionsDetails?.perm_assign_edit || followupClosed?.closed_on != 0 || !findIds(followupClosed?.created_by?.id, followupClosed?.assigned_to?.id)) ? "" :handleAssign()}>{followupClosed?.closed_on != 0 ? <DisableAssignIcon/>: findIds(followupClosed.created_by.id, followupClosed.assigned_to.id) ? <AssignIcon /> :  <DisableAssignIcon/> }</IconButton> 
                                 </Tooltip>  */}
                                </H7TablePrimeContent>  
                        </Grid>
                        
                        <Grid item xs={12} md={4} sm={2} lg={4}></Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={4} sm={2} lg={2}>
                            <Typography sx={{fontWeight:600, fontSize:'14px'}}>Validation</Typography> 
                            <Typography sx={{mt:0.2}}>{leadDetails?.validated_status ==="Validated" ? <>
                            <Tooltip title="Lead is Validated" placement="top-start">
                            <IconButton>
                            <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.74789 5.85463C4.79433 5.90112 4.84947 5.938 4.91017 5.96316C4.97087 5.98832 5.03594 6.00128 5.10164 6.00128C5.16735 6.00128 5.23241 5.98832 5.29311 5.96316C5.35381 5.938 5.40896 5.90112 5.45539 5.85463L11.2479 0.147132C11.2943 0.100643 11.3495 0.063764 11.4102 0.0386019C11.4709 0.0134397 11.5359 0.000488281 11.6016 0.000488281C11.6674 0.000488281 11.7324 0.0134397 11.7931 0.0386019C11.8538 0.063764 11.909 0.100643 11.9554 0.147132L13.4554 1.62213C13.5019 1.66857 13.5388 1.72371 13.5639 1.78441C13.5891 1.84511 13.602 1.91017 13.602 1.97588C13.602 2.04159 13.5891 2.10665 13.5639 2.16735C13.5388 2.22805 13.5019 2.2832 13.4554 2.32963L5.43039 10.3546C5.38396 10.4011 5.32881 10.438 5.26811 10.4632C5.20741 10.4883 5.14235 10.5013 5.07664 10.5013C5.01094 10.5013 4.94587 10.4883 4.88517 10.4632C4.82447 10.438 4.76933 10.4011 4.72289 10.3546L0.247893 5.85463C0.154196 5.76087 0.101562 5.63375 0.101562 5.50119C0.101562 5.36864 0.154196 5.24152 0.247893 5.14776L1.74789 3.64776C1.79433 3.60127 1.84947 3.56439 1.91017 3.53923C1.97087 3.51407 2.03594 3.50111 2.10164 3.50111C2.16735 3.50111 2.23241 3.51407 2.29311 3.53923C2.35381 3.56439 2.40896 3.60127 2.45539 3.64776L4.74789 5.85463Z" fill="#03A500"/>
                                </svg> 
                            </IconButton>
                            </Tooltip> </> : leadDetails?.validated_status}</Typography>  
                        </Grid> */}
                
                        {leadDetails?.leaddetails?.status === "Closed-No-Action" ? <></>:((checkAdminRole && permissionsDetails?.perm_followup_create) || permissionsDetails?.perm_followup_create && (user?.data?.user?.id === leadDetails?.leaddetails?.assigned_to?.id)) ? <> {Object.keys(followupClosed).length >0 ? <>
                            {followupClosed?.followup_type ==="Call" ? <>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} sm={4} lg={6}>
                                <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, marginTop:'10px'}}>Call Response<span style={{color:'#FF0000'}}>*</span></H7TablePrimeContent>  
                                    <SelectDropDown 
                                        id='callResponce'
                                        name='callResponce'
                                        // disabled={followupClosed}
                                        placeholder ='Select Field'
                                        options={callResponces} 
                                        onchange={handleOfCallResponce}
                                        //  label='Select industry'
                                        value={callResponce}
                                        sx={{p:0.1, mt:0.5, borderRadius:"10px"}}
                                        MenuProps={MenuProps}
                                    />
                                    <DisplayError error={error} fortagid='callResponce' />
                                </Grid>
                                </Grid>
                            </>:<></>}

                            {((currentDate > tomorrowDate && closingRemark ===''|| (currentDate > tomorrowDate && closingRemark !=='')) || MissingDeadLine !== "" )? 
                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingRight:'15px'}} spacing={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
                                    <Grid item xs={12} md={6} sm={6} lg={12}>
                                        <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, marginTop:'10px'}}>Missing Deadline Remark<span style={{color:'#FF0000'}}>*</span></H7TablePrimeContent>  
                                        <Box marginRight="10px">
                                            <TextArea id='missingDeadLineRemark' name='missingDeadLineRemark' value={MissingDeadLine?.replace(/\s\s+/g, " ")} onChange={handleOfMissingDeadLine} style={{marginTop:'5px', width:'100%', fontFamily: 'Montserrat',fontStyle: 'normal', background:'#FFFFFF', border:"1px solid #B0AEFF", height:'80px',borderRadius:'8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding:'5px'}} placeholder ="Write a note about Missing Deadline remark."></TextArea>
                                        </Box> 
                                        <DisplayError error={error} fortagid='missingDeadLineRemark' />
                                    </Grid>
                                    <div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                        <span>{MissingDeadLine?.length}/2000</span>
                                    </div>
                            </Grid>
                            :<></>}

                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingRight:'10px'}} spacing={{ xs: 2, sm: 2, md: 2, lg: 1 }}> 
                                <Grid item xs={12} md={6} sm={6} lg={12}>
                                    <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, marginTop:'10px'}}>{leadLostButton ? 'Lost Remark' :'Closing Remark'}<span style={{color:'#FF0000'}}>*</span></H7TablePrimeContent>  
                                    <Box marginRight="10px">
                                        <TextArea name='closingRemark' value={closingRemark?.replace(/\s\s+/g, " ")} onChange={handleOfClosingRemark} style={{width:'100%', marginTop:'5px',fontFamily: 'Montserrat',fontStyle: 'normal', background:'#ffffff', border:"1px solid #B0AEFF", height:'80px',borderRadius:'8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding:'5px'}} placeholder ={leadLostButton ? "Write a note about lost remark." :"Write a note about closing remark."}></TextArea>
                                    </Box>
                                    <DisplayError error={error} fortagid='closingRemark' />
                                </Grid>
                                <div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                    <span>{closingRemark?.length}/2000</span>
                                </div>
                            </Grid>

                    </>
                    :<></>}

                    <Box style={{marginTop:'10px',display:'flex',justifyContent: 'space-between', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6} lg={12}>
                                <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}>Close this Follow-up with any one of the following options</H7TablePrimeContent>
                            </Grid>
                            <Box sx={{mt:1, mr:{xs:1, sm:1, xl:3, lg:3}, ml:6, mb:3, display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                <CustemBtn 
                                    // disabled={followupClosed}
                                    id='new_follow-Up'
                                    onClick={handleClickCreateNewFollowup}
                                    sx={{display:'flex', flexDirection:'row', mr:5, color: '#200E32', "&:hover": {color:'#200E32', backgroundColor: createNewFollowupPage ?'#EDEDFC':'#FFFFFF'}, border:'1px solid #858585', fontWeight:'500', borderRadius: '22px', ml:leadDetails?.validated_status==='Pending' ? 2: 0, pl:5, pr:5, fontSize: '16px', backgroundColor: createNewFollowupPage ? "#EDEDFC":'#FFFFFF' }}
                                    text='New Follow-Up'
                                />
                                
                                {leadDetails?.leaddetails?.validated_status === 'Pending' ?<></> :<>
                                    {(permissionsDetails?.perm_opportunity_create && (user?.data?.user?.id == leadDetails?.leaddetails.assigned_to?.id) || (checkAdminRole && permissionsDetails?.perm_opportunity_create)) ?<>
                                        <CustemBtn 
                                            id='convert_opporunity'
                                            onClick={handleClickConvertToOpportunity}
                                            sx={{display:'flex', flexDirection:'row', color: '#200E32', "&:hover": {color: '#200E32', backgroundColor: convertToOpportunity ?'#EDEDFC':'#FFFFFF'}, border:'1px solid #858585', fontWeight:'500', borderRadius:'22px', pl:5, pr:5, mr:5, fontSize: '16px', backgroundColor:convertToOpportunity ? '#EDEDFC':'#FFFFFF' }}
                                            text='Convert to Opportunity'
                                        /></>:<></>}
                                </>}
                                
                                <CustemBtn
                                    id='lead_lost'
                                    // disabled={followupClosed}
                                    onClick={()=> {setLeadLostButton(true) 
                                        setConvertToOpportunity(false)
                                        setCreateNewFollowupPage(false)
                                        setDisableButton(true)
                                        setCreateNewFollowup(false)
                                        setError([])}}
                                    sx={{display:'flex', borderRadius:'22px',flexDirection:'row', border:'1px solid #858585', fontWeight:'500', "&:hover": {color: '#200E32', backgroundColor: leadLostButton ?'#EDEDFC':'#FFFFFF'}, color: '#200E32', pl:10, pr:10, fontSize: '16px', backgroundColor: leadLostButton ?'#EDEDFC':'#FFFFFF' }}
                                    text="Lead Lost"
                                />                     
                            </Box>
                        </Grid>
                    </Box>
                    </>:<></>}
                    {createNewFollowup === true  ? <>   
                        <Box style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                            <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <T6TableText sx={{mt:1, color:'#ABABAB'}}> Scheduled Date & Time <span style={{color:'#FF0000'}}>*</span></T6TableText>
                                </Grid>
                                {displayWorkingDays.map((label, index) => (
                                    <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                        <Button
                                            id='working_days'
                                            variant={createNewFollowupDate === label?.date? 'contained' : 'outlined'}
                                            onClick={() => sumbitDate(label)}
                                            fullWidth
                                            name='date'
                                            sx={{
                                                borderRadius: '8px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: '10px 20px',
                                                border:label?.holiday ===true ?'1px solid #E70707':'1px solid #4F709C',
                                                bgcolor: createNewFollowupDate === label?.date ? '#4F709C' :'transparent',
                                                // color: selectedDate === label?.day? '#FFFFFF' : '#6E7100',
                                                fontWeight: createNewFollowupDate === label?.date ? 600 : 400,
                                                "&:hover": {
                                                    bgcolor: createNewFollowupDate === label?.date ? '#4F709C' :'transparent'}
                                            }}
                                        >
                                    <T6TableText  sx={{ lineHeight: 1, mb:0.5, color: createNewFollowupDate === label?.date ? themeColor.palette.White: themeColor.palette.FollowupID }}>{moment(label?.date).format("dddd")}</T6TableText>
                                    <T6TableText sx={{ lineHeight: 1, color: createNewFollowupDate === label?.date ? themeColor.palette.White: themeColor.palette.primarytext2, mt:0.5 }}>{moment(label?.date).format(" Do MMMM ")}</T6TableText>
                                        </Button>   
                                    </Grid>
                                ))} 
                                
                                <Grid item xs={6} sm={4} md={3} lg={2} sx={{ paddingTop: '10px' }}>
                                    <Button   
                                        variant={startDate ? 'outlined' : 'contained'}
                                        // onClick={() => sumbitDate(label)}
                                        fullWidth
                                        name='date'
                                        id='date'
                                        sx={{
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            border:'1px solid #4F709C',
                                            padding: '10px 20px',
                                            bgcolor: startDate ? '#4F709C' :'transparent',
                                            // bgcolor: startDate ? '#8AA000' :'#344feb',
                                            // color: selectedDate === label?.day? '#FFFFFF' : '#6E7100',
                                            fontWeight: startDate ? 600 : 400,
                                            "&:hover": {
                                                bgcolor: startDate? '#4F709C' :'transparent'}
                                        }}
                                        >
                                    {
                                    (
                                    () => {
                                    if(showcalender) {
                                        return (
                                        <>
                                            <T6TableText sx={{lineHeight: 1, mb:0.5, color: themeColor.palette.FollowupID}}>See More</T6TableText>
                                            <T6TableText sx={{lineHeight: 1, color: themeColor.palette.primarytext2, mt:0.5 }}>Click Here</T6TableText>
                                    <DatePickerOpenTo
                                        id='datepicker'
                                        open={true}
                                        name='startDate'
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={startDate}
                                        onChange={changeStartDate}
                                        onClose={() => setShowCalender(false)}
                                        minDate={addDays(new Date(displayWorkingDays[displayWorkingDays?.length - 1].date), 1)}
                                        maxDate={addDays(new Date(displayWorkingDays[displayWorkingDays?.length - 1].date), 23)}
                                        style={{ height:'0px', width:'0px', visibility:'hidden',backgroundColor:  '#ffffff', fontSize: '14px', lineHeight: '17px', width: '100%', p: 0.1, mt: 1, pl: 1 }} 
                                    />
                                        </>
                                        )
                                    } else if(startDate) {
                                        return (
                                            <>
                                                <T6TableText sx={{ lineHeight: 1, mb:0.5, color: startDate ? themeColor.palette.White: themeColor.palette.FollowupID}}>{moment(startDate).format("dddd")}</T6TableText>
                                                <T6TableText sx={{lineHeight: 1, color: startDate ? themeColor.palette.White : themeColor.palette.primarytext2, mt:0.5 }}>{moment(startDate).format(" Do MMMM ")}</T6TableText>
                                            </>
                                        )
                                    } 
                                    else {
                                        return (
                                            <Box onClick={() => handleCalender()}>
                                                <T6TableText sx={{lineHeight: 1, mb:0.5, color: themeColor.palette.FollowupID}}>See More</T6TableText>
                                                <T6TableText sx={{lineHeight: 1, color: themeColor.palette.primarytext2, mt:1 }}>Click Here</T6TableText>
                                            </Box>
                                        )
                                    }
                                    }
                                    )()
                                    }
                                    </Button>    
                                </Grid>
                            </Grid>
                        
                            {/* </Grid> */}
                        </Box> 
                        <DisplayError error={error} fortagid='value' />
                        
                        {/* <Box style={{marginTop:"30px",  alignItems:"center",display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}> */}
                        <Grid container spacing={2} marginTop="10px" marginLeft="0.1px" alignItems= 'center'> {/* Added alignItems="center" */}
                            <Grid item xs={12} md={6} sm={12} lg={1}>
                                <T6TableText style={{ textAlign: 'center', color: themeColor.palette.FollowupID}}>Hours</T6TableText>
                            </Grid>
                            <Grid item lg={11} container spacing={1} justifyContent="space-between"> 
                                {renderHoursButtons()}
                            </Grid>
                            <div style={{marginLeft:'100px'}}><DisplayError error={error} fortagid='createHr' /></div>
                        </Grid>
                        {/* </Box> */}
        
                        <Grid container spacing={2} marginTop="10px" marginLeft="0.1px" alignItems= 'center'>
                            <Grid item xs={12} md={6} sm={12} lg={1}>
                                <T6TableText sx={{textAlign: 'center', color: themeColor.palette.FollowupID}}>Minutes</T6TableText>
                            </Grid>
                            <Grid item xs={6} container spacing={1} justifyContent="space-between">
                                {renderMinutesButtons()}
                                <Button id='am' variant="outlined" style={{ marginLeft: '10px', padding: '5px 5px', minWidth: '50px', background: createNewFollowupAmPm === 'AM' ? themeColor.palette.FollowupID : 'transparent', marginTop: 'auto', color: createNewFollowupAmPm === 'AM' ? '#FFFFFF' : '#444444' }} onClick={() => submitAmPm(null, 'AM')}>
                                    AM
                                </Button>
                                <Button id='pm' variant="outlined" style={{ minWidth: '50px', padding: '5px 5px', marginTop: 'auto', background: createNewFollowupAmPm === 'PM' ? themeColor.palette.FollowupID : '#FFFFFF', color: createNewFollowupAmPm === 'PM' ? '#FFFFFF' : '#444444' }} onClick={() => submitAmPm(null, 'PM')}>
                                    PM
                                </Button>   
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                            <div style={{marginLeft:'90px'}}><DisplayError error={error} fortagid='createMin'/></div>
                            {/* { createNewDisplayErrorMsg ? <Typography style={{marginTop:'5px',color:'red', fontSize:'13px',marginLeft:'100px'}}>Please Select Minutes and AM/PM</Typography> :<></>} */}
                        </Grid>  

                        <Grid container spacing={2} marginTop='10px' marginLeft='0.1px' alignItems="center">
                            <Grid item xs={12} md={6} sm={12} lg={1}>
                                <T6TableText style={{ textAlign:'start', color:'#ABABAB'}}>Type</T6TableText>
                            </Grid>
                            <Grid item lg={3} container spacing={1} justifyContent="space-between">
                                {renderIconsButtons()}
                            </Grid>   
                        </Grid> 
                        <div style={{marginLeft:'0px'}}><DisplayError error={error} fortagid='createType' /></div>
                    
                        <Grid container spacing={2} marginTop='10px' marginLeft='0.1px'>
                        <Grid item xs={12} md={6} sm={12} lg={12}>
                            <T6TableText style={{color:'#ABABAB'}}>Summary <span style={{marginLeft:'12px', color: themeColor.palette.primarytext2}}>{(createNewFollowUpType?.length === 0 || createNewFollowupHour?.length === 0 || createNewFollowupMinute?.length===  0 || createNewFollowupDate?.length ===  0 || createNewFollowupAmPm?.length === 0) ? '': `${createNewFollowUpType} scheduling at ${createNewFollowupHour} : ${createNewFollowupMinute} ${createNewFollowupAmPm} on ${moment(createNewFollowupDate).format('D/M/YYYY')}.`}</span></T6TableText>
                        </Grid>
                        </Grid>

                        {/* <Box style={{marginTop:"30px", display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <Typography variant="body1" style={{ textAlign: 'center', fontSize:'14px', color: '#6E7100', fontWeight: 600}}>Minutes</Typography>
                                </Grid>
                                <Grid item xs={9} container spacing={2}>
                                <Grid item xs={4} container spacing={2} justifyContent="space-between">
                                    {renderMinutesButtons()}
                                </Grid>            
                            </Grid>
                        
                            <Grid item xs={2} container spacing={1} justifyContent="space-between">
                                <Button variant="outlined" style={{ minWidth: '50px', background: createNewFollowupAmPm ==='AM'? '#8AA000' : 'transparent',marginTop:'auto',color:createNewFollowupAmPm ==='AM'? '#FFFFFF':'#444444'}} onClick={()=>submitAmPm(null, 'AM')}>
                                    AM
                                </Button>
                                <Button variant="outlined" style={{ minWidth: '50px', marginTop:'auto', background : createNewFollowupAmPm ==='PM'? '#8AA000': '#FFFFFF', color: createNewFollowupAmPm ==='PM'? '#FFFFFF':'#444444'}} onClick={()=> submitAmPm(null, 'PM')}>
                                    PM
                                </Button>
                                <DisplayError error={error} fortagid='createampm' />
                            
                                  
                            </Grid>         
                    </Box> */}
                    
                    {/* <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <Typography sx={{mt:2, fontSize:'14px', fontWeight:600}}>Type<span style={{color:'#FF0000'}}>*</span></Typography>  
                                <SelectDropDown 
                                    name='createType'
                                    placeholder ='Select Type'
                                    options={Types} 
                                    onchange={handleOfCreateFollowUpDetails}
                                    value={createNewFollowUpType}
                                    //  disabled={Object.keys(editFollowupDetails)?.length >0}
                                    sx={{p:0.1, mt:0.5, backgroundColor:'#ffffff', borderRadius:"10px"}}
                                    // MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='createType' />
                            </Grid> */}
                        {/* </Grid> */}
                    </> :<></>}

                {/* <Box sx={{ mt: 1, mr: { xs: 1, sm: 1, xl:2, lg:2}, display: 'flex', flexDirection:'row',justifyContent: 'end' }}>
                    {loader ? 
                        (<TailSpin
                            height="30"
                            width="30"
                            color="blue"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />) : <CustemBtn id='save' disabled={Object.keys(followupClosed)?.length === 0 ? disableButton:!disableButton} onClick={submitNewFollowup} variant='contained' sx={{ mb:2, borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px'}} text="Submit" />}
                </Box>  */}
                    </Grid>
                </>}
            </>}  
               {/* ((user?.data?.user?.id !== leadDetails?.leaddetails?.assigned_to?.id) checkAdminRole && permissionsDetails?.perm_followup_create) || permissionsDetails?.perm_followup_create || */}
            </Box>
            {leadDetails?.leaddetails?.status === "Lost" || leadDetails?.leaddetails?.status === "Closed-No-Action" || leadDetails?.leaddetails?.status === "Converted" ? <></> :
                (permissionsDetails?.perm_followup_create && (user?.data?.user?.id == leadDetails?.leaddetails.assigned_to?.id) || (checkAdminRole && permissionsDetails?.perm_followup_create)) ? 
            <><Box sx={{ mt: 4, mr: { xs: 1, sm: 1, xl:2, lg:2}, display:'flex', justifyContent:'end' }}>
                    {loader ? 
                        (<TailSpin
                            height="30"
                            width="30"
                            color="blue"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />) : <CustemBtn id='save' disabled={Object.keys(followupClosed)?.length === 0 ? !disableButton : !disableButton} onClick={submitNewFollowup} variant='contained' sx={{ mb:2, borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px'}} text="Submit" />}
                </Box></> : <></>}
         </Box>
       }

        {openOpportunityModel && <OpportunityForm setEditOpportunityDetails={setEditOpportunityDetails} editOpportunityDetails={editOpportunityDetails} closingRemark={closingRemark} callResponce= {callResponce} MissingDeadLine={MissingDeadLine} closedFollowupDate = {closedFollowupDate} setOpenOpportunityModel={setOpenOpportunityModel} openOpportunityModel={openOpportunityModel}/>}
        {leadDetails?.getfollowup?.length > 0 ? <FollowupDetail /> :<></>}
        {openAssignModal && <AssignModal title={title} taskNumber={followupClosed?.followup_no} taskAssignedFromUser={followupClosed} setUpdate={setUpdate} update={update} openAssignModal={openAssignModal} setOpenAssignModal= {setOpenAssignModal} saveAssignedManagerOrExecutiveName={saveAssignedManagerOrExecutiveName}/>}
    </>
     )
}