
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box, Grid, Typography } from '@mui/material';

const Parent = styled(Box)(({ theme }) => ({
    margin: '20px',
    // display:'flex',
    // justifyContent:'space-between'
}));

const CountLayOut = styled(Grid)(({ theme }) => ({
    spacing: 2,

    columns: { xl: 2 },
}));

const TodayLeadLayOut = styled(Grid)(({ theme }) => ({

    spacing: 2,
    columns: { xl: 4 },
}));

const ExpiringLeadLayout = styled(Grid)(({ theme }) => ({

    spacing: 2,
    columns: { xl: 10 },
}));

const WishesLayout = styled(Grid)(({ theme }) => ({
    spacing: 2,
    columns: { xl: 2 },
}));

const Text = styled(Typography)(({ theme }) => ({
    // borderBottom: "0.5px solid #B9B9B9",
    display:'flex',
    fontSize: '12px',
    color: '#3107AA'
}));

const Header = styled(Typography)(({ theme }) => ({
    // borderBottom: "0.5px solid #B9B9B9",
    fontSize: '16px',
    color: '#333333'
}));

const CardLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    borderBottom: "0.5px solid #B9B9B9",
    justifyContent: 'space-between'
}));

const DataCountLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    borderBottom: "0.5px solid #B9B9B9",
    justifyContent: 'space-between'
}));

const Header2 = styled(Typography)(({ theme }) => ({
    // borderBottom: "0.5px solid #B9B9B9",
    color: 'var(--primary-text-1, #333)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal'
}));

const CountCard = styled(Box)(({ theme }) => ({
    marginTop: '10px',
    display: 'flex',
    width: '120px',
    padding: '6px 12px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    borderRadius: '8px',
    background: ' var(--white, #F9F9F9)',
    boxShadow: '0px 2.4647302627563477px 8px 0px rgba(0, 0, 0, 0.20)'
}));

const LeadIconBox = styled(Box)(({ theme }) => ({
    // borderBottom: "0.5px solid #B9B9B9",
    display: 'flex',
    width: '64px',
    height: '64px',
    padding: '3.286px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8.216px',
    borderRadius: ' 8.216px',
    backgroundColor: '#FC3434',
    background: 'var(linear-gradient(136deg, rgba(252, 52, 52, 0.70) 0%, #FC3434 100%))',
    boxShadow: '1.6431535482406616px 3.2863070964813232px 3.2863070964813232px 0px rgba(0, 0, 0, 0.10)',
}));

const CountCard2 = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
}));

const BrownColorTypo = styled(Typography)(({ theme }) => ({
    color: '#956700',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
}));

const BlackColorNum = styled(Typography)(({ theme }) => ({
    color: '#333333',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
}));

const SkyBlueTypo = styled(Typography)(({ theme }) => ({
    color: '#1F78FF',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
}));

const GreenTypo = styled(Typography)(({ theme }) => ({
    color: '#088F05',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
}));

const RedTypo = styled(Typography)(({ theme }) => ({
    color: '#E70707',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
}));

const GreyTypo = styled(Typography)(({ theme }) => ({
    color: '#656565',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
}));

const OppoIconBox = styled(Box)(({ theme }) => ({
    // borderBottom: "0.5px solid #B9B9B9",
    display: 'flex',
    width: '64px',
    height: '64px',
    padding: '3.286px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8.216px',
    borderRadius: ' 8.216px',
    backgroundColor: '#4b49f3 ',
    background: 'var(linear-gradient(136deg, rgba(252, 52, 52, 0.70) 0%, #FC3434 100%))',
    boxShadow: '1.6431535482406616px 3.2863070964813232px 3.2863070964813232px 0px rgba(0, 0, 0, 0.10)',
}));

const TodayLeadParent = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '8px 8px 0px 8px',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '8px',
    flex: '1 0 0',
}));

const LeadCard = styled(Box)(({ theme }) => ({
    marginTop: '10px',
    display: 'flex',
    padding: '8px 8px 10px 8px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    // flex: '1 0 0',
    //alignSelf: 'stretch',
    borderRadius: '6px',
    background: 'var(--white, #F9F9F9)',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.20)'
}));

const LeadNo = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '70px',
    padding: '0px 2px',
    alignItems: 'center',
    gap: '4px',
}));

const LeadNoText = styled(Box)(({ theme }) => ({
    color: 'var(--lead-id, #C72424)',
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
}));

const DateLayout = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1px',
}));

const DateTypo = styled(Typography)(({ theme }) => ({
    color: '#4F709C',
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
}));

const CompanyName = styled(Typography)(({ theme }) => ({
    color: 'var(--primary-text-1, #333)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    flex: '1 0 0',
    marginTop: '10px',
    marginBottom: '10px'
}));

const FollowupType = styled(Box)(({ theme }) => ({
    color: '#333333',
    leadingTrim: 'both',
    textedge: 'cap',
    fontSize: '14px',
    fontstyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    width: '100%',
    marginTop: '10px'
}));

const FollowupIcon = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'stretch',
    gap: '10px',
    borderRadius: '8px',
    border: "1px solid #088F05"
}));

const FollowupName = styled(Box)(({ theme }) => ({
    color: '#4F709C',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontSize: '10px',
    alignSelf: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
}))

const CallIconCss = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '4px',

    alignItems: 'center',
    gap: '10px',
    borderRadius: '8px 0px 0px 8px',
    background: '#088F05'
}));

const CallNameCss = styled(Typography)(({ theme }) => ({
    color: '#333333',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    maxWidth: '150px', 
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    "&:hover": {
        maxWidth: '150px', 
        cursor:'pointer'
    } 

}));

const PagiLayout = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',

}));

const FilledDot = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',

}));

const FilledDotSelected = styled(Typography)(({ theme }) => ({
    width: '16px',
    height: '10px',
    borderRadius: '65px',
    border: '#3107AA',
    background: '#3107AA'

}));

const CountCard3 = styled(Typography)(({ theme }) => ({
    padding: '2px 10px',
    alignItems: 'center',
    marginLeft:'5px',
    marginBottom:'5px',
    borderRadius: '13px',
    background: '#FFFFFF'
}));

const PendingCofheader = styled(Typography)(({ theme }) => ({
   
}));

const PendingCofCard = styled(Grid)(({ theme }) => ({
    marginTop:'20px',
    padding:'10px',
   background:'#ffffff',
   borderRadius:'10px',
   cursor:'pointer'
}));


export {
    Parent, CountLayOut, TodayLeadLayOut, ExpiringLeadLayout, WishesLayout, Text, Header,
    CardLayout, DataCountLayout, Header2, CountCard, LeadIconBox, CountCard2, BrownColorTypo,
    BlackColorNum, SkyBlueTypo, GreenTypo, RedTypo, GreyTypo, OppoIconBox, TodayLeadParent, LeadCard,
    LeadNo, LeadNoText, DateLayout, DateTypo, CompanyName, FollowupType, FollowupIcon, FollowupName,
    CallIconCss, CallNameCss, PagiLayout, FilledDot, FilledDotSelected, CountCard3,PendingCofheader, PendingCofCard
};
