import React from 'react'

const WhiteBugReportIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2477_59791)">
        <path d="M14.625 9.75C15.2463 9.75 15.75 9.24632 15.75 8.625C15.75 8.00368 15.2463 7.5 14.625 7.5C14.0037 7.5 13.5 8.00368 13.5 8.625C13.5 9.24632 14.0037 9.75 14.625 9.75Z" fill="white"/>
        <path d="M9.375 9.75C9.99632 9.75 10.5 9.24632 10.5 8.625C10.5 8.00368 9.99632 7.5 9.375 7.5C8.75368 7.5 8.25 8.00368 8.25 8.625C8.25 9.24632 8.75368 9.75 9.375 9.75Z" fill="white"/>
        <path d="M12 12V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.5 13.5C19.5 15.4891 18.7098 17.3968 17.3033 18.8033C15.8968 20.2098 13.9891 21 12 21C10.0109 21 8.10322 20.2098 6.6967 18.8033C5.29018 17.3968 4.5 15.4891 4.5 13.5V10.5C4.5 8.51088 5.29018 6.60322 6.6967 5.1967C8.10322 3.79018 10.0109 3 12 3C13.9891 3 15.8968 3.79018 17.3033 5.1967C18.7098 6.60322 19.5 8.51088 19.5 10.5V13.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.7507 17.2496L19.0488 16.0693" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.7507 6.75L19.0488 7.93031" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.25 6.75L4.95187 7.93031" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.25 17.2496L4.95187 16.0693" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.5 12H22.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_2477_59791">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>
)
}

export default WhiteBugReportIcon