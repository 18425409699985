import { createSlice } from '@reduxjs/toolkit'
import { auththunk, resetPassword } from '../Thunk/auththunk'

const initialState = {
     user : null,
     emailAddress: null,
}

export const userSlice = createSlice({
          name:'auth',
          initialState,
          reducers:{ 
            saveLoginData(state, action){
               state.user= action.payload
            } 
          },

          extraReducers:(builder)=>{
            builder.addCase(auththunk.fulfilled,(state,action)=>{
              console.log(action.payload)
               state.user = action.payload
            })

            builder.addCase(resetPassword.fulfilled, (state,action)=>{
              console.log(state.emailAddress)
               state.emailAddress = action.payload
            })
         }
})


export const {saveLoginData} = userSlice.actions
export default userSlice.reducer
