
const organizationTypes = [
    {
        value: 'PRIVATE LIMITED',
        label: 'PRIVATE LIMITED',
    },
    {
        value: 'LIMITED', 
        label: 'LIMITED',
    },
    {
        value: 'LLP',
        label: 'LLP',
    },
    {
        value: 'NGO',
        label: 'NGO',
    },
    {
        value: 'TRUST', 
        label: 'TRUST',
    },
    {
        value: 'PROPRIETOR', 
        label: 'PROPRIETOR',
    }, 
    {
        value: 'FOREIGN ENTITY',
        label: 'FOREIGN ENTITY',
    },
    {
        value: 'OTHERS',
        label: 'OTHERS',
    }, 
]

const industryTypes = [
    {
        value: 'Information Technology', 
        label: 'Information Technology', 
    },
    {
        value: 'Manufacturing',
        label: 'Manufacturing',
    },
    {
        value:'Logistics',
        label: 'Logistics',
    },
    {
        value: 'Pharma', 
        label: 'Pharma', 
    },
    {
        value: 'Software Development', 
        label: 'Software Development', 
    }, 
    {
        value: 'Coworking Space',
        label: 'Coworking Space',
    },
    {
        value: 'Media',
        label: 'Media',
    }, 
    {
        value: 'Education',
        label: 'Education',
    }, 
    {
        value: 'Financial', 
        label: 'Financial', 
    }, 
    {
        value:'Government',
        label: 'Government',
    }, 
    {
        value: 'Telecom',
        label: 'Telecom',
    }, 
    {
        value:'Trust', 
        label: 'Trust', 
    }, 
    {
        value: 'NGO',
        label: 'NGO',
    }, 
    {
        value: 'Services', 
        label: 'Services',
    }, 
    {
        value: 'Consultancy',
        label: 'Consultancy',
    }, 
    {
        value: 'HealthCare',   
        label: 'Health Care', 
    }, 
    {
        value: 'AirLinesOrAviation', 
        label: 'AirLines or Aviation',
    }, 
    {
        value: 'Research',  
        label: 'Research', 
    }, 
    {
        value:'Chemicals', 
        label:'Chemicals', 
    }, 
    {
        value: 'MiningAndMetals',
        label: 'Mining and Metals',
    } 

]

export { industryTypes, organizationTypes} 