import React from 'react'
import { IconButton,DialogTitle, Typography } from '@mui/material';
import CloseIcon from './CloseIcon';

const DialogHeaderCompanyName = ({onClose, label, title, storeCompanyDetails}) => {
  const wordsToRemove = ["TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"];
  const pattern = new RegExp(wordsToRemove.join("|"), "gi");
  const remainingNames = label.replace(pattern, (match) => `(${match})`).trim();

  return (
    <DialogTitle sx={{m: 1, p:0, borderRadius: '10px', display: 'flex', flexDirection:'row', backgroundColor: '#3107AA'}}>
        <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5" sx={{fontWeight: 600, color: '#FFFFFF', letterSpacing: '0.04em' }}>
              {title === "CreateFromLead" || title === 'CreateFromDashboard' || title === 'Create Company' || storeCompanyDetails?.title === 'Create Company' ? label : remainingNames}
            </Typography>
        </div>
        {onClose && (
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                ml: 'auto',
            }}
            >
            <CloseIcon w={30} h={30} />
            </IconButton>
        )}
    </DialogTitle>
  )
}

export default DialogHeaderCompanyName