import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { DeleteIcon, EditIcon, Navbar } from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import FollowupCard from '../../components/custom/FollowupCard';
import FollowUpDetails from '../FollowUp/FollowUpDetails';
import ProgressBar from '../../components/custom/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { getalertlist } from '../../Redux/Thunk/alertthunk';
import CreateTaskForm from '../Task/CreateTaskForm';
import AddIcon from '../../components/custom/AddIcon';
import { useLocation, useNavigate } from 'react-router-dom';

const CardBody = styled('div')({
  backgroundColor: '#F0EFFD',
  padding: '20px',
  borderRadius: '10px',
})

const Item = styled('div')({
  display: 'flex',
  justifyContent: 'center'
})


export default function AlertList() {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {state} = useLocation()
  const {updateCreateEditFollowup} =useSelector((state) => state.settingList)
  const {alertlist_data} = useSelector((state)=> state.alert)
  const [ openTaskPopup, setOpenTaskPopup] = useState(false)
  const [openFollowupPopup, setOpenFollowupPopup]= useState(false)
  const [editFollowupDetails, setEditFollowupDetails] = useState({});
  const [listname, setlistName] = useState('followup')
  const [followupClosed, setFollowupClosed] = useState(false)
  const [alert_data, setAlertData] = useState([])
  const [taskedit, setTaskEdit]= useState({})
  const [update, setUpdate] = useState(false);
  const Title = "Alert List"

  useEffect(() => {
    dispatch(getalertlist({start:(currentPage*6 - 6), length:6, name: state ? state :listname})).then((res) => {
      // setlistName(state ? state :listname)
      // setAlertData(res?.payload?.data)
    })
  }, [listname, currentPage, update])

  const handleClickOpen = (task) => {
    setTaskEdit(task)
    setOpenTaskPopup(true)
    // navigate('/dashboard/lead', {state: {lead :lead, operation:'editLead'}})
  };

  const openFollowupPage =(followup) =>{
    if(followup?.closed_on === 0){  
      setEditFollowupDetails(followup)
      setOpenFollowupPopup(true)
      setFollowupClosed(false)
    }else{
      setEditFollowupDetails(followup)
      setFollowupClosed(true)
      setOpenFollowupPopup(true)
      // sweetalert("error", "This followup is closed. Create new followup")
    }
  }
  
  const handleAlertfollowup = () => {
    setlistName('followup')
    setCurrentPage(1)
  }

  const handleAlerttask = () => {
    setlistName('task')
    setCurrentPage(1)
  }
  
 const handleCreate = () => {
   if(listname == 'followup') {
    navigate('/dashboard/createfollowup', {state: {title:Title}});
   } else {
    navigate('/dashboard/createtask', {state: {title:Title}});
   }
 }

  return (
    <>
      <Grid sx={{ borderBottom: '1px solid #e4e4e4', mt: 3, ml: 5, mr: 5, display: "flex", justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Grid sx={{display:'flex', justifyContent:'start'}}>
        <Grid item xs={10}>
          <CustemBtn onClick={handleAlertfollowup} variant='h6' text="Followup Alert List" sx={{ fontSize: '20px', fontWeight:`${listname === 'followup' ? 600:''}`, cursor: 'pointer',backgroundColor:`${listname == 'followup' ?'lightgrey':''}`, border:`${listname == 'followup' ?'1px solid black':''}`, borderBottom:'0px' }}></CustemBtn>
        </Grid>
        <Grid item xs={10}>
          <CustemBtn onClick={handleAlerttask} variant='h6' text="Task Alert List" sx={{ fontSize: '20px', fontWeight:`${listname === 'task' ? 600:''}`, cursor: 'pointer',backgroundColor:`${listname == 'task' ?'lightgrey':''}`, border:`${listname == 'task' ?'1px solid black':''}`, borderBottom:'0px' }}></CustemBtn>
        </Grid>
        </Grid>
        <Grid item xs={10}>
         {/* <AddIcon/> */}
            <Tooltip title={ listname === 'followup' ? "New Followup" :"New Task"}>
                <IconButton sx={{ml:1}} onClick={handleCreate}> 
                <svg width="34" height="34" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="21" cy="21" r="20.5" fill="#A484FE" stroke="#A484FE"/>
                    <path d="M14.125 21H27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 14.125V27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </IconButton>
            </Tooltip> 
        </Grid>
      </Grid>
      <CardBody sx={{ mt: 3, ml: { xs: 1, sm: 1, xl: 5,lg:5 }, mr: { xs: 1, sm: 1, xl: 5,lg:5} }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 8, lg: 12 }}>
             {
              listname == 'followup' ?
             <>
              {
                alertlist_data?.data?.followup?.map((followup) => {
                  return <>
                    <Grid item xs={2} sm={4} md={4} lg={4}>
                      <Item>
                        <FollowupCard onClick={openFollowupPage} followup={followup} width={390} alert_data={alertlist_data?.data?.followup} />
                      </Item>
                  </Grid>
                   </>
                })
              }
             </>: alertlist_data?.data?.task?.map((task) => {
              return <>
                   <Grid item xs={2} sm={4} md={4} lg={4}>
                          <Item>
                            <FollowupCard onClick={handleClickOpen} width={390} task={task} />
                          </Item>
                      </Grid>
                 </>
             })
             }
          </Grid>
        </Box>
      </CardBody>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, pt: 4, pb: 4 }}>
        <PaginationFooter number={6} list={listname == 'followup'? alertlist_data?.data?.totalfollowupreacords : alertlist_data?.data?.totaltaskreacords } currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </Box>
      {openTaskPopup? <CreateTaskForm title={Title} from ='alert' For='edit' taskedit={taskedit} openTaskPopup={openTaskPopup} setOpenTaskPopup={setOpenTaskPopup} update={update} setUpdate={setUpdate} />:<></> }
      {openFollowupPopup && <FollowUpDetails title={Title} followupClosed ={followupClosed} setUpdate={setUpdate} update={update} open={openFollowupPopup} setOpenFollowupPopup={setOpenFollowupPopup} editFollowupDetails={editFollowupDetails} setEditFollowupDetails={setEditFollowupDetails}/>}
    </>
  );
}