import React from 'react'
import { H1FormTitle, T6TableText, T1ButtonText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T2popupSubText } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor'
import moment from 'moment';
import { Box } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { ActionButtons, ApproveBtn } from './NewOppoDetailscss';

const ChildCard = styled('div')({
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
    width:'100%'
})
  
const CardInfo = styled('div')({
    display:'flex',
})

const ExecutiveReq = ({ stage, opportunity }) => {
    
    return (
        <>
            {(
                () => {
                    if (stage?.status == 'Pending' && stage?.for_stage == 'Qualified') {
                        return <>
                             {/* <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, mt:1}}>
                              {stage?.req_remark ? 'Request Remark' : ''}
                           </H7TablePrimeContent>
                           <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt:0.5, mb:0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                              {stage?.req_remark}
                           </T5Normaltext> */}
                        </>
                    } 
                    // else if (stage?.status == 'Pending') {

                    //     return <ApproveBtn sx={{color: themeColor.palette.BrandColor, border:'0.5px solid Orange', marginBottom: '5px', marginTop:'5PX' }}>
                    //         Awaiting for Approval
                    //     </ApproveBtn>


                    // } 
                    else if (stage?.status == 'Approved') {
                        return <Box sx ={{display:'flex', flexDirection:'column', alignItems:'space-between'}}>
                    
                        <ActionButtons sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flexDirection:'column'}}>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                    Requested By:
                                </H7TablePrimeContent>
                               <Box>
                               <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, }}>
                                     {stage?.created_by?.firstname} {stage?.created_by?.lastname} 
    
                                </H7TablePrimeContent>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, }}>
                                {moment(stage.createdAt).format('D MMM Y hh:mm A')}
    
                                </H7TablePrimeContent>
                               </Box>
                               
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection:'column' }}>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                    Approved By:
                                </H7TablePrimeContent>
                                <Box>
                               <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500 }}>
                                     {stage?.updated_by?.firstname} {stage?.updated_by?.lastname}
    
                                </H7TablePrimeContent>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500 }}>
                                {moment(stage.updatedAt).format('D MMM Y hh:mm A')}
    
                                </H7TablePrimeContent>
                               </Box>
                            </Box>
    
                        </ActionButtons>
                        </Box>
                    } else if (stage?.status =='Rejected'){
                        return <ChildCard sx={{mt:0}}>
                            <Box sx={{display:'flex', mb:0.5, justifyContent:'flex-start', alignItems:'center'}}>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                    Reject Remark
                                </H7TablePrimeContent>
                            </Box>
                            <CardInfo sx={{pr: 1, pb: 0.4 }}>
                                <T5Normaltext sx={{color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                                {stage?.remarks}
                                </T5Normaltext>
                            </CardInfo>
                        <Box sx={{display:'flex', justifyContent:'space-between'}}>
                            <Box sx={{ display: 'flex', flexDirection:'column'}}>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2}}>
                                    Requested By:
                                </H7TablePrimeContent>
                            <Box>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, pb:0.1}}>
                                    {stage?.created_by?.firstname} {stage?.created_by?.lastname} 
                                </H7TablePrimeContent>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, }}>
                                {moment(stage.createdAt).format('D MMM Y hh:mm A')}
                                </H7TablePrimeContent>
                            </Box>
                            
                            </Box>
                            <Box sx={{mb:1}} >
                                <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2}}>
                                    Rejected By: 
                                </H7TablePrimeContent>
                            <Box>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, pb:0.1}}>
                                    {stage?.updated_by?.firstname} {stage?.updated_by?.lastname}
                                </H7TablePrimeContent>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500}}>
                                    on {moment(stage.updatedAt).format('D MMM Y hh:mm A')}
                                </H7TablePrimeContent>
                            </Box>
                            </Box>
                        </Box>
                     </ChildCard>
                    } else if (stage?.status == 'ClosedLost') {
                        return <ChildCard sx={{mt:1}}>
                           
                           <Box sx={{display:'flex', justifyContent:'space-between'}}>
                           <Box sx={{ display: 'flex', flexDirection:'column'}}>
                               <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                   Closed By:
                               </H7TablePrimeContent>
                              <Box>
                              <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, }}>
                                    {stage?.created_by?.firstname} {stage?.created_by?.lastname} 
   
                               </H7TablePrimeContent>
                               <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500,  mb:1}}>
                               on {moment(stage.createdAt).format('D MMM Y hh:mm A')}
   
                               </H7TablePrimeContent>
                               
                              </Box>
                              <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                   Lost reason:
                               </H7TablePrimeContent>
                              <Box>
                              <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, mb:1 }}>
                                {opportunity.lost_reason}
                               </H7TablePrimeContent>
                              </Box>
                              
                           </Box>
                          
                           </Box>
                           <Box sx={{display:'flex', mb:0.5, justifyContent:'flex-start', alignItems:'center'}}>
                              <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                 Closed Remark
                              </H7TablePrimeContent>
                           </Box>
                        <CardInfo sx={{pr: 1, pb: 0.2 }}>
                           <T5Normaltext sx={{color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                              {stage?.remarks}
                           </T5Normaltext>
                        </CardInfo>
                        </ChildCard>
                     }
                }
            )()}
        </>
    )
}

export default ExecutiveReq
