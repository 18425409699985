import React,{useEffect} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {Dialog, Box, Grid} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useDispatch, useSelector} from "react-redux";
import DialogContentText from '@mui/material/DialogContentText';
import { emptyCompanyName } from "../../Redux/slices/createCompanySlice";
import { CustemBtn } from '../../components/custom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
     padding: theme.spacing(1),
  },
}));


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontWeight:600 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function CompanyNameLists({open, setOpenModal, setDisplayForm, setDisableCompanyName}) {
  const dispatch = useDispatch();
  const {companyName} = useSelector((state)=>state.company)
  const handleClickOpen = () => {
    // setOpen(true);
  };

  const handleClose = () => {
     setOpenModal(false);
  };
 
  const openFormDetails=()=>{
    setDisplayForm(true)
    setDisableCompanyName(true)
    setOpenModal(false)
  }

  const ChangeCompanyName =() => {
    setOpenModal(false);
  }

  return (
    <div>  
      {companyName.length > 0 ? <> 
       <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            
          </BootstrapDialogTitle>
          <DialogContent   onClose={handleClose}>
            <DialogContentText id="alert-dialog-description">
                {companyName.map((name,i) => (
                      <h4> {name.company_name} {
                        (
                          () => {
                            if(name.company_type == 'PRIVATE LIMITED' || name.company_type =='LIMITED' || name.company_type == 'LLP') {
                              return `${name.company_type}`
                            } else {
                               return `(${name.company_type})`
                            }
                          }
                        )()
                      }</h4>   
                    )) 
                }  
                <Typography style={{color:'red', fontWeight:600}}>Company name already exist.</Typography>
            </DialogContentText>
          </DialogContent> 
          {/* <DialogActions sx={{mt:1,mb:1,mr:2}}>
            <CustemBtn variant='contained' sx={{borderRadius:"10px", pl:2,pr:2, fontSize:'16px',backgroundColor:'#7673F6'}} text="Change" onClick={ChangeCompanyName}/>
            <CustemBtn variant='contained' sx={{borderRadius:"10px", pl:2,pr:2, fontSize:'16px',backgroundColor:'#7673F6'}} text="Proceed" onClick={openFormDetails}/>
          </DialogActions> */}
      </Dialog> 
       </> :<> 
             ""
           </>}
    </div>
  );
}