import React, { useEffect,useState } from 'react'
import { Grid, styled} from '@mui/material'
import {H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import LeadDetailsCompanyIcon from '../../pages/LeadDetails/LeadDetailsCompanyIcon';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const ChildCard = styled('div')({
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
})

const CardInfo = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
})

export default function CompanyNameDetails ({getCompanyDetails, title}) {
    const navigate = useNavigate();
    
    const handleClickCompanyWebsite = (website)=>{
        if (website?.length > 0) {
          window.open(website, '_blank');
        }
    }

    const label = `${getCompanyDetails?.company?.company_name} ${getCompanyDetails?.company?.company_type}`
    const wordsToRemove = ["TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"];
    const pattern = new RegExp(wordsToRemove.join("|"), "gi");
    const remainingNames = label.replace(pattern, (match) => `(${match})`).trim();

    const handleClickCompanyProfile =() =>{
        if( title === "CompanyProfilePage"){
            console.log("helll")
        }else{
            navigate('/dashboard/profile', { state: getCompanyDetails?.company?.id})
        } 
    }

     return(
     <>
        <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
            <Grid item xs={2} sm={2} md={2} lg={2}>
                <LeadDetailsCompanyIcon/>
            </Grid>
            <Grid item xs={10}>
                {/* <Grid container direction="row" spacing={0}> */}
                    <Grid item>
                        <H3SubHeader sx={{color:themeColor.palette.BrandColor ,"&:hover": {cursor: title === "CompanyProfilePage" ? 'default':'pointer'} }} onClick={handleClickCompanyProfile}>{remainingNames}</H3SubHeader>
                    </Grid>
                        
                    <ChildCard>
                        <CardInfo sx={{ pt:1, pb: 0.2}}>
                            <H7TablePrimeContent sx={{flex:1, pb: 0.5, color: themeColor.palette.InputLabel}}>Company Type</H7TablePrimeContent>
                            <H7TablePrimeContent sx={{flex:1, pb: 0.5, color: themeColor.palette.InputLabel}}>Industry Type</H7TablePrimeContent>
                        </CardInfo>
                        <CardInfo sx={{pb: 1 }}>
                            <T6TableText sx={{flex: 1, color:themeColor.palette.primarytext2, pb: 1}}>{getCompanyDetails?.company?.company_type}</T6TableText>
                            <T6TableText sx={{flex: 1, color:themeColor.palette.primarytext2, pb: 1}} >{getCompanyDetails?.company?.industry_type}</T6TableText>
                        </CardInfo>

                        <CardInfo sx={{pb: 0.2}}>
                            <H7TablePrimeContent sx={{flex:1, pb: 0.5, color: themeColor.palette.InputLabel}}>Incorporation Date</H7TablePrimeContent>
                            <H7TablePrimeContent sx={{flex:1, pb: 0.5, color: themeColor.palette.InputLabel}}>Website</H7TablePrimeContent>   
                        </CardInfo>

                        <CardInfo sx={{pb: 1 }}>
                            <T6TableText sx={{color:themeColor.palette.primarytext2, flex:1}}>{moment(getCompanyDetails?.company?.company_doi).format('D/M/YYYY')}</T6TableText>
                            {/* <span style={{ display: 'flex' }}> */}
                            {/* <span style={{ flexShrink: 0 }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.90836 13.2225L8.19476 10.9943C8.12996 10.9985 8.06456 11.0007 7.9987 11.0007C6.86783 11.0007 5.88307 10.3749 5.37183 9.45078L3.19509 5.68056C2.85566 6.38202 2.66536 7.16912 2.66536 8.00065C2.66536 10.5725 4.48579 12.7192 6.90836 13.2225ZM8.39183 13.3197C11.1538 13.1185 13.332 10.8139 13.332 8.00065C13.332 7.41858 13.2388 6.85832 13.0664 6.33398H10.4935C10.8126 6.81065 10.9987 7.38392 10.9987 8.00065C10.9987 8.56578 10.8424 9.09438 10.5708 9.54565L8.39183 13.3197ZM9.42763 8.85898L9.4421 8.83398C9.58416 8.58892 9.66536 8.30425 9.66536 8.00065C9.66536 7.08018 8.91916 6.33398 7.9987 6.33398C7.07823 6.33398 6.33203 7.08018 6.33203 8.00065C6.33203 8.29285 6.40723 8.56752 6.53932 8.80625L6.55532 8.83398C6.8433 9.33218 7.38183 9.66732 7.9987 9.66732C8.60523 9.66732 9.1361 9.34332 9.42763 8.85898ZM4.02218 4.44646L5.3077 6.67305C5.7975 5.68213 6.8185 5.00065 7.9987 5.00065H12.4089C11.4489 3.59214 9.83183 2.66732 7.9987 2.66732C6.41848 2.66732 4.99879 3.35456 4.02218 4.44646ZM7.9987 14.6673C4.3168 14.6673 1.33203 11.6825 1.33203 8.00065C1.33203 4.31875 4.3168 1.33398 7.9987 1.33398C11.6806 1.33398 14.6654 4.31875 14.6654 8.00065C14.6654 11.6825 11.6806 14.6673 7.9987 14.6673Z" fill="#9B9AAF"/>
                            </svg>
                            </span> */}
                            <T6TableText sx={{color:themeColor.palette.mainSkyBlue, whiteSpace: 'pre-line', wordBreak:'break-all', flex: 1, "&:hover": {cursor:'pointer'}}} onClick={()=>handleClickCompanyWebsite(getCompanyDetails?.company?.company_website)}>{getCompanyDetails?.company?.company_website}</T6TableText>
                            {/* </span> */}
                        
                        </CardInfo>
                    </ChildCard>
                    
                    {/* </Grid> */}
                
            </Grid>
           
        </Grid>
     </>
     )
}