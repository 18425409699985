import React,{useState, useEffect}from 'react';
import { Navbar, DropDown, CustemBtn, SelectDropDown} from '../../components/custom';
import {Grid, Box, styled, Card, Avatar, Typography, TextField,} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getAllCompanyName} from "../../Redux/Thunk/createcompanythunk";
import { displayContactList } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';

const CardBody = styled('div')({
    backgroundColor:'#F0EFFD',
    padding:'20px',
    borderRadius:'10px',
})

const Container = styled('div')({
    background:'#F5F4FF',
    borderRadius:'10px',
    padding:'20px 20px 40px',
    margin:'30px',
    gap:'10px',
    height:'80px',
})
////   Create New Lead Form  //
const DropDownCreateNewLeadForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {allCompanyName} = useSelector((state)=> state.company)
    const [leadName, setLeadName] = useState('')
    const [companyNames, setCompanyNames]= useState([])
    const [dropdownValues, setDropdownValues]= useState('')
    const [buttonStatus, setButtonStatus] = useState(false);
    const allContactDisabled = dropdownValues?.contact?.every(obj => obj.enabled === false)
    
    useEffect(()=>{ 
        dispatch(getAllCompanyName());
    },[])
        
    const handleProceed = () => {
        if((dropdownValues?.registeredAddress === null && dropdownValues?.headOffice === null && dropdownValues?.branch?.length === 0) || (dropdownValues?.contact?.length === 0)){
            swal({
                buttons: {
                Cancel: {
                    text: "Go To Dashboard",
                    value: "Cancel",
                    className: "popup-blue",
                },
                Delete: {
                    text: (dropdownValues?.contact?.length === 0) ? "Create Contact" : "Create Address",
                    value: (dropdownValues?.contact?.length === 0) ? "contact" : "address",
                    className: "popup-gray",
                },
                },
                title:((dropdownValues?.registeredAddress === null && dropdownValues?.headOffice === null && dropdownValues?.branch.length === 0) && (dropdownValues?.contact.length === 0)) ? `Please add Address and Contact of ${dropdownValues?.label} Company.` : (dropdownValues?.contact?.length === 0) ? `Please Create Contact of ${dropdownValues?.label} Company.` :`Please Create Address of ${dropdownValues?.label} Company.` ,
                className: "custom-modal",
                closeOnClickOutside: false,
            }).then((value)=>{
                if(value==='address'){
                    navigate('/dashboard/address')
                }else if(value==='contact'){
                    navigate('/dashboard/createcontactdetails')
                }else{
                    navigate('/dashboard')
                }
            })
        }else if(allContactDisabled === true){
            sweetalert('error', `All contacts are disable for ${dropdownValues?.label}. Please add new contact.`)
        }else{
            navigate('/dashboard/lead',{ state: {dropdownValues: dropdownValues, operation:'createLead' }});
        }
        // else if(dropdownValues?.contact.length === 0) {
        //     swal({
        //         buttons: {
        //         Cancel: {
        //             text: "Go To Dashboard",
        //             value: "Cancel",
        //             className: "popup-blue",
        //         },
        //         Delete: {
        //             text: "Create Contact",
        //             value: "contact",
        //             className: "popup-gray",
        //         },
        //         },
        //         title: `Please Create Contact of ${dropdownValues?.label} Company.` ,
        //         className: "custom-modal",
        //         closeOnClickOutside: false,
        //     }).then((value)=>{
        //         if(value=='contact'){
        //             navigate('/dashboard/createcontactdetails')
        //         }else{
        //             navigate('/dashboard')
        //         }
        //     })
        // }
        // dispatch(displayContactList(dropdownValues?.id)).then((res)=>{
        //     console.log(res,"Responcessssssssss")
        // })
    
        // navigate('/dashboard/lead',{ state: {dropdownValues: dropdownValues, operation:'createLead' }});
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };

    return(
        <>
         {/*  Nav bar */}
            <Grid sx={{borderBottom:'1px solid #e4e4e4', mt:2, ml:{xs:1,sm:1,xl:6,lg:6}, mr:{xs:1,sm:1,xl:6,lg:6}, display:"flex",flexDirection:{lg:'row',sm:'row',xs:'column-reverse'}, justifyContent:{lg:'space-between',sm:'space-between',xs:'start'},alignItems:{lg:'flex-end',md:'flex-end',xs:'center'}}}>    
                <Typography variant='h6' sx={{fontSize:{lg:'22px',md:'20px',sm:'18px'}, fontWeight:600}}>Create New Lead</Typography>
            </Grid>
            <CardBody sx={{mt:2, pb:6, ml:{xs:1, sm:1, xl:6, lg:6}, mr:{xs:1, sm:1, xl:6, lg:6} }}>
                <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                    <Grid item xs={12} md={2} sm={2} lg={2}>
                        <Typography>Company</Typography>  
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={3}>
                        <Typography>Name</Typography>  
                        <DropDown sx={{background:'#FFFFFF',mt:1}} 
                            placeholder = 'Select Company'
                            options ={allCompanyName?.map((name)=>{return {label:`${name?.company_name} ${name?.company_type}`, value:`${name?.company_name} ${name?.company_type}`, id: name?.id, contact: name?.contact, branch:name?.branch, headOffice:name?.head_office, registeredAddress:name?.registered_address}})}
                            onChange = {(e, value, reason) => {
                                if(value===null){
                                    setButtonStatus(false)
                                    setDropdownValues("")
                                }else{
                                    setButtonStatus(true)
                                    setDropdownValues(value)
                                }
                            }}
                            getOptionLabel={(option) =>(option ? option.label :"")}
                            value = {dropdownValues}
                        />
                    </Grid>
                </Grid>
            </CardBody>

            <Box sx={{mt:4, mr:{xs:1,sm:1,xl:6,lg:6}, mb:2, display:'flex', justifyContent:'end' }}>
                <CustemBtn onClick={handleProceed} disabled={!buttonStatus} variant='contained' sx={{borderRadius:"10px",pl:5,pr:5,fontSize:'16px'}} text ='Proceed'/>
            </Box>
        </>
    )
}

export default DropDownCreateNewLeadForm