import React from 'react'

const EditIcon = ({w,h}) => {
  return (
    <svg width={w} height={h} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1008 4.19922H5.47856C4.90098 4.19922 4.34705 4.44505 3.93864 4.88264C3.53022 5.32022 3.30078 5.91371 3.30078 6.53255V22.8659C3.30078 23.4847 3.53022 24.0782 3.93864 24.5158C4.34705 24.9534 4.90098 25.1992 5.47856 25.1992H20.723C21.3006 25.1992 21.8545 24.9534 22.2629 24.5158C22.6713 24.0782 22.9008 23.4847 22.9008 22.8659V14.6992" stroke="#1B1C00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.084 2.91628C22.5481 2.45215 23.1776 2.19141 23.834 2.19141C24.4904 2.19141 25.1199 2.45215 25.584 2.91628C26.0481 3.38041 26.3089 4.0099 26.3089 4.66628C26.3089 5.32266 26.0481 5.95215 25.584 6.41628L14.5007 17.4996L9.83398 18.6663L11.0007 13.9996L22.084 2.91628Z" stroke="#1B1C00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>    
  )
}

export default EditIcon

//width="20" height="20"