import React from 'react'

const LinkedinIcon = ({linkedin, enabled}) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.5872 18.339H15.9217V14.1622C15.9217 13.1662 15.9015 11.8845 14.5327 11.8845C13.143 11.8845 12.9307 12.9683 12.9307 14.0887V18.339H10.2652V9.75H12.8257V10.9207H12.8602C13.218 10.2457 14.088 9.53325 15.3877 9.53325C18.0885 9.53325 18.588 11.3108 18.588 13.6245L18.5872 18.339ZM7.2547 8.57475C6.3967 8.57475 5.70745 7.88025 5.70745 7.026C5.70745 6.1725 6.39745 5.47875 7.2547 5.47875C8.1097 5.47875 8.8027 6.1725 8.8027 7.026C8.8027 7.88025 8.10895 8.57475 7.2547 8.57475ZM8.5912 18.339H5.9182V9.75H8.5912V18.339ZM19.9207 3H4.5802C3.84595 3 3.25195 3.5805 3.25195 4.29675V19.7033C3.25195 20.4202 3.84595 21 4.5802 21H19.9185C20.652 21 21.252 20.4202 21.252 19.7033V4.29675C21.252 3.5805 20.652 3 19.9185 3H19.9207Z" fill={linkedin === 0 || enabled === false ?"#E6E6E6": "#0177B5"}/>
    </svg>
  )
}

export default LinkedinIcon