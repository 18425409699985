import React, { useState, useEffect } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Box, Grid, Card, IconButton, Tooltip } from '@mui/material';
import { CompanyName, CustemBtn, InputBox, PaginationFooter, SelectDropDown, TextArea } from '../../components/custom';
import { useDispatch, useSelector } from 'react-redux';
import { enableDisableContact } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import { CreateEditFollowup } from '../../Redux/Thunk/createsettinglist';
import DisplayError from '../../validation/DisplayError';
import validate from '../../validation/validation';
import {leaddetails } from '../../Redux/Thunk/createleadthunk';
import TableListTitle from '../../components/custom/TableListTitle';
import FollowUpDetails from '../FollowUp/FollowUpDetails';
import { LeadFollowupDetails } from './LeadFollowupDetails';
import { TailSpin} from 'react-loader-spinner';
import { oppodetails } from '../../Redux/Thunk/createopportunitythunk';

const CardBody = styled('div')({
    backgroundColor:'#FFFFFF',
    padding:'10px 20px 20px',
    borderRadius:'10px',
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

const callResponces =[
    { value:'Contacted Call Back',label:"Contacted Call Back"}, { value:'Contacted Discussed',label:"Contacted Discussed"}, { value:'Next',label:"Next"},{ value:'No Response',label:"No Response"},{ value:'No Valid Number',label:"No Valid Number"},{ value:'Not Contactable',label:"Not Contactable"}
    ,{ value:'Not Reachable',label:"Not Reachable"}, { value:'Phone Switched Off',label: "Phone Switched Off"}
]

export const LeadFollowupList = () => {
    const [refresh, setRefresh] = useState(false)
    const {state}= useLocation()
    // const [followup, setFollowup] = useState(state?.followup)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {user} = useSelector((state) => state.auth)
    // const leadDetails = useSelector((state) => state.lead.leaddetail)
    // const followup = leadDetails?.getfollowup?.map((followup) =>followup)
    const [followups, setFollowup]= useState([])
    const permissionsDetails = user?.data?.user
    const {updateCreateEditFollowup} =useSelector((state) => state.settingList)
    const [page, setPage] = React.useState(1);
    const [loader, setLoader] = useState(false)
    const [openFollowupPopup, setOpenFollowupPopup] = useState(false)
    const [editFollowupDetails, setEditFollowupDetails] = useState({});
    const [selectedOpportunityDetails, setSelectedOpportunityDetails] = useState('')
   
    const Title= "Followup List"
    const tooltipTitle = "New Followup"
    const title ="LeadDetailsPage"

    const indexOfLastItem = page * 4;
    const indexOfFirstItem = indexOfLastItem - 4;
    const currentItems = followups?.getfollowup?.map((followup) =>followup)?.slice(indexOfFirstItem, indexOfLastItem);
    const buttonDisableforFollowupOpen= currentItems?.every(obj => obj.summary != '')
    const openFollowup= currentItems?.find((item)=>item?.summary ==='')

   useEffect(()=>{
    setLoader(true)
    if(state?.leaddetails){
        dispatch(leaddetails(state?.leaddetails?.id)).then((res)=>{
            setFollowup(res?.payload)
            setLoader(false)
        }) 
    }else{
        dispatch(oppodetails(state?.oppodetails?.id)).then((res)=>{
            setFollowup(res?.payload)
            setLoader(false)
        })
    }    
   },[updateCreateEditFollowup])
    // let validation_input = [
    //     (currentDate > currentItems?.map((item)=>new Date(Number(item[0]?.time))))? { tagid: 'missing_deadline_remark', text: closefollowup?.missing_deadline_mark, required: true, regex_name: 'free_text', errmsg: 'Please add missing deadline remark.', min:100, max:2000}:<></>,
    //     (currentItems?.map((item)=>item[0]?.followup?.summary === '')) ? { tagid: 'summary', text: closefollowup?.summary, required: true, regex_name: 'free_text', errmsg: 'Please add the closing Remark between 100 to 2000 characters', min:100, max:2000 }:<></>,
    //     (followup?.followup_type ==="Call") ? {tagid:'callResponce', text: callResponce, required: true, regex_name:'text', allow_numbers: true, other_chars: true, errmsg:'Please Select Type.'} :<></>,
    // ]
     
    // const handleChangeFollowup =(e) => {
    //     setCloseFollowup({...closefollowup,[e.target.name]:e.target.value})
    // }
   
    // const handleOfCallResponce =(event)=>{
    //     setCallResponce(event.target.value)
    // }
   
    const handleCreateFollowup=()=>{
        if(buttonDisableforFollowupOpen === false){
            sweetalert('warning',`Following ${openFollowup?.followup_no} has open followup, Please close followup then create new.`)
        }else{
         setOpenFollowupPopup(true)
        }
        // navigate('/dashboard/createfollowup')
    }

    const handleFollowupClose = (followup, setError) =>{
        // const createEditFollowup= {
        //     create :{
        //         companyid:'',
        //         followup_for_id:'', 
        //         operationmode:'create',
        //         followup_date:'',
        //         followup_type:'',
        //         followup_for: '',
        //         time:'', 
        //     },
        //     edit:{
        //         followupid: followup?.id, 
        //         operationmode:'edit',
        //         summary: closefollowup?.summary,
        //         missing_deadline_mark: closefollowup?.missing_deadline_mark, 
        //         call_response: callResponce 
        //     }
        // }
        // if(validate(validation_input).length === 0) {
        dispatch(CreateEditFollowup(followup)).then((res) =>{
            if(res?.payload?.errormsg) {
                sweetalert('error', res?.payload?.errormsg) 
                setError([]) 
            } else {
                setError([]) 
                sweetalert('success', 'Followup Closed Successfully.') 
                // swal({
                //     buttons: {
                //     Cancel: {
                //         text: "Go To Dashboard",
                //         value: "Cancel",
                //         className: "popup-blue",
                //     },
                //     Delete: {
                //         text: "Go To Followup List",
                //         value: "followupList",
                //         className: 'popup-gray',
                //     },
                //     },
                //     title: `Follow-up Successfully Closed.`,
                //     className: "custom-modal",
                //     closeOnClickOutside: false,
                // }).then((value)=>{
                //     if(value =='followupList'){
                //         if(state?.title ==="companyProfilePageLeadDetails"){
                //             navigate('/dashboard/profile/companyFollowup',{state: state?.followupInformation})
                //         }else{
                //             navigate('/dashboard/followuplist')
                //         }
                //     }else{
                //         navigate('/dashboard')
                //     }
                // })
            }
        })
       
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant==='error' ? 'error' : variant==='success'? 'success' :'warning',
            title: text,
            button: "Ok!",
        });
    };

    return (
        <> 
         { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                <TailSpin
                  height="30"
                  width="30"
                  color="blue"
                  ariaLabel="tail-spin-loading"
                  radius="3"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                /> </Box>:<>
            <CompanyName style={{background:'#3107AA'}} sx={{color:'#FFFFFF',letterSpacing:"0.04em"}}  label={`${(followups??'').company?.company_name} ${(followups??'').company?.company_type}`}/>  
            <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
                <Grid sx={{ borderBottom: '1px solid #e4e4e4', mt: 2, ml:4 , mr: 4, display: "flex", flexDirection: { lg: 'row', sm: 'row', xs: 'column-reverse' }, justifyContent: { lg: 'space-between', sm: 'space-between', xs: 'start' }, alignItems: { lg: 'flex-end', md: 'flex-end', xs: 'center' } }}>
                    <Typography variant='h6' sx={{ fontSize: { lg: '22px', md: '20px', sm: '18px' }, fontWeight: 600 }}> { followups?.leaddetails? `Follow up List  For ${followups?.leaddetails?.lead_no}` : `Follow up List  For ${followups?.oppodetails?.opportunity_no}` }</Typography>
                </Grid>

            {/* Search component and create new task */}
            <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mr:4 }}>
                {/* <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={} onClick ={handleCreateFollowup} onchange={handleSearch} /> */}
                {followups?.leaddetails?.status ==="Converted" || followups?.leaddetails?.status ==="Lost" || followups?.oppodetails?.opportunity_stage ==='Closed-Won' || followups?.oppodetails?.opportunity_stage ==="Closed-Lost"? <></>:
                    permissionsDetails?.role?.permissions?.perm_followup_create ?<>
                        <Tooltip title={tooltipTitle}>
                            <IconButton onClick={handleCreateFollowup}> 
                            <svg width="34" height="34" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="21" cy="21" r="20.5" fill="#A484FE" stroke="#A484FE"/>
                                <path d="M14.125 21H27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21 14.125V27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </IconButton>
                        </Tooltip>
                    </>:<></>}
            </Grid>
            </Grid>

            <Box sx={{mt:2, p:2, ml:{xs:1, sm:1, xl:3, lg:2}, mr:{xs:1, sm:1, xl:3, lg:2}, borderRadius:'10px', backgroundColor:'#D8D7FF'}}>
                <Grid container spacing={{xs: 2, md:2, lg:2, sm:2}} columns={{ xs: 2, sm: 2, md:12, lg:8}}>
                {/* < Create Followup/> */}
                {currentItems?.map((followup)=>( 
                    <>
                        <Grid item xs={2} sm={4} md={4} lg={4}>
                            <LeadFollowupDetails followup={followup} handleFollowupClose={handleFollowupClose}/>
                        </Grid> 
                    </>))}
                </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, pt: 4, pb: 4 }}>
                <PaginationFooter number={4} list={(followups?.getfollowup?.map((followup) =>followup))?.length} currentPage={page} setCurrentPage={setPage}/>
            </Box>

            {openFollowupPopup && <FollowUpDetails title={state?.leaddetails?.id ? "LeadDetailsPage":"OpportunityDetailsPage"} editFollowupDetails={editFollowupDetails} selectedOpportunityDetails={followups?.oppodetails} selectedLeadDetails={followups?.leaddetails} open={openFollowupPopup} setOpenFollowupPopup={setOpenFollowupPopup}/>}
            </>}
        </>
    )
}