import React from 'react';
import ExecutiveRoute from './ExecutiveRoute';

const Routing = ({isAuthenticated}) => {
  return (
    <>
     <ExecutiveRoute isAuthenticated={isAuthenticated}/>
     {/* {isAuthenticated === true ? <><ExecutiveRoute/></> :<>
    <Routes>
      <Route path='/' element={<Login/> }/>
    </Routes></>} */}
    </>
  )
}

export default Routing
