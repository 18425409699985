import React,{useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Card, Grid, Box, IconButton} from '@mui/material';
import { CompanyName, CountCard, ContactCard,AddIcon, DeleteIcon, EditIcon, FollowupCard, Navbar, OpportunityCard, OpportunityIcon, FollowUpIcon, TaskIcon, LeadIcon, OrganizationDetails} from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useLocation , Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import NewCountCard from '../../components/custom/NewCountCard';
import AdminRole from '../../adminrole/Adminrole';

const Item = styled('div')({
    display:'flex',
    justifyContent:'center'
})

export default function CompanyCountCard ({getCompanyDetails}){
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {user} = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())

    const permissionDisable =() =>{
        sweetalert("error", "You have no permissions to access this. Please contact your administrator for more information.")
    }

    const sweetalert = (variant, text) => {
        swal({
          icon: variant==='error' ? 'error' :'success',
          title: text , 
          button: "Ok!",
        }); 
    };

    return(
    <>
     <Box style={{marginTop:'15px'}}>
        <Grid container spacing={1} columns={{ xs:12, sm: 12, md: 12, lg: 12}}>
          <>
          {/* ========= Lead ========= */}
          <Grid item xs={6} sm={6} md={4} lg={4}>
          {(checkAdminRole && permissionsDetails?.perm_lead_view) || permissionsDetails?.perm_lead_view ? <>
            <Link to='/dashboard/profile/companyLeads' state ={getCompanyDetails?.data?.company} style={{textDecoration:'none'}} >
              <NewCountCard
                  sx={{
                    background: "var(--white, #FFFFFF)",
                    boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px', 
                    flex: 1, pl:2.5,pr:2.5,
                    mt:{xs:1, sm:1, lg:0},pt:1, pb:1,
                  }}
                   background="#FC3434"
                  // background= 'var(linear-gradient(136deg, rgba(252, 52, 52, 0.70) 0%, #FC3434 100%))'
                  openoppo ={getCompanyDetails?.data?.companydetailscount?.countofopenlead}
                  header="Leads"
                  open='Open'
                  startName="WIP"
                  endName="Converted"
                  lost = 'Closed'
                  closedlost = {getCompanyDetails?.data?.companydetailscount?.countofclosedlostlead}
                  incompletecount={getCompanyDetails?.data?.companydetailscount?.countofinprocesslead}
                  completecount={getCompanyDetails?.data?.companydetailscount?.countofconvertedlead}
                  icon={<LeadIcon height={40} width={40} />}
              />
            </Link>
            </>: <>
            <Item onClick={permissionDisable}> 
              <NewCountCard
                  sx={{
                    background: "var(--white, #FFFFFF)",
                    boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px', 
                    flex: 1, pl:2.5,pr:2.5,
                    mt:{xs:1, sm:1, lg:0},pt:1, pb:1,
                  }}
                  background="#FC3434"
                  header="Leads"
                  startName="Inprocess"
                  endName="Converted"
                  incompletecount={0}
                  completecount={0}
                  icon={<LeadIcon height={40} width={40} />}
                />
            </Item>
            </>}
          </Grid>
           
          {/* ========= opportunity List ========= */}
          <Grid item xs={4} sm={4} md={4} lg={4} >
          {(checkAdminRole && permissionsDetails?.perm_opportunity_view) || permissionsDetails?.perm_opportunity_view ?<>
            <Link to='/dashboard/profile/companyOpportunity' state ={getCompanyDetails?.data?.company} style={{textDecoration:'none'}}>
              <NewCountCard
                sx={{
                  background: "#FFFFFF",
                  // boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px', 
                  flex: 1,
                  pl:2.5,pr:2.5,pt:1, pb:1,
                  mt:{xs:1, sm:1, lg:0}
                }}
                startName="Won"
                open="Open"
                openoppo ={getCompanyDetails?.data?.companydetailscount?.countofopenoppo}
                endName="Lost"
                background = "#4b49f3"
                header="Opportunities"
                incompletecount={getCompanyDetails?.data?.companydetailscount?.countofclosedwonoppo}
                completecount={getCompanyDetails?.data?.companydetailscount?.countofclosedlostoppo}
                icon = {<OpportunityIcon height={40} width={40} />}
              />
            </Link>
            </> :<>
            <Item onClick={permissionDisable}> 
            <NewCountCard
                sx={{
                  background: "var(--white, #FFFFFF)",
                  // boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px', 
                  flex: 1,
                  pl:2.5,pr:2.5,pt:1, pb:1,
                  mt:{xs:1, sm:1, lg:0}
                }}
                startName="Closed Won"
                endName="Closed Lost"
                background="#4b49f3"
                header="Opportunities"
                incompletecount={0}
                completecount={0}
                icon = {<OpportunityIcon height={40} width={40} />}
              />
            </Item>
            </>}
          </Grid>

             {/* ========= task List ========= */}
             <Grid item xs={4} sm={4} md={4} lg={4} >
             {(checkAdminRole && permissionsDetails?.perm_task_view) || permissionsDetails?.perm_task_view ? <>
                <Link to='/dashboard/profile/companyTask' state ={getCompanyDetails?.data?.company} style={{textDecoration:'none'}} >
                        <NewCountCard
                        sx={{
                          background: "var(--white, #FFFFFF)",
                          boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)',
                          borderRadius: '8px', 
                          flex: 1,
                          pl:2.5,pr:2.5,pt:1, pb:1,
                          mt:{xs:1, sm:1, lg:0}
                        }}
                        startName="Open"
                        endName="Closed"
                        background="#9b9e04"
                        header="Tasks"
                        incompletecount={getCompanyDetails?.data?.companydetailscount?.countoftaskpending}
                        completecount={getCompanyDetails?.data?.companydetailscount?.countoftaskcompleted}
                        icon = { <TaskIcon height={40} width={40} /> }
                        />
                    </Link>
                    </> :<>
                    <Item onClick={permissionDisable}> 
                    <NewCountCard
                        sx={{
                          background: "var(--white, #FFFFFF)",
                          boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)',
                          borderRadius: '8px', 
                          flex: 1,
                          pl:2.5,pr:2.5,pt:1, pb:1,
                          mt:{xs:1, sm:1, lg:0}
                        }}
                        startName="Open"
                        endName="Closed"
                        background="#9b9e04"
                        header="Tasks"
                        incompletecount={0}
                        completecount={0}
                        icon = { <TaskIcon height={40} width={40} /> }
                    />
                    </Item>
                </>}
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
            <Link to={permissionsDetails?.perm_cof_view ? '/dashboard/profile/companycaflist' :''} state={getCompanyDetails?.data?.company} style={{ textDecoration: 'none',  color:'inherit' }}>
                <Box sx={{ background: "var(--white, #FFFFFF)",
                      boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)',
                      borderRadius: '8px', 
                      flex: 1, pl:2.5,pr:2.5,
                      mt:{xs:1, sm:1, lg:1},pt:1, pb:1}}>
                    <Typography sx={{textAlign:'center', borderBottom:'1px solid #E4E4E4', color:'#444444', fontSize: '14px', fontWeight:"700", letterSpacing:'0.04rem'}}>
                        COF
                    </Typography>
                    <Box sx={{display:'flex', mt:1, flexDirection:'row', justifyContent:'space-between'}}>
                        <Typography sx={{textAlign:'center', color:'#3C8AFF', fontSize: '12px', fontWeight:"600", letterSpacing:'0.04rem'}}>
                          Pending : <span style={{color:'#444444'}}>{getCompanyDetails?.data?.companydetailscount?.pending_cof}</span></Typography>
                        <Typography sx={{textAlign:'center', color:'#038400', fontSize: '12px', fontWeight:"700", letterSpacing:'0.04rem'}}>
                          Approved : <span style={{color:'#444444'}}>{getCompanyDetails?.data?.companydetailscount?.approved_cof}</span></Typography>
                    </Box>
                </Box>
              </Link>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Link to={permissionsDetails?.perm_service_view ? '/dashboard/profile/companyservicelist' :''} state={getCompanyDetails?.data?.company} style={{ textDecoration: 'none',  color:'inherit' }}>
                <Box sx={{ background: "var(--white, #FFFFFF)",
                      boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.2)',
                      borderRadius: '8px', 
                      flex: 1, pl:2.5,pr:2.5,
                      mt:{xs:1, sm:1, lg:1},pt:1, pb:1}}>
                      <Typography sx={{textAlign:'center',  borderBottom:'1px solid #E4E4E4', color:'#444444', fontSize: '14px', fontWeight:"700", letterSpacing:'0.04rem'}}>
                          Service  Details
                      </Typography>
                      <Box sx={{display:'flex', mt:1, flexDirection:'row', justifyContent:'space-between'}}>
                          <Typography sx={{textAlign:'center', color:'#3C8AFF', fontSize: '12px', fontWeight:"600", letterSpacing:'0.04rem'}}>
                            Pending : <span style={{color:'#444444'}}>{getCompanyDetails?.data?.companydetailscount?.pending_service_id}</span></Typography>
                          <Typography sx={{textAlign:'center', color:'#038400', fontSize: '12px', fontWeight:"700", letterSpacing:'0.04rem'}}>
                            Approved : <span style={{color:'#444444'}}>{getCompanyDetails?.data?.companydetailscount?.approved_service_id}</span></Typography>
                      </Box>
                </Box>
              </Link>
            </Grid>
       </>
        {/* ))} */}
        </Grid>
       </Box>
    </>
    )
}