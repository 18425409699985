import React from 'react'

const AddPlusIcon = ({For}) => {
  return (
    <svg width={`${For == 'oppo_details' ? '60' : '40'}`} height= {`${For == 'oppo_details' ? '60' : '40'}`} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21" cy="21" r="20.5" fill="#3107AA" stroke="#3107AA"/>
    <path d="M16.1875 21H25.8125" stroke="#F9F9F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 16.1875V25.8125" stroke="#F9F9F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default AddPlusIcon