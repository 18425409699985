import React from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const OutlinedButton = ({sx}) => {
  return (
    <Stack direction="row" m={10} spacing={2}>
     <Button variant="outlined" style={sx} sx={{borderRadius:'40px',pl:'40px',pr:'40px',fontSize:'16px',color:'#7673F6',border:'2px solid',textTransform:'none',fontWeight:600,letterSpacing:1}}>Go to Dashboard</Button>
    </Stack>
  )
}

export default OutlinedButton
