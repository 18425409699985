import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './Redux/store/store'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAOaghVtA_oFWnlJ3Cq-PCkKg_yGVMY-Cc",
  authDomain: "jaicrm-2023.firebaseapp.com",
  projectId: "jaicrm-2023",
  storageBucket: "jaicrm-2023.appspot.com",
  messagingSenderId: "307821329044",
  appId: "1:307821329044:web:bf70ff4d8146e970b99281",
  measurementId: "G-NEBE636GN5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);

root.render(
  <React.StrictMode>
    <BrowserRouter>
     <Provider store={store}>
      <PersistGate persistor={persistor}>
         <App />
      </PersistGate>
     </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
