import { createSlice } from '@reduxjs/toolkit'
import { createContactForm, displayContactList, editContact, enableDisableContact } from '../Thunk/createcontact'

const initialState = {
    createcontact: null,
    getContactList: [],
    update:false,
    updateContact :false,
    editCompanyDetails: null,
    statusChangeForContact:{},
    updateStatusforContact :false,
    displayCreateContact :false,
    contactDetails:{}
}

export const createContactSlice = createSlice({
          name:'createcontact',
          initialState,
          reducers:{
              storeContactDetails(state, action){
                state.contactDetails = action.payload
               }
          },
          extraReducers:(builder)=>{

            builder.addCase(createContactForm.fulfilled, (state, action)=>{
                 state.createcontact = action.payload
                 if(!action.payload.errormsg) {
                  state.displayCreateContact=!state.displayCreateContact
                 }
            })
      
            builder.addCase(displayContactList.fulfilled, (state, action)=>{
                  state.getContactList = action.payload
                  state.update = !state.update
            }) 

            builder.addCase(editContact.fulfilled, (state, action)=>{
              state.editCompanyDetails = action.payload
              if(!action.payload.errormsg) {
                state.updateContact = !state.updateContact
               }
             
            }) 
            
            builder.addCase(enableDisableContact.fulfilled, (state, action)=>{
              state.statusChangeForContact = action.payload
              //state.updateStatusforContact = !state.updateStatusforContact
            }) 
          }
})


export const { storeContactDetails } = createContactSlice.actions
export default createContactSlice.reducer