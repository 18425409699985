import { createSlice } from '@reduxjs/toolkit'
import { createuserthunk,getuserlistthunk,useractivestatusthunk } from '../Thunk/createuserthunk'

const initialState = {
     createduser : null,
     getuserlist : [],
     getactivestatus:null,
     update:false
}

export const createUserSlice = createSlice({
          name:'createduser',
          initialState,
          reducers:{
               updateData(state,action){
                console.log(state.update)
                state.update =  !state.update
               }
          },
          extraReducers:(builder)=>{
            builder.addCase(createuserthunk.fulfilled,(state,action)=>{
                 state.createduser = action.payload
            })

            builder.addCase(getuserlistthunk.fulfilled,(state,action)=>{
                 state.getuserlist = action.payload  
            })

            builder.addCase(useractivestatusthunk.fulfilled,(state,action)=>{
                 state.getactivestatus = action.payload
            })
         },
})


export const { updateData } = createUserSlice.actions
export default createUserSlice.reducer
