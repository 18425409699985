import React,{useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper, IconButton, Tooltip} from '@mui/material';
import { EditIcon, Navbar,PopperFilter, InputBox, CustemBtn, CompanyName, LessThanIcon, DisableAssignIcon, AssignIcon } from '../../../components/custom';
import FilterIcon from '../../../components/custom/FilterIcon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {fetchComanyLeads, opportunityAssignedForUser} from '../../../Redux/Thunk/createopportunitythunk';
import {FetchFollowupList} from '../../../Redux/Thunk/createsettinglist';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { headCells } from './headcells';
import AssignModal from '../../../components/custom/AssignModal';
import { leadAssignedToUser, leaddetails } from '../../../Redux/Thunk/createleadthunk';
import swal from 'sweetalert';
import FilterCloseIcon from '../../../components/custom/FilterCloseIcon';
import FollowUpDetails from '../../FollowUp/FollowUpDetails';
import TableListTitle from '../../../components/custom/TableListTitle';
import TableListSearch from '../../../components/custom/TableListSearch';
import { displayContactList } from '../../../Redux/Thunk/createcontact';
import { TailSpin} from 'react-loader-spinner';
import moment from 'moment';
import CallIcon from '../../../components/custom/CallIcon';
import MeetingIcon from '../../../components/custom/MeetingIcon';
import EmailIcon from '../../../components/custom/EmailIcon';
import DemoIcon from '../../../components/custom/DemoIcon';
import FollowupTaskIcon from '../../../components/custom/FollowupTaskIcon';
import { followupAssigned } from '../../../Redux/Thunk/createTaskthunk';

const CardBody = styled('div')({
   backgroundColor:'#F0EFFD',
   padding:'10px',
   borderRadius:'10px',
})

export default function CompanyWisefollowupList (){
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {user} = useSelector((state) => state.auth)
  const {state} = useLocation()
  const {updateCreateEditFollowup} = useSelector((state)=> state.settingList)
  const permissionsDetails = user?.data?.user?.role?.permissions
  // const {followupList } = useSelector((state) => state.opportunity)
  const [order, setOrder] = useState('asc');
  const [followupList, setFollowupList]= useState([])
  const [update, setUpdate] = useState(false);
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAssignModal,setOpenAssignModal] = useState(false)
  const [openFollowupPopup, setOpenFollowupPopup]= useState(false)
  const [search, setSearch] = React.useState('')
  const [editFollowupDetails, setEditFollowupDetails] = useState({});
  const [filterfollowup, setFilterfollowup] = useState('')
  const [placement, setPlacement] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenopper] = React.useState(false);
  const [followupassigned, setFollowupAssigned]= useState({})
  const [followupClosed, setFollowupClosed] = useState(false)
  const [loader, setLoader] = useState(false)
  const Title= "Follow-Up List"
  const tooltipTitle = "New Follow Up"
  const placeholderTitle = "Search followup"
  const followupName = "Follow-up"
  const route ="companyProfilePage"

  useEffect(()=> {
     getFollowupDetailsList()
  },[update, updateCreateEditFollowup])

  const getFollowupDetailsList = () => {
    setLoader(true)
    dispatch(FetchFollowupList({start: page*rowsPerPage, length:rowsPerPage, search:search, filterfollowup:filterfollowup, company_id:state?.id})).then((res)=>{
      setFollowupList(res?.payload)
      setLoader(false)
    })
  }

  const handleCloseFilter = () => {
    setFilterfollowup('')
    setUpdate(!update)
  }

  const handleCreateFollowup= () => {
    navigate('/dashboard/createfollowup',{state :{title:route, companyName:state, operation:'createFollowup'}})
   // dispatch(fetchComanyLeads(state?.id))
    dispatch(displayContactList(state?.id))
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
  
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    
    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => (
            <TableCell
              sx ={{fontWeight:600, fontSize:'13px'}}
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'normal' : 'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

            <TableCell align='left' sx = {{zIndex:0, padding:'0px', fontWeight:600, fontSize:'13px'}}>Schedule Date & Time</TableCell>
            <TableCell align='left' sx = {{zIndex:0, padding:'0px', fontWeight:600, fontSize:'13px'}}>Status</TableCell>
           {/* {permissionsDetails?.perm_assign_edit ? <TableCell align='left' sx ={{ fontWeight:600, fontSize:'13px' }}>Assign</TableCell>:""} */}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setUpdate(!update)
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUpdate(!update)
  };
  
  const saveAssignedManagerOrExecutiveName =(managerList, selectManagerName)=>{
    if(selectManagerName ===''){
      sweetalert ('error', "Please Select Name") 
    }else{
      let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectManagerName)
          dispatch(followupAssigned({followupid:followupassigned?.id, user_id:findUser?.id})).then((res)=>{
            if(res?.payload?.errormsg){
              sweetalert ('error', res?.payload?.errormsg) 
              setOpenAssignModal(false)
            }else{
              sweetalert ('success', res?.payload?.data) 
              setOpenAssignModal(false)
              setUpdate(!update)
            }
      })
    }
    //     let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectManagerName)
    //     if(leadAssigned?.followup_for === 'lead'){
    //         dispatch(leadAssignedToUser({lead_id:leadAssigned?.responseFollowup?.id, user_id:findUser?.id})).then((res)=>{
    //           if(res?.payload?.errormsg){
    //               sweetalert ('error', res?.payload?.errormsg) 
    //               setOpenAssignModal(false)
    //           }else{
    //               sweetalert ('success', res?.payload?.data) 
    //               setOpenAssignModal(false)
    //               setUpdate(!update)
    //           }
    //         })
    //     }else{
    //         dispatch(opportunityAssignedForUser({opportunity_id:leadAssigned?.responseFollowup?.id, user_id:findUser?.id})).then((res)=>{
    //         if(res?.payload?.errormsg){
    //             sweetalert ('error', res?.payload?.errormsg) 
    //             setOpenAssignModal(false)
    //         }else{
    //             sweetalert ('success', res?.payload?.data) 
    //             setOpenAssignModal(false)
    //             setUpdate(!update)
    //         }
    //         })
    //     }
    // }
  }

  const handleClickOpenLeadDetailsPage =(followup)=>{
    if(permissionsDetails?.perm_lead_view){
      dispatch(leaddetails(followup?.responseFollowup?.id)).then((res)=>{
        if(res.payload) {
        navigate('/dashboard/leaddetails', {state: {leadDetails: followup?.responseFollowup, title:"FollowupLeadDetails", loader:true}})
        }
      })
    }else{
      sweetalert('error', "You have no permissions to access this. Please contact your administrator for more information.")
    }
  }

  const openFollowupPage =(followup) =>{
      if(followup?.closed_on === 0){  
        if(permissionsDetails?.perm_followup_edit){
          setEditFollowupDetails(followup)
          setOpenFollowupPopup(true)
          setFollowupClosed(false)
        }else{
          sweetalert('error', "You have no permissions to access this. Please contact your administrator for more information.")
        }
      }else{
        setEditFollowupDetails(followup)
        setFollowupClosed(true)
        setOpenFollowupPopup(true)
        // sweetalert("error", "This followup is closed. Create new followup")
      }
  }

  const handleAssign = (followup) => {
    setFollowupAssigned(followup)
    setOpenAssignModal(true)
  }

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setUpdate(!update)
  }

  const handleFilter = (e)=> {
    setPage(0)
    if (e.target.value == "All followup") {
      setFilterfollowup('')
      setOpenopper(false)
      setUpdate(!update)
    } else {
      setFilterfollowup(e.target.value)
      setOpenopper(false)
      setUpdate(!update)
    }  
  }

  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
    {/* <Box sx={{display:'flex', flexDirection:{xs:'column',md:'column',lg:'column'}, mt:2, ml:{xs:1,sm:1,xl:5,lg:4}, mr:{xs:1,sm:1,xl:5,lg:4}}}> */}
      <CompanyName sx={{"&:hover": {cursor:'pointer'}}} label={`${state?.company_name} ${state?.company_type}`} companyID={state?.id}/>    
    
    {/* Display Followup List */}
      <PopperFilter filtervalues ={[{name:'All followup'}, {name:'Open'},{name:'Close'}]} open={openPopper} anchorEl={anchorEl} placement={placement} onClick={handleFilter} />
      
      {/* Display Followup Lists   */}
      <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
        <TableListTitle title={Title} />
  
        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={permissionsDetails?.perm_followup_create} onClick ={handleCreateFollowup} onchange={handleSearch} />     
          <Tooltip title="Filter By Status" placement="top-start">
            <IconButton onClick={handleClick("bottom-start")} sx={{mr: {lg:4, xs:1}}}>
              {!openPopper ? <FilterIcon /> : <FilterCloseIcon />}
            </IconButton>
          </Tooltip>
          </Grid>
      </Grid>

      {
      filterfollowup ? <Box sx={{ml:4, display:'flex', justifyContent:'space-between', fontSize:'10px',width:'24%',p:0.5,mt:0.5, border:'1px solid black', borderRadius:'10px'}}>
        <Typography sx={{fontSize:'12px'}}>
       Showing Follow-up for status  <span style={{color:'blue', marginLeft:'10px'}}>{filterfollowup}</span> 
      </Typography>
      <Typography onClick={handleCloseFilter} sx={{color:"red", fontSize:'12px', cursor:'pointer', fontWeight:600, mr:0.5}}>
         Clear Filter X
      </Typography>
      </Box>:""
    }

    {/* <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
      <TableListTitle title={Title} />

        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Tooltip title={tooltipTitle}>
                <IconButton sx={{mr: {lg:4, xs:1}}} onClick={handleCreateFollowup}> 
                  <svg width="32" height="32" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="21" cy="21" r="20.5" fill="#A484FE" stroke="#A484FE"/>
                      <path d="M14.125 21H27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M21 14.125V27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </IconButton>
          </Tooltip>
        </Grid>
      </Grid> */}

      <CardBody sx={{mt:1, ml:{xs:1, sm:1, md:1, xl:4, lg:4}, mr:{xs:1, sm:1, md:1, xl:4, lg:4}}}> 
        <Paper sx={{padding:'10px', mb: 2 }}>
          <TableContainer sx={{ maxHeight: 440}}>
            <Table
              sx={{ minWidth: 750 }}
              size= 'small'
              stickyHeader aria-label="sticky table">  
                <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={followupList?.data?.length}
                />
                  { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                  <TailSpin
                    height="30"
                    width="30"
                    color="blue"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  /> </Box>:<>
                  <TableBody>
                  {followupList?.data?.length === 0? <TableRow>
                      <TableCell colSpan={9} style={{textAlign: 'center', color:'red', fontWeight:600}}>
                        No follow-ups available
                      </TableCell>
                    </TableRow>:( 
                      followupList?.data?.map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                          <TableRow
                              hover
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row?.followup_no}
                              selected={isItemSelected}>
                              <TableCell component="th" id={labelId} scope="row" padding="none" align='left' sx={{padding:'3px', fontSize:'14px'}}>
                                  <Typography sx={{color:'#4F709C',fontWeight: 600, fontSize:'14px', "&:hover": {cursor:'pointer'}}} onClick={()=> openFollowupPage(row)} >{row.followup_no}</Typography>
                              </TableCell>
                              <TableCell align="left" sx={{padding:'3px', fontSize:'14px', fontWeight: 600, color:'#C72424', "&:hover": {cursor:'pointer'}}} onClick={()=> handleClickOpenLeadDetailsPage(row)}>{row?.responseFollowup?.lead_no}</TableCell>
                              <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>{row?.responseFollowup?.contact?.first_name} {row?.responseFollowup?.contact?.last_name}</TableCell>
                              <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>{row?.created_by?.firstname} {row?.created_by?.lastname} </TableCell>
                              <TableCell align="left" sx={{ padding: '3px', fontSize: '12px', "&:hover": row?.closed_on != 0 ? {cursor:'default'}:{cursor:'pointer'}}} onClick={()=> row?.closed_on != 0 ? "" : handleAssign(row)}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span style={{ marginRight: '4px' }}>{row?.assigned_to?.firstname}</span>
                                  <span style={{ marginRight: '4px' }}>{row?.assigned_to?.lastname}</span>
                                  <span>
                                    {permissionsDetails?.perm_assign_edit ? <>
                                      <Tooltip title="Assign Follow-up to" placement="top-start">
                                      <IconButton>
                                      {row?.closed_on != 0 ? <DisableAssignIcon/>: <AssignIcon /> } 
                                    </IconButton> 
                                    </Tooltip></>: " "}
                                  </span>
                                </div>
                              </TableCell>
                              {/* <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>  
                                {row?.responseFollowup?.assigned_to?.firstname} {row?.responseFollowup?.assigned_to?.lastname}</TableCell>   */}
                              <TableCell align="left" sx={{padding:'3px'}}>
                                <Tooltip title={row?.followup_type ==="Call" ?
                                  "Call": row?.followup_type ==="Meeting"? "Meeting":row?.followup_type ==="Email"? "Email":row?.followup_type  === "Demo" ? "Demo": "Task" } placement="top-start">
                                  <IconButton>
                                    {row?.followup_type ==="Call" ?
                                    <CallIcon/> : row?.followup_type ==="Meeting"? <MeetingIcon/>:row?.followup_type ==="Email"? <EmailIcon/>:row?.followup_type  === "Demo" ? <DemoIcon/>:<FollowupTaskIcon/> }
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>{moment(row?.followup_date).format('D/M/YYYY hh:mm A')}</TableCell>
                              <TableCell align="left" sx={{padding:'3px', fontSize:'12px', color: row?.closed_on === 0 ?'#3C8AFF' : '#049E01'}}>{row?.closed_on === 0 ? "Open" :"Closed"}</TableCell> 
                              {/* {permissionsDetails?.perm_assign_edit ? <><TableCell align="left"sx={{padding:'3px', fontSize:'14px'}}><CustemBtn 
                                  sx={{backgroundColor: '#FFC700', color:'white', borderRadius:'20px', color:'#000000',
                                  "&:hover": {
                                      backgroundColor: row?.status == 'Assigned'?'#007500' : '#FFC700' 
                                  }}} variant='contained' disabled={row?.closed_on != 0} onClick={()=> handleAssign(row)} text='Assign'
                              /> 
                              </TableCell> </> :<></>}             */}
                          </TableRow>
                      );
                      }))}
                  </TableBody>
                   </>}
              </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={followupList?.recordsTotal ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
  
        {/* <Link to='/dashboard/profile' state={state?.id} style={{textDecoration:'none'}} >
            <Typography display='flex' justifyContent='start' sx={{color:'#000000', fontWeight:600, fontSize:'13px', letterSpacing:0.5, mt:3,textAlign:'start'}}>
                <LessThanIcon/> Back to Company Profile
            </Typography>
        </Link> */}

      </CardBody>

      {openFollowupPopup && <FollowUpDetails  followupClosed ={followupClosed} setUpdate={setUpdate} update={update} open={openFollowupPopup} setOpenFollowupPopup={setOpenFollowupPopup} editFollowupDetails={editFollowupDetails} setEditFollowupDetails={setEditFollowupDetails}/>}
      {openAssignModal && <AssignModal title={followupName} taskNumber={followupassigned?.followup_no} taskAssignedFromUser={followupassigned} setUpdate={setUpdate} update={update} openAssignModal={openAssignModal} setOpenAssignModal= {setOpenAssignModal} saveAssignedManagerOrExecutiveName={saveAssignedManagerOrExecutiveName}/>}
    {/* </Box> */}
    </>

  );
}
