import TextareaAutosize from '@mui/base/TextareaAutosize';
import React from 'react'

export default function TextArea ({style, placeholder, onChange, name, Default, value, disabled}){
  return (
    <TextareaAutosize
        aria-label="empty textarea"
        placeholder={placeholder}
        style={style}
        value={value}
        onChange={onChange}
        name={name}
        defaultValue={Default}
        disabled={disabled}
    />
  )
}

