import { createSlice } from '@reduxjs/toolkit'
import { createCompany, editCompany, getCompanyList, verifyCompanyName, getAllCompanyName, displayCompanyDetails } from '../Thunk/createcompanythunk'

const initialState = {
    getcreatecompany: null,
    getcompanylist: null,
    companyName: [],
    allCompanyName: [],
    companyupdate:null,
    getCompanyDetails :null,
    updateCompany:false,
    companyData:{},
    storeCompanyDetails:{}
}

export const createCompanySlice = createSlice({
          name:'createcompany',
          initialState,
          reducers:{
               emptyCompanyName(state,action){
                console.log(action.payload)
                state.companyName = action.payload
               },

               storeCompanyData(state, action){
                state.companyData = action.payload
               },

               emptyCompanyDetailsData(state, action){
                    state.getCompanyDetails = null
               },

               storeCompayName(state, action){
                    state.storeCompanyDetails = action.payload
                    console.log(action.payload,"action.payload")
               },

               storeAddressDetails(state, action){
                    state.createAddressDetails = action.payload
               },
                   
          },
          
          extraReducers:(builder)=>{
            builder.addCase(createCompany.fulfilled,(state,action)=>{
                 state.getcreatecompany = action.payload
                 state.updateCompany = ! state.updateCompany
            })

            builder.addCase(getCompanyList.fulfilled,(state,action)=>{
                 state.getcompanylist = action.payload  
            })

            builder.addCase(editCompany.fulfilled, (state,action)=>{
                 state.companyupdate = action.payload
            })
            
          //   Verify Company name.
            builder.addCase(verifyCompanyName.fulfilled, (state, action) => {
               state.companyName = action.payload 
            })
 
          //   Display all Company names 
            builder.addCase(getAllCompanyName.fulfilled, (state, action) => {
               state.allCompanyName = action.payload 
            })
            
          //  Get company details for company profile page
            builder.addCase(displayCompanyDetails.fulfilled, (state, action) => {
               state.getCompanyDetails = action.payload 
            })
         },
})


export const { emptyCompanyName, storeCompanyData, storeAddressDetails, emptyCompanyDetailsData, storeCompayName} = createCompanySlice.actions
export default createCompanySlice.reducer
