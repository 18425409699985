import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Paper, Tooltip, Snackbar, Typography, styled, IconButton } from '@mui/material';
import { H1FormTitle, T6TableText, T1ButtonText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, H2POPupMainText } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor'
import { LeadTaskDetails } from '../LeadDetails/LeadTaskDetails';
import { AddPlusIcon } from '../../components/custom';
import CreateTaskForm from '../Task/CreateTaskForm';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { createTaskForLead } from '../../Redux/Thunk/createTaskthunk';
import AdminRole from '../../adminrole/Adminrole';
import AddIcon from '../../components/custom/AddIcon';

const CardBody = styled('div')({
    backgroundColor:'#FFFFFF',
    padding:'20px',
    borderRadius:'10px',
    height:'30%',
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
})

const Container = styled('div')({
    padding: '5px',
    borderRadius: '10px',
    width: '100%',
    height: '100%', // This ensures the border covers the entire height
    border: '2px dotted var(--dark-grey, #858585)',
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
});

export default function TaskDetails({ tasklist, opportunity, ids }) {
    const dispatch = useDispatch()
    const [openTaskPopup, setOpenTaskPopup] = useState(false)
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const [taskedit, setTaskEdit] = useState({})
    const [hierchy, setHierachy] = useState(false)
    const title = 'Opp Details'

    useEffect(() => {
       findIds(opportunity?.assigned_to?.id)
    }, [opportunity?.opportunity_id])
 
    const findIds= (id1, id2) => {
       if (ids?.includes(id1) || ids?.includes(id2)) {
          setHierachy(true)
       } else {
          setHierachy(false)
       }
   }
    
    const handleCreateNewTask = () => {
        setOpenTaskPopup(true)
        setTaskEdit({})
    }
    
    const handleCloseTask = (closeTask, setCloseTask, setError) => {
        dispatch(createTaskForLead(closeTask)).then((res) => {
            if (res?.payload?.errormsg) {
                sweetalert('error', res?.payload?.errormsg)
                setError([])
            } else {
                sweetalert('success', res?.payload?.data?.msg)
                setError([])
                setCloseTask({})
                // swal({
                //     buttons: {
                //         Cancel: {
                //             text: "Go To Dashboard",
                //             value: "Cancel",
                //             className: "popup-blue",
                //         },
                //         Delete: {
                //             text: "Go To Task List",
                //             value: "taskList",
                //             className: 'popup-gray',
                //         },
                //     },
                //     title: res?.payload?.data,
                //     className: "custom-modal",
                //     closeOnClickOutside: false,
                // }).then((value) => {
                //     if (value == 'taskList') {
                //         navigate('/dashboard/tasklist')
                //     } else {
                //         navigate('/dashboard')
                //     }
                // })
            }
        })
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };
    return (
        <>
            <Grid sx={{ mb: 1, ml: 2, display: 'flex', justifyContent: 'space-between' }}>
                <T1ButtonText sx={{ color: themeColor.palette.Primarytext1 }}>
                    Activities
                </T1ButtonText>
        
                {opportunity?.opportunity_stage === "ClosedLost" || opportunity?.opportunity_stage === "ClosedWon" || opportunity?.opportunity_stage === "ClosedNoAction" || (opportunity?.opportunity_stage === "ClosedWon" && opportunity?.approval_status == 'Approved') || (opportunity?.opportunity_stage === "VerbalConfirmation" && opportunity?.approval_status == 'Pending') ? <></> :
                (tasklist?.length > 0 && (((user?.data?.user?.id == opportunity?.assigned_to?.id) && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create))) ? <>
                    <Tooltip title="Create New Task" placement="bottom-start">
                        <Typography id='add_task1' sx={{cursor:'pointer'}} onClick={() => handleCreateNewTask()}> 
                            <AddIcon/> {/* <AddPlusIcon/> */}
                        </Typography>
                    </Tooltip>
                 </>:<></>}  
            </Grid>
        
           {(tasklist?.length == 0) ?  
            <CardBody>
                <Container>
                {(opportunity?.opportunity_stage === "ClosedLost" || opportunity?.opportunity_stage === "ClosedWon" || opportunity?.opportunity_stage === "ClosedNoAction"  || (opportunity?.opportunity_stage === "ClosedWon" && opportunity?.approval_status == 'Approved') || (opportunity?.opportunity_stage === "VerbalConfirmation" && opportunity?.approval_status == 'Pending')) ? <H2POPupMainText sx={{color: themeColor.palette.Primarytext1, height:'50px', mt:2}}>No Tasks Created Yet</H2POPupMainText>:
                        (((user?.data?.user?.id == opportunity?.assigned_to?.id) && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create)) ? 
                        <> <Grid item xs={12} md={10} sm={10} lg={12} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>   
                            <IconButton id='add_task2' onClick={() => handleCreateNewTask()}>
                            <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.0011 45.0725C35.3624 45.0725 44.5725 35.8624 44.5725 24.5011C44.5725 13.1398 35.3624 3.92969 24.0011 3.92969C12.6398 3.92969 3.42969 13.1398 3.42969 24.5011C3.42969 35.8624 12.6398 45.0725 24.0011 45.0725Z" stroke="#088F05" stroke-width="3.42857" stroke-miterlimit="10" />
                                <path d="M15.4297 24.5H32.5725" stroke="#088F05" stroke-width="3.42857" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M24 15.9297V33.0725" stroke="#088F05" stroke-width="3.42857" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </IconButton>  
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} lg={12}>   
                            <H2POPupMainText sx={{color: themeColor.palette.Primarytext1}}>Create Task</H2POPupMainText>
                        </Grid>     
                        </> :  <Grid item xs={12} md={10} sm={10} lg={12}>
                            <H2POPupMainText sx={{color: themeColor.palette.Primarytext1, height:'50px', mt:2}}>No Tasks Created Yet</H2POPupMainText>
                    </Grid> 
                }
                </Container>
            </CardBody> : 
            <Box sx={{minHeight: '100vh', height:'900px', overflowY: 'auto', '::-webkit-scrollbar': { width: '0' }}}>
            <>{tasklist?.map((task) => {
                        return <LeadTaskDetails opportunity={opportunity} ids={ids} show={(user?.data?.user?.id == task?.assigned_to.id)} handleCloseTask={handleCloseTask} task={task} For='oppo_details' />
                    })
                }</> </Box>
           }
            {/* <CardBody sx={{ overflow: "hidden", overflowY: "scroll",height:`${tasklist?.length == 0 ? '200px' : '800px'}`, display: `${tasklist?.length == 0 ? 'flex' : ''}`, alignItems: 'center', justifyContent: 'center' }}>
                {
                    tasklist?.length == 0 ? <Typography onClick={() => handleCreateNewTask()} sx={{ color: 'red', cursor:'pointer' }} ><AddPlusIcon For= 'oppo_details' /></Typography> : tasklist?.map((task) => {
                        return <LeadTaskDetails handleCloseTask={handleCloseTask} task={task} For='oppo_details' />
                    })
                }
            </CardBody> */}
            
            
            {openTaskPopup && <CreateTaskForm For ='create' openTaskPopup={openTaskPopup} taskedit={taskedit} setOpenTaskPopup={setOpenTaskPopup} selectedOpportunityDetails={opportunity} />}
        </>
    )
}