import { createSlice } from '@reduxjs/toolkit';
import { createlead, displayLeadList, editlead, leadValidate, companyLeads, leaddetails } from "../Thunk/createleadthunk";

const initialState = {
    createLead: null,
    fetchleadlist : [],
    editleadlist :[],
    leadvalidate :null,
    leaddetail:[],
    updateEditlead:false,
}

export const createLeadSlice = createSlice({
    name:'createlead',
    initialState,
    // reducers:{
    //      emptyCompanyName(state,action){
    //       console.log(action.payload)
    //       state.companyName = action.payload
    //      }
    // },

    extraReducers:(builder)=>{

      builder.addCase(createlead.fulfilled,(state,action)=>{
           state.createLead = action.payload
      })

      builder.addCase(displayLeadList.fulfilled,(state,action)=>{
        state.fetchleadlist = action.payload
      })

      builder.addCase(editlead.fulfilled,(state,action)=>{
        state.editleadlist = action.payload
        state.updateEditlead = ! state.updateEditlead
      })

      builder.addCase(leadValidate.fulfilled,(state,action)=>{
        state.leadvalidate = action.payload
      })

      builder.addCase(leaddetails.fulfilled,(state,action)=>{
        state.leaddetail = action.payload
      })
   },
})


// export const { emptyCompanyName } = createCompanySlice.actions
export default createLeadSlice.reducer
