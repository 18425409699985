import { InputBase } from '@mui/material'
import React from 'react'



const InputBox = ({sx, type,onCopy,onPaste, readOnly, disable,disabledcolor, placeholder, endAdornment, name, onchange, onblur, ref, id,Default,register,value, onKeyPress}) => {
   
  return (
    <InputBase onPaste={onPaste} onCopy={onCopy} disabled={disable} type={type} value={value} defaultValue={Default} sx={{...sx, "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor:disabledcolor
    },}} id={id} onChange={onchange} readOnly={readOnly} onBlur={onblur} placeholder={placeholder} endAdornment={endAdornment} name={name} onKeyPress={onKeyPress} inputRef={ref}></InputBase>
  )
}

export default InputBox


// sx={{width:'100%',border:"2px solid #B0AEFF",borderRadius:'10px',p:0.1,mt:1,pl:1}}