const LeadNoStatusIcon = () => {

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3624_88656)">
        <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" fill="#F9F9F9" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.0013 9.33398C16.3925 9.33398 16.7096 9.63246 16.7096 10.0007V12.0007C16.7096 12.3688 16.3925 12.6673 16.0013 12.6673C15.6101 12.6673 15.293 12.3688 15.293 12.0007V10.0007C15.293 9.63246 15.6101 9.33398 16.0013 9.33398ZM16.0013 19.334C16.3925 19.334 16.7096 19.6325 16.7096 20.0007V22.0007C16.7096 22.3689 16.3925 22.6673 16.0013 22.6673C15.6101 22.6673 15.293 22.3689 15.293 22.0007V20.0007C15.293 19.6325 15.6101 19.334 16.0013 19.334ZM23.0846 16.0007C23.0846 16.3689 22.7675 16.6673 22.3763 16.6673H20.2513C19.8601 16.6673 19.543 16.3689 19.543 16.0007C19.543 15.6325 19.8601 15.334 20.2513 15.334H22.3763C22.7675 15.334 23.0846 15.6325 23.0846 16.0007ZM12.4596 16.0007C12.4596 16.3689 12.1425 16.6673 11.7513 16.6673H9.6263C9.2351 16.6673 8.91797 16.3689 8.91797 16.0007C8.91797 15.6325 9.2351 15.334 9.6263 15.334H11.7513C12.1425 15.334 12.4596 15.6325 12.4596 16.0007ZM21.01 20.7147C20.7333 20.9751 20.2849 20.9751 20.0083 20.7147L18.5056 19.3005C18.229 19.0401 18.229 18.6181 18.5056 18.3577C18.7823 18.0973 19.2307 18.0973 19.5073 18.3577L21.01 19.7719C21.2866 20.0323 21.2866 20.4543 21.01 20.7147ZM13.497 13.6436C13.2203 13.904 12.7719 13.904 12.4952 13.6436L10.9926 12.2294C10.716 11.9691 10.716 11.547 10.9926 11.2866C11.2693 11.0263 11.7177 11.0263 11.9944 11.2866L13.497 12.7008C13.7736 12.9612 13.7736 13.3833 13.497 13.6436ZM10.9926 20.7147C10.716 20.4543 10.716 20.0323 10.9926 19.7719L12.4952 18.3577C12.7719 18.0973 13.2203 18.0973 13.497 18.3577C13.7736 18.6181 13.7736 19.0401 13.497 19.3005L11.9944 20.7147C11.7177 20.9751 11.2693 20.9751 10.9926 20.7147ZM18.5056 13.6436C18.229 13.3833 18.229 12.9612 18.5056 12.7008L20.0083 11.2866C20.2849 11.0263 20.7333 11.0263 21.01 11.2866C21.2866 11.547 21.2866 11.9691 21.01 12.2294L19.5073 13.6436C19.2307 13.904 18.7823 13.904 18.5056 13.6436Z" fill="url(#paint0_linear_3624_88656)"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_3624_88656" x1="16.0013" y1="9.33398" x2="16.0013" y2="22.6673" gradientUnits="userSpaceOnUse">
        <stop offset="0.223958"/>
        <stop offset="1" stop-opacity="0.31"/>
        </linearGradient>
        <clipPath id="clip0_3624_88656">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )}

    export default LeadNoStatusIcon