
import { Card, CardContent,CardHeader,styled,Typography,Box} from '@mui/material';

const CardHeaders = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'1px solid #E4E4E4'
})

const CardBody = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    marginTop:'10px'
})

export default function NewCountCard({closedlost, sx, header, lost, incompletecount, openoppo, completecount, CardData, icon, background, startName, endName,open, color, startCount}) {
    return (
        <>
            <Card  sx={{ ...sx}}>
                <Box>
                    <CardHeaders >
                        <Typography sx={{color:'#444444', fontSize: '14px', fontWeight:"700", letterSpacing:'0.04rem'}}>
                            {header}
                        </Typography>
                    </CardHeaders>
                    <CardBody>
                      {/* {CardData} */}
                      <Box sx={{alignSelf:'center'}} >
                        <Box sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: '1.6805px',
                            gap: ' 4.2px',
                            width: "3.5rem",
                            height: "3.5rem",
                            // background: `linear-gradient(135.71deg, #FFFFFF 4.26%, ${background} 95.75%)`,
                            // boxShadow: '1.6431535482406616px 3.2863070964813232px 3.2863070964813232px 0px rgba(0, 0, 0, 0.10)',
                             background: ` ${background}`,
                             boxShadow: '0px 4px 20px #e4e4e4', 
                            borderRadius: '8px',
                        }}>
                            { icon }
                        </Box>
                        </Box>
                         {
                            open ==='Open' ?   

                            <Box sx={{
                                flex:1,
                                flexGrow:2,
                                textAlign:"right",
                                justifyContent: "space-between",
                            }}>
                        
                        {/* <Box sx={{display:'flex', justifyContent: "space-between"}}> */}
                            <Box>
                                    <Typography sx={{
                                        justifyContent: "flex-start",
                                        color: ((startName === "WIP" ? "#038400":'#3C8AFF')),
                                        justifyContent: "flex-start",
                                        fontWeight: 600,
                                        fontSize: "12px",letterSpacing:'0.04rem'
                                    }}> {open}</Typography>
            
                                    <Box sx={{
                                        justifyContent: "flex-start",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                    }}>
                                        {openoppo}     
                                    </Box> 
                                       
                                   </Box>
                                   
                                {/* </Box> */}

                                
                               
                                  <Box sx={{display:'flex', justifyContent: "space-between"}}>
                                    <Box >
                                        <Typography sx={{
                                            justifyContent: "flex-start",
                                            color: ((startName === "Lost"? "#EB0000":'#038400')),
                                            justifyContent: "flex-start",
                                            fontWeight: 600,
                                            marginLeft:'30px',
                                            fontSize: "12px",letterSpacing:'0.04rem'
                                        }}> {startName}</Typography>
                
                                        <Box sx={{
                                            justifyContent: "flex-start",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                        }}>
                                            {incompletecount}
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Typography sx={{
                                            justifyContent: "flex-start",
                                            color: ((endName === "Lost" ? "#EB0000":'#038400')),
                                            fontWeight: 600,
                                            fontSize: "12px",letterSpacing:'0.04rem'
                                        }}> {endName}</Typography>
                                        <Box sx={{
                                            justifyContent: "flex-start",
                                            fontWeight:700,
                                            fontSize: "14px",
                                        }}>
                                            {completecount}
                                        </Box>
                                    </Box>

                                    {startName === "WIP" ? <Box>
                                        <Typography sx={{
                                            justifyContent: "flex-start",
                                            color: ("#EB0000"),
                                            fontWeight: 600,
                                            fontSize: "12px",letterSpacing:'0.04rem'
                                        }}>{lost}</Typography>
                                        <Box sx={{
                                            justifyContent: "flex-start",
                                            fontWeight:700,
                                            fontSize: "14px",
                                        }}>
                                          {closedlost}
                                        </Box>
                                     </Box>:<></>}
                                  </Box>

                        
                             </Box>




                                  : 
                                <Box sx={{
                                flex:1,
                                flexGrow:2,
                                textAlign:"right",
                                justifyContent: "space-between",
                                }}>
                            <Typography sx={{
                            justifyContent: "flex-start",
                            color: ((startName === "Inprocess"? "#FFC700":'#3C8AFF')),
                            justifyContent: "flex-start",
                            fontWeight: 600,
                            fontSize: "12px",letterSpacing:'0.04rem'
                         }}> {startName}</Typography>

                        <Box sx={{
                            justifyContent: "flex-start",
                            fontWeight: 700,
                            fontSize: "14px",
                        }}>
                            {incompletecount}
                           {/* {startCount} */}
                        </Box>
                        <Typography sx={{
                            justifyContent: "flex-start",
                            color: ((endName === "Lost" ? "#EB0000":'#038400')),
                            fontWeight: 600,
                            fontSize: "12px",letterSpacing:'0.04rem'
                        }}> {endName}</Typography>
                        <Box sx={{
                            justifyContent: "flex-start",
                            fontWeight:700,
                            fontSize: "14px",
                        }}>
                            {completecount}
                        </Box>
                            </Box>
                         }
                    </CardBody>
                </Box>
            </Card>
        </>
    )
}