import React ,{useState} from 'react';
import { useSelector } from 'react-redux';
import { FollowupDetail } from './FollowupDetail';
import { LeadFollowupList } from './LeadFollowupList';
import validate from '../../validation/validation';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Box, Grid, Card, IconButton, Tooltip } from '@mui/material';
import { CustemBtn, InputBox, PaginationFooter, SelectDropDown, TextArea } from '../../components/custom';
import DisplayError from '../../validation/DisplayError';
import moment from 'moment';

const CardBody = styled('div')({
    backgroundColor:'#FFFFFF',
    padding:'10px 20px 20px',
    borderRadius:'10px',
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

const callResponces =[
    { value:'Contacted Call Back',label:"Contacted Call Back"}, { value:'Contacted Discussed',label:"Contacted Discussed"}, { value:'Next',label:"Next"},{ value:'No Response',label:"No Response"},{ value:'No Valid Number',label:"No Valid Number"},{ value:'Not Contactable',label:"Not Contactable"}
    ,{ value:'Not Reachable',label:"Not Reachable"}, { value:'Phone Switched Off',label: "Phone Switched Off"}
]

export const LeadFollowupDetails = ({followup, title, followupInformation, handleFollowupClose}) => {
//     const followupdetails = leaddetails?.getfollowup?.map((followup) =>followup)
const [callResponce, setCallResponce] = useState('')
const [error, setError] = useState([])
const [closefollowup, setCloseFollowup] = useState({
    summary: '',
    missing_deadline_mark: ''
})
    const currentDate = new Date()

    let validation_input = [
         currentDate > new Date(Number(followup?.time)) ? { tagid: 'missing_deadline_mark', text: closefollowup?.missing_deadline_mark, required: true, regex_name: 'free_text', errmsg: 'Please add missing deadline remark between 100 to 2000 characters.', min:100, max:2000} :<></>,
        { tagid: 'summary', text: closefollowup?.summary, required: true, regex_name: 'free_text', errmsg: 'Please add the closing Remark between 100 to 2000 characters', min:100, max:2000 },
        (followup?.followup_type ==="Call") ? {tagid:'callResponce', text: callResponce, required: true, regex_name:'text', allow_numbers: true, other_chars: true, errmsg:'Please Select Type.'} :<></>,
    ]
 
    const handleChangeFollowup =(e) => {
        setCloseFollowup({...closefollowup,[e.target.name]:e.target.value})
    }

    const handleOfCallResponce =(event)=>{
        setCallResponce(event.target.value)
    }

    const submitfollowup = (followup) =>{
        const createEditFollowup= {
            create :{
                companyid:'',
                followup_for_id:'', 
                operationmode:'create',
                followup_date:'',
                followup_type:'',
                followup_for: '',
                time:'', 
            },
            edit:{
                followupid: followup?.id, 
                operationmode:'edit',
                summary: closefollowup?.summary,
                missing_deadline_mark: closefollowup?.missing_deadline_mark, 
                call_response: callResponce 
            }
        }
        if(validate(validation_input).length === 0) {
            console.log(createEditFollowup,createEditFollowup)
            handleFollowupClose(createEditFollowup, setError)
    }else {
        setError(validate(validation_input))
    }
    }

    return (
        <>
            {/* <Box sx={{mt:2, p:2, ml:{xs:1, sm:1, xl:3, lg:3}, mr:{xs:1, sm:1, xl:3, lg:3}, borderRadius:'10px', backgroundColor:'#F0EFFD'}}> */}
            <CardBody sx={{pb:2}}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                    <Grid item xs={12} md={4} sm={4} lg={6}>
                        <Typography>Follow-Up ID</Typography>
                        <InputBox disable={followup?.followup_no} value={followup?.followup_no} name='organizationName' sx={{fontWeight: 600, border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={6}>
                        <Typography>Scheduled Date</Typography> 
                        <InputBox disable={followup?.time} value={moment(Number(followup?.time)).format('D/M/YYYY')} name='organizationName' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid> 
                </Grid>
                
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                    <Grid item xs={12} md={2} sm={2} lg={3}>
                        <Typography style={{marginTop:'10px'}}>Time</Typography>
                        <InputBox disable={followup?.time} value={new Date(parseInt(followup?.time)).getHours()} name='hr' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2} sm={2} lg={3}>
                        <Typography style={{marginTop:'35px'}}></Typography>
                        <InputBox disable={followup.time} value={new Date(parseInt(followup.time)).getMinutes()} name='min' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2} sm={2} lg={3}>
                        <Typography style={{marginTop:'35px'}}></Typography>
                        <InputBox disable={followup.time} value={new Date(parseInt(followup.time)).toLocaleTimeString().slice(-2)} name='am/pm' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4} sm={4} lg={3}>
                        <Typography style={{marginTop:'10px'}}>Type</Typography>
                        <InputBox disable={followup.followup_type} value={followup.followup_type} name='type' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>

                {followup?.followup_type ==="Call" ? <>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                        <Typography style={{marginTop:'10px'}}>Call response<span style={{color:'#FF0000'}}>*</span></Typography>  
                        <SelectDropDown
                            name='callResponce'
                            placeholder ='Select Field'
                            options={callResponces} 
                            onchange={handleOfCallResponce}
                            //  label='Select industry'
                            value={followup?.call_response ==''? callResponce: followup?.call_response}
                            disabled={followup?.call_response !==''}
                            sx={{p:0.1, mt:0.5, backgroundColor: followup?.call_response !=='' ?'#E9E9FF' :'#FFFFFF', borderRadius:"10px"}}
                            MenuProps={MenuProps}
                        />
                        <DisplayError error={error} fortagid='callResponce' />
                    </Grid>
                </Grid>
                </>:<></>}

                {((currentDate > new Date(Number(followup?.time)) && followup?.summary ==='') || (followup?.missing_deadline_mark !=='')) ?
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt:1, paddingRight:'15px' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                   <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography>Missing Deadline Remark<span style={{ color: '#FF0000' }}>*</span></Typography>
                        <TextArea disabled={followup?.missing_deadline_mark !==''} onChange={handleChangeFollowup} value={followup?.missing_deadline_mark ===''? closefollowup?.missing_deadline_mark?.replace(/\s\s+/g, " "):followup.missing_deadline_mark} name='missing_deadline_mark' style={{fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", width: '100%',background: followup?.missing_deadline_mark !=='' ?'#E9E9FF' :'#FFFFFF', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', padding:'5px'}} placeholder='Write a note about missing Deadline remark.'/>
                        <DisplayError error={error} fortagid='missing_deadline_mark' />
                    </Grid>
                    {followup?.missing_deadline_mark  ==='' ? <><div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                        <span>Characters: {closefollowup?.missing_deadline_mark?.length}/2000</span>
                    </div></>:<></>}
                    </Grid>
                </Grid> : <></>}

                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingRight:'15px'}} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Typography style={{marginTop:'10px'}}>Closing Remark<span style={{ color: '#FF0000' }}>*</span></Typography>
                            <TextArea disabled={followup?.summary !==''} onChange={handleChangeFollowup} value={followup?.summary ==='' ? closefollowup?.summary?.replace(/\s\s+/g, " ") :followup?.summary} name='summary' style={{fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", width: '100%', background: followup?.summary !=='' ?'#E9E9FF' :'#FFFFFF', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', padding:'5px'}} placeholder='Write a note about closing remark.'/>
                            <DisplayError error={error} fortagid='summary' />
                        </Grid> 
                        {followup?.summary ==='' ? <><div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                            <span>Characters: {closefollowup?.summary?.length}/2000</span>
                        </div></>:<></>}
                    </Grid>
                </Grid> 
            
                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'end' }}>
                    <CustemBtn disabled={followup?.summary !==''} onClick={()=>submitfollowup(followup)} variant='contained' sx={{ borderRadius: "10px", fontSize: '16px', backgroundColor: '#7673F6' }} text="Close Follow-Up" />
                </Box>
            </CardBody>
            {/* </Box> */}
        </>
    )
}