import React from 'react'

const CloseIcon = ({w, h}) => {
  return (
    <svg width={w} height={h} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3303_132872)">
    <path d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z" fill="#D9D9D9" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5 10.5L10.5 17.5" stroke="#E70707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5 10.5L17.5 17.5" stroke="#E70707" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_3303_132872">
    <rect width={w} height={h} fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default CloseIcon