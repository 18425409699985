import React, { useEffect } from 'react';
import { Navbar, DropDown, CustemBtn, CompanyName, InputBox, DatePicker, TextArea} from '../../components/custom';
import {Grid, Box, styled, Card, Avatar, Typography, TextField,} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

const MainContainer = styled('div')({
    width:'100%',
    maxHeight:'100%',
    height:'auto',
})

const Container = styled('div')({
    background:'#F5F4FF',
    borderRadius:'10px',
    padding:'0px 20px 30px',
    margin:'30px',
    gap:'10px',
})

/**
* Lead Details For Opp Creation 
*/
const LeadDeatilsForOppCreation = ({selectedLeadDetails, selectedCompany, getContactList}) => {
    const navigate = useNavigate()
    const contactName = getContactList.find((name)=> name.id == selectedLeadDetails?.contact)

    const handleSave = () => {
        navigate('/dashboard/opportunity', {state:{company:selectedCompany, lead_id:selectedLeadDetails?.lead_no, operation:'createOpp'}})
    }

    return(

        <MainContainer>
            <Container>
                <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                    <Grid item xs={12} md={2} sm={2} lg={2}>
                        <Typography sx={{fontWeight: {xs:600, lg:400, md:600}}}>Organization</Typography>  
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={4}>
                        <Typography>Name</Typography>  
                        <InputBox value={selectedCompany?.value} disabled={true} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}}/> 
                    </Grid>
                    <Grid item xs={12} md={3} sm={3} lg={3}>
                        <Typography>Lead ID</Typography> 
                        <InputBox value={selectedLeadDetails?.lead_no} disabled={true} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}}/> 
                    </Grid>
                    <Grid item xs={12} md={3} sm={3} lg={3}>
                        <Typography>Contact Name</Typography>  
                        <InputBox value={`${contactName?.first_name ??''} ${contactName?.last_name ??''}`} disabled={true} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}}/>
                    </Grid>
                </Grid>

    {/*        Lead    */}
                <Box style={{marginTop:'30px'}}>
                    <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                        <Grid item xs={12} md={2} sm={2} lg={2}>
                            <Typography sx={{fontWeight: {xs:600, lg:400, md:600}}}>Lead</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} lg={4}>
                            <Typography>Name</Typography>
                            <InputBox value={selectedLeadDetails?.lead_name} disabled={true} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="Vodafone limited Mr, Ramesh Jadhav"/>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <Typography>Source</Typography>
                            <InputBox value={selectedLeadDetails?.lead_source} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="Parent Organization "/>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}l lg={3}>
                            <Typography>Data Source</Typography>
                            <InputBox value={selectedLeadDetails?.data_source} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="Mobigic Batabase "/>
                        </Grid>
                    </Grid>
                </Box>

                <Box style={{marginTop:'20px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={0} md={2} sm={2} lg={2}>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} lg={4}>
                            <Typography>Service Provider</Typography>
                            <InputBox value={selectedLeadDetails?.service_provider} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="TTML"/>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <Typography>Product Family</Typography>
                            <InputBox value={selectedLeadDetails?.product_family} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="Network Service"/>
                            {/* <Typography>{errors.email_address ? errors.email_address : ""}</Typography> */}
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <Typography>Product</Typography>
                            <InputBox value={selectedLeadDetails?.product} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="ILL"/>
                        </Grid>
                    </Grid>
                </Box>

    {/*         summary           */}
                <Box style={{marginTop:'40px'}}>
                    <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                        <Grid item xs={12} md={2} sm={2} lg={2}>
                            <Typography sx={{fontWeight: {xs:600, lg:400, md:600}}}>Summary</Typography>  
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} lg={10}>
                            <TextArea value={selectedLeadDetails?.summary} style={{width:'100%', fontFamily: 'Montserrat',fontStyle: 'normal', background:'#E9E9FF', border:"1px solid #B0AEFF", height:'80px',borderRadius:'8px',marginBottom:'15px',fontWeight: 400, fontSize: '14px', lineHeight: '17px',padding:'5px'}} placeholder ="Write a note about Lead."></TextArea>
                        </Grid>
                    </Grid>
                </Box> 
            </Container>

            <Box sx={{margin:'30px'}} display="flex" justifyContent="flex-end" mb={4}>
                <CustemBtn onClick={handleSave} variant="contained" sx={{borderRadius:"10px",pl:5,pr:5,fontSize:'16px',backgroundColor:'#7673F6'}} text="Proceed" />
            </Box>
        </MainContainer>
    )
}

export default LeadDeatilsForOppCreation
