import * as React from 'react';
import { styled,Box,InputBase,Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import  ProgressBar  from './ProgressBar';
import EditIcon from './EditIcon';
import OppoGoToDetailsBtn from './CustemBtn';
import InputBox from './InputBox';
import AddIcon from './AddIcon';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


const CardHeaders = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'0.5px solid #BBBBBB'
})

const CardBody = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    marginTop:"10px",
   
})

const CardData = styled('div')({
    display:'flex',
    flexDirection:'column',
})

const ChildCard = styled('div')({
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
    boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)'
 })

export default function AddressCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 550,borderRadius:'10px',boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)',backgroundColor:'#F7F1FF' }}>
      <CardContent>
       <CardHeaders>
        <Typography variant='h6' sx={{color:'#444444',fontSize:'22px',fontWeight:600}}>
        Addresses
        </Typography>
        <Typography sx={{pt:0.2}}>
         <AddIcon/>
        </Typography>
       </CardHeaders>
       <ChildCard sx={{p:2,mt:1}}>
       <Box sx={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid #D5D5D5'}}>
       <Typography sx={{fontSize:'18px',color:'#7673F6', fontWeight:600,letterSpacing:1}}>
           Registered Office
        </Typography>
        <Typography sx={{color:'#3C8AFF',fontSize:'18px'}} >
           <EditIcon w={20} h={20}/>
        </Typography>
       </Box>
       <CardData sx={{pt:1}}>
         <Typography>
            Address Details
         </Typography>
         <Typography sx={{fontSize:'14px',mt:1,color:'#9DA002'}}>
         Third floor, Flat Number. 1223, Urban Bliss, PAN Card Culb Road, Balewadi, Baner, Pune.
         </Typography>
       </CardData>
       <CardBody sx={{mt:2}}>
          <Typography sx={{flex:2}}>
            Email
          </Typography>
          <Typography sx={{flex:2}}>
            Phone No
          </Typography>
          <Typography sx={{flex:1.5}}>
            GST No
          </Typography>
       </CardBody>
       <CardBody>
          <Typography sx={{flex:2,fontSize:'14px',color:'#9DA002'}}>
           asdfghjkl@gmail.com
          </Typography>
          <Typography sx={{flex:2,fontSize:'14px',color:'#9DA002'}}>
            9898989898
          </Typography>
          <Typography sx={{flex:1.5,fontSize:'14px',color:'#9DA002'}}>
          24AAACC4175D1Z4
          </Typography>
       </CardBody>
       <CardBody sx={{mt:2}}>
          <Typography sx={{flex:1}}>
            Country 
          </Typography>
          <Typography sx={{flex:1}}>
            State 
          </Typography>
          <Typography sx={{flex:1}}>
            City
          </Typography>
          <Typography sx={{flex:1.1}}>
           Pin Code
          </Typography>
       </CardBody>
       <CardBody >
          <Typography sx={{flex:1,fontSize:'14px',color:'#9DA002'}}>
            India
          </Typography>
          <Typography sx={{flex:1,fontSize:'14px',color:'#9DA002'}}>
           Maharashtra
          </Typography>
          <Typography sx={{flex:1,fontSize:'14px',color:'#9DA002'}}>
           Pune
          </Typography>
          <Typography sx={{flex:1.1,fontSize:'14px',color:'#9DA002'}}>
           442424
          </Typography>
       </CardBody>
       </ChildCard>
      </CardContent>
    </Card>
  );
}
