import React,{useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { Navbar, DropDown, CustemBtn, SelectDropDown} from '../../components/custom';
import {Grid, Box, styled, Card, Avatar, Typography, TextField, DialogTitle, IconButton, DialogContent} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import CreateAddressPopup from './CreateAddresspopup';
import { main_search } from '../../Redux/Thunk/dashboardthunk';
import DisplayError from '../../validation/DisplayError';
import displayname from '../../displaycompanyname/displaycompany';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import swal from 'sweetalert';
import CloseIcon from '../../components/custom/CloseIcon';
import DialogHeaderCompanyName from '../../components/custom/DialogHeaderCompanyName';
import { TailSpin } from 'react-loader-spinner';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));


function BootstrapDialogTitle(props) {
    const { children, onClose, title, editAddress, companyData,...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p:2}} {...other}>
        {children}
        {onClose ? (
          <IconButton
            id='close_address'
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              display:'flex', justifyContent:'center', 
              right: 5,
              top: 1,
            }}
          >
           {companyData?.title === 'Create Company' || title === "CreateFromLead" || title === 'CompanyProfilePage'? <></> :<CloseIcon w={35} h={35}/>}
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

////   Create New Address Form  //
const CreateAddressDropdown = ({title, open, setOpen, companyDetails, setOpenCompanyModel, openCompanyModel}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {getcreatecompany, companyData} = useSelector((state)=> state.company)
    const [createAddressModel, setCreateAddressModel] = useState(false)
    const [allCompanyName, setallCompanyName] = useState([])
    const [searchinput, setSearchInput] = useState('') 
    const [companyName, setCompanyName]= useState({})
    // const {allCompanyName} = useSelector((state)=> state.company)
    // const [companyNames, setCompanyNames]= useState([])
    // const [dropdownValues, setDropdownValues]= useState(undefined)
    const [buttonStatus, setButtonStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [address, setAddress] = useState(undefined);
    const [error, setError] = useState([]);
    const [editCompanyDetails, setEditCompanyDetails] = React.useState({});
    const [addressType, setAddressType] = React.useState('');
    const [displayErrorMsg, setDisplayErrorMsg]= useState(false)

    const wordsToRemove = ["TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"];
    const companyNameList = (companyData?.title === 'Create Company') ? companyData?.companyDetails?.company_name : title ==='CreateFromLead' ? companyDetails?.company_name:''
    const companyTypeList = (companyData?.title === 'Create Company') ? companyData?.companyDetails?.company_type : title ==='CreateFromLead' ? companyDetails?.company_type:''
    const pattern = new RegExp(wordsToRemove.join("|"), "gi");
    const remainingNames = `${companyNameList} ${companyTypeList}`.replace(pattern, (match) => `(${match})`).trim();
  
    useEffect(() =>{
        if(companyData?.title === 'Create Company' || title === 'CompanyProfilePage' || title ==='CreateFromLead'){
            setCompanyName({
                branch:companyData ? companyData?.companyDetails?.head_office : companyDetails?.head_office,     /// Change head office name to branch 
                headOffice: companyData ? companyData?.companyDetails?.head_office :companyDetails?.head_office,
                label:companyData || title ==='CreateFromLead' ? remainingNames :`${companyDetails?.company_name} ${companyDetails?.company_type}`,
                registeredAddress:companyData? companyData?.companyDetails?.registered_address : companyDetails?.registered_address,
                value:companyData || title ==='CreateFromLead' ? remainingNames : `${companyDetails?.company_name} ${companyDetails?.company_type}`,
                id:companyData? companyData?.companyDetails?.id : companyDetails?.id
            })
        }
       },[companyData, title])

    useEffect(() => {
        if(searchinput.length > 1) {
            let timeout_func = setTimeout(() => {
            dispatch(main_search({searchinput, selectedoption:"Company"})).then((res) => {
                setallCompanyName(res.payload.data) 
                setLoading(false)
            })
            }, 1000)

            return () => {
            clearTimeout(timeout_func);
            };
        } else {
            setallCompanyName([]) 
            setLoading(false)
        }
    }, [searchinput])

    const handleClose = () => {
        // if(title === "Create Company"){
        //     navigate('/dashboard')
        // }else{
            setOpen(false)
            setOpenCompanyModel(false)
            setAddressType('')
        // }
    }

    const handleChange = (event) => {
        if(Object.keys(companyName)?.length === 0){ 
            setButtonStatus(false)
        }else{
            setAddressType(event.target.value);
            setButtonStatus(true)
        }
    }

    const handleClickSelectdAddress=()=>{
        if(Object.keys(companyName)?.length === 0){ 
           setDisplayErrorMsg(true)
        }else{
            setCreateAddressModel(true)
            setDisplayErrorMsg(false) 
            // setOpen(false) 
        }
    }

    return(
        <>
        <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        {/* Title  */}
        {companyData?.title === 'Create Company' || title === "CreateFromLead" || title === 'CompanyProfilePage' ? <DialogHeaderCompanyName label={title === 'CompanyProfilePage'? `${companyDetails?.company_name} ${companyDetails?.company_type}`: remainingNames} onClose={handleClose} title={title} />:""}
        <BootstrapDialogTitle sx={{display:'flex', pt: companyData?.title === 'Create Company' || title === "CreateFromLead" ? 0 : 1, pb:0, pl:2, pr:2,justifyContent:'start',fontWeight:600, borderRadius:'10px', color:'#000000', fontSize:'24px', letterSpacing: "0.04em"}} id="customized-dialog-title" title={title} companyData={companyData} onClose={handleClose}>Create Address</BootstrapDialogTitle> 
            <DialogContent dividers> 
                <Grid container spacing={{xs:1, sm:2, md:2, lg:4}}>
                    {companyData?.title === 'Create Company' || title === "CreateFromLead" || title === 'CompanyProfilePage'? "" :<>
                        <Grid item xs={12} md={6} sm={6} lg={5}>
                            <Typography sx={{fontSize:'14px', color:'#A3A3A3'}}>Company Name<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <div style={{ position: 'relative' }}>
                            <DropDown sx={{background:'#FFFFFF', mt:1}} 
                                id='select_company'
                                name='companyName'
                                options ={allCompanyName?.length > 0 ? displayname(allCompanyName) : []}
                                onChange = {(e, value, reason) => {
                                    if(value === null){
                                        setCompanyName('')
                                        //   emptyAddressDeatils()
                                        setSearchInput('')
                                        setAddressType('')
                                        setButtonStatus(false)
                                    }else{
                                        setCompanyName(value)
                                        setAddressType('')
                                        setButtonStatus(false)
                                    }
                                }}
                                onSearch={(e) =>{
                                if(e.target.value == '') {
                                    e.preventDefault()
                                    setallCompanyName([])
                                    setLoading(false)
                                } else {
                                    setSearchInput(e.target.value.trim())
                                    setLoading(true)
                                }
                                }}
                                clearOnBlur={true}
                                getOptionLabel={(option) =>(option.label ? option.label :"")}
                                value = {companyName}
                                placeholder ='Select Company '
                            />
                            {loading && <Box style={{display: 'flex', alignItems: 'center', position: 'absolute', top: '50%', left: '65%', transform: 'translate(-50%, -50%)'}}>
                                    <TailSpin
                                    height="25"
                                    width="40"
                                    color="blue"
                                    ariaLabel="tail-spin-loading"
                                    radius="2"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    /> <span>
                                    <Typography variant="caption" color="textSecondary">
                                        Loading...
                                    </Typography></span>
                                    </Box>}
                                    </div>
                            {displayErrorMsg ? <small style={{color:'red', fontSize:'smaller'}}>Please Select Company.</small> :" "}
                        </Grid>
                    </>}
                    <Grid item xs={12} md={6} sm={6} lg={7}>
                    <Typography sx={{fontSize:'14px', color:'#A3A3A3'}}>Select Address Type<span style={{color:'#FF0000'}}>*</span></Typography>
                    <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={addressType}
                        onChange={handleChange}
                        sx={{fontSize:'16px'}}
                    >
                        <FormControlLabel sx={{mr:2}} disabled={Object.keys(companyName)?.length === 0|| companyDetails?.registered_address?.register_address != null || companyDetails?.registeredAddress != null || companyName?.registeredAddress != null} value='Registered Office' control={<Radio />} label='Registered Office'/>
                        <FormControlLabel sx={{mr:2}} disabled={Object.keys(companyName)?.length === 0|| companyDetails?.head_office?.head_office != null || companyDetails?.headOffice != null || companyName?.headOffice != null} value='Head Office' control={<Radio />} label='Head Office' />
                        <FormControlLabel value='Branch Office' disabled={Object.keys(companyName)?.length === 0} control={<Radio />} label='Branch Office'/>
                    </RadioGroup>
                    </FormControl>  
                    </Grid>
                </Grid>
                <Box sx={{mt:4, display:'flex', justifyContent:'end' }}>
                    <CustemBtn id='save_add' onClick={handleClickSelectdAddress} disabled={!buttonStatus} variant='contained' sx={{borderRadius:"10px", pl:5, pr:5, fontSize:'16px', color:'#FFFFFF', "&:hover": {color:'#FFFFFF'}}} text ='Proceed'/>
                </Box>
            </DialogContent>
       </BootstrapDialog>
    
           {createAddressModel && <CreateAddressPopup title={companyData?.title === 'Create Company'? companyData?.title: title} openAddressModel={createAddressModel} open={open} setOpen={setOpen} addressType={addressType} setOpenAddressModel={setCreateAddressModel} companyDetails={title === "CreateFromLead" || title === "CompanyProfilePage" ? companyDetails: companyName} setButtonStatus={setButtonStatus}
            setEditCompanyDetails={setEditCompanyDetails} editCompanyDetails = {editCompanyDetails}/>}
        </>
    )
}

export default CreateAddressDropdown
