import React ,{useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {Grid, Box, styled, Card, Avatar, Typography, IconButton, TextField, DialogContent, DialogActions, CircularProgress, Button} from '@mui/material';
import {InputBox, Label, Logo, CustemBtn, EyeCloseIcon, Navbar, DropDown, DatePicker, TextArea} from '../../components/custom';
import  validate  from "../../validation/validation";
import { useLocation, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import SelectDropDown from "../../components/custom/SelectDropDown";
import dayjs from 'dayjs';
import {createCompany, verifyCompanyName, getAllCompanyName, displayCompanyDetails, editCompany} from "../../Redux/Thunk/createcompanythunk";
import { useDispatch, useSelector} from "react-redux";
import CompanyNameLists from "./CompanyNameLists";
import { emptyCompanyName, storeCompanyData,storeAddressDetails } from "../../Redux/slices/createCompanySlice";
import {organizationTypes} from './CompanyValues';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DisplayError from "../../validation/DisplayError";
import DatePickerOpenTo from "../../components/custom/DatePickerYearMonth";
import { TailSpin} from 'react-loader-spinner';
import { getIndustryTypesList } from "../../Redux/Thunk/createsettinglist";
import CreateAddressPopup from '../Addresspopup/CreateAddresspopup'; 
import CreateAddressDropdown from "../Addresspopup/CreateAddressDropDown";
import CloseIcon from '../../components/custom/CloseIcon';
import DialogHeaderCompanyName from "../../components/custom/DialogHeaderCompanyName";
import {H1FormTitle, T6TableText, H5FormLabel} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import AdminRole from '../../adminrole/Adminrole';
import { displayContactList } from "../../Redux/Thunk/createcontact";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const useStyles = makeStyles((theme) => ({
    typography:{
      fontSize:'14px',
      color:'#A3A3A3'
    }
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, title, editCompany, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p:2}} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              display:'flex', justifyContent:'center', 
              right: 5,
              // top: 11,
            }}
          >
           {title === 'companyProfile'|| (title === "Company List" && Object.keys(editCompany).length > 0)? <></> :<CloseIcon w={35} h={35}/>}
          </IconButton>
        ) : null}
      </DialogTitle>
    );
}
  
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

/**
 * @CompanyDetails
 * @description This component create and edit company details.
*/
// openAddressModel, setOpenAddressModel,
const CreateCompanyForm = ({openAddressModel, setOpenAddressModel, setUpdate, update, editCompanyDetails, openCompanyModel, setOpenCompanyModel, title}) => {
    const classes = useStyles();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {user} = useSelector(state => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const { state } = useLocation()
    const {companyName} = useSelector((state)=>state.company)
    const {displayIndustryList} = useSelector((state) => state.settingList)
    const [error, setError] = useState([]);
    const [editAddressDetails, setEditAddressDetails] = React.useState({});
    // const [editCompanyDetails, setEditCompanyDetails] = useState(state ? state?.company: {})
    const [buttonLoader, setButtonLoader] = useState(false)
    const [companyDetails , setCompanyDetails] = useState({
        company_type:'',
        name:'',
        industry_type:'',
        website: '',
        brand: '',
        turnover:'',
        summary: ''
    })
    const [identificationValues, setIdentificationValues] = useState({
        cin:'',
        tan:'',
        pan:'',
    })
    // editCompanyDetails? editCompanyDetails?.company_doi :
    const [value, setValue] = useState(Object.keys(editCompanyDetails)?.length === 0 ? dayjs(new Date()) : editCompanyDetails?.company_doi);
    const [turnoverondate, setTurnoverDate] = useState(Object.keys(editCompanyDetails)?.length === 0 ? dayjs(new Date()) : editCompanyDetails?.company_turnoverondate)
    const [displayCompanyType, setDisplayCompanyType]= useState('')
    const [openModal, setOpenModal] = useState(false)
    const [displayForm, setDisplayForm] = useState(false)
    const [disableCompanyName, setDisableCompanyName]= useState(Object.keys(editCompanyDetails)?.length > 0 ? true : false);
    // const [industryTypes, setIndustryTypes]= useState(displayIndustryList?.value?.length === 0 ? [] : displayIndustryList?.value?.map((type)=>{return {label:type, value:type}}))
    // const [openAddressModel, setOpenAddressModel]= useState(false)
    const [industryTypes, setIndustryTypes]= useState([])
    const [loader, setLoader]= useState(false)
    const [createCompanyDetails, setCreateCompanyDetails]= useState({})
    const [disableButtonStatus, setDisableButtonStatus] = useState(Object.keys(editCompanyDetails)?.length > 0 ? true :false)
    const [displayErrorMsg, setDisplayErrorMsg] = useState(false)
    const [address, setAddress] = useState({
        label:'',
        value:''
    });
    const route= "Create Company" 
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())

    useEffect(()=>{
        dispatch(getIndustryTypesList()).then((res)=>{
            setIndustryTypes(res?.payload?.value) 
        })

       if(Object.keys(editCompanyDetails).length > 0 ){ 
        //    console.log(industryTypes,"industryTypes")
            // let obj = {value:editCompanyDetails?.industry_type, label:editCompanyDetails?.industry_type}
            // let check_industry_value = industryTypes.find((industry) => industry.value === obj.value)
            // if(!check_industry_value) {
            //     setIndustryTypes([...industryTypes, obj]);
            // }else{
            //     setIndustryTypes(industryTypes)
            // }
            
            setCompanyDetails({
                company_type: editCompanyDetails? editCompanyDetails?.company_type :'',
                name: editCompanyDetails? editCompanyDetails?.company_name: '',
                industry_type: editCompanyDetails? editCompanyDetails?.industry_type:'',
                website: editCompanyDetails? editCompanyDetails?.company_website : '',
                brand: editCompanyDetails? editCompanyDetails?.company_brand : '',
                // cin: editCompanyDetails? editCompanyDetails?.company_cin : '',
                // tan: editCompanyDetails? editCompanyDetails?.company_tan : '',
                // pan: editCompanyDetails? editCompanyDetails?.company_pan : '',
                turnover: editCompanyDetails? editCompanyDetails?.company_turnover : '',
                summary: editCompanyDetails? editCompanyDetails?.company_summary : ''
            })

            setIdentificationValues({
                cin: editCompanyDetails? editCompanyDetails?.company_cin : '',
                tan: editCompanyDetails? editCompanyDetails?.company_tan : '',
                pan: editCompanyDetails? editCompanyDetails?.company_pan : '',
            })

        } 

    },[editCompanyDetails])
  
    const handleChange = (newValue) => {
        setValue(newValue?.$d);
    };
    
    const handleDate = (newValue) => {
        setTurnoverDate(newValue?.$d);
    }

    /**
    * Validation for forms fields.
    */
    let validation_input = [
        // {tagid: 'name', text:companyDetails.name, regex_name:'text', required: true, errmsg: 'Please Enter Name.'},
        //{tagid: 'companyFullName', text:companyDetails.companyName, regex_name: 'text', required: true, errmsg: 'Please Enter Full Name.', allow_numbers: false, other_chars: true, min:1, max: 20},
        {tagid: 'turnover', text: companyDetails.turnover, required: true, regex_name: 'min_number',errmsg: 'Please Enter Amount', min:1},
        {tagid: 'website', text: companyDetails.website, required: true, regex_name:'website', errmsg: 'Please Enter Correct Website for eg.https://www.xyz.com, www.xyz.com, etc..'},
        // {tagid: 'pan' || 'tan' || 'cin', text: companyDetails?.pan || companyDetails?.tan || companyDetails?.cin, required: true, regex_name:'pan_card'||'cin_card', errmsg:'Please Enter correct number'},
        // {tagid: 'tan', text: companyDetails?.tan, required: true, regex_name:'pan_card', errmsg: 'Please Enter TAN Number'},
        // {tagid: 'cin', text: companyDetails?.cin, required: true, regex_name:'cin_card', errmsg: 'Please Enter CIN Number', max:30, min:5, allow_numbers: true, other_chars: true},
        {tagid: 'industry_type', text:companyDetails?.industry_type, regex_name:'text', other_chars: true, required: true, errmsg: 'Please Select Industry Type.'},
        {tagid: 'summary', text:companyDetails?.summary, required: true, regex_name: 'free_text', errmsg: 'Please add the summary between 100 to 2000 characters', min:100, max:2000},
        // (identificationValues?.pan?.length === 0 && identificationValues?.tan?.length === 0 && identificationValues?.cin?.length === 0 ? {tagid: 'allFields', text: identificationValues?.pan || identificationValues?.tan || identificationValues?.cin, required: true, regex_name:'pan_card'||'cin_card', errmsg: 'Enter Atleast One'} : identificationValues?.pan?.length > 0 ||identificationValues?.tan?.length >0 || identificationValues?.cin?.length >0 ? (identificationValues?.tan?.length >0 ?{tagid: 'tan', text: identificationValues?.tan, required: true, regex_name:'pan_card', errmsg: 'Please Enter minimum 10 digit TAN Number'} :'' || identificationValues?.cin?.length >0 ?{tagid: 'cin', text: identificationValues?.cin, required: true, regex_name:'cin_card', errmsg: 'Please Enter Correct CIN Number', max:30, min:5, allow_numbers: true, other_chars: true}:'' || identificationValues?.pan?.length > 0? {tagid: 'pan', text: identificationValues?.pan, required: true, regex_name:'pan_card', errmsg: 'Please Enter minimum 10 digit PAN Number', max:30, min:5, allow_numbers: true, other_chars: true}:''):'' ),
        (identificationValues?.tan?.length > 0 && companyDetails?.company_type !== 'TRUST') ? {tagid: 'tan', text: identificationValues?.tan, required: true, regex_name:'tan_card', errmsg: 'Please Enter Correct TAN Number'} :{tagid: 'tan', text: identificationValues?.tan, regex_name:'tan_card', errmsg: 'Please Enter Correct TAN Number'} ,
        (identificationValues?.cin?.length > 0 && companyDetails?.company_type !== 'TRUST') ? {tagid: 'cin', text: identificationValues?.cin, required: true, regex_name:'cin_card', errmsg: 'Please Enter Correct CIN Number', max:30, min:5, allow_numbers: true, other_chars: true}: {tagid: 'cin', text: identificationValues?.cin, regex_name:'cin_card', errmsg: 'Please Enter Correct CIN Number', max:30, min:5, allow_numbers: true, other_chars: true},
        (identificationValues?.pan?.length > 0 && companyDetails?.company_type !== 'TRUST') ? {tagid: 'pan', text: identificationValues?.pan, required: true, regex_name:'pan_card', errmsg: 'Please Enter Correct PAN Number', max:30, min:5, allow_numbers: true, other_chars: true}: {tagid: 'pan', text: identificationValues?.pan, regex_name:'pan_card', errmsg: 'Please Enter Correct PAN Number', max:30, min:5, allow_numbers: true, other_chars: true},
        // Object.keys(editCompanyDetails).length > 0 ? (identificationValues?.pan?.length === 0 && identificationValues?.tan?.length === 0 && identificationValues?.cin?.length === 0)? {tagid: 'editCompany', text: identificationValues?.tan,required: true, regex_name:'tan_card', errmsg: 'Enter Atleast One'} :'' :''
    ]

    let validationForCompanyName = [
        {tagid: 'name', text:companyDetails?.name?.trim(), regex_name:'free_text', required: true, errmsg: 'Please Enter Name.'},
        {tagid: 'company_type', text:companyDetails?.company_type, regex_name:'text', required: true, errmsg: 'Please Select Type.'},
    ]

    /**
     * 
     * @param {Object} event - 
     */
    const handleOfCreateCompanyDetails = (event) => {
        setCompanyDetails({...companyDetails, [event.target.name]:event.target.value}) 
    }
    
    const handleChangeIdentificationValues =(event)=>{
        setIdentificationValues({...identificationValues, [event.target.name]:event.target.value?.toUpperCase()?.trim()})
    }

    const handleOfVerifyCompnayName = () => {
        if(validate(validationForCompanyName).length === 0){
            setButtonLoader(true)
            setDisableCompanyName(false)  
            const verifyName = {name:companyDetails.name.trim(), type:companyDetails?.company_type.trim()}
            setDisplayCompanyType(companyDetails?.name?.concat(" ", companyDetails?.company_type))
            dispatch(verifyCompanyName(verifyName)).then((data)=>{
                if(data.payload.length > 0 ){
                    sweetalert('error', 'Company name already exist.')
                    setButtonLoader(false)
                } else{
                    setDisplayForm(true) 
                    setDisableCompanyName(true)  
                    setDisableButtonStatus(true)
                    setError([])
                    setButtonLoader(false)
                }
            })
        }else{
            setError(validate(validationForCompanyName))
        } 
    }

    const checkValidation = ()=>{
        if(identificationValues?.pan?.length === 0 && identificationValues?.tan?.length === 0 && identificationValues?.cin?.length === 0 && companyDetails?.company_type !== 'TRUST'){
            setDisplayErrorMsg(true)
            return false
        }else{
            setDisplayErrorMsg(false)
            return true
        }
    }

    const handleClose = () => {
        setOpenCompanyModel(false)
    }

    const handleCompanyDetails = () => {
        if(validate(validation_input).length === 0 && checkValidation()){
            // setDisableCompanyName(false)  
                companyDetails.doi = value
                companyDetails.turnoverondate = turnoverondate
                companyDetails.pan = identificationValues?.pan 
                companyDetails.tan = identificationValues?.tan 
                companyDetails.cin = identificationValues?.cin
            if(Object.keys(editCompanyDetails).length === 0 ){ 
                setLoader(true)
                // companyDetails.company_fullname= companyDetails?.name?.concat(' ', (companyDetails?.company_type === "Private Limited" || companyDetails?.company_type ==='Limited'|| companyDetails?.company_type ==='LLP' )? companyDetails?.company_type :"")
                dispatch(createCompany(companyDetails)).then((data)=>{
                    if(data?.payload?.errormsg){
                        sweetalert('error', data?.payload?.errormsg)
                        setDisableCompanyName(true) 
                        // setOpenCompanyModel(false)
                        setError([])   
                        setLoader(false)
                        return 
                    }else{
                        if(title === "CreateFromLead" ){
                            sweetalert('success', data?.payload?.data?.msg)
                            setOpenCompanyModel(false)
                            dispatch(storeCompanyData({companyDetails:data?.payload?.data?.company_details, title:route}))
                            dispatch(storeAddressDetails({}))
                            dispatch(displayContactList(data?.payload?.data?.company_details?.id))
                            dispatch(displayCompanyDetails(data?.payload?.data?.company_details?.id))
                            navigate('/dashboard/lead',{ state: {title:route, companyName: data?.payload?.data?.company_details}})
                            setError([]) 
                        }else if(title === "Company List"){
                            sweetalert('success', data?.payload?.data?.msg)
                            setUpdate(!update)
                            setOpenCompanyModel(false)
                        }else{
                        setError([])
                        console.log(data?.payload?.data?.company_details,"company_details") 
                        dispatch(storeCompanyData({companyDetails:data?.payload?.data?.company_details, title:route})) 
                        setCreateCompanyDetails(data?.payload?.data?.company_details)
                        setLoader(false)
                        setDisableButtonStatus(false)
                        swal({
                            buttons: {
                            Cancel: {
                                text: "Company Profile",
                                value: "profile",
                                className: "popup-gray",
                            },
                            Delete: {
                                text: (checkAdminRole && permissionsDetails?.perm_address_create) || permissionsDetails?.perm_address_create ? "Add Address" :'Company List',
                                value: "address",
                                className: "popup-blue",
                            },
                            },
                            title: "Company Created Successfully. ",
                            className: "custom-modal",
                            closeOnClickOutside: false,
                        }).then((value)=>{
                            if(value === 'profile'){
                                navigate('/dashboard/profile',{state: data?.payload?.data?.company_details?.id})
                                setDisableCompanyName(true)
                                setOpenCompanyModel(false)
                            }else{
                                if((checkAdminRole && permissionsDetails?.perm_address_create) || permissionsDetails?.perm_address_create){
                                    setOpenAddressModel(true)
                                    setDisableCompanyName(true)
                                    setOpenCompanyModel(false)
                                }else{
                                    navigate('/dashboard/companylist')
                                }
                            }
                        })
                    }}
                }) 
                dispatch(getAllCompanyName())
            }else{  
                setLoader(true)
             companyDetails.company_id = editCompanyDetails?.id
        //   companyDetails.company_fullname= companyDetails?.name?.concat(' ', (companyDetails?.company_type === "PRIVATE LIMITED" || companyDetails?.company_type ==='LIMITED'|| companyDetails?.company_type ==='LLP' )? companyDetails?.company_type :"")
                dispatch(editCompany(companyDetails)).then((res)=>{
                    if (res.payload?.errormsg) {
                        sweetalert('error', res?.payload?.errormsg)
                        setDisableCompanyName(true)  
                        setError([])
                        setLoader(false)
                    return
                    }else{
                    if(state?.operation === 'companyProfile'){   
                        navigate('/dashboard/profile',{state: editCompanyDetails?.id})
                        sweetalert('success', res?.payload?.data)
                        // setDisableCompanyName(true)  
                        setLoader(false)
                        setError([]) 
                    }else{
                        // navigate('/dashboard/companylist')
                        setUpdate(!update)
                        sweetalert('success', res?.payload.data)
                        setOpenCompanyModel(false)
                        // setDisableCompanyName(true) 
                        setError([])  
                    }
                }
                })
                }
        }else{
            setError(validate(validation_input))
            checkValidation()
        } 
    }

    const sweetalert = (variant, text) => {
            switch(variant) {
            case 'error':
                variant ='error'
                break;
            case 'success':
                variant = 'success'
                break;
            case 'warning':
                variant = 'warning'
                break
            }
        swal({
            icon: variant==='error' ? 'error' :'success',
            title: text , 
            button: "Ok!",
        }); 
    };

    return (
        <>
        <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openCompanyModel}
        fullWidth
        maxWidth="lg"
      >
        {/* Display Company name */}
        {title === "CreateFromDashboard" || title === "CreateFromLead" || (title === "Company List" && Object.keys(editCompanyDetails).length === 0) ? <></>: <DialogHeaderCompanyName label={`${editCompanyDetails?.company_name} ${editCompanyDetails?.company_type}`} onClose={handleClose} />}
        <BootstrapDialogTitle sx={{display:'flex', pt:title === "CreateFromLead" || (title === "Company List" && Object.keys(editCompanyDetails).length > 0) ? 0 : 1, pb:0.5, pl:2, pr:2, justifyContent:'start', borderRadius:'10px', fontWeight:600, color:'#333333', fontSize:'24px', letterSpacing: "0.04em"}} id="customized-dialog-title" title={title} editCompany= {editCompanyDetails} onClose={handleClose}>{Object.keys(editCompanyDetails)?.length === 0 ? "Create Company" : "Edit Company"} </BootstrapDialogTitle> 

        <DialogContent dividers> 
            <Box style={{display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12} lg={2} >
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Company</H5FormLabel>  
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={2}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Type<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                        <SelectDropDown 
                            id='company type'
                            name='company_type'
                            options={organizationTypes} 
                            onchange={!disableCompanyName ? handleOfCreateCompanyDetails :""}
                            placeholder='Select type'
                            value={companyDetails?.company_type}
                            disabled={disableCompanyName ? disableCompanyName : ''}
                            sx={{p:0.1, mt:1, pl:1,backgroundColor:disableCompanyName? '#E9E9FF' : '#ffffff',borderRadius:"10px"}}
                            MenuProps={MenuProps} 
                        /> 
                        <DisplayError error={error} fortagid='company_type' /> 
                    </Grid>

                    <Grid item xs={12} md={4} sm={4} lg={3}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Name<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                        <InputBox id='company name' name='name' disable ={disableCompanyName ? disableCompanyName : ''} onchange={!disableCompanyName ? handleOfCreateCompanyDetails :''} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background: disableCompanyName ?'#E9E9FF' :'#FFFFFF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} 
                            placeholder ="Mobigic Technologies"
                            value={companyDetails?.name?.replace(/\s\s+/g, " ")} /> 
                        <DisplayError error={error} fortagid='name'/> 
                    </Grid>

                    {Object.keys(editCompanyDetails).length === 0 ? <Grid item xs={12} md={4} sm={4} lg={1}>
                        {/* <CustemBtn id='validate' variant='contained' disabled={disableCompanyName ? disableCompanyName : '' || buttonLoader} onClick={handleOfVerifyCompnayName} sx={{color:'#FFFFFF', backgroundColor:"#049E01", "&:hover": {backgroundColor:"#049E01", color:'#FFFFFF' }, fontSize:'15px',fontWeight:'500', borderRadius:'8px', mt:3}} text="Validate"/>              */}
                        <Button
                            id="validate"
                            variant="contained"
                            disabled={disableCompanyName || buttonLoader}
                            onClick={handleOfVerifyCompnayName}
                            sx={{
                                color: '#FFFFFF',
                                backgroundColor: '#049E01',
                                '&:hover': { backgroundColor: '#049E01', color: '#FFFFFF' },
                                fontSize: '14px', 
                                fontWeight: '500',
                                borderRadius: '8px',
                                mt: 3,
                                textTransform: 'none', 
                                padding: '8px 16px', 
                            }}
                            >
                            {buttonLoader ? <CircularProgress size={20} color="inherit" /> : 'Validate'}
                        </Button>
                    </Grid> :  <></>}

                    <Grid item xs={12} md={6} sm={6} lg={4}> 
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Full Name</H5FormLabel>
                        <InputBox id='companyFullName' name='companyFullName' placeholder= "Mobigic Technologies Private Limited" disable ={disableCompanyName ? disableCompanyName : ''} sx={{ '&::placeholder': {
                        color: '#444444'}, border:"1px solid #B0AEFF", background:'#E9E9FF', fontSize:'14px', fontWeight: '600', width:'100%', color:'#444444', borderRadius:'8px', p:0.5, mt:1}}
                        readOnly={true} value={Object.keys(editCompanyDetails).length > 0 ? `${companyDetails?.name} ${companyDetails?.company_type}`: Object.keys(companyDetails).length > 0 ? companyDetails?.name?.concat(' ', (companyDetails?.company_type === "PRIVATE LIMITED" || companyDetails?.company_type ==='LIMITED'|| companyDetails?.company_type ==='LLP' ) ? companyDetails?.company_type :"" ) : "Mobigic Technologies Private Limited"} /> 
                    </Grid>     
                </Grid>
            </Box>
            {displayForm || Object.keys(editCompanyDetails).length > 0 ? <>  
                <Box style={{marginTop:'20px'}}>
                <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                        <Grid item xs={12} md={0} sm={0} lg={2}>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} lg={2}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Industry Type<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                        <SelectDropDown
                            id='industry type' 
                            name='industry_type'
                            options={industryTypes?.map((type)=>{return {label:type, value:type}})} 
                            value={companyDetails?.industry_type}
                            onchange={handleOfCreateCompanyDetails}
                            placeholder='Select industry'
                            sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px",}}
                            MenuProps={MenuProps}
                        />
                        <DisplayError error={error} fortagid='industry_type' /> 
                    </Grid>

                        <Grid item xs={12} md={6} sm={6} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}> Website<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <InputBox  id='website' name='website' onchange={handleOfCreateCompanyDetails} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#FFFFFF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} 
                                placeholder ="http://abcd.com"
                                value={companyDetails?.website?.toLowerCase()?.trim()}
                            />
                            <DisplayError error={error} fortagid='website' /> 
                        </Grid>

                        <Grid item xs={12} md={6} sm={6}l lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Date of Incorporation<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <DatePickerOpenTo
                                id = 'value'
                                name ='value'
                                openTo="year"
                                views={['year', 'month', 'day']}
                                value={value}
                                onChange={handleChange} 
                                maxDate={new Date()}
                                style={{minWidth:'100%',background:'#FFFFFF',fontSize:'14px',marginTop:'8px'}}
                            />
                        </Grid>

                    <Grid item xs={12} md={4} sm={4} lg={2}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Brand</H5FormLabel>
                        <InputBox id='brand' name='brand' onchange={handleOfCreateCompanyDetails} sx={{border:"1px solid #B0AEFF", background:'#FFFFFF', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.5, mt:1, pl:1}} 
                            placeholder ="Puma"
                            value={companyDetails?.brand?.replace(/\s\s+/g, " ")}/>
                        </Grid>
                    </Grid>
                </Box>
                </> :" "}
                

            {displayForm || Object.keys(editCompanyDetails).length > 0 ? <>
    {/*       Identification       */} 
                <Box style={{marginTop:'20px'}}>
                    <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                        <Grid item xs={12} md={12} sm={12} lg={2}>
                        <H5FormLabel sx={{ color: themeColor.palette.InputLabel }}>
                            Identification {typeof companyDetails?.company_type === 'string' && companyDetails?.company_type !== 'TRUST' ? <span style={{ color: '#FF0000' }}>*</span> : ''}
                        </H5FormLabel>
  
                            {displayErrorMsg ? <Typography style={{color:'red', fontSize:'13px'}}> Enter Atleast One </Typography> :''}
                            {/* <DisplayError error={error} fortagid='allFields' /> */}
                        </Grid>

                        <Grid item xs={12} md={6} sm={6} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>CIN</H5FormLabel>
                            <InputBox 
                                id ='cin'
                                name='cin'   
                                onchange={(e)=>{
                                    if(e.target.value.length <= 21)handleChangeIdentificationValues(e)
                                    }}  
                                sx={{border:"1px solid #B0AEFF", background:'#FFFFFF', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.5, mt:1, pl:1, mr:6}} 
                                placeholder ="U21091KA2019OPC141331"
                                value={identificationValues?.cin?.trim()}
                                />
                            <DisplayError error={error} fortagid='cin' /> 
                    </Grid>

                        <Grid item xs={12} md={4} sm={4} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>TAN</H5FormLabel>
                            <InputBox
                                id ='tan'
                                name='tan' 
                                onchange={(e)=>{
                                    if(e.target.value.length <=10)handleChangeIdentificationValues(e)
                                }}  
                                sx={{border:"1px solid #B0AEFF", background:'#FFFFFF', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.5, mt:1, pl:1}} 
                                placeholder ="ABCD12345X"
                                value={identificationValues?.tan?.trim()}
                            />
                            <DisplayError error={error} fortagid='tan' />
                    </Grid>
                
                        <Grid item xs={12} md={4} sm={4} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>PAN</H5FormLabel>
                            <InputBox
                                id ='pan'
                                name='pan' 
                                onchange={(e)=>{
                                    if(e.target.value.length <=10)handleChangeIdentificationValues(e)
                                }} 
                                sx={{border:"1px solid #B0AEFF", background:'#FFFFFF', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.5, mt:1, pl:1}} 
                                placeholder ="AAAAA4567A"
                                value={identificationValues?.pan?.trim()}
                            />
                            <DisplayError error={error} fortagid='pan' />
                    </Grid>
                </Grid>
                </Box>

    {/*     Turnover     */}
                <Box style={{marginTop:'20px'}}>
                <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                        <Grid item xs={12} md={12} sm={12} lg={2}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Turnover</H5FormLabel>  
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Amount (In Cr.)<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <InputBox id ='turnover' name='turnover' value={companyDetails?.turnover} onchange={handleOfCreateCompanyDetails} sx={{border:"1px solid #B0AEFF", background:'#FFFFFF', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.5, mt:1, pl:1}} placeholder ="10000"/>
                            <DisplayError error={error} fortagid='turnover' />
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>As on Date<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <DatePickerOpenTo
                                id ='as on date'
                                name ='value'
                                openTo="year"
                                views={['year', 'month', 'day']}
                                value={turnoverondate}
                                onChange={handleDate} 
                                maxDate={new Date()}
                                style={{minWidth:'100%',background:'#FFFFFF',fontSize:'14px',marginTop:'8px'}}
                            />
                        </Grid>
                    </Grid>
                </Box>

    {/*      summary    */}
                <Box sx={{marginTop:'20px'}}>
                    <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                        <Grid item xs={12} md={12} sm={12} lg={2}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Summary<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        </Grid>

                        <Grid item xs={12} md={12} sm={12} lg={10} sx={{paddingRight:'20px'}}>
                            <TextArea id= 'summary' name='summary' value={companyDetails?.summary?.replace(/\s\s+/g, " ")} onChange={handleOfCreateCompanyDetails} style={{width:'100%', fontFamily: 'Montserrat', background:'#FFFFFF', border:"1px solid #B0AEFF", height:'80px',borderRadius:'8px',fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding:'8px'}} placeholder ="Write a note about a Company."></TextArea>  
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <DisplayError error={error} fortagid='summary' />
                                <div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                    <span>{companyDetails?.summary?.length}/2000</span>
                                </div>
                            </div> 
                    </Grid>   
                    </Grid>
                </Box> 
            </> :''}
             {/* </CardBody>  */}
        </DialogContent>

        {!disableButtonStatus ? <></> : <><DialogActions>
            <Button
                id="save"
                variant="contained"
                disabled={loader}
                onClick={handleCompanyDetails}
                sx={{
                    color: '#FFFFFF',
                    backgroundColor: '#3107AA',
                    '&:hover': { backgroundColor: '#3107AA', color: '#FFFFFF' },
                    fontSize: '16px', 
                    fontWeight: '500',
                    borderRadius:"10px", pl:5, pr:5,
                    textTransform: 'none', 
                    padding: '4px 25px', 
                    boxShadow:'0px 4px 6px #e1e1e1'
                }}
                >
                {loader ? <CircularProgress size={20} color="inherit" /> : 'Save'}
            </Button>
        </DialogActions></>}

        </BootstrapDialog>

        {openAddressModel && <CreateAddressDropdown open={openAddressModel} setOpen={setOpenAddressModel} setEditCompanyDetails={setEditAddressDetails} editCompanyDetails={editAddressDetails}
            setOpenCompanyModel={setOpenCompanyModel} openCompanyModel={openCompanyModel} setAddress={setAddress} address={address} />} 
       </>
    
    )
}

export default CreateCompanyForm