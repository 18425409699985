
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../components/custom/CloseIcon';
import Typography from '@mui/material/Typography';
import {Box, CircularProgress, Grid} from "@mui/material"
import { ComapanyName, DropDown, InputBox } from '../../components/custom';
import CustemBtn from '../../components/custom/CustemBtn'
import SwitchBtn from '../../components/custom/Switch';
import TextArea from '../../components/custom/TextArea';
import validate from '../../validation/validation';
import { useDispatch, useSelector } from 'react-redux';
import { createRole, getUserRole } from '../../Redux/Thunk/createrolethunk';
import swal from 'sweetalert';
import DisplayError from '../../validation/DisplayError';
import {H1FormTitle, H3SubHeader, H5FormLabel} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
         <CloseIcon w={35} h={35}/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CreateRole({open, setOpen, handleModalOpen}) {
 const [ role, setRole ] = useState('')
 const [error, setError] = useState([])
 const dispatch = useDispatch()
 const { fetchuserrole } = useSelector((state) => state.role)
 const [ selectedRole, setSelectedRole ] = useState('')
 const [ update, setUpdate ] = useState(false)
 const [buttonStatus, setButtonStatus] = useState(false)
 const [loader, setLoader] = useState(false)

  const [permissions, setPermissions ] = useState({
    perm_user_view: false,
    perm_user_create: false,
    perm_user_edit: false,
    perm_user_delete: false,
    perm_company_view: false,
    perm_company_create: false,
    perm_company_edit: false,
    perm_company_delete: false,
    perm_branch_view: false,
    perm_branch_create: false,
    perm_branch_edit: false,
    perm_branch_delete: false,
    perm_contact_view: false,
    perm_contact_create: false,
    perm_contact_edit: false,
    perm_contact_delete: false,
    perm_lead_view: false,
    perm_lead_create: false,
    perm_lead_edit: false,
    perm_lead_delete: false,
    perm_opportunity_view: false,
    perm_opportunity_create: false,
    perm_opportunity_edit: false,
    perm_opportunity_delete: false,
    perm_address_view: false,
    perm_address_create: false,
    perm_address_edit: false,
    perm_task_view: false,
    perm_task_create: false,
    perm_task_edit: false,
    // perm_task_delete: false,
    perm_followup_view: false,
    perm_followup_create: false,
    perm_followup_edit: false,
    // perm_followup_delete: false,
    // perm_validate_view: false,
    // perm_validate_create: false,
    // perm_validate_edit: false,
    // perm_validate_delete: false,
    perm_settings_view: false,
    perm_settings_create:false,
    perm_settings_edit: false,
    perm_settings_delete: false,
    // perm_assign_view:false,
    perm_assign_edit:false,
    //  perm_approve_view:false,
    perm_approve_edit:false,
    // perm_validate_view:false,
    perm_validate_edit:false,
    perm_performance_view:false,
    perm_taskAssign_edit:false,
    perm_service_create:false,
    perm_cof_create:false,
    perm_service_view:false,
    perm_cof_view:false,
    // perm_dashboard_view:false
   })
   
  useEffect(() => {
    dispatch(getUserRole())
  },[update])
   
  const handleAccess =(e) =>{
    e.preventDefault();
    console.log(e.target.value, e.target.name, permissions?.[e.target.name] === false, "dkkkkkkkkkkkk")
    if(role.trim()?.length > 0 || selectedRole?.label?.length > 0 ) {
      const targetName = e.target.name; 
        if (targetName) {
          setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [targetName]: !prevPermissions[targetName],
          }))}

          // if(permissions?.[e.target.name] === false ){ 
          //   setPermissions({...permissions, [e.target.name] : true})
          // }else{
          //   setPermissions({...permissions, [e.target.name] : false})
          // } 
      } else {
        sweetalert('warning', 'Please add Role Name or Select Role')
      }
  }

  console.log(permissions,"permuuuuuuuuuuu")
  let validation_input = [
    selectedRole != undefined ? "" :{tagid: 'role', text:role?.trim(), regex_name:'text', required: true, errmsg: 'Please Enter Correct Role Name'},
    role?.length === 0 ? {tagid: 'selected_role', text:selectedRole?.label, regex_name:'free_text', required: true, errmsg: 'Please Select Role', min:1}:"",
  ]

  const changeRole =(e)=>{
    // alert(e.target.value)
      if(e.target.value?.length === 0){
        setRole('')
        setError([])
        setPermissions({
          perm_user_view: false,
          perm_user_create: false,
          perm_user_edit: false,
          perm_user_delete: false,
          perm_company_view: false,
          perm_company_create: false,
          perm_company_edit: false,
          perm_company_delete: false,
          perm_branch_view: false,
          perm_branch_create: false,
          perm_branch_edit: false,
          perm_branch_delete: false,
          perm_contact_view: false,
          perm_contact_create: false,
          perm_contact_edit: false,
          perm_contact_delete: false,
          perm_lead_view: false,
          perm_lead_create: false,
          perm_lead_edit: false,
          perm_lead_delete: false,
          perm_opportunity_view: false,
          perm_opportunity_create: false,
          perm_opportunity_edit: false,
          perm_opportunity_delete: false,
          perm_address_view: false,
          perm_address_create: false,
          perm_address_edit: false,
          perm_task_view: false,
          perm_task_create: false,
          perm_task_edit: false,
          // perm_task_delete: false,
          perm_followup_view: false,
          perm_followup_create: false,
          perm_followup_edit: false,
          // perm_followup_delete: false,
          // perm_validate_view: false,
          // perm_validate_create: false,
          perm_assign_edit:false,
          // perm_approve_view:false,
          perm_approve_edit:false,
          // perm_validate_view:false,
          perm_validate_edit:false,
          // perm_validate_delete: false,
          perm_settings_view: false,
          perm_settings_create: false,
          perm_settings_edit: false,
          perm_settings_delete: false,
          perm_performance_view:false,
          perm_taskAssign_edit:false,
          perm_service_create:false,
          perm_cof_create:false,
          perm_service_view:false,
          perm_cof_view:false,
        })
        return setButtonStatus(false)
      }
     if(!e.target.value.startsWith(' ')){
        setButtonStatus(true)
        handleExistingRole(null)
        setRole(e.target.value)
        setPermissions({
        perm_user_view: false,
        perm_user_create: false,
        perm_user_edit: false,
        perm_user_delete: false,
        perm_company_view: false,
        perm_company_create: false,
        perm_company_edit: false,
        perm_company_delete: false,
        perm_branch_view: false,
        perm_branch_create: false,
        perm_branch_edit: false,
        perm_branch_delete: false,
        perm_contact_view: false,
        perm_contact_create: false,
        perm_contact_edit: false,
        perm_contact_delete: false,
        perm_lead_view: false,
        perm_lead_create: false,
        perm_lead_edit: false,
        perm_lead_delete: false,
        perm_opportunity_view: false,
        perm_opportunity_create: false,
        perm_opportunity_edit: false,
        perm_opportunity_delete: false,
        perm_address_view: false,
        perm_address_create: false,
        perm_address_edit: false,
        perm_task_view: false,
        perm_task_create: false,
        perm_task_edit: false,
        // perm_task_delete: false,
        perm_followup_view: false,
        perm_followup_create: false,
        perm_followup_edit: false,
        // perm_followup_delete: false,
        // perm_validate_view: false,
        // perm_validate_create: false,
        perm_assign_edit:false,
        // perm_approve_view:false,
        perm_approve_edit:false,
        // perm_validate_view:false,
        perm_validate_edit:false,
        // perm_validate_delete: false,
        perm_settings_view: false,
        perm_settings_create: false,
        perm_settings_edit: false,
        perm_settings_delete: false,
        perm_performance_view:false,
        perm_taskAssign_edit:false,
        perm_service_create:false,
        perm_cof_create:false,
        perm_service_view:false,
        perm_cof_view:false,
        })
     } 
     // setSelectedRole(undefined)
  }

  const handleClose = () => {
    setOpen(false);
    setButtonStatus(false)
    setRole('')
    setSelectedRole('')
    setError([])
    setPermissions({
      perm_user_view: false,
      perm_user_create: false,
      perm_user_edit: false,
      perm_user_delete: false,
      perm_company_view: false,
      perm_company_create: false,
      perm_company_edit: false,
      perm_company_delete: false,
      perm_branch_view: false,
      perm_branch_create: false,
      perm_branch_edit: false,
      perm_branch_delete: false,
      perm_contact_view: false,
      perm_contact_create: false,
      perm_contact_edit: false,
      perm_contact_delete: false,
      perm_lead_view: false,
      perm_lead_create: false,
      perm_lead_edit: false,
      perm_lead_delete: false,
      perm_opportunity_view: false,
      perm_opportunity_create: false,
      perm_opportunity_edit: false,
      perm_opportunity_delete: false,
      perm_address_view: false,
      perm_address_create: false,
      perm_address_edit: false,
      perm_task_view: false,
      perm_task_create: false,
      perm_task_edit: false,
      // perm_task_delete: false,
      perm_followup_view: false,
      perm_followup_create: false,
      perm_followup_edit: false,
      // perm_followup_delete: false,
      // perm_validate_view: false,
      // perm_validate_create: false,
      // perm_validate_edit: false,
      // perm_validate_delete: false,
      perm_settings_view: false,
      // perm_settings_create: false,
      perm_settings_create: false,
      perm_settings_edit: false,
      perm_settings_delete: false,
      // perm_assign_view:false,
      perm_assign_edit:false,
      // perm_approve_view:false,
      perm_approve_edit:false,
      // perm_validate_view:false,
      perm_validate_edit:false,
      perm_performance_view:false,
      perm_taskAssign_edit:false,
      perm_service_create:false,
      perm_cof_create:false,
      perm_service_view:false,
      perm_cof_view:false,
    })
  };
  
console.log(" perm_company_view", permissions?.perm_company_view)

const handleExistingRole = (e,value,reason) => {   
     if (value === null){
      setPermissions({
        perm_user_view: false,
        perm_user_create: false,
        perm_user_edit: false,
        perm_user_delete: false,
        perm_company_view: false,
        perm_company_create: false,
        perm_company_edit: false,
        perm_company_delete: false,
        perm_branch_view: false,
        perm_branch_create: false,
        perm_branch_edit: false,
        perm_branch_delete: false,
        perm_contact_view: false,
        perm_contact_create: false,
        perm_contact_edit: false,
        perm_contact_delete: false,
        perm_lead_view: false,
        perm_lead_create: false,
        perm_lead_edit: false,
        perm_lead_delete: false,
        perm_opportunity_view: false,
        perm_opportunity_create: false,
        perm_opportunity_edit: false,
        perm_opportunity_delete: false,
        perm_address_view: false,
        perm_address_create: false,
        perm_address_edit: false,
        perm_task_view: false,
        perm_task_create: false,
        perm_task_edit: false,
        perm_followup_view: false,
        perm_followup_create: false,
        perm_followup_edit: false,
        perm_assign_edit:false,
        perm_approve_edit:false,
        perm_validate_edit:false,
        perm_settings_view: false,
        perm_settings_create: false,
        perm_settings_edit: false,
        perm_settings_delete: false,
        perm_performance_view:false,
        perm_taskAssign_edit:false,
        perm_service_create:false,
        perm_cof_create:false,
        perm_service_view:false,
        perm_cof_view:false,
        // perm_dashboard_view:false
      })
      setButtonStatus(false)
      setSelectedRole('')
     }else{
      setSelectedRole(value)
      setRole('')
      setError([])
      setButtonStatus(true)
      const role_data = fetchuserrole?.data?.filter((role)=>role?.id == value?.value)
      setPermissions(role_data[0]?.permissions)
    }
}

const emptyState = ()=>{
  setButtonStatus(false)
  setRole('')
  setSelectedRole('')
  setPermissions({
      perm_user_view: false,
      perm_user_create: false,
      perm_user_edit: false,
      perm_user_delete: false,
      perm_company_view: false,
      perm_company_create: false,
      perm_company_edit: false,
      perm_company_delete: false,
      perm_branch_view: false,
      perm_branch_create: false,
      perm_branch_edit: false,
      perm_branch_delete: false,
      perm_contact_view: false,
      perm_contact_create: false,
      perm_contact_edit: false,
      perm_contact_delete: false,
      perm_lead_view: false,
      perm_lead_create: false,
      perm_lead_edit: false,
      perm_lead_delete: false,
      perm_opportunity_view: false,
      perm_opportunity_create: false,
      perm_opportunity_edit: false,
      perm_opportunity_delete: false,
      perm_address_view: false,
      perm_address_create: false,
      perm_address_edit: false,
      perm_task_view: false,
      perm_task_create: false,
      perm_task_edit: false,
      // perm_task_delete: false,
      perm_followup_view: false,
      perm_followup_create: false,
      perm_followup_edit: false,
      // perm_followup_delete: false,
      // perm_validate_view: false,
      // perm_validate_create: false,
      perm_assign_edit:false,
      // perm_approve_view:false,
      perm_approve_edit:false,
      // perm_validate_view:false,
      perm_validate_edit:false,
      // perm_validate_delete: false,
      perm_settings_view: false,
      perm_settings_create: false,
      perm_settings_edit: false,
      perm_settings_delete: false,
      perm_performance_view:false,
      perm_taskAssign_edit:false,
      perm_service_create:false,
      perm_cof_create:false,
      perm_service_view:false,
      perm_cof_view:false,
      // perm_dashboard_view:false
  })
}

const handleSaveRole = () => {
  if (validate(validation_input).length == 0){   
    // Create new role   (selectedRole != undefined || role.length > 0 ) &&
    if(role?.length > 0 ){
      if(role.toLocaleLowerCase().trim() === 'god' ) {
        return sweetalert('error', "Sorry, You can't create God role")
      } 
      setButtonStatus(false)
      setLoader(true)
      dispatch(createRole({permissions:permissions, role:role.toLocaleLowerCase()})).then((res)=>{
        if(res?.payload?.errormsg){
          sweetalert("error", res?.payload?.errormsg)
          setLoader(false)
        }else{
          sweetalert('success', res?.payload?.data)
          setUpdate(!update)                                                                                                                                                                                                                                                                                                                                                                                                                      
          setOpen(false)
          setLoader(false)
          setButtonStatus(true)
          emptyState()
        }
     })
    }else{
      setButtonStatus(false)
      // Update role permission
      swal({
        buttons: {
        Cancel: {
            text: "No",
            value: "Cancel",
            className: "popup-blue",
        },
        Delete: {
            text: "Yes",
            value: "yes",
            className: "popup-gray",
        },
        },
        title: "Do you want to change the permissions for selected role ?",
        className: "custom-modal",
        closeOnClickOutside: false,
      }).then((value)=>{
        if(value=='yes'){
          setError([])
          setButtonStatus(false)
          setLoader(true)
          let access = role ? role : selectedRole.label
          dispatch(createRole({permissions:permissions, role:selectedRole.label})).then((res)=>{
            if(res?.payload?.errormsg){
              sweetalert("error", res?.payload?.errormsg)
              setLoader(false)
            }else{
              sweetalert('success', res?.payload?.data)
              setUpdate(!update)  
              setLoader(false)                                                                                                                                                                                                                                                                                                                                                                                                                    
              setOpen(false)
              setButtonStatus(true)
              emptyState()
            }
          })
        }else{
          setButtonStatus(true)
          // setUpdate(!update)                                                                                                                                                                                                                                                                                                                                                                                                                      
          // setOpen(false)
        }
    })}
       
  }else{
    //  if (selectedRole == undefined && role.length==0){
      setError(validate(validation_input))
    //  }
  }
}

const sweetalert = (variant, text) => {
  if(variant === 'error') {
    variant = 'error'
  } else if (variant == 'success') {
    variant = 'success'
  } else if (variant == 'warning') {
    variant = 'warning'
  }
  swal({
    icon: variant,
    title: text , 
    button: "Ok!",
  }); 
};

  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        maxWidth="lg"
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={open}
        // fullWidth
        // maxWidth="xl"
     
      >    
        <BootstrapDialogTitle  id="customized-dialog-title" onClose={handleClose}>
          <H1FormTitle sx={{color: themeColor.palette.Primarytext1}}>Create/Edit Role</H1FormTitle>
          <Box style={{marginTop:'15px', display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                {/* <Grid sx={{flex:0.5,display:{xs:'none',md:'flex',lg:"flex"}}} >
                    <Typography>Role </Typography>  
                </Grid> */}
                <Grid container sx={{display:'flex', flexDirection:'row', justifyContent:'start', alignItems:'center'}} spacing={{lg:5, xl:5, md:5, sm:2, xs:2}} >
                        <Grid item xs={12} md={6} xl={4} sm={6} lg={4}>
                          <H5FormLabel sx={{color: themeColor.palette.InputLabel}}> Role Create</H5FormLabel>
                              <InputBox 
                                onchange={changeRole}
                                name='role'
                                value={role?.replace(/\s\s+/g, " ")}
                                sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="Role Name"
                              />
                            <DisplayError error={error} fortagid='role'/>
                        </Grid>
                        <Grid item >
                          <Typography sx={{visibility:'hidden'}}>v</Typography>
                          <Box sx={{borderRadius:'50%', background:'#CBCAFF', width:'50px', height:'50px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <Typography>OR</Typography>
                          </Box>
                        </Grid>
                        
                        <Grid item xs={12} md={6} sm={6} xl={4} lg={4}>
                          <H5FormLabel sx={{color: themeColor.palette.InputLabel}}> Edit Role</H5FormLabel>  
                            <DropDown sx={{background:'#FFFFFF', mt:1, borderRadius:"10px"}} 
                                placeholder = 'Select Existing Role'
                                options = {fetchuserrole?.data.filter((rem_god) => rem_god.role.toLocaleLowerCase() !== 'god')?.map((role) => { return {value:role?.id ? role?.id:'', label:role?.role}})}
                                onChange = {handleExistingRole}
                                value = {selectedRole}
                                getOptionLabel={(option) =>(option.label ? option.label :"")}
                                Default ='Existing Role'
                            />
                             <DisplayError error={error} fortagid='selected_role'/>
                        </Grid>
                </Grid>
            </Box>
            <H3SubHeader sx={{color: themeColor.palette.Primarytext1, mt:1}}>Permissions</H3SubHeader>
            <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
               
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2}  flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600}}>Features</Typography>  
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600}} >View</Typography>  
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600}} >Create</Typography>  
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600}} >Edit</Typography>  
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600}} >Notes</Typography>  
                       </Grid>
               </Grid>
           </Box>
        </BootstrapDialogTitle>

        <DialogContent dividers> 
            <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>      
                <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                        <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                            <CustemBtn  
                              sx={{borderRadius:"10px",
                              fontSize:{lg:'16px',xs:'20px'}, 
                              fontWeight:{lg:500,sm:500,xs:600,md:500} ,
                              boxShadow:'none', color:'#444444',
                              borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                              borderRadius:'0px', backgroundColor:'none',
                              "&:hover": {backgroundColor:'none'}
                            }} text="User" />
                        </Grid>
                        <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                        
                            <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_user_view'
                              value = {permissions?.perm_user_view}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_user_view?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_user_view?'#3107AA':'#888888'
                              },
                              }} 
                              text = {permissions?.perm_user_view?'Allow':'Denied'}  
                            />
                        </Grid>
                        <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                            <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_user_create'
                              value = {permissions?.perm_user_create}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_user_create?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_user_create?'#3107AA':'#888888'
                              },
                              }} 
                              text = {permissions?.perm_user_create?'Allow':'Denied'} 
                            />
                        </Grid>
                        <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           
                            <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_user_edit'
                              value = {permissions?.perm_user_edit}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_user_edit?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_user_edit?'#3107AA':'#888888'
                              },
                              }} 
                              text = {permissions?.perm_user_edit?'Allow':'Denied'} 
                            />
                        </Grid>
                        <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can view, edit and create user.</small>  
                       </Grid>
                </Grid>
            </Box>

            <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>    
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                            sx={{borderRadius:"10px",  
                            borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                            borderRadius:'0px',fontSize:'16px',
                            boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                            fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                            "&:hover": {backgroundColor:'none'},
                            color:'#444444', backgroundColor:'none'}} 
                            text="Opportunity" />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_opportunity_view'
                              value = {permissions?.perm_opportunity_view}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_opportunity_view?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_opportunity_view?'#3107AA':'#888888'
                              },
                              }} 
                              text = {permissions?.perm_opportunity_view?'Allow':'Denied'} 
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Create</Typography>  
                           <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_opportunity_create'
                              value = {permissions?.perm_opportunity_create}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_opportunity_create?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_opportunity_create?'#3107AA':'#888888'
                              },
                              }} 
                              text = {permissions?.perm_opportunity_create?'Allow':'Denied'} 
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_opportunity_edit'
                              value = {permissions?.perm_opportunity_edit}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_opportunity_edit?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_opportunity_edit?'#3107AA':'#888888'
                            },
                              }} 
                              text = {permissions?.perm_opportunity_edit?'Allow':'Denied'} 
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can view, edit and create opportunity.</small>  
                       </Grid>
               </Grid>
           </Box>

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
               
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                            sx={{borderRadius:"10px", 
                            borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                            fontSize:'16px',boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                            fontWeight:{lg:500,sm:500,md:500, xs:600}, color:'#444444', 
                            backgroundColor:'none',
                            "&:hover": {backgroundColor:'none'},
                            borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                            borderRadius:'0px',
                            }} text="Company" />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_company_view'
                              value = {permissions?.perm_company_view}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_company_view ?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_company_view ?'#3107AA':'#888888'
                            },
                              }} 
                              text = {permissions?.perm_company_view?'Allow':'Denied'} 
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Create</Typography>  
                           <CustemBtn variant="contained" 
                           onClick={handleAccess}
                           name = 'perm_company_create'
                           value = {permissions?.perm_company_create}
                           sx={{borderRadius:"20px",
                           fontSize:'14px',
                           backgroundColor:permissions?.perm_company_create?'#3107AA':'#888888',
                           pl:2,
                           "&.MuiButtonBase-root:hover": {
                            bgcolor: permissions?.perm_company_create?'#3107AA':'#888888'
                          },
                           }} 
                           text = {permissions?.perm_company_create?'Allow':'Denied'}  
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                           onClick={handleAccess}
                           name = 'perm_company_edit'
                           value = {permissions?.perm_company_edit}
                           sx={{borderRadius:"20px",
                           fontSize:'14px',
                           backgroundColor:permissions?.perm_company_edit?'#3107AA':'#888888',
                           pl:2,
                           "&.MuiButtonBase-root:hover": {
                            bgcolor: permissions?.perm_company_edit?'#3107AA':'#888888'
                          },
                           }} 
                           text = {permissions?.perm_company_edit?'Allow':'Denied'}  
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can view, edit and create company.</small>  
                       </Grid>
               </Grid>
           </Box>

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>  
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                            sx={{borderRadius:"10px", 
                            borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                            fontSize:'16px',boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                            fontWeight:{lg:500,sm:500,md:500, xs:600}, color:'#444444', 
                            backgroundColor:'none',
                            borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                            borderRadius:'0px',
                            "&:hover": {backgroundColor:'none'}
                           }} text="Lead" />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                               onClick={handleAccess}
                               name = 'perm_lead_view'
                               value = {permissions?.perm_lead_view}
                               sx={{borderRadius:"20px",
                               fontSize:'14px',
                               backgroundColor:permissions?.perm_lead_view ?'#3107AA':'#888888',
                               pl:2,
                               "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_lead_view ?'#3107AA':'#888888'
                              },
                               }} 
                               text = {permissions?.perm_lead_view ?'Allow':'Denied'}  
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Create</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_lead_create'
                            value = {permissions?.perm_lead_create}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_lead_create?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                             bgcolor: permissions?.perm_lead_create?'#3107AA':'#888888'
                           },
                            }} 
                            text = {permissions?.perm_lead_create?'Allow':'Denied'}  
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_lead_edit'
                            value = {permissions?.perm_lead_edit}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_lead_edit?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                             bgcolor: permissions?.perm_lead_edit?'#3107AA':'#888888'
                           },
                            }} 
                            text = {permissions?.perm_lead_edit?'Allow':'Denied'}  
                           /> 
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can view, edit and create lead.</small>  
                       </Grid>
               </Grid>
           </Box>

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Address" />

                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_address_view'
                            value = {permissions?.perm_address_view}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_address_view?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                             bgcolor: permissions?.perm_address_view?'#3107AA':'#888888'
                           },
                            }} 
                            text = {permissions?.perm_address_view?'Allow':'Denied'}  
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Create</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_address_create'
                            value = {permissions?.perm_address_create}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_address_create?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                             bgcolor: permissions?.perm_address_create?'#3107AA':'#888888'
                           },
                            }} 
                            text = {permissions?.perm_address_create?'Allow':'Denied'}  
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                           onClick={handleAccess}
                           name = 'perm_address_edit'
                           value = {permissions?.perm_address_edit}
                           sx={{borderRadius:"20px",
                           fontSize:'14px',
                           backgroundColor:permissions?.perm_address_edit ?'#3107AA':'#888888',
                           pl:2,
                           "&.MuiButtonBase-root:hover": {
                            bgcolor: permissions?.perm_address_edit ?'#3107AA':'#888888'
                          },
                           }} 
                           text = {permissions?.perm_address_edit ?'Allow':'Denied'} 
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can view, edit and create address.</small>  
                       </Grid>
               </Grid>
           </Box>

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Contact" 
                           />  
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_contact_view'
                            value = {permissions?.perm_contact_view}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_contact_view?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_contact_view?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_contact_view?'Allow':'Denied'} 
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Create</Typography>  
                           <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_contact_create'
                              value = {permissions?.perm_contact_create}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_contact_create?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_contact_create?'#3107AA':'#888888'
                              },
                              }} 
                              text = {permissions?.perm_contact_create?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_contact_edit'
                            value = {permissions?.perm_contact_edit}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_contact_edit?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_contact_edit?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_contact_edit?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can view, edit and create contact.</small>  
                       </Grid>
               </Grid>
           </Box>

      {/*  Folloup Permission display */}
           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Follow Up" 
                           />  
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_followup_view'
                            value = {permissions?.perm_followup_view}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_followup_view?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_followup_view?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_followup_view?'Allow':'Denied'} 
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Create</Typography>  
                           <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_followup_create'
                              value = {permissions?.perm_followup_create}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_followup_create?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_followup_create?'#3107AA':'#888888'
                              },
                              }} 
                              text = {permissions?.perm_followup_create?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           {/* <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_followup_edit'
                            value = {permissions?.perm_followup_edit}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_followup_edit?'#7673F6':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_followup_edit?'#7673F6':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_followup_edit?'Allow':'Denied'}
                           /> */}
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can view, edit and create follow up.</small>  
                       </Grid>
               </Grid>
           </Box>

      {/*  Task Permission Display */}
           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Task" 
                           />  
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_task_view'
                            value = {permissions?.perm_task_view}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_task_view?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_task_view?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_task_view?'Allow':'Denied'} 
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Create</Typography>  
                           <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_task_create'
                              value = {permissions?.perm_task_create}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_task_create?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_task_create?'#3107AA':'#888888'
                              },
                              }} 
                              text = {permissions?.perm_task_create?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_task_edit'
                            value = {permissions?.perm_task_edit}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_task_edit?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_task_edit?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_task_edit?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>By allowing edit, user can assign task</small>  
                       </Grid>
               </Grid>
           </Box>

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}> 
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Setting" />

                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2}  flexDirection='column' >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                             onClick={handleAccess}
                             name = 'perm_settings_view'
                             value = {permissions?.perm_settings_view}
                             sx={{borderRadius:"20px",
                             fontSize:'14px',
                             backgroundColor:permissions?.perm_settings_view?'#3107AA':'#888888',
                             pl:2,
                             "&.MuiButtonBase-root:hover": {
                               bgcolor: permissions?.perm_settings_view?'#3107AA':'#888888'
                             },
                             }} 
                             text = {permissions?.perm_settings_view?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2}  flexDirection='column' >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                             onClick={handleAccess}
                             name = 'perm_settings_create'
                             value = {permissions?.perm_settings_create}
                             sx={{borderRadius:"20px",
                             fontSize:'14px',
                             backgroundColor:permissions?.perm_settings_create?'#3107AA':'#888888',
                             pl:2,
                             "&.MuiButtonBase-root:hover": {
                               bgcolor: permissions?.perm_settings_create?'#3107AA':'#888888'
                             },
                             }} 
                             text = {permissions?.perm_settings_create?'Allow':'Denied'}
                           />
                       </Grid>

                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_settings_edit'
                            value = {permissions?.perm_settings_edit}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_settings_edit?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_settings_edit?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_settings_edit?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can view, edit and create setting.</small>  
                       </Grid>
               </Grid>
           </Box>

           {/* permissions for Assign */}
           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}> 
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Assign (L/O/F)" />

                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2}  flexDirection='column' >
                       </Grid>

                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'>        
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                              onClick={handleAccess}
                              name = 'perm_assign_edit'
                              value = {permissions?.perm_assign_edit}
                              sx={{borderRadius:"20px",
                              fontSize:'14px',
                              backgroundColor:permissions?.perm_assign_edit?'#3107AA':'#888888',
                              pl:2,
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: permissions?.perm_assign_edit?'#3107AA':'#888888'
                              },
                            }} 
                            text = {permissions?.perm_assign_edit?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>By allowing edit, user can assign Lead/Oppo/Followup</small>  
                       </Grid>
               </Grid>
           </Box>
           
           {/* permissions for Assign task only*/}
           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}> 
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600},
                           "&:hover": {backgroundColor:'none'}, 
                           color:'#444444', backgroundColor:'none'}} 
                           text="Assign (Task)" />

                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2}  flexDirection='column' >
                       </Grid>

                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'>  
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_taskAssign_edit'
                            value = {permissions?.perm_taskAssign_edit}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_taskAssign_edit?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_taskAssign_edit?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_taskAssign_edit?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>selected role can assign task.</small>  
                       </Grid>
               </Grid>
           </Box>

           {/* permissions for Approve */}

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}> 
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Approve (Opportunity)" />

                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2}  flexDirection='column' >
                           {/* <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                             onClick={(e)=>handleAccess(e)}
                             name = 'perm_approve_view'
                             value = {permissions?.perm_approve_view}
                             sx={{borderRadius:"20px",
                             fontSize:'14px',
                             backgroundColor:permissions?.perm_approve_view?'#3107AA':'#888888',
                             pl:2,
                             "&.MuiButtonBase-root:hover": {
                               bgcolor: permissions?.perm_approve_view?'#3107AA':'#888888'
                             },
                             }} 
                             text = {permissions?.perm_approve_view?'Allow':'Denied'}
                            /> */}
                       </Grid>
                       
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'> 
                        
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_approve_edit'
                            value = {permissions?.perm_approve_edit}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_approve_edit?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_approve_edit?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_approve_edit?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} flexDirection='column'>
                           <small>By allowing edit, user can approve opportunity</small>  
                       </Grid>
               </Grid>
           </Box>

           {/* Permissions for Validate */}

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}> 
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Validate (Lead)" />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2}  flexDirection='column' >
                           {/* <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                             onClick={handleAccess}
                             name = 'perm_validate_view'
                             value = {permissions?.perm_validate_view}
                             sx={{borderRadius:"20px",
                             fontSize:'14px',
                             backgroundColor:permissions?.perm_validate_view?'#3107AA':'#888888',
                             pl:2,
                             "&.MuiButtonBase-rot:hover": {
                               bgcolor: permissions?.perm_validate_view?'#3107AA':'#888888'
                             },
                             }} 
                             text = {permissions?.perm_validate_view?'Allow':'Denied'}
                           /> */}
                       </Grid>

                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'> 
                        
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'>
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >Edit</Typography>  
                           <CustemBtn variant="contained" 
                            onClick={handleAccess}
                            name = 'perm_validate_edit'
                            value = {permissions?.perm_validate_edit}
                            sx={{borderRadius:"20px",
                            fontSize:'14px',
                            backgroundColor:permissions?.perm_validate_edit?'#3107AA':'#888888',
                            pl:2,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: permissions?.perm_validate_edit?'#3107AA':'#888888'
                            },
                            }} 
                            text = {permissions?.perm_validate_edit?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} >
                           <small>By allowing edit, user can validate lead</small>  
                       </Grid>
               </Grid>
           </Box>

           {/* permission for see performance_data */}

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}> 
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                       <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                           <CustemBtn  
                           sx={{borderRadius:"10px",  
                           borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                           borderRadius:'0px',fontSize:'16px',
                           boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                           fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                           "&:hover": {backgroundColor:'none'},
                           color:'#444444', backgroundColor:'none'}} 
                           text="Performance" />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2}  flexDirection='column' >
                           <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                           <CustemBtn variant="contained" 
                             onClick={handleAccess}
                             name = 'perm_performance_view'
                             value = {permissions?.perm_performance_view}
                             sx={{borderRadius:"20px",
                             fontSize:'14px',
                             backgroundColor:permissions?.perm_performance_view?'#3107AA':'#888888',
                             pl:2,
                             "&.MuiButtonBase-root:hover": {
                               bgcolor: permissions?.perm_performance_view?'#3107AA':'#888888'
                             },
                             }} 
                             text = {permissions?.perm_performance_view?'Allow':'Denied'}
                           />
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'> 
                        
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'>
                         
                       </Grid>
                       <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} >
                           <small>By allowing view, user can see the performance</small>  
                       </Grid>
               </Grid>
           </Box>
          

           <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}> 
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                  <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                      <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                      <CustemBtn  
                      sx={{borderRadius:"10px",  
                      borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                      borderRadius:'0px',fontSize:'16px',
                      boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                      fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                      color:'#444444', backgroundColor:'none'}} 
                      text="COF" />
                  </Grid>
                 
                  <Grid container item xs={12} md={6} sm={3} lg={2} rowGap={2} flexDirection='column'>
                      <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                      <CustemBtn variant="contained" 
                      onClick={handleAccess}
                      name = 'perm_cof_view'
                      value = {permissions?.perm_cof_view}
                      sx={{borderRadius:"20px",
                      fontSize:'14px',
                      backgroundColor:permissions?.perm_cof_view?'#3107AA':'#888888',
                      pl:2,
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: permissions?.perm_cof_view?'#3107AA':'#888888'
                      },
                      }} 
                      text = {permissions?.perm_cof_view?'Allow':'Denied'}
                      />
                  </Grid>

                  <Grid container item xs={12} md={6} sm={3} lg={2} rowGap={2} flexDirection='column'>
                      <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                      <CustemBtn variant="contained" 
                      onClick={handleAccess}
                      name = 'perm_cof_create'
                      value = {permissions?.perm_cof_create}
                      sx={{borderRadius:"20px",
                      fontSize:'14px',
                      backgroundColor:permissions?.perm_cof_create?'#3107AA':'#888888',
                      pl:2,
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: permissions?.perm_cof_create?'#3107AA':'#888888'
                      },
                      }} 
                      text = {permissions?.perm_cof_create?'Allow':'Denied'}
                      />
                  </Grid>
                
                  <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'> 
                  </Grid>

                  <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} >
                    <small>selected role can view and create cof.</small>  
                  </Grid>
               </Grid>
            </Box> 
            
            <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}> 
               <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center',textAlign:'center'}} spacing={{lg:8,xs:3}} >
                <Grid container  item xs={12} md={6} sm={3} lg={2} flexDirection='column' rowGap={2} >
                    <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'hidden'}}}>Features</Typography>  
                    <CustemBtn  
                      sx={{borderRadius:"10px",  
                      borderBottom:{xs:'1px solid #e4e4e4',lg:'none',sm:'none'}, 
                      borderRadius:'0px',fontSize:'16px',
                      boxShadow:'none',fontSize:{lg:'16px',xs:'20px'}, 
                      fontWeight:{lg:500,sm:500,md:500, xs:600}, 
                      color:'#444444', backgroundColor:'none'}} 
                      text="Service" />
                </Grid>
               
                <Grid container item xs={12} md={6} sm={3} lg={2} rowGap={2} flexDirection='column'>
                    <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                    <CustemBtn variant="contained" 
                      onClick={handleAccess}
                      name = 'perm_service_view'
                      value = {permissions?.perm_service_view}
                      sx={{borderRadius:"20px",
                      fontSize:'14px',
                      backgroundColor:permissions?.perm_service_view?'#3107AA':'#888888',
                      pl:2,
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: permissions?.perm_service_view?'#3107AA':'#888888'
                      },
                      }} 
                      text = {permissions?.perm_service_view?'Allow':'Denied'}
                    />
                </Grid>

                <Grid container item xs={12} md={6} sm={3} lg={2} rowGap={2} flexDirection='column'>
                    <Typography sx={{fontWeight:600,visibility:{lg:'hidden',sm:'hidden',md:'hidden',xs:'visible'}}} >View</Typography>  
                    <CustemBtn variant="contained" 
                      onClick={handleAccess}
                      name = 'perm_service_create'
                      value = {permissions?.perm_service_create}
                      sx={{borderRadius:"20px",
                      fontSize:'14px',
                      backgroundColor:permissions?.perm_service_create?'#3107AA':'#888888',
                      pl:2,
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: permissions?.perm_service_create?'#3107AA':'#888888'
                      },
                      }} 
                      text = {permissions?.perm_service_create?'Allow':'Denied'}
                    />
                </Grid>
              
                <Grid container item xs={12} md={6} sm={3} lg={2} xl={2}  rowGap={2} flexDirection='column'> 
                </Grid>
                <Grid container item xs={12} md={6} sm={3} lg={2} xl={2} rowGap={2} >
                  <small>selected role can view and create service.</small>  
                </Grid>
              </Grid>
           </Box> 

        </DialogContent>
          <DialogActions sx={{mt:1, mb:1}}>
            <Button
              id="validate" variant="contained" disabled={!buttonStatus} onClick={() => !buttonStatus ? '' : handleSaveRole()}
              sx={{
                backgroundColor: !buttonStatus ? themeColor.palette.backgroundColor :'', borderRadius:"10px", pl:5, pr:5,fontSize:'16px',
                textTransform: 'none', 
              }}
              >
              {loader ? <CircularProgress size={20} color="inherit" /> : 'Save'}
            </Button>
            {/* <CustemBtn onClick={handleSaveRole} disabled={!buttonStatus} variant="contained" sx={{borderRadius:"10px", pl:5, pr:5,fontSize:'16px'}} text="Save" /> */}
          </DialogActions>
        {/* </Dialog> */}
        </BootstrapDialog>
    </div>
  );
}