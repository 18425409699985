const LeadConvertedStatusIcon = () => {

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4040_81538)">
        <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" fill="#630000" stroke="#630000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.5013 7.66602C21.1036 7.66602 24.8346 11.397 24.8346 15.9993C24.8346 20.6017 21.1036 24.3327 16.5013 24.3327C11.8989 24.3327 8.16797 20.6017 8.16797 15.9993C8.16797 11.397 11.8989 7.66602 16.5013 7.66602ZM16.5013 15.166C14.8346 15.166 13.4457 15.4438 12.3346 15.9993C12.3346 18.3005 14.2001 20.166 16.5013 20.166C18.8025 20.166 20.668 18.3005 20.668 15.9993C19.5569 15.4438 18.168 15.166 16.5013 15.166ZM13.5846 11.8327C12.6246 11.8327 11.8162 12.4821 11.5748 13.3656L11.5429 13.4998H15.6264C15.4336 12.5487 14.5927 11.8327 13.5846 11.8327ZM19.418 11.8327C18.4579 11.8327 17.6496 12.4821 17.4081 13.3656L17.3762 13.4998H21.4597C21.2669 12.5487 20.4261 11.8327 19.418 11.8327Z" fill="#FAFF00"/>
        </g>
        <defs>
        <clipPath id="clip0_4040_81538">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )}

export default LeadConvertedStatusIcon 
