import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";

export const createTaskForLead = createAsyncThunk('/createtask',
   async (data) => {
      const res = await axiosInstance.post('/mixinmanagetask', data)
      return res.data
   }
)

export const getTaskList = createAsyncThunk('/gettasklist',
   async (data) => {
      const res = await axiosInstance.post('/fetchtasklist', data)
      return res.data
   }
) 

export const followupAssigned = createAsyncThunk('/followupAssigned',
   async (data) => {
      const res = await axiosInstance.post('/followupAssigned', data)
      return res.data
   }
) 
export const getAllWorkingDays = createAsyncThunk('/workingDays',
   async (data) => {
      const res = await axiosInstance.post('/getWorkingDays', data)
      return res.data
   }
) 
