import { experimentalStyled as styled } from '@mui/material/styles';

const Item = styled('div')({
    display:'flex',
    justifyContent:'center'
  })
  
  const CardBody = styled('div')({
    //  backgroundColor:'#F0EFFD',
     padding:'20px',
     borderRadius:'10px',
    })
  
  const Search = styled('div')({
    display:'flex',
    padding:'0px 10px',
    borderRadius:'10px',
    justifyItems:'center',
    // border:'1px solid #B0AEFF ',
    flexDirection:'row',
    backgroundColor:'#ffffff',
    boxShadow:'0px 4px 20px #e4e4e4'
  })
  
  const CardHeader = styled('div')({
      display:'flex',
      justifyContent:'space-between',
      borderBottom:'0.5px solid #BBBBBB',
      padding:'10px'
  })
  
  const CardInfo = styled('div')({
      display:'flex',
      justifyContent:'space-between',
      // marginTop:"10px",
  })

  export {CardInfo, CardBody,CardHeader}