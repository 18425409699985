import { createSlice } from '@reduxjs/toolkit'
import { createFeasibility, cafServiceApproval, cafServiceReject, uploadCOFServiceID, createOpportunity, sendrequsetforapproval, opportunityRejectForUser, createcommercials, fetchComanyLeads, fetchopportunitylist, oppodetails, opportunityApprovedForUser, opportunityedit, uploadstagedocument } from '../Thunk/createopportunitythunk'

const initialState = {
     createopportunity: [],
     companyleads: [],
     opportunitylist: [],
     opportunityedit: [],
     opportunitydetails: [],
     updateOpportunityEdit: false,
     cafService: false,
     storeOtherCafID:'',
     billingStartDate:{},
     errormsgCaf:false,
     errormsgBilling:false
     //    refreshoppopage : false
}

export const createOpportunitySlice = createSlice({
     name: 'creatopportunity',
     initialState,
     reducers: {
          emptyOpportunityList(state, action) {
               state.opportunitylist = action.payload
          },
          storeOtherCAFID(state, action){
               state.storeOtherCafID = action.payload
          },
          DisplayErrorMsgCAF(state, action){
               state.errormsgCaf = action.payload 
          },
          storeBillingStartDate(state, action){
               state.billingStartDate = action.payload
          },
          DisplayErrorMsgBilling(state, action){
               state.errormsgBilling = action.payload 
          },
          
     },
     
     extraReducers: (builder) => {
          builder.addCase(createOpportunity.fulfilled, (state, action) => {
               state.createopportunity = action.payload
          })

          builder.addCase(fetchComanyLeads.fulfilled, (state, action) => {
               state.companyleads = action.payload
          })

          builder.addCase(fetchopportunitylist.fulfilled, (state, action) => {
               state.opportunitylist = []
               state.opportunitylist = action.payload
          })

          builder.addCase(opportunityedit.fulfilled, (state, action) => {
               state.opportunityedit = action.payload
               state.updateOpportunityEdit = !state.updateOpportunityEdit
          })

          builder.addCase(oppodetails.fulfilled, (state, action) => {
               state.opportunitydetails = action.payload
          })

          builder.addCase(opportunityApprovedForUser.fulfilled, (state, action) => {
               state.updateOpportunityEdit = !state.updateOpportunityEdit
          })

          builder.addCase(sendrequsetforapproval.fulfilled, (state, action) => {    // request again
               state.updateOpportunityEdit = !state.updateOpportunityEdit
          })
          builder.addCase(createFeasibility.fulfilled, (state, action) => {
               state.updateOpportunityEdit = !state.updateOpportunityEdit
          })

          builder.addCase(opportunityRejectForUser.fulfilled, (state, action) => {
               state.updateOpportunityEdit = !state.updateOpportunityEdit
          })

          builder.addCase(uploadstagedocument.fulfilled, (state, action) => {
               state.updateOpportunityEdit = !state.updateOpportunityEdit
          })

          builder.addCase(createcommercials.fulfilled, (state, action) => {
               state.updateOpportunityEdit = !state.updateOpportunityEdit
          })

          // CAF and service id approved and reject
          builder.addCase(cafServiceApproval.fulfilled, (state, action) => {
               state.cafService = !state.cafService
          })
          builder.addCase(cafServiceReject.fulfilled, (state, action) => {
               state.cafService = !state.cafService
          })
          builder.addCase(uploadCOFServiceID.fulfilled, (state, action) => {
               state.cafService = !state.cafService
          })
     },
})

export const { emptyOpportunityList, storeOtherCAFID , DisplayErrorMsgCAF, storeBillingStartDate, DisplayErrorMsgBilling} = createOpportunitySlice.actions
export default createOpportunitySlice.reducer
