import React, { useState, useEffect } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import OpportunityCard from '../../components/custom/OpportunityCard'
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { CountCard, EditIcon, Navbar, NotificationIcon, OpportunityIcon, FollowupCard, ProgressBar, RenewalCard, OppoLeadListCard, ExecutiveCard, FloatingButton, FollowUpIcon, TaskIcon, LeadIcon, UserIcon, CompanyIcon, CalenderIcon, CalenderCard } from '../../components/custom';
import { Link, useNavigate } from 'react-router-dom';
import AlertIcon from '../../components/custom/AlertIcon';
import AnniversaryWish from '../../components/manager/AnniversaryWish';
import FloatingListModal from '../../components/custom/FloatingListModal';
import NotificationModal from '../../components/custom/NotificationsModal';
import { useDispatch, useSelector } from 'react-redux';
import ContactIcon from '../../components/custom/ContactIcon';
import { dash_notification, dashboardthunk, getperformance, getwishlistthunk, upcomingrenewal } from '../../Redux/Thunk/dashboardthunk';
import swal from 'sweetalert';
import BirthdayWishe from '../../components/manager/BirthdayWish';
import dayjs from 'dayjs';
import moment from 'moment';
import CreateTaskForm from '../Task/CreateTaskForm';
import ResetIcon from '../../components/custom/ResetIcon';

const Item = styled('div')({
  display: 'flex',
  justifyContent: 'center'
})

const CardBody = styled('div')({
  padding: '20px',
  borderRadius: '10px',
})

const CardBody1 = styled('div')({
  padding: '10px',
  borderRadius: '10px',
})
const CardLayout = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const DashSidebar = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const CardDetails = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

})

const CardInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})


const Dashboard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const [opennotificationmodal, setOpenNotificationModal] = useState(false)
  const [openCalender, setOpenCalender] = useState(false);
  const { user } = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const dashboard_data = useSelector((state) => state?.dashboard?.dashboard_data?.data)
  const wish_list = useSelector((state) => state?.dashboard?.wishlist_data?.data)
  const upcoming_renewal = useSelector((state) => state?.dashboard?.upcoming_renewal ? state?.dashboard?.upcoming_renewal : [])
  const [start_date, setStart_Date] = useState(dayjs().startOf('month'));
  const [end_date, setEnd_Date] = useState(dayjs().endOf('day'));
  const performance_data = useSelector((state) => state?.dashboard?.performance_report?.data)
  const [performancedate, setPerformanceDate] = useState('This Month')
  const [refresh, setRefresh ]= useState(false)
  const [taskedit, setTaskEdit]= useState({})
  const [update, setUpdate] = useState(false);
  const [days, setDays]= useState('')
  
  const [ openTaskPopup, setOpenTaskPopup] = useState(false)
  const Title = "Alert List"

  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth();
  var startDate = new Date(year, month, 1);
  startDate.setHours(0, 0, 0, 0);
  
  var endDate = new Date(year, month + 1, 0);
  endDate.setHours(23, 59, 59, 999);

 
  const [performstartdate, setPerformStartDate] = useState(startDate.getTime())
  const [performsenddate, setPerformEndDate] = useState(endDate.getTime())
  const notification_data = useSelector((state) => state?.dashboard?.notifications?.data)

  useEffect(() => {
    dispatch(dashboardthunk({ start_date, end_date }))
    dispatch(getwishlistthunk())
    dispatch(upcomingrenewal())
    dispatch(dash_notification())
  }, [start_date, end_date])

  useEffect(() => {
    setEnd_Date(dayjs().endOf('day'))
  }, [start_date])

 
  
  useEffect(() => {
    dispatch(getperformance({start_date : performstartdate, end_date:performsenddate})).then((res) => {
      
    })
  }, [refresh])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const openNotificationModal = () => {
    setOpenNotificationModal(true)
  }

  const handleClose = (value) => {
    setOpen(false);
  };

  const handlecloseNotificationModal =() => {
    setOpenNotificationModal(false)
  }
  const handlePerformance = (e) => {
    setPerformanceDate(e.target.value)
    var today = new Date();
    if(e.target.value === 'This Week') {
      var currentDay = today.getDay();
      var startDate = new Date(today);
      startDate.setDate(today.getDate() - currentDay);
      startDate.setHours(0, 0, 0, 0)
      setPerformStartDate(startDate.getTime())
      var endDate = new Date(today)
      //endDate.setDate(startDate.getDate() + 6);
      endDate.setHours(23, 59, 59, 999)
      setPerformEndDate(endDate.getTime())
      setRefresh(!refresh)
    } else if(e.target.value == 'This Month') {
      var year = today.getFullYear();
      var month = today.getMonth();
      var startDate = new Date(year, month, 1);
      startDate.setHours(0, 0, 0, 0);
      setPerformStartDate(startDate.getTime())
      var endDate = new Date();
      endDate.setHours(23, 59, 59, 999);
      setPerformEndDate(endDate.getTime())
      setRefresh(!refresh)
    } else if(e.target.value == 'Last Month') {
        var year = today.getFullYear();
        var month = today.getMonth();
        var lastMonthStartDate = new Date(year, month - 1, 1);
        lastMonthStartDate.setHours(0, 0, 0, 0);
        var lastMonthEndDate = new Date(year, month, 0);
        lastMonthEndDate.setHours(0, 0, 0, 0);
        setPerformStartDate(lastMonthStartDate.getTime())
        setPerformEndDate(lastMonthEndDate.getTime())
        setRefresh(!refresh)
    } else if(e.target.value == 'Last 3 Months') {
        var year = today.getFullYear();
        var month = today.getMonth();
        var lastThreeMonthsStartDate = new Date(year, month - 3, 1);
        lastThreeMonthsStartDate.setHours(0, 0, 0, 0);
        setPerformStartDate(lastThreeMonthsStartDate.getTime())
        var lastMonthEndDate = new Date(year, month, 0);
        lastMonthEndDate.setHours(23, 59, 59, 999);
        setPerformEndDate(lastMonthEndDate.getTime())
        setRefresh(!refresh)
    } else {
      setPerformStartDate(new Date("2010-01-01").getTime())
      setPerformEndDate(new Date().getTime())
      setRefresh(!refresh)
    }
  }
 
  const openCalenderCard = () => {
    setOpenCalender(true)
  }

  const permissionDisable = () => {
    sweetalert("error", "You have no permissions to access this. Please contact your administrator for more information.")
  }
  
  const handleResetDate =() => {
    setStart_Date(dayjs().startOf('month'))
    setEnd_Date(dayjs().endOf('month'))
  }
  // useEffect(()=>{
  //  if(user?.data?.user?.id === undefined){
  //   navigate('/')
  //  }else{
  //   navigate('/dashboard')
  //  }
  // },[])

  //for task
  // const handleTaskopen = (task) => {
  //   setTaskEdit(task)
  //   setOpenTaskPopup(true)
  //   // navigate('/dashboard/lead', {state: {lead :lead, operation:'editLead'}})
  // };

  const sweetalert = (variant, text) => {
    swal({
      icon: variant === 'error' ? 'error' : 'success',
      title: text,
      button: "Ok!",
    });
  };

  const CardView =
    <>
      <CardDetails>
        <Typography sx={{ color: "#7673F6", fontWeight: 600, fontSize: '14px' }}>
          EUP-22-01523
        </Typography>
        <Typography sx={{ color: '#FFCE00', fontWeight: 600, letterSpacing: 1, fontSize: '12px' }}>
          Meeting
        </Typography>
      </CardDetails>
      <CardInfo>
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 0.5 }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
            Date & Time
          </Typography>
          <Typography sx={{ color: '#F16500', pt: 0.5, fontSize: '14px', fontWeight: 600 }} >
            DD-MM-YYYY
          </Typography>
        </Box>
        {/* <Box sx={{display:'flex',flexDirection:'column',pt:1.5}}>
              <Typography sx={{fontSize:'1.2rem'}}>
                  Assigned By
              </Typography>
              <Typography sx={{color:'#F16500',pt:0.5}}>
                  Manoj Gholap
              </Typography>
              </Box> */}
      </CardInfo>
      <Typography sx={{ pt: 0.5, fontSize: '14px', fontWeight: 600 }}>
        Remaining Days
      </Typography>
      <Typography sx={{ pt: 0.5 }}>
        <ProgressBar />
      </Typography>
    </>

  const ExecutiveData = <>

    {/* opportunity card */}
    {permissionsDetails?.perm_opportunity_view ? <>
      <CardBody sx={{ flex: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid spacing={{ xs: 2, md: 3, }} columns={{ xs: 2, sm: 2, md: 8, lg: 3 }}>
            {Array.from(Array(1)).map((_, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <OppoLeadListCard dash_data={dashboard_data?.oppofordashboard} name="My opportunities" />
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardBody>
    </> : <></>}

    {/* lead list card */}
    {permissionsDetails?.perm_lead_view ? <>
      <CardBody sx={{ flex: 2 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 8, lg: 3 }}>
            {Array.from(Array(1)).map((_, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <OppoLeadListCard dash_data={dashboard_data?.leadfordashboard} name="My Leads" />
              </Grid>
            ))}
          </Grid>
        </Box>
      </CardBody>
    </> : <></>}
  </>

  const GodData = <>
    {/* opportunity card */}
    <Box sx={{ display: 'flex', mt: 2, flexDirection: 'column', borderRadius: '10px', backgroundColor: 'transparent', width: `${ (wish_list?.wishlist_contact.length > 0 ||  wish_list?.wishlist_companies.length > 0) ? '96.5%':'94.5%'}` }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 2, pr: 2, pt: 2 }}>
        <Typography sx={{fontWeight:600 }}>Analytics</Typography>
        <Box>
        <CustemBtn value='This Week' onClick={handlePerformance} variant='h6' text="This Week" sx={{ fontSize: '14px', color:`${performancedate == 'This Week' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'This Week' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'This Week' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        <CustemBtn value='This Month' onClick={handlePerformance} variant='h6' text="This Month" sx={{ fontSize: '14px',marginLeft:'5px', color:`${performancedate == 'This Month' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'This Month' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'This Month' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        <CustemBtn value='Last Month' onClick={handlePerformance} variant='h6' text="Last Month" sx={{ fontSize: '14px',marginLeft:'5px', color:`${performancedate == 'Last Month' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'Last Month' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'Last Month' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        <CustemBtn value='Last 3 Months' onClick={handlePerformance} variant='h6' text="Last 3 Months" sx={{ fontSize: '14px',marginLeft:'5px', color:`${performancedate == 'Last 3 Months' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'Last 3 Months' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'Last 3 Months' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        <CustemBtn value='All' onClick={handlePerformance} variant='h6' text="All" sx={{ fontSize: '14px',marginLeft:'5px', color:`${performancedate == 'All' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'All' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'All' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        </Box>
        <Link style={{ textDecoration: 'none' }} to='/dashboard/performancelist'>
          <Typography sx={{ color: '#A484FE', fontWeight:600 }}>{ performance_data?.performance_data?.length == 0 ? '' : 'See All'}</Typography>
        </Link>
      </Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        {
          performance_data?.performance_data?.length == 0 ? <CardBody sx={{ flex: 2 }}>
            <Box sx={{ flexGrow: 1, alignItems:"center" }}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 8, lg: 4 }}>
                <Grid sx={{textAlign:'center'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                   <Typography sx={{color:'red', fontSize:'16px'}}>No Data Available !</Typography>
                </Grid>
              </Grid>
            </Box>
          </CardBody> : performance_data?.performance_data?.slice(0, 2).map((emp) => {
            return <CardBody sx={{ flex: 2 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 8, lg: 4 }}>
                  <Grid item xs={2} sm={4} md={4}>
                    <ExecutiveCard emp={emp} For='dash' sx={{ backgroundColor: '#FBFFF5' }} />
                  </Grid>
                </Grid>
              </Box>
            </CardBody>
          })
        }
      </Box>
    </Box>
    {/* lead list card */}
    {/* <CardBody sx={{ flex: 2 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 8, lg: 4 }}>
          {Array.from(Array(1)).map((_, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <ExecutiveCard sx={{ backgroundColor: '#FBFFF5' }} name="Name of Manager" />
            </Grid>
          ))}
        </Grid>
      </Box>
    </CardBody> */}
  </>

  return (
    <Box sx={{ marginBottom: '40px' }}>
      <Grid sx={{ borderBottom: '1px solid #e4e4e4', mt: 3, ml: { xs: 1, sm: 1, xl: 5, lg: 5 }, mr: { xs: 1, sm: 1, xl: 5, lg: 5 }, display: "flex", justifyContent: 'space-between', alignItems: 'flex-end' }}>
        <Grid sx={{ display: 'flex', alignItems: 'center' }} item xs={10}>
          <Tooltip title="Calender" placement="top-start">
            <Typography onClick={openCalenderCard}><CalenderIcon /></Typography>
          </Tooltip>
          <Typography ml={1} sx={{color:'#3E238D', fontWeight:600}}> {moment(Number(new Date(start_date).getTime())).format('MMMM Do YYYY')} to {moment(Number(new Date(end_date).getTime())).format('MMMM Do YYYY')}</Typography>
          <Typography ml={1} sx={{mt:1, cursor:'pointer'}} onClick={handleResetDate}><ResetIcon/></Typography>
        </Grid>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {/* User Icon */}
          {permissionsDetails?.perm_user_view ? <>
            <Tooltip title="Users" placement="top-start">
              <Typography sx={{ pr: 1 }} onClick={() => navigate('/dashboard/userlist')}><UserIcon /></Typography>
            </Tooltip></> : <></>}

          {/* Contact Icon  */}
          {permissionsDetails?.perm_contact_view ? <>
            <Tooltip title="Contacts" placement="top-start">
              <Typography sx={{ pr: 1 }} onClick={() => navigate('/dashboard/contactlist')}><ContactIcon /></Typography>
            </Tooltip></> : <></>}

          {/* Company icon */}
          {permissionsDetails?.perm_company_view ? <>
            <Tooltip title="Companies" placement="top-start">
              <Typography sx={{ pr: 1 }} onClick={() => navigate('/dashboard/companylist')}><CompanyIcon /></Typography>
            </Tooltip></> : <></>}

          {/* Notification */}
         
            {
              user?.data?.user?.role.role == 'god' ? '':  <>
              <Tooltip title="Notification" placement="top-start">
                <Typography onClick={openNotificationModal} sx={{ pr: 1 }} >
                <NotificationIcon w={32} h={32} /></Typography> 
                </Tooltip>
              </>
            }
           
         

        </Grid>
      </Grid>

      <CardLayout sx={{ flexDirection: { sm: 'column', md: 'column', lg: 'row', xs: 'column' }, alignItems: 'center', justifyContent: 'center', /*pl: { xs: 1, sm: 1, lg: 4, xl: 4 }, pr: { xs: 1, sm: 1, lg: 4, xl: 4 } */ }}>
        {/* countcard */}
        <CardBody sx={{ mt: 2, ml: { xs: 1, sm: 1, xl: 3, lg: 3 }, mr: { xs: 1, sm: 1, xl: 3, lg: 3 }, flex: 2, backgroundColor: 'transparent' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 8, lg: 8 }}>
              {/* {Array.from(Array(1)).map((_, index) => ( */}
              <>
                {/* Leads */}
                <Grid item xs={2} sm={4} md={4} lg={4}>
                  {permissionsDetails?.perm_lead_view ? <>
                    <Link to='/dashboard/leadlist' style={{ textDecoration: 'none' }} >
                      <Item>
                        <CountCard
                          sx={{
                            background: "#f7fdff",
                            boxShadow: '0px 4px 20px #e4e4e4',
                            borderRadius: '8px',
                            flex: 2,
                            pt: 1, pb: 1, pl: 2, pr: 2,
                          }}
                          // color= "#3C8AFF"
                          background="#9DCEFC"
                          header="Leads"
                          startName="WIP"
                          endName="Converted"
                          incompletecount={dashboard_data?.inprocessleadcount}
                          completecount={dashboard_data?.convertedleadcount}
                          icon={<LeadIcon height={40} width={40} />}
                        />
                      </Item>
                    </Link>
                  </> : <>
                    <Item onClick={permissionDisable}>
                      <CountCard
                        sx={{
                          background: "#f7fdff",
                          boxShadow: '0px 4px 20px #e4e4e4',
                          borderRadius: '8px',
                          flex: 2,
                          pt: 1, pb: 1, pl: 2, pr: 2,
                        }}
                        // color= "#3C8AFF"
                        background="#9DCEFC"
                        header="Leads"
                        startName="Inprocess"
                        endName="Converted"
                        incompletecount={0}
                        completecount={0}
                        icon={<LeadIcon height={40} width={40} />}
                      />
                    </Item>
                  </>}
                </Grid>

                {/* Follow ups */}
                <Grid item xs={2} sm={4} md={4} lg={4} >
                  {permissionsDetails?.perm_followup_view ? <>
                    <Link to='/dashboard/followuplist' style={{ textDecoration: 'none' }} >
                      <Item>
                        <CountCard
                          sx={{
                            background: "#f7fdff",
                            boxShadow: '0px 4px 20px #e4e4e4',
                            borderRadius: '8px',
                            flex: 2,
                            pt: 1, pb: 1, pl: 2, pr: 2,
                          }}
                          // color= "#3C8AFF"
                          startName="Open"
                          endName="Closed"
                          background="#FBFF41"
                          header="Follow-Up"
                          incompletecount={dashboard_data?.pendingfollowupcount}
                          completecount={dashboard_data?.completedfollowupcount}
                          icon={<FollowUpIcon height={40} width={40} />}
                        />

                      </Item>
                    </Link></> : <>
                    <Item onClick={permissionDisable}>
                      <CountCard
                        sx={{
                          background: "#f7fdff",
                          boxShadow: '0px 4px 20px #e4e4e4',
                          borderRadius: '8px',
                          flex: 2,
                          pt: 1, pb: 1, pl: 2, pr: 2,
                        }}
                        // color= "#3C8AFF"
                        startName="Open"
                        endName="Closed"
                        background="#FBFF41"
                        header="Follow-Up"
                        incompletecount={0}
                        completecount={0}
                        icon={<FollowUpIcon height={40} width={40} />}
                      />
                    </Item>
                  </>}
                </Grid>

                {/* Tasks */}

                <Grid item xs={2} sm={4} md={4} lg={4}>
                  {permissionsDetails?.perm_task_view ? <>
                    <Link to='/dashboard/tasklist' style={{ textDecoration: 'none' }} >
                      <Item>
                        <CountCard
                          sx={{
                            background: "#f7fdff",
                            boxShadow: '0px 4px 20px #e4e4e4',
                            borderRadius: '8px',
                            flex: 2,
                            pb: 1, pl: 2, pr: 2,
                          }}
                          // color= "#3C8AFF"
                          startName="Open"
                          endName="Closed"
                          background="#FBFF41"
                          header="Tasks"
                          incompletecount={dashboard_data?.countoftaskpending}
                          completecount={dashboard_data?.countoftaskcompleted}
                          icon={<TaskIcon height={40} width={40} />}
                        />

                      </Item>
                    </Link>
                  </> : <>
                    <Item onClick={permissionDisable}>
                      <CountCard
                        sx={{
                          background: "#f7fdff",
                          boxShadow: '0px 4px 20px #e4e4e4',
                          borderRadius: '8px',
                          flex: 2,
                          pb: 1, pl: 2, pr: 2,
                        }}
                        // color= "#3C8AFF"
                        startName="Open"
                        endName="Closed"
                        background="#FBFF41"
                        header="Tasks"
                        incompletecount={0}
                        completecount={0}
                        icon={<TaskIcon height={40} width={40} />}
                      />
                    </Item>
                  </>}
                </Grid>

                <Grid item xs={2} sm={4} md={4} lg={4}>
                  {permissionsDetails?.perm_opportunity_view ? <>
                    <Link to='/dashboard/opportunitylist' style={{ textDecoration: 'none' }}>
                      <Item>
                        <CountCard
                          sx={{
                            background: "#f7fdff",
                            boxShadow: '0px 4px 20px #e4e4e4',
                            borderRadius: '8px',
                            flex: 2,
                            pb: 1, pl: 1, pr: 1,
                          }}
                          color="#04CB0C"
                          open="Open"
                          endName="Lost"
                          startName = "Won"
                          background="#9DCEFC"
                          header="Opportunities"
                          openoppo ={dashboard_data?.countofopenoppo}
                          incompletecount={dashboard_data?.closedwonoppocount}
                          completecount={dashboard_data?.closedlostoppocount}
                          icon={<OpportunityIcon height={40} width={40} />}
                        />
                      </Item>
                    </Link>
                  </> : <>
                    <Item onClick={permissionDisable}>
                      <CountCard
                        sx={{
                          background: "#f7fdff",
                          boxShadow: '0px 4px 20px #e4e4e4',
                          borderRadius: '8px',
                          flex: 2,
                          pt: 1, pb: 1, pl: 1, pr: 1,
                        }}
                        color="#04CB0C"
                        startName="Closed Won"
                        endName="Closed Lost"
                        background="#9DCEFC"
                        header="Opportunities"
                        incompletecount={0}
                        completecount={0}
                        icon={<OpportunityIcon height={40} width={40} />}
                      />
                    </Item>
                  </>}
                </Grid>
              </>
              {/* ))} */}
            </Grid>
          </Box>
        </CardBody>

        {/* cardlist */}
        <CardBody sx={{ mt: 2, minHeight: 250, ml: { xs: 1, sm: 1, xl: 3, lg: 3 }, mr: { xs: 1, sm: 1, xl: 3, lg: 3 }, flex: 2, backgroundColor: 'transparent' }}>
          <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AlertIcon h={22} w={22} />
              <Typography style={{ marginLeft: '10px', fontWeight: 600 }}>Alert</Typography>
            </Box>

            <Box>
              <Link style={{ textDecoration: 'none' }} to='/dashboard/alertlist'>
                {(dashboard_data?.followupfordashboard?.length > 0 || dashboard_data?.taskfordashboard?.length > 0) ? <Typography color='#A484FE' fontSize='14px' fontWeight='bold' alignItems='center' >See All</Typography> : ''}
              </Link>
            </Box>
          </Box>

          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 8 }}>
            {/* {Array.from(Array(2)).map((_, index) => ( */}
            {
              (dashboard_data?.followupfordashboard?.length == 0 && dashboard_data?.taskfordashboard?.length == 0) ? <>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Item>
                    <Typography sx={{ color: 'red' }}>No Data Available !</Typography>
                  </Item>
                </Grid>
              </>
                : dashboard_data?.followupfordashboard?.map((followup) => {
                  return <Grid item xs={2} sm={4} md={4} lg={4}>
                    <Item>
                      <FollowupCard For='dash' followup={followup} width={330} />
                    </Item>
                  </Grid>
                })
            }
            {
              dashboard_data?.taskfordashboard?.map((task) => {
                return <Grid item xs={2} sm={4} md={4} lg={4}>
                  <Item>
                    <FollowupCard  For='dash' task={task} width={330} />
                  </Item>
                </Grid>
              })
            }
            {/* ))} */}
          </Grid>
        </CardBody>
        {/* sidebar */}

        <DashSidebar sx={{ flex: 1}}>
          <CardBody sx={{ mt: 2, minHeight: 250, ml: { xs: 1, sm: 1, xl: 3, lg: 3 }, mr: { xs: 1, sm: 1, xl: 2, lg: 2 }, flex: 1, backgroundColor: 'transparent'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 600, mb: 1, fontSize: '14px' }}>Upcoming Renewals</Typography>
              {/* <Link style={{ textDecoration: 'none' }} state={{ For: 'renewal' }} to='/dashboard/opportunitylist' >
                {upcoming_renewal?.data?.length > 0 ? <Typography fontSize='12px' alignItems='center' >See All</Typography> : ''}
              </Link> */}
            </Box>
            <Grid  spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 8, lg: 12 }}>
              {
                // upcoming_renewal?.data?.length == 0 ? <>
                  <Grid sx={{ minWidth: '260px' }} item xs={12} sm={12} md={12} lg={12}>
                    <Item>
                      <Typography sx={{color:'red'}} >No Data Available !</Typography>
                    </Item>
                  </Grid>
                // </> :
                //   upcoming_renewal?.data?.map((company) => {
                //     return <Grid sx={{ mt: 1 }} item xs={2} sm={4} md={12}>
                //       <Item>
                //         <RenewalCard renewal_data={company} />
                //       </Item>
                //     </Grid>
                //   })
              }
            </Grid>

          </CardBody>
        </DashSidebar>
      </CardLayout>

      {/* my opportunity and my lead card */}
      <CardLayout sx={{ flexDirection: { sm: 'column', md: 'column', lg: 'row', xs: 'column' }, pl: { xs: 1, sm: 1, lg: 4, xl: 3 }, pr: { xs: 1, sm: 1, lg: 4,  } }}>
        <Box sx={{ display: 'flex', flexDirection: { sm: 'column', md: 'column', lg: 'row', xs: 'column' }, flex: 4, mt: 3 }}>
          {permissionsDetails?.perm_performance_view ? GodData : ExecutiveData}
        </Box>

        {/* sidebar */}
        <DashSidebar sx={{ flex: 1 }}>
          <CardBody sx={{ mt: 5, overflow: 'hidden' }}>
            <Box sx={{ flexGrow: 1, borderRadius: '10px' }}>
              <Grid container sx={{ maxHeight: '370px', overflowY: 'scroll' }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 8, lg: 12 }}>
                {
                  wish_list?.wishlist_companies?.map((company) => {
                    return <Grid item xs={2} sm={4} md={12}>
                      <Item>
                        <AnniversaryWish id={company?.id} name={`${company?.company_name} ${company?.company_type}`} />
                      </Item>
                    </Grid>
                  })
                }
                {
                  wish_list?.wishlist_contact?.map((contact) => {
                    return <Grid item xs={2} sm={4} md={12}>
                      <Item>
                        <BirthdayWishe name={`${contact?.first_name} ${contact?.last_name}`} id={contact?.id} />
                      </Item>
                    </Grid>
                  })
                }
              </Grid>
            </Box>
          </CardBody>

          {/* <Box sx={{ flexGrow: 1, borderRadius: '10px' }}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 8, lg: 12 }}>
                {
                  wish_list?.wishlist_contact?.map((contact) => {
                    return <Grid item xs={2} sm={4} md={12}>
                            <Item>
                              dffs
                              <BirthdayWishe name ={`${contact?.first_name} ${contact?.last_name}`} email={contact?.email} />
                            </Item>
                          </Grid>
                  })
                }
              </Grid>
            </Box> */}
        </DashSidebar>
      </CardLayout>
      <FloatingListModal open={open} setOpen={setOpen} handleClose={handleClose} />
      <NotificationModal notification_data={notification_data} open = {opennotificationmodal} setOpen={setOpenNotificationModal} handleClose={handlecloseNotificationModal} />
      <FloatingButton onClick={handleClickOpen} />

      {openCalender && <CalenderCard days={days} setDays={setDays} start_date={start_date} end_date={end_date} setStart_Date={setStart_Date} setEnd_Date={setEnd_Date} open={openCalender} setOpenCalender={setOpenCalender} />}
      {/* {openTaskPopup? <CreateTaskForm title={Title} from ='alert' For='edit' taskedit={taskedit} openTaskPopup={openTaskPopup} setOpenTaskPopup={setOpenTaskPopup} update={update} setUpdate={setUpdate} />:<></> } */}
    </Box>

  )
}

export default Dashboard


// <Box sx={{ flexGrow: 1, backgroundColor: '#F4F4F4', p: 2, borderRadius: '10px' }}>
//<Box sx={{ flexGrow: 1, backgroundColor: '#F4F4F4', p: 2, mt: 3, borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>