import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';

const Icon = () => {
    return (
        <>
            <svg width="18" height="7" style={{padding:"5px"}} viewBox="0 0 18 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 1.72021L8.75 5.77974L1 1.72021" stroke="#333333" stroke-width="1.47619" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </>
    )
}

export default function DropDown({ name, id, Default ,searchinput, createLabel, handleCreateNew, handleFocus, onSearch, clearOnBlur, options,inputValue, placeholder, sx, isOptionEqualToValue,onChange, getOptionLabel, oninputchange,value,setInputValue,setValue,freesolo }) {
    return (
    <Autocomplete
        disablePortal
        freeSolo={freesolo}
        id={id}
        // id="combo-box-demo"
        options={options}
        name={name}
        sx={{
            '&.Mui-focused': {      
            },
            '.css-13ekc2m-MuiInputBase-root-MuiOutlinedInput-root':{
                borderRadius:'8px',
            },
            ...sx
            }}
            size='small'
            value={value}
            onChange={onChange}
            clearOnBlur={clearOnBlur}
           // getOptionSelected={(option) =>option.label}
            getOptionLabel={getOptionLabel}
           defaultValue={Default}
           isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => <TextField {...params} value={searchinput} onFocus={handleFocus} onChange={onSearch} placeholder={placeholder} variant="outlined" />}                    
            // noOptionsText={(
            //     <Typography variant="body2" color="gray" sx={{cursor:'pointer'}} onClick={handleCreateNew}>
            //         {createLabel}
            //     </Typography>
            //   )}
         />
    )
}   

