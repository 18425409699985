import React ,{useState} from 'react'
import { ActionButtons, ApproveBtn, RejectBtn } from './NewOppoDetailscss'
import { experimentalStyled as styled } from '@mui/material/styles';
import { cafServiceApproval, cafServiceReject, opportunityApprovedForUser, opportunityRejectForUser } from '../../Redux/Thunk/createopportunitythunk'
import { useDispatch } from 'react-redux'
import validate from "../../validation/validation";
import swal from 'sweetalert'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import themeColor from '../../Theme/themeColor'
import moment from 'moment'
import { H7TablePrimeContent,T5Normaltext} from '../../Theme/theme'
import { TextArea } from '../../components/custom'
import RejectActions from './RejectActions'
import DisplayError from '../../validation/DisplayError';
import CloseIcon from '../../components/custom/CloseIcon';

const ChildCard = styled('div')({
   backgroundColor:'#FFFFFF',
   borderRadius:'10px',
   width:'100%'
 })
 
 const CardInfo = styled('div')({
   display:'flex',
 })
 
 const CardHeaders = styled('div')({
     display:'flex',
     justifyContent:'flex-start',
     alignItems:'center',
     borderBottom:'0.5px solid #BBBBBB',
 })

const ManagerActions = ({ opportunity_id,opportunity, opportunity_stage, stage, hierchy, For, name }) => {
   const dispatch = useDispatch()
   const [openRejectModel, setOpenRejectModel] = useState(false)
   const [rejectRemark, setRejectRemark] = useState('')
   const [error, setError] = useState([]);
   const [disabledButton, setDisabledButton] = useState(false)
   const [loaderReject, setLoderReject] = useState(false)
   const [show, setShow] = useState(true)

   console.log(opportunity_id, opportunity_stage," HHHHHHHHHHHHHH")
   const handleApprove = () => {
      setDisabledButton(true)
      if(name ==='CafService'){
         dispatch(cafServiceApproval({ opportunity_id: opportunity_id, opportunity_stage: opportunity_stage, For: For})).then((res) => {
            if (res.payload.errormsg) {
               setDisabledButton(false)
               return sweetalert('error', res.payload.errormsg)
            } else {
               setDisabledButton(false)
               return sweetalert('success', res.payload.data)
            }
         })
      }else{
         dispatch(opportunityApprovedForUser({ opportunity_id: opportunity_id, opportunity_stage: opportunity_stage })).then((res) => {
            if (res.payload.errormsg) {
               setDisabledButton(false)
               return sweetalert('error', res.payload.errormsg)
            } else {
               setDisabledButton(false)
               return sweetalert('success', res.payload.data)
            }
         })
      }
   }

   let validation_input = [
      {tagid: 'rejectRemark', text: rejectRemark, required: true, regex_name: 'free_text', errmsg: 'Please add the reject remark between 30 to 1000 characters.', min:30, max:1000 },
   ]
   //reject oppo request
   const handleOpenRejectModel = () => {
      setShow(true)
      setOpenRejectModel(true)
   }

   const handleOfRejectRemark = (e) =>{
      setRejectRemark(e.target.value)
   }

   const handleChangeReject = () =>{
      if (validate(validation_input).length == 0) {
         setLoderReject(true)
         if(name ==='CafService'){
            dispatch(cafServiceReject({opportunity_id : opportunity_id, opportunity_stage : opportunity_stage, remark : rejectRemark, For: For})).then((res)=>{
               if (res.payload.errormsg) {
                  setLoderReject(false)
                  setRejectRemark('')
                  setOpenRejectModel(false)
                  return sweetalert('error', res.payload.errormsg)
               } else {
                  setLoderReject(false)
                  setRejectRemark('')
                  setOpenRejectModel(false)
                  return sweetalert('success', res.payload.data)
               }
            })
         }else{
            dispatch(opportunityRejectForUser({opportunity_id : opportunity_id, opportunity_stage : opportunity_stage, remark : rejectRemark})).then((res)=>{
               if (res.payload.errormsg) {
                  setLoderReject(false)
                  setRejectRemark('')
                  setOpenRejectModel(false)
                  return sweetalert('error', res.payload.errormsg)
               } else {
                  setLoderReject(false)
                  setRejectRemark('')
                  setOpenRejectModel(false)
                  return sweetalert('success', res.payload.data)
               }
            })
         }
      } else {
         setError(validate(validation_input))
      }
   }

console.log(stage,"stage")
   const sweetalert = (variant, text) => {
      swal({
         icon: variant === 'error' ? 'error' : 'success',
         title: text,
         button: "Ok!",
      });
   };

   return (
      <>
         {
            (
               () => {
                  if (stage?.status == 'Pending'  ) {
                     if(openRejectModel === false){
                      return  <ActionButtons>
                                 {/* <Button
                                    id="validate" variant="contained" onClick={() => disabledButton ? '' :handleApprove()}
                                    sx={{
                                          color:themeColor.palette.BrandColor, 
                                          backgroundColor: disabledButton ? themeColor.palette.backgroundColor :'#FFFFFF',
                                          ":hover":{cursor:'pointer', boxShadow:'none', backgroundColor: disabledButton ? themeColor.palette.backgroundColor :'#FFFFFF'},
                                          display: 'flex',
                                          padding: '4px 0px',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          gap: '6px',
                                          flex: '1 0 0',
                                          borderRadius: '8px',
                                          border: '1px solid #3107AA',
                                          boxShadow:'none',
                                          textTransform: 'none', 
                                    }}
                                    >
                                    {disabledButton ? <CircularProgress size={20} color="inherit" /> : ' Approve'}
                                 </Button> */}
                                 {
                                     opportunity_stage == 'ClosedNoAction' || opportunity_stage == 'ClosedLost' ? '' :<ApproveBtn onClick={() => (disabledButton) ? '' :handleApprove()} sx={{ color:themeColor.palette.BrandColor, backgroundColor: disabledButton ? themeColor.palette.backgroundColor :'', ":hover":{cursor:'pointer'}}}>
                                       {disabledButton ? <CircularProgress size={20} color="inherit" /> : ' Approve'}
                                    </ApproveBtn >
                                 }
                         
                         {
                            opportunity_stage == 'ClosedNoAction' || opportunity_stage == 'ClosedLost'? '' :<RejectBtn onClick={handleOpenRejectModel} sx={{ color: themeColor.palette.PrimaryRed, ":hover":{cursor:'pointer'}}}>
                              Reject
                           </RejectBtn>
                         }                        
                     </ActionButtons>
                     }else{
                     return <ChildCard>
                        
                      {
                        show ? <>
                             <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center',}}>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                              Reject Remark<span style={{ color: 'red' }}>*</span>
                           </H7TablePrimeContent>
                           <Typography sx={{cursor:'pointer', mt:1}} onClick={() => {
                             setShow(!show)
                             setOpenRejectModel(!openRejectModel)
                           }}>
                              <CloseIcon w={25} h={25} />
                           </Typography>
                        </Box>
                     <CardInfo sx={{pr: 1, pb: 0.2 }}>
                        <TextArea name='rejectRemark' value={rejectRemark?.replace(/\s\s+/g, " ")} onChange={handleOfRejectRemark} style={{fontFamily: 'Montserrat',  marginTop:'5px', fontStyle: 'normal',  width: '100%', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', border: '0.5px solid #B0AEFF', background: '#FEFEFE', p: 0.1, paddingLeft:'10px'}} placeholder="Write a note about reject remark."></TextArea>
                     </CardInfo>
                        <div style={{ display:'flex', justifyContent:'end', paddingRight:'10px',marginLeft: 'auto', fontSize:'12px'}}>
                           <span>{rejectRemark.length}/1000</span>
                        </div>
                     <DisplayError error={error} fortagid='rejectRemark' />
                     <CardInfo sx={{pr: 1, pb: 0.5, mt:1 }}>
                     <Button
                        id="validate" variant="contained" onClick ={loaderReject ? '' : handleChangeReject}
                        sx={{
                           color:themeColor.palette.BrandColor,
                              backgroundColor: loaderReject ? themeColor.palette.backgroundColor :'#FFFFFF',
                              ":hover":{cursor:'pointer', boxShadow:'none', backgroundColor: loaderReject ? themeColor.palette.backgroundColor :'#FFFFFF'},
                              display: 'flex',
                              padding: '4px 0px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '6px',
                              flex: '1 0 0',
                              borderRadius: '8px',
                              border: '1px solid #3107AA',
                              boxShadow:'none',
                              textTransform: 'none', 
                        }}
                     >
                        {loaderReject ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                     </Button>

                        {/* <ApproveBtn onClick ={disabledButton ? '' : handleChangeReject} sx={{color:themeColor.palette.BrandColor, backgroundColor: disabledButton ? themeColor.palette.backgroundColor :'', ":hover":{cursor:'pointer'}}}>Save</ApproveBtn> */}
                     </CardInfo>
                        </> : ''
                      }
                           </ChildCard>
                     }

                  } else if (stage?.status == 'Approved') {
                     return <Box sx ={{display:'flex', flexDirection:'column', alignItems:'space-between'}}>
                    
                    <ActionButtons sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection:'column'}}>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                Requested By:
                            </H7TablePrimeContent>
                           <Box>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, }}>
                                 {stage?.created_by?.firstname} {stage?.created_by?.lastname} 

                            </H7TablePrimeContent>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, }}>
                            on {moment(stage.createdAt).format('D MMM Y hh:mm A')}

                            </H7TablePrimeContent>
                           </Box>
                           
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection:'column' }}>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                Approved By:
                            </H7TablePrimeContent>
                            <Box>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500 }}>
                                 {stage?.updated_by?.firstname} {stage?.updated_by?.lastname}
                            </H7TablePrimeContent>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500 }}>
                              on {moment(stage.updatedAt).format('D MMM Y hh:mm A')}
                            </H7TablePrimeContent>
                           </Box>
                        </Box>

                    </ActionButtons>
                    </Box>
                  }else if (stage?.status == 'Rejected') {
                     return <ChildCard sx={{mt:0}}>
                        <Box sx={{display:'flex', mb:0.5, justifyContent:'flex-start', alignItems:'center'}}>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                              Reject Remark
                           </H7TablePrimeContent>
                        </Box>
                     <CardInfo sx={{pr: 1, pb: 0.4 }}>
                        <T5Normaltext sx={{color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                           {stage?.remarks}
                        </T5Normaltext>
                     </CardInfo>

                        <Box sx={{display:'flex', justifyContent:'space-between'}}>
                        <Box sx={{ display: 'flex', flexDirection:'column'}}>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel,pb:0.2}}>
                                Requested By:
                            </H7TablePrimeContent>
                           <Box>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, pb:0.1}}>
                                 {stage?.created_by?.firstname} {stage?.created_by?.lastname} 
                            </H7TablePrimeContent>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, }}>
                               on {moment(stage.createdAt).format('D MMM Y hh:mm A')}
                            </H7TablePrimeContent>
                           </Box>
                           
                        </Box>
                           <Box sx={{mb:1}} >
                              <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2}}>
                                 Rejected By: 
                              </H7TablePrimeContent>
                           <Box>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, pb:0.1}}>
                              {stage?.updated_by?.firstname} {stage?.updated_by?.lastname}
                            </H7TablePrimeContent>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500 }}>
                              on {moment(stage.updatedAt).format('D MMM Y hh:mm')}
                            </H7TablePrimeContent>
                           </Box>
                           </Box>
                        </Box> 
                     </ChildCard>
                  } else if (stage?.status == 'ClosedLost') {
                     return <ChildCard sx={{mt:1}}>
                        
                        <Box sx={{display:'flex', justifyContent:'space-between'}}>
                        <Box sx={{ display: 'flex', flexDirection:'column'}}>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                Closed By:
                            </H7TablePrimeContent>
                           <Box>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, }}>
                                 {stage?.created_by?.firstname} {stage?.created_by?.lastname} 

                            </H7TablePrimeContent>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500,  mb:1}}>
                            on {moment(stage.createdAt).format('D MMM Y hh:mm A')}

                            </H7TablePrimeContent>
                            
                           </Box>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                Lost reason:
                            </H7TablePrimeContent>
                           <Box>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.Primarytext1, fontWeight: 500, mb:1 }}>
                             {opportunity.lost_reason}
                            </H7TablePrimeContent>
                           </Box>
                           
                        </Box>
                       
                        </Box>
                        <Box sx={{display:'flex', mb:0.5, justifyContent:'flex-start', alignItems:'center'}}>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                              Closed Remark
                           </H7TablePrimeContent>
                        </Box>
                     <CardInfo sx={{pr: 1, pb: 0.2 }}>
                        <T5Normaltext sx={{color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                           {stage?.remarks}
                        </T5Normaltext>
                     </CardInfo>
                     </ChildCard>
                  }
               }
            )() 
         }

         {/* {openRejectModel && <RejectActions />} */}
        
      </>
   )
}

export default ManagerActions
