import { Box } from '@mui/material';
import React from 'react'
import { PieChart } from "react-minimal-pie-chart";

const DonutChart = ({emp}) => {
  let oppo = emp.user_oppo
  let closed_won = emp.user_closed_won
  let closed_lost = emp.user_closed_lost
  let open_opp = oppo - (closed_won+closed_lost)
  //console.log(oppo, closed_won, closed_lost, open_opp)
  return (
      <PieChart
       // center={[50, 50]}
        data={[
          {
          color: "#543DE0",
          title: "Open Oppo.",
          value: Math.floor(open_opp),
          },
          {
          color: "#15E703",
          title: "Closed Won",
          value:Math.floor(closed_won),
          },
          {
            color: "#FF0000",
            title: "Closed Lost",
            value:Math.floor(closed_lost),
            },
            {
              color: "#CFCFCF",
              title: "leads",
              value:emp.user_lead,
              },
        ]}
        // label = {(data) =>`${emp.calculate_efficiency?emp.calculate_efficiency:'0'}%`}
        //     labelStyle={{
        //      fontWeight:600,
        //     }}
        labelPosition={0}
        //lengthAngle={360}
        //lineWidth={emp.user_oppo ? 22 : 'none'}
        //paddingAngle={0}
       // radius={50}
        //rounded
        startAngle={0}
        //viewBoxSize={[100, 100]}
       />
  )
}

export default DonutChart
