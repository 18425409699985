import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";

export const dashboardthunk = createAsyncThunk('data/getdashboarddata',
    async(data)=>{
    const res = await axiosInstance.post('/getdashboarddata', data);
        return res.data
    }
)

export const todayleadthunk = createAsyncThunk('data/get_today_lead',
    async(data)=>{
    const res = await axiosInstance.post('/get_today_lead', data);
        return res.data
    }
)

export const expiryleadthunk = createAsyncThunk('data/get_expiry_lead',
    async(data)=>{
    const res = await axiosInstance.post('/get_expiry_lead', data);
        return res.data
    }
)

export const fetchdashboardlead = createAsyncThunk('data/fetchdashboardlead',
    async(data)=>{
    const res = await axiosInstance.post('/fetchdashboardlead', data);
        return res.data
    }
)

export const dashboardcount = createAsyncThunk('data/getdashboardcount',
    async(data)=>{
    const res = await axiosInstance.post('/getdashboardcount', data);
        return res.data
    }
)

export const getwishlistthunk = createAsyncThunk('data/getwishlist',
    async(data)=>{
    const res = await axiosInstance.post('/getwishlist', data);
        return res.data
    }
)

export const sendwishemailthunk = createAsyncThunk('data/sendwishtocontact',
    async(data)=>{
    const res = await axiosInstance.post('/sendwishtocontact', data);
        return res.data
    }
)

export const sendcompanywishthunk = createAsyncThunk('data/sendwishtocompany',
    async(data)=>{
    const res = await axiosInstance.post('/sendwishtocompany', data);
        return res.data
    }
)

export const upcomingrenewal = createAsyncThunk('data/upcomingrenewal',
    async (data)=>{
    const res = await axiosInstance.post('/upcomingrenewal', data);
        return res.data
    }
)

export const getperformance = createAsyncThunk('data/getperformance',
    async (data)=>{
    const res = await axiosInstance.post('/getperformance', data);
        return res.data
    }
)

export const dash_notification = createAsyncThunk('data/getdashnotifications',
    async (data)=>{
    const res = await axiosInstance.get('/getdashnotifications', data);
        return res.data
    }
)

export const deletenotification = createAsyncThunk('data/deletenotification',
    async (data)=>{
    const res = await axiosInstance.get(`/deletenotification/${data}`);
        return res.data
    }
)

export const clearnotifications = createAsyncThunk('data/clearallnotifications',
    async ()=>{
    const res = await axiosInstance.get('/clearallnotifications');
        return res.data
    }
)

export const main_search = createAsyncThunk('data/mainsearch',
    async (data)=>{
    const res = await axiosInstance.post('/mainsearch', data);
        return res.data
    }
)