import React from 'react' 
import {useDispatch, useSelector} from "react-redux";

export default function AdminRole() {
    const { user } = useSelector((state) => state.auth)

    const find_role = user?.data?.user?.type
    const find_god  = user?.data?.user?.role.role

     if (find_role === 'Admin' || find_god === 'god') {
        return true
     } else {
        return false
     }
}