const LessThanIcon = () => {

    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_828_47739)">
        <path d="M8.5 12L5 8L8.5 4" stroke="#6F6BFF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_828_47739">
        <rect width="15" height="15" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )}

    export default LessThanIcon