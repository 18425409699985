import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {Grid, Card} from '@mui/material';
import OpportunityCard from '../../components/custom/OpportunityCard'
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import { Typography } from '@mui/material';
import { CompanyName, ContactCard, DeleteIcon, EditIcon, Navbar } from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import AddIcon from '../../components/custom/AddIcon';
import { useLocation } from 'react-router-dom';


const Item = styled('div')({
  display:'flex',
  justifyContent:'center'
})

const CardBody = styled('div')({
   backgroundColor:'#F0EFFD',
   padding:'10px',
   borderRadius:'10px',
})

const Search = styled('div')({
  display:'flex',
  padding:'0px 10px',
  borderRadius:'10px',
  justifyItems:'center',
  // border:'1px solid #B0AEFF ',
  flexDirection:'row',
  backgroundColor:'#ffffff',
  boxShadow:'0px 4px 20px #e4e4e4'
})

const ChildCard = styled('div')({
  backgroundColor:'#FFFFFF',
  borderRadius:'10px',
})

const CardInfo = styled('div')({
  display:'flex',
  flexWrap:'wrap',
  justifyContent:'space-between',
})

const CardHeaders = styled('div')({
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    borderBottom:'0.5px solid #BBBBBB',
})

export default function OpportunityCommercialCard({commerical}) {
    console.log(commerical,"header")
 return(
    <>
      <CardHeaders>
        <Typography variant='h6' sx={{color:'#3592FF',fontSize:'22px',fontWeight:600, pl:1, pr:0.5, pt:1}}>
            Commercial
        </Typography>
      </CardHeaders>
      <ChildCard > 
                    {/* <CardInfo sx={{mt:2}}></CardInfo> */}
                        {/* {Object.entries(headerName).map(([key,value,index])=>{  */}
                        {/* {Object.keys(headerName).map((key, value, index) => { */}
        <CardInfo sx={{mt:1, pb:0.5, pl:1}}>
            <Typography sx={{flex:1, fontWeight:600}}>
                One Time Charges
            </Typography>
            <Typography sx={{flex:1, fontWeight:600}}>
              Contract Peroid
            </Typography>
            <Typography sx={{flex:1, fontWeight:600}}>
                Annual Charges
            </Typography>
        </CardInfo>
        <CardInfo sx={{pl:1}}>
            <Typography sx={{flex:1,fontSize:'14px',color:'#3107AA'}}>
                {commerical?.opportunity_name}
            </Typography>
            <Typography sx={{flex:1,fontSize:'14px',color:'#3107AA'}}>
                {commerical?.contact?.first_name} {commerical?.contact?.last_name}
            </Typography>
            <Typography sx={{flex:1, fontSize:'14px',color:'#3107AA'}}>
                {commerical?.order_type}
            </Typography>
        </CardInfo>

        <CardInfo sx={{mt:1, pb:0.5, pl:1, pt:0.5}}>
            <Typography sx={{flex:1, fontWeight:600}}>
                Billing Frequency
            </Typography>
            <Typography sx={{flex:1, fontWeight:600}}>
                Billing Type
            </Typography>
            <Typography sx={{flex:1, fontWeight:600}}>
                Date Of Billing
            </Typography>
        </CardInfo>
        <CardInfo sx={{pl:1}}>
            <Typography sx={{flex:1, fontSize:'14px', color:'#3107AA'}}>
                {commerical?.closure_date}
            </Typography>
            <Typography sx={{flex:1, fontSize:'14px', color:'#3107AA'}}>
                {commerical?.lineofbusiness}
            </Typography>
            <Typography sx={{flex:1, fontSize:'14px', color:'#3107AA'}}>
                {commerical?.product_family}
            </Typography>
        </CardInfo>
      
        <CardInfo sx={{mt:1, pb:0.5, pl:1, pt:0.5}}>
            <Typography sx={{flex:1, fontWeight:600}}>
                Date Of Renewal
            </Typography>
            <Typography sx={{flex:1, fontWeight:600}}>
                Service ID
            </Typography>
            <Typography sx={{flex:1}}>
             
            </Typography>
        </CardInfo>
        <CardInfo sx={{pl:1, mb:1}} >
            <Typography sx={{flex:1, fontSize:'14px',color:'#3107AA', fontWeight:600}}>
                {commerical?.product}
            </Typography>
            <Typography sx={{flex:1, fontSize:'14px',color:'#3107AA'}}>
                {commerical?.service_provider}
            </Typography>
            <Typography sx={{flex:1, fontSize:'14px',color:'#3107AA'}}>
               
            </Typography>
        </CardInfo>
      </ChildCard> 
    </>
 )
}