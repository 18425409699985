import React, { useEffect, useState } from 'react';
import { Navbar, DropDown, CustemBtn, CompanyName, InputBox, TextArea, SelectDropDown, AddPlusIcon} from '../../components/custom';
import {FormControl, Select, MenuItem, Grid, Box, styled, Card, Avatar, Typography, TextField, InputLabel, Tooltip, IconButton, ListSubheader } from '@mui/material';
import swal from 'sweetalert';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeadSource, TypeList } from './LeadsValues';
import validate from "../../validation/validation";
import LeadDetails from '../LeadDetails/LeadDetails';
import { useDispatch, useSelector } from 'react-redux';
import { displayContactList } from '../../Redux/Thunk/createcontact';
import { createlead, editlead, leaddetails } from '../../Redux/Thunk/createleadthunk';
import { getProductFamilyTypesList, getProductTypesList, getServiceProviderTypesList } from '../../Redux/Thunk/createsettinglist';
import DisplayError from '../../validation/DisplayError';
import { displayCompanyDetails, getAllCompanyName } from '../../Redux/Thunk/createcompanythunk';
import CreateAddressDropDown from '../Addresspopup/CreateAddressDropDown';
import ContactForm from '../ContactForm/ContactForm';
import { main_search } from '../../Redux/Thunk/dashboardthunk';
import displayname from '../../displaycompanyname/displaycompany';
import {H1FormTitle, T6TableText, H5FormLabel} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import CreateCompanyForm from '../Company/CreateCompanyForm';
import { emptyCompanyDetailsData, storeCompanyData, storeAddressDetails, storeCompayName } from '../../Redux/slices/createCompanySlice';
import { storeContactDetails } from '../../Redux/slices/createContactSlice';
import ClassNameGenerator from '@mui/utils/ClassNameGenerator/ClassNameGenerator';
import DisabledAddPlusIcon from '../../components/custom/DisabledAddPlusIcon';
import { TailSpin } from 'react-loader-spinner';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const MainContainer = styled('div')({
    width: '100%',
    maxHeight: '100%',
    height: 'auto',
})

const CardBody = styled('div')({
    padding: '10px 20px 10px',
    borderRadius: '10px',
})

////   Create New Lead Form  //
const CreateNewLeadForm = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch();
    const {createAddressDetails, refresh} = useSelector((state)=> state.company)
    const {getcreatecompany} = useSelector((state)=>state.company)
    const [editLeadDetails, setEditLeadDetails] = useState(state?.operation === 'editLead' ? state?.lead : {})
    const {getContactList, contactDetails} = useSelector((state)=> state.contact)
    const { getServiceProviderTypes, getproductFamilyList, productTypesList } = useSelector(state => state.settingList)
    const [error, setError] = useState([]);
    let dropdownValuesForProductFamily = getproductFamilyList?.value?.map((item) => { return { label: item, value: item } })
    const [product, setProduct] = useState([])
    const [productFamily, setProductFamily] = useState([])
    const [searchinput, setSearchInput] = useState('') 
    const [serviceProvider, setServiceProvider] = useState('')
    const [productType, setProductType]= useState('')
    const [openContactModel, setOpenContactModel] = useState(false)
    const getCompanyDetails = useSelector((state)=> state.company?.getCompanyDetails)
    const [productFamilyType, setProductFamilyType]= useState('')
    const [dropdownValuesForServiceProvider, setdropdownValuesForServiceProvider] = useState((getServiceProviderTypes.value?.length === 0 || getServiceProviderTypes === undefined) ? [] : getServiceProviderTypes?.value?.map((item) => { return { value: item , label:item} }))
    //const service_provider_list = Object.keys(editLeadDetails)?.length > 0 ? getServiceProviderTypes?.value?.push(editLeadDetails?.service_provider) : getServiceProviderTypes?.value
    //const product_list = Object.keys(editLeadDetails).length > 0 ? productTypesList?.value?.push(editLeadDetails?.product) : productTypesList?.value
    // const dropdownValuesForServiceProvider = getServiceProviderTypes == undefined ? [] : getServiceProviderTypes?.value?.map((item) => { return { label: item, value: item } })
   // const dropdownValuesForProductTypes = productTypesList == undefined ? [] : product_list?.map((item) => { return { label: item, value: item } })
    // if (state?.operation === 'editLead') {
    //     dropdownValuesForServiceProvider?.push({value:editLeadDetails.service_provider, label:editLeadDetails.service_provider})
    // }
    
    const [leadDetails, setLeadDetails] = useState({
        lead_name: '',
        lead_source: '',
        data_source: '',
        // service_provider: '',
        // product: '',
        // product_family:'',
        summary: '',
        type:'',     // Hot, warm and cold
        contact_id: '',
        // id:'',
    })
    const [dropdownDisabled, setDropdownDisabled] = useState(false)
    const [disableButton, setDisableButton] = useState(true)
    const [refresh1, setRefresh1] = useState(false)
    const [companyName, setCompanyName] = useState(null)
    const [openAddressModel, setOpenAddressModel]= useState(false)
    const [selectedOption, setSelectedOption] = useState('');
    const [editContactDetails, setEditContactDetails] = React.useState({});   
    const [contactList, setContactList] = React.useState([])
    const [CreateNewCompany, setCreateNewCompany] = useState(false)
    const route = "CreateFromLead"
    const [editCompanyDetails, setEditCompanyDetails] = React.useState({});
    const [editAddressDetails, setEditAddressDetails] =useState({})
    const {updateCompany} = useSelector((state)=> state.company);
    const [allCompanyName, setallCompanyName] = useState([])
    const allContactsDisable= getContactList?.every(obj => obj.enabled === false)   //contacts are disable
    const [openCompanyModel, setOpenCompanyModel] = useState(false)
    const [show, setShow] = useState(true)
    const [buttonStatus, setButtonStatus] = useState(false)
    const [loader, setLoader] = useState(false)
    const [selectedCompanyLabel, setSelectedCompanyLabel] = React.useState("")
    let allContactList = []
    // if(companyName!== '' || Object.keys(editLeadDetails)?.length > 0){
    //      allContactList = getCompanyDetails?.data?.contacts.filter((status) => status?.enabled === true)?.map((contact) => { return { value: contact?.id, label: `${contact.first_name} ${contact.last_name}` } })
    // }else{
    //     allContactList = []
    // }
    
    const wordsToRemove = ["TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"];
    const companyNameList = (state?.title === "companyProfilePage" || state?.title === "Create Company" ) ? state?.companyName?.company_name:''
    const companyTypeList = (state?.title === "companyProfilePage" || state?.title === "Create Company") ? state?.companyName?.company_type:''
    const remainingNames = `${companyNameList} ${companyTypeList}`?.split(' ')?.map(word => (wordsToRemove.includes(word) ? `(${word})` : word))?.join(' ');
    
    // console.log(createAddressDetails, selectedOption, "createAddressDetails")
    // console.log(dropdownValuesForServiceProvider, 'dropdownValuesForServiceProvider')

    useEffect(() => {
      if(searchinput.length > 2) {
        let timeout_func = setTimeout(() => {
            //  alert("helll")
            setButtonStatus(true)
            dispatch(main_search({searchinput:searchinput, selectedoption:"Company"})).then((res) => {
                if(!searchinput.length) {
                    setallCompanyName([])
                    setButtonStatus(false)
                } else {
                    setallCompanyName(res.payload.data) 
                    setButtonStatus(false)
                }         
            })
          }, 1000);
         
          return () => {
            clearTimeout(timeout_func);
          };
      } else {
        setSearchInput('')
        setallCompanyName([]) 
        dispatch(storeContactDetails({}))
        setButtonStatus(false)
      } 
        dispatch(storeAddressDetails({}))
        setSelectedOption('')
    }, [searchinput])

    useEffect(() => { 
        if(state?.title === "companyProfilePage" || state?.title === "Create Company"){
            setCompanyName({
                value: remainingNames,
                label: remainingNames,
                id: state?.companyName?.id,
                registeredAddress: state?.companyName?.registered_address,
                headOffice: state?.companyName?.head_office,
                branch: state?.companyName?.branch
            })  
            setDisableButton(false)
            setDropdownDisabled(true)
        } 

        if(contactDetails?.title === "Create Contact"){
            setLeadDetails({
                lead_name: '',
                lead_source: '',
                data_source: '',
                summary: '',
                type:'',    
                contact_id:contactDetails?.contactDetails?.id ? contactDetails?.contactDetails?.id :'', 
            })
            setDisableButton(false)
            // setButtonStatus(false)
        }    
        
        if(createAddressDetails?.title === "Create Address") {
            setSelectedOption(createAddressDetails?.addressDetails?.id)
            setDisableButton(false)
            // setButtonStatus(false)
        }    
    }, [createAddressDetails?.title === "Create Address", state?.title === "companyProfilePage", state?.title === "Create Company", contactDetails?.title === "Create Contact", refresh1])
    //  [state?.title === "companyProfilePage", state?.title === "Create Company", contactDetails?.title === "Create Contact"])

    console.log(createAddressDetails?.title, contactDetails?.title, "createAddressDetails?.title")
    // const getContactList = (companyId) => {
    //     console.log(companyId,"companyId")
    //     dispatch(displayContactList(Object.keys(editLeadDetails)?.length > 0 ? editLeadDetails?.company?.id :companyId)).then((data) => {
    //         setContactList(data?.payload?.filter((status) => status?.enabled === true))

    // })}

    useEffect(()=>{  
        //dispatch(getAllCompanyName())
        dispatch(getServiceProviderTypesList())
        if (Object.keys(editLeadDetails)?.length > 0) {
            setLoader(true)
            setLeadDetails({
                lead_name: editLeadDetails ? editLeadDetails?.lead_name : '',
                lead_source: editLeadDetails ? editLeadDetails?.lead_source : '',
                data_source: editLeadDetails ? editLeadDetails?.data_source : '',
                type: editLeadDetails ? editLeadDetails?.type : '',
                summary: editLeadDetails ? editLeadDetails?.summary : '',
                contact_id: editLeadDetails ? editLeadDetails?.contact?.enabled === true ? editLeadDetails?.contact?.id: '':'',
            })
            setProductFamilyType(editLeadDetails ? editLeadDetails?.product_family:'')
            setServiceProvider(editLeadDetails ? editLeadDetails?.service_provider:'')
            setProductType(editLeadDetails ? editLeadDetails?.product:'')
            setSelectedOption(editLeadDetails ? editLeadDetails?.branch?.id :'')
            setDisableButton(false)
            dispatch(displayContactList(editLeadDetails?.company?.id)).then((res) =>{
              setLoader(false)
            })
            setDropdownDisabled(true)
        }      
    },[])

    useEffect(() => {
        displayDropdownValues()
    }, [productFamilyType, serviceProvider])

    useEffect(() => {
        if (state?.operation === 'editLead') {
            if (getServiceProviderTypes?.value) {
                let obj = {value:editLeadDetails.service_provider, label:editLeadDetails.service_provider}
                let check_service_provider = dropdownValuesForServiceProvider.find((provider) => provider.value == obj.value)
                if(!check_service_provider) {
                    setdropdownValuesForServiceProvider([...dropdownValuesForServiceProvider, obj])
                }
            }
           // dropdownValuesForServiceProvider?.push({value:editLeadDetails.service_provider, label:editLeadDetails.service_provider})
        }

    }, [])

    const displayDropdownValues = () => {
        if(productFamilyType && serviceProvider){
            const concatenated = serviceProvider?.concat(productFamilyType); 
            dispatch(getProductTypesList({selected_productfamily: concatenated ??''})).then((res)=>{
                if(res?.payload?.value?.length === 0){
                    if(Object.keys(editLeadDetails).length > 0 && show) { 
                        setProduct([editLeadDetails?.product])  
                        setShow(false)
                      return
                    }
                    setProduct([])
                    sweetalert('error', `No product exists for this ${productFamilyType} under ${serviceProvider}. Please contact the admin about adding a product.`)
                }else{
                    setProduct(res?.payload?.value)
                }
            })     
        }
        if(serviceProvider){
            dispatch(getProductFamilyTypesList({selected_serviceprovider:serviceProvider})).then((res)=>{      
                if(res?.payload?.value?.length === 0){
                    setProduct([])
                    setProductFamily([])
                    sweetalert('error', `Add Product family for ${serviceProvider}. Please contact to Admin.`)
                }else{
                    if(state?.operation === 'editLead') {
                        let get_family = res?.payload?.value.find((family) => family ===  editLeadDetails?.product_family)
                        if (!get_family) {
                          let prod_family = [...res?.payload?.value, editLeadDetails?.product_family]    
                          setProductFamily(prod_family)  
                        }else{
                            setProductFamily(res?.payload?.value)
                        }
                      
                    }else{
                        setProductFamily(res?.payload?.value)
                    }      
                }
            })
        }
    }

    let validation_input = [
        { tagid: 'address', text:selectedOption, regex_name: 'free_text', required: true, errmsg: 'Please Select Address.', min:3, max:500 },
        ((state?.title=== "Lead List" || state ==="CreateFromDashboard") &&  Object.keys(editLeadDetails).length === 0 )? { tagid: 'companyName', text: companyName?.value ??'', regex_name: 'free_text', required: true, errmsg: 'Please Select Company Name.',min:3, max:100} :'',
        // (getContactList?.length === 0 && companyName !== '')? { tagid: 'contact_id', text: leadDetails?.contact_id, regex_name: 'free_text', required: true, errmsg: 'Please Add Contact.' } : ((allContactsDisable === true && companyName !=='') || editLeadDetails?.contact?.enabled === false )? { tagid: 'contact_id', text: leadDetails?.contact_id, regex_name: 'free_text', required: true, errmsg: 'All contacts are disable. Please add new contact.' }:{ tagid: 'contact_id', text: leadDetails?.contact_id, regex_name: 'free_text', required: true, errmsg: 'Please Select Contact.' },
        editLeadDetails?.contact?.enabled === false ? { tagid: 'contact_id', text: leadDetails?.contact_id, regex_name: 'free_text', required: true, errmsg: 'All contacts are disable. Please add new contact.' }:{ tagid: 'contact_id', text: leadDetails?.contact_id, regex_name: 'free_text', required: true, errmsg: 'Please Select Contact.' },
        { tagid: 'lead_source', text: leadDetails?.lead_source, regex_name: 'text', required: true, errmsg: 'Please Select Source.' },
        { tagid: 'data_source', text: leadDetails?.data_source, regex_name: 'text', required: true, allow_numbers: true, other_chars:true, errmsg: 'Please Enter Data Source.' },
        { tagid: 'product', text: productType, required: true, regex_name: 'free_text', errmsg: 'Please Select Product.' },
        { tagid: 'lead_name', text: leadDetails?.lead_name, regex_name: 'free_text', required: true, errmsg: 'Please Enter Lead Name between 1 to 50 characters.', min:1, max:50},
        {tagid: 'service_provider', text: serviceProvider, required: true, regex_name: 'free_text', errmsg: 'Please Select Service Provider'},
        { tagid: 'summary', text: leadDetails?.summary, required: true, regex_name: 'free_text', errmsg: 'Please add the summary between 100 to 2000 characters', min:100, max:2000 },  //min:1, max: 300
        {tagid:'product_family', text:productFamilyType, required: true, regex_name: 'free_text', errmsg: 'Please Select Product Family'},
        {tagid: 'type', text: leadDetails?.type, required: true, regex_name: 'text', errmsg: 'Please Select type.' }
    ]

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setDisableButton(false)
    }

    const handleOfCreateLeadForm = (event) => {
        setLeadDetails({ ...leadDetails, [event.target.name]: event.target.value })
    }

    const handleOfCreateForServiceProvider=(event)=>{
        setServiceProvider(event.target.value)
        setProduct([])
        setProductFamilyType('')
        setProductType('')
    }

    const handleOfCreateProductFamilyType=(event)=>{
        setProductFamilyType(event.target.value)
    }

    const handleOfCreateProductType =(event) =>{
        setProductType(event.target.value)
    }

    console.log(companyName, searchinput,  "companyName")
    console.log(buttonStatus, disableButton, "buuuuuuuuuuuuuuu")
    const handleChangeCompany = (e, value, reason) => {
        // if(state?.title === "Create Company"){
        //     // value = companyName?.value
        //     setCompanyName(null)
        //     setCompanyName({
        //         value: '',
        //         label: '',
        //         id: '',
        //         registeredAddress: '',
        //         headOffice: '',
        //         branch: ''
        //     }) 
        //     setSelectedCompanyLabel("")
        //     setButtonStatus(false)
        //     setDisableButton(true) 
        //     setSearchInput('')
        // }else
         if(value === null || companyName?.value === null){
            setCompanyName(null)
            setButtonStatus(false)
            setallCompanyName([])
            setSearchInput('')
            setError([])
            setDisableButton(true) 
            setDropdownDisabled(false)
            setLeadDetails({
                lead_name: '',
                lead_source: '',
                data_source: '',
                summary: '',
                contact_id: '',
                type:'',
            })
            setSelectedOption('')
            dispatch(storeAddressDetails({}))
            setServiceProvider('')
        }else{
            setCompanyName(value) 
            setLeadDetails({
                lead_name: '',
                lead_source: '',
                data_source: '',
                type:'',
                summary: '',
                contact_id: '',
            });
            setServiceProvider('')
            // setProductFamily('')
            // setProductType('')
            // getContactList(value?.id)
            setDisableButton(false)
            dispatch(displayCompanyDetails(value?.id)).then((res) =>{
                if(res.payload.data){
                    setDropdownDisabled(true)
                    dispatch(displayContactList(value?.id))
                }
            })
        }
    }

   
    const handleSave = () => {
        if (validate(validation_input).length == 0) {
            setDisableButton(true)
            if (Object.keys(editLeadDetails).length == 0) {
                leadDetails.company_id = state?.title === "companyProfilePage" ? state?.companyName?.data?.company ? state?.companyName?.data?.company?.id :state?.companyName?.id: companyName?.id;
                leadDetails.address_id = selectedOption
                leadDetails.service_provider = serviceProvider
                leadDetails.product_family = productFamilyType
                leadDetails.product = productType
                // leadDetails.contact_id = dropdownValues?.id;
                dispatch(createlead(leadDetails)).then((res) => {
                    let LeadID = res?.payload?.data?.lead_id
                    // if(res?.payload?.)
                    if( state?.operation ==='createLead'){
                        if(state?.title === "companyProfilePage"){
                            sweetalert('success',`Lead (${LeadID}) Successfully Created.`)
                            navigate('/dashboard/profile/companyLeads', {state : state?.companyName?.data?.company ? state?.companyName?.data?.company :state?.companyName})
                            setDisableButton(false)
                        }else{
                            sweetalert('success',`Lead (${LeadID}) Successfully Created.`)
                            navigate('/dashboard/leadlist')
                            setDisableButton(false)
                        }
                    }else{
                        swal({
                            buttons: {
                                Cancel: {
                                    text: "Lead List",
                                    value: "Cancel",
                                    className: "popup-blue",
                                },
                                Delete: {
                                    text: "Company Profile",
                                    value: "profile",
                                    className: "popup-gray",
                                },
                            },
                            title: `Lead (${LeadID}) Successfully Created.`,
                            className: "custom-modal",
                            closeOnClickOutside: false,
                        }).then((value) => {
                            if (value == 'profile') {
                                setDisableButton(false)
                                navigate('/dashboard/profile', { state: companyName?.id })
                            } else {
                                setDisableButton(false)
                                navigate('/dashboard/leadlist')
                            }
                        })}
                    })
            } else {
                leadDetails.company_id = editLeadDetails?.company?.id;
                leadDetails.address_id = selectedOption
                // leadDetails.contact_id = dropdownValues.id;
                leadDetails.service_provider = serviceProvider
                leadDetails.product_family = productFamilyType
                leadDetails.product = productType
                leadDetails.lead_id = editLeadDetails?.id
                dispatch(editlead(leadDetails)).then((res) => {
                    if(state?.title === "companyProfilePage"){
                        navigate('/dashboard/profile/companyLeads', {state :editLeadDetails?.company })
                        setDisableButton(false)
                        sweetalert('success', res?.payload?.data?.msg)
                    }else if(state?.title === "LeadDetailsPage"){
                       
                        dispatch(leaddetails(editLeadDetails?.id)).then((response)=>{
                            if(response?.payload){
                                navigate('/dashboard/leaddetails', {state: {leadDetails: editLeadDetails, title:state?.title, loader:true}})
                                setDisableButton(false)
                                sweetalert('success', res?.payload?.data?.msg)
                            }
                        })
                       
                    }else{
                        navigate('/dashboard/leadlist')
                        setDisableButton(false)
                        sweetalert('success', res?.payload?.data?.msg)
                }})
            }
        } else {
            setError(validate(validation_input))
        }
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };

    const handleOpenAddressModel =() =>{
        if(companyName === ""){
            sweetalert('error', "Please Select Company")
        }else{
            setOpenAddressModel(true)
            dispatch(storeCompanyData({}))
            dispatch(displayCompanyDetails(companyName?.id))
        }
    }
    
    const handleOpenCompanyModel =() =>{
        setOpenCompanyModel(true)
        setEditCompanyDetails({})
        // setCompanyName('')
        // navigate('/dashboard/company', {state: {operation: route, company:{}}})
    }

    const handleOpenContactModel =() =>{
        if(companyName === ""){
            sweetalert('error', "Please Select Company")
        }else{
            setOpenContactModel(true)
            dispatch(storeCompayName({}))
        }
    }

    return (
    <>
        <MainContainer>
            {/*  Nav bar */}                    
           {Object.keys(editLeadDetails)?.length > 0 || state?.title === "companyProfilePage" ? <CompanyName sx={{"&:hover": {cursor:'pointer'} }} companyID={Object.keys(editLeadDetails)?.length > 0 ? editLeadDetails?.company?.id :(state?.title === "companyProfilePage" && state?.operation==="createLead") ? remainingNames?.id ? state?.companyName?.data?.company?.id : state?.companyName?.id :''} label={Object.keys(editLeadDetails)?.length > 0 ? `${editLeadDetails?.company?.company_name} ${editLeadDetails?.company?.company_type}` :(state?.title === "companyProfilePage" && state?.operation==="createLead") ? remainingNames?.label ? `${state?.companyName?.data?.company?.company_name}` :`${state?.companyName?.company_name} ${state?.companyName?.company_type}`:''} /> :''}
            <Box style={{ borderBottom: '0.5px solid #E8DADA' }}>
                <Grid sx={{ mt: 1, ml: 4, mr: 8, display: "flex", flexDirection: { lg: 'row', sm: 'row', xs: 'column-reverse' }, justifyContent: { lg: 'space-between', sm: 'space-between', xs: 'start' }, alignItems: { lg: 'flex-end', md: 'flex-end', xs: 'start' } }}>
                    {Object.keys(editLeadDetails).length > 0 ? <H1FormTitle sx={{color: themeColor.palette.Primarytext1}}>{state?.for === 'tasklist'? 'Task Lead Details':'Edit Lead'}</H1FormTitle> : <H1FormTitle sx={{color: themeColor.palette.Primarytext1}}>Create Lead</H1FormTitle>}
                </Grid>
            </Box>

            {
             loader ?   <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
             <TailSpin
                 height="30"
                 width="30"
                 color="blue"
                 ariaLabel="tail-spin-loading"
                 radius="3"
                 wrapperStyle={{}}
                 wrapperClass=""
                 visible={true}
             /> 
             
             </Box> : <>
               <CardBody sx={{ mt: 1, pb: 4, ml: { xs: 1, sm: 1, xl: 2, lg: 2 }, mr: { xs: 1, sm: 1, xl: 2, lg: 2} }}>
                    <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 2}}>
                    {Object.keys(editLeadDetails)?.length > 0 || state?.title === "companyProfilePage" ? <></> :<>
                        <Grid item xs={12} md={4} sm={4} lg={3}>    
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Company Name<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                           <div style={{ position: 'relative' }}>
                                <DropDown 
                                    id='select_company'
                                    sx={{background:'#FFFFFF', mt:1}} 
                                    placeholder = 'Search Company'
                                    name='companyName'
                                    //options ={allCompanyName?.map((name)=>{return {label:`${name?.company_name} ${name?.company_type}`, value:`${name?.company_name} ${name?.company_type}`, id: name?.id, branch:name?.branch, headOffice:name?.head_office, registeredAddress:name?.registered_address}})}
                                    options={buttonStatus ? [{ label: 'Loading...', value: null }] : allCompanyName?.length > 0 ?
                                         displayname(allCompanyName) : []
                                    }
                                    onChange = {handleChangeCompany}
                                    onSearch={(e) =>{
                                        if(e.target.value == '') {
                                            e.preventDefault()
                                            setallCompanyName([])
                                            // setButtonStatus(false);
                                        } else {
                                            setSearchInput(e.target.value.trim())
                                            // setButtonStatus(true);
                                        }
                                    }}
                                    //value= ''
                                    clearOnBlur={true}
                                    getOptionLabel={(option) =>(option ? option.label :"")}
                                    value = {companyName}
                                    searchinput={searchinput}
                                    // createLabel='Create New Company'
                                    // handleCreateNew={handleOpenCompanyModel}
                                />
                            </div>
                            <DisplayError error={error} fortagid='companyName' />
                        </Grid>

                        <Grid item xs={12} md={1} sm={1} lg={1}> 
                            <Tooltip title="Create New Company">
                            <IconButton sx={{mt:{xs:1,sm:4,xl:4,lg:3}, p:0}} onClick={handleOpenCompanyModel}>
                                <AddPlusIcon/>
                            </IconButton>
                            </Tooltip>
                        </Grid>
                    </>}

                    {/* ====== select and create Address ============ */}
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Address<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <Box sx={{minWidth: 120 }}>
                                <FormControl fullWidth>    
                                {/* <InputLabel id="demo-multiple-name-label">Name</InputLabel>      */}
                                <Select id="select_address" 
                                    displayEmpty
                                     disabled={!dropdownDisabled || !state?.title === "companyProfilePage" }
                                    // disabled={(Object.keys(editLeadDetails)?.length === 0 && companyName === null )|| !state?.title === "companyProfilePage"}
                                    value={selectedOption}
                                    name='address'
                                    onChange={handleOptionChange}
                                    MenuProps={MenuProps}
                                    sx={{mt: 1, width:'100%', height:'40px', backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, color:`${state?.for ==='tasklist' ?'#444444':''}`, borderRadius: "10px" }}>
                                     <MenuItem disabled value="">
                                        <span>Select Address</span>
                                    </MenuItem>
                                    {getCompanyDetails?.data?.branches?.map((item, i)=>{
                                    const menuItems = [];

                                  if (item.register_address === true) {
                                    return (
                                        [
                                            <ListSubheader key={`register-subheader-${i}`} style={{fontWeight:'bold'}}>Registered Office Address</ListSubheader>,
                                            <MenuItem key={`register-item-${i}`} style={{ whiteSpace: 'normal', width:'fit-content' }} value={item.id}>{item.address}</MenuItem>,
                                        ]
                                    );
                                  }
                          
                                  if (item.head_office === true) {
                                    return (
                                        [
                                            <ListSubheader key={`head-subheader-${i}`} style={{fontWeight:'bold'}}> Head Office Address</ListSubheader>,
                                            <MenuItem key={`head-item-${i}`} value={item.id} style={{ whiteSpace: 'normal', width:'fit-content' }}>{item.address}</MenuItem>,
                                        ]
                                    );
                                  }
                          
                                  return menuItems;
                                })}
                                {getCompanyDetails?.data?.branches?.map((item, i) => {
                                    if (!item.head_office && !item.register_address) {
                                        return (
                                            [
                                                <ListSubheader key={`branch-subheader-${i}`} style={{fontWeight:'bold'}}> Branch Office Address</ListSubheader>,
                                                <MenuItem key={`branch-item-${i}`} style={{ whiteSpace: 'normal',  width:'fit-content'}} value={item.id}>{item.address}</MenuItem>,
                                            ]
                                        );
                                    }
                                    return null;
                                })}
                                </Select>       
                                </FormControl>
                            </Box> 
                            {getCompanyDetails?.data?.branches?.length === 0 && companyName !== null ? <small style={{color:'red'}}>Please Add Address</small> :<DisplayError error={error} fortagid='address' />}
                        </Grid>
                            
                        {Object.keys(editLeadDetails)?.length > 0 ? <></> :<><Grid item xs={12} md={1} sm={1} lg={1}> 
                            <Tooltip title="Create New Address">
                            <IconButton sx={{mt:{xs:1,sm:4,xl:4,lg:3}, p:0}} onClick={()=> (!dropdownDisabled || !state?.title === "companyProfilePage") ? '' : handleOpenAddressModel()}>
                            {!dropdownDisabled || !state?.title === "companyProfilePage" ? <DisabledAddPlusIcon /> : <AddPlusIcon />}
                            </IconButton>
                            </Tooltip>
                        </Grid></>}

                    {/* ====== select and create contact ============ */}
                        <Grid item xs={12} md={2} sm={2} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Contact Name<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <SelectDropDown
                                id='select_contact'
                                disabled={!dropdownDisabled || !state?.title === "companyProfilePage"}
                                name='contact_id'
                                options={companyName!== '' || Object.keys(editLeadDetails)?.length > 0 ? getCompanyDetails?.data?.contacts.filter((status) => status?.enabled === true)?.map((contact) => { return { value: contact?.id, label: `${contact.first_name} ${contact.last_name}` } }) :[]}
                                // options={getCompanyDetails?.data?.contacts?.map((contact) => { return { value: contact?.id, label: `${contact.first_name} ${contact.last_name}` } })}
                                onchange={handleOfCreateLeadForm}
                                placeholder='Select Name'
                                clearOnBlur={true}
                                value={leadDetails?.contact_id}
                                sx={{ p: 0.1, mt: 1, backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, color:`${state?.for ==='tasklist' ?'#444444':''}`, borderRadius: "10px" }}
                            />
                            {(getContactList?.length === 0 && companyName !== null) ? <small style={{color:'red'}}>Please Add Contact</small> : ((allContactsDisable === true && companyName !== null) ? <small style={{color:'red'}}>All contacts are disabled. Please create new contact.</small>:<DisplayError error={error} fortagid='contact_id' /> )}
                        </Grid>

                        {Object.keys(editLeadDetails)?.length > 0 ? <></> :<>
                            <Grid item xs={12} md={1} sm={1} lg={1}> 
                                <Tooltip title="Create New Contact">
                                <IconButton sx={{mt:{xs:1,sm:4,xl:4,lg:3}, p:0}} onClick={() => !dropdownDisabled || !state?.title === "companyProfilePage" ?'' :handleOpenContactModel()}>
                                    {!dropdownDisabled || !state?.title === "companyProfilePage" ? <DisabledAddPlusIcon /> : <AddPlusIcon />}
                                </IconButton>
                                </Tooltip>
                            </Grid></>}
                    </Grid>
                
                {/* <Grid item xs={12} md={4} sm={4} lg={2}>
                    <Typography sx={{mt:2, fontWeight: 600, fontSize: '18px', lineHeight: '29px',borderBottom: '0.5px solid #E8DADA' }}>Lead Details</Typography>
                </Grid> */}
              

     {/* ===============    Lead  Details ================   */}
                <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                        {/* <Grid item xs={12} md={12} sm={12} lg={1}>
                            <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Lead</Typography>
                        </Grid> */}
                        <Grid item xs={12} md={4} sm={4} lg={2}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Type<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <SelectDropDown
                               id='select_type'
                               disabled={state?.for==='tasklist'}
                                name='type'
                                options={TypeList}
                                onchange={handleOfCreateLeadForm}
                                value={leadDetails?.type}
                                placeholder='Select Type'
                                sx={{ p: 0.1, pl: 1, mt:1, backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, borderRadius: "10px" }}
                                MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='type' />
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Lead Name<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <InputBox disable={state?.for==='tasklist'} name='lead_name' onchange={handleOfCreateLeadForm} value={leadDetails?.lead_name?.replace(/\s\s+/g, " ")} sx={{mt:1, border: "1px solid #B0AEFF",backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }} placeholder="Lead Name" />
                            <DisplayError error={error} fortagid='lead_name' />
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={3}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Source<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <SelectDropDown
                                id='select_source'
                                disabled={state?.for==='tasklist'}
                                name='lead_source'
                                options={LeadSource}
                                onchange={handleOfCreateLeadForm}
                                value={leadDetails.lead_source}
                                placeholder='Select Source'
                                sx={{ p: 0.1, pl: 1, mt:1, backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, borderRadius: "10px" }}
                                MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='lead_source' />
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={3}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Data Source<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <InputBox disable={state?.for==='tasklist'} name='data_source' onchange={handleOfCreateLeadForm} value={leadDetails?.data_source?.replace(/\s\s+/g, " ")} sx={{ mt:1, border: "1px solid #B0AEFF", backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }} placeholder="Data Source" />
                            <DisplayError error={error} fortagid='data_source' />
                        </Grid>
                    </Grid>
                </Box>

                <Box style={{ marginTop: '20px' }}>
                    <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                        {/* <Grid item xs={12} md={0} sm={0} lg={1}>
                        </Grid> */}
                        <Grid item xs={12} md={4} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Service Provider<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <SelectDropDown
                                id='service_provider'
                                disabled={state?.for==='tasklist'}
                                name='service_provider'
                                placeholder="Select Service Provider"
                                options={state?.operation === 'editLead' ? dropdownValuesForServiceProvider : getServiceProviderTypes.value?.length === 0  ? [] : getServiceProviderTypes?.value?.map((item) => { return { value: item , label:item}})}
                                onchange={handleOfCreateForServiceProvider}
                                value={serviceProvider}
                                sx={{ p: 0.1, pl: 1, mt:1, backgroundColor:`${state?.for ==='tasklist' ?'#E9E9FF':'#ffffff' }`, borderRadius: "10px" }}
                                MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='service_provider' />
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Product Family<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <SelectDropDown
                                id='product_family'
                                name='product_family'
                                disabled={state?.for ==='tasklist'|| serviceProvider === ''|| productFamily?.length === 0}
                                options={productFamily?.map((item) => { return { label: item, value: item }})}
                                onchange={handleOfCreateProductFamilyType}
                                value={productFamilyType}
                                placeholder= {serviceProvider === '' || productFamily?.length > 0 ? 'Select Product Family' : 'Loading...'}
                                // Default={dropdownValuesForProductFamily[0]?.value}
                                //  defaultlabel='Network Service'
                                sx={{ p: 0.1,pl: 1,  mt:1,backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, borderRadius: "10px" }}
                                MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='product_family' />
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={4}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Product<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <SelectDropDown
                                id ='product'
                                name='product'
                                disabled={state?.for==='tasklist' || productFamilyType ===''||(productFamilyType ==='' && serviceProvider ==='') || product?.length === 0}
                                options={product?.map((item) => { return { label: item, value: item }})}
                                onchange={handleOfCreateProductType}
                                // Default={dropdownValuesForProductTypes ? dropdownValuesForProductTypes[0]?.value :''}
                                value={productType}
                                placeholder={(productFamilyType ==='' || serviceProvider ==='' || product?.length > 0) ? 'Select Product':'Loading...'}
                                sx={{ p: 0.1, pl: 1,  mt:1, backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, borderRadius: "10px" }}
                            />
                            <DisplayError error={error} fortagid='product' />
                        </Grid>
                    </Grid>
                </Box>

                {/*   Summary     */}
                {/* <Box style={{ marginTop: '20px' }}> */}
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingRight:'15px'}} spacing={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel, marginTop: '20px'}}>Summary<span style={{ color: '#FF0000' }}>*</span></H5FormLabel>
                            <TextArea disabled={state?.for==='tasklist'} name='summary' value={leadDetails?.summary?.replace(/\s\s+/g, " ")} onChange={handleOfCreateLeadForm} style={{fontFamily: 'Montserrat',  marginTop:'10px', fontStyle: 'normal', border: "1px solid #B0AEFF", width: '100%',backgroundColor:`${state?.for==='tasklist' ?'#E9E9FF':'#ffffff' }`, height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', padding:'5px'}} placeholder="Write a note about Lead."></TextArea>
                            <DisplayError error={error} fortagid='summary' />
                        </Grid>
                        <div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                            <span>{leadDetails?.summary?.length}/2000</span>
                        </div>
                    </Grid>
                {/* </Box> */}
            </CardBody>

            <Box sx={{ mt: 2, mr: { xs: 1, sm: 1, xl: 6, lg: 6 }, mb: 2, display: 'flex', justifyContent: 'end' }}>
            {state?.for == 'tasklist' ? '' : <CustemBtn id='save' disabled={disableButton} onClick={handleSave} variant='contained' sx={{ borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px'}} text="Save" />}
            </Box>
             </>
            }

          

        </MainContainer>

        {openAddressModel && <CreateAddressDropDown open= {openAddressModel} setOpen= {setOpenAddressModel} companyDetails={getCompanyDetails?.data?.company} 
            title={route} setEditCompanyDetails={setEditAddressDetails} editCompanyDetails = {editAddressDetails}/>}

        {openContactModel && <ContactForm setRefresh1={setRefresh1} refresh1={refresh1} open ={openContactModel} setOpenModal={setOpenContactModel} title={route}
         dropdownValues={companyName} editContactDetails={editContactDetails} setEditContactDetails= {setEditContactDetails} />}
        
        {openCompanyModel && <CreateCompanyForm openCompanyModel ={openCompanyModel} setOpenCompanyModel={setOpenCompanyModel}  
            setEditCompanyDetails={setEditCompanyDetails} editCompanyDetails={editCompanyDetails} title={route} />}
        </>   

    )
}

export default CreateNewLeadForm