import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Paper, Tooltip, Snackbar, Typography, styled, IconButton } from '@mui/material';
import { AssignIcon, CompanyIcon, CustemBtn, DisableAssignIcon, EditIcon, NotificationIcon, SelectDropDown, TextArea } from '../../components/custom';
import { H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T1ButtonText } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor'
import LeadDetailsCompanyIcon from '../LeadDetails/LeadDetailsCompanyIcon';
import LeadDetailsContactIcon from '../LeadDetails/LeadDetailsContactIcon';
import { leadAssignedToUser, leadValidate, leaddetails } from '../../Redux/Thunk/createleadthunk';
import { createOpportunity, oppodetails, opportunityAssignedForUser, opportunityedit, opportunitylost } from '../../Redux/Thunk/createopportunitythunk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import ThermometerIcon from '../../components/custom/ThermometerIcon';
import moment from 'moment';
import { CheckCircle } from '@mui/icons-material';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import { getContactList } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';
import AssignModal from '../../components/custom/AssignModal';
import TaskDetails from './TaskDetails';
import OpportunityJourney from './OpportunityJourney';
import CompanyNameDetails from '../../components/custom/CompanyNameDetails';
import CallIcon from '../../components/custom/CallIcon';
import EmailIcon from '../../components/custom/EmailIcon';
import OpportunityForm from '../../pages/Opportunity/OpportunityForm'
import { ActionButtons, ApproveBtn, RejectBtn } from './NewOppoDetailscss';
import DisplayError from '../../validation/DisplayError';
import validate from '../../validation/validation';
import ContactNameDetails from '../../components/custom/ContactNameDetails';
import ReminderIcon from '../../components/custom/ReminderIcon';
import AdminRole from '../../adminrole/Adminrole';
import { getresettime, sendreminder } from '../../Redux/Thunk/reminders';

const LoginContainer = styled('div')({
    minHeight: '100vh',
    overflowY: 'auto',
    // marginTop: "0.2px",
    // marginBottom:'0.2px',
    //  marginRight:'20px',
    // padding: "10px",
    background: '#F9F9F9',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
})

const CardBody = styled('div')({
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
})

const ChildCard = styled('div')({
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
})

const CardInfo = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
})

const LostReasons =[
    {
        value: 'High Price',
        label: 'High Price',
    },
    {
        value: 'Proposed Solution not acceptable',
        label: 'Proposed Solution not acceptable',
    },
    {
        value: 'No Feasibility',
        label: 'No Feasibility',
    },
    {
        value: 'Not Happy with Company',
        label: 'Not Happy with Company',
    },
    {
        value: 'Not Happy with Partner',
        label: 'Not Happy with Partner',
    },
    {
        value: 'Delay',
        label: 'Delay',
    },
    {
        value: 'No Activity',
        label: 'No Activity',
    },
    {
        value: 'Others',
        label: 'Others',
    },
  ]

export default function OpportunityDetails() {
    const navigate = useNavigate();
    const { state } = useLocation()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const { createTask } = useSelector((state) => state.task)
    const [update, setUpdate] = useState(false)
    const permissionsDetails = user?.data?.user.role.permissions
    const { updateCreateEditFollowup } = useSelector((state) => state.settingList)
    const { updateOpportunityEdit } = useSelector((state) => state.opportunity)
    const [opportunitydetails, setOpportunitydeatils] = useState([])
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(false)
    const [status, setStatus] = useState('')
    const [opportunityAssigned, setOpporuntiyAssigned] = useState({})
    const [openAssignModal, setOpenAssignModal] = useState(false)
    const [loader, setLoader] = useState(true)
    // const user_ids = [user?.data?.user?.id]
    const [openOpportunityModel, setOpenOpportunityModel] = useState(false)
    const [editOpportunityDetails, setEditOpportunityDetails] = useState({})
    const [closedLost, setClosedLost] = useState(false)
    const [approveClosedLost, setApproveClosedLost] = useState(false)
    const [saveStatus, setSaveStatus] = useState('')
    const [disabledButton, setDisabledButton] = useState(false)
    const [statusLost, setStatusLost] = useState({
        lost_reason: '',
        detailreason: ''
    })
    const [error, setError] = useState([]);
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const [user_ids, setUser_ids] = useState([])
    const [resettime, setResetTime] = useState('')
    const [disable, setDisable] = useState(false)
    const title = "OpportunityDetailsPage"
    
    useEffect(() => {
        if(!state || state === 'undefined') {
            navigate('/dashboard')
            return
          } else {
        // setLoader(true)
            dispatch(oppodetails(state?.oppDetails)).then((res) => {
                setOpportunitydeatils(res?.payload)
                setUser_ids(res?.payload?.all_ids)
                setStatus(res?.payload?.oppodetails?.opportunity_stage)   // set Status in dropdown
                setLoader(false)
                setClosedLost(false)
                setApproveClosedLost(false)
            })
        }
    }, [update, updateCreateEditFollowup, updateOpportunityEdit, createTask, state])

    useEffect(() => {
        // setLoader(true)
        if(opportunitydetails?.oppodetails?.id) {
             dispatch(getresettime({id : opportunitydetails?.oppodetails?.id, stage : opportunitydetails?.oppodetails?.opportunity_stage})).then((res) => {
            setResetTime(res.payload.data)
        }) 
        }
      
    }, [opportunitydetails?.oppodetails?.id])

    let validation_input = [
        { tagid: 'lost_reason', text: statusLost?.lost_reason, regex_name: 'text', required: true, errmsg: 'Please Select Reason.' },
        { tagid:'detailreason', text: statusLost?.detailreason, regex_name: 'free_text', required: true,  errmsg: 'Please add the reason between 30 to 1000 characters', min:30, max:1000}
    ]

    const handleClickCompanyWebsite = (website) => {
        if (website?.length > 0) {
            window.open(website, '_blank');
        }
    }

    const handleClickEditLeadDetails = () => {
        navigate('/dashboard/lead', { state: { lead: opportunitydetails?.oppodetails, operation: 'editLead', title: title } })
        dispatch(displayCompanyDetails(opportunitydetails?.oppodetails?.company?.id))
        getContactList(opportunitydetails?.oppodetails?.company?.id)
    };

    const handleClickEditOppDetails =(id) =>{
        setOpenOpportunityModel(true)
        
        dispatch(displayCompanyDetails(id))
    }

    const handleReminder = (id, stage) => {
     setDisable(true)
     dispatch(sendreminder({opportunity_id : id, stage:stage})).then((res) => {
         if (res.payload.errormsg) {
            sweetalert('error', res.payload.errormsg)
         } else {
            sweetalert('success', res.payload.data)
            dispatch(getresettime({id :opportunitydetails?.oppodetails?.id, stage : opportunitydetails?.oppodetails?.opportunity_stage})).then((res) => {
                setResetTime(res.payload.data)
            }) 
            
         }
     })
    }

    const findIds = (id1, id2) => {
        if (user_ids.includes(id1) || user_ids.includes(id2)) {
            return true
        } else {
            return false
        }
    }

    const handleValidate = (id) => {
        swal({
            buttons: {
                Cancel: {
                    text: "No",
                    value: "cancel",
                    className: "popup-blue",
                },
                Delete: {
                    text: "Yes",
                    value: "yes",
                    className: "popup-gray",
                },
            },
            title: `Do You Want to Validate the Lead (${opportunitydetails?.oppodetails?.opportunity_no}) ?`,
            className: "custom-modal",
            closeOnClickOutside: false,
        }).then((value) => {
            if (value == 'yes') {
                dispatch(leadValidate({ lead_id: id })).then((res) => {
                    sweetalert('success', res?.payload?.data)
                    setUpdate(!update)
                })
            } else {
                console.log('hello')
            }
        })
    }

    const handleAssign = () => {
        setOpporuntiyAssigned(opportunitydetails?.oppodetails)
        setOpenAssignModal(true)
    }

    const handleChangeLeadNotAssign = () => {
        sweetalert('error', "You have no permissions to access this. Please contact your administrator for more information.")
    }

    const saveAssignedManagerOrExecutiveName = (managerList, selectManagerName) => {
        if (selectManagerName === '') {
            sweetalert('error', "Please Select Name")
        } else {
            let findUser = managerList.find((item) => `${item?.firstname} ${item?.lastname}` === selectManagerName)
            dispatch(opportunityAssignedForUser({ opportunity_id: opportunityAssigned?.id, user_id: findUser?.id })).then((res) => {
                sweetalert('success', res.payload.data)
                setOpenAssignModal(false)
                setUpdate(!update)
            })
        }
    }

    const handleClickCompanyProfile = () => {
        navigate('/dashboard/profile', { state: opportunitydetails?.company?.id })
    }

    const handleChnagedOpenLeadDetailsPage = () => {
        navigate('/dashboard/leaddetails', {state:{title: title, leadDetails:opportunitydetails?.leaddetails} })
    }

    const handleChangedServiceDetailsPage =() =>{
        navigate('/dashboard/service', { state: { serviceDetails: opportunitydetails?.oppodetails?.id, title:'Opportunity Details' } })
    }

    const handleChangeLostOpportunity =(e)=>{
        setStatusLost({...statusLost, [e.target.name]:e.target.value})
    }

    const handleChangedClosedLost =()=>{
        setClosedLost(true)
        setApproveClosedLost(false)
    }

    const handleChangeApproveClosedLost =() =>{
        setApproveClosedLost(true)
        setClosedLost(false)
    }

    const handleChangedCancleClosedLost = () =>{
        setClosedLost(false)
    }

    const SubmitLostOpportunity = () =>{
        if(validate(validation_input).length === 0){
            setError([])
            setDisabledButton(true)
            dispatch(opportunitylost({opportunity_id:opportunitydetails?.oppodetails?.id, lost_reason:statusLost?.lost_reason, reasonindetail:statusLost?.detailreason})).then((res)=>{
                if (res.payload.errormsg) {
                    setDisabledButton(false)
                    return sweetalert('error', res.payload.errormsg)     
                } else {
                    sweetalert('success', res.payload.data)
                    setApproveClosedLost(false)
                    setClosedLost(false)
                    setUpdate(!update)
                    setDisabledButton(false)
                } 
                setStatusLost({
                    lost_reason: '',
                    detailreason: ''
                })
            })
           }else{
              setError(validate(validation_input))
          } 
    }

    const sweetalert = (variant, text) => {
        swal({
           icon: variant === 'error' ? 'error' : 'success',
           title: text,
           button: "Ok!",
        });
    };

    return (
        <>
            {loader ? <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
                <TailSpin
                    height="30"
                    width="30"
                    color="blue"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                /> </Box> : <>
                <LoginContainer>
                    {/* Display Image */}
                    <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                        {/* Grid Item 1 */}
                        <Grid item xs={12} md={10} sm={10} lg={4}>
                            {/* <CardBody sx={{ mt: 0, ml: { xs: 1, sm: 1, xl: 0, lg: 0 }, mr: { xs: 1, sm: 1, xl: 2, lg: 2 } }}> */}
                                <Grid sx={{ pl:1, mb: 1, pr:1, display: 'flex', justifyContent: 'space-between', mt:2, alignItems: 'flex-end' }}>
                                    <Grid item xs={10}>
                                        {/* Thermometer Icon */}
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Tooltip title={opportunitydetails?.oppodetails?.type === "Hot" ? "Hot" : opportunitydetails?.oppodetails?.type === "Cold" ? "Cold" : "Warm"} placement="top-start">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
                                                    <path d="M6.97674 11.3023V2.32558C6.97674 1.02326 5.95349 0 4.65116 0C3.34884 0 2.32558 1.02326 2.32558 2.32558V11.3023C0.930233 12.093 0 13.6279 0 15.3488C0 17.907 2.09302 20 4.65116 20C7.2093 20 9.30233 17.907 9.30233 15.3488C9.30233 13.6279 8.37209 12.1395 6.97674 11.3023Z" fill={opportunitydetails?.oppodetails?.type === "Hot" ? "#F16500" : opportunitydetails?.oppodetails?.type === "Cold" ? "#B0AEFF" : "#FFC700"} fill-opacity="0.3" />
                                                    <path d="M4.6523 18.6038C2.83834 18.6038 1.39648 17.162 1.39648 15.348C1.39648 14.1852 2.00114 13.1155 3.02439 12.5108L3.72207 12.0922V2.32476C3.72207 1.81314 4.14067 1.39453 4.6523 1.39453C5.16393 1.39453 5.58253 1.81314 5.58253 2.32476V12.1387L6.28021 12.5573C7.30346 13.162 7.90811 14.2317 7.90811 15.3945C7.90811 17.162 6.46625 18.6038 4.6523 18.6038Z" fill={opportunitydetails?.oppodetails?.type === "Hot" ? "#F16500" : opportunitydetails?.oppodetails?.type === "Cold" ? "#B0AEFF" : "#FFC700"} />
                                                    <path d="M6.32644 12.6504C6.65203 13.162 6.88458 13.7667 6.88458 14.4178C6.88458 16.2318 5.44272 17.6736 3.62877 17.6736C2.97761 17.6736 2.37296 17.4876 1.86133 17.1155C2.46598 17.9992 3.44272 18.6039 4.559 18.6039C6.37296 18.6039 7.81482 17.162 7.81482 15.3481C7.81482 14.2318 7.21016 13.2085 6.32644 12.6504Z" fill={opportunitydetails?.oppodetails?.type === "Hot" ? "#F16500" : opportunitydetails?.oppodetails?.type === "Cold" ? "#B0AEFF" : "#FFC700"} />
                                                </svg>
                                            </Tooltip>
                                            <span>
                                                <H3SubHeader sx={{ ml: 0.5, color: themeColor.palette.OpportunityID }}>
                                                    {opportunitydetails?.oppodetails?.opportunity_no}
                                                </H3SubHeader>
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }} item xs={4}>
                                        <T5Normaltext sx={{ fontWeight:600, ml: 0.5, color: '#4642FF' }}>{opportunitydetails?.oppodetails?.opportunity_stage}
                                        </T5Normaltext>
                                        {/* <CustemBtn sx={{
                                            fontSize: '12px', mb: 0.1, padding: '1px 12px',
                                            alignItems: 'center',gap: '2px', fontWeight: 600, backgroundColor: '#4642FF', color: '#FFFFFF', border: '1px solid #4642FF', borderRadius: '10px',
                                            "&:hover": { backgroundColor: "#4642FF" , cursor:'default'}
                                        }}
                                            variant='contained' text={opportunitydetails?.oppodetails?.opportunity_stage} /> */}
                                    </Grid>
                                </Grid>

                                {/*   company Details   */}
                                <CardBody sx={{padding: '10px', mt: 0, ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl: 1, lg: 1 } }}>
                                    <CompanyNameDetails getCompanyDetails={opportunitydetails} title={title} />
                                </CardBody>

                                {/*     Contacts  Details  */}
                                <ContactNameDetails contactDetails={opportunitydetails?.oppodetails}/>

                                <Box>
                                    <Grid sx={{pl:2, mt: 2, mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                        <Grid item xs={10}>
                                            <H3SubHeader sx={{ color: themeColor.palette.Primarytext1, LineHeight: "21.94px" }}>
                                                Opportunity Info
                                            </H3SubHeader>
                                        </Grid>
                                        <Grid item xs={2} sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }} >
                                            {(checkAdminRole && permissionsDetails?.perm_opportunity_edit) || (permissionsDetails?.perm_opportunity_edit && (user?.data?.user?.id == opportunitydetails?.oppodetails?.assigned_to?.id)) ? <Typography sx={{textAlign:'right', pr:1}} onClick={() => handleClickEditOppDetails(opportunitydetails?.oppodetails?.company?.id)}>{(opportunitydetails?.oppodetails?.opportunity_stage === "Identified") ? <EditIcon w={20} h={20} /> : ''} </Typography> :''} 
                                            {/* <EditIcon w={20} h={20} /> */}
                                        </Grid>
                                    </Grid>

                                    <ChildCard sx={{ml:1, mr:1}}>
                                    {/* <CardInfo sx={{ pt:1, pl: 1, pr: 2, pb: 0.2}}>
                                            <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel}}>
                                                Service ID: 
                                            </H7TablePrimeContent>
                                        </CardInfo> */}
                                        
                                        <CardInfo sx={{ pt:1, pl: 1, pr: 2, pb: 0.2}}>
                                            <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel}}>
                                                Created by: 
                                            </H7TablePrimeContent>
                                            <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel }}>
                                                Assigned to:  
                                            </H7TablePrimeContent>
                                        </CardInfo>
                                        
                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                            <T5Normaltext sx={{flex: 1,
                                                mt: 0.2, 
                                                color: themeColor.palette.primarytext2,
                                                whiteSpace: 'pre-line', // Allow line breaks
                                                wordBreak: 'break-word', // Break long words
                                                maxWidth: 'auto', // Limit max width to 150px
                                                }}>
                                                {opportunitydetails?.oppodetails?.created_by?.firstname} {opportunitydetails?.oppodetails?.created_by?.lastname}
                                            </T5Normaltext>
                
                                            <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2}}>
                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ flexShrink: 0, maxWidth: 'auto',  flex: 1,
                                                    whiteSpace: 'pre-line',
                                                    wordBreak: 'break-word'}}>
                                                 {opportunitydetails?.oppodetails?.assigned_to?.firstname} {opportunitydetails?.oppodetails?.assigned_to?.lastname} 
                                                </span>
                                              
                                                {(checkAdminRole && permissionsDetails?.perm_assign_edit) || (permissionsDetails?.perm_assign_edit && findIds(opportunitydetails?.oppodetails?.assigned_to?.id)) ? <>
                                                    {opportunitydetails?.oppodetails?.opportunity_stage ==='ClosedWon' || opportunitydetails?.oppodetails?.opportunity_stage === 'ClosedLost' || opportunitydetails?.oppodetails?.opportunity_stage === "ClosedNoAction" ? <></>:<>   
                                                        <Tooltip title="Assign Opportunity" placement="top-start">
                                                            <IconButton onClick={(checkAdminRole && permissionsDetails?.perm_assign_edit) || (permissionsDetails?.perm_assign_edit && findIds(opportunitydetails?.oppodetails?.assigned_to?.id)) ? handleAssign :''}>
                                                                <AssignIcon /> 
                                                            </IconButton> 
                                                        </Tooltip>              
                                                    </>}
                                                    </>:<></>}
                                                </span> 
                                            </T5Normaltext>
                                        </CardInfo>

                                        <CardInfo sx={{pl: 1, pr: 2, pb: 0.2}}>
                                            {opportunitydetails?.oppodetails?.opportunity_type === "Upgrade" || opportunitydetails?.oppodetails?.opportunity_type === "Shifting" || opportunitydetails?.oppodetails?.opportunity_type === "Downgrade" ? 
                                                <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                    Service ID
                                                </H7TablePrimeContent>:
                                                <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                    Lead
                                                </H7TablePrimeContent>}
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Opportunity Name
                                            </H7TablePrimeContent>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                            {opportunitydetails?.oppodetails?.opportunity_type === "Upgrade" || opportunitydetails?.oppodetails?.opportunity_type === "Shifting" || opportunitydetails?.oppodetails?.opportunity_type === "Downgrade" ? 
                                                <T5Normaltext sx={{ flex: 1, fontWeight: 600, color: themeColor.palette.LeadID, "&:hover": { cursor:!permissionsDetails?.perm_service_view ? 'default' :'pointer'}}} onClick={()=> !permissionsDetails?.perm_service_view ? '' :handleChangedServiceDetailsPage()}>
                                                {opportunitydetails?.oppodetails?.servicedetails?.service_id}
                                            </T5Normaltext>:
                                            <T5Normaltext sx={{ flex: 1, fontWeight: 600, color: themeColor.palette.LeadID, "&:hover": { cursor:!permissionsDetails?.perm_lead_view ? 'default' :'pointer'}}} onClick={()=> !permissionsDetails?.perm_lead_view ? '' :handleChnagedOpenLeadDetailsPage()}>
                                                {opportunitydetails?.leaddetails?.lead_no}
                                            </T5Normaltext>}
                                        
                                            <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2,
                                                  whiteSpace: 'pre-line', 
                                                  wordBreak: 'break-word', 
                                                  maxWidth: 'auto',
                                                }}>
                                                {opportunitydetails?.oppodetails?.opportunity_name}
                                            </T5Normaltext>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                            {/* <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Order
                                            </H7TablePrimeContent> */}
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Order Type
                                            </H7TablePrimeContent>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Service Provider Opportunity ID
                                            </H7TablePrimeContent>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.opportunity_type ? opportunitydetails?.oppodetails?.opportunity_type : '-' }
                                            </T5Normaltext>
                                            {/* <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.order_type}
                                            </T5Normaltext> */}
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.service_opportunityid ? opportunitydetails?.oppodetails?.service_opportunityid : '-'}
                                            </T5Normaltext>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Exp. Date of Closer
                                            </H7TablePrimeContent>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Line of Business
                                            </H7TablePrimeContent>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {moment(opportunitydetails?.oppodetails?.closure_date).format('DD/MM/YYYY')}
                                            </T5Normaltext>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.lineofbusiness}
                                            </T5Normaltext>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Service Provider
                                            </H7TablePrimeContent>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Product Family
                                            </H7TablePrimeContent>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.service_provider ? opportunitydetails?.oppodetails?.service_provider : '-'}
                                            </T5Normaltext>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.product_family}
                                            </T5Normaltext>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                Product
                                            </H7TablePrimeContent>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                                expected MRC
                                            </H7TablePrimeContent>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.product}
                                            </T5Normaltext>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.expected_mrc}
                                            </T5Normaltext>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                                            expected NRC
                                            </H7TablePrimeContent>
                                        </CardInfo>

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                                {opportunitydetails?.oppodetails?.expected_nrc}
                                            </T5Normaltext>
                                        </CardInfo>
                                        

                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                            <H7TablePrimeContent style={{ flex: 1, whiteSpace: 'pre-line', wordBreak: 'break-all', color: themeColor.palette.InputLabel }}>
                                                Address (<span style={{ color: '#008179', fontSize: '12px', }}>{opportunitydetails?.oppodetails?.branch?.register_address == true ? "Registered Office Address" : "" || opportunitydetails?.oppodetails?.branch?.head_office == true ? "Head Office Address" : '' ||
                                                    (opportunitydetails?.oppodetails?.branch?.register_address == false && opportunitydetails?.oppodetails?.branch?.head_office == false) ? "Branch Office Address" : ''}</span>)
                                            </H7TablePrimeContent>
                                        </CardInfo>
                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                                                {opportunitydetails?.oppodetails?.branch?.address} {opportunitydetails?.oppodetails?.branch?.city} {opportunitydetails?.oppodetails?.branch?.state} {opportunitydetails?.oppodetails?.branch?.country} {opportunitydetails?.oppodetails?.branch?.pincode}
                                            </T5Normaltext>
                                        </CardInfo>

                                        <CardInfo s
                                        x={{ pl: 1, pr: 2, pb: 0.2 }}>
                                            <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel, marginLeft: '2px' }}>
                                                Opportunity Summary
                                            </H7TablePrimeContent>
                                        </CardInfo>
                                        <CardInfo sx={{ pl: 1, pr: 2, pb: 1 }}>
                                            <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                                                {opportunitydetails?.oppodetails?.summary}
                                            </T5Normaltext>
                                        </CardInfo>
                                    </ChildCard>
                                </Box>
                            {/* stage?.status == 'Pending' && (stage?.for_stage == 'Qualified' || stage?.for_stage == 'Feasible' || stage?.for_stage == 'Solution' || stage?.for_stage == 'Commercials' || stage?.for_stage == 'Proposal'
                    || stage?.for_stage == 'NegotiationCommercials' || stage?.for_stage == 'NegotiationProposal' || stage?.for_stage == 'VerbalConfirmation' || stage?.for_stage == 'ClosedWon' || stage?.for_stage == 'COF' || stage?.for_stage == 'Service' */}
                                <Box sx={{ backgroundColor:'#FFFFFF', mt:2, mb:2, mr:{xs:1, sm:1, xl:1, lg:1}, ml:{xs:1, sm:1, xl:1, lg:1}}}>    
                                {(opportunitydetails?.oppodetails?.opportunity_stage === 'ClosedLost' ||  opportunitydetails?.oppodetails?.opportunity_stage ==="ClosedNoAction" || opportunitydetails?.oppodetails?.opportunity_stage === 'ClosedWon') ? <></> :
                                 <>
                                 {(checkAdminRole && permissionsDetails?.perm_approve_edit) || (!permissionsDetails.perm_approve_edit && (user?.data?.user?.id === opportunitydetails?.oppodetails?.assigned_to?.id)) || (permissionsDetails.perm_approve_edit && (user?.data?.user?.id === opportunitydetails?.oppodetails?.assigned_to?.id)) ? 
                                 <Grid sx={{ display: 'flex', mt:2, ml:1, justifyContent: 'flex-start' }} item xs={4}>
                                    {(approveClosedLost) ? <></>:<><CustemBtn sx={{ mt:1, mb:1, 
                                        fontSize: '12px',padding: '1px 12px', alignItems: 'center',gap: '2px', fontWeight: 600, backgroundColor: '#FFFFFF', color: '#E70707', border: '1px solid #E70707', borderRadius: '5px',
                                        "&:hover": { backgroundColor: "#FFFFFF" }
                                    }}
                                        disabled={closedLost} variant='contained' text="Closed Lost" onClick={handleChangedClosedLost}/>
                                    </>}
                                </Grid> :<></>}
                                </>}
                            
                                {closedLost ? <>
                                    <T5Normaltext sx={{ flex: 1, ml:2, color: themeColor.palette.primarytext2}}>
                                            Are Sure, Do You Want to Closed Lost ?
                                    </T5Normaltext>
                                    <ActionButtons sx={{ml:2, mr:2}}>
                                        <ApproveBtn onClick = {handleChangeApproveClosedLost} sx={{ mb:1, color: themeColor.palette.BrandColor, ":hover":{cursor:'pointer'}}}>
                                            Yes
                                        </ApproveBtn >
                                        <RejectBtn onClick= {handleChangedCancleClosedLost} sx={{ mb:1, color: themeColor.palette.PrimaryRed, ":hover":{cursor:'pointer'}}}>
                                            No
                                        </RejectBtn>
                                    </ActionButtons>
                                </> :<></>
                                }

                                {approveClosedLost ? 
                                    <Box sx={{mt:2, mr:{xs:1, sm:1, xl:1, lg:1}, ml:{xs:1, sm:1, xl:1, lg:1}}}>
                                    <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 2}}> 
                                        <Grid item xs={10}>
                                            <T1ButtonText sx={{color: themeColor.palette.Primarytext1}}>
                                                Closed Lost Remark
                                            </T1ButtonText>
                                        </Grid>
                                        <Grid item xs={12} md={6} sm={6} lg={6}>
                                            <H7TablePrimeContent style={{paddingtTop:'0px',color: themeColor.palette.InputLabel}}>Reasons<span style={{ color: 'red' }}>*</span></H7TablePrimeContent>
                                            <SelectDropDown
                                                // disabled={state.for === 'tasklist'}
                                                placeholder='Select Field'
                                                name='lost_reason'
                                                options={LostReasons}
                                                onchange={handleChangeLostOpportunity}
                                                value={statusLost?.lost_reason}
                                                sx={{ p: 0.1, pl: 1, mt:0.5, backgroundColor: '#ffffff', borderRadius: "10px" }}
                                            />
                                            <DisplayError error={error} fortagid='lost_reason' />
                                        </Grid>
                
                                        <Grid item xs={12} md={12} sm={12} lg={12} sx={{mr:1}}>   
                                            <H7TablePrimeContent style={{color: themeColor.palette.InputLabel, marginBottom:'5px'}}>Mention Reason in Detail<span style={{ color: 'red' }}>*</span></H7TablePrimeContent> 
                                            <TextArea name='detailreason' value={statusLost?.detailreason?.replace(/\s\s+/g, " ")} onChange={handleChangeLostOpportunity} style={{width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '5px' }} placeholder="Enter the reason."></TextArea>
                                            <DisplayError error={error} fortagid='detailreason' />
                                            <div style={{ display:'flex', justifyContent:'end', paddingRight:'10px',marginLeft: 'auto', fontSize:'12px'}}>
                                                <span>{statusLost?.detailreason.length}/1000</span>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Box sx={{mt:2, mr:{xs:1, sm:1, xl:1, lg:1}, display:'flex', justifyContent:'end' }}>
                                        <CustemBtn variant="contained" disabled={disabledButton} onClick={SubmitLostOpportunity} sx={{borderRadius:"10px", padding: '1px 10px',
                                            alignItems: 'center', gap: '2px', mb:1, fontSize:'16px'}} text= "Save"/>
                                    </Box>
                                    </Box>
                                    
                                    :<></>
                                } 
                                </Box>
                                 
                        </Grid>

                        {/* Grid Item 2 */}
                        
                        <Grid item xs={12} md={10} sm={10} lg={4}>
                        <Grid sx={{ pl:1, mb: 1, pr:1, display: 'flex', justifyContent: 'space-between', mt:2, alignItems: 'flex-end' }}>
                                    <Grid item xs={10}>
                                        {/* Thermometer Icon */}
                                        <T1ButtonText sx={{ mb: 0.2, color: themeColor.palette.Primarytext1 }}>
                                            Opportunity Journey
                                        </T1ButtonText>
                                    </Grid>
                                    <Grid sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }} item xs={4}>
                                    {
                                           (opportunitydetails?.oppodetails?.approval_status != 'Pending' || (permissionsDetails.perm_approve_edit)) ? '' :((resettime.length > 0 ? new Date().getTime() > resettime[0].reset_time : true) &&  ((user?.data?.user?.id == opportunitydetails?.oppodetails?.assigned_to.id))) ?  <Typography sx={{pl:2, cursor:'pointer'}} onClick={() => disable ? '' : handleReminder(opportunitydetails?.oppodetails?.id, opportunitydetails?.oppodetails?.opportunity_stage)} ><ReminderIcon /></Typography> : ""
                                        }
                                        {/* <CustemBtn sx={{
                                            fontSize: '12px', mb: 0.1, padding: '1px 12px',
                                            alignItems: 'center',gap: '2px', fontWeight: 600, backgroundColor: '#4642FF', color: '#FFFFFF', border: '1px solid #4642FF', borderRadius: '10px',
                                            "&:hover": { backgroundColor: "#4642FF" , cursor:'default'}
                                        }}
                                            variant='contained' text={opportunitydetails?.oppodetails?.opportunity_stage} /> */}
                                    </Grid>
                                </Grid>
                            <CardBody sx={{padding:'20px', ml: { xs: 1, sm: 1, xl: 0, lg: 0 }, mr: { xs: 1, sm: 1, xl: 0, lg: 0 } }}>
                             
                                <OpportunityJourney ids = {opportunitydetails?.all_ids}  opp_stage={opportunitydetails?.oppodetails} stage_data={opportunitydetails?.oppo_stages} />

                            </CardBody>
                        </Grid>
                        <Grid item xs={12} md={10} sm={10} lg={4}>
                            <Box sx={{ p: '22px 5px', mt: 0, ml: { xs: 1, sm: 1, xl: 0, lg: 0 }, mr: { xs: 1, sm: 1, xl: 2, lg: 2 } }}>
                                <TaskDetails ids = {opportunitydetails?.all_ids} tasklist = {opportunitydetails?.gettask} opportunity = {opportunitydetails?.oppodetails} />
                            </Box>
                        </Grid>
                    </Grid>      
                </LoginContainer>
            </>}

            {openOpportunityModel && <OpportunityForm title = {title} editOpportunityDetails={opportunitydetails?.oppodetails} setOpenOpportunityModel={setOpenOpportunityModel} openOpportunityModel={openOpportunityModel}/>}
            {openAssignModal && <AssignModal title='Opportunity' taskNumber={opportunitydetails?.oppodetails?.opportunity_no} taskAssignedFromUser={opportunityAssigned} setUpdate={setUpdate} update={update} openAssignModal={openAssignModal} setOpenAssignModal= {setOpenAssignModal} saveAssignedManagerOrExecutiveName={saveAssignedManagerOrExecutiveName}/>}
        </>
    )
}