import React,{useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography, Card, Grid, Box, IconButton} from '@mui/material';
import { CompanyName, CountCard, ContactCard,AddIcon, DeleteIcon, EditIcon, FollowupCard, Navbar, OpportunityCard, OpportunityIcon, FollowUpIcon, TaskIcon, LeadIcon, OrganizationDetails} from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useLocation , Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import Identification from './Identification';
import Health from './Health';
import { ProfileContact } from './ProfileContact';
import { ProfileAddress } from './ProfileAddress';
import swal from 'sweetalert';
import { enableDisableContact } from '../../Redux/Thunk/createcontact';
import { TailSpin} from 'react-loader-spinner';
import CreateCompanyForm from '../Company/CreateCompanyForm';
import AdminRole from '../../adminrole/Adminrole';

const CardBody = styled('div')({
  //  backgroundColor:'#F0EFFD',
  //  padding:'20px 10px',
   borderRadius:'10px',
})

const Item = styled('div')({
  display:'flex',
  justifyContent:'center'
})

export default function CustPrfile() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const[getCompanyDetails ,setGetCompanyDetails] = useState({})
  //  const {getCompanyDetails} = useSelector(state => state.company);
  const {refresh, updateEditCompany} = useSelector(state => state.address)
  const {updateContact, updateStatusforContact, displayCreateContact} = useSelector(state => state?.contact)
  const [displayCompanyName ,setDisplayCompanyName]= useState('')
  const [buttonStatus ,setButtonStatus] = useState(false);
  const {updateStatus} = useSelector(state=> state.contact)
  const [openModalForCompanyDetails, setOpenModalForCompanyDetails] = useState(false)
  const [loader, setLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const [openCompanyModel, setOpenCompanyModel] = useState(false)
  const [editCompanyDetails, setEditCompanyDetails] = React.useState({});
  const [update, setUpdate] = useState(false);
  // `${getCompanyDetails?.data?.company?.company_name} ${getCompanyDetails?.data?.company?.company_type}`
 
  useEffect(() => {

    fetchCompanyDetails();
    // setGetCompanyDetails({});
    // setButtonStatus(true)
  },[refresh,open, state, updateContact, updateStatus, updateEditCompany, update, updateStatusforContact, displayCreateContact])
  
  const fetchCompanyDetails = () =>{
    if(!state) {
      navigate('/dashboard')
      return
    } else {
      (open && updateStatus ) ? setLoader(false) : setLoader(true)
      dispatch(displayCompanyDetails(state)).then((res)=>{
        setGetCompanyDetails(res?.payload)
        setDisplayCompanyName(`${res.payload?.data?.company?.company_name} ${res.payload?.data?.company?.company_type}`)
        open ? setLoader(false) : setLoader(false)
        setOpen(false)
      })
    }
  }
  
  const permissionDisable =() =>{
    sweetalert("error", "You have no permissions to access this. Please contact your administrator for more information.")
  }

  const handleOpenCompanyDetails = ()=>{
    setOpenCompanyModel(true)
    setEditCompanyDetails(getCompanyDetails?.data?.company)
    // navigate('/dashboard/company',{state: {company:getCompanyDetails?.data?.company, operation:'companyProfile'}})
  }
 
  const contactStatusChangesFromProfilePage=(contact)=>{
    swal({
        buttons: {
          Cancel: {
            text: "Cancel",
            value: "Cancel",
            className: "popup-blue",
          },
          Delete: {
            text: "Ok",
            value: "Ok",
            className: "popup-gray",
          },
        },
        title: `Do you want to ${contact?.enabled === false ?"Enable" : "Disable"} ${contact?.first_name} ${contact?.last_name} ?`,
        className: "custom-modal-user",
        closeOnClickOutside: false,
      }).then((value)=>{
        if(value=='Ok'){
            dispatch(enableDisableContact({contactid: contact?.id, set_contact_status: !contact.enabled})).then((res) => {
              setOpen(true)
            })  
        }else{
          console.log("helllo")
            // fetchCompanyDetails()
        }
      })  
  }
 
  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };

  return (
    <>
     { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
        <TailSpin
          height="30"
          width="30"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        /> </Box>:<>
    <Box sx={{backgroundColor:'#F0F0F0'}}>
    <Box sx={{display:'flex', flexDirection:{xs:'column',md:'column',lg:'column'}, mt:0, ml:{xs:1,sm:1,xl:5,lg:4}, mr:{xs:1,sm:1,xl:5,lg:4}}}>
       {/* <Box sx={{flex:1.4, pr:{lg:2}}}> */}
          <CompanyName style={{borderRadius:'6px'}} label={displayCompanyName == undefined || null ? "" : displayCompanyName} companyID={displayCompanyName == undefined || null ? "" :""}/>    
       {/* </Box> */}

       <Box style={{marginTop:'15px'}}>
        <Grid container spacing={2} columns={{ xs:12, sm: 12, md: 12, lg: 12}}>
          <>
          {/* ========= Lead ========= */}
          <Grid item xs={6} sm={6} md={4} lg={3}>
          {(checkAdminRole && permissionsDetails?.perm_lead_view) || permissionsDetails?.perm_lead_view ? <>
            <Link to='/dashboard/profile/companyLeads' state ={getCompanyDetails?.data?.company} style={{textDecoration:'none'}} >
              <CountCard
                  sx={{
                    background: "#f7fdff",
                    boxShadow: '0px 4px 20px #e4e4e4',
                    borderRadius: '8px', 
                    flex: 1, pl:2.5,pr:2.5,
                    mt:{xs:1, sm:1, lg:0},pt:1, pb:1,
                    // pt:1, pb:1, pl:{xs:1,sm:1,lg:2.5, md:2.5}, pr:{xs:1,sm:1,lg:2.5,md:2.5},
                    // mt:{xs:1, sm:1, lg:0},ml:{xs:1,sm:1,xl:5}, mr:{xs:1,sm:1,xl:5},
                  }}
                  background="#9DCEFC"
                  header="Leads"
                  startName="WIP"
                  endName="Converted"
                  incompletecount={getCompanyDetails?.data?.companydetailscount?.countofinprocesslead}
                  completecount={getCompanyDetails?.data?.companydetailscount?.countofconvertedlead}
                  icon={<LeadIcon height={40} width={40} />}
              />
            </Link>
            </>: <>
            <Item onClick={permissionDisable}> 
              <CountCard
                  sx={{
                    background: "#f7fdff",
                    boxShadow: '0px 4px 20px #e4e4e4',
                    borderRadius: '8px', 
                    flex: 1, pl:2.5,pr:2.5,
                    mt:{xs:1, sm:1, lg:0},pt:1, pb:1,
                    // pt:1, pb:1, pl:{xs:1,sm:1,lg:2.5, md:2.5}, pr:{xs:1,sm:1,lg:2.5,md:2.5},
                    // mt:{xs:1, sm:1, lg:0},ml:{xs:1,sm:1,xl:5}, mr:{xs:1,sm:1,xl:5},
                  }}
                  background="#9DCEFC"
                  header="Leads"
                  startName="Inprocess"
                  endName="Converted"
                  incompletecount={0}
                  completecount={0}
                  icon={<LeadIcon height={40} width={40} />}
                />
            </Item>
            </>}
          </Grid>
          
          {/* ========= Followup List ========= */}
          {/* <Grid item xs={6} sm={6} md={4} lg={3} >
          {permissionsDetails?.perm_followup_view ? <>
            <Link to='/dashboard/profile/companyFollowup' state = {getCompanyDetails?.data?.company} style={{textDecoration:'none'}} >
              <CountCard
                  sx={{
                    background: "#f7fdff",
                    boxShadow: '0px 4px 20px #e4e4e4',
                    borderRadius: '8px', 
                    flex: 1,
                    pl:2.5,pr:2.5,pt:1, pb:1,
                    mt:{xs:1, sm:1, lg:0}
                  }}
                  startName="Open"
                  endName="Closed"
                  background="#FBFF41"
                  header="Follow-Up"
                  incompletecount={getCompanyDetails?.data?.companydetailscount?.countofpendingfollowup}
                  completecount={getCompanyDetails?.data?.companydetailscount?.countofcompletedfollowup}
                  icon = {<FollowUpIcon height={40} width={40} />}
              />
            </Link>
            </> :<>
            <Item onClick={permissionDisable}> 
              <CountCard
                    sx={{
                      background: "#f7fdff",
                      boxShadow: '0px 4px 20px #e4e4e4',
                      borderRadius: '8px', 
                      flex: 1,
                      pl:2.5,pr:2.5,pt:1, pb:1,
                      mt:{xs:1, sm:1, lg:0}
                    }}
                    startName="Open"
                    endName="Closed"
                    background="#FBFF41"
                    header="Follow-Up"
                    incompletecount={0}
                    completecount={0}
                    icon = {<FollowUpIcon height={40} width={40} />}
                />
            </Item>
            </>}
          </Grid> */}
          
           
          {/* ========= task List ========= */}
          <Grid item xs={6} sm={6} md={4} lg={3} >
          {(checkAdminRole && permissionsDetails?.perm_task_view) || permissionsDetails?.perm_task_view ? <>
            <Link to='/dashboard/profile/companyTask' state ={getCompanyDetails?.data?.company} style={{textDecoration:'none'}} >
                <CountCard
                  sx={{
                    background: "#f7fdff",
                    boxShadow: '0px 4px 20px #e4e4e4',
                    borderRadius: '8px', 
                    flex: 1,
                    pl:2.5,pr:2.5,pt:1, pb:1,
                    mt:{xs:1, sm:1, lg:0}
                  }}
                  startName="Open"
                  endName="Closed"
                  background="#FBFF41"
                  header="Tasks"
                  incompletecount={getCompanyDetails?.data?.companydetailscount?.countoftaskpending}
                  completecount={getCompanyDetails?.data?.companydetailscount?.countoftaskcompleted}
                  icon = { <TaskIcon height={40} width={40} /> }
                />
            </Link>
            </> :<>
            <Item onClick={permissionDisable}> 
              <CountCard
                  sx={{
                    background: "#f7fdff",
                    boxShadow: '0px 4px 20px #e4e4e4',
                    borderRadius: '8px', 
                    flex: 1,
                    pl:2.5,pr:2.5,pt:1, pb:1,
                    mt:{xs:1, sm:1, lg:0}
                  }}
                  startName="Open"
                  endName="Closed"
                  background="#FBFF41"
                  header="Tasks"
                  incompletecount={0}
                  completecount={0}
                  icon = { <TaskIcon height={40} width={40} /> }
              />
            </Item>
            </>}
          </Grid>

 
          {/* ========= opportunity List ========= */}
          <Grid item xs={4} sm={6} md={4} lg={3} >
          {(checkAdminRole && permissionsDetails?.perm_opportunity_view) || permissionsDetails?.perm_opportunity_view ?<>
            <Link to='/dashboard/profile/companyOpportunity' state ={getCompanyDetails?.data?.company} style={{textDecoration:'none'}}>
              <CountCard
                 sx={{
                  background: "#f7fdff",
                    boxShadow: '0px 4px 20px #e4e4e4',
                    borderRadius: '8px', 
                    flex: 1,
                    pl:2.5,pr:2.5,pt:1, pb:1,
                    mt:{xs:1, sm:1, lg:0}
                }}
                startName="Won"
                open="Open"
                openoppo ={getCompanyDetails?.data?.companydetailscount?.countofopenoppo}
                endName="Lost"
                background="#9DCEFC"
                header="Opportunities"
                incompletecount={getCompanyDetails?.data?.companydetailscount?.countofclosedwonoppo}
                completecount={getCompanyDetails?.data?.companydetailscount?.countofclosedlostoppo}
                icon = {<OpportunityIcon height={40} width={40} />}
              />
            </Link>
            </> :<>
            <Item onClick={permissionDisable}> 
            <CountCard
                 sx={{
                  background: "#f7fdff",
                  boxShadow: '0px 4px 20px #e4e4e4',
                  borderRadius: '8px', 
                  flex: 1,
                  pl:2.5,pr:2.5,pt:1, pb:1,
                  mt:{xs:1, sm:1, lg:0}
                }}
                startName="Closed Won"
                endName="Closed Lost"
                background="#9DCEFC"
                header="Opportunities"
                incompletecount={0}
                completecount={0}
                icon = {<OpportunityIcon height={40} width={40} />}
              />
            </Item>
            </>}
          </Grid>
       </>
        {/* ))} */}
        </Grid>
       </Box>
       </Box>

    {/* ============ Company Details ============= */}  
    <CardBody sx={{mt:2, ml:{xs:1, sm:1, xl:4, lg:4}, mr:{xs:1,sm:1,xl:4, lg:4}, display:'grid'}}>
      <Grid sx={{ borderBottom:'1px solid #e4e4e4',pb:1,display:"flex",justifyContent:'space-between',alignItems:'flex-end'}}>
        <Grid item xs={10}>
          <Typography variant='h6' sx={{fontSize:{sm:"20px",md:'24px',xl:'24px'}, fontWeight:600, letterSpacing:'0.04rem'}}>Company Details</Typography>
        </Grid>

        {(checkAdminRole && permissionsDetails?.perm_company_edit) || permissionsDetails?.perm_company_edit ? <IconButton onClick={handleOpenCompanyDetails} >
          <EditIcon w={24} h={24} />
        </IconButton> :<></>}
      </Grid>

      {(checkAdminRole && permissionsDetails?.perm_company_view) || permissionsDetails?.perm_company_view ?<>
      <Box sx={{ flexGrow: 1, mt:2}}>
        <Grid container spacing={{ xs:2, md:3 }} columns={{ xs: 2, sm: 2, md: 8, lg: 12 }}>
          {/* {Array.from(Array(3)).map((_, index) => ( */}
            <Grid item xs={2} sm={4} md={4}>
                <OrganizationDetails getCompanyDetails={getCompanyDetails}/>   
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
                <Identification getCompanyDetails={getCompanyDetails}/>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
                <Health getCompanyDetails={getCompanyDetails}/>
            </Grid>
          {/* ))} */}
        </Grid>
      </Box>

      <Grid sx={{ borderBottom:'1px solid #e4e4e4', p:1,mt:3, borderRadius:'10px', display:"column", backgroundColor:'#ffffff'}}>
        <Grid item xs={10}>
          <Typography variant='h6' sx={{fontSize:{sm:"16px",md:'20px',xl:'20px'}, ml:1, letterSpacing:'0.04rem',fontWeight:600, borderBottom:'1px solid #EFEFEF'}}>Company Summary</Typography>
        </Grid>
        <Grid>
          <Typography sx={{ color: "#3107AA", fontSize: "16px", padding: '5px 0px', whiteSpace: 'pre-line', wordBreak:'break-all'}} >{getCompanyDetails?.data?.company?.company_summary}</Typography>
      </Grid>
      </Grid> </>:<><Typography style={{color:'#f75d4f', margin:'10px'}}>You have no permissions to access this. Please contact your administrator for more information.</Typography></>}
    </CardBody>

{/*  ==========  Address deatils and contact details ============= */}
    {/* <Card sx={{mt:3, ml:{xs:1,sm:1,xl:5,lg:4}, mr:{xs:1,sm:1,xl:5,lg:4}}}> */}
      <Box sx={{ flexGrow: 1, mt:3, ml:{xs:1,sm:1,xl:5,lg:4}, mr:{xs:1,sm:1,xl:5,lg:4} }}>
        <Grid container spacing={{xs: 2, md: 3}} columns={{ xs: 2, sm: 2, md:12, lg:12}}>
            <Grid item xs={2} sm={4} md={6}>
              <ProfileAddress addresses ={getCompanyDetails} permissionsDetails={permissionsDetails}/>
            </Grid>
            <Grid item xs={2} sm={4} md={6}>
                <ProfileContact contacts ={getCompanyDetails} permissionsDetails={permissionsDetails} contactStatusChangesFromProfilePage={contactStatusChangesFromProfilePage}/>
            </Grid>
        </Grid>
      </Box>
    {/* </Card> */}
    </Box>
   </>}

    {/* ===========  create company ======  */} 
    {openCompanyModel && <CreateCompanyForm openCompanyModel ={openCompanyModel} setOpenCompanyModel={setOpenCompanyModel}  
      setUpdate={setUpdate} update={update} setEditCompanyDetails={setEditCompanyDetails} editCompanyDetails={editCompanyDetails} title='companyProfile' />}
    </>
  );
}
