
const BillingFrequency = [
    {
        value: 'Hourly',
        label: 'Hourly',
    },
    {
        value: 'Weekly',
        label: 'Weekly',
    },
    {
        value: 'Fortnightly',
        label: 'Fortnightly',
    },
    {
        value: 'Monthly',
        label: 'Monthly',
    },
    {
        value: 'Quarterly',
        label: 'Quarterly',
    }, 
    {
        value: 'Half-Yearly',
        label: 'Half-Yearly',
    },
    {
        value: 'Annually',
        label: 'Annually',
    }, 
]

const Business = [
    {
        value: 'IT Infrastructure',
        label: 'IT Infrastructure',
    },
    {
        value: 'Software',
        label: 'Software',
    },
]

const OpportunityStage = [
    {
        value: 'IdentifiedOpportunity',
        label: 'Identified Opportunity',
    },
    {
        value: 'QualifiedOpportunity',
        label: 'Qualified Opportunity',
    },
    {
        value: 'Solution',
        label: 'Solution',
    },
    {
        value: 'Commercials',
        label: 'Commercials',
    },
    {
        value: 'ProposalSent',
        label: 'Proposal Sent',
    }, 
    {
        value: 'Negotiation',
        label: 'Negotiation',
    },
    {
        value: 'VerbalConfirmation',
        label: 'Verbal Confirmation',
    }, 
    {
        value: 'ClosedWon',
        label: 'Closed - Won',
    },
    {
        value: 'ClosedLost',
        label: 'Closed - Lost',
    }, 
]

const OpportunityType = [
    {
        value: 'BusinessFromExistingAccount',
        label: 'Business From Existing Account',
    },
    {
        value: 'BusinessFromNewAccount',
        label: 'Business From New Account',
    },
]

const Order = [
    {
        value: 'New',
        label: 'New',
    },
    {
        value: 'Modified',
        label: 'Modified',
    },
    {
        value: 'Upgrade',
        label: 'Upgrade',
    },
    {
        value: 'Downgrade',
        label: 'Downgrade',
    },
]

const OrderNew = [
    {
        value: 'New Service',
        label: 'New Service',
    }
    
]

const OrderType = [
    {
        value: 'Upgrade',
        label: 'Upgrade',
    },
    {
        value: 'Downgrade',
        label: 'Downgrade',
    },
    {
        value: 'Shifting',
        label: 'Shifting',
    },
]

const BillType= [
    {
        value: 'Advance',
        label: 'Advance',
    },
    {
        value: 'Arrears',
        label: 'Arrears',
    },
]

const BillBy = [
    {
        value: 'Mobigic',
        label: 'Mobigic',
    },
    {
        value: 'Others',
        label: 'Others',
    },
]

const Months = [
    {
        value: 1,
        label: 1,
    },
    {
        value: 2,
        label: 2,
    },
    {
        value: 3,
        label: 3,
    },
    {
        value: 4,
        label: 4,
    },
    {
        value: 8,
        label: 8,
    },
    {
        value: 12,
        label: 12,
    },
    {
        value: 18,
        label: 18,
    },
    {
        value: 24,
        label: 24,
    },
    {
        value: 30,
        label: 30,
    },
    {
        value: 36,
        label: 36,
    },
]

const StatusList =[
    {
        value: 'Identified Opportunity',
        label: 'Identified Opportunity',
    },
    {
        value: 'Qualified Opportunity',
        label: 'Qualified Opportunity',
    },
    {
        value: 'Solution',
        label: 'Solution',
    },
    {
        value: 'Commercials',
        label: 'Commercials',
    },
    {
        value: 'Proposal Sent',
        label: 'Proposal Sent',
    },
    {
        value: 'Negotiation',
        label: 'Negotiation',
    },
    {
        value: 'Verbal Confirmation',
        label: 'Verbal Confirmation',
    },
    {
        value: 'Closed-Won',
        label: 'Closed - Won',
    },
    {
        value: 'Closed-Lost',
        label: 'Closed - Lost',
    },
   
]

const LostReasons =[
    {
        value: 'High Price',
        label: 'High Price',
    },
    {
        value: 'Proposed Solution not acceptable',
        label: 'Proposed Solution not acceptable',
    },
    {
        value: 'No Feasibility',
        label: 'No Feasibility',
    },
    {
        value: 'Not Happy with Company',
        label: 'Not Happy with Company',
    },
    {
        value: 'Not Happy with Partner',
        label: 'Not Happy with Partner',
    },
    {
        value: 'Delay',
        label: 'Delay',
    },
    {
        value: 'No Activity',
        label: 'No Activity',
    },
    {
        value: 'Others',
        label: 'Others',
    },
]

export {LostReasons,Order, BillBy, OrderNew, Months, StatusList, BillType, OrderType, OpportunityType, OpportunityStage, Business, BillingFrequency} 