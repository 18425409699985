const headCells = [
    {
      id: 'Lead ID',
      numeric: false,
      disablePadding: false,
      label:'Lead ID',
    },
    {
      id: 'Contact Name',
      numeric: false,
      disablePadding: false,
      label: 'Contact Name',
    }, 
    // {
    //   id: 'Lead Name',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Lead Name',
    // },
    // {
    //   id: 'Lead Source',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Lead Source',
    // },
    {
      id: 'Created By',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
     
    {
      id: 'Assigned to',
      numeric: false,
      disablePadding: false,
      label: 'Assigned to',
    },
    {
      id: 'Service Provider',
      numeric: false,
      disablePadding: false,
      label: 'Service Provider',
    }, 
    {
      id: 'Product',
      numeric: false,
      disablePadding: false,
      label: 'Product',   
    },  
    {
      id: 'Date of Creation',
      numeric: false,
      disablePadding: false,
      label: 'Date of Creation',   
    },
    {
      id: 'Status',
      numeric: false,
      disablePadding: false,
      label: 'Status',   
    },
    // {
    //   id: 'Assign',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Assign',   
    // },
  ];
  
    export { headCells }