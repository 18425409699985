import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import {Box, Checkbox, FormControlLabel, Grid} from "@mui/material"
import { ComapanyName, CompanyName, DatePicker, DropDown, InputBox, SelectDropDown } from '../../components/custom';
import CustemBtn from '../../components/custom/CustemBtn'
import SwitchBtn from '../../components/custom/Switch';
import TextArea from '../../components/custom/TextArea';
import dayjs from 'dayjs';
import validate from '../../validation/validation';
import { Country, State, City }  from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import { createCompanyAddress, editCompanyAddress, pinCodeData} from '../../Redux/Thunk/createcompanyaddressthunk';
import swal from 'sweetalert'
import DisplayError from '../../validation/DisplayError';
import { displayCompanyDetails, getAllCompanyName } from '../../Redux/Thunk/createcompanythunk';
import { useNavigate } from 'react-router-dom';
import ContactForm from '../ContactForm/ContactForm';
// import { state_code_data } from './statecode';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import { main_search } from '../../Redux/Thunk/dashboardthunk';
import displayname from '../../displaycompanyname/displaycompany';
import DialogHeaderCompanyName from '../../components/custom/DialogHeaderCompanyName';
import {H1FormTitle, T6TableText, H5FormLabel} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import AdminRole from '../../adminrole/Adminrole';
import { storeCompayName, storeAddressDetails} from '../../Redux/slices/createCompanySlice';

let state_code_data = [
  {
      'AN' : '35'
  },
  {
      'AP': '28'
  }, 
  {
      'AR':'12'
  }, 
  {
      'AS':'18'
  },
  {
      'BR' : '10'
  },
  {
      'CH': '04'
  }, 
  {
      'DH':'26'
  }, 
  {
      'DL': '07'
  },
  {
      'GA' : '30'
  },
  {
      'GJ': '24'
  }, 
  {
      'HR':'06'
  }, 
  {
      'HP':'02'
  },
  {
      'JK' : '01'
  },
  {
      'JH': '20'
  }, 
  {
      'KA':'29'
  }, 
  {
      'KL': '32'
  },
  {
      'LA': '01'
  }, 
  {
      'LD':'31'
  }, 
  {
      'MP': '23'
  },
  {
      'MH': '27'
  },
  {
      'MN': '14'
  },
  {
      'ML': '17'
  },
  {
      'MZ': '15'
  },
  {
      'NL': '13'
  },
  {
      'OR': '21'
  },
  {
      'PY': '34'
  },
  {
      'PB': '03'
  },
  {
      'RJ': '08'
  },
  {
      'SK': '11'
  },
  {
      'TN': '33'
  },
  {
      'TG': '36'
  },
  {
      'TR': '16'
  },
  {
      'UT': '05'
  },
  {
      'UP': '09'
  },
  {
      'WB': '19'
  }, 
  {
      'CT' :'22'
  }
]

const useStyles = makeStyles((theme) => ({
  typography:{
    fontSize:'14px',
    color:'#A3A3A3'
  }
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const CardBody = styled('div')({
  backgroundColor:'#F0EFFD',
  padding:'10px 20px 40px',
  borderRadius:'10px',
})

// function BootstrapDialogTitle(props) {
//   const { children, onClose, title, editAddress, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: 'absolute',
//             display:'flex', justifyContent:'center', 
//             right: 10,
//             // top: 11,
//             color: ((title === "CompanyProfilePage" || title === "Create Company") && Object.keys(editAddress).length === 0) ? '#FFFFFF':Object.keys(editAddress).length === 0 ?'#000000' : '#FFFFFF' 
//           }}
//           // sx={{
//           //   position: 'absolute',
//           //   right: 8,
//           //   top: 8,
//           //   color: (theme) => theme.palette.grey[500],
//           // }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }

// BootstrapDialogTitle.propTypes = {
//   children: PropTypes.node,
//   onClose: PropTypes.func.isRequired,
// };


export default function CreateAddressPopup({title, setOpenAddressModel, openAddressModel, setAddress, openAddressTypeModel, setOpenAddressTypeModel, addressType, dropdownValues, companyDetails, setEditCompanyDetails, open, setOpen, editCompanyDetails, address }) {  
  const classes = useStyles();
  const navigate =useNavigate()
  const dispatch = useDispatch()
  const {user} = useSelector(state => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const [value, setValue] = React.useState(Object.keys(editCompanyDetails)?.length === 0 || editCompanyDetails?.waiver_date === 0  ? dayjs(new Date()) : editCompanyDetails?.waiver_date );
  const [error, setError] = useState([]);
  const [disableButton, setDisableButton] = useState(false)
  const [allCompanyName, setallCompanyName] = useState([])
  const [searchinput, setSearchInput] = useState('') 
  const [companyName, setCompanyName]= useState(title === "CreateFromDashboard" ? '' : dropdownValues?.id)
  const [createdAddressDetails, setCreateAddressDetails] = useState({})
  const [openContactModel, setOpenContactModel]= useState(false)
  const [editContactDetails, setEditContactDetails] = useState({})
  const [companypan, setCompanyPan] = useState('')
  const [checkedForHeadOffice, setCheckedForHeadOffice] = useState(false);
  const [flag, setFlag] = useState(false)
  const [country, setCountry] = useState(false)
  const route = "Create Address"
  const wordsToRemove = ["TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"];
  const [disablefield, setDisableField] = useState(false)
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const [addressDetails, setAddressDetails] = useState({
    address:'' ,
    gstno: '' ,
    phone:'' ,
    email:'' ,
    country:'IN',
    state: '' ,
    city: '' ,
    pincode: '' ,
    sez:'' ,
    gst_waiver:'',
  })

  useEffect(() => {
    if(searchinput.length > 1) {
     let timeout_func = setTimeout(() => {
      dispatch(main_search({searchinput, selectedoption:"Company"})).then((res) => {
        setallCompanyName(res.payload.data) 
      })
    }, 1000);
    
    return () => {
      clearTimeout(timeout_func);
    };

    } else {
      setallCompanyName([]) 
    }
  }, [searchinput])
 
  console.log(companyDetails,"companyDetails")
    useEffect(()=>{
        //dispatch(getAllCompanyName());
        dispatch(displayCompanyDetails(companyDetails?.id ? companyDetails?.id :companyName)).then((res) => {
          setCompanyPan(res.payload?.data?.company)
          //setAddressDetails(res.payload?.data)
        })
    },[companyName?.id])

    useEffect(() => {
      if(flag){
        setAddressDetails((prevAddressDetails) => ({
          ...prevAddressDetails,
          city: '',
        }))
        setFlag(false)
      }

      if(country){
        setAddressDetails((prevAddressDetails) => ({
          ...prevAddressDetails,
          state: '', city:''
        }))
        setCountry(false)
      }
  
    }, [flag, country]);
   
    // useEffect(() => {
    //   if(addressDetails.pincode.length == 6) {
    //     dispatch(pinCodeData(addressDetails.pincode)).then((res) => {
    //      // console.log(res.payload[0].PostOffice, 'res')
    //      if (res.payload[0]?.PostOffice == null) {
    //       setAddressDetails({...addressDetails, 
    //         country:'IN',
    //         state:'',
    //         city:'',
    //       }) 
    //       return setDisableField(false)
    //      } else {
    //       let find_state = State.getStatesOfCountry('IN')
    //       let state_data = find_state.find((ele) => ele.name == res?.payload[0]?.PostOffice[0]?.Circle)
    //       if(res.payload[0]?.PostOffice != null) {
    //         setAddressDetails({...addressDetails, 
    //           country:'IN',
    //           state:state_data?.isoCode,
    //           city:res.payload[0].PostOffice[0]?.Block,
    //         }) 
    //         setDisableField(true)
    //       }
    //      }
    //     })
    //   } else{
    //     setAddressDetails({...addressDetails, 
    //       country:'IN',
    //       state:'',
    //       city:'',
    //     }) 
    //     setDisableField(false)
    //   }
    // }, [addressDetails.pincode])
    
  useEffect(()=>{
    if(Object.keys(editCompanyDetails).length > 0 ){
      setAddressDetails({
        address: editCompanyDetails ? editCompanyDetails?.address :'' ,
        gstno: editCompanyDetails ? editCompanyDetails?.gstno :'' ,
        phone: editCompanyDetails ? editCompanyDetails?.phone :'' ,
        email: editCompanyDetails ? editCompanyDetails?.email :'' ,
        country:editCompanyDetails ? editCompanyDetails?.country :'IN',
        state: editCompanyDetails ? editCompanyDetails?.state :'' ,
        city: editCompanyDetails ? editCompanyDetails?.city :'' ,
        pincode: editCompanyDetails ? editCompanyDetails?.pincode :'' ,
        sez: editCompanyDetails ? editCompanyDetails?.sez :'' ,
        gst_waiver: editCompanyDetails ? editCompanyDetails?.gst_waiver :'',
      })}
  },[])

  const handleClose = () => {
    // if(title === "Create Company"){
    //  navigate('/dashboard/companylist')
    // }else{
      setOpenAddressModel(false);
      setOpen(false)
      setDisableButton(false)
      setError([])
      setEditCompanyDetails({})
      setAddressDetails({
        address: '' ,
        gstno: '' ,
        phone: '' ,
        email: '' ,
        country:'IN',
        state: '' ,
        city: '' ,
        pincode: '' ,
        sez: '' ,
        gst_waiver: '' ,
      })
  
      // setChecked({
      //   registeredaddress: address?.label ==="Registered Office" || dropdownValues?.headOffice !== null ? true :false,
      //   head_office_address: address?.label ==="Head Office" || dropdownValues?.registeredAddress !== null ? true: false
      // })
    // } 
  }
  
    let state_code = ''
    state_code_data.forEach((code) => {
      if (code[addressDetails?.state]) {
        state_code = code[addressDetails?.state];
      }
    });

    // console.log(flag, state_code, addressDetails, "state_code")
  // let data = state_code_data.map((code) => {
  //   if (Object.keys(code).join('') == addressDetails?.state) {
  //      state_code.value = Object.values(code).join('')
  //   }
  // })
  
  let check_first_digit = addressDetails?.phone ? addressDetails?.phone[0] : 0
  
  let validation_input = [
    {tagid:'address', text: addressDetails?.address, required: true, regex_name:'free_text', errmsg: 'Please add the Address between 30 to 150 characters', min:30, max:150},
    // title ==="CreateFromDashboard"? {tagid:'companyName', text:companyName?.value ?? '', regex_name: 'free_text', required: true, errmsg: 'Please Select Company Name.', min:10, max: 100} :'',
    companypan?.company_type !== 'TRUST' ? !addressDetails?.gstno?.trim() && state_code ? {tagid: 'gstno', text: addressDetails?.gstno?.trim(), required: true, regex_name:'gstin', errmsg: 'Please Enter Valid GST Number'}: !addressDetails?.gstno?.trim().startsWith(state_code ?? '') ? {tagid: 'gstno', text: `${addressDetails?.gstno?.trim()} (GST No.) does not match state code (${state_code})`, required: true, regex_name:'gstin', errmsg: 'State code and GST Number Mismatch'} : {tagid: 'gstno', text: addressDetails?.gstno?.trim(), required: true, regex_name:'gstin', errmsg: 'Please Enter Valid GST Number'} : !addressDetails?.gstno?.trim() && state_code ? {tagid: 'gstno', text: addressDetails?.gstno?.trim(), regex_name:'gstin', errmsg: 'Please Enter Valid GST Number'}: !addressDetails?.gstno?.trim().startsWith(state_code ?? '') ? {tagid: 'gstno', text: `${addressDetails?.gstno?.trim()} (GST No.) does not match state code (${state_code})`, regex_name:'gstin', errmsg: 'State code and GST Number Mismatch'} :{tagid: 'gstno', text: addressDetails?.gstno?.trim(), regex_name:'gstin', errmsg: 'Please Enter Valid GST Number'},
    {tagid: 'email', text: addressDetails?.email?.trim(), regex_name: 'email', required: true, errmsg: 'Please Enter Valid Email ID.'},
    Number(check_first_digit) <= 5 ? {tagid: 'phone', text: addressDetails?.phone?.trim(), required: true, regex_name: 'exact_x_digits', errmsg: 'Please Enter Valid Mobile Number.'}:{tagid: 'phone', text: addressDetails?.phone?.trim(), required: true, regex_name: 'exact_x_digits', errmsg: 'Please Enter Valid Mobile Number.',x_count:10 },
    {tagid: 'pincode', text: addressDetails?.pincode?.trim(), regex_name: 'exact_x_digits', required: true, errmsg: 'Please Enter Valid Pincode.',x_count:6},
    {tagid: 'sez', text: addressDetails?.sez, regex_name: 'text', errmsg: 'Please Select Sez', required:true},
    // (!checked.registeredaddress && !checked.head_office_address)? {tagid: 'check', text: checked.registeredaddress?'true':'' || checked.head_office_address?'true':'' , regex_name: 'text', errmsg: 'Please Select Address Type', required:true}:'',
    // addressDetails?.sez=='Yes'? {tagid: 'gst_waiver', text: addressDetails?.gst_waiver, regex_name: 'text', errmsg: 'Please Select GST waiver', required:true}:'',
    addressDetails?.sez=="Yes" ? {tagid: 'gst_waiver', text: addressDetails?.gst_waiver, regex_name: 'text', errmsg: 'Please Select GST waiver', required:true} :<></>,
    {tagid:'state', text:addressDetails?.state ??'', regex_name: 'free_text', required: true, errmsg: 'Please Select State.', min:1, max: 100},
    {tagid:'city', text:addressDetails?.city ??'', regex_name: 'free_text', required: true, errmsg: 'Please Select City.', min:1, max: 100},
  ] 
  
  //handle Change all fields 
  const handleChangeForCreateAddress= (e)=> {
    setAddressDetails({...addressDetails, [e.target.name]:e.target.value})
  }
 
  // Handle changes to the 'state' field
  const handleStateChange = (e) => {
    const newState = e.target.value;
    setAddressDetails((prevAddressDetails) => ({
      ...prevAddressDetails,
      state: newState,
    }));
    setFlag(true)
  };

  // Handle changes to the 'city' field
  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setAddressDetails((prevAddressDetails) => ({
      ...prevAddressDetails,
      city: newCity,
    }));
  }

   // Handle changes to the 'country' field
   const handleCountryChange = (e) => {
    const newCountry = e.target.value;
    setAddressDetails((prevAddressDetails) => ({
      ...prevAddressDetails,
      country: newCountry,
    }));
    setCountry(true)
  }

  const handleChangeForSameHeadOffice = (event) => {
    setCheckedForHeadOffice(event.target.checked); 
  }; 

  const handleGSTWaiver=(newValue) =>{
    setValue(newValue?.$d)
  }

  // const changeOffice =(e) => {
  //     setChecked({...checked,[e.target.name]: e.target.checked })
  // }

  const handleAddress = () => {
     if(validate(validation_input).length === 0){     
        (addressDetails.sez=='Yes' && addressDetails.gst_waiver=='Yes')? addressDetails.waiver_date = value : addressDetails.waiver_date = 0 
        addressDetails.registeredaddress = addressType === "Registered Office" ? true: false
        addressDetails.head_office_address = (addressType === "Head Office" && checkedForHeadOffice === false) ? true: checkedForHeadOffice === true ? true :false
        addressDetails.company_id = companyDetails?.id
        setDisableButton(true)
       if((title === "CreateFromDashboard" || title === "CreateFromLead" || title === "CompanyProfilePage" || title === "Create Company")&& Object.keys(editCompanyDetails).length==0){  
          dispatch(createCompanyAddress(addressDetails)).then((res) =>{
            console.log(addressDetails,"addressDetails")
            if(res?.payload?.errormsg){
              sweetalert ('error', res.payload?.errormsg) 
              setDisableButton(false)
              setError(validate(validation_input))
              return
            }else{
              if(title === "CreateFromLead" || title === "CompanyProfilePage"){
                sweetalert ('success', res.payload.data?.msg) 
                dispatch(storeAddressDetails({addressDetails: res?.payload?.data?.address_details, title:route}))
                setOpenAddressModel(false);
                setOpen(false)
                setOpenContactModel(false)
                setError([])
                setDisableButton(false)
                setAddressDetails({
                  address: '',
                  gstno: '',
                  phone:'',
                  email: '' ,
                  country:'IN',
                  state:'',
                  city:'',
                  pincode: '',
                  sez: '',
                  gst_waiver: 0,
                })
                setEditCompanyDetails({})
                //   setChecked({
                //     registeredaddress: 'Registered Office' || address?.label ==="Registered Office"? true :false || dropdownValues?.headOffice != null ? true :false,
                //     head_office_address: 'Head Office' || address?.label ==="Head Office"? true :false || dropdownValues?.registeredAddress != null ? true: false
                // })
              }else if(title === "Create Company"){      
                swal({
                  buttons: {
                  Cancel: {
                    text: "Go To Profile",
                    value: "profile",
                    className: "popup-gray",
                  },
                  Delete: {
                    text:(checkAdminRole && permissionsDetails?.perm_contact_create) || permissionsDetails?.perm_contact_create ? "Add Contact" : 'Go To Dashboard',
                    value: "create",
                    className: "popup-blue",
                  },
                  },
                  title: `${res?.payload?.data?.msg}`,
                  className: "custom-modal",
                  closeOnClickOutside: false,
              }).then((value)=>{
                  if(value=='profile'){
                    setDisableButton(false)
                    setError([])
                    setEditCompanyDetails({})
                    navigate('/dashboard/profile',{state: companyDetails?.id})
                    setEditCompanyDetails({})
                    setDisableButton(false)
                // setOpen(false)
                    setAddressDetails({
                          address: '' ,
                          gstno: '' ,
                          phone: '' ,
                          email: '' ,
                          country:'IN',
                          state: '' ,
                          city: '' ,
                          pincode: '' ,
                          sez: '' ,
                          gst_waiver: '' ,
                    })
                  }else{
                      if((checkAdminRole && permissionsDetails?.perm_contact_create) || permissionsDetails?.perm_contact_create){
                        dispatch(storeCompayName({title:title, companyName:companyDetails, editContactDetails:editContactDetails}))
                        setOpenContactModel(true)
                        // setOpen(false)
                        // setOpenAddressModel(false) 
                        setEditCompanyDetails({})
                        setDisableButton(false)
                        setAddressDetails({
                              address: '' ,
                              gstno: '' ,
                              phone: '' ,
                              email: '' ,
                              country:'IN',
                              state: '' ,
                              city: '' ,
                              pincode: '' ,
                              sez: '' ,
                              gst_waiver: '' ,
                        })
                      }else{
                        setOpenAddressModel(false);
                        setOpen(false)
                        setOpenContactModel(false)
                      }
                  }
              }) 
              }else{
                sweetalert ('success', res?.payload?.data?.msg)
                handleClose()
              // swal({
              //   buttons: {
              //   Cancel: {
              //     text: "Go To Profile",
              //       value: "profile",
              //       className: "popup-gray",
              //   },
              //   Delete: {
              //       text: "Dashboard",
              //       value: "create",
              //       className: "popup-blue",
              //   },
              //   },
              //   title: `${res?.payload?.data}`,
              //   className: "custom-modal",
              //   closeOnClickOutside: false,
              // }).then((value)=>{
              //     if(value=='profile'){
              //       setDisableButton(false)
              //       setError([])
              //       setEditCompanyDetails({})
              //       navigate('/dashboard/profile',{state: companyDetails?.id})
              //     }else{
              //       setDisableButton(false)
              //       // setOpenAddressModel(false)
              //      navigate('/dashboard')
              //       setEditCompanyDetails({})
              //       setAddressDetails({
              //         address: '' ,
              //         gstno: '' ,
              //         phone: '' ,
              //         email: '' ,
              //         country:'IN',
              //         state: '' ,
              //         city: '' ,
              //         pincode: '' ,
              //         sez: '' ,
              //         gst_waiver: '' ,
              //       })
              //     }
              // }) 
              }
              
              dispatch(displayCompanyDetails(companyName?.id ?companyName?.id :companyDetails?.id))
              dispatch(getAllCompanyName())
            }})
       }else{ 
        addressDetails.branch_id = editCompanyDetails?.id
        dispatch(editCompanyAddress(addressDetails)).then((res) =>{ 
          if(res.payload.errormsg) {
            sweetalert('error', res.payload.errormsg)
            setDisableButton(false)
            setError(validate(validation_input))
            return
          }
          sweetalert ('success', res?.payload.data) 
          setOpenAddressModel(false);
          setError([])
          setAddressDetails({})
          setEditCompanyDetails({})
          setDisableButton(false)
        //   setChecked({
        //     registeredaddress: address?.label ==="Registered Office"? true :false || dropdownValues?.headOffice != null ? true :false,
        //     head_office_address: address?.label ==="Head Office"? true :false || dropdownValues?.registeredAddress != null ? true: false
        // })
        })
      }
      }else {
       setError(validate(validation_input))
      //  setErrorMessage(true)
     }
  }
  
  const emptyAddressDeatils =()=>{
    setError([])
    setEditCompanyDetails({})
    setAddressDetails({
      address: '' ,
      gstno: '' ,
      phone: '' ,
      email: '' ,
      country:'IN',
      state: '' ,
      city: '' ,
      pincode: '' ,
      sez: '' ,
      gst_waiver: '' ,
    })
    //  setChecked({
    //   registeredaddress: (address?.label ==="Registered Office"|| dropdownValues?.headOffice !== null )? true :false,
    //   head_office_address: (address?.label ==="Head Office" || dropdownValues?.registeredAddress !== null) ? true: false
    // })
  }
  
  const sweetalert = (variant, text) => {
       swal({
        icon: variant==='error' ? 'error' :'success',
        title: text , 
        button: "Ok!",
      }); 
  };
 
  const companyNameList = (title === "CreateFromLead")? companyDetails?.company_name:''
  const companyTypeList = (title === "CreateFromLead")? companyDetails?.company_type:''
  const remainingNames = `${companyNameList} ${companyTypeList}`?.split(' ')?.map(word => (wordsToRemove.includes(word) ? `(${word})` : word))?.join(' ');
 
  return (
    <div>
   <> 
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openAddressModel}
        fullWidth
        maxWidth="lg"
      >
        {/* Title  */}
        <DialogHeaderCompanyName label={title === "Create Company" || title === "CreateFromDashboard" ? companyDetails?.value : title === "CreateFromLead" ? remainingNames : `${companyDetails?.company_name} ${companyDetails?.company_type}`} onClose={handleClose} title={title} />
        {/* <BootstrapDialogTitle sx={{display:'flex',justifyContent:'center',fontWeight:600, p:((title === "CompanyProfilePage" || title==="Create Company") && Object.keys(editCompanyDetails)?.length === 0) ? 1 :Object.keys(editCompanyDetails)?.length === 0 ? 0: 1, m:((title === "CompanyProfilePage" || title==="Create Company") && Object.keys(editCompanyDetails)?.length === 0) ? 1: Object.keys(editCompanyDetails)?.length === 0 ? 0 :1, borderRadius:'10px', backgroundColor:(title === "CompanyProfilePage" || title==="Create Company") && Object.keys(editCompanyDetails)?.length === 0 ?'blue': Object.keys(editCompanyDetails)?.length === 0 ?'':'blue', fontWeight:600, color:'#FFFFFF', letterSpacing: "0.04em"}} id="customized-dialog-title" onClose={handleClose} title={title} editAddress={editCompanyDetails}>{remainingNames}</BootstrapDialogTitle>  */}
        {Object.keys(editCompanyDetails)?.length===0 ? 
          <Typography sx={{mt: title === "CompanyProfilePage" || title==="Create Company" ? 0: 1, pl:2, fontWeight:600, fontSize:'18px'}} onClose={handleClose}>
            Create {addressType} Address 
          </Typography> :
          <Typography sx={{pl:2, fontWeight:600, fontSize:'18px'}} onClose={handleClose}>
              Edit {addressType} Address 
          </Typography> } 

        <DialogContent dividers> 
        {/* Company Selection dropdown */}
        {/* { title ==="CreateFromDashboard" && Object.keys(editCompanyDetails).length === 0 ?<>
          <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                        <Typography>Company Name<span style={{color:'#FF0000'}}>*</span></Typography>  
                        <DropDown sx={{background:'#FFFFFF', mt:1}} 
                            name='companyName'
                            options ={displayname(allCompanyName)}
                            onChange = {(e, value, reason) => {
                                if(value === null){
                                  setCompanyName('')
                                  emptyAddressDeatils()
                                  setSearchInput('')
                                }else{
                                  setCompanyName(value)
                                }
                            }}
                            onSearch={(e) =>{
                              if(e.target.value == '') {
                                  e.preventDefault()
                                  setallCompanyName([])
                              } else {
                                  setSearchInput(e.target.value.trim())
                              }
                            }}
                            clearOnBlur={true}
                            getOptionLabel={(option) =>(option.label ? option.label :"")}
                            value = {companyName}
                            placeholder ='Select Company '
                        />
                        <DisplayError error={error} fortagid='companyName' />
              </Grid>
          </Grid> 
            </>:<>
             {Object.keys(editCompanyDetails).length > 0  && title ==="CompanyProfilePage" ? <></> :<>   
              </>}</>} */}

          {/* {Object.keys(editCompanyDetails).length > 0 && title ==="CompanyProfilePage" ?<>
            <Typography sx={{fontSize:18, fontWeight:600}}>{`${address?.label} Address`}</Typography>  
          </> :<>
            <Box style={{display:'flex', marginTop:'10px', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
              <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={6} lg={6} sx={{display:'flex',alignItems:'center'}}>
                      <Typography><SwitchBtn checked={checked?.registeredaddress === undefined ? false : (checked?.registeredaddress)} disabled={companyName==="" || companyName?.registeredAddress != null || dropdownValues?.registeredAddress != null ||dropdownValues?.registered_address != null || checked?.head_office_address || address?.label ==="Head Office"} onChange={changeOffice} name="registeredaddress"/></Typography>
                      <Typography>Registered Office Address</Typography>  
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={6} sx={{display:'flex',alignItems:'center'}}>
                      <Typography><SwitchBtn checked={checked?.head_office_address=== undefined? false : checked?.head_office_address  } disabled={companyName ==="" || companyName?.headOffice != null || dropdownValues?.headOffice != null || dropdownValues?.head_office != null || checked?.registeredaddress || address?.label ==="Registered Office"} onChange={changeOffice} name="head_office_address"/></Typography>
                      <Typography>Head Office Address</Typography>  
                  </Grid>         
              </Grid>
            </Box>
          </>} */}
          <Box style={{display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={3}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Country<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <SelectDropDown 
                            id='country'
                            name='country'
                            disabled={disablefield}
                            options= {Country.getAllCountries().map((country)=>{return {value:country.isoCode, label:country.name}})}
                            Default={addressDetails?.country}
                            onchange ={handleCountryChange}
                            placeholder='--Select Country--'
                            value={addressDetails?.country}
                            sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                          />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={3}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>State<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <SelectDropDown 
                            id='state'
                            name='state'
                            disabled={disablefield}
                            options={State.getStatesOfCountry(addressDetails?.country).map((state)=>{ return {value : state.isoCode ,label:!state.name ? '--Select City--':state.name} })}
                            onchange ={handleStateChange}
                            value={addressDetails?.state}
                            placeholder='--Select State--'
                            sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                          />
                          <DisplayError error={error} fortagid='state'/>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={3}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>City<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <SelectDropDown 
                            id='city'
                            name='city'
                            disabled={disablefield}
                            options={City.getCitiesOfState(addressDetails?.country,addressDetails?.state).map((city)=>{ return {value : city.name ,label:city.name} })}
                            onchange ={handleCityChange}
                            value={addressDetails?.city}
                            placeholder='--Select City--'
                            sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                          />
                          <DisplayError error={error} fortagid='city'/>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={3}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Pincode<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <InputBox 
                         onchange={(e)=>{
                          if(e.target.value.length <= 6){
                            handleChangeForCreateAddress(e)
                          }}}
                          onKeyPress={(e) => {
                            const keyCode = e.keyCode || e.which;
                            const keyValue = String.fromCharCode(keyCode);
                            const numericRegex = /^[0-9]*$/;
                            if (!numericRegex.test(keyValue)) {
                              e.preventDefault();
                            }
                          }}
                           value={addressDetails?.pincode} name='pincode' sx={{border:"1px solid #B0AEFF",height:'40px', background:'#FFFFFF', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="123456"/>
                        <DisplayError error={error} fortagid='pincode'/>
                    </Grid>
            </Grid>
          </Box>

          {/* adjectment of not showing background side of address model bcoz contact model is open then address model is not closed.*/}
          {openContactModel ? '' :<Box style={{marginTop:'6px', display:'flex', paddingRight:'15px', flexDirection:{sm:'column', xs:'column', lg:'row'}}}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={12} sm={12} lg={6}>
                    <H5FormLabel sx={{color: themeColor.palette.InputLabel, mt:1}}>Address<span style={{color:'#FF0000'}}>*</span></H5FormLabel> 
                    <TextArea name='address' value={addressDetails?.address?.replace(/\s\s+/g, " ")} onChange ={handleChangeForCreateAddress} placeholder="Write Address Details" style={{marginTop:'10px',width:'100%', border: "1px solid #B0AEFF", borderRadius: '8px', fontFamily: 'Montserrat', fontStyle: 'normal', height:70, paddingLeft:'10px',paddingTop:'5px'}} />
                    <DisplayError error={error} fortagid='address' />  
                    <div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                    <span>{addressDetails?.address?.length}/150</span>
                    </div> 
                </Grid>  
               
               {/* Address Preview */}
                <Grid item xs={12} md={12} sm={12} lg={6}>
                  <H5FormLabel sx={{color: themeColor.palette.InputLabel, mt:1}}>Address Preview</H5FormLabel> 
                  <Typography sx={{fontSize:'14px', whiteSpace: 'pre-line', wordBreak:'break-all'}}>{addressDetails?.address ? `${addressDetails?.address} ${addressDetails?.city ? `, ${addressDetails?.city}` :''} ${addressDetails?.state ? `, ${State.getStatesOfCountry(addressDetails?.country).find((state) =>state.isoCode === addressDetails?.state)?.name}` :''} ${addressDetails?.country ? `, ${Country.getAllCountries().find((country)=>country.isoCode === addressDetails?.country)?.name }` :''} ${addressDetails?.pincode ? `, ${addressDetails?.pincode}` :''}`:""}</Typography> 
                </Grid>   
            </Grid>
          </Box>}
          
          <Box style={{marginTop:'20px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Email<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <InputBox name='email' value={addressDetails?.email} onchange ={handleChangeForCreateAddress} sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="abc@mail.com "/>
                        <DisplayError error={error} fortagid='email' />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} lg={4}>
                      <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Mobile No<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <InputBox name='phone' value={addressDetails?.phone}
                          onchange={(e)=>{
                            if(e.target.value.length <=10)handleChangeForCreateAddress(e)
                          }} 
                          onKeyPress={(e) => {
                            const keyCode = e.keyCode || e.which;
                            const keyValue = String.fromCharCode(keyCode);
                            const numericRegex = /^[0-9]*$/;
                            if (!numericRegex.test(keyValue)) {
                              e.preventDefault();
                            }
                          }} 
                          sx={{border:"1px solid #B0AEFF", height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                          placeholder ="6789012345"
                        />
                        {/* {errorMessage && <small style={{color:'red'}}>Please Enter Phone number</small>} */}
                        <DisplayError error={error} fortagid='phone' />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} lg={4}> 
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>GST No {typeof companypan?.company_type === 'string' && companypan?.company_type !== 'TRUST' ? <span style={{color:'#FF0000'}}>*</span> : ''}{companypan?.company_pan && <span style={{fontSize:'12px', fontWeight:600}}>  (PAN No - {companypan?.company_pan})</span>} </H5FormLabel>  
                        <InputBox name='gstno' value={addressDetails?.gstno?.toUpperCase()} 
                        onchange={(e)=>{
                          if(e.target.value.length <=15)handleChangeForCreateAddress(e)
                        }}  
                        sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="06BZAHM6385P6Z2"/>
                        <DisplayError error={error} fortagid='gstno' />
                    </Grid>
            </Grid>
          </Box>
          
          {openContactModel ? '' : <Box style={{marginTop:'20px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>SEZ<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <SelectDropDown 
                            id='sez'
                            name='sez'
                            placeholder='--Select SEZ--'
                            onchange={handleChangeForCreateAddress}
                            value={addressDetails?.sez}
                            options= {[{value:'Yes',label:'Yes'},{value:'No',label:'No'}]}
                            sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                          />
                          <DisplayError error={error} fortagid='sez'/>
                    </Grid>
                
                    { addressDetails?.sez=="Yes" ? <>
                         <Grid item xs={12} md={6} sm={6} lg={4}>
                         <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>GST waiver<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                          <SelectDropDown 
                              id='gst_waiver'
                              name='gst_waiver'
                              placeholder='--Select Waiver--'
                              onchange={handleChangeForCreateAddress}
                              value={addressDetails?.gst_waiver}
                            
                              options= {[{value:'Yes',label:'Yes'},{value:'No',label:'No'}]}
                              sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                            />
                            <DisplayError error={error} fortagid='gst_waiver'/>
                      </Grid> </> :<></>}
                     
                  {
                    (()=>{
                    if(addressDetails?.sez=="Yes" && addressDetails?.gst_waiver=="Yes") {
                      return <Grid item xs={12} md={6} sm={6} lg={4}>
                      <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>GST waiver end date<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                      <DatePickerOpenTo
                        name='gst_waiver-date'
                        openTo="day"
                        // views={['year', 'month', 'day']}
                        value={value}
                        onChange={handleGSTWaiver}
                        minDate={new Date()}
                        style={{minWidth:'100%',background:'#FFFFFF',fontSize:'14px',marginTop:'8px'}}
                    />
                      </Grid>
                    }
                    })()
                  }
{/* (title === 'CompanyProfilePage' && (Object.keys(companyDetails?.head_office)?.length > 0 || companyDetails?.head_office === null)) || */}
              {((title === 'CreateFromLead' || title === 'CompanyProfilePage') && (Object.keys(editCompanyDetails)?.length === 0 && companyDetails?.head_office !== null ? Object.keys(companyDetails?.head_office)?.length > 0 : '')) || companyDetails?.headOffice != null || Object.keys(editCompanyDetails)?.length > 0 ? <></> : (addressType ==="Registered Office" && Object.keys(editCompanyDetails)?.length === 0)? <>
                  <Grid item xs={12} md={6} sm={6} lg={12}>
                    <FormControlLabel control={<Checkbox checked={checkedForHeadOffice} onChange={handleChangeForSameHeadOffice} />}
                     label="Same for Head Office Address"/>  
                  </Grid></> :<></>}
            </Grid>
            </Box>
          }
        </DialogContent>

          <DialogActions sx={{mt:1, mb:1, mr:2}}>
            <CustemBtn variant="contained" id = 'save_address' disabled={disableButton} onClick={handleAddress} sx={{borderRadius:"10px",pl:5, pr:5}} text="Save" />
          </DialogActions>
        </BootstrapDialog>
    
      {/* create contact  */}
      {openContactModel && <ContactForm open={openContactModel} setOpenModal={setOpenContactModel} title={title === "Create Company" ? title : route} setOpen ={setOpen} dropdownValues={companyDetails} editContactDetails={editContactDetails} setEditContactDetails= {setEditContactDetails} setOpenAddressModel={setOpenAddressModel} openAddressModel={openAddressModel}/>}
  </>
  {/* title={title === "Create Company" ? title : route} setOpen ={setOpen} dropdownValues={companyDetails} editContactDetails={editContactDetails} setEditContactDetails= {setEditContactDetails} setOpenAddressModel={setOpenAddressModel} openAddressModel={openAddressModel} */}
  </div>
  );
}