const LeadDetailsCompanyIcon = () => {
    return (
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#DBDBDB"/>
    <path d="M9.75 31.625H34.25" stroke="#3107AA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M23.75 31.625V12.375C23.75 12.1429 23.6578 11.9204 23.4937 11.7563C23.3296 11.5922 23.1071 11.5 22.875 11.5H12.375C12.1429 11.5 11.9204 11.5922 11.7563 11.7563C11.5922 11.9204 11.5 12.1429 11.5 12.375V31.625" stroke="#3107AA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M32.5 31.625V19.375C32.5 19.1429 32.4078 18.9204 32.2437 18.7563C32.0796 18.5922 31.8571 18.5 31.625 18.5H23.75" stroke="#3107AA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 15.875H18.5" stroke="#3107AA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.75 22.875H20.25" stroke="#3107AA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 27.25H18.5" stroke="#3107AA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.25 27.25H29" stroke="#3107AA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M27.25 22.875H29" stroke="#3107AA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
  }
  
  export default LeadDetailsCompanyIcon