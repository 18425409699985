const headCellsService = [
    {
        id: 'Service ID',
        numeric: false,
        disablePadding: true,
        label: 'Service ID',
    },
    {
        id: 'Service Provider ID',
        numeric: false,
        disablePadding: true,
        label: 'Service Provider ID',
    },
    {
        id: 'Opportunity ID',
        numeric: false,
        disablePadding: true,
        label: 'Opportunity ID',
    }, 
    {
        id: 'Service Provider',
        numeric: false,
        disablePadding: true,
        label: 'Service Provider',
    },
    {
        id: 'Product',
        numeric: false,
        disablePadding: true,
        label: 'Product',
    },
    {
        id: 'Contact Name',
        numeric: false,
        disablePadding: true,
        label: 'Contact Name',
    },
    {
        id: 'Created By',
        numeric: false,
        disablePadding: true,
        label: 'Created By',
    },
    {
        id: 'Assigned to',
        numeric: false,
        disablePadding: true,
        label: 'Assigned to',
    },
    {
        id: 'Date of Creation',
        numeric: false,
        disablePadding: true,
        label: 'Date of Creation',
    },
];

export { headCellsService }