import React,{useState, useEffect}from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import OpportunityCard from '../../components/custom/OpportunityCard'
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import { Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import { CompanyName, ContactCard, DeleteIcon, EditIcon, Navbar, TextArea } from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import AddIcon from '../../components/custom/AddIcon';
import Card from '@mui/material/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeadTaskDetails } from './LeadTaskDetails';
import { useDispatch, useSelector } from 'react-redux';
import { leadLost } from '../../Redux/Thunk/createleadthunk';
import DisplayError from '../../validation/DisplayError';
import validate from '../../validation/validation';
import swal from 'sweetalert';
import TaskDetails from './TaskDetails';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import { FollowupDetail } from './FollowupDetail';
import { TailSpin} from 'react-loader-spinner';
import {leaddetails } from '../../Redux/Thunk/createleadthunk';
import { getContactList } from '../../Redux/Thunk/createcontact';
import AdminRole from '../../adminrole/Adminrole';

const CardBody = styled('div')({
  //  backgroundColor:'#F0EFFD',
   padding:'20px',
   borderRadius:'10px',
})

const Search = styled('div')({
  display:'flex',
  padding:'0px 10px',
  borderRadius:'10px',
  justifyItems:'center',
  // border:'1px solid #B0AEFF ',
  flexDirection:'row',
  backgroundColor:'#ffffff',
  boxShadow:'0px 4px 20px #e4e4e4'
})

const ChildCard = styled('div')({
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
})

const CardInfo = styled('div')({
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'space-between',
})

const CardHeaders = styled('div')({
  display: 'flex', 
  justifyContent: 'space-between', 
  alignItems: 'center',
  borderBottom:'0.5px solid #e4e4e4',
  marginTop:'10px'
})
                 
export default function LeadDetails() {
   const {state} = useLocation()
   const {user} = useSelector((state) => state.auth)
   const permissionsDetails = user?.data?.user?.role?.permissions
   const {updateCreateEditFollowup} =useSelector((state) => state.settingList)
   const {updateEditlead} = useSelector((state)=> state.lead)
   const {updateOpportunityCreate} = useSelector((state) =>state.opportunity)
   const {createTask} = useSelector((state)=> state.task)
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [openModal, setOpenModal] = useState(false)
   const [loader, setLoader] = useState(state?.loader ?state?.loader :true)
   const [displayLeadDetails, setDisplayLeadDetails] = useState([])
   const [closingRemark, setClosingRemark] = useState('')
   const [error, setError] = useState([]);
   const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
   const title ="LeadDetailsPage"

   useEffect(()=>{
    // setLoader(true)
       dispatch(leaddetails(state?.leadDetails?.id)).then((res)=>{
        setDisplayLeadDetails(res?.payload)
        setLoader(false)
       }) 
   },[updateCreateEditFollowup, updateEditlead, createTask, state])

   let validation_input = [
    { tagid: 'summary', text: closingRemark, required: true, regex_name: 'free_text', errmsg: 'Please add the closing Remark between 100 to 2000 characters', min:100, max:2000},  //min:1, max: 300
   ]

   const submitLeadLost = () =>{
    const allTaskClosed = displayLeadDetails?.gettask?.every(obj => obj.closing_remark !='')
    if((displayLeadDetails?.getfollowup?.find((status)=> status.closed_on === 0)) && (allTaskClosed === false)){
      sweetalert('warning',`Following lead has open followup and task, Please close the followup and task then change the status Lead lost.`)
    }else if (displayLeadDetails?.getfollowup?.find((status)=> status.closed_on === 0)){
      sweetalert('warning',`Following lead has open followup, Please close the followup then change the status Lead lost.`)
    }else if (allTaskClosed === false){
      sweetalert('warning',`Following lead has open task, Please close the task then change the status Lead lost.`)
    }else{
        setOpenModal(true)
    }
   }

   const handleClose =()=>{
    setOpenModal(false)
    setError([])
   }

   const handleOfClosingRemark =(event) =>{
    setClosingRemark(event.target.value)
   }

   const handleClickEditLeadDetails = () => {
      navigate('/dashboard/lead', {state: {lead :displayLeadDetails?.leaddetails, operation:'editLead', title:title}})
      dispatch(displayCompanyDetails(displayLeadDetails?.leaddetails?.company?.id))
      getContactList(displayLeadDetails?.leaddetails?.company?.id)
   };

   const createOpportunity =() =>{
    const allTaskClosed = displayLeadDetails?.gettask?.every(obj => obj.closing_remark !='')
    if((displayLeadDetails?.getfollowup?.find((status)=> status.closed_on === 0)) && (allTaskClosed === false)){
        sweetalert('warning',`Following lead has open followup and open Task, Please close first then convert to opportunity.`)
    }else if (displayLeadDetails?.getfollowup?.find((status)=> status.closed_on === 0)){
        sweetalert('warning',`Following lead has open followup, Please close first then convert to opportunity.`)
    }else if (allTaskClosed === false){
        sweetalert('warning',`Following lead has open Task, Please close first then convert to opportunity.`)
    }else{
      navigate('/dashboard/opportunity', {state :{title:title, company:displayLeadDetails?.leaddetails?.company, leadDetails:displayLeadDetails?.leaddetails, operation:'createOpp'}})
      // navigate('/dashboard/createopportunity')
    }
   }

   const handleLeadLost = () =>{
    if(validate(validation_input).length == 0) {
        dispatch(leadLost({lead_id: displayLeadDetails?.leaddetails?.id, lost_reason:closingRemark})).then((res) =>{
        sweetalert('success', res?.payload?.data?.msg)
        setOpenModal(false)
        navigate('/dashboard/leadlist')
      })
    }else {
        setError(validate(validation_input))
     } 
   }

  const sweetalert = (variant, text) => {
    swal({
        icon: variant==='error' ? 'error' : variant==='success'? 'success' :'warning',
        title: text,
        button: "Ok!",
    });
  }
  
  const handleChangeCreateTask =()=>{
    sweetalert('error',"work is pending")
  }

  const handleChangeCreateFollowup =()=>{
    sweetalert('error',"work is pending")
  }

  return (
    <>
     { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
        <TailSpin
          height="30"
          width="30"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        /> </Box>:<>
          <CompanyName sx={{ "&:hover": {cursor:'pointer'} }} label={`${displayLeadDetails?.leaddetails?.company?.company_name} ${displayLeadDetails?.leaddetails?.company?.company_type}`} companyID={displayLeadDetails?.leaddetails?.company?.id} />
            <CardBody sx={{mt:0.5, ml:{xs:1, sm:1, xl:4, lg:4}, mr:{xs:1, sm:1, xl:4, lg:4}}}>
              <Grid sx={{ borderBottom:'1px solid #e1e1e1', mb:2, display:"flex", justifyContent:'space-between', alignItems:'flex-end'}}>
                <Grid item xs={10}>
                  <Typography variant='h6' sx={{fontSize:{sm:"20px",md:'22px',xl:'22px',lg:'22px'}, fontWeight:600}}>Lead Details</Typography>
                </Grid>
                <Grid sx={{display:'flex',alignItems:'end'}} item xs={2}>
                <Typography sx={{textAlign:'right', color:displayLeadDetails?.leaddetails?.status==='Open'? '#CBB279' : displayLeadDetails?.leaddetails?.status==='WIP'? '#0094C2':displayLeadDetails?.leaddetails?.status==='Converted'? '#038600':'#E70707' ,fontSize:'22px',pr:2}}>{displayLeadDetails?.leaddetails?.status}</Typography>
                  {displayLeadDetails?.leaddetails?.status === 'Converted' || displayLeadDetails?.leaddetails?.status === "Closed-No-Action"|| displayLeadDetails?.leaddetails?.status === 'Lost' || !permissionsDetails?.perm_lead_edit || (checkAdminRole && !permissionsDetails?.perm_lead_edit)? <></> :  <Typography sx={{textAlign:'right'}} onClick={handleClickEditLeadDetails}><EditIcon w={23} h={23} /></Typography>}
                </Grid>
              </Grid>

              <Box sx={{flexGrow: 1}}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 4,lg:4 }}>
                    <Grid item xs={2} sm={4} md={4}>
                      <Card style={{borderRadius:'10px'}}>
                      <CardHeaders>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={displayLeadDetails?.leaddetails?.type ==="Hot"? "Hot": displayLeadDetails?.leaddetails?.type ==="Cold" ? "Cold" :"Warm"} placement="top-start">
                          <IconButton>
                            <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="14" cy="14.5" r="14" fill={displayLeadDetails?.leaddetails?.type === "Hot" ? "#F16500" : displayLeadDetails?.leaddetails?.type === "Cold" ? "#B0AEFF" : "#FFC700"} />
                            </svg>
                          </IconButton>
                          </Tooltip>
                          <Typography variant='h6' sx={{ color: '#C72424', fontWeight: 600 }}>{displayLeadDetails?.leaddetails?.lead_no}</Typography>
                        </Box>
                        <Typography sx={{marginLeft: 'auto', fontSize:'16px', pr:1, color:displayLeadDetails?.leaddetails?.validated_status ==="Pending" ?'#949494' :"#038600"}}>{displayLeadDetails?.leaddetails?.validated_status ==="Pending" ?"Pending Validation" :"Validated"}</Typography>                          
                      </CardHeaders>
                        
                        <ChildCard sx={{pl:2, pr:1, pb:1}}>
                            <CardInfo sx={{marginTop:"10px"}}>
                                <Typography sx={{flex:1, fontSize:'12px',fontWeight:600}}>
                                  Lead Name
                                </Typography>
                                <Typography sx={{flex:1, fontSize:'12px',fontWeight:600}}>
                                    Contact
                                </Typography>
                                <Typography sx={{flex:1,fontSize:'12px', fontWeight:600}}>
                                  Source
                                </Typography>
                                <Typography sx={{flex:1, fontSize:'12px', fontWeight:600}}>
                                    Data Source
                                </Typography>
                            </CardInfo>
                            <CardInfo sx={{marginTop:"2px"}}>
                                <Typography sx={{flex:1,fontSize:'16px', color:'#130048'}}>
                                  {displayLeadDetails?.leaddetails?.lead_name}
                                </Typography>
                                <Typography sx={{flex:1,fontSize:'16px', color:'#130048'}}>
                                  {displayLeadDetails?.leaddetails?.contact?.first_name} {displayLeadDetails?.leaddetails?.contact?.last_name}
                                </Typography>
                                <Typography sx={{flex:1,fontSize:'16px', color:'#130048'}}>
                                  {displayLeadDetails?.leaddetails?.lead_source}
                                </Typography>
                                <Typography sx={{flex:1,fontSize:'16px', color:'#130048'}}>
                                  {displayLeadDetails?.leaddetails?.data_source}
                                </Typography>
                            </CardInfo>
                            <CardInfo sx={{mt:2}}>
                                <Typography sx={{flex:1, fontSize:'12px',fontWeight:600}}>
                                Service Provider 
                                </Typography>
                                <Typography sx={{flex:1, fontSize:'12px',fontWeight:600}}>
                                Product Family
                                </Typography>
                                <Typography sx={{flex:1, fontSize:'12px',fontWeight:600}}>
                                Product
                                </Typography>
                                <Typography sx={{flex:1,fontSize:'12px', fontWeight:600}}>
                        
                                </Typography>
                            </CardInfo>
                            <CardInfo sx={{marginBottom:"2px"}}>
                                <Typography sx={{flex:1, fontSize:'16px',flexWrap:'wrap', color:'#130048'}}>
                                  {displayLeadDetails?.leaddetails?.service_provider} 
                                </Typography>
                                <Typography sx={{flex:1, fontSize:'16px', color:'#130048'}}>
                                  {displayLeadDetails?.leaddetails?.product_family}
                                </Typography>
                                <Typography sx={{flex:1,fontSize:'16px', color:'#130048'}}>
                                  {displayLeadDetails?.leaddetails?.product}
                                </Typography>
                                <Typography sx={{flex:1,fontSize:'16px', color:'#130048'}}>     
                                </Typography>
                            </CardInfo>

                            <CardInfo sx={{mt:2}}>
                                <Typography sx={{flex:1, fontWeight:600, fontSize:'12px', whiteSpace: 'pre-line', wordBreak:'break-all'}}>
                                  Address (<span style={{color:'#008179', fontSize:'12px',}}>{displayLeadDetails?.leaddetails?.branch?.register_address == true ? "Registered Office Address" : "" ||displayLeadDetails?.leaddetails?.branch?.head_office == true? "Head Office Address" :'' || 
                                   (displayLeadDetails?.leaddetails?.branch?.register_address == false && displayLeadDetails?.leaddetails?.branch?.head_office == false ) ? "Branch Office Address" :'' }</span>)
                                </Typography>
                            </CardInfo>
                            <CardInfo sx={{marginBottom:"2px"}}>
                                <Typography sx={{flex:1, fontSize:'16px', color:'#130048', whiteSpace: 'pre-line', wordBreak:'break-all'}}>
                                  {displayLeadDetails?.leaddetails?.branch?.address}
                                </Typography>
                            </CardInfo>

                            <CardInfo sx={{mt:2}}>
                                <Typography sx={{flex:1, fontWeight:600, fontSize:'12px'}}>
                                  Lead Summary
                                </Typography>
                            </CardInfo>
                            <CardInfo sx={{marginBottom:"2px"}}>
                              <Typography sx={{flex:1, fontSize:'16px', color:'#130048', whiteSpace: 'pre-line', wordBreak:'break-all'}}>{displayLeadDetails?.leaddetails?.summary}</Typography>
                            </CardInfo>

                        </ChildCard>       
                      </Card>
                    </Grid>
                </Grid>
              </Box>

              {/* <Grid sx={{ borderBottom:'1px solid #e4e4e4', p:1,mt:3, borderRadius:'10px', display:"column",backgroundColor:'#ffffff'}}>
                <Grid item xs={10}>
                  <Typography sx={{fontWeight:600, lineHeight: 1.5, pl:1, borderBottom:'1px solid #e4e4e4'}}>Lead Summary</Typography>
                </Grid>
                <Grid>
                  <Typography sx={{flex:1, p:1, fontSize:'14px', color:'#130048', overflowWrap: 'break-word'}}>{displayLeadDetails?.leaddetails?.summary}</Typography>
                </Grid>
              </Grid> */}
            
            {/* Lead lost button */}
              {displayLeadDetails?.leaddetails?.status === 'Converted' || displayLeadDetails?.leaddetails?.status ==="Closed-No-Action" || displayLeadDetails?.leaddetails?.status === 'Lost' ? <></>:<>
              <Grid sx={{pb:1, mt:4, display:"flex", justifyContent:'space-between',alignItems:'flex-end'}}>
                <Grid item xs={8}>
                  {/* <CustemBtn sx={{borderRadius:'10px', pl:6, pr:6, backgroundColor:'#BC0000', "&:hover": {backgroundColor: '#BC0000'}}} 
                    disabled={displayLeadDetails?.leaddetails?.validated_status==='Pending'} onClick={submitLeadLost} variant="contained" text="Lead Lost"/> */}
                </Grid>
                
                {/* Convert opp */}
                  <Grid item xs={4}>
                    {(checkAdminRole && permissionsDetails?.perm_opportunity_create) || permissionsDetails?.perm_opportunity_create ? <>
                      <CustemBtn sx={{borderRadius:'10px', backgroundColor:'#038600', color:'#FFFFFF', pl:4, pr:4, fontSize:'16px'}} disabled={displayLeadDetails?.leaddetails?.validated_status==='Pending'} variant="contained" text="Convert to Opportunity" onClick={createOpportunity}/>
                    </> :<></>}
                  </Grid>
              </Grid>
              </>}
              
              {/* Lead lost button and opportunity create button */}
              {displayLeadDetails?.leaddetails?.status === 'Lost' ? <>
              <Grid sx={{ borderBottom:'1px solid #e4e4e4', p:1,mt:3, borderRadius:'10px', display:"column",backgroundColor:'#ffffff'}}>
                <Grid item xs={10}>
                  <Typography variant='h6' sx={{fontWeight:600, fontSize:'1rem', lineHeight: 1.5, pl:1,borderBottom:'1px solid #e4e4e4'}}>Lost Reason</Typography>
                </Grid>
                <Grid>
                  <Typography sx={{color:'#444444', p:1}}>{displayLeadDetails?.leaddetails?.lost_reason}</Typography>
                </Grid>
              </Grid>
              </>:<></>}

              {displayLeadDetails?.leaddetails?.status === "Closed-No-Action" && displayLeadDetails?.getfollowup?.length === 0 ? "" : <><Box>
                  <Grid container spacing={{xs: 2, md: 3,lg:2, sm:3}} columns={{ xs: 2, sm: 2, md:12, lg:12}}>
                      <Grid item xs={2} sm={12} md={12} lg={12}>
                        {(checkAdminRole && permissionsDetails?.perm_followup_view) || permissionsDetails?.perm_followup_view ? <FollowupDetail title={state?.title} CompanyName={state?.leadDetails?.company} displayLeadDetails={displayLeadDetails}/> : <Typography style={{color:'#f75d4f', margin:'10px'}}>You have no permissions to access this. Please contact your administrator for more information.</Typography>}
                      </Grid>
                      {/* <Grid item xs={2} sm={4} md={6} lg={6}>
                        {permissionsDetails?.perm_task_view ? <TaskDetails title={state?.title} CompanyName={state?.leadDetails?.company} displayLeadDetails={displayLeadDetails}/> : <Typography style={{color:'#f75d4f', margin:'10px'}}>You have no permissions to access this. Please contact your administrator for more information.</Typography>}
                      </Grid> */}
                  </Grid>
              </Box></>}
            </CardBody>

  {/*  lead loss madal */}
            <Dialog
              open={openModal}
              onClose={handleClose}
              aria-labelledby = "alert-dialog-title"
              aria-describedby = "alert-dialog-description"
              fullWidth
              maxWidth="sm"
            >
              <DialogContent>
                  <Grid sx={{ mr:1, display:"flex", flexDirection:{lg:'row',sm:'row',xs:'column-reverse'}, justifyContent:{lg:'space-between',sm:'space-between',xs:'start'}, alignItems:{lg:'flex-center',md:'flex-end',xs:'start'}}}>
                      <Typography variant='h6' sx={{fontSize:{lg:'20px',md:'18px',sm:'14px'}, fontWeight:600}}>Do you want to make the Lead as Lost</Typography>
                  </Grid>
          
                  <Grid container sx={{display:'flex', marginTop:'10px', flexDirection:'row', justifyContent:'space-between'}} spacing={{lg:2, md:2, sm:2, xs:2}}>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Typography sx={{mt:2}}>Closing Remark<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <TextArea name='summary' style={{ marginTop:'10px', width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '8px' }} onChange={handleOfClosingRemark} placeholder="Remark."></TextArea>
                            <DisplayError error={error} fortagid='summary' /> 
                        </Grid>    
                  </Grid>    
              </DialogContent>

                <Box sx={{mt:1, mr:{xs:1, sm:1, xl:3, lg:3}, mb:3, display:'flex', justifyContent:'center'}}>
                    <CustemBtn sx={{borderRadius:"20px", border:"1px solid red", color:'red', pl:5, pr:5, mr:5, fontSize:'16px'}}  onClick = {handleClose} text='Cancel'/>
                    <CustemBtn sx={{borderRadius:"20px", border:"1px solid #3CD307", color:'#3CD307', pl:5, pr:5, fontSize:'16px'}} onClick={handleLeadLost} text='Yes'/>    
                </Box> 
            </Dialog>
        </>}
    </>
  );
}