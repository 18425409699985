import * as React from 'react';
import { styled, Box, InputBase, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ProgressBar from './ProgressBar';
import EditIcon from './EditIcon';
import OppoGoToDetailsBtn from './CustemBtn';
import { useNavigate } from 'react-router-dom';
import { leaddetails } from '../../Redux/Thunk/createleadthunk';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


const CardHeaders = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '0.5px solid #EFEFEF'
})

const CardBody = styled('div')({
  display: 'flex',
  justifyContent: 'space-around',
  marginTop: "10px",
  textAlign: 'center',
  width: "100%",
  flexWrap: 'wrap'
})

const CardData = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

export default function OppoLeadListCard({ name, dash_data }) {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate()
  const Title ="DashboardLeadDetails"
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openLeadDetailsPage =(row) =>{
    //get lead for show lead details
    console.log(row,"rowwww")
    if(row.lead_no) {
      dispatch(leaddetails(row?.id)).then((res)=>{
        if(res.payload) {
          navigate('/dashboard/leaddetails', {state: {leadDetails: row, title:Title, loader:true}})
        }
       }) 
    }else{
      navigate('/dashboard/opportunitydetails', {state: {oppDetails: row, title:Title}})
    }
  
 }

  return (
    <Card sx={{ maxWidth: 440, minHeight:275,backgroundColor:`${name !=='My Leads' ? 'transparent':'transparent'}`, borderRadius: '10px' }}>
      <CardContent>
        <CardHeaders>
          <Typography sx={{ color: '#E15F00', fontSize: '14px', pb: 0.3, fontWeight: 600 }}>
            {name}
          </Typography>
        </CardHeaders>

        <Box sx={{ borderBottom: '0.5px solid #e4e4e4', pb: 0.2 }}>
         {
          dash_data?.length != 0 ?   
         
          <CardBody>
          <Typography sx={{ color: '#444444', flex: 0.5, textAlign: 'start', fontSize: '12px', fontWeight: 600 }}>
            {name == 'My Leads' ? 'Lead ID' : 'Oppo ID'}
          </Typography>
          <Typography sx={{ color: '#444444', flex: 1, fontWeight: 600, fontSize: '12px' }}>
            Date of Creation
          </Typography>
          <Typography sx={{ color: '#444444', flex: 1, fontWeight: 600, fontSize: '12px' }}>
            Contact Name
          </Typography>
          <Typography sx={{ color: '#444444', flex: 1, fontWeight: 600, fontSize: '12px' }}>
            Service Provider
          </Typography>
          <Typography sx={{ color: '#444444', flex: 1, fontWeight: 600, fontSize: '12px' }}>
            Status
          </Typography>
        </CardBody>
       
         :  <CardBody sx={{alignItems:'center', minHeight:200}} >
          <Typography style={{ color:"red", fontSize:'16px'}}>{name == 'My Leads' ? 'No Leads Available !':'No Opportunities Available !'}</Typography>
         </CardBody>
         }
       </Box>
        {
          dash_data?.map((leadoppo) => {
            return <CardBody>
              <Typography onClick={() => openLeadDetailsPage(leadoppo)} sx={{ color: '#FE8731', flex: 0.5, textAlign: 'start', fontSize: '10px', cursor:'pointer' }}>
                {name == 'My Leads' ? leadoppo?.lead_no : leadoppo?.opportunity_no}
              </Typography>
              <Typography sx={{ color: '#444444', flex: 1, fontSize: '10px' }}>
               {moment(leadoppo?.createdAt)?.format('D/M/YYYY')}
              </Typography>
              <Typography sx={{ color: '#444444', flex: 1, fontSize: '10px' }}>
                {leadoppo?.contact?.first_name} {leadoppo?.contact?.last_name}
              </Typography>
              <Typography sx={{ color: '#444444', flex: 1, fontSize: '10px' }}>
               {leadoppo?.service_provider}
              </Typography>
              <Typography sx={{ color: '#3C8AFF', flex: 1, flexWrap: "wrap", fontSize: '10px' }}>
               {leadoppo?.status} {leadoppo?.opportunity_stage}
              </Typography>
            </CardBody>
          }) 
     
        }
      
       
      </CardContent>
    </Card>
  );
}

