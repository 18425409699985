import * as React from 'react';
import { styled,Box,InputBase,Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import  ProgressBar  from './ProgressBar';
import EditIcon from './EditIcon';
import CustemBtn from './CustemBtn';
import InputBox from './InputBox';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


const CardHeaders = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    borderBottom:'0.5px solid #D4D4D4'
})

const CardBody = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    marginTop:"10px"
})

const CardData = styled('div')({
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
})

export default function OpportunityCard({status,CTA1,CTA2,CardView,sx}) {
  const [expanded, setExpanded] = React.useState(false);
  console.log(CardView)
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: '100%',borderRadius:'10px',boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)',...sx }}>
      <CardContent>

      
       {/* <CardHeaders>
        <Typography sx={{color:'#7673F6',fontSize:'20px',fontWeight:'600'}}>
          Name of Company  
        </Typography>
        <Typography >
         {editicon}
        </Typography>
       </CardHeaders>
       <Box sx={{display:'flex',pt:1,justifyContent:'space-between'}}>
       <Typography sx={{fontSize:'18px',color:'#FE8731',fontWeight:600}}>
            OP-01523
        </Typography>
        <Typography sx={{color:'#3C8AFF',fontSize:'16px',fontWeight:600,letterSpacing:1}} >
            {status}
        </Typography>
       </Box> */}
       {CardView}
       {/* <CardBody>
          <CardData>
            <Typography sx={{color:"#444444"}}>
                Date of Creation
            </Typography>
            <InputBox sx={{width:'100%',border:"2px solid #B0AEFF",borderRadius:'10px',p:0.1,mt:1,pl:1}} />
          </CardData>
          <CardData sx={{paddingLeft:'20px'}}>
            <Typography sx={{color:"#444444",}}>
               Date of Billing
            </Typography>
            <InputBox sx={{width:'100%',border:"2px solid #B0AEFF",borderRadius:'10px',p:0.1,mt:1,pl:1}} />
          </CardData>
       </CardBody>
       <CardBody>
          <CardData>
            <Typography sx={{color:"#444444"}}>
               Service Provider
            </Typography>
            <InputBox sx={{width:'100%',border:"2px solid #B0AEFF",borderRadius:'10px',p:0.1,mt:1,pl:1}} />
          </CardData>
          <CardData sx={{paddingLeft:'20px'}}>
            <Typography sx={{color:"#444444"}}>
                Product
            </Typography>
            <InputBox sx={{width:'100%',border:"2px solid #B0AEFF",borderRadius:'10px',p:0.1,mt:1,pl:1}} />
          </CardData>
       </CardBody> */}
       <Box sx={{display:'flex',justifyContent:'space-between',mt:3,flexDirection:'row-reverse'}}>
         {CTA2}
         {CTA1}
         {/* {/* <CustemBtn text="Assign" sx={{color:"#FFFFFF",backgroundColor:"#EEBA00",width:'30%',borderRadius:'30px'}}/> */}
         
       </Box>
      </CardContent>
    </Card>
  );
}