import React from 'react'
import {Routes,Route} from 'react-router-dom'
import Login from '../pages/authorization/Login'
import ForgotPassword from '../pages/authorization/ForgotPassword'
import ResetPassword from '../pages/authorization/ResetPassword'
import { ProtectedRoute } from './ProtectedRoute';
import ExecutiveRoute from './ExecutiveRoute';

const ManagerRoute = ({isAuthenticated}) => {

  return (
    <>
    <Routes>
    {/* Authoriszation Routes */}
      <Route path='/' element={<Login/> }/>
      <Route path='/forgot-password' element={<ForgotPassword/>} />
      <Route path='/resetpassword' element={<ResetPassword/>} />
    </Routes>
    </>
  )
}

export default ManagerRoute
