import React, { useState, useEffect } from 'react';
import { Navbar, DropDown, CustemBtn, CompanyName, InputBox, TextArea, DatePicker, SelectDropDown } from '../../components/custom';
import { Grid, Box, styled, Card, Avatar, Typography, TextField, Autocomplete } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { displayCompanyDetails, getAllCompanyName } from '../../Redux/Thunk/createcompanythunk';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComanyLeads } from '../../Redux/Thunk/createopportunitythunk';
import { displayContactList } from '../../Redux/Thunk/createcontact';
import CreateTaskForm from './CreateTaskForm';
import Swal from 'sweetalert2';
import { main_search } from '../../Redux/Thunk/dashboardthunk';
import displayname from '../../displaycompanyname/displaycompany';
import moment from 'moment';
import AdminRole from '../../adminrole/Adminrole';

const MainContainer = styled('div')({
    width: '100%',
    maxHeight: '100%',
    height: 'auto',
})

const CardBody = styled('div')({
    backgroundColor:'#FFFFFF',
    padding:'20px',
    borderRadius:'10px',
})

const TaskLists = [
    {
        value: 'Lead',
        label: 'Lead',
    },
    {
        value: 'Opportunity',
        label: 'Opportunity',
    }
]
////   Create New follow Up  //
const CreateTask = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {state} = useLocation()
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const { getContactList } = useSelector((state) => state?.contact)
    const {companyleads} = useSelector((state)=> state?.opportunity)
    const [displaySelectId, setDisplaySelectId] = useState('');
    const [selectedId, setSelectedId] = useState("");
    const [searchinput, setSearchInput] = useState('') 
    const [companyList, setCompanyList] = useState([]);
    const [TaskFor, setTaskFor] = useState('Opportunity')
    const [companyName, setCompanyName] = useState('');
    const [contactName, setContactName] = useState("")
    const [TaskList, setTaskList] = useState({})
    const [opportunityList, setOppList] = useState([])
    const [showLeadDetails, setshowLeadDetails] = useState(false);
    const [showOpportunityDetails, setShowOpportunityDetails] = useState(false)
    const [selectedLeadDetails, setSelectedLeadDetails] = useState('')
    const [selectedOpportunityDetails, setSelectedOpportunityDetails] = useState('')
    const [openTaskPopup, setOpenTaskPopup] = useState(false)
    const [taskedit, setTaskEdit]= useState({})
    const [addressDetails, setAddressDetails] =useState('')
    const getCompanyDetails = useSelector((state)=> state.company?.getCompanyDetails)
    const [displayCompanyName, setDisplayCompanyName] = useState({})

    useEffect(() => {
        if(searchinput.length > 1) {
            let timeout_func = setTimeout(() => {
                dispatch(main_search({searchinput, selectedoption:"Company"})).then((res) => {
                    if(!searchinput.length) {
                        setCompanyList([])
                    } else {
                        setCompanyList(res.payload.data) 
                    }
                    
                })
              }, 1000);
             
              return () => {
                clearTimeout(timeout_func);
              };
            
          } else {
            setSearchInput('')
            setCompanyList([]) 
          }
    }, [searchinput])

    const handleCreateNewTask = () => {
        setOpenTaskPopup(true)
    }

    const handleChangeForTask = (event) => {
        // setCreateNewTask({...createNewTask , [event.target.name]: event.target.value})
        // console.log({...createNewTask , [event.target.name]:event.target.value})
    }

    const handleChangeCompanyName = (e, value, reason) => {
        console.log(value)
        if(value === null){
            setshowLeadDetails(false)
            setShowOpportunityDetails(false)
            setTaskFor('')
            setSearchInput('')
            setCompanyName('')
            setSelectedId('')
        }else{
            setCompanyName(value) 
            dispatch(fetchComanyLeads(value.id)).then((res)=>{
                if(res?.payload){
                    setDisplayCompanyName(res?.payload)
                    console.log(res?.payload,"ppppppppppppppppppppp")
                    const filter_oppo = res?.payload?.opportunity.filter((stage) => ( (((user?.data?.user?.id == stage?.assigned_to) && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create)) && (stage.opportunity_stage !=='ClosedWon') && (stage.opportunity_stage !== 'ClosedLost') && (stage?.opportunity_stage !== "ClosedNoAction")))
                    setOppList(filter_oppo)
                    // setTaskList(res?.payload)
                    setTaskFor('')
                    setSelectedId('')
                    setshowLeadDetails(false)
                    setShowOpportunityDetails(false)
                }
            })
            dispatch(displayContactList(value?.id))
            dispatch(displayCompanyDetails(value?.id)).then((res) =>{
               
            })
        }

        // setTaskFor('')
        // setCompanyName(value)
        // if (value.id) {
        //     dispatch(fetchComanyLeads(value.id)).then((res) => {
        //         setTaskList(res?.payload)
        //     })
        //     dispatch(displayContactList(value?.id))
        // }
    }

    const handleChangeTaskFor = (e, value, reason) => {
        if(Object.keys(companyleads).length > 0){
            if(value === null){
                setshowLeadDetails(false)
                setShowOpportunityDetails(false)
                setTaskFor('')
                setSelectedId('')
            }else{
                setTaskFor(value);
                if (value.value === "Opportunity") {
                    setOppList(companyleads?.opportunity)
                    setshowLeadDetails(false)
                    setSelectedId('')    
                } else {
                    setOppList(companyleads?.lead?.filter((status) => status?.status !== "Converted" || status?.status == "Lost"));
                    setShowOpportunityDetails(false)
                    setSelectedId('')
                } 
            }   
        }else{
            sweetalert("error", "Please Select Company Name")
        }

        // if (value === null) {
        //     setshowLeadDetails(false)
        //     setShowOpportunityDetails(false)
        //     setTaskFor('')
        //     setSelectedId('')
        // } else {
        //     setTaskFor(value);
        //     if (value.value === "Opportunity") {
        //         setOppList(TaskList?.opportunity)
        //     } else {
        //         setOppList(TaskList?.lead?.filter((status) => status?.status !== "Converted" || status?.status == "Lost"));
        //     }
        // }
    }
 
    const sweetalert = (variant, text) => {  
        Swal.fire({
          icon: variant==='error' ? 'error' : variant==='success'? 'success' :'warning',
          title: text , 
          button: "Ok!",
        }); 
    };
console.log(getContactList, selectedOpportunityDetails, getCompanyDetails?.data?.branches, "selectedOpportunityDetailsselectedOpportunityDetails")
    return (

        <MainContainer>
            {((state?.title ==="Task List" && state?.operation==="createTask")||(state==="CreateFromDashboard") || state?.title==="Alert List")? <></>:<CompanyName style={{ background: '#F3F3F3' }} sx={{ color: '#3107AA', letterSpacing: "0.04em" }} label={state?.title === "companyProfilePage" || state?.title ==="LeadDetailsPage" ? `${state?.companyName?.company_name} ${state?.companyName?.company_type}`:""} />}
            <Grid sx={{borderBottom:'1px solid #e4e4e4', mt:2, ml:{xs:1,sm:1,xl:4,lg:4}, mr:{xs:1,sm:1,xl:4,lg:4}, display:"flex",flexDirection:{lg:'row',sm:'row',xs:'column-reverse'}, justifyContent:{lg:'space-between',sm:'space-between',xs:'start'},alignItems:{lg:'flex-end',md:'flex-end',xs:'center'}}}>    
                <Typography variant='h6' sx={{fontSize:{lg:'22px',md:'20px',sm:'18px'}, fontWeight:600}}>Create Task</Typography>
            </Grid>
          
            <CardBody sx={{mt:showLeadDetails ? 2 :2, pb:4, ml:{xs:1,sm:1,xl:4,lg:4}, mr:{xs:1,sm:1,xl:4,lg:4} }}>
                <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                    {/* <Grid item xs={12} md={2} sm={2} lg={2}>
                        <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Company</Typography>
                    </Grid> */}
                     {state?.title ==="Task List" || state ==="CreateFromDashboard" || state?.title==="Alert List"?<>
                        <Grid item xs={12} md={4} sm={4} lg={3}>
                            <Typography>Company Name</Typography>
                            <DropDown 
                                id='companyname'
                                sx={{background:'#FFFFFF', mt:1}} 
                                placeholder = 'Search Company'
                                name='companyName'
                                //options ={allCompanyName?.map((name)=>{return {label:`${name?.company_name} ${name?.company_type}`, value:`${name?.company_name} ${name?.company_type}`, id: name?.id, branch:name?.branch, headOffice:name?.head_office, registeredAddress:name?.registered_address}})}
                                options={displayname(companyList)}
                                onChange = {handleChangeCompanyName}
                                onSearch={(e) =>{
                                    if(e.target.value == '') {
                                        e.preventDefault()
                                        setCompanyList([])
                                        setSearchInput('')
                                        setCompanyName('')
                                    } else {
                                        setSearchInput(e.target.value.trim())
                                    }
                                  }}
                                //value= ''
                                clearOnBlur={true}
                                getOptionLabel={(option) =>(option ? option.label :"")}
                                value = {companyName}
                                searchinput={searchinput}
                                createLabel='Create New Company'
                               // handleCreateNew={handleOpenCompanyModel}
                            />
                        </Grid>
                    </>:<></>}
                    <Grid sx={{display:'none'}} item xs={12} md={3} sm={3} lg={3}>
                        <Typography>Task for</Typography>
                        <DropDown sx={{ background: '#FFFFFF', fontWeight: '400', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', mt: 1 }}
                            id="task for"
                            placeholder='Select for'
                            onChange={handleChangeTaskFor}
                            options={TaskLists}
                            getOptionLabel={(option) => (option.label ? option.label : "")}
                            isOptionEqualToValue = {(option, value) => option.label === value}
                            value={TaskFor}
                        />
                    </Grid>

                    {companyName ? (
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <Typography>Select ID</Typography>
                            <DropDown 
                                id='select_opportunity'
                                sx={{ background: '#FFFFFF', fontWeight: '400', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', mt: 1 }}
                                placeholder='Select Opportunity ID '
                                onChange={(e, value, reason) => {
                                    if (value === null) {
                                        setshowLeadDetails(false)
                                        setShowOpportunityDetails(false)
                                        setSelectedId('')
                                    } else {
                                        if (TaskFor.label === "Lead") {
                                            // setshowLeadDetails(true)
                                            // const LeadDetails = opportunityList.find((lead) => lead?.lead_no == value?.value)
                                            // setSelectedLeadDetails(LeadDetails)
                                            // const findContatcName = getContactList.find((name) => name.id == LeadDetails?.contact)
                                            // setContactName(findContatcName)
                                        } else {
                                            setShowOpportunityDetails(true)
                                            const OpportunityDetails = opportunityList.find((opportunity) => opportunity?.opportunity_no == value?.value)
                                            setSelectedOpportunityDetails(OpportunityDetails)
                                            const data = getCompanyDetails?.data?.branches.find((address) => address?.id == OpportunityDetails?.branch)
                                           console.log(data,"dddddddddddddddddd")
                                            setAddressDetails(data)
                                            const findContactName = getContactList.find((name) => name?.id == OpportunityDetails?.contact)
                                            setContactName(findContactName)
                                        }
                                        setSelectedId(value)
                                    }
                                }}
                                options={opportunityList?.map((lead) => { return { value:  lead?.opportunity_no, label: lead?.opportunity_no } })}
                                getOptionLabel={(option) => (option.label ? option.label : "")}
                                value={selectedId}
                            />
                        </Grid>
                    ) : " "}
                   

                    {/*    Display Contact name    */}
                    {showLeadDetails || showOpportunityDetails ? <>
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <Typography>Contact Name</Typography>
                            <InputBox value={`${contactName?.first_name ?? ''} ${contactName?.last_name ?? ''}`} disabled={true} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} />
                        </Grid>
                        </> : <></>}
                </Grid>
              
                {showLeadDetails || showOpportunityDetails ? <>
                    <Box sx={{borderBottom:'1px solid #e4e4e4'}}>
                        <Typography sx={{ fontSize: { lg: '20px', md: '1px', sm: '16px' }, fontWeight: 600, mt:2}}>{TaskFor.label==="Lead"? "Lead Details" :"Opportunity Details"}</Typography>
                    </Box></>:<></>}

                {/*    Display Lead Details    */}

                {showLeadDetails ? <>
                    {/*        Lead    */}
                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2} sm={2} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Lead</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Name</Typography>
                                <InputBox value={selectedLeadDetails?.lead_name} disabled={true} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Vodafone limited Mr, Ramesh Jadhav" />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Source</Typography>
                                <InputBox value={selectedLeadDetails?.lead_source} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Parent Organization " />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} l lg={3}>
                                <Typography>Data Source</Typography>
                                <InputBox value={selectedLeadDetails?.data_source} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Mobigic Batabase " />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={0} md={2} sm={2} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Service Provider</Typography>
                                <InputBox value={selectedLeadDetails?.service_provider} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="TTML" />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Product Family</Typography>
                                <InputBox value={selectedLeadDetails?.product_family} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Network Service" />
                                {/* <Typography>{errors.email_address ? errors.email_address : ""}</Typography> */}
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Product</Typography>
                                <InputBox value={selectedLeadDetails?.product} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="ILL" />
                            </Grid>
                        </Grid>
                    </Box>

                    {/*         summary           */}
                    <Box style={{ marginTop: '30px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={2} sm={2} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Summary</Typography>
                            </Grid>
                            <Grid item xs={12} md={10} sm={10} lg={10}>
                                <TextArea value={selectedLeadDetails?.summary} style={{ width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal', background: '#E9E9FF', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }} placeholder="Write a note about Lead."></TextArea>
                            </Grid>
                        </Grid>
                    </Box>
                </>
                    : <></>}

                {/*   Display Opportunity Details    */}
                {showOpportunityDetails ? <>
                    <Box style={{ marginTop: '10px', display: 'flex', flexDirection: { sm: 'column', xs: 'column', lg: 'row' } }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Opportunity</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Name</Typography>
                                <InputBox name='opportunity_name' value={selectedOpportunityDetails?.opportunity_name} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="Vodafone limited Mr, Ramesh Jadhav" />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Order Type</Typography>
                                <InputBox
                                    name='order_type'
                                    placeholder='--Select Type--'
                                    value={selectedOpportunityDetails?.opportunity_type}
                                    disabled={true}
                                    // Default={orderType?.map((item)=> {return {label:item[0], value:item[0]}})}
                                    // label='Select industry'
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Expected Date of Closure</Typography>
                                <InputBox
                                    value={moment(selectedOpportunityDetails?.closure_date).format('D/M/YYYY')}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Line Of Business</Typography>
                                <InputBox
                                    name='business_line'
                                    //  options={Business} 
                                    placeholder='--Select Business--'
                                    value={selectedOpportunityDetails?.lineofbusiness}
                                    // label='Select industry'
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Service Provider</Typography>
                                <InputBox
                                    name='service_provider'
                                    value={selectedOpportunityDetails?.service_provider}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Product Family</Typography>
                                <InputBox
                                    name='product_family'
                                    placeholder='--Select Familly--'
                                    value={selectedOpportunityDetails?.product_family}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Product</Typography>
                                <InputBox
                                    name='product'
                                    placeholder='--Select Product--'
                                    value={selectedOpportunityDetails?.product}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                           
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Service Provider Oppr. ID</Typography>
                                <InputBox name='service_opportunityid' value={Number(selectedOpportunityDetails?.service_opportunityid)} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="123456789" />
                            </Grid>

                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Expected MRC</Typography>
                                <InputBox
                                    name='product_family'
                                    placeholder='--Select Familly--'
                                    value={selectedOpportunityDetails?.expected_mrc}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            
                            <Grid item xs={12} md={2} sm={2} lg={2}>
                                <Typography>Expected NRC</Typography>
                                <InputBox
                                    name='product'
                                    placeholder='--Select Product--'
                                    value={selectedOpportunityDetails?.expected_nrc}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} sm={8} lg={8}>
                                    <Typography>Address</Typography>
                                    <InputBox value={`${addressDetails?.address ?? ''} ${addressDetails?.city ?? ''} ${addressDetails?.state??''} ${addressDetails?.country??''} ${addressDetails?.pincode ??''}`} disabled={true} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} />
                                </Grid>
                           
                            {/* <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Service Provider Oppr. ID</Typography>
                                <InputBox name='service_opportunityid' value={Number(selectedOpportunityDetails?.service_opportunityid)} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="123456789" />
                            </Grid> */}
                        </Grid>
                        <Box style={{ marginTop: '20px' }}>
                            <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                                <Grid item xs={12} md={0} sm={0} lg={2}>
                                </Grid>
                                
                            </Grid>
                        </Box>
                    </Box>

                    {/*       Commercial        */}
                    {/* <Box style={{ marginTop: '30px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Commercial</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>One Time Charges</Typography>
                                <InputBox name='otc' value={selectedLeadDetails?.otc} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="1000" />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <Typography>Annual Reccuring Charges</Typography>
                                <InputBox name='arc' value={selectedLeadDetails?.arc} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="1000" />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Contract Period(months)</Typography>
                                <InputBox
                                    placeholder='--Select months--'
                                    name='months'
                                    value={selectedLeadDetails?.months}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Billing Frequency<span style={{ color: 'red' }}>*</span></Typography>
                                <InputBox
                                    placeholder='--Select Frequency--'
                                    name='bill_frequency'
                                    value={selectedLeadDetails?.bill_frequency}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <Typography>Billing Type<span style={{ color: 'red' }}>*</span></Typography>
                                <InputBox
                                    name='bill_type'
                                    placeholder='--Select Type--'
                                    value={selectedLeadDetails?.bill_type}
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{ marginTop: '20px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={0} sm={0} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Date of Billing</Typography>
                                <InputBox
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                    value={selectedLeadDetails?.date_of_billing}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={3}>
                                <Typography>Date of Renewal </Typography>
                                <InputBox
                                    sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                                    value={selectedLeadDetails?.date_of_renewal}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Service ID</Typography>
                                <InputBox name='service_id' value={selectedLeadDetails?.service_id} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', background: '#E9E9FF', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="007" />
                            </Grid>
                        </Grid>
                    </Box> */}
                   

                    {/*         summary           */}
                    <Box style={{ marginTop: '30px' }}>
                        <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                            <Grid item xs={12} md={12} sm={12} lg={2}>
                                <Typography sx={{ fontWeight: { xs: 600, lg: 400, md: 600 } }}>Summary</Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} lg={10}>
                                <TextArea name='summary' value={selectedOpportunityDetails?.summary} style={{ width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal', background: '#E9E9FF', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '5px' }} placeholder="Write a note about Opportunity."></TextArea>
                            </Grid>
                        </Grid>
                    </Box>
                </> : <></>}

            </CardBody>

            {showLeadDetails || showOpportunityDetails ? <>
                <Box sx={{mt:4, mr:{xs:1,sm:1,xl:6,lg:6}, mb:2, display:'flex', justifyContent:'end' }}>
                    <CustemBtn id='proceed' onClick={handleCreateNewTask} disabled={!selectedId || openTaskPopup} variant='contained' sx={{ borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px'}} text="Proceed" />
                </Box></> :<></>
            }

            {/* <Box sx={{mt:4, mr:{xs:1,sm:1,xl:6,lg:6}, mb:2, display:'flex', justifyContent:'end' }}>
                <CustemBtn onClick={handleCreateNewTask} disabled={!selectedId || openTaskPopup} variant='contained' sx={{borderRadius:"10px",pl:5,pr:5,fontSize:'16px'}} text ='Proceed'/>
            </Box> */}
          
            {openTaskPopup && <CreateTaskForm title={state} task_for={TaskFor} For='create' taskedit={taskedit} setTaskEdit={setTaskEdit} openTaskPopup={openTaskPopup} setOpenTaskPopup={setOpenTaskPopup} selectedOpportunityDetails={selectedOpportunityDetails} selectedLeadDetails={selectedLeadDetails}  />}
        </MainContainer>
    )
}

export default CreateTask
