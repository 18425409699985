import React ,{useEffect, useState} from 'react'
import Header from './Header'
import { Box, Divider, Grid, Typography, styled} from '@mui/material'
import CafseridDetails from './CafseridDetails'
import CafSerIDFlow from './CafSerIDFlow'
import { Sidebar } from './CAF.Ser.IDcss'
import { H7TablePrimeContent } from '../../../Theme/theme'
import themeColor from '../../../Theme/themeColor'
import DocumentUpload from '../DocumentUpload'
import { CustemBtn } from '../../../components/custom'
import { useDispatch, useSelector } from 'react-redux'
import { getServiceDetails, oppodetails, uploadCOFServiceID } from '../../../Redux/Thunk/createopportunitythunk'
import DisplayActions from './DisplayActions'
import { useLocation, useNavigate } from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'
import { DisplayErrorMsgBilling, DisplayErrorMsgCAF, storeBillingStartDate, storeOtherCAFID } from '../../../Redux/slices/createOpportunitySlice'
import swal from 'sweetalert'
import { getresettime, sendreminder } from '../../../Redux/Thunk/reminders'
import ReminderIcon from '../../../components/custom/ReminderIcon'

const CardBody = styled('div')({
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '10px',
})

const LoginContainer = styled('div')({
    paddingTop: "10px",
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
})

const ServiceIDDetails = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [serviceIDDetails, setServiceIDDetails] = useState({})
   const {cafService} = useSelector((state)=>state.opportunity)
   const [loader, setLoader] = useState(true)
   const { user } = useSelector((state) => state.auth)
   const [oppo_id, setOppo_id] = useState('')
   const permissionsDetails = user?.data?.user.role.permissions
   const [resettime, setResetTime] = useState('')
   const { state } = useLocation()
   const [disable, setDisable] = useState(false)

    useEffect(()=>{
        if(!state || state === 'undefined') {
            navigate('/dashboard')
            return
          } else {
        dispatch(getServiceDetails(oppo_id ? oppo_id : state?.serviceDetails)).then((res) => {
            if (res.payload.errormsg) {
                sweetalert('error', res.payload.errormsg)
                setLoader(false)
            }else{
                setServiceIDDetails(res?.payload)
                dispatch(getresettime({id : res?.payload?.oppo_details?.id, stage : 'Service'})).then((res) => {
                    setResetTime(res.payload.data)
                    setLoader(false)
                })
                setLoader(false)
            }
        })
        dispatch(DisplayErrorMsgCAF({value:false}))
        dispatch(DisplayErrorMsgBilling({value:false}))
        dispatch(storeOtherCAFID(''))
    }
    },[cafService, oppo_id, state.serviceDetails])

    console.log(serviceIDDetails, 'service details')

    let show_display = serviceIDDetails?.oppo_stages?.filter((ele) => (ele.status == 'Approved' || ele.status == 'Pending'))
    
    const sweetalert = (variant, text) => {
        swal({
           icon: variant === 'error' ? 'error' : 'success',
           title: text,
           button: "Ok!",
        });
    };

    const handleReminder = (id, stage) => {
        setDisable(true)
        dispatch(sendreminder({opportunity_id : id, stage:stage})).then((res) => {
            if (res.payload.errormsg) {
               sweetalert('error', res.payload.errormsg)
            } else {
               sweetalert('success', res.payload.data)
               dispatch(getresettime({id : serviceIDDetails?.oppo_details?.id, stage : 'Service'})).then((res) => {
                   setResetTime(res.payload.data)
               }) 
               
            }
        })
       }

    return (
        <Box sx={{ padding: '10px' }}>
            {loader ? <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
                <TailSpin
                    height="30"
                    width="30"
                    color="blue"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                /> </Box> : <>

            <Header For='Service' modified_oppo ={serviceIDDetails.find_modified_oppo} name = "Service ID Details" service= {serviceIDDetails?.oppo_details}  />  
            <LoginContainer>
            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                <Grid item xs={12} md={10} sm={10} lg={5}>
                    <CafseridDetails For='Service' setOppo_id={setOppo_id} modified_oppo ={serviceIDDetails.find_modified_oppo} cofDetails={serviceIDDetails} opportunitydetails={serviceIDDetails?.oppo_details}/>
                </Grid>
                   
                <Grid item lg={7} xs={12} md={10} sm={10}>
                <Box sx={{mr:{xs:1,sm:1,xl:2, lg:2}, display:'flex',justifyContent:'end' }}>
                     {
                        (serviceIDDetails?.oppo_details?.servicedetails?.status != 'Pending' || serviceIDDetails?.oppo_stages?.length === 0 || (permissionsDetails.perm_approve_edit) ) ? '' :((resettime?.length > 0 ? new Date().getTime() > resettime[0]?.reset_time : true) &&  ((user?.data?.user?.id == serviceIDDetails?.oppo_details?.assigned_to))) ?  <Typography sx={{pl:2, cursor:'pointer'}} onClick={ disable ? '' : () => handleReminder(serviceIDDetails?.oppo_details?.id, 'Service') } ><ReminderIcon /></Typography> : ""
                    }
                </Box>
                  <CardBody sx={{ mt: !((resettime?.length > 0 ? new Date().getTime() > resettime[0]?.reset_time : true) &&  ((user?.data?.user?.id == serviceIDDetails?.oppo_details?.assigned_to))) ? 3.7 : 0.5 , ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl: 0, lg: 0 } }}>
                        {((show_display?.length === 0 || serviceIDDetails?.oppo_stages?.length === 0 ) && (serviceIDDetails?.oppo_details?.opportunity_stage == 'ClosedWon' && serviceIDDetails?.oppo_details?.approval_status == 'Approved' && serviceIDDetails?.oppo_details?.cofdetails?.status == 'Approved') ) ? (
                         (
                          () => {
                            if(serviceIDDetails.find_modified_oppo[0]?.id != serviceIDDetails?.oppo_details?.id) {
                                return  <H7TablePrimeContent sx={{ color: 'red', pb: 0.2 }}>
                                    This Opportunity has Service Document Rejected
                                </H7TablePrimeContent>
                            } else {
                                return <DisplayActions opp_stage={serviceIDDetails} opportunity_id={serviceIDDetails?.oppo_details?.id} For='Service'/>
                            }
                          }
                         )()
                        )
                        : !(serviceIDDetails?.oppo_details?.opportunity_stage == 'ClosedWon' && serviceIDDetails?.oppo_details?.approval_status == 'Approved' && serviceIDDetails?.oppo_details?.cofdetails?.status == 'Approved') ? <Box sx={{textAlign:'center'}}>
                           <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb: 0.2 }}>
                          {
                            (
                                () => {
                                    if(serviceIDDetails?.oppo_details?.opportunity_stage == 'ClosedLost') {
                                       return 'This Opportunity is Closed Lost.'
                                    }  else if(serviceIDDetails?.oppo_details?.cofdetails?.status == 'Rejected') {
                                        return 'This Opportunity has COF Document Rejected.'
                                     } else {
                                        return ' Please Complete all Stages of this opportunity.'
                                     } 
                                }
                            )()
                          }
                        </H7TablePrimeContent>
                          </Box> : ''}
                        <CafSerIDFlow oppo_stages = {serviceIDDetails} ids = {serviceIDDetails?.all_ids} For='Service'/>
                    </CardBody>
                </Grid>
            </Grid>
            </LoginContainer>
            </>}
            
        </Box>
    )
}

export default ServiceIDDetails
