/**
 * 
 * @param height height of icon
 * @param width width of icon 
 * @returns 
 */
const LeadIcon = ({height,width}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2891 4.83789L14.6201 9.16892" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.332 12.8818L22.6631 17.2129" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.9064 12.8811C10.4141 13.3734 10.1376 14.0411 10.1376 14.7373C10.1376 15.4335 10.4141 16.1012 10.9064 16.5934C11.3987 17.0857 12.0664 17.3623 12.7626 17.3623C13.4588 17.3623 14.1264 17.0857 14.6187 16.5934L21.4246 9.78755C21.5887 9.62345 21.8113 9.53126 22.0433 9.53126C22.2754 9.53126 22.498 9.62345 22.6621 9.78755L25.7557 12.8811C25.9197 13.0452 26.0119 13.2678 26.0119 13.4999C26.0119 13.7319 25.9197 13.9545 25.7556 14.1186L19.0271 20.8471C15.6164 24.2578 10.0557 24.3584 6.62178 20.9709C5.79925 20.1606 5.14522 19.1955 4.69738 18.1313C4.24955 17.0671 4.01676 15.9248 4.01245 14.7702C4.00814 13.6156 4.23238 12.4715 4.67225 11.404C5.11212 10.3364 5.75892 9.36652 6.57538 8.55011L13.3813 1.74421C13.5454 1.58011 13.7679 1.48793 14 1.48793C14.2321 1.48793 14.4546 1.58011 14.6187 1.74421L17.7123 4.8378C17.8764 5.00189 17.9686 5.22445 17.9686 5.45652C17.9686 5.68858 17.8764 5.91114 17.7123 6.07524L10.9064 12.8811Z" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default LeadIcon