import React, {useEffect, useState} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {Grid, Card, Dialog, DialogTitle, Toolbar, DialogContent, IconButton} from '@mui/material';
import OpportunityCard from '../../components/custom/OpportunityCard'
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import { Typography } from '@mui/material';
import { CompanyName, ContactCard, DeleteIcon, EditIcon, Navbar, SelectDropDown, TextArea } from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import AddIcon from '../../components/custom/AddIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import OpportunityCardView from './OpportunityCardView';
import OpportunityCommercialCard from './OpportunityCommercialCard';
import { useDispatch, useSelector } from 'react-redux';
import { createOpportunity, oppodetails, opportunityedit } from '../../Redux/Thunk/createopportunitythunk';
import { FollowupDetail } from '../LeadDetails/FollowupDetail';
import TaskDetails from '../LeadDetails/TaskDetails';
import swal from 'sweetalert';
import CloseIcon from '@mui/icons-material/Close';
import DisplayError from '../../validation/DisplayError';
import validate from "../../validation/validation";
import { TailSpin} from 'react-loader-spinner';
import moment from 'moment';

const StatusList =[
  {
      value: 'Identified Opportunity',
      label: 'Identified Opportunity',
  },
  {
      value: 'Qualified Opportunity',
      label: 'Qualified Opportunity',
  },
  {
      value: 'Solution',
      label: 'Solution',
  },
  {
      value: 'Commercials',
      label: 'Commercials',
  },
  {
      value: 'Proposal Sent',
      label: 'Proposal Sent',
  },
  {
      value: 'Negotiation',
      label: 'Negotiation',
  },
  {
      value: 'Verbal Confirmation',
      label: 'Verbal Confirmation',
  },
  {
      value: 'Closed-Won',
      label: 'Closed - Won',
  },
  {
      value: 'Closed-Lost',
      label: 'Closed - Lost',
  },
 
]

const LostReasons =[
  {
      value: 'High Price',
      label: 'High Price',
  },
  {
      value: 'Proposed Solution not acceptable',
      label: 'Proposed Solution not acceptable',
  },
  {
      value: 'No Feasibility',
      label: 'No Feasibility',
  },
  {
      value: 'Not Happy with Company',
      label: 'Not Happy with Company',
  },
  {
      value: 'Not Happy with Partner',
      label: 'Not Happy with Partner',
  },
  {
      value: 'Delay',
      label: 'Delay',
  },
  {
      value: 'No Activity',
      label: 'No Activity',
  },
  {
      value: 'Others',
      label: 'Others',
  },
]

const Item = styled('div')({
  display:'flex',
  justifyContent:'center'
})

const CardBody = styled('div')({
   backgroundColor:'#F0EFFD',
   padding:'20px',
   borderRadius:'10px',
})

const Search = styled('div')({
  display:'flex',
  padding:'0px 10px',
  borderRadius:'10px',
  justifyItems:'center',
  // border:'1px solid #B0AEFF ',
  flexDirection:'row',
  backgroundColor:'#ffffff',
  boxShadow:'0px 4px 20px #e4e4e4'
})

const CardDetails = styled('div')({
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  marginTop:"10px"
})

const ChildCard = styled('div')({
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
 })

const CardInfo = styled('div')({
  display:'flex',
  flexWrap:'wrap',
  justifyContent:'space-between',
})

const CardHeaders = styled('div')({
  display:'flex',
  justifyContent:'flex-start',
  alignItems:'center',
  borderBottom:'0.5px solid #e4e4e4',
  marginTop:'10px'
})

const HeadingInfomation = styled('div')({
  flex:1,
  fontSize:'16px',
  // fontWeight: 600,
  color:'#3107AA'
})

export default function OpportunityDetails() {
  const navigate = useNavigate();
  const {state} = useLocation()
  const dispatch = useDispatch()
  const {user} = useSelector((state) => state.auth)
  const {createTask} = useSelector((state)=> state.task)
  const [update, setUpdate] = useState(false)
  const permissionsDetails = user?.data?.user
  const {updateCreateEditFollowup} =useSelector((state) => state.settingList)
  const {updateOpportunityEdit} = useSelector((state)=> state.opportunity)
  const [opportunitydetails, setOpportunitydeatils] = useState([])
  const [updateStatus, setUpdateStatus] = useState(false)
  const [status, setStatus] = useState('')
  const [loader, setLoader]= useState(false)
  const [saveStatus, setSaveStatus]= useState('')
  const [statusLost, setStatusLost] = useState({
    lost_reason:'',
    detailreason:''
  })
  const [error, setError] = useState([]);
  const title ="OpportunityDetailsPage"

  // console.log(opportunitydetails,"dataaaaaaaaa")
  useEffect(() => {
    setLoader(true)
    dispatch(oppodetails(state?.oppDetails)).then((res)=>{
      setOpportunitydeatils(res?.payload)
      setStatus(res?.payload?.oppodetails?.opportunity_stage)   // set Status in dropdown
      setLoader(false)
    })
  }, [update, updateCreateEditFollowup, updateOpportunityEdit, createTask, state])

  let validation_input = [
    { tagid: 'lost_reason', text: statusLost?.lost_reason, regex_name: 'text', required: true, errmsg: 'Please Select Reason.' },
    { tagid:'detailreason', text: statusLost?.detailreason, regex_name: 'free_text', required: true,  errmsg: 'Please add the reason between 100 to 2000 characters', min:100, max:2000}
  ]
 
  const handleChangeLostOpportunity =(e)=>{
    setStatusLost({...statusLost, [e.target.name]:e.target.value})
  }
  const handleClickEditOpportunity=() =>{
    navigate('/dashboard/opportunity', {state: {opportunity: opportunitydetails?.oppodetails, leadNo:opportunitydetails?.lead_no, operation:'editOpp', title:title}})
  }

  const SubmitLostOpportunity =()=>{
    if(validate(validation_input).length == 0){
      setError([])
      sumbitstatusDetails(saveStatus)
     }else{
        setError(validate(validation_input))
    } 
  }

  const handleClose = () => {
    setUpdateStatus(false);
    setStatusLost({
      lost_reason:'',
      detailreason:''
    })
    setError([])
};
  
  const handleOfStatus = (event) => {
    console.log(event.target.value)
    if(event.target.value === "Closed-Lost" || event.target.value ==='Closed-Won'){
      const allTaskClosed = opportunitydetails?.gettask?.every(obj => obj.closing_remark !='')
      if((opportunitydetails?.getfollowup?.find((status)=> status.closed_on === 0)) && (allTaskClosed === false)){
        sweetalert('warning',`Following opportunity has open followup and task, Please close the followup and task then change the status.`)
      }else if (opportunitydetails?.getfollowup?.find((status)=> status.closed_on === 0)){
        sweetalert('warning',`Following opportunity has open followup, Please close the followup then change the status.`)
      }else if (allTaskClosed === false){
        sweetalert('warning',`Following opportunity has open task, Please close the task then change the status.`)
      }else{
        setSaveStatus(event.target.value)
        swal({
          buttons: {
          Cancel: {
              text: "No",
              value: "No",
              className: "popup-blue",
          },
          Delete: {
              text: "Yes",
              value: "Yes",
              className: 'popup-gray',
          },
          },
          title: `Do you want to change status?`,
          className: "custom-modal",
          closeOnClickOutside: false,
      }).then((value)=>{
        if(value =='Yes'){
          if(event.target.value==="Closed-Lost"){
            setUpdateStatus(true)
          }else{sumbitstatusDetails(event.target.value)}     
        }else{
            setUpdate(!update)
          }
      })
      }
    }else{
      const status = event.target.value 
      setSaveStatus(event.target.value)
        swal({
          buttons: {
          Cancel: {
              text: "No",
              value: "No",
              className: "popup-blue",
          },
          Delete: {
              text: "Yes",
              value: "Yes",
              className: 'popup-gray',
          },
          },
          title: `Do you want to change status?`,
          className: "custom-modal",
          closeOnClickOutside: false,
      }).then((value)=>{
          if(value =='Yes'){
           sumbitstatusDetails(status)   
          }else{
            setUpdate(!update)
          }
      })
    }
  }

  const sweetalert = (variant, text) => {
    swal({
        icon: variant==='error' ? 'error' : variant==='success'? 'success' :'warning',
        title: text,
        button: "Ok!",
    });
  }

  const sumbitstatusDetails=(status)=>{
    const oppJson={
      opportunity_id : opportunitydetails?.oppodetails?.id,
      opportunity_stage :status,
      opportunity_name:opportunitydetails?.oppodetails?.opportunity_name ,
      contact:opportunitydetails?.oppodetails?.contact?.id,
      opportunity_type:opportunitydetails?.oppodetails?.opportunity_type,
      order_type:opportunitydetails?.oppodetails?.order_type,
      lineofbusiness: opportunitydetails?.oppodetails?.lineofbusiness,
      product_family: opportunitydetails?.oppodetails?.product_family,
      product: opportunitydetails?.oppodetails?.product,
      service_provider: opportunitydetails?.oppodetails?.service_provider,
      service_opportunityid: opportunitydetails?.oppodetails?.service_opportunityid,
      summary:opportunitydetails?.oppodetails?.summary,
      detailreason: statusLost?.detailreason,
      lost_reason: statusLost?.lost_reason
    }
    console.log(oppJson,"jsonnnnnnnnnnn")
    dispatch(opportunityedit(oppJson)).then((res)=>{
      setStatus(status)
      setUpdateStatus(false)
      setUpdate(!update)
      console.log(res.payload)
    })
    //  setSaveStatus('')
  }

  return (
    <>
    {loader ? <>
      <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
        <TailSpin
          height="30"
          width="30"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
       /> </Box>
    </>:<>
    <CompanyName style={{background:'#3107AA'}} sx={{color:'#FFFFFF',letterSpacing:"0.04em"}} label={`${opportunitydetails?.company?.company_name} ${opportunitydetails?.company?.company_type}`}/>

    <CardBody sx={{mt:3, ml:{xs:1,sm:1,xl:5,lg:5}, mr:{xs:1,sm:1,xl:5, lg:5}}}>
      <Grid sx={{ borderBottom:'1px solid #e1e1e1', mb:2, display:"flex", justifyContent:'space-between', alignItems:'flex-end'}}>
        <Grid item xs={10}>
          <Typography variant='h6' sx={{fontSize:{sm:"20px",md:'22px',xl:'22px'}, fontWeight:600}}>Opportunity Details</Typography>
        </Grid>
        <Grid sx={{display:'flex',alignItems:'end'}} item xs={2}>
        <Typography sx={{textAlign:'right',color:'#FFCE00',fontSize:'28px',pr:2}}>{state?.oppDetails?.status}</Typography>
        <SelectDropDown
            name='Status'
            options={StatusList}
            onchange={handleOfStatus}
            placeholder='--Select Status--'
            value={status}
            disabled={((opportunitydetails?.oppodetails?.opportunity_stage ==="Closed-Won"|| opportunitydetails?.oppodetails?.opportunity_stage ==="Closed-Lost")&& opportunitydetails?.oppodetails?.approval_status ==="Approved")}
            sx={{ p: 0.1, width: 200, pl: 1, backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#F3F3F3'}`, borderRadius: "10px" }}
          />
           {((opportunitydetails?.oppodetails?.opportunity_stage ==="Closed-Won"|| opportunitydetails?.oppodetails?.opportunity_stage ==="Closed-Lost")&& opportunitydetails?.oppodetails?.approval_status ==="Approved") || opportunitydetails?.oppodetails?.approval_status ==="Approved"|| !permissionsDetails?.role?.permissions?.perm_opportunity_edit ? <></> :  <Typography sx={{textAlign:'right',pl:1}} onClick={handleClickEditOpportunity}><EditIcon w={27} h={27} /></Typography>}
        </Grid>
      </Grid>

    <Box sx={{ flexGrow: 1}}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 4,lg:4 }}>
        <Grid item xs={2} sm={4} md={4}>
          <Card style={{borderRadius:'10px',boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)'}}>
              <CardHeaders>
                  <Typography variant='h6' sx={{pl:2, color:'#FE8731',fontWeight:600}}>{state?.oppDetails?.opportunity_no}</Typography>
               </CardHeaders>
               <ChildCard sx={{pl:2, pr:1, pb:1}}>
                <CardInfo sx={{marginTop:"10px"}}>
                    <Typography sx={{flex:1, fontWeight:600}}>
                      Opportunity Name
                  </Typography>
                  <Typography sx={{flex:1, fontWeight:600}}>
                      Contact
                  </Typography>
                  <Typography sx={{flex:1, fontWeight:600}}>
                      Order
                  </Typography>
                  <Typography sx={{flex:1, fontWeight:600}}>
                      Order Type
                  </Typography>
                  <Typography sx={{flex:1, fontWeight:600}}>
                    Exp.Date of Closer
                  </Typography>
              </CardInfo>
              <CardInfo sx={{marginTop:"2px"}}>
              <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                {opportunitydetails?.oppodetails?.opportunity_name}
              </Typography>
              <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                {opportunitydetails?.oppodetails?.contact?.first_name} {opportunitydetails?.oppodetails?.contact?.last_name}
              </Typography>
              <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                {opportunitydetails?.oppodetails?.opportunity_type}
              </Typography>
              <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                {opportunitydetails?.oppodetails?.order_type}
              </Typography>
              <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                {moment(opportunitydetails?.oppodetails?.closure_date)?.format('D/M/YYYY')}
              </Typography>
              </CardInfo>

              <CardInfo sx={{marginTop:"20px"}}>
                  <Typography sx={{flex:1, fontWeight:600}}>
                    Line of Business
                  </Typography>
                  <Typography sx={{flex:1, fontWeight:600}}>
                  Service Provider Opp.ID
                  </Typography>
                  <Typography sx={{flex:1, fontWeight:600}}>
                    Service Provider 
                  </Typography>
                  <Typography sx={{flex:1, fontWeight:600}}>
                    Product Family  
                  </Typography>
                  <Typography sx={{flex:1, fontWeight:600}}>
                    Product
                  </Typography>
              </CardInfo>
              <CardInfo sx={{marginTop:"2px"}}>          
                <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                  {opportunitydetails?.oppodetails?.lineofbusiness}
                </Typography>
                <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                  {opportunitydetails?.oppodetails?.service_opportunityid}
                  </Typography>
                <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                  {opportunitydetails?.oppodetails?.service_provider} 
                  </Typography>
                <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                  {opportunitydetails?.oppodetails?.product_family} 
                  </Typography>
                <Typography sx={{flex:1,fontSize:'14px', color:'#3107AA'}}>
                  {opportunitydetails?.oppodetails?.product}
                </Typography>
              </CardInfo>
              
              <CardInfo sx={{marginTop:"20px"}}>
                  <Typography sx={{flex:1, fontWeight:600}}>
                    Address (<span style={{color:'#008179'}}>{opportunitydetails?.oppodetails?.branch?.register_address == true ? "Registered Office Address" : "" ||opportunitydetails?.oppodetails?.branch?.head_office == true? "Head Office Address" :'' || 
                    (opportunitydetails?.oppodetails?.branch?.register_address == false && opportunitydetails?.oppodetails?.branch?.head_office == false ) ? "Branch Office Address" :'' }</span>)
                  </Typography>
              </CardInfo>
              <CardInfo sx={{marginBottom:"2px", mt:0}}>
                  <Typography sx={{flex:1, fontSize:'14px',flexWrap:'wrap', color:'#3107AA', overflowWrap: 'break-word'}}>
                      {opportunitydetails?.oppodetails?.branch?.address}
                  </Typography>
              </CardInfo>
              </ChildCard> 
            </Card>

          </Grid>
          {/* <Grid item xs={2} sm={4} md={4}>
            <Card style={{borderRadius:'10px', backgroundColor:'#ffffff', boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)'}}>
            <OpportunityCommercialCard commerical= {state} />
            </Card>
            </Grid>       */}
 {/* ))}  */}
      </Grid>
    </Box>

      <Grid sx={{ borderBottom:'1px solid #e4e4e4', p:1,mt:3, borderRadius:'10px', display:"column",backgroundColor:'#ffffff'}}>
        <Grid item xs={10}>
          <Typography sx={{fontWeight:600, borderBottom:'1px solid #e4e4e4'}}>Opportunity Summary</Typography>
        </Grid>
        <Grid>
          <Typography sx={{color:'#3107AA', p:1, whiteSpace: 'pre-line', wordBreak:'break-all'}}>{opportunitydetails?.oppodetails?.summary}</Typography>
        </Grid>
      </Grid>

      {/* Opportunity Lost  */}
      {opportunitydetails?.oppodetails?.opportunity_stage ==="Closed-Lost" ?<>
        <Grid sx={{ borderBottom:'1px solid #e4e4e4', p:1,mt:3, borderRadius:'10px', display:"column",backgroundColor:'#ffffff'}}>
          <Grid item xs={10}>
            <Typography variant='h6' sx={{ fontSize:'1rem', fontWeight:600, borderBottom:'1px solid #e4e4e4'}}>Opportunity Lost Reason</Typography>
          </Grid>
          <Grid>
          {/* <Grid sx={{display:"flex", flexDirection:'row',justifyContent:'space-between', alignItems:'flex-end'}}> */}
              <Grid container>
                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ p: 1 }}>
                    Lost Reason : <span style={{ color: '#3107AA' }}>{opportunitydetails?.oppodetails?.lost_reason}</span>
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ p: 1 }}>
                    Updated By : <span style={{ color: '#3107AA' }}>{permissionsDetails?.name}</span>
                  </Typography>
                </Grid>
              </Grid>
           {/* </Grid> */}
           <Typography sx={{flex:1, fontSize:'14px', color:'#3107AA', wordBreak:'break-all'}}>{opportunitydetails?.oppodetails?.reasonindetail}</Typography>
          </Grid>
        </Grid>
      </> :<></>}

      <Box>
        <Grid container spacing={{xs: 2, md: 3,lg:2, sm:3}} columns={{ xs: 2, sm: 2, md:12, lg:12}}>
          {/* < Create Followup/> */}
            <Grid item xs={2} sm={4} md={6} lg={6}>
              {permissionsDetails?.role?.permissions?.perm_followup_view ? <FollowupDetail title={state?.title} CompanyName={opportunitydetails?.oppodetails?.company} displayLeadDetails={opportunitydetails}/> : <Typography style={{color:'#f75d4f', margin:'10px'}}>You have no permissions to access this. Please contact your administrator for more information.</Typography>}
            </Grid>
            {/* < Create Task /> */}
            <Grid item xs={2} sm={4} md={6} lg={6}>
              {permissionsDetails?.role?.permissions?.perm_task_view ? <TaskDetails title={state?.title} CompanyName={opportunitydetails?.oppodetails?.company} displayLeadDetails={opportunitydetails} /> : <Typography style={{color:'#f75d4f', margin:'10px'}}>You have no permissions to access this. Please contact your administrator for more information.</Typography>} 
            </Grid>
        </Grid>
      </Box>
    </CardBody> 
    </>}

      {updateStatus &&  
        <Dialog
                open={updateStatus}
              // / onClose={handleClose}
                aria-labelledby = "alert-dialog-title"
                aria-describedby = "alert-dialog-description"
                fullWidth
                maxWidth="md"
              >
                  <DialogTitle sx={{borderBottom:'0.5px solid #E8DADA', padding: '0px'}} >
                  <Toolbar
                      children={
                      <>
                          <Box> 
                          <Grid
                              sx={{ mr: 1, display: { lg: 'row', sm: 'row', xs: 'column-reverse' }, justifyContent: { lg: 'space-between', sm: 'space-between', xs: 'start' },
                              alignItems: { lg: 'flex-end', md: 'flex-end', xs: 'start' }}}>
                              <Typography variant="h6" sx={{ fontSize: { lg: '18px', md: '16px', sm: '12px' }, fontWeight: 600 }}>
                                  Closed Lost Remark
                              </Typography> 
                          </Grid>
                          </Box>
                          <div style={{ position: 'absolute', right: 25 }}>
                          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                              <CloseIcon />
                          </IconButton>
                          </div>
                      </>
                      }
                      ></Toolbar>     
                  </DialogTitle>
                  
                <DialogContent>
                  <Box style={{ marginTop: '20px' }}>
                      <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                          <Grid item xs={12} md={4} sm={4} lg={4}>
                              <Typography>Reasons<span style={{ color: 'red' }}>*</span></Typography>
                              <SelectDropDown
                                  // disabled={state.for === 'tasklist'}
                                  placeholder='Select Field'
                                  name='lost_reason'
                                  options={LostReasons}
                                  onchange={handleChangeLostOpportunity}
                                  value={statusLost?.lost_reason}
                                  sx={{ p: 0.1, pl: 1, backgroundColor: '#ffffff', borderRadius: "10px" }}
                              />
                              <DisplayError error={error} fortagid='lost_reason' />
                          </Grid>

                          <Grid item xs={12} md={12} sm={8} lg={8}>   
                              <Typography>Mention Reason in Detail<span style={{ color: 'red' }}>*</span></Typography> 
                              <TextArea name='detailreason' value={statusLost?.detailreason?.replace(/\s\s+/g, " ")} onChange={handleChangeLostOpportunity} style={{ width: '100%', fontFamily: 'Montserrat', fontStyle: 'normal',  backgroundColor: `${state.for === 'tasklist' ? '#E9E9FF' : '#ffffff'}`, border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '5px' }} placeholder="Enter the reason."></TextArea>
                              <DisplayError error={error} fortagid='detailreason' />
                          </Grid>
                      </Grid>
                  </Box>
                </DialogContent>

                  <Box sx={{mt:2, mr:{xs:1, sm:1, xl:3, lg:3}, mb:2, display:'flex', justifyContent:'end' }}>
                      <CustemBtn variant="contained" onClick={SubmitLostOpportunity} sx={{borderRadius:"10px", pl:5, pr:5, fontSize:'16px',backgroundColor:'#7673F6'}} text= "Save"/>
                  </Box> 
        </Dialog>
        }
       
    </>
  );
}