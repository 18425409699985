import React ,{useState} from 'react';
import { styled,Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import  ProgressBar  from './ProgressBar';
import DatePicker from './DatePicker';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


const CardHeaders = styled('div')({
    display:'flex',
    justifyContent:'space-between',
})

const CardBody = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
})

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    left:50,
    top:20,
    backgroundColor:'transparent'
  },
});

const dialogStyle = {
  position: 'fixed',
  left:50,
  top:20,
  //backgroundColor:'transparent'
  // width: '100%',
  // maxWidth: '100%',
  // maxHeight: '70%',
  //borderRadius: '8px 8px 0 0',
};

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export default function CalenderCard({open, setOpenCalender, days, setStart_Date, setEnd_Date, start_date, end_date}) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClose=()=>{
    setOpenCalender(false)
  }

  const changeStartDate = (newValue) =>{
    let formate= convert(newValue.$d)
    setStart_Date(convert(formate)) 
    // let hours = dayjs(formate).diff(dayjs(end_date),"hours");
    // const day = Math.floor(hours / 24);
  }

  const changeEndDate = (newValue) =>{
    let formate= convert(newValue.$d)
    setEnd_Date(formate) 
    // let hours = dayjs(formate).diff(dayjs(start_date),"hours");
    // const day = Math.floor(hours / 24);
  }


   let hours = dayjs(end_date).diff(dayjs(start_date),"hours");
   let ini_diff = Math.floor(hours / 24);
 
  return (
    <Dialog
    open={open}
    PaperProps={{ style: dialogStyle }}
    onClose={handleClose}
    classes={{ paper: classes.paper }}
  >
    <Card sx={{ maxWidth: 500, p:2,borderRadius:'10px',boxShadow:' 0px 4px 20px #efefef', backgroundColor:'#F1F2C8' }}>
     
       <CardBody>
       <Box sx={{display:'flex',flexDirection:'column',p:1.5,backgroundColor:'#C7CB0B',borderRadius:'10px',flex:1}}>
       <Typography sx={{color:'#ffffff',mb:0.5,fontWeight:600,letterSpacing:0.5, fontSize:'14px'}}>
           Start Date
        </Typography>
        <Typography >
          <DatePicker
            maxDate={new Date()}
            style={{fontSize:'12px'}}
            value={start_date} 
            onChange={changeStartDate} 
          />
        </Typography>
       </Box>
       
       <Box sx={{display:'flex',flexDirection:'column', justifyContent:'center', m:1 }}>
       <Typography sx={{p:1.5,backgroundColor:'#ffffff',borderRadius:'10px', fontSize:'12px'}} >
            {days > 0 ? days : (ini_diff + 1)} Days
        </Typography>
       </Box>

       <Box sx={{display:'flex',flexDirection:'column',p:1.5,backgroundColor:'#C7CB0B',borderRadius:'10px',flex:1}}>
       <Typography sx={{color:'#ffffff',mb:0.5,fontWeight:600,letterSpacing:0.5, fontSize:'14px'}}>
          End Date
        </Typography>
        <Typography >
        <DatePicker
          maxDate={new Date()}
          minDate={start_date}
          value={end_date} 
          onChange={changeEndDate} />
        </Typography>
       </Box>
       </CardBody>
 
    
    </Card>
    </Dialog>
  );
}
