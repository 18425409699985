import React, { useState } from 'react';
import { Typography, Box, Grid, Card, IconButton, Tooltip } from '@mui/material';
import { AssignIcon, ContactCard, CustemBtn, DatePicker, InputBox, SelectDropDown, TextArea } from '../../components/custom';
import validate from '../../validation/validation';
import { useDispatch, useSelector } from 'react-redux';
import { experimentalStyled as styled } from '@mui/material/styles';
import DisplayError from '../../validation/DisplayError';
import TaskDetails from './TaskDetails';
import moment from 'moment';
import { H7TablePrimeContent, T5Normaltext, H5FormLabel } from '../../Theme/theme'
import themeColor from '../../Theme/themeColor'
import AdminRole from '../../adminrole/Adminrole';
import swal from 'sweetalert';
import { opportunityAssignedForUser } from '../../Redux/Thunk/createopportunitythunk';
import AssignModal from '../../components/custom/AssignModal';
import { createTaskForLead } from '../../Redux/Thunk/createTaskthunk';

const CardBody = styled('div')({
    backgroundColor: '#FFFFFF',
    padding: '10px 20px 20px',
    borderRadius: '10px',
})

const task_status = [{ value: "Assigned", label: "Assigned" }, { value: "Completed", label: "Completed" }, { value: "Customer Side Pending", label: "Customer Side Pending" },
{ value: "Inprocess", label: "Inprocess" }, { value: "Pending Input", label: "Pending Input" }, { value: "Roll Over", label: "Roll Over" }]

export const LeadTaskDetails = ({ handleCloseTask, ids, task, show, opportunity, taskDetails, For }) => {
    const [error, setError] = useState([])
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const [openAssignModal, setOpenAssignModal] = useState(false)
    const [opportunityAssigned, setOpportunityAssigned] = useState({})
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const [update, setUpdate] = useState(false)
    const [closeTask, setCloseTask] = useState({
        missing_deadline: '',
        closing_remark: '',
        status: task ? task?.status:''
    })
    let currentDate = new Date()
    const title = 'Task'
   
    const handleClose = (e) => {
        setCloseTask({ ...closeTask, [e.target.name]: e.target.value })
    }

    console.log(opportunity, task, "opportunity")
    const findIds= (id1, id2) => {
        if (ids?.includes(id1) || ids?.includes(id2)) {
          return true 
        } else {
          return false
        }
    }

    let validation_input = [
        currentDate > new Date(task?.end_date) ? { tagid: 'missing_deadline', text: closeTask?.missing_deadline, required: true, regex_name: 'free_text', errmsg: 'Please add missing deadline remark between 100 to 2000 characters.', min: 100, max: 2000 } : '',
        (task?.closing_remark == '') ? { tagid: 'closing_remark', text: closeTask?.closing_remark, required: true, regex_name: 'free_text', errmsg: 'Please add closing remark between 100 to 2000 characters.', min: 100, max: 2000 } : '',
        // { tagid: 'status', text: closeTask?.status, required: true, regex_name: 'text', errmsg: 'Please Select Status.' }
    ]

    const submitTask = (task) => {
        closeTask.task_type = task?.task_type
        closeTask.subject = task?.subject
        closeTask.start_date = task?.start_date
        closeTask.end_date = task?.end_date
        closeTask.operationmode = 'edit'
        closeTask.task_for = task?.task_for
        closeTask.taskid = task?.id
        if (validate(validation_input).length === 0) {
            handleCloseTask(closeTask, setCloseTask, setError)
        } else {
            setError(validate(validation_input))
        }
    }

    const handleAssign = () => {
        setOpportunityAssigned(task)
        setOpenAssignModal(true)
    }

    const saveAssignedManagerOrExecutiveName = (managerList, selectManagerName) => {
        let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectManagerName)
        dispatch(createTaskForLead({start_date:task?.start_date, end_date:task?.end_date, task_type: task?.task_type, missing_deadline: task?.missing_deadline, closing_remark: task?.closing_remark, assigned_to: findUser?.id, task_for: task?.task_for, taskid: task?.id, status:task?.status, operationmode:'edit'})).then((res) => {
            if (res.payload.errormsg) {
                sweetalert('error', res?.payload?.errormsg)
                setOpenAssignModal(false)
            } else {
                sweetalert('success', "Task Assigned Successfully")
                setUpdate(!update)
                setOpenAssignModal(false)
            }})
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    }

    return (
        <>

            {/* <Box sx={{mt:2, p:2, ml:{xs:1, sm:1, xl:3, lg:1}, mr:{xs:1, sm:1, xl:3, lg:1}, borderRadius:'10px', backgroundColor:'#F0EFFD'}}> */}
            <CardBody sx={{ pb: 2, mt:1, ml:0.5 }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', gap:"5px" }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                    <Grid item xs={12} md={4} sm={4} lg={2}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                            Task ID
                        </H7TablePrimeContent>
                        {
                            task?.task_no ?  <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                            {task?.task_no}
                        </T5Normaltext> : ''
                        }
                    </Grid>
                    <Grid  item xs={12} md={4} sm={4} lg={4}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                            Type
                        </H7TablePrimeContent>
                        {
                            task?.task_type ?  <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                            {task?.task_type}
                        </T5Normaltext> : ''
                        }
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={3}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                            Start Date
                        </H7TablePrimeContent>
                        {
                            task?.start_date ?  <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                            {moment(task?.start_date).format("DD MMM YYYY")}
                        </T5Normaltext> : ''
                        }
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={3}>
                        <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel }}>
                            End Date
                        </H7TablePrimeContent>
                        {
                            task?.start_date ?  <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                            {moment(task?.end_date).format("DD MMM YYYY")}
                        </T5Normaltext> : ''
                        }
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={5}>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel}}>
                                Assign To
                            </H7TablePrimeContent>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <T5Normaltext sx={{
                                    color: themeColor.palette.primarytext2,
                                    whiteSpace: 'pre-line', // Allow line breaks
                                    wordBreak: 'break-word', // Break long words
                                    maxWidth: 'auto',}}>
                                    { `${task?.assigned_to?.firstname} ${task?.assigned_to?.lastname}`}
                                </T5Normaltext>
                                <span style={{flexShrink: 0}}> 
                                    {(checkAdminRole && permissionsDetails?.perm_taskAssign_edit) || (permissionsDetails?.perm_taskAssign_edit && findIds(task?.assigned_to?.id)) || (user?.data?.user?.id == task?.created_by?.id) ? <>
                                    <Tooltip id='assign_task' title = "Assign Task to" placement = "top-start">
                                    <IconButton onClick={()=> (opportunity?.opportunity_stage ==='ClosedWon' || opportunity?.opportunity_stage === 'ClosedLost' || opportunity?.opportunity_stage ==="Closed-No-Action" || task?.closing_remark !=='') ? '' : handleAssign()}>
                                        {opportunity?.opportunity_stage ==='ClosedWon' || opportunity?.opportunity_stage === 'ClosedLost' || opportunity?.opportunity_stage ==="Closed-No-Action" || task?.closing_remark !== '' ? <></>: <AssignIcon /> } 
                                        </IconButton> 
                                    </Tooltip></>: " "}
                                </span>
                            </div>
                    </Grid>

                    
                    <Grid item xs={12} md={4} sm={4} lg={3}>
                    {task?.closed_on ? <>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel}}>
                            Closed On
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2, }}>
                         { task?.closed_on != 0 ? moment(Number(task?.closed_on)).format('DD MMM YYYY') : '-'}
                        </T5Normaltext>
                            </>: <Grid item xs={12} md={4} sm={4} lg={4}></Grid>
                        }
                    </Grid> 
                </Grid>

                {/*  Requirement   */}
                <Grid container sx={{ display: 'flex',flexWrap:'wrap', justifyContent: 'space-between', mb:0.5, paddingRight: '10px' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                    <Grid item xs={12} md={6} sm={6} lg={12}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, mt: 1 }}>
                            Requirement
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                          {task?.subject}
                        </T5Normaltext>
                    </Grid>
                </Grid>

                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, mb:1}} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                    
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                        {(((show && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create)) && task?.closing_remark === '') ?
                            <>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Stage<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                    <SelectDropDown
                                        id='status'
                                        name='status'
                                        // disabled={task?.closing_remark !== ''}
                                        placeholder='Select Field'
                                        options={task_status}
                                        onchange={handleClose}
                                        value={closeTask?.status}
                                        sx={{ p: 0.1, mt: 0.5, backgroundColor:'#ffffff', borderRadius: "10px" }}
                                        // MenuProps={MenuProps}
                                    />
                                    <DisplayError error={error} fortagid='status' />
                                </> :<>
                                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Stage</H5FormLabel>
                                        <T5Normaltext sx={{ color: themeColor.palette.primarytext2}}>
                                        {task?.status ? task?.status : '__'}
                                        </T5Normaltext>
                            </>}

                           {/* {(!show || task?.closing_remark !== '') ? <> 
                                <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}>Stage</H7TablePrimeContent>
                                <T5Normaltext sx={{ color: themeColor.palette.primarytext2}}>
                                    {task?.status ? task?.status : '__'}
                                </T5Normaltext>
                           </> :<>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel}}>Stage<span style={{color:'#FF0000'}}>*</span></H7TablePrimeContent>
                            <SelectDropDown
                                name='status'
                                // disabled={taskedit?.closing_remark !== ''}
                                placeholder='Select Field'
                                options={task_status}
                                onchange={handleClose}
                                value={closeTask?.status}
                                sx={{ p: 0.1, mt: 0.5, backgroundColor:'#ffffff', borderRadius: "10px" }}
                                // MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='status' />
                            </>} */}
                        </Grid>

                    {/* <Grid item xs={12} md={4} sm={6} lg={4}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, mt: 1 }}>
                            Stage
                        </H7TablePrimeContent>
                        <SelectDropDown
                            name='status'
                            disabled={task?.closing_remark !== '' || !show}
                            placeholder='Select Field'
                            options={task_status}
                            onchange={handleClose}
                            value={closeTask?.status}
                            sx={{ p: 0.1, backgroundColor: task?.closing_remark !== '' ? '#E9E9FF' : '#ffffff', borderRadius: "10px", marginTop: '3px' }}
                        />
                        <DisplayError error={error} fortagid='status' />
                    </Grid> */}
                </Grid>


                        {(((show && permissionsDetails?.perm_task_create)) || (checkAdminRole && permissionsDetails?.perm_task_create)) ? (currentDate > new Date(task?.end_date) && task?.closing_remark === '' && task?.missing_deadline == '') ? 
                            <><Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                            <Grid container spacing={2}> 
                            <Grid item xs={12} md={4} sm={4} lg={12}> 
                                <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, mt: 1 }}>Missing Deadline Remark<span style={{color:'#FF0000'}}>*</span></H7TablePrimeContent>
                                <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                    <TextArea value={closeTask.missing_deadline?.replace(/\s\s+/g, " ")} onChange={handleClose} name='missing_deadline' style={{ width: '100%', backgroundColor: '#ffffff', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }} placeholder="Write a note about Missing Deadline."></TextArea>
                                </Grid>
                                <DisplayError error={error} fortagid='missing_deadline' />
                            </Grid>
                            {closeTask?.closing_remark =="" ?<div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                <span>{closeTask.missing_deadline?.length}/2000</span>
                            </div> :<></>} 
                            </Grid>
                            </Grid>
                            </> : ((currentDate > new Date(task?.end_date) && task?.closing_remark === '' && task?.missing_deadline !== '')) ? <>
                                <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                    <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, mt: 1}}>Missing Deadline Remark</H7TablePrimeContent>
                                    <T5Normaltext sx={{ color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                                        {task.missing_deadline}
                                    </T5Normaltext>
                                </Grid>
                             </> :<></>:<></>}

                {/* {((currentDate > new Date(task?.end_date) && task?.closing_remark === '' && ((show && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create))) || (task?.missing_deadline !== '')) ?
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, paddingLeft: '10px', paddingRight: '10px' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6} lg={12}>
                            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, mt: 1 }}>
                                Missing Deadline <span style={{ color: '#FF0000' }}>*</span>
                            </H7TablePrimeContent>
                                <TextArea disabled={task?.missing_deadline !== ''} value={closeTask.missing_deadline ? closeTask?.missing_deadline : task?.missing_deadline} name='missing_deadline' onChange={handleClose} style={{ width: '100%', paddingTop: '5px', paddingLeft: '5px', backgroundColor: task?.missing_deadline !== '' ? '#E9E9FF' : '#FFFFFF', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px' }} placeholder="Write a note about Missing Deadline."></TextArea>
                                <DisplayError error={error} fortagid='missing_deadline' />
                            </Grid>
                            {task?.missing_deadline === "" ? <><div style={{ marginLeft: 'auto', fontSize: '12px' }}>
                                <span>{closeTask?.missing_deadline?.length}/2000</span>
                            </div></> : <></>}
                        </Grid>
                    </Grid> : ''} */}

                            {task?.closing_remark === "" && ((show && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create)) ? 
                              <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={4} sm={4} lg={12}>
                                    <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, mt: 1.5 }}>Closing Remark<span style={{color:'#FF0000'}}>*</span></H7TablePrimeContent>
                                    {/* <Typography fontWeight="bold">Closing Remark<span style={{ color: '#FF0000'}}>*</span></Typography> */}
                                    <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                        <TextArea value={closeTask?.closing_remark?.replace(/\s\s+/g, " ")} onChange={handleClose} name='closing_remark' style={{ width: '100%', backgroundColor: `${task?.closing_remark !== '' ? '#E9E9FF' : '#ffffff'}`, fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }}placeholder="Write a note about Closing Remark."></TextArea>
                                    </Grid>
                                    <DisplayError error={error} fortagid='closing_remark' />
                                </Grid> 
                                    {task?.closing_remark == "" ?<div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                    <span>{closeTask.closing_remark?.length}/2000</span>
                                    </div> :<></>} 
                                </Grid>
                            </Grid>
                            : task?.closing_remark !== "" ?<Grid item xs={12} md={4} sm={4} lg={12}>
                                <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, mt: 1.5 }}>Closing Remark</H7TablePrimeContent>
                                    <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                        <T5Normaltext sx={{ color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                                            {task?.closing_remark}
                                        </T5Normaltext>
                                    </Grid>
                                </Grid>:<></>
                        }

                 {/* { ((show && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create) || task?.closing_remark !== "") ?  <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px' }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6} lg={12}>
                            <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, mt: 1.5}}>
                                Closing Remark <span style={{ color: '#FF0000' }}>*</span>
                            </H7TablePrimeContent>
                            <TextArea disabled={task?.closing_remark !== '' || !((show && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create))} value={closeTask?.closing_remark ? closeTask?.closing_remark : task?.closing_remark} name='closing_remark' onChange={handleClose} style={{width: '100%', paddingTop: '5px', paddingLeft: '5px',marginTop:'3px', backgroundColor: task?.closing_remark !== '' ? '#E9E9FF' : '#FFFFFF', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px' }} placeholder="Write a note about Closing Remark."></TextArea>
                            <DisplayError error={error} fortagid='closing_remark' />
                        </Grid>
                        {task?.closing_remark === "" ? <><div style={{ marginLeft: 'auto', fontSize: '12px' }}>
                            <span> {closeTask?.closing_remark?.length}/2000</span>
                        </div></> : <></>}
                    </Grid>
                </Grid> : ''} */}

                {
                    (task?.closing_remark === '' && ((show && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create))) ? <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
                    <CustemBtn onClick={() => submitTask(task)} variant='contained' sx={{ borderRadius: "10px", fontSize: '16px' }} text="Close Task" />
                </Box> : ''
                }
            </CardBody>
            {/* </Box> */}

            {openAssignModal && <AssignModal taskNumber={opportunityAssigned?.task_no} title={title} setUpdate={setUpdate} taskAssignedFromUser={opportunityAssigned} update={update} openAssignModal={openAssignModal} setOpenAssignModal={setOpenAssignModal} saveAssignedManagerOrExecutiveName={saveAssignedManagerOrExecutiveName} />}
        </>
    )
}