const headCells = [
    {
      id: 'Contact Name',
      numeric: false,
      disablePadding: false,
      label: 'Contact Name',
    },
    {
      id: 'Company Name',
      numeric: false,
      disablePadding: false,
      label: 'Company Name',
    },
    {
      id: 'Email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'Mobile No.',
      numeric: false,
      disablePadding: false,
      label: 'Mobile No.',
    },
    {
      id: 'DOB',
      numeric: false,
      disablePadding: false,
      label: 'DOB',
    },
    {
      id: 'Designation',
      numeric: false,
      disablePadding: false,
      label: 'Designation',
    },
    {
      id: 'Created by',
      numeric: false,
      disablePadding: false,
      label: 'Created by',
    },
    {
      id: 'Social Media',
      numeric: false,
      disablePadding: false,
      label: 'Social Media',   
    }
    
  ];

  export { headCells }