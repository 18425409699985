import React from 'react'

const AlarmIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M9.99925 18.3333C5.85715 18.3333 2.49929 14.9754 2.49929 10.8333C2.49929 6.69114 5.85715 3.33327 9.99925 3.33327C14.1414 3.33327 17.4993 6.69114 17.4993 10.8333C17.4993 14.9754 14.1414 18.3333 9.99925 18.3333ZM9.99925 16.6666C13.2209 16.6666 15.8326 14.0549 15.8326 10.8333C15.8326 7.6116 13.2209 4.99994 9.99925 4.99994C6.77763 4.99994 4.16595 7.6116 4.16595 10.8333C4.16595 14.0549 6.77763 16.6666 9.99925 16.6666ZM10.8326 10.8333H13.3326V12.4999H9.16592V6.6666H10.8326V10.8333ZM1.45508 5.23534L4.40136 2.28906L5.57987 3.46757L2.63359 6.41385L1.45508 5.23534ZM15.5973 2.28906L18.5435 5.23534L17.365 6.41385L14.4187 3.46757L15.5973 2.28906Z" fill="#C16666"/>
</svg>
  )
}

export default AlarmIcon
