import React from 'react'

const ResetIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_703_19845)">
    <path d="M8 8C8 8 11 5 16 5C23 5 27 12 27 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 24C24 24 21 27 16 27C9 27 5 20 5 20" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 12H27V6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11 20H5V26" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_703_19845">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default ResetIcon
