import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import CreateNewLeadForm from '../pages/Leads/CreateNewLeadForm'
import DropDownCreateNewLeadForm from '../pages/Leads/DropDownCreateNewLeadForm'
import AlertList from '../pages/AlertList/AlertList'
import CustPrfile from '../pages/CustProfile/CustProfile'
import Dashboard from '../pages/Dashboard/Dashboard'
import FollowUpList from '../pages/FollowUp/FollowUpList';
import LeadDetails from '../pages/LeadDetails/LeadDetails'
import OpportunityDetails from '../pages/OpportunityDetails/OpportunityDetails'
import NewOpportunityDetails from '../pages/OpportunityDetails/NewOpportunityDetails'
import OpportunityList from '../pages/Opportunity/OpportunityList'
import TaskList from '../pages/Task/TaskList'
import Login from '../pages/authorization/Login'
import Shared from './Shared'
import CreateNewOpportunity from '../pages/Opportunity/CreateNewOpportunity'
import CreateNewOpportunityForm from '../pages/Opportunity/OpportunityForm'
import LeadDetailsForOpportunity from '../pages/Opportunity/LeadDetailsForOpportunity'
import CreateUser from '../pages/User/CreateUser'
import SettingMasterList from '../pages/SettingList/SettingMasterList'
import ForgotPassword from '../pages/authorization/ForgotPassword'
import ResetPassword from '../pages/authorization/ResetPassword'
import CreatePassword from '../pages/authorization/CreatePassword'
import ContactForm from '../pages/ContactForm/ContactForm'
import CreateContact from '../pages/ContactForm/CreateContact'
import CreateTask from '../pages/Task/CreateTask'
import UserList from '../pages/User/UserList'
import CompanyList from '../pages/Company/CompanyList'
import CreateFollowUp from '../pages/FollowUp/CreateFollowUp'
import CreateAddress from '../pages/Addresspopup/CreateAddressDropDown';
import { OfficeAddress } from '../pages/CustProfile/OfficeAddress'
import { useSelector } from 'react-redux'
import { ProtectedRoute } from './ProtectedRoute';
import CompanyWiseLeads from '../pages/CustProfile/CompanyWiseLeads/CompanyWiseLeads'
import CompanyWiseOpportunity from '../pages/CustProfile/CompanyWiseOpportunity/CompanyWiseOpportunity'
import CompanyWisefollowupList from '../pages/CustProfile/CompanyWiseFollowup/CompanyWiseFollowupList'
import ContactList from '../pages/ContactForm/ContactList'
import LeadList from '../pages/Leads/LeadList'
import CompanyWiseTask from '../pages/CustProfile/CompanyWiseTask/CompanyWiseTask'
import LeadTaskList from '../pages/LeadDetails/LeadTaskList'
import { LeadFollowupList } from '../pages/LeadDetails/LeadFollowupList'
import ExecutiveList from '../pages/ExecutiveList/ExecutiveList'
import CreateCompanyForm from '../pages/Company/CreateCompanyForm'
import Dashboardnew from '../pages/Dashboard/Dashboardnew'
import NewLeadDetails from '../pages/LeadDetails/NewLeadDetails'
import NewCompanyProfile from '../pages/CustProfile/NewCompanyProfile'
import CAFDetails from '../pages/OpportunityDetails/CAF.SER.ID/CAFDetails'
import ServiceIDDetails from '../pages/OpportunityDetails/CAF.SER.ID/ServiceIDDetails'
import CafList from '../pages/OpportunityDetails/CAF.SER.ID/CafList'
import ServiceIDList from '../pages/OpportunityDetails/CAF.SER.ID/ServiceIDList'
import { Switch } from '@mui/material';
import NewSeeAllContacts from '../pages/CustProfile/NewSeeAllContacts';
import { NewOfficeAddress } from '../pages/CustProfile/NewOfficeAddress';
import CompanyWiseSeriveIDList from '../pages/CustProfile/CompanyWiseServiceID/CompanyWiseServiceID';
import CompanyWiseCafList from '../pages/CustProfile/CompanyWiseCaf/CompanyWiseCafList';

 const ExecutiveRoute = ({isAuthenticated}) => {
  const {user} = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  console.log(permissionsDetails,"permissionsDetails")
  
  return (
    <Routes>
    {/* / <Router> */}
    <>
    
    {/* Authoriszation Routes */}
    <Route path='/' element={<Login/>}/>
    <Route path='/forgot-password' element={<ForgotPassword/>} />
    <Route path='/resetpassword' element={<ResetPassword/>} />
    <Route path='/updatepassword' element={<CreatePassword/>} />
       
    {/* {isAuthenticated ?<> */}
    
    <Route path='/dashboard' element={<Shared/>} >
      <Route index element={<Dashboardnew/>}/>

      {/* Lead Routing */}
      {permissionsDetails?.perm_lead_view? <Route path='/dashboard/leadlist' element={<LeadList/>}/> :<></>}
      {/* <Route path='/dashboard/leaddetails' element={<LeadDetails/>}/> */}
      <Route path='/dashboard/leaddetails' element={<NewLeadDetails/>}/>
      <Route path='/dashboard/createlead' element={<DropDownCreateNewLeadForm/>}/> 
      {permissionsDetails?.perm_lead_create || permissionsDetails?.perm_lead_edit || (permissionsDetails?.perm_lead_create && permissionsDetails?.perm_lead_edit) ? <> 
      <Route path='/dashboard/lead' element={<CreateNewLeadForm/>}/> </> : <></>}
      
      {/*  Profile Rounting*/}
      {/* {permissionsDetails?.perm_company_view ? */}
      {/* <Route path='/dashboard/profile' element={<CustPrfile/>}/> */}
      <Route path='/dashboard/profile' element={<NewCompanyProfile />}/>
       {/* :<></>} */}
      
      {/* Company Wise leads List */}
      {permissionsDetails?.perm_lead_view ?
      <Route path='/dashboard/profile/companyLeads' element={<CompanyWiseLeads/>}/> :<></>}
      
      {/* company wise Opportunity List */}
      {permissionsDetails?.perm_opportunity_view ?
      <Route path='/dashboard/profile/companyOpportunity' element={<CompanyWiseOpportunity/>}/> :<></>}

       {/* company wise followup List */}
       {permissionsDetails?.perm_followup_view ?
       <Route path='/dashboard/profile/companyFollowup' element={<CompanyWisefollowupList/>}/> :<></>}

       {/* company wise task list */}
       {permissionsDetails?.perm_task_view ?
       <Route path='/dashboard/profile/companyTask' element={<CompanyWiseTask/>}/> :<></> }

      {/* followuplist routing */}
      {permissionsDetails?.perm_followup_view ? <Route path='/dashboard/followuplist' element={<FollowUpList/>}/>: <></>} 
      <Route path='/dashboard/followupdetails' element={<OpportunityDetails/>}/>
      {permissionsDetails?.perm_followup_create ? <Route path='/dashboard/createfollowup' element={<CreateFollowUp/>}/>:<></>} 

      {/* Opportunity list routing */}
      {permissionsDetails?.perm_opportunity_view ? <Route path='/dashboard/opportunitylist' element={<OpportunityList/>}/> : <></> }
      <Route path='/dashboard/opportunitydetails' element={<NewOpportunityDetails/>}/>
      {/* <Route path='/dashboard/newopportunitydetails' element={<NewOpportunityDetails/>}/> */}

      {/* alert List */}
      <Route path='/dashboard/alertlist' element={<AlertList/>}/>
      
      {/* floating button navigaton */}
      {permissionsDetails?.perm_opportunity_create ? <> <Route path='/dashboard/createopportunity' element={<CreateNewOpportunity/>}/> 
      <Route path='/dashboard/opportunity' element={<CreateNewOpportunityForm/>}/> </>: <></>}
      <Route path='/dashboard/createopportunitylead' element={<LeadDetailsForOpportunity/>}/>
      
      {/* create company details */}
      {(permissionsDetails?.perm_company_create || permissionsDetails?.perm_company_edit ||(permissionsDetails?.perm_company_create && permissionsDetails?.perm_company_edit )) ? <Route path='/dashboard/company' element={<CreateCompanyForm/>}/> : <></>}
      {permissionsDetails?.perm_company_view ? <Route path='/dashboard/companylist' element={<CompanyList/>}/> :<></>}
  
      {/* create task details */}
      {permissionsDetails?.perm_task_create ? <Route path='/dashboard/createtask' element={<CreateTask/>}/> : <></>}

       {permissionsDetails?.perm_task_view ? <Route path='/dashboard/tasklist' element={<TaskList/>}/> :<></>}

      <Route path='/dashboard/tasklistdetails' element={<OpportunityDetails/>}/>    

      {/* create contact details */}
      {permissionsDetails?.perm_contact_create || permissionsDetails?.perm_contact_edit || (permissionsDetails?.perm_contact_edit && permissionsDetails?.perm_contact_create) ? <Route path='/dashboard/createcontact' element={<ContactForm/>}/> :<></>}
      {/* <Route path='/dashboard/createcontactdetails' element={<CreateContact/>}/> */}

      {/* for manager dashboard page navigaton  create user*/}
      {permissionsDetails?.perm_user_create || permissionsDetails?.perm_user_edit ? <Route path='/dashboard/user' element={<CreateUser/>}/> :<></>}
      
      {/* address routes */}
      {permissionsDetails?.perm_address_create || permissionsDetails?.perm_address_edit || (permissionsDetails?.perm_address_create || permissionsDetails?.perm_address_edit)? <Route path='/dashboard/address' element={<CreateAddress/>}/>:<></>}

      {/*see all office address (branches , head and resistered address) */}
      <Route path='/dashboard/allcontacts' element={<NewSeeAllContacts/>}/>
      <Route path='/dashboard/officeaddress' element={<NewOfficeAddress/>}/>

      {/* setting page */}
      {permissionsDetails?.perm_settings_view ? <Route path='/dashboard/setting' element={<SettingMasterList/>}/> :<></>}
      
      {/* userlist */}
      {permissionsDetails?.perm_user_view ? <Route path='/dashboard/userlist' element={<UserList/>}/> :<></>}

      {/* Contact List */}
      {permissionsDetails?.perm_contact_view ? <Route path='/dashboard/contactlist' element={<ContactList/>}/> :<></>}

     {/*  In Lead details page display task list(see all ) */}
      <Route path='/dashboard/leadtasklist' element={<LeadTaskList/>}/>

     {/*  In Lead details page display followup list(see all ) */}
      <Route path='/dashboard/leadfollowuplist' element={<LeadFollowupList/>}/>

      {/* Executive List*/}
      <Route path='/dashboard/performancelist' element={<ExecutiveList/>}/>

      {/* for COF Details */}
      <Route path='/dashboard/cof' element={<CAFDetails/>}/>
      <Route path='/dashboard/coflist' element={<CafList/>}/>


    {/* for Service ID Details */}
    <Route path='/dashboard/service' element={<ServiceIDDetails/>}/>
    <Route path='/dashboard/servicelist' element={<ServiceIDList/>}/>

    {/* Company wise service id list and CAF list */}
    <Route path='/dashboard/profile/companyservicelist' element={<CompanyWiseSeriveIDList/>}/>
    <Route path='/dashboard/profile/companycaflist' element={<CompanyWiseCafList/>}/>

    </Route>
    </>
    

    {/* </> :<>
    <Route path='/' element={<Login/>}/>
    </>} */}
    {/* </> */} {/* </Router> */}
   </Routes>
  )
}

export default ExecutiveRoute