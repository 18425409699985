import React from 'react'

const CallIconWhite = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
    <path d="M16.1562 1.5C18.2807 2.05946 20.2187 3.17276 21.7721 4.72622C23.3256 6.27968 24.4389 8.21761 24.9984 10.3421" stroke="#EFEFEF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.6621 5.5C18.2705 6.19789 20.0168 7.94421 20.7147 10.5526" stroke="#EFEFEF" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.0826 15.6677C16.217 15.5783 16.3716 15.5238 16.5323 15.5092C16.693 15.4946 16.8549 15.5203 17.0032 15.584L22.7229 18.1468C22.9157 18.2292 23.0766 18.3718 23.1815 18.5532C23.2865 18.7347 23.3298 18.9453 23.3051 19.1534C23.1167 20.5616 22.4232 21.8534 21.3537 22.7886C20.2842 23.7238 18.9115 24.2387 17.4907 24.2377C13.1161 24.2377 8.92061 22.4998 5.82727 19.4065C2.73392 16.3131 0.996095 12.1177 0.996095 7.74301C0.995009 6.3223 1.50995 4.94954 2.44515 3.88003C3.38035 2.81053 4.67216 2.11708 6.08033 1.92865C6.28849 1.90392 6.49907 1.94727 6.68053 2.05222C6.862 2.15716 7.0046 2.31806 7.08699 2.51082L9.64972 8.23543C9.71269 8.38246 9.73833 8.54278 9.72436 8.70211C9.7104 8.86145 9.65726 9.01486 9.56967 9.1487L6.97783 12.2305C6.88589 12.3693 6.83153 12.5295 6.82005 12.6955C6.80857 12.8615 6.84037 13.0277 6.91234 13.1778C7.91536 15.2311 10.0378 17.3281 12.0972 18.3214C12.2481 18.3931 12.415 18.4242 12.5816 18.4116C12.7481 18.3991 12.9085 18.3433 13.0469 18.2499L16.0826 15.6677Z" fill="#EFEFEF" stroke="#EFEFEF" stroke-width="0.768133" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}

export default CallIconWhite
