const LeadDetailsContactIcon = () => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="44" height="44" rx="22" fill="#E4E4E4"/>
        <g clip-path="url(#clip0_4644_131928)">
        <path d="M22.875 23.75C24.808 23.75 26.375 22.183 26.375 20.25C26.375 18.317 24.808 16.75 22.875 16.75C20.942 16.75 19.375 18.317 19.375 20.25C19.375 22.183 20.942 23.75 22.875 23.75Z" stroke="#3107AA" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.5 15.875H14.125" stroke="#3107AA" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.5 22H14.125" stroke="#3107AA" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.5 28.125H14.125" stroke="#3107AA" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.625 26.375C18.2363 25.56 19.0289 24.8984 19.9402 24.4428C20.8514 23.9872 21.8562 23.75 22.875 23.75C23.8938 23.75 24.8986 23.9872 25.8098 24.4428C26.7211 24.8984 27.5137 25.56 28.125 26.375" stroke="#3107AA" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.625 31.625V12.375C31.625 11.8918 31.2332 11.5 30.75 11.5L15 11.5C14.5168 11.5 14.125 11.8918 14.125 12.375V31.625C14.125 32.1082 14.5168 32.5 15 32.5H30.75C31.2332 32.5 31.625 32.1082 31.625 31.625Z" stroke="#3107AA" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_4644_131928">
        <rect width="28" height="28" fill="white" transform="translate(8 8)"/>
        </clipPath>
        </defs>
        </svg>
    )
  }
  
  export default LeadDetailsContactIcon