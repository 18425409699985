import React from 'react'
import {Outlet} from 'react-router-dom'
import { Navbar } from '../components/custom';

const Shared = () => {
  return (
    <>
      <Navbar/>
      <Outlet/>
    </>
  )
}

export default Shared;