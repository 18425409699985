
const LeadClosedNoActionStatusIcon = () => {

    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4117_134053)">
        <path d="M16 28.5C22.6274 28.5 28 23.1274 28 16.5C28 9.87258 22.6274 4.5 16 4.5C9.37258 4.5 4 9.87258 4 16.5C4 23.1274 9.37258 28.5 16 28.5Z" fill="#630000" stroke="#630000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.5013 24.8327C11.8989 24.8327 8.16797 21.1017 8.16797 16.4993C8.16797 11.897 11.8989 8.16602 16.5013 8.16602C21.1036 8.16602 24.8346 11.897 24.8346 16.4993C24.8346 21.1017 21.1036 24.8327 16.5013 24.8327ZM13.168 18.166V19.8327H19.8346V18.166H13.168ZM13.168 15.666C13.8583 15.666 14.418 15.1063 14.418 14.416C14.418 13.7257 13.8583 13.166 13.168 13.166C12.4776 13.166 11.918 13.7257 11.918 14.416C11.918 15.1063 12.4776 15.666 13.168 15.666ZM19.8346 15.666C20.525 15.666 21.0846 15.1063 21.0846 14.416C21.0846 13.7257 20.525 13.166 19.8346 13.166C19.1443 13.166 18.5846 13.7257 18.5846 14.416C18.5846 15.1063 19.1443 15.666 19.8346 15.666Z" fill="#A9A9A9"/>
        </g>
        <defs>
        <clipPath id="clip0_4117_134053">
        <rect width="32" height="32" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>
    )}

export default LeadClosedNoActionStatusIcon 
