import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";


export const createlead = createAsyncThunk('data/createLead',
    async(data)=>{
    const res = await axiosInstance.post('/createlead',data);
     return res.data
    }
  
)

export const displayLeadList = createAsyncThunk('data/leadList',
   async(data) => {
    const res = await axiosInstance.post('/fetchleadlist',data);
    return res.data
   }
)

export const editlead = createAsyncThunk('data/editlead',
   async(data) => {
    const res = await axiosInstance.post('/leadedit',data);
    return res.data
   }
)

export const leadValidate = createAsyncThunk('data/leadapproved',
   async(data) => {
    const res = await axiosInstance.post('/leadapproved',data);
    return res.data
   }
)

export const leadAssignedToUser = createAsyncThunk('data/leadAssigned',
   async(data) => {
    const res = await axiosInstance.post('/leadAssigned',data);
    return res.data
   }
)

export const companyLeads = createAsyncThunk('data/companyLeads',
   async(data) =>{
      const res = await axiosInstance.post('/companyLeadList', data);
      return res.data
   }
)

export const leaddetails = createAsyncThunk('data/leaddetails',
   async(data) =>{
      const res = await axiosInstance.get(`/leaddetails/${data}`);
      return res.data
   }
)

export const leadLost = createAsyncThunk('data/leadLost',
   async(data) =>{
      const res = await axiosInstance.post('/leadlost', data);
      return res.data
   }
)

