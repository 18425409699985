
import React, {useState, useEffect} from 'react';
import { Typography,Card,CardContent, Grid, Box, Button, CircularProgress} from '@mui/material';
import { CustemBtn, DeleteIcon, EditIcon, InputBox, SelectDropDown} from '../../components/custom';
import { useDispatch, useSelector } from 'react-redux';
import {createProductTypes, getProductFamilyTypesList, getProductTypesList, getProductsProductFamilyTypes} from '../../Redux/Thunk/createsettinglist';
import swal from 'sweetalert';
import { CardHeader, CardInfo} from './SettingMasterList.style'
import { yellow } from '@mui/material/colors';
import { TailSpin } from 'react-loader-spinner';

export default function ProductList ({permissionsDetails}){
    const dispatch = useDispatch();
    const {product, getServiceProviderTypes, getproductFamilyList} = useSelector(state => state.settingList)
    const [productType, setProductType]= useState([]) 
    // const [getproductFamily, setGetProductFamily] = useState([])
    const [openButton, setOpenButton] = useState(false)
    const [buttonStatus, setButtonStatus] = useState(false);
    const [indexValue, setIndexValue] = useState('') 
    let initallySelectedServiceProviderValue = getServiceProviderTypes?.value?.map((name)=> ({value : name, label:name}))
    const [serviceProvider, setServiceProvider] = useState('');
    // let initallySelectedValue = (getproductFamilyList??[])?.value?.map((name)=> ({value : name, label:name}))
    const [productFamily, setProductFamily]= useState('');
    const [inputFieldStatus, setInputFieldstatus] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [loader, setLoader] = useState(true)
    const [flag ,setFlag]= useState(false)
    const [disabled, setDisabled]= useState(false)
    const [buttonDisabledForSave, setButtonDisabledForSave] = useState(false)
    const [values, setValues] = useState({
        value:'',
        index:''
    })
  
    useEffect(()=>{
        if (serviceProvider?.length === 0 && productFamily?.length === 0) {
            console.log('Both arrays are empty');
            setLoader(false)
        } else {
        const concatenated = serviceProvider?.concat(productFamily); 
        setLoader(true)
        dispatch(getProductTypesList({selected_productfamily: concatenated ??''})).then((res)=>{
            setProductType(res?.payload?.value) 
            setButtonStatus(false)    
            setInputFieldstatus(false)
            setValues({ value:'', index:'' })
            setLoader(false)
        })
    }
    },[productFamily, product, serviceProvider])
   console.log(getproductFamilyList,"getproductFamilyList")
    useEffect(()=>{
        // dispatch(getProductFamilyTypesList({selected_serviceprovider:serviceProvider})).then((res)=>{
        //     setGetProductFamily(res?.payload?.value)
        // })
    },[serviceProvider])
    
    const createServiceProvider =(event) =>{
       setButtonStatus(true)
       setValues({value: event.target.value?.trimStart()})
    }
  
    const addProductType = () =>{ 
        if(productType?.find(item =>item?.toUpperCase()=== values?.value?.toUpperCase())){
            sweetAlert("error", "This field is already exit")
        }else if(values?.value === ''){
            sweetAlert("error", "Not valid.")
        }else{
            setButtonDisabled(true)
            const value = [...productType, values?.value?.trim()]
            dispatch(createProductTypes({selected_productfamily: serviceProvider?.concat(productFamily), setting_product_list:value})).then((res) =>{
                if(res?.payload?.errormsg){
                    setButtonDisabled(false)
                    sweetAlert("error", res?.payload?.errormsg)
                }else{
                    sweetAlert("success", "Product/Service Created Successfully.")
                    setButtonDisabled(false)
                    setValues({ value:'', index:'' })
                    setButtonStatus(false)
                    setFlag(!flag)
                }
            }) 
        }
    }

    const sweetAlert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
        })
    };

    const editProductType = (index) =>{
        setOpenButton(true)
        setIndexValue(index)
        setButtonStatus(false)
        setInputFieldstatus(true)
    }
  
    const handleChangeProductType =(event, index)=>{
        setValues({value:event.target.value?.trimStart(), index:index})
    }
  
    const handleOfProductFamily = (event) =>{
        setProductFamily(event.target.value)
        setOpenButton(false)
        // dispatch(getProductsProductFamilyTypes({selected_productfamily: event.target.value}))
    }

    const handleOfServiceProvider=(event) =>{
        // setGetProductFamily([])
        setServiceProvider(event.target.value)
        setDisabled(true)
        dispatch(getProductFamilyTypesList({selected_serviceprovider:event.target.value})).then((res)=>{
            // setGetProductFamily(res?.payload?.value)
            setDisabled(false)
        })
        setProductType([])
        setOpenButton(false)
        // setButtonStatus(true)
        // setInputFieldstatus(true)
    }

    const saveProductType =() =>{
        const data= productType?.slice();
        data.splice(values?.index, 1, values?.value);
    
        const isDuplicate = data?.some(
          (item, index) =>
            item?.toUpperCase() === values?.value?.toUpperCase() && index !== values?.index
        );
      
          if (isDuplicate) {
              sweetAlert("error", "This Product/Service is already exit")
          }else if(values?.value === ''){
            sweetAlert("error", "Not valid.")
          }else{
            swal({
                buttons: {
                Cancel: {
                    text: "Cancel",
                    value: "Cancel",
                    className: "popup-blue",
                },
                Delete: {
                    text: "Confirm",
                    value: "Confirm",
                    className: "popup-gray",
                },
                },
                title: `Do you want to Save the Product/Service option ${values.value} ?`,
                className: "custom-modal",
                closeOnClickOutside: false,
              }).then((value)=>{
                if(value=="Confirm"){
                    setButtonDisabledForSave(true)
                    dispatch(createProductTypes({selected_productfamily: serviceProvider?.concat(productFamily), setting_product_list:data})).then((res) =>{
                        if(res?.payload?.errormsg){
                            setButtonDisabledForSave(false)
                            sweetAlert("error", res?.payload?.errormsg)
                        }else{
                            sweetAlert("success", "Product/Service Updated Successfully.")
                            setOpenButton(false)
                            setButtonStatus(true)
                            setButtonDisabledForSave(false)
                            setFlag(!flag)
                            setValues({ value:'', index:'' })
                        }
                    })
                }else{
                    setOpenButton(false)
                    setInputFieldstatus(false)
                    setButtonDisabledForSave(false)
                    setValues({ value:'', index:'' })
                }
            })     
        }
    }
     
    const deleteProductType =(index, type) =>{
        swal({
            buttons: {
            Cancel: {
                text: "Cancel",
                value: "Cancel",
                className: "popup-blue",
            },
            Delete: {
                text: "Confirm",
                value: "Confirm",
                className: "popup-gray",
            },
            },
            title: `Do you want to delete the Product/Service option ${type} ?`,
            className: "custom-modal",
            closeOnClickOutside: false,
          }).then((value)=>{
              if(value =="Confirm"){
                setLoader(true)
                const data= productType.slice();
                data.splice(index, 1);
                dispatch(createProductTypes({selected_productfamily: serviceProvider?.concat(productFamily), setting_product_list:data})).then((res)=>{
                    if(res?.payload?.errormsg){
                        sweetAlert("error", res?.payload?.errormsg)
                        setLoader(false)
                    }else{
                        sweetAlert("success", "Product/Service Deleted Successfully.")
                        setFlag(!flag)
                        // setLoader(false)
                        setValues({ value:'', index:'' })
                    }
                })
              }
          })
    }

    return(
        <Card sx={{ maxWidth: '100%',borderRadius:'10px',boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
           
                <CardHeader>
                    <Typography sx={{color:'#444444',fontSize:'18px',fontWeight:600}}>
                        Product / Service
                    </Typography>
                </CardHeader>
                <CardContent style={{ display: "flex",
                    flexDirection: "column",
                    height: 300,
                    overflow: "hidden",
                    overflowY: "scroll"}}>
                <Box style={{ minWidth: 120}}>
                    <Typography>
                        Select Service Provider
                    </Typography>
                    <SelectDropDown 
                        name='serviceProvider'
                        options={getServiceProviderTypes?.value?.map((name)=>({value : name, label:name}))} 
                        onchange={handleOfServiceProvider}
                        // defaultlabel='--Select Name--'
                        // Default={productFamily}
                         value = {serviceProvider}
                         placeholder='Select Service Provider'
                        // label ='Select Product Family'
                        sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                    />
                    {/* <small style={{backgroundColor:'#f7ca25'}}>Select a service provider</small> */}
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    { loader ? <Box sx={{height:'300px', alignItems:'center', display:'flex', mt:0.5, justifyContent:"center", borderRadius:'10px'}}>
                        <TailSpin
                          height="30"
                          width="30"
                          color="blue"
                          ariaLabel="tail-spin-loading"
                          radius="3"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        /> </Box>:<>
                <Box style={{ minWidth: 120, marginTop:"10px"}}>
                    <Typography>
                        Select Product Family
                    </Typography>
                    <SelectDropDown 
                        name='contact_id'
                        options={getproductFamilyList?.value?.map((name)=>({value : name, label:name}))} 
                        onchange={handleOfProductFamily}
                        // defaultlabel='--Select Name--'
                        // Default={productFamily}
                        placeholder={disabled ? 'Loading...' :'Select Product Family'}
                        value = {productFamily}
                        // label ='Select Product Family'
                        sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                    />
                    {/* <small style={{backgroundColor:'#f7ca25'}}>Select a product family and add/edit product/service</small> */}
                </Box>
                {permissionsDetails?.perm_settings_create ?<><CardInfo sx={{mt:1}}>
                    <Typography>
                        Add Product
                    </Typography> 
                    <Typography sx={{pr:2}}>
                        Action
                    </Typography> 
                </CardInfo></>:<></>}
              
                {permissionsDetails?.perm_settings_create ?<><CardInfo>
                    <InputBox sx={{width:'78%', border:"2px solid #B0AEFF", borderRadius:'10px', pl:1, backgroundColor:inputFieldStatus ||(serviceProvider?.length === 0 && productFamily?.length === 0) ?'#C1C1C1' :'#FFFFFF'}} name='industry' disable={inputFieldStatus || (serviceProvider?.length === 0 && productFamily?.length === 0)} value={values.value} onchange ={createServiceProvider} placeholder='Product'/>
                    <Button
                      id="validate" variant="contained" disabled={!buttonStatus} onClick={() => buttonDisabled ? '' : addProductType()}
                      sx={{borderRadius:"10px", pl:2, pr:2, fontSize:'16px'}}
                      >
                      {buttonDisabled ? <CircularProgress size={20} color="inherit" /> : 'ADD'}
                    </Button>
                    {/* <Typography>
                     <CustemBtn variant="contained" sx={{backgroundColor:'#84c75a'}} text="ADD" disabled={!buttonStatus} onClick={addProductType}/> 
                    </Typography> */}
                </CardInfo></>:<></>}
               
                {productType?.map((types, index) =>( <>
                    <CardInfo sx={{mt:2}} key={index}>
                        {openButton && indexValue===index ? <><InputBox sx={{width:'78%', border:"1px solid #B0AEFF", borderRadius:'10px', pl:1}} Default={types} onchange={(event)=>handleChangeProductType(event, index)}/>
                            <Button
                                id="validate" variant="contained" onClick={() => buttonDisabledForSave ? '' : saveProductType()}
                                sx={{textTransform: 'none', borderRadius:"10px", pl:2, pr:2, fontSize:'16px'}}
                                >
                                {buttonDisabledForSave ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                            </Button>
                          
                            </> :<> 
                            <InputBox sx={{width:'78%', borderBottom:"0.5px solid #F3F3F3", pl:1}} value={types} onchange={(event)=>handleChangeProductType(event, index, types)}/>
                                {permissionsDetails?.perm_settings_edit ?<>
                                    <Typography sx={{pl:1, pt:1}} onClick={()=> {editProductType(index) ; setValues({value:types, index:index})}}>
                                        <EditIcon w={20} h={20} />
                                    </Typography>
                                    <Typography sx={{pr:1, pt:1}} onClick={() => deleteProductType(index, types)}>
                                        <DeleteIcon w={20} h={20} />
                                    </Typography> </> :<></>}
                        </>}
                    </CardInfo> </>  
                ))}</>}
                </Box>
            </CardContent>
        </Card>
    )
}