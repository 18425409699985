import { Grid, IconButton, Tooltip, Typography } from "@mui/material"
import InputBox from "./InputBox"
import FilterIcon from "./FilterIcon"
import FilterCloseIcon from "./FilterCloseIcon"
import { experimentalStyled as styled } from '@mui/material/styles';

const Search = styled('div')({
    display:'flex',
    padding:'0px 10px',
    borderRadius:'10px',
    justifyItems:'center',
     border:'1px solid #B0AEFF ',
    flexDirection:'row',
    backgroundColor:'#ffffff',
  })

  
const TableListTitle = ({title}) =>{
    return (
        <>
        {/* <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:3}}> */}
            <Grid item xs={2} display="flex" alignItems="center">
                <Typography variant="h6" sx={{ ml:{lg:5, xs:1}, fontSize: { sm: '20px', xs: '20px', md: '18px', xl: '20px', lg: '20px' }, fontWeight: 600 }}>
                    {title}
                </Typography>
                {/* <CustemBtn onClick={handleCreateFollowup} sx={{ borderRadius: '8px', ml: 2,mb:0.5 }} text="Create New Followup" variant="contained" /> */}
            </Grid>

            {/* <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Search>
                    <InputBox sx={{fontSize:'14px'}} onchange={handleSearch} placeholder="Search followup" />
                    <Typography sx={{pl:4, pt:1}}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#7673f6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M13.7051 13.6978L17.502 17.4946" stroke="#7673f6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    </Typography>
                </Search>

                <Tooltip title="New Follow Up">
                    <IconButton sx={{ml:1}} onClick={handleCreateFollowup}> 
                    <svg width="32" height="32" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="21" cy="21" r="20.5" fill="#A484FE" stroke="#A484FE"/>
                        <path d="M14.125 21H27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21 14.125V27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </IconButton>
                </Tooltip>
                    
                <Tooltip title="Filter By Status" placement="top-start">
                    <IconButton onClick={handleClick("bottom-start")} sx={{mr: {lg:4, xs:1}}}>
                        {!openPopper ? <FilterIcon /> : <FilterCloseIcon />}
                    </IconButton>
                </Tooltip>
            </Grid> */}
        {/* </Grid> */}
        </>
    )
}

export default TableListTitle 
