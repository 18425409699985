
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Box, Grid, Typography } from '@mui/material';

const IFParent = styled(Typography)(({ theme }) => ({
    display:'flex',
    justifyContent:'space-between',
    marginTop:'10px',
    marginBottom:'10px',
}));

const IFChild1 = styled(Typography)(({ theme }) => ({
    display:'flex',
    justifyContent:'start',
    flexDirection:'row',
   
}));

const IFChild2 = styled(Typography)(({ theme }) => ({
    // display:'flex',
    // justifyContent:'space-between'
}));

const IFIconBG = styled(Typography)(({ theme }) => ({
    display:'flex',
    alignItems:'center',
}));

const ActionButtons = styled(Typography)(({ theme }) => ({
    display: 'flex',
    padding: '4px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    flex: '1 0 0',
    marginTop:'5px'
}));

const ApproveBtn = styled(Typography)(({ theme }) => ({
    display: 'flex',
    padding: '4px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    flex: '1 0 0',
    borderRadius: '8px',
    border: '1px solid #3107AA'
}));

const RejectBtn = styled(Typography)(({ theme }) => ({
    display: 'flex',
    padding: '4px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    flex: '1 0 0',
    borderRadius: '8px',
    border: '1px solid #E70707'
}));

export {
    IFParent, IFChild1, IFChild2, IFIconBG, ActionButtons, ApproveBtn, RejectBtn
};