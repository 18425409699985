
const LeadOpenStatusIcon = () => {

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4024_115688)">
        <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" fill="#630000" stroke="#630000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.332 10H23.091C23.2751 10 23.4244 10.1492 23.4244 10.3333C23.4244 10.3913 23.4092 10.4483 23.3804 10.4987L20.9987 14.6667L23.3804 18.8346C23.4717 18.9945 23.4162 19.1981 23.2564 19.2894C23.206 19.3182 23.149 19.3333 23.091 19.3333H11.6654V22.6667H10.332V10Z" fill="#F9F9F9"/>
        </g>
        <defs>
        <clipPath id="clip0_4024_115688">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )}

export default LeadOpenStatusIcon

