import * as React from 'react';
import { styled,Box,InputBase,Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import  ProgressBar  from './ProgressBar';
import EditIcon from './EditIcon';
import OppoGoToDetailsBtn from './CustemBtn';
import InputBox from './InputBox';
import AddIcon from './AddIcon';
import DeleteIcon from './DeleteIcon';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardHeaders = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    borderBottom:'0.5px solid #BBBBBB',
})

const CardBody = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    marginTop:"10px",
})

const ChildCard = styled('div')({
   backgroundColor:'#FFFFFF',
   borderRadius:'10px',
   boxShadow:' 0px 4px 10px rgba(0, 0, 0, 0.2)'
})

export default function ContactCard({CardView,sx}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ borderRadius:'10px',boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)', ...sx }}>
      <CardContent>
       {CardView}
      </CardContent>
    </Card>
  );
}
