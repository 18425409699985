import React,{useState} from 'react'
import { Box } from '@mui/material'
import { H1FormTitle, T6TableText, T1ButtonText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T2popupSubText } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor'
import { useSelector } from 'react-redux';
import { EditCommercial, Identified, QualifiedPending, Rejected } from './Icons';
import { ActionButtons, ApproveBtn, IFChild1, IFChild2, IFIconBG, IFParent, RejectBtn } from './NewOppoDetailscss';
import CreateCommercials from './CreateCommercials';

const OppStageName = ({stage, name, commercials}) => {

    return (
        <>
            {(
                () => {
                    if (stage?.status == 'Pending' && (stage?.for_stage == 'Qualified' || stage?.for_stage == 'Feasible' || stage?.for_stage == 'Solution' || stage?.for_stage == 'Commercials' || stage?.for_stage == 'Proposal'
                    || stage?.for_stage == 'NegotiationCommercials' || stage?.for_stage == 'NegotiationProposal' || stage?.for_stage == 'VerbalConfirmation' || stage?.for_stage == 'ClosedWon' || stage?.for_stage == 'COF' || stage?.for_stage == 'Service')) {
                        return <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                         <IFIconBG>
                            {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                        </IFIconBG>
                        <T2popupSubText sx={{color: themeColor.palette.primarytext2 }}>
                            {stage?.for_stage == 'ClosedWon'? 'PO' :stage?.for_stage == 'NegotiationCommercials' ? 'Commercials':stage?.for_stage == 'NegotiationProposal' ? 'Proposal': stage.for_stage}
                        </T2popupSubText>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <H7TablePrimeContent sx={{color: themeColor.palette.PrimaryGold }}>
                            Pending Approval
                        </H7TablePrimeContent>
                        </Box>
                    </Box>
                    } else if (stage?.status == 'Approved') {
                        return <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG>
                        <T2popupSubText sx={{ color: themeColor.palette.primarytext2 }}>
                          {stage?.for_stage == 'ClosedWon' ? 'PO' : stage?.for_stage == 'NegotiationCommercials' ? 'Commercials' : stage?.for_stage == 'NegotiationProposal' ? 'Proposal' : stage.for_stage}
                        </T2popupSubText>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                          <H7TablePrimeContent sx={{ color: 'green' }}>
                            Approved
                          </H7TablePrimeContent>
                        </Box>
                      </Box>                      
                    }else{
                        return <><Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <Box sx={{display:'flex'}}>
                             {name === 'Rejected'? <></>: <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG>}
                            <T2popupSubText sx={{ color: themeColor.palette.primarytext2 }}>
                                {(stage.for_stage == 'ClosedWon') ? 'PO' :stage?.for_stage == 'NegotiationCommercials' ? 'Commercials':stage?.for_stage == 'NegotiationProposal' ? 'Proposal' : stage.for_stage ? stage.for_stage : stage}             
                            </T2popupSubText>
                            </Box>
                            {name === 'Rejected'? <></>:<><Box sx={{display:'flex'}}>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.PrimaryRed}}> 

                                {stage.for_stage == 'ClosedLost' ? '' :'Rejected'}
                                </H7TablePrimeContent>
                            </Box></>}
                            </Box></>
                    }
                }

            )()}
        </>
    )

}

export default OppStageName
