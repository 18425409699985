import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";
import axios from 'axios'

export const getalertlist = createAsyncThunk('data/alertlist',
    async(data)=>{
      const res = await axiosInstance.post('/alertlist', data);
        return res.data
    }
)
