import React, { useEffect, useState } from "react";
import {Grid, Box, styled, Card, Avatar, Typography, InputAdornment, IconButton, TextField} from '@mui/material';
import {InputBox, Label, Logo, CustemBtn, EyeCloseIcon, EyeOpenIcon} from '../../components/custom';
import { Link, useNavigate } from "react-router-dom";
import  validate  from "../../validation/validation";
import { useDispatch } from 'react-redux';
import { auththunk } from "../../Redux/Thunk/auththunk";
import { setHeaders } from "../../Redux/Thunk/axioshelper";
import DisplayError from "../../validation/DisplayError";
import { TailSpin} from 'react-loader-spinner';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import LoginImage from "../../components/custom/LoginImage";
import { hover } from "@testing-library/user-event/dist/hover";
import {LoginContainer, LogoBox, LoginDetails} from './authorization.style'
import {H1FormTitle, T6TableText, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'

// const LoginContainer = styled('div')({
//   minHeight:'100vh',
//   overflowY: 'auto',
//   // marginTop: "0.2px",
//   // marginBottom:'0.2px',
//    marginRight:'20px',
//   // padding: "10px",
//   background: '#F8FCFF',
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'center',
//   alignItems: 'center',
// })

// const Image = styled('div')({
//  '@media (max-width: 992px)': {
//      display: 'none', 
//      alignItems:'center',
//    },
// })

// const LogoBox = styled('div')({
//   display:'flex',
//   flexDirection:'row',
//   alignItems:'center',
//   justifyContent:'center',
//   margin:'50px 0px 20px 0px',
//   '@media (max-width: 768px)': {
//     margin:'20px 20px', 
//   },
// })

// const LoginDetails =styled('div')({
//   background: '#FFFFFF',
//   width: 'auto',
//   height: '280px',
//   borderRadius:'10px',
//   padding: "20px 20px 32px",
//   margin:'40px 50px',
//   '@media (max-width: 768px)': {
//     margin:'40px 20px', 
//     alignItems:'center',
//     fontSize:'18px',
//   },
// })

const Login = () =>{
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"))  
  const [resErr, setResErr] = useState('')
  const [error, setError] = useState([])
  const [state, setState] = useState({
    username:'',
    password:'',
  }) 
  const [showPassword, setShowPassword]= useState(true)
  const [loader, setLoader]= useState(false)
  const [loading, setLoading] = React.useState(false);
  function handleClick() {
    setLoading(true);
  }
  const handleState=(e)=>{
    setState({...state,[e.target.name]:e.target.value.trim()})
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
         handleLogin();     
    }
  }

  useEffect(() => {
    if (accessToken) {
      navigate('/dashboard')
    }
  },[])

  let validation_input = [
   //{tagid: 'username', text: state?.username, regex_name: 'email', required: true, errmsg: 'Invalid Credentials.'},
  //  {tagid: 'password', text: state?.password, required: true, regex_name: 'password', errmsg:"Password must contains at least 8 characters, Including 1 UPPER case, number and special characters"},
      //  {tagid: 'password', text: state?.password, required: true, allow_numbers: true, other_chars:true, regex_name: 'text', min:1, errmsg: 'Please enter password.'},  
]
   
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin=()=>{
   if(validate(validation_input).length==0){
    setLoading(true);
    dispatch(auththunk(state)).then((data)=>{
      if(data?.payload?.message){
        setResErr(data?.payload?.message)
        setError([])
        setLoading(false)
        return
      }else{
        setLoading(false)
        localStorage.setItem('accessToken', data?.payload?.data?.accessToken)
        localStorage.setItem('refreshToken', data?.payload?.data?.refreshToken)
        setHeaders('Authorization', 'Bearer '+ data?.payload?.data?.accessToken); 
        navigate('/dashboard')
      }
     }
    )   
   }else{
    setError(validate(validation_input))
    setResErr('')
   }  
  }

  const handleCopy = (e) => {
    e.preventDefault();
  }


  
  return(
    <>
      {
        accessToken ? '' : <LoginContainer>
        {/* Display Image */}
        <Grid container>
          <Grid item xs={12} md={10} sm={10} lg={8} style={{display:'flex', justifyContent:"center"}}>
           <LoginImage />
            {/* <img  alt="The house from the offer."
              src="/login_image.png" style={{width: '100%', 
              height:'90%',
             // minHeight:'auto',
              maxWidth:'100%'}} /> */}
            
          </Grid>
          {/* Employee Login Details */}
          {/* <Container> */}
          <Grid item xs={12} md={10} sm={10} lg={4} style={{background:'#F1F1F1',borderBottomRightRadius:'10px', borderTopRightRadius:'10px', width: '100%',height:'auto', maxWidth:'100%'}}>
              <LogoBox>
                <Logo width={172} height={45}/>
              </LogoBox>
              <LoginDetails>
                  <H1FormTitle sx={{color:themeColor.palette.BrandColor}}>
                    User Login
                  </H1FormTitle>
                  <InputBox name='username' onchange={handleState} value={state.username} sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:2, pl:1}} placeholder ="User Email"/>
                  <DisplayError error={error} fortagid='username'/>
                  <InputBox onPaste={(e) => e.preventDefault()} onCopy={handleCopy} name='password' type={showPassword ?'password': 'text'} value={state.password} onchange={handleState} onKeyPress={handleKeyPress} sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:3, pl:1}} placeholder ="Password" endAdornment={
                     <InputAdornment position="end">
                        <IconButton style={{ml:1}}  aria-label="toggle password visibility"
                          onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                        </IconButton> 
                     </InputAdornment>
                     }/>
                  <DisplayError error={error} fortagid='password'/> 
                  {resErr?<><small style={{color:'red'}}>{resErr}</small><br/></>:''}

                  <Link to='/forgot-password' style={{textDecoration:'none'}} >
                    <H7TablePrimeContent sx={{color:themeColor.palette.TableButton, mt:3, textAlign:'end'}}>
                      Forgot Password ?
                    </H7TablePrimeContent>
                  </Link>
                  <Box sx={{display:'flex', justifyContent:'center' }}>
                  <LoadingButton
                    sx={{width:'100%', height:'40px', textTransform:'none', color:'#FFFFFF', fontSize:'16px', "&:hover": {backgroundColor:'#3107AA'}, backgroundColor:'#3107AA', fontWeight:'500', borderRadius:'10px', marginTop:'20px'}} 
                    size="small"
                    onClick={handleLogin}
                    loading={loading}
                    loadingIndicator="Loading…"
                    variant="contained"
                  >
                    <span>Sign In</span>
                  </LoadingButton>
                  {/* {loader ? 
                  (<TailSpin
                      height="30"
                      width="30"
                      color="blue"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                  />) :
                  <CustemBtn onClick={handleLogin} variant='contained' sx={{width:'100%', height:'40px', color:'#FFFFFF', fontSize:'16px', fontWeight:'500', borderRadius:'10px', marginTop:'20px'}} text="Sign In"></CustemBtn> 
                  } */}
                 </Box> 
              </LoginDetails> 
          </Grid> 
           {/* </Container> */}
        </Grid>
      </LoginContainer>
      }
      </>
  )}


export default Login