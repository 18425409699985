import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Paper, Tooltip, Snackbar, Typography, styled, IconButton, CircularProgress } from '@mui/material';
import { H1FormTitle, T6TableText, T1ButtonText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T2popupSubText } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor'
import { CustemBtn, InputBox, TextArea } from '../../components/custom';
import { ActionButtons, ApproveBtn, IFChild1, IFChild2, IFIconBG, IFParent, RejectBtn } from './NewOppoDetailscss';
import { Identified, QualifiedPending, Rejected } from './Icons';
import moment, { now } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { createFeasibility, downloaddocument, opportunityApprovedForUser, sendrequsetforapproval, uploadstagedocument } from '../../Redux/Thunk/createopportunitythunk';
import ManagerActions from './ManagerActions';
import ExecutiveReq from './ExecutiveReq';
import OppStageName from './OppStageName';
import DocumentUpload from './DocumentUpload';
import CreateCommercials from './CreateCommercials';
import RejectActions from './RejectActions';
import DisplayError from '../../validation/DisplayError';
import validate from "../../validation/validation";
import AdminRole from '../../adminrole/Adminrole';

export default function DisplayComponent({ props, SumbitFeasiblity, show }) {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user.role.permissions
    const [feasibility_remark, setFeasibility_remark] = useState('')
    const [feasible_remark, setFeasibleRemark] = useState('')
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState([]);
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())

   
    const handleFeasiremark = (e) => {
        setFeasibility_remark(e.target.value)
    }

    const handleFeasibleRemark = (e) => {
        setFeasibleRemark(e.target.value.trim())
    }

    let validation_input = [
        { tagid: 'rejectRemark', text: feasibility_remark, required: true, regex_name: 'free_text', errmsg: 'Please add the request remark between 30 to 1000 characters.', min: 30, max: 1000 },
    ]

    const handleRaisedRequestAgain = () => {
        if (validate(validation_input).length === 0) {
            setLoader(true)
            dispatch(sendrequsetforapproval({ opportunity_id: props.opp_stage.id, opportunity_stage: props.opp_stage?.opportunity_stage, req_remark:feasibility_remark})).then((res) => {
                if (res.payload.errormsg) {
                    sweetalert('error', res.payload.errormsg)
                    setLoader(false)
                    setFeasibility_remark('')
                } else {
                    sweetalert('success', res.payload.data)
                    setLoader(false)
                    setFeasibility_remark('')
                }
            })
        } else {
            setError(validate(validation_input))
        }
        setFeasibility_remark('')
    }

    const handleFeasibility = () => {
        // SumbitFeasiblity(feasibility_remark)
        if (validate(validation_input).length === 0) {
            setLoader(true)
            setError([])
            dispatch(createFeasibility({ opportunity_id: props.opp_stage.id, remark: feasibility_remark })).then((res) => {
                if (res.payload.errormsg) {
                    sweetalert('error', res.payload.errormsg)
                    setLoader(false)
                    setFeasibility_remark('')
                    setFeasibleRemark('')
                } else {
                    sweetalert('success', res.payload.data)
                    setLoader(false)
                    setFeasibility_remark('')
                    setFeasibleRemark('')
                }
            })
          
        } else {
            setError(validate(validation_input))
        }
    }

    const handleFeasibilityRemark = () => {
        // SumbitFeasiblity(feasibility_remark)
        setLoader(true)
        dispatch(createFeasibility({ opportunity_id: props.opp_stage.id, remark: feasible_remark })).then((res) => {
            if (res.payload.errormsg) {
                sweetalert('error', res.payload.errormsg)
                setLoader(false)
                setFeasibleRemark('')
            } else {
                sweetalert('success', res.payload.data)
                setLoader(false)
                setFeasibleRemark('')
    
            }
        })    
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };
    
    // if (show) {
        switch (props?.opp_stage?.opportunity_stage) {

            case 'Identified': {
                return <>
                    {(
                        () => {
                            if (props?.opp_stage?.opportunity_stage == 'Identified' && props?.opp_stage?.approval_status == 'Rejected') {
                                return <>
                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <><IFChild1>
                                        <IFIconBG>
                                            <QualifiedPending />
                                        </IFIconBG>
                                        <OppStageName name='Rejected' stage='Qualified' opp_stage={props.opp_stage} />
                                            </IFChild1>
                                            <IFParent sx={{ justifyContent: 'start', flexDirection: 'column', borderBottom: '0.5px solid #AAAA' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                                            Request Remark:<span style={{ color: 'red' }}>*</span>
                                                        </H7TablePrimeContent >
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', pb: 0.2 }}>
                                                    <TextArea onChange={(e) => {
                                                       
                                                            handleFeasiremark(e)
                                                        
                                                    }} value={feasibility_remark?.replace(/\s\s+/g, " ")} placeholder="Please add request remark" style={{ fontFamily: 'Montserrat', marginTop: '5px', fontStyle: 'normal', width: '100%', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', border: '0.5px solid #B0AEFF', background: '#FEFEFE', p: 0.1, paddingLeft: '10px' }} />
                                                </Box>
                                                <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginLeft: 'auto', fontSize: '12px' }}>
                                                    <span>{feasibility_remark.length}/1000</span>
                                                </div>
                                                <DisplayError error={error} fortagid='rejectRemark' />
                                                <Button
                                                    id="validate" variant="contained" onClick ={handleRaisedRequestAgain}
                                                    sx={{
                                                        color:themeColor.palette.BrandColor,
                                                        backgroundColor:'#FFFFFF',
                                                        ":hover":{cursor:'pointer', boxShadow:'none', backgroundColor:'#FFFFFF'},
                                                        display: 'flex',
                                                        padding: '4px 0px',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '6px',
                                                        flex: '1 0 0',
                                                        borderRadius: '8px',
                                                        border: '1px solid #3107AA',
                                                        boxShadow:'none',
                                                        textTransform: 'none', 
                                                        marginBottom: '5px',
                                                        mt: 1,
                                                    }}
                                                >
                                                    {loader ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                                                </Button> 
                                                {/* <IFChild2>
                                                    <ApproveBtn onClick={handleRaisedRequestAgain} sx={{ color: themeColor.palette.BrandColor, "&:hover": { cursor: 'pointer' } }}>Raise Request </ApproveBtn>
                                                </IFChild2> */}
                                        </IFParent> 
                                        </> : ''
                                    }
                                </>
                            }
                        }
                    )()}
                </>
            }
                break

            case 'Qualified': {
                return <>
                    {(
                        () => {
                            if (props.opp_stage.opportunity_stage == 'Qualified' && props.opp_stage.approval_status == 'Rejected') {
                                return <>
                                    { 
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show )? <>
                                            <IFChild1>
                                                <IFIconBG>
                                                    <QualifiedPending />
                                                </IFIconBG>
                                                <T2popupSubText>
                                                    <OppStageName name='Rejected' stage='Feasible' opp_stage={props.opp_stage} />
                                                </T2popupSubText>

                                            </IFChild1>
                                            <IFParent sx={{ justifyContent: 'start', flexDirection: 'column', borderBottom: '0.5px solid #AAAA' }}>
                                                {/* <Box sx={{display:'flex'}}>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight:500}}>
                                Feasibility Id :
                                </H7TablePrimeContent>
                                <InputBox placeholder='add ID' sx={{height:'20px',border:'0.5px solid #333333', marginLeft:'20px'}} name='id' />
                                </Box> */}
                                                {/* <T2popupSubText sx={{ fontWeight: 500 }}>
                                    Feasibility
                                </T2popupSubText> */}
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                                        Request Remark:<span style={{ color: 'red' }}>*</span>
                                                    </H7TablePrimeContent >
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', pb: 0.2 }}>
                                                    <TextArea onChange={handleFeasiremark} value={feasibility_remark?.replace(/\s\s+/g, " ")} placeholder="Please add request remark" style={{ fontFamily: 'Montserrat', marginTop: '5px', fontStyle: 'normal', width: '100%', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', border: '0.5px solid #B0AEFF', background: '#FEFEFE', p: 0.1, paddingLeft: '10px' }} />
                                                </Box>
                                                <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginLeft: 'auto', fontSize: '12px' }}>
                                                    <span>{feasibility_remark.length}/1000</span>
                                                </div>
                                                <DisplayError error={error} fortagid='rejectRemark' />
                                                <Button
                                                    id="validate" variant="contained" onClick={handleFeasibility}
                                                    sx={{
                                                        color: themeColor.palette.BrandColor,
                                                        backgroundColor: '#FFFFFF',
                                                        ":hover": { cursor: 'pointer', boxShadow: 'none', backgroundColor: '#FFFFFF' },
                                                        display: 'flex',
                                                        padding: '4px 0px',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '6px',
                                                        flex: '1 0 0',
                                                        borderRadius: '8px',
                                                        border: '1px solid #3107AA',
                                                        boxShadow: 'none',
                                                        textTransform: 'none',
                                                        marginBottom: '5px',
                                                        mt: 1,
                                                    }}
                                                >
                                                    {loader ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                                                </Button>
                                                {/* <ApproveBtn onClick={handleFeasibility} sx={{ mt: 1, ":hover": { cursor: 'pointer' }, color: themeColor.palette.BrandColor, marginBottom: '5px' }}>
                                                    Raise Request
                                                </ApproveBtn> */}
                                        </IFParent> </> : ''
                                    }
                                </>

                            } else if (props.opp_stage.opportunity_stage == 'Qualified' && props.opp_stage.approval_status == 'Approved') {
                                return <>
                                    {
                        (props?.opp_stage?.opportunity_stage == 'Qualified' && props?.opp_stage?.approval_status == 'Approved') ?

                        (checkAdminRole && permissionsDetails?.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && user?.data?.user?.id == props?.opp_stage?.assigned_to?.id ) ?
                              // (stage?.status == 'Approved' && stage?.for_stage == "Qualified") ? <>
                                 <IFParent sx={{ justifyContent: 'start', flexDirection: 'column' }}>
                                    <T2popupSubText sx={{ fontWeight: 600 }}>
                                       Feasibility Check
                                    </T2popupSubText>
                                    <Box sx={{ display: 'flex', mt: 0.5, justifyContent: 'flex-start', alignItems: 'center' }}>
                                       <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                                          Request Remark:
                                       </H7TablePrimeContent >
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', pb: 0.2 }}>
                                       <TextArea value={feasible_remark?.replace(/\s\s+/g, " ")} onChange={(e) => {
                                        
                                            handleFeasibleRemark(e)
                                        
                                       }} placeholder="Please add request remark" style={{ fontFamily: 'Montserrat', marginTop: '5px', fontStyle: 'normal', width: '100%', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', border: '0.5px solid #B0AEFF', background: '#FEFEFE', p: 0.1, paddingLeft: '10px' }} />
                                    </Box>
                                    <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginLeft: 'auto', fontSize: '12px' }}>
                                       <span>{feasible_remark.length}/1000</span>
                                    </div>
                                    <Button
                                       id="validate" variant="contained" onClick={handleFeasibilityRemark}
                                       sx={{
                                          color: themeColor.palette.BrandColor,
                                          backgroundColor: '#FFFFFF',
                                          ":hover": { cursor: 'pointer', boxShadow: 'none', backgroundColor: '#FFFFFF' },
                                          display: 'flex',
                                          padding: '4px 0px',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          gap: '6px',
                                          flex: '1 0 0',
                                          borderRadius: '8px',
                                          border: '1px solid #3107AA',
                                          boxShadow: 'none',
                                          textTransform: 'none',
                                          marginBottom: '5px',
                                          mt: 1,
                                       }}
                                    >
                                       {loader ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                                    </Button>
                                    {/* <ApproveBtn onClick={handleFeasibility} sx={{mt:1, ":hover":{cursor:'pointer'}, color: themeColor.palette.BrandColor, marginBottom: '5px' }}>
                                 Raise Request
                              </ApproveBtn> */}
                                 </IFParent>
                              // </> : ''
                              : ''

                           : ''
                        // <RejectActions opportunity_stage ='Identified' stage={stage} approval_status='Rejected' opp_stage={props.opp_stage}/>

                     }
                                </>
                            }
                        }
                    )()}

                </>
            }
                break

            case 'Feasible': {
                return <>
                    {(
                        () => {
                            if (props.opp_stage.opportunity_stage == 'Feasible' && props.opp_stage.approval_status == 'Rejected') {
                                return <>
                                    {
                                         (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <>
                                            <DocumentUpload For='reject' name='Solution' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} />
                                        </> : ''

                                    }
                                </>

                            } else if (props.opp_stage.opportunity_stage == 'Feasible' && props.opp_stage.approval_status == 'Approved') {
                                return <>
                                    {
                                         (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show )? 
                                            <DocumentUpload name='Solution' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> 
                                            : ''
                                        // <RejectActions opportunity_stage ='Feasible' stage={stage} approval_status='Rejected' opp_stage={props.opp_stage}/>
                                    }
                                </>
                            }
                        }
                    )()}

                </>
            }
                break

            case 'Solution': {
                return <>
                    {(
                        () => {
                            if (props.opp_stage.opportunity_stage == 'Solution' && props.opp_stage.approval_status == 'Rejected') {
                                return <>
                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <CreateCommercials For='reject' opportunity_id={props.opp_stage.id} status={props?.opp_stage} /> : ''
                                    }
                                </>

                            } else if (props.opp_stage.opportunity_stage == 'Solution' && props.opp_stage.approval_status == 'Approved') {
                                return <>
                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <CreateCommercials opportunity_id={props.opp_stage.id} status={props?.opp_stage} /> : ''
                                    }
                                </>
                                // }else if(props.opp_stage.opportunity_stage == 'Solution' && props.opp_stage.approval_status == 'Pending') {
                                //     return <>
                                //         {
                                //             !permissionsDetails.perm_approve_edit ? <CreateCommercials name='EditIcon'  opportunity_id={props.opp_stage.id} status={props?.opp_stage} /> : ''
                                //         }
                                //     </>
                            }

                        }
                    )()}
                </>
            }
                break

            case 'Commercials': {
                return <>
                    {(
                        () => {
                            if (props.opp_stage.opportunity_stage == 'Commercials' && props.opp_stage.approval_status == 'Rejected') {
                                return <>

                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <DocumentUpload For='reject' name='Proposal' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''

                                    }
                                </>
                            } else if (props.opp_stage.opportunity_stage == 'Commercials' && props.opp_stage.approval_status == "Approved") {
                                return <>
                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <DocumentUpload name='Proposal' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''

                                    }
                                </>
                            }
                        }
                    )()}
                </>
            }
                break

            case 'Negotiation': {
                return <>
                    {(
                        () => {
                            if (props.opp_stage.opportunity_stage == 'Negotiation' && props.opp_stage.approval_status == 'Rejected' && (props?.stage_data[0].for_stage == 'NegotiationProposal' && props?.stage_data[0].status == 'Rejected')) {
                                return <>

                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <DocumentUpload For='reject' name='Proposal' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''
                                    }
                                </>
                            }
                            else if (props.opp_stage.opportunity_stage == 'Negotiation' && props.opp_stage.approval_status == 'Rejected') {
                                return <>

                                    {
                                         (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <CreateCommercials For='reject' opportunity_id={props.opp_stage.id} status={props?.opp_stage} /> : ''
                                    }
                                </>
                            } else if (props.opp_stage.opportunity_stage == 'Negotiation' && props.opp_stage.approval_status == "Approved") {
                                return <>
                                    {
                                         (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <DocumentUpload name='Proposal' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''
                                    }
                                </>
                            }
                        }
                    )()}
                </>
            }
            break

            case 'Proposal': {
                return <>
                    {(
                        () => {
                            if (props.opp_stage.opportunity_stage == 'Proposal' && props.opp_stage.approval_status == 'Rejected') {
                                return <>
                                    {
                                         (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <DocumentUpload For='reject' name='Verbal Confirmation' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''
                                    }
                                </>
                            } else if (props.opp_stage.opportunity_stage == 'Proposal' && props.opp_stage.approval_status == 'Approved') {
                                return <>
                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <><DocumentUpload name='Verbal Confirmation' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} />
                                            <span style={{ color: 'red', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>OR</span><CreateCommercials opportunity_id={props.opp_stage.id} status={props?.opp_stage} /> </> : ''
                                    }
                                </>
                            }
                        }
                    )()}
                </>
            }
            break

            case 'VerbalConfirmation': {
                return <>
                    {(
                        () => {
                            if (props.opp_stage.opportunity_stage == 'VerbalConfirmation' && props.opp_stage.approval_status == 'Rejected') {
                                return <>
                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <DocumentUpload For='reject' name='PO' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''
                                    }
                                </>
                            } else if (props.opp_stage.opportunity_stage == 'VerbalConfirmation' && props.opp_stage.approval_status == 'Approved') {
                                return <>
                                    {
                                        (checkAdminRole && permissionsDetails.perm_opportunity_create) || (permissionsDetails.perm_opportunity_create && show ) ? <DocumentUpload name='PO' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''
                                    }
                                </>
                            }
                        }
                    )()}
                </>
            }
                break

                // case 'ClosedWon' :{
                //     return<>
                //     {(
                //         ()=>{
                //             if(props.opp_stage.opportunity_stage == 'ClosedWon' && props.opp_stage.approval_status == 'Rejected'){
                //             return <>
                //             {
                //                 !permissionsDetails.perm_approve_edit ? <DocumentUpload name='CAF' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''

                //             }

                //             </>     
                //             }else if(props.opp_stage.opportunity_stage == 'ClosedWon' && props.opp_stage.approval_status == 'Approved'){
                //                 return <>
                //                 {
                //                     !permissionsDetails.perm_approve_edit ? <DocumentUpload name='CAF' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''

                //                 }
                //                 </>     
                //             }  
                //         }
                //     )()}
                //     </>
                // }
                break
        }
    // }
}