import React from 'react'
import { styled, Box} from '@mui/material';
import {Card,CardContent} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {LeadIcon,FollowUpIcon,OpportunityIcon,TaskIcon} from './index'
import DonutChart from './DonutChart';




const CardHeaders = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
})


const CardBody = styled('div')({

    display: 'flex',

    justifyContent: 'space-between',

    marginTop: "10px",
    

})

export default function ExecutiveCard({ sx, name, For, emp }) {

    const [expanded, setExpanded] = React.useState(false);

   console.log(emp)

    const handleExpandClick = () => {

        setExpanded(!expanded);

    };



    return (

        <Card sx={{ ...sx, maxWidth: 500, borderRadius: '10px',p:2, boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
          <Typography sx={{fontWeight:600, fontSize:`${For == 'dash' ? '14px':'16px'}`}} >{emp?.name}</Typography>
            <CardHeaders>
              <CardBody sx={{flex:1}}>
                 <Box sx={{ display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
                 <Box sx={{backgroundColor:'#ffffff',boxShadow:'0px 2.78796px 13.9398px rgba(0, 0, 0, 0.25)', borderRadius:'50%'}}>
                     <DonutChart emp={emp} />
                 </Box>
                 <Box mt={1} sx={{display:'flex', justifyContent:'space-between', width:'80%'}}>
                    <Typography mt={0.5} sx={{width:'10px', height:'10px', backgroundColor:'#15E703', borderRadius:'50%'}} ></Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>Closed won</Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>{emp.user_closed_won}</Typography>
                </Box>
                <Box sx={{display:'flex', justifyContent:'space-between', width:'80%'}}>
                    <Typography mt={0.5} sx={{width:'10px', height:'10px', backgroundColor:'#FF0000', borderRadius:'50%'}} ></Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>Closed Lost</Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>{emp.user_closed_lost}</Typography>
                </Box>
                <Box sx={{display:'flex', justifyContent:'space-between', width:'80%'}}>
                    <Typography mt={0.5} sx={{width:'10px', height:'10px', backgroundColor:'#534FFF', borderRadius:'50%'}} ></Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>Open OPP.</Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>{emp.user_oppo - (emp.user_closed_won + emp.user_closed_lost)}</Typography>
                </Box>
                <Box sx={{display:'flex', justifyContent:'space-between', width:'80%'}}>
                    <Typography mt={0.5} sx={{width:'10px', height:'10px', backgroundColor:'#CFCFCF', borderRadius:'50%'}} ></Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>Leads</Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>{emp.user_lead}</Typography>
                </Box>
                {/* <Box sx={{display:'flex', justifyContent:'space-between', width:'80%'}}>
                    <Typography mt={0.5} sx={{width:'10px', height:'10px', backgroundColor:'lightgrey', borderRadius:'50%'}} ></Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>Oppo.</Typography>
                    <Typography sx={{fontSize:'12px', fontWeight:600}}>{emp.user_oppo}</Typography>
                </Box> */}
                 </Box>
              </CardBody>
              <Box sx={{flex:2}} >
              <CardBody sx={{backgroundColor:'#ffffff',p:0.5,borderRadius:'10px',ml:2,boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.1)'}}>
                 <Box sx={{display:'flex',justifyContent:"start"}}>
                    <Typography sx={{pl:0.5}}> <LeadIcon height={24} width={24} /> </Typography>
                    <Typography sx={{pl:3}}> Leads </Typography>
                 </Box>
                 <Typography sx={{pl:4,color:'#4E7FFF',textAlign:'right'}}  > {emp?.user_lead} </Typography>
              </CardBody>
              <Box sx={{display:'flex', justifyContent:'space-between', mt:1}}>
              <Box  sx={{backgroundColor:'#ffffff',flex:1, p:0.5,borderRadius:'10px',ml:2,boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.1)'}} >
                    {/* <Typography sx={{pl:0.5}}> <FollowUpIcon height={24} width={24} /> </Typography> */}
                    <Typography > Follow-ups </Typography>
                   <Box sx={{display:'flex', alignItems:'center'}}>
                   <Typography sx={{pl:0.5, flex:0.5}}> <FollowUpIcon height={24} width={24} /> </Typography>
                    <Box sx={{display:'flex',flex:2, justifyContent:'space-between',flexDirection:'column', ml:1}}>
                     <Box sx={{display:'flex', justifyContent:'space-between',backgroundColor:"#F1F1F1", borderRadius:'10px',p:0.5,pl:1,pr:1, mt:0.5}}>
                     <Typography sx={{fontSize:'12px'}}>Open</Typography>
                     <Typography sx={{fontSize:'12px', color:'#049E01', fontWeight:600}} ml={1} >{emp.user_open_followup}</Typography>
                     </Box>
                     <Box sx={{display:'flex', justifyContent:'space-between',backgroundColor:"#F1F1F1", borderRadius:'10px',p:0.5,pl:1,pr:1, mt:0.5}}>
                     <Typography sx={{fontSize:'12px'}} >Total</Typography>
                     <Typography sx={{fontSize:'12px', color:'#eb4034', fontWeight:600}} ml={1} >{emp.user_total_followup}</Typography>
                     </Box>
                    </Box>
                   </Box>
                    
                 {/* <Typography sx={{pl:4,color:'#F2DA00',flex:1,textAlign:'right'}}> {emp?.user_followup} </Typography>  */}
              </Box>
              <Box  sx={{backgroundColor:'#ffffff',flex:1, p:0.5,borderRadius:'10px',ml:2,boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.1)'}} >
                    {/* <Typography sx={{pl:0.5}}> <FollowUpIcon height={24} width={24} /> </Typography> */}
                    <Typography > Tasks </Typography>
                   <Box sx={{display:'flex', alignItems:'center'}}>
                   <Typography sx={{pl:0.5, flex:0.5}}> <TaskIcon height={24} width={24} /> </Typography>
                    <Box sx={{display:'flex',flex:2, justifyContent:'space-between',flexDirection:'column', ml:1}}>
                     <Box sx={{display:'flex',  justifyContent:'space-between' ,backgroundColor:"#F1F1F1", borderRadius:'10px',p:0.5,pl:1,pr:1, mt:0.5}}>
                     <Typography sx={{fontSize:'12px'}} >Open</Typography>
                     <Typography sx={{color:'#049E01', fontSize:'12px', fontWeight:600}} ml={1} >{emp.user_open_task}</Typography>
                     </Box>
                     <Box sx={{display:'flex', justifyContent:'space-between',backgroundColor:"#F1F1F1", borderRadius:'10px',p:0.5,pl:1,pr:1, mt:0.5}}>
                     <Typography sx={{fontSize:'12px'}} >Total</Typography>
                     <Typography sx={{fontSize:'12px', color:'#eb4034', fontWeight:600}} ml={1} >{emp.user_total_task}</Typography>
                     </Box>
                    </Box>
                   </Box>
                    
                 {/* <Typography sx={{pl:4,color:'#F2DA00',flex:1,textAlign:'right'}}> {emp?.user_followup} </Typography>  */}
              </Box>
              {/* <Box  sx={{backgroundColor:'#ffffff',p:0.5,flex:1,borderRadius:'10px',ml:2,boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.1)'}} >
                 <Box sx={{display:'flex',justifyContent:"start"}}>
                    <Typography sx={{pl:0.5}}> <TaskIcon height={24} width={24} /> </Typography>
                    <Typography sx={{pl:3}}> Tasks </Typography>
                 </Box>
                 <Typography sx={{pl:4,flex:1,textAlign:'right',color:'#7AC500'}}>{emp?.user_task}</Typography> 
              </Box> */}
              </Box>
              <CardBody  sx={{backgroundColor:'#ffffff',p:0.5,borderRadius:'10px',ml:2,boxShadow:'0px 4px 10px rgba(0, 0, 0, 0.1)'}} >
                 <Box sx={{display:'flex',justifyContent:"start"}}>
                    <Typography sx={{pl:0.5}}> <OpportunityIcon height={24} width={24} /> </Typography>
                    <Typography sx={{pl:3}}> Opportunities </Typography>
                 </Box>
                 <Typography sx={{pl:4,color:'#5000B6',flex:1,textAlign:'right'}}>{emp?.user_oppo}</Typography> 
              </CardBody>
              <CardBody  sx={{borderRadius:'10px',ml:2}} >
                  <Box sx={{display:'flex'}}>
                  <Typography sx={{color:'#4E7FFF', fontSize:'10px', fontWeight:600}}>Missed Follow-ups</Typography>
                  <Box ml={1} sx={{borderRadius:'50%', border:'1px solid red', width:'15px', height:'15px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Typography sx={{fontSize:'10px', fontWeight:600, color:'red'}}>{emp?.user_missed_followup}</Typography>
                        </Box>
                  </Box>
                  <Box sx={{display:'flex'}}>
                  <Typography sx={{color:'#4E7FFF', fontSize:'10px', fontWeight:600}}>Missed Tasks</Typography>
                  <Box ml={1} sx={{borderRadius:'50%', border:'1px solid red', width:'15px', height:'15px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Typography sx={{fontSize:'10px', fontWeight:600, color:'red'}}>{emp?.user_missed_task}</Typography>
                  </Box>
                  </Box>
              </CardBody>
              </Box>
            </CardHeaders>             
               
               
            
               
                {/* <CardHeaders>
                    <Typography sx={{ color: '#444444', fontSize: '16px', lineHeight: "20px", fontWeight: "500" }}>
                        {name}
                    </Typography>
                </CardHeaders>
                <CardBody
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: "414px",
                        height: "289px",
                    }}>
                    <Box sx={{flex:1}} >
                        <Typography>
                        reports
                        </Typography>
                    </Box>
                    <Box sx={{
                        flexGrow: 0,
                        justifyContent: "flex-end",
                        padding: '0px 30px',
                        flex:1.8
                    }}>
                        <Box sx={{
                            maxWidth: "214px",
                            maxHeight: "40px",
                            borderRadius: '40px',
                            backgroundColor: "#FFFFFF",
                            padding: "10px 20px",
                            marginBottom:"5px",
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: ' 0px 4px 20px #e3e3e3',
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                justifyContent: "flex-start",
                            }}>
                                <Box sx={{
                                    padding: "5px 20px 0px 0px"
                                }}>
                                    <LeadIcon height={24} width={24} />
                                </Box>
                                <Box sx={{
                                    justifyContent: "end",
                                    padding: "5px 0px 0px 0px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    letterSpacing: "0.02em",
                                    color: "#444444",
                                }}>Leads</Box>
                            </Box>
                            <Box sx={{
                                color: "#4E7FFF",
                                padding: "5px 0px 0px 0px"
                            }}>
                                500
                            </Box>
                        </Box>
                        <Box sx={{
                            maxWidth: "214px",
                            maxHeight: "40px",
                            borderRadius: '40px',
                            backgroundColor: "#FFFFFF",
                            padding: "10px 20px",
                            marginBottom:"5px",
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: ' 0px 4px 20px #e3e3e3',
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                justifyContent: "flex-start",
                            }}>
                                 <Box sx={{
                                    padding: "5px 20px 0px 0px"
                                }}>
                                    <FollowUpIcon height={24} width={24} />
                                </Box>
                                <Box sx={{
                                    justifyContent: "end",
                                    padding: "5px 0px 0px 0px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    letterSpacing: "0.02em",
                                    color: "#444444",
                                }}>Follow-ups</Box>
                            </Box>
                            <Box sx={{
                                color: "#F2DA00",
                                padding: "5px 0px 0px 0px"
                            }}>
                                500
                            </Box>
                        </Box>
                        <Box sx={{
                            maxWidth: "214px",
                            maxHeight: "40px",
                            borderRadius: '40px',
                            backgroundColor: "#FFFFFF",
                            padding: "10px 20px",
                            marginBottom:"5px",
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: ' 0px 4px 20px #e3e3e3',
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                justifyContent: "flex-start",
                            }}>
                                  <Box sx={{
                                    padding: "5px 20px 0px 0px"
                                }}>
                                    <TaskIcon height={24} width={24} />
                                </Box>
                                <Box sx={{
                                    justifyContent: "end",
                                    padding: "5px 0px 0px 0px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    letterSpacing: "0.02em",
                                    color: "#444444",
                                }}>Tasks</Box>
                            </Box>
                            <Box sx={{
                                color: "#7AC500",
                                padding: "5px 0px 0px 0px"
                            }}>
                                500
                            </Box>
                        </Box>
                        <Box sx={{
                            maxWidth: "214px",
                            maxHeight: "40px",
                            borderRadius: '40px',
                            backgroundColor: "#FFFFFF",
                            padding: "10px 20px",
                            marginBottom:"5px",
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: ' 0px 4px 20px #e3e3e3',
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                justifyContent: "flex-start",
                            }}>
                                 <Box sx={{
                                    padding: "5px 20px 0px 0px"
                                }}>
                                    <OpportunityIcon height={24} width={24} />
                                </Box>
                                <Box sx={{
                                    justifyContent: "end",
                                    padding: "5px 0px 0px 0px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    letterSpacing: "0.02em",
                                    color: "#444444",
                                }}>Opportunities</Box>
                            </Box>
                            <Box sx={{
                                color: "#5000B6",
                                padding: "5px 0px 0px 0px"
                            }}>
                                500
                            </Box>
                        </Box>
                        <Box
                        sx={{
                            maxWidth: "214px",
                            borderRadius: '40px',
                            padding: "10px",
                            marginBottom:"5px",
                            display: "flex",
                            flexDirection:"row",
                            justifyContent: "space-between",
                        }}>
                       
                       <Typography sx={{color:"#0600FF",fontSize:10}}>Missed Follow-Ups</Typography> 
                       <Typography sx={{color:"#FF0000",fontSize:10}}>05</Typography>
                       <Typography sx={{color:"#0600FF",fontSize:10}}>Missed Tasks</Typography>
                       <Typography sx={{color:"#FF0000",fontSize:10}}>05</Typography> 
                        </Box>
                    </Box>
                </CardBody> */}

        </Card>

    );

}