import { createSlice } from '@reduxjs/toolkit'
import { createRole, getAllRoles, getUserRole } from '../Thunk/createrolethunk'

const initialState = {
     createrole : null,
     fetchuserrole : null,
     getallroles :[]
}

export const createRoleSlice = createSlice({
          name:'role',
          initialState,
          reducers:{
          },

          extraReducers:(builder)=>{
            builder.addCase(createRole.fulfilled,(state,action)=>{
               state.createrole = action.payload
            })

            builder.addCase(getUserRole.fulfilled,(state,action)=>{
               state.fetchuserrole = action.payload
            })

            builder.addCase(getAllRoles.fulfilled,(state,action)=>{
               state.getallroles = action.payload
            })
         }
})



export default createRoleSlice.reducer
