import { AppBar, Avatar, Badge,Box, Button, styled, Toolbar, Typography,InputBase, Menu,MenuItem } from '@mui/material'
import React, { useEffect,useState } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import { AddIcCallRounded } from '@mui/icons-material';
import Logo from './Logo';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyName ,displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import DropDown from './DropDown';
import SearchDropdown from './SearchDropdown';
import { setHeaders } from "../../Redux/Thunk/axioshelper";
import { getAllRoles } from '../../Redux/Thunk/createrolethunk';
import swal from 'sweetalert';
import SelectDropDown from './SelectDropDown';
import { main_search } from '../../Redux/Thunk/dashboardthunk';
import displayname from '../../displaycompanyname/displaycompany';
import {H1FormTitle, T1MedNormalText, T6TableText, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import { TailSpin } from 'react-loader-spinner';

const StyledToolbar = styled(Toolbar)({
      display:'flex',
      justifyContent:'space-between',
      color:'#444444',
      backgroundColor:'#CECECE',
      alignItems:'center',
      // padding:'4px'
})

const Icons = styled('div')({
   display:'flex',
  //  gap:'10px',
  alignItems:'center',
})

const Search = styled('div')({
      display:'flex',
      alignItems:'center',
      padding:'0px 10px',
      borderRadius:'40px',
      width:'35%',
      height:'36px',
      background:'#ffffff',
      border:'1px solid #B0AEFF ',
      flexDirection:'row',
   })

   const RemoveBorder = styled('div')({
      width:'500%',
      boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
      "&:hover": {
        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }
      },
      '@media (max-width: 768px)': {
        width:'300%',
        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
        "&:hover": {
          boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }
        }
      }
  });

     const RemoveBorderforSelectCategory = styled('div')({
      width:'320%',
      boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
      "&:hover": {
        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }
      },
      '@media (max-width: 768px)': {
        width:'200%',
        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
        "&:hover": {
          boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }
        }
      }
  });

const Navbar = () => {
   const [flag,setFlag] = useState(false)
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const {user} = useSelector((state) => state.auth)
   const permissionsDetails = user?.data?.user?.role?.permissions
   const { getallroles } = useSelector ((state) => state?.role)
   const {getCompanyDetailsm} = useSelector((state)=> state.company);
   const [companyList, setCompanyList] = useState([]);
   const [open,setOpen] = useState(false)
   const {updateCompany, allCompanyName, createdCompanyUpdated} = useSelector((state)=> state?.company)
   const [companyNames, setCompanyNames]= useState([])
   const [dropdownValues, setDropdownValues]= useState('')
   const [buttonStatus, setButtonStatus] = useState(false);
   const [searchCompany, setSearchCompany] = useState('');
   const NameOfRole = getallroles?.find((id)=> id?.id === user?.data?.user?.role?.id)
   const [updateList, setUpdateList]= useState('')
   const [searchinput, setSearchInput] = useState('') 
   const [selectedoption, setSelectedOption] = useState('')
   const [search_result, setsearch_result] = useState([])

    useEffect(()=>{
      setSearchCompany('')
      setDropdownValues('')
      dispatch(getAllRoles()).then((res)=>{
        console.log(res)
      })
      dispatch(getAllCompanyName()).then((res) => {
        setCompanyList(res?.payload)
      })
    },[updateList, updateCompany])

    useEffect(() => {
      if (selectedoption && searchinput.length > 1) {
       let timeout_func = setTimeout(() => {
        setButtonStatus(true)
        dispatch(main_search({searchinput, selectedoption})).then((res) => {
          setButtonStatus(false)
          switch(selectedoption) {
            case 'Company':{
              //   let search_data = res.payload.data.map((comp) =>{
              //   return {
              //     value:`${comp.company_name} ${comp.company_type}`,
              //     label:`${comp.company_name} ${comp.company_type}`,
              //     id:comp.id
              //   }
              // }) 
              setsearch_result(displayname(res.payload.data))
              break
            }
            case 'Lead' : {
              let search_data = res.payload.data.map((lead) =>{
                return {
                  value:`${lead.lead_no} ${lead.lead_name}`,
                  label:`${lead.lead_no} ${lead.lead_name}`,
                  id:lead.id
                }
              })
              setsearch_result(search_data)
              break
            }
            case 'Opportunity' : {
              let search_data = res.payload.data.map((oppo) =>{
                return {
                  value:`${oppo.opportunity_no} ${oppo.opportunity_name}`,
                  label:`${oppo.opportunity_no} ${oppo.opportunity_name}`,
                  id:oppo.id
                }
              })
              setsearch_result(search_data)
              break
            }
            case 'Service' : {
              let search_data = res.payload.data.map((oppo) =>{
                return {
                  value:`${oppo.service_id} ${oppo.service_id}`,
                  label:`${oppo.service_id} For ${oppo.opportunity.opportunity_no}`,
                  id:oppo.opportunity.id
                }
              })
              setsearch_result(search_data)
              break
            }
          } 
        })
      }, 1000);

      return () => {
        clearTimeout(timeout_func)
      }

      } else {
        if(searchinput.length == 0) {
          setsearch_result([])
          setButtonStatus(false)
        }
        //setsearch_result([{value:'', label:'', id:''}])
        
      }
    
    }, [searchinput])
    
  //   const filteredOptions = searchCompany !=''
  //   ? companyList?.filter((name) =>
  //       `${name?.company_name} ${name?.company_type}`
  //         .toLowerCase()
  //         .includes(searchCompany.toLowerCase())
  //     )
  //   : companyList;

  // const options = filteredOptions?.map((name) => ({
  //   label: `${name?.company_name} ${name?.company_type}`,
  //   value: `${name?.company_name} ${name?.company_type}`,
  //   id: name?.id,
  // }));

  const searchDetails =(value) => {
    switch(selectedoption) {
      case 'Company':{
        if(value?.id){
          navigate('/dashboard/profile', {state: value?.id}) 
        }
        break
      }
      case 'Lead':{
        if(permissionsDetails?.perm_lead_view){
        if(value?.id){
           navigate('/dashboard/leaddetails', {state: {leadDetails: {id:value?.id}, loader:true}}) 
          //navigate('/dashboard/profile', {state: value?.id}) 
        }}else{
          <Typography>Do not have permissions.</Typography>
        }
        break
      }
      case 'Opportunity' : {
        if(permissionsDetails?.perm_opportunity_view){
        if(value?.id) {
           navigate('/dashboard/opportunitydetails', { state: { oppDetails: value?.id }})
        }}else{
          <Typography>Do not have permissions.</Typography>
        }
        break
      }
      case 'Service':{
        if(permissionsDetails?.perm_opportunity_view){
        if(value?.id) {
           navigate('/dashboard/service', { state: { serviceDetails: value?.id, title: 'Service List' }})
        }}else{
          <Typography>Do not have permissions.</Typography>
        }
        break
      }

    }    
  }

  const handleClickSettingPage =()=>{
    setOpen(false)
    navigate('/dashboard/setting')
  }

  const userLogout = () =>{
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    setHeaders('Authorization', 'Bearer '+ '');
    navigate('/');
  }

  const updateUserPassword =()=>{
    navigate('/resetpassword')
  }

  const sweetAlert = (variant, text) => {
    swal({
        icon: variant === 'error' ? 'error' : 'success',
        title: text,
    })
  }

  const clearSearch =() => {
    setSearchInput('')
    //setSelectedOption('')
    setsearch_result([])
    console.log(search_result, 'search')
  }


  return (
        <>
        <AppBar position='sticky' sx={{boxShadow:'0px 1px 1px #efefef'}}>
        <StyledToolbar>
            <Box onClick={() => clearSearch()} sx={{display:{xs:'none',sm:'block'}}} >
              <Link to='/dashboard' sx={{textDecoration:'none',color:'inherit'}}>
               <Logo width={110} height={32}/>
              </Link> 
            </Box>

            {permissionsDetails?.perm_company_view ? <>
              <Search>
                <RemoveBorderforSelectCategory>
                <DropDown 
                  id='search_for'
                  clearOnBlur={true} 
                  options= {[{value:"Company", label:'Company'}, {value:'Lead', label:'Lead'}, {value:'Opportunity', label:'Opportunity'}, {value:"Service", label:'Service'}]}
                  placeholder="Search For"
                  
                  onChange = {(e, value, reason) => {
                    if(value !== null){
                      setsearch_result([])
                      setSelectedOption(value.value)
                    } else {
                      setSelectedOption('')
                    }
                  }}
                />
                </RemoveBorderforSelectCategory>   
                <RemoveBorder>     
                  <DropDown 
                    //freesolo = {true} 
                    id='search_value'
                    options= {search_result?.length > 0 ? search_result : []}
                    getOptionLabel={(option) =>(option.label ? option.label :"")}
                    onChange = {(e, value, reason) => {
                      if(value === null){
                        setSearchInput('')
                      }else{
                        searchDetails(value)
                      }
                    }}
                    onSearch={(e) =>{
                      setSearchInput(e.target.value)
                    }}
                    handleFocus={() => {
                      if(!selectedoption) {
                        setsearch_result([])
                        sweetAlert('error', 'Please select Search For.')
                      }
                    }}
                    value=''
                    searchinput = {searchinput}
                    clearOnBlur={true} 
                    placeholder={`Search ${selectedoption ? selectedoption : 'Value'}`}
                  />

                </RemoveBorder> 
                {buttonStatus ? <div style={{display: 'flex', alignItems:'end', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <TailSpin
                  height="20"
                  width="30"
                  color="blue"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                /> 
                  <span>
                    <Typography variant="caption" color="textSecondary">
                        Loading...
                    </Typography></span>
                </div>:''}
              </Search>
            </> : <></>}

            <GroupIcon sx={{display:{xs:'block',sm:'none'}}} />
              <Icons onClick={()=>setOpen(true)} sx={{":hover":{cursor:'pointer'}}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="38" viewBox="0 0 36 38" fill="none">
                <rect x="0.5" y="0.953125" width="35" height="35.5605" rx="17.5" fill="#F9F9F9"/>
                <path d="M15.5795 22.5356C17.1992 22.403 18.8262 22.403 20.4459 22.5356C21.3278 22.5878 22.2056 22.6998 23.0737 22.871C24.9521 23.2573 26.1784 23.8875 26.704 24.904C27.0987 25.6973 27.0987 26.6417 26.704 27.435C26.1784 28.4514 25.0007 29.1223 23.0542 29.4679C22.1868 29.6455 21.3088 29.761 20.4264 29.8135C19.6088 29.905 18.7913 29.905 17.964 29.905H16.4749C16.1635 29.8643 15.8618 29.844 15.5698 29.844C14.6873 29.7979 13.8091 29.6858 12.942 29.5086C11.0636 29.1426 9.83725 28.4921 9.31169 27.4756C9.1088 27.0819 9.00184 26.642 9.00012 26.1949C8.99601 25.745 9.09974 25.3012 9.30195 24.904C9.81779 23.8875 11.0441 23.2268 12.942 22.871C13.8129 22.6974 14.6941 22.5853 15.5795 22.5356ZM18.003 7.05469C21.2657 7.05469 23.9107 9.81709 23.9107 13.2247C23.9107 16.6323 21.2657 19.3947 18.003 19.3947C14.7402 19.3947 12.0952 16.6323 12.0952 13.2247C12.0952 9.81709 14.7402 7.05469 18.003 7.05469Z" fill="#200E32"/>
                <rect x="0.5" y="0.953125" width="35" height="35.5605" rx="17.5" stroke="#200E32"/>
              </svg>
                <Badge sx={{display:'flex', flexDirection:'column'}}  color="error" >
                  <T1MedNormalText sx={{marginLeft:"10px", color:themeColor.palette.Primarytext1}} >{user?.data?.user?.name}</T1MedNormalText>
                  <T6TableText sx={{marginLeft:"10px", color:'#005AC5'}}>{user?.data?.user?.role?.role === 'god'? "god" :NameOfRole?.role}</T6TableText>
                </Badge>
              </Icons>

        </StyledToolbar>

        <Menu
        sx={{marginTop:'40px'}}
          onClose={()=>setOpen(false)}
          open={open}
            anchorOrigin={{
             vertical: 'top',
             horizontal: 'right',
           }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            elevation: 1,
            sx: {
               overflow: 'visible',
              filter: 'drop-shadow(0px 4px 10px #efefef)',
              borderRadius:'0px 0px 8px 8px',
            },
          }}
          >
          {/* <Link to='/dashboard/companylist' style={{color:'inherit',textDecoration:'none'}} > 
            <MenuItem>
              <Avatar sx={{height:'25px',width:'25px'}} />
              <Typography sx={{p:1,pt:0,pb:0,pr:5}}>Company</Typography>
            </MenuItem>
          </Link>

        {permissionsDetails?. perm_user_view ? <>
          <Link  to='/dashboard/userlist' style={{color:'inherit',textDecoration:'none'}} > 
            <MenuItem>
              <Avatar sx={{height:'25px',width:'25px'}} />
              <Typography sx={{p:1,pt:0,pb:0,pr:5}}>User</Typography>
            </MenuItem>
          </Link></> :<></> } */}

          {permissionsDetails?.perm_settings_view ? <> 
            <MenuItem onClick={handleClickSettingPage}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 12.376C10.864 12.376 12.375 10.8649 12.375 9.00098C12.375 7.13702 10.864 5.62598 9 5.62598C7.13604 5.62598 5.625 7.13702 5.625 9.00098C5.625 10.8649 7.13604 12.376 9 12.376Z" stroke="#3107AA" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.8794 5.67441C14.0486 5.91676 14.1968 6.17317 14.3223 6.44082L16.1434 7.45332C16.3708 8.47178 16.3732 9.52759 16.1505 10.5471L14.3223 11.5596C14.1968 11.8272 14.0486 12.0836 13.8794 12.326L13.9145 14.4143C13.1427 15.1175 12.2293 15.6474 11.2356 15.9682L9.44265 14.8924C9.14772 14.9135 8.85165 14.9135 8.55672 14.8924L6.77078 15.9611C5.77396 15.6462 4.8575 15.1184 4.08484 14.4143L4.12 12.333C3.95214 12.0873 3.80402 11.8287 3.67703 11.5596L1.85593 10.5471C1.62854 9.52861 1.62614 8.4728 1.8489 7.45332L3.67703 6.44082C3.80252 6.17317 3.95072 5.91676 4.12 5.67441L4.08484 3.58613C4.85663 2.88284 5.77008 2.35299 6.76375 2.03223L8.55672 3.10801C8.85165 3.08691 9.14772 3.08691 9.44265 3.10801L11.2286 2.03926C12.2254 2.35418 13.1419 2.88199 13.9145 3.58613L13.8794 5.67441Z" stroke="#3107AA" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            {/* <Link to='/dashboard/setting' style={{color:'inherit',textDecoration:'none'}} > */}
            <T6TableText sx={{p:1, pt:0, pb:0, pr:5, fontWeight:600, color:themeColor.palette.BrandColor}} >Setting</T6TableText>
            {/* </Link> */} 
            </MenuItem> 
          </>: <></>}

            <MenuItem onClick={updateUserPassword}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3701_149275)">
              <path d="M14.625 6.1875H3.375C3.06434 6.1875 2.8125 6.43934 2.8125 6.75V14.625C2.8125 14.9357 3.06434 15.1875 3.375 15.1875H14.625C14.9357 15.1875 15.1875 14.9357 15.1875 14.625V6.75C15.1875 6.43934 14.9357 6.1875 14.625 6.1875Z" stroke="#3107AA" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.1875 6.1875V3.9375C6.1875 3.19158 6.48382 2.47621 7.01126 1.94876C7.53871 1.42132 8.25408 1.125 9 1.125C10.3605 1.125 11.5516 2.09109 11.8125 3.375" stroke="#3107AA" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 11.25C9.77665 11.25 10.4062 10.6204 10.4062 9.84375C10.4062 9.0671 9.77665 8.4375 9 8.4375C8.22335 8.4375 7.59375 9.0671 7.59375 9.84375C7.59375 10.6204 8.22335 11.25 9 11.25Z" stroke="#3107AA" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 11.25V12.9375" stroke="#3107AA" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
              <clipPath id="clip0_3701_149275">
              <rect width="18" height="18" fill="white"/>
              </clipPath>
              </defs>
              </svg>
              <T6TableText sx={{p:1, pt:0, pb:0, pr:5, fontWeight:600, color:themeColor.palette.BrandColor}}>Change Password</T6TableText>
            </MenuItem>

            <MenuItem onClick={userLogout}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.2344 6.04688L15.1875 9L12.2344 11.9531" stroke="#E70707" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.3125 9H15.1875" stroke="#E70707" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.3125 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V3.375C2.8125 3.22582 2.87176 3.08274 2.97725 2.97725C3.08274 2.87176 3.22582 2.8125 3.375 2.8125H7.3125" stroke="#E70707" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <T6TableText sx={{p:1, pt:0, pb:0, pr:5, fontWeight:600, color:themeColor.palette.BrandColor}}>Logout</T6TableText>
            </MenuItem>
        </Menu>
        </AppBar>
        </>
  )
}

export default Navbar



