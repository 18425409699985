import React from 'react'
import { BlackColorNum, BrownColorTypo, CallIconCss, CallNameCss, CardLayout, CompanyName, CountCard, CountCard2, CountLayOut, DateLayout, DateTypo, ExpiringLeadLayout, FollowupIcon, FollowupName, FollowupType, GreenTypo, GreyTypo, Header, Header2, LeadCard, LeadIconBox, LeadNo, LeadNoText, OppoIconBox, Parent, RedTypo, SkyBlueTypo, Text, TodayLeadLayOut, TodayLeadParent, WishesLayout } from './DashStyledCss'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import ThermometerIcon from '../../components/custom/ThermometerIcon'
import AlarmIcon from '../../components/custom/AlarmIcon'
import CallIconWhite from '../../components/custom/CallIconWhite'
import moment from 'moment'
import { H6TableCompany, H7TablePrimeContent, T4MedNormalText } from '../../Theme/theme'
import themeColor from '../../Theme/themeColor'
import { Link, useNavigate } from 'react-router-dom'
import { leaddetails } from '../../Redux/Thunk/createleadthunk'
import { useDispatch } from 'react-redux'
const Title ="DashboardLeadDetails"

const DashboardCard = ({ followup, For, setLoader }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const openLeadDetailsPage =(row) =>{
        setLoader(true)
        //get lead for show lead details
        if(row.lead_no) {
          dispatch(leaddetails(row?.id)).then((res)=>{
            if(res.payload) {
                setLoader(false)
              navigate('/dashboard/leaddetails', {state: {leadDetails: row, title:Title, loader:true}})
            }
           }) 
        } else {
            dispatch(leaddetails(row?.lead?.id)).then((res)=>{
                if(res.payload) {
                  navigate('/dashboard/leaddetails', {state: {leadDetails: row?.lead, title:Title, loader:true}})
                }
               }) 
        }
      
     }

 const number_days = followup?.expired_in
    return (
        <Grid sx={{cursor:'pointer'}} item xs={6} sm={6} md={6} lg={6} xl={6}>
            <LeadCard onClick={() => openLeadDetailsPage(followup)}>
                <Box sx={{ display: 'flex', }}>
                    <LeadNo>
                    {
                                (
                                    () => {
                                        if (followup?.lead?.type == 'Hot' || followup?.type == 'Hot') {
                                            return <ThermometerIcon/>
                                        } else if (followup?.lead?.type == 'Warm' || followup?.type == 'Warm'){
                                            return <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
                                            <path d="M6.97674 11.3023V2.32558C6.97674 1.02326 5.95349 0 4.65116 0C3.34884 0 2.32558 1.02326 2.32558 2.32558V11.3023C0.930233 12.093 0 13.6279 0 15.3488C0 17.907 2.09302 20 4.65116 20C7.2093 20 9.30233 17.907 9.30233 15.3488C9.30233 13.6279 8.37209 12.1395 6.97674 11.3023Z" fill="#FED005" fill-opacity="0.3"/>
                                            <path d="M4.6523 18.6038C2.83834 18.6038 1.39648 17.162 1.39648 15.348C1.39648 14.1852 2.00114 13.1155 3.02439 12.5108L3.72207 12.0922V2.32476C3.72207 1.81314 4.14067 1.39453 4.6523 1.39453C5.16393 1.39453 5.58253 1.81314 5.58253 2.32476V12.1387L6.28021 12.5573C7.30346 13.162 7.90811 14.2317 7.90811 15.3945C7.90811 17.162 6.46625 18.6038 4.6523 18.6038Z" fill="url(#paint0_linear_3466_174257)"/>
                                            <path d="M3.04983 12.5539L3.05012 12.5537L3.74779 12.1351L3.77207 12.1205V12.0922V2.32476C3.77207 1.84075 4.16828 1.44453 4.6523 1.44453C5.13631 1.44453 5.53253 1.84075 5.53253 2.32476V12.1387V12.167L5.55681 12.1816L6.25448 12.6002L6.25477 12.6004C7.26284 13.196 7.85811 14.2495 7.85811 15.3945C7.85811 17.1339 6.43911 18.5538 4.6523 18.5538C2.86596 18.5538 1.44648 17.1344 1.44648 15.348C1.44648 14.203 2.04176 13.1495 3.04983 12.5539Z" stroke="#FCC400" stroke-opacity="0.5" stroke-width="0.1"/>
                                            <path d="M6.32644 12.6504C6.65203 13.162 6.88458 13.7667 6.88458 14.4178C6.88458 16.2318 5.44272 17.6736 3.62877 17.6736C2.97761 17.6736 2.37296 17.4876 1.86133 17.1155C2.46598 17.9992 3.44272 18.6039 4.559 18.6039C6.37296 18.6039 7.81482 17.162 7.81482 15.3481C7.81482 14.2318 7.21016 13.2085 6.32644 12.6504Z" fill="#ED9C00"/>
                                            <defs>
                                              <linearGradient id="paint0_linear_3466_174257" x1="4.6523" y1="1.39453" x2="4.6523" y2="18.6038" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.203125" stop-color="#FFC700" stop-opacity="0"/>
                                                <stop offset="0.797909" stop-color="#FFD600"/>
                                              </linearGradient>
                                            </defs>
                                          </svg>
                                        } else {
                                            return <svg xmlns="http://www.w3.org/2000/svg" width="10" height="21" viewBox="0 0 10 21" fill="none">
                                            <path d="M6.97674 11.8023V2.82558C6.97674 1.52326 5.95349 0.5 4.65116 0.5C3.34884 0.5 2.32558 1.52326 2.32558 2.82558V11.8023C0.930233 12.593 0 14.1279 0 15.8488C0 18.407 2.09302 20.5 4.65116 20.5C7.2093 20.5 9.30233 18.407 9.30233 15.8488C9.30233 14.1279 8.37209 12.6395 6.97674 11.8023Z" fill="#B0AEFF" fill-opacity="0.3"/>
                                            <path d="M4.6523 19.1038C2.83834 19.1038 1.39648 17.662 1.39648 15.848C1.39648 14.6852 2.00114 13.6155 3.02439 13.0108L3.72207 12.5922V2.82476C3.72207 2.31314 4.14067 1.89453 4.6523 1.89453C5.16393 1.89453 5.58253 2.31314 5.58253 2.82476V12.6387L6.28021 13.0573C7.30346 13.662 7.90811 14.7317 7.90811 15.8945C7.90811 17.662 6.46625 19.1038 4.6523 19.1038Z" fill="url(#paint0_linear_3466_174269)"/>
                                            <path d="M3.04983 13.0539L3.05012 13.0537L3.74779 12.6351L3.77207 12.6205V12.5922V2.82476C3.77207 2.34075 4.16828 1.94453 4.6523 1.94453C5.13631 1.94453 5.53253 2.34075 5.53253 2.82476V12.6387V12.667L5.55681 12.6816L6.25448 13.1002L6.25477 13.1004C7.26284 13.696 7.85811 14.7495 7.85811 15.8945C7.85811 17.6339 6.43911 19.0538 4.6523 19.0538C2.86596 19.0538 1.44648 17.6344 1.44648 15.848C1.44648 14.703 2.04176 13.6495 3.04983 13.0539Z" stroke="#B0AEFF" stroke-opacity="0.5" stroke-width="0.1"/>
                                            <path d="M6.32644 13.1504C6.65203 13.662 6.88458 14.2667 6.88458 14.9178C6.88458 16.7318 5.44272 18.1736 3.62877 18.1736C2.97761 18.1736 2.37296 17.9876 1.86133 17.6155C2.46598 18.4992 3.44272 19.1039 4.559 19.1039C6.37296 19.1039 7.81482 17.662 7.81482 15.8481C7.81482 14.7318 7.21016 13.7085 6.32644 13.1504Z" fill="#8B88FF"/>
                                            <defs>
                                              <linearGradient id="paint0_linear_3466_174269" x1="4.6523" y1="1.89453" x2="4.6523" y2="19.1038" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.463835" stop-color="#B0AEFF" stop-opacity="0"/>
                                                <stop offset="0.795044" stop-color="#B0AEFF"/>
                                              </linearGradient>
                                            </defs>
                                          </svg>
                                        }
                                    }
                                )()
                            }
                        <H7TablePrimeContent sx={{color: themeColor.palette.LeadID, fontSize:'11px'}}>
                            {followup?.lead?.lead_no} {followup?.lead_no}
                        </H7TablePrimeContent>
                    </LeadNo>

                   
                    {
                        For == 'expiry' ? <DateLayout sx={{marginLeft:'10px'}}>
                            <H7TablePrimeContent>Expire in </H7TablePrimeContent>
                            <H7TablePrimeContent sx={{color:`${
                                (
                                    () => {
                                        if (number_days <=2) {
                                            return '#E70707'
                                        } else if (number_days > 2 && number_days < 3 ){
                                            return '#ED9C00'
                                        } else {
                                            return "#747600"
                                        }
                                    }
                                )()
                            }`}}>{number_days} Days</H7TablePrimeContent>
                        </DateLayout> : <DateLayout>
                             <Typography sx={{marginLeft:'10px', marginTop:'5px'}}><AlarmIcon /></Typography>
                            <DateTypo>
                                <H7TablePrimeContent sx={{ color: themeColor.palette.FollowupID}}>
                                {followup?.followup_date ? `Today, ${moment(Number(followup?.followup_date)).format('h:mm A')}` : ''}
                                </H7TablePrimeContent>
                                
                                {/* Today, 09:30 P.M */}
                            </DateTypo>
                        </DateLayout>
                    }

                </Box>
                {
                    (For == 'expiry' && followup.followup_date) ? <Box sx={{ display: 'flex', gap: 1 }}>
                        {/* <CompanyName sx={{ flex: 2 }}> */}
                            <H6TableCompany sx={{ flex: 2,  marginTop: '10px', marginBottom: '10px' }}>
                            {followup?.company?.company_type ==="NGO" ||followup?.company?.company_type==="OTHERS"|| followup?.company?.company_type==="TRUST" || followup?.company?.company_type==="PROPRIETOR" || followup?.company?.company_type==="FOREIGN ENTITY" ? `${followup?.company?.company_name} (${followup?.company?.company_type})`: `${followup?.company?.company_name} ${followup?.company?.company_type}`}

                            </H6TableCompany>
                        {/* </CompanyName> */}
                        <Box sx={{ display: 'flex', marginTop: '10px', flex: 1 }}>
                             { <AlarmIcon />}
                            <Typography sx={{ fontSize: '10px', marginLeft:'5px' }}>{ moment(Number(followup?.followup_date)).format('D MMM h:mm A') }</Typography>
                        </Box>
                    </Box> : <H6TableCompany sx={{ flex: 2,  marginTop: '10px', marginBottom: '10px' }}>
                    {followup?.company?.company_type ==="NGO" ||followup?.company?.company_type==="OTHERS"|| followup?.company?.company_type==="TRUST" || followup?.company?.company_type==="PROPRIETOR" || followup?.company?.company_type==="FOREIGN ENTITY" ? `${followup?.company?.company_name} (${followup?.company?.company_type})`: `${followup?.company?.company_name} ${followup?.company?.company_type}`}
                      
                        </H6TableCompany>
                }
                <FollowupType>
                    <FollowupIcon>
                        <CallIconCss
                            sx={{
                                background: `${(
                                    () => {
                                        if (followup?.followup_type == 'Email') {
                                            return `#00A9A9`
                                        } else if (followup?.followup_type == 'Meeting') {
                                            return '#3107AA'
                                        } else if (followup?.followup_type == 'Meeting') {
                                            return '#1F78FF'
                                        } else if (!followup?.followup_type) {
                                            return '#858585'
                                        }
                                    }
                                )()
                                    }`
                            }}
                        >
                            {(
                                () => {
                                    if (followup.followup_type == 'Call') {
                                        return <CallIconWhite />
                                    } else if (followup?.followup_type == 'Email') {
                                        return <Box sx={{ width: '24px', height: '24px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M2 5.5V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V19H20V7.3L12 14.5L2 5.5ZM0 10H5V12H0V10ZM0 15H8V17H0V15Z" fill="#F9F9F9" />
                                            </svg>
                                        </Box>
                                    } else if (followup?.followup_type == 'Meeting') {
                                        return <Box sx={{ width: '24px', height: '24px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="27" viewBox="0 0 24 27" fill="none">
                                                <path d="M22.6667 4.83398C22.6667 7.04312 20.8759 8.83398 18.6667 8.83398C16.4575 8.83398 14.6667 7.04312 14.6667 4.83398C14.6667 2.62485 16.4575 0.833984 18.6667 0.833984C20.8759 0.833984 22.6667 2.62485 22.6667 4.83398ZM5.33333 2.16732C2.38781 2.16732 0 4.55513 0 7.50065V10.1673H2.66667V7.50065C2.66667 6.02789 3.86057 4.83398 5.33333 4.83398H9.33333V2.16732H5.33333ZM18.6667 26.1673C21.6121 26.1673 24 23.7795 24 20.834V18.1673H21.3333V20.834C21.3333 22.3068 20.1395 23.5007 18.6667 23.5007H14.6667V26.1673H18.6667ZM5.33333 19.5007C7.54247 19.5007 9.33333 17.7099 9.33333 15.5007C9.33333 13.2915 7.54247 11.5007 5.33333 11.5007C3.1242 11.5007 1.33333 13.2915 1.33333 15.5007C1.33333 17.7099 3.1242 19.5007 5.33333 19.5007ZM18.6667 10.1673C15.7212 10.1673 13.3333 12.5552 13.3333 15.5007H24C24 12.5552 21.6121 10.1673 18.6667 10.1673ZM0 26.1673C0 23.2219 2.38781 20.834 5.33333 20.834C8.27885 20.834 10.6667 23.2219 10.6667 26.1673H0Z" fill="#EFEFEF" />
                                            </svg>
                                        </Box>
                                    } else if (followup?.followup_type == 'Demo') {
                                        return <Box sx={{ width: '30px', height: '30px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <path d="M10.6667 5.66667C10.6667 7.13943 9.47276 8.33333 8 8.33333C6.52724 8.33333 5.33333 7.13943 5.33333 5.66667C5.33333 4.19391 6.52724 3 8 3C9.47276 3 10.6667 4.19391 10.6667 5.66667ZM6.66667 21.6667V29.6667H4V13.6667C4 11.4575 5.79087 9.66667 8 9.66667C9.09412 9.66667 10.0856 10.1059 10.8078 10.8178L13.9737 13.8076L17.0575 10.7239L18.9431 12.6095L14.0268 17.5257L12 15.6116V29.6667H9.33333V21.6667H6.66667ZM13.3333 7H25.3333V19H13.3333V21.6667H19.1539L22.9185 29.6667H25.8657L22.1011 21.6667H26.6667C27.4031 21.6667 28 21.0697 28 20.3333V5.66667C28 4.93029 27.4031 4.33333 26.6667 4.33333H13.3333V7Z" fill="#EFEFEF" />
                                            </svg>
                                        </Box>
                                    } else if (!followup?.followup_type) {
                                        return <Box sx={{ width: '30px', height: '30px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <path d="M18.6673 19.0033V29.334H5.33398C5.33398 23.4429 10.1096 18.6673 16.0007 18.6673C16.9215 18.6673 17.8151 18.784 18.6673 19.0033ZM16.0007 17.334C11.5807 17.334 8.00065 13.754 8.00065 9.33398C8.00065 4.91398 11.5807 1.33398 16.0007 1.33398C20.4207 1.33398 24.0007 4.91398 24.0007 9.33398C24.0007 13.754 20.4207 17.334 16.0007 17.334ZM25.334 22.1151L28.1624 19.2867L30.048 21.1723L27.2196 24.0007L30.048 26.8291L28.1624 28.7147L25.334 25.8863L22.5056 28.7147L20.62 26.8291L23.4484 24.0007L20.62 21.1723L22.5056 19.2867L25.334 22.1151Z" fill="#F9F9F9" />
                                            </svg>
                                        </Box>
                                    }
                                }
                            )()}
                        </CallIconCss>
                        <Box sx={{ flexDirection: 'column' }}>
                            {
                                followup?.followup_date ? <Box>
                                    <FollowupName>
                                        {followup?.followup_date ? `${

                                             (() => {
                                               if (followup?.followup_type == 'Call') {
                                                return `${followup?.assigned_to?.firstname} ${followup?.assigned_to?.lastname} will ${followup?.followup_type} to`
                                               } else if (followup?.followup_type == 'Email') {
                                                    return `${followup?.assigned_to?.firstname} ${followup?.assigned_to?.lastname} will ${followup?.followup_type} to`
                                               }else if (followup?.followup_type == 'Meeting') {
                                                return `${followup?.assigned_to?.firstname} ${followup?.assigned_to?.lastname} will meet to`
                                               } else if (followup?.followup_type == 'Demo') {
                                                return `${followup?.assigned_to?.firstname} ${followup?.assigned_to?.lastname} will provide ${followup?.followup_type} to`
                                               }
                                             })()
                                              
                                            }   
                                            ` 
                                            : ''}
                                    </FollowupName>
                                    <Tooltip title={followup?.followup_date ? `${followup?.lead?.contact?.first_name} ${followup?.lead?.contact?.last_name}` : ''} placement="top-start">
                                    <CallNameCss>
                                        {followup?.followup_date ? `${followup?.lead?.contact?.first_name} ${followup?.lead?.contact?.last_name}` : ''}
                                    </CallNameCss>
                                    </Tooltip>
                                </Box> : <T4MedNormalText sx={{ color: themeColor.palette.Primarytext1 }}>
                                    No follow-up
                                </T4MedNormalText>
                            }
                        </Box>
                    </FollowupIcon>
                </FollowupType>
            </LeadCard>

        </Grid>
    )
}

export default DashboardCard
