import React from 'react'

const FollowupTaskIcon = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.95455 16L0.5 19.5V1C0.5 0.44772 0.94772 0 1.5 0H19.5C20.0523 0 20.5 0.44772 20.5 1V15C20.5 15.5523 20.0523 16 19.5 16H4.95455ZM9.7929 9.1213L7.31802 6.64645L5.90381 8.0607L9.7929 11.9497L15.4497 6.29289L14.0355 4.87868L9.7929 9.1213Z" fill="#B4AC00"/>
    </svg>
  )
}

export default FollowupTaskIcon