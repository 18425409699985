import React from 'react'

const AddIcon = ({h, w }) => {
  // w=28, h=27
  return (
    <svg width="32" height="33"  viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 28.5C22.6274 28.5 28 23.1274 28 16.5C28 9.87258 22.6274 4.5 16 4.5C9.37258 4.5 4 9.87258 4 16.5C4 23.1274 9.37258 28.5 16 28.5Z" stroke="#049E01" stroke-width="2" stroke-miterlimit="10"/>
        <path d="M11 16.5H21" stroke="#049E01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 11.5V21.5" stroke="#049E01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default AddIcon
