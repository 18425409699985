import React from 'react'

const EyeCloseIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7109 9.94727L17.5 13.0332" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.0469 11.6631L12.6016 14.8115" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.94531 11.6572L7.39062 14.8135" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.28125 9.94727L2.49219 13.0488" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5 8.19531C3.8125 9.82031 6.21875 11.875 10 11.875C13.7812 11.875 16.1875 9.82031 17.5 8.19531" stroke="#A8A8A8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default EyeCloseIcon
