import React,{useState, useEffect} from "react";
import {Grid, Box, styled, Card, Button, Typography, IconButton, InputAdornment, TextField, CircularProgress} from '@mui/material';
import {InputBox, Label, Logo, CustemBtn, EyeCloseIcon, EyeOpenIcon} from '../../components/custom';
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { performResetPassword, updatePassword } from "../../Redux/Thunk/auththunk";
import swal from "sweetalert";
import  validate  from "../../validation/validation";
import DisplayError from "../../validation/DisplayError";
import {LoginContainer, LogoBox, ResetPasswordDetails} from './authorization.style'
import {H1FormTitle, T6TableText, H5FormLabel} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import LoginImage from "../../components/custom/LoginImage";

// const LoginContainer = styled('div')({
//   minHeight:'100vh',
//   margin: "10px",
//   background: '#F8FCFF',
//   display: 'flex',
//   flexDirection: 'row',
//   justifyContent: 'center',
//   alignItems: 'center',
// })

// const LogoBox = styled('div')({
//   display:'flex',
//   flexDirection:'row',
//   alignItems:'center',
//   justifyContent:'center',
//   margin:'50px 0px 20px 0px',
//   '@media (max-width: 992px)': {
//     margin:'20px 20px', 
//   },
// })

// const Image = styled('div')({
//   '@media (max-width: 992px)': {
//       display: 'none', 
//       alignItems:'center',
//     },
//  })
 
// const ResetPasswordDetails =styled('div')({
//   background: '#FFFFFF',
//   width: 'auto',
//   height: '400px',
//   borderRadius:'10px',
//   padding: "20px 20px 32px",
//   margin:'40px 50px',
//   '@media (max-width: 992px)': {
//     margin:'40px 20px', 
//     alignItems:'center',
//   },
// })

// Reset password component is used for reset password and change password(common component).

const ResetPassword = () =>{
 const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth)
  const search = useLocation().search;
  const hash = new URLSearchParams(search).get('hash');
  const [error, setError] = useState([])
  const [showNewPassword, setShowNewPassword]= useState(true)
  const [showConfirmPassword, setShowConfirmPassword]= useState(true)
  const [confirmPassword, setConfirmNewPassword] = useState('')
  const [resetPassword ,setResetPassword] = useState({
    password:'',
    email:'',
  })
  const [currentPassword, setCurrenPassword] = useState('')
  const [displayErrorMsg, setDisplayErrorMsg ] = useState(false)
  const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword]= useState(true)
  const [loader, setLoader] = useState(false)
  
  useEffect(()=>{
    if(!hash){
      // navigate('/')
    }
  },[])

  let validation_input = [
    (hash ? {tagid: 'email', text: resetPassword.email, regex_name: 'email', required: true, errmsg:'Please enter registered email.'} :""),
    {tagid: 'password', text: resetPassword.password, required: true, regex_name: 'password', errmsg: 'Please enter valid password.'},
    // {tagid: 'confirmPassword', text: confirmPassword, required: true, regex_name: 'password', errmsg: 'Please enter valid password.'},  
  ]

  const handleResetPassword =(event) => {
    setResetPassword({...resetPassword, [event.target.name]:event.target.value.trim()})
  }

  const handleCurrentPassword = (event)=>{
    setCurrenPassword(event.target.value?.trim())
  }

  const handleResetNewPassword =(event) => {
    setConfirmNewPassword(event.target.value)
  }

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword =() =>{
    setShowConfirmPassword(!showConfirmPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const UpdatePassword = ()=>{
    if(validate(validation_input).length == 0){
      setLoader(true)
        if(confirmPassword === resetPassword?.password){
          dispatch(updatePassword({username:user?.data?.user?.email, currentPassword: currentPassword, confirmPassword: confirmPassword})).then((res)=>{
            if(res?.payload?.errormsg){
              setError([])
              sweetalert('error', res?.payload?.errormsg)
              setLoader(false)
            }else{
              sweetalert('success', res?.payload?.data)
              navigate('/')
              setLoader(false)
            }
          })
        }else{
          setError([])
          setLoader(false)
          sweetalert('error',"The confirm password does not match.")
        }   
    }else{
      setError(validate(validation_input))
      checkValidation()
    }  
  }

  const checkValidation =()=>{
    if(currentPassword?.length === 0 && confirmPassword?.length === 0){
      setDisplayErrorMsg(true)
      setConfirmPasswordErrorMsg(true)
    }else if (currentPassword?.length === 0){
      setDisplayErrorMsg(true)
      setConfirmPasswordErrorMsg(false)
    }else if(confirmPassword?.length === 0){
      setDisplayErrorMsg(false)
      setConfirmPasswordErrorMsg(true)
    }else{
      setDisplayErrorMsg(false)
      setConfirmPasswordErrorMsg(false)
    }
  }

  const handleChangeToCancle =()=>{
    navigate('/dashboard')
  }

  const submitResetPassword = () =>{
    if(validate(validation_input).length == 0){
      setLoader(true)
      if(confirmPassword === resetPassword?.password){
        resetPassword.hash = hash
        dispatch(performResetPassword(resetPassword)).then((res)=>{
          if(res?.payload?.errormsg){
            sweetalert('error', res?.payload?.errormsg)
            setLoader(false)
          }else{
            sweetalert('success', res?.payload?.data)
            setLoader(false)
            navigate('/')
          }
        })
      }else{
        setError([])
        setLoader(false)
        sweetalert('error',"New and Confirm Password does not match.")
      }
    }else{
      setError(validate(validation_input))
    }  
  }

  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };

  return(
      <LoginContainer>
        {/* Display Image */}
        <Grid container style={{display:'flex', justifyContent:"center"}}>
         {hash ? <><Grid item xs={12} md={10} sm={10} lg={8} style={{display:'flex', justifyContent:"center"}}>
           <LoginImage />
              {/* <Image>
                <img  alt="The house from the offer."
                  src="/login_image.png" style={{width: '100%', 
                  height:'auto',
                  maxWidth:'100%'}} />
              </Image> */}
            </Grid>
          </>:<></>}
          {/* Employee Reset Password Details */}
          {/* <ResetPasswordContainer> */}
          <Grid item xs={12} md={10} sm={10} lg={4} style={{background:'#EDEDFF', borderBottomRightRadius:'10px', borderTopRightRadius:'10px', width: '100%',height:'auto', maxWidth:'100%'}}>
              <LogoBox>
                <Logo width={172} height={45}/>
              </LogoBox>
              
              <ResetPasswordDetails>
              {hash ? <>
                  <H1FormTitle sx={{color:themeColor.palette.BrandColor,}}>
                    Reset Password
                  </H1FormTitle>

                  <H5FormLabel sx={{color:themeColor.palette.Primarytext1, mt:2}} >
                    Registered Email
                  </H5FormLabel>
                  <InputBox name='email' value={resetPassword.email} onchange={handleResetPassword} sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:1, pl:1}} placeholder ="User Email"/>
                  <DisplayError error={error} fortagid='email'/>
                </>:<>
                  <H1FormTitle sx={{color:themeColor.palette.BrandColor}}>
                    Change Password
                  </H1FormTitle>
                  <Typography sx={{color:themeColor.palette.Primarytext1, fontSize:'12px', letterSpacing:0.5, mt:2}}>
                    Current Password
                  </Typography>
                  <InputBox name='currentPassword' type={showCurrentPassword ?'password': 'text'} value={currentPassword} onchange={handleCurrentPassword} sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:1, pl:1}} placeholder ="Current Password" endAdornment={
                      <InputAdornment position="end">
                        <IconButton style={{ml:1}}  aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword} onMouseDown={handleMouseDownPassword}>
                          {showCurrentPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                       </IconButton> 
                    </InputAdornment>
                  }/>
                  {displayErrorMsg ? <small style={{color:'red', fontSize:'smaller'}}>Please enter current password.</small> :" "}
                  {/* <DisplayError error={error} fortagid='currentPassword'/> */}
                </>}

                  <H5FormLabel sx={{color:themeColor.palette.Primarytext1, mt:2}} >
                    New Password
                  </H5FormLabel>
                  <InputBox name='password' type={showNewPassword ?'password': 'text'} value={resetPassword.password} onchange={handleResetPassword} sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:1, pl:1}} placeholder ="New Password" endAdornment={
                      <InputAdornment position="end">
                          <IconButton style={{ml:1}}  aria-label="toggle password visibility"
                          onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {showNewPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                        </IconButton> 
                      </InputAdornment>
                    }/>
                    <Typography sx={{color:'#000000', fontSize:'10px', letterSpacing:0.5}}>(Password must be 8 characters long with 1 uppercase letter, number & special character.)</Typography>
                    <DisplayError error={error} fortagid='password'/>

                  <H5FormLabel sx={{color:'#000000', mt:2}}>
                    Confirm Password 
                  </H5FormLabel>
                  <InputBox name='confirmPassword' type={showConfirmPassword ?'password': 'text'} value={confirmPassword} onchange={handleResetNewPassword} sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:1, pl:1}} placeholder ="Password" endAdornment={
                      <InputAdornment position="end">
                         <IconButton style={{ml:1}} aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword}>
                          {showConfirmPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                        </IconButton> 
                      </InputAdornment> 
                  }/>
                   {confirmPasswordErrorMsg ? <small style={{color:'red', fontSize:'smaller'}}>Please enter valid password. </small> :" "}
                  {/* <DisplayError error={error} fortagid='confirmPassword'/>  */}

                  {hash ? <>  
                      <Button
                          id="validate"
                          variant="contained"
                          disabled={loader}
                          onClick={submitResetPassword}
                          sx={{
                              color: '#FFFFFF',
                              backgroundColor: '#3107AA',
                              '&:hover': { backgroundColor: '#3107AA', color: '#FFFFFF' },
                              fontSize: '16px', 
                              fontWeight: '500',
                              borderRadius:"10px",
                              textTransform: 'none', 
                              width:'100%', height:'40px',
                              marginTop:'20px',
                              boxShadow:'0px 4px 6px #e1e1e1'
                          }}
                          >
                          {loader ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
                        </Button>
                  {/* <CustemBtn onClick={submitResetPassword} variant='contained' sx={{width:'100%', height:'40px', color:'#FFFFFF',fontSize:'16px', fontWeight:'500', borderRadius:'10px', marginTop:'20px'}} text="Submit"/> */}
                    </> : <><Grid container style={{display:'flex', marginTop:'10px', flexDirection:'row', justifyContent:'center'}}>
                        <CustemBtn variant='contained' onClick={handleChangeToCancle} sx={{color:'#FFFFFF', mr:3, fontSize:'16px', fontWeight:'500', borderRadius:'10px', marginTop:'15px', background:'#FF3333',"&:hover": {backgroundColor:"red"}}} text="Cancel"/>
                        <Button
                          id="validate"
                          variant="contained"
                          disabled={loader}
                          onClick={UpdatePassword}
                          sx={{
                              color: '#FFFFFF',
                              backgroundColor: '#3107AA',
                              '&:hover': { backgroundColor: '#3107AA', color: '#FFFFFF' },
                              fontSize: '16px', 
                              fontWeight: '500',
                              borderRadius:"10px",
                              textTransform: 'none', 
                              marginTop:'15px',
                              boxShadow:'0px 4px 6px #e1e1e1'
                          }}
                          >
                          {loader ? <CircularProgress size={20} color="inherit" /> : 'Change'}
                        </Button>
                        {/* <CustemBtn variant='contained' onClick={UpdatePassword} sx={{color:'#FFFFFF', fontSize:'16px', fontWeight:'500', borderRadius:'10px', marginTop:'15px'}} text="Change"/> */}
                      </Grid>
                    </>}
              </ResetPasswordDetails> 
          </Grid>  
          {/* </ResetPasswordContainer> */}
        </Grid>
      </LoginContainer>
  )}


export default ResetPassword