import React, { useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, Paper, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, IconButton, Tooltip} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { leadValidate, leadAssignedToUser, leaddetails, displayLeadList } from '../../../Redux/Thunk/createleadthunk';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import { headCells } from './headcells';
import {PopperFilter, FilterCloseIcon,EditIcon, Navbar, FilterIcon, CustemBtn, InputBox, CompanyName, LessThanIcon  } from '../../../components/custom';
import swal from 'sweetalert';
import { H4TableID, H6TableCompany, T6TableText } from '../../../Theme/theme';
import AssignModal from '../../../components/custom/AssignModal';
import TableListTitle from '../../../components/custom/TableListTitle';
import TableListSearch from '../../../components/custom/TableListSearch';
import { TailSpin} from 'react-loader-spinner';
import { displayCompanyDetails } from '../../../Redux/Thunk/createcompanythunk';
import { displayContactList, getContactList } from '../../../Redux/Thunk/createcontact';
import AssignIcon from '../../../components/custom/AssignIcon';
import DisableAssignIcon from '../../../components/custom/DisableAssignIcon';
import moment from 'moment';
import themeColor from '../../../Theme/themeColor';
import DisableEditIcon from '../../../components/custom/DisableEditIcon';
import AdminRole from '../../../adminrole/Adminrole';
import { storeAddressDetails } from '../../../Redux/slices/createCompanySlice';
import { storeContactDetails } from '../../../Redux/slices/createContactSlice';

const CardBody = styled('div')({
  //  backgroundColor:'#F0EFFD',
  //  padding:'10px',
  //  borderRadius:'10px',
})

const useStyles = makeStyles({
  tableCell: {
    padding: '3px',
    fontSize: '12px',
  },
});

export default function CompanyWiseLeads (){
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {state} = useLocation()
  const {user} = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user.role?.permissions
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [user_ids, setUserId] = useState([])
  const [open, setOpen] = useState(false);
  // const { fetchleadlist } = useSelector ((state) => state.lead)
  const [fetchleadlist, setFetchleadlist]= useState([]);
  const [update, setUpdate] = useState(false);
  const [orderBy, setOrderBy] = useState('calories');
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [companyName, setCompanyName] = useState('')
  const [openAssignModal,setOpenAssignModal] = useState(false)
  const [filterlead, setFilterLead] = useState('')
  const [leadAssigned, setLeadAssigned]= useState({})
  const [loader, setLoader] = useState(false)
  const leadName = 'Lead'
  const route = 'companyProfilePage'
  const title = 'companyProfilePageLeadDetails'
  const tooltipTitle = "New Lead"
  const placeholderTitle = "Search Lead"
  const listname = ''
  const classes = useStyles();
// state?.data?.company ? state?.data?.company?.id :state?.id

  React.useEffect(()=> {
    if(!state || state === 'undefined') {
      navigate('/dashboard')
      return
    } else {
      setFetchleadlist([])
      setLoader(true)
      dispatch(displayLeadList({start:page*rowsPerPage, length:rowsPerPage, company_id:state?.id, search:search, filterlead:filterlead, col_sort: orderBy.split(' ').join(''), order: order.toUpperCase()})).then((res)=>{
        setFetchleadlist(res?.payload)
        setLoader(false)
      })
    }
  },[update])

  const handleCreateLead=()=>{
    dispatch(displayContactList(state?.data?.company ? state?.data?.company?.id :state?.id)).then((res)=>{
      if(res?.payload){
        navigate('/dashboard/lead' , {state: { companyName :state?.data?.company ? state?.data?.company :state, title: route, operation:'createLead'}})
        dispatch(storeAddressDetails({}))
        dispatch(storeContactDetails({}))
      }
    })   
  }

  const handleLeadDetails=(lead)=>{
    navigate('/dashboard/leaddetails', {state:{title: title, leadDetails:lead} })
  }

  const findIds= (id1, id2) => {
    if (user_ids.includes(id1) || user_ids.includes(id2)) {
      return true 
    } else {
      return false
    }
  }

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setUpdate(!update)
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setUpdate(!update)
  };

  const handleClickEditLead = (lead) => {
    if(lead?.status === 'Lost'|| lead?.status ==="Closed-No-Action"){
      sweetalert('error', "This lead has been Lost.")
    }else if (lead?.status ==='Converted'){
      sweetalert('error', "This lead has been Converted to Opportunity.")
    }else{
      navigate('/dashboard/lead', {state: {lead :lead, operation:'editLead'}})
      dispatch(displayCompanyDetails(lead?.company?.id))
      getContactList(lead?.company?.id)
    } 
  };

  const handleValidate =(id)=>{
    // swal({
    //   buttons: {
    //       Cancel: {
    //           text: "No",
    //           value: "cancel",
    //           className: "popup-blue",
    //       },
    //       Delete: {
    //           text: "Yes",
    //           value: "yes",
    //           className: "popup-gray",
    //       },
    //   },
    //   title:  `Do You Want to Validate the Lead ?`,
    //   className: "custom-modal",
    //   closeOnClickOutside: false,
    // }).then((value) => {
    //     if (value == 'yes') {
    //       dispatch(leadValidate({lead_id:id})).then((res)=>{
    //         sweetalert('success', res?.payload?.data)
    //         setUpdate(!update)
    //       })
    //     } else {
    //       setUpdate(!update)
    //     }
    // }) 
  }

  const handleAssign = (row) => {
    setLeadAssigned(row)
    setOpenAssignModal(true)
  }

  const handleChangeLeadNotAssign =()=>{
    sweetalert('error', "You have no permissions to access this. Please contact your administrator for more information.")
  }

  const handleFilter = (e)=> {
    setPage(0)
    if (e.target.value == "All Leads") {
      setFilterLead('')
      setOpenopper(false)
      setUpdate(!update)
    } else {
      setFilterLead(e.target.value)
      setOpenopper(false)
      setUpdate(!update)
    }  
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
    setUpdate(!update)
  }

  const handleClickOpen = (lead) => {
    if(lead?.status === 'Lost'|| lead?.status === "Closed-No-Action"){
      sweetalert('error', "This lead has been Lost")
    }else if (lead?.status ==='Converted'){
      sweetalert('error', "This lead has been Converted to Opportunity.")
    }else{
      navigate('/dashboard/lead', {state: {lead :lead, operation:'editLead', title: route}})
      dispatch(displayCompanyDetails(lead?.company?.id))
      getContactList(lead?.company?.id)
    } 
    // }else{
    //   navigate('/dashboard/lead', {state: {lead :lead, operation:'editLead', title: route}})
    // } 
    // navigate('/dashboard/lead', {state: {lead :lead, operation:'editLead'}})
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
  
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    
    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => ( 
             <TableCell
             sx ={{fontWeight:600, fontSize:'13px', zIndex:0}}
             key={headCell.id}
             align={headCell.numeric ? 'center' : 'left'}
             padding={headCell.disablePadding ? 'normal' : 'none'}
             sortDirection={orderBy === headCell.id ? order : false}
           >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>      
          ))}
        
         {/* {(checkAdminRole && permissionsDetails?.perm_validate_edit) || permissionsDetails?.perm_validate_edit ? <TableCell align='center' sx = {{ zIndex: !openPopper ? 1 : 0, fontWeight:600, fontSize:'13px',p:0}}>Validate
           </TableCell> :<TableCell align='left' sx ={{ zIndex: !openPopper ? 1 : 0, fontWeight:600, fontSize:'13px', p:0}}>
            Validation</TableCell>} */}
            <TableCell align='left' sx ={{ zIndex: !openPopper ? 1 : 0, fontWeight:600, fontSize:'13px', p:0}}>
            Validation</TableCell>

          {/* {(checkAdminRole && permissionsDetails?.perm_lead_edit) || permissionsDetails?.perm_lead_edit ? <TableCell align='left' sx = {{zIndex:0, fontWeight:600, fontSize:'13px'}}>Edit</TableCell> :<></>} */}

        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setUpdate(!update)
  };
  
  const openLeadDetailsPage =(row) =>{
    dispatch(leaddetails(row.id)).then(()=>{
      navigate('/dashboard/leaddetails', {state: {leadDetails: row, title:title}})
    })  
  }

  const saveAssignedManagerOrExecutiveName = (managerList, selectManagerName)=>{
    if(selectManagerName ===''){
      sweetalert ('error', "Please Select Name") 
    }else{
        let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectManagerName)
        dispatch(leadAssignedToUser({lead_id:leadAssigned?.id, user_id:findUser?.id})).then((res)=>{
        sweetalert ('success', res?.payload?.data) 
        setOpenAssignModal(false)
        setUpdate(!update)
       })
    }
  }
  const handleCloseFilter = () => {
    setFilterLead('')
    setUpdate(!update)
  }

  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };

  return (
    <>
      <CompanyName style={{"&:hover": {cursor:'pointer'}}} sx={{color:'#FFFFFF',letterSpacing:"0.04em"}}  label={state?.data?.company ? `${state?.data?.company?.company_name} ${state?.data?.company?.company_type}`:`${state?.company_name} ${state?.company_type}`} companyID={state?.data?.company ? state?.data?.company?.id :state?.id}/>    
      <PopperFilter filtervalues ={[{name:'All Leads'}, {name:'Open'},{name:'WIP'},{name:'Converted'},{name:'Lost'}, {name:'Closed-No-Action'}] } open={openPopper} anchorEl={anchorEl} placement={placement} onClick={handleFilter} />
    
    {/* Create New lead and search lead */}
    <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
      {/* Table list title  */}
      <TableListTitle title={"Lead List"} />

      {/* Search component and create new lead */}
      <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={(checkAdminRole && permissionsDetails?.perm_lead_create) || permissionsDetails?.perm_lead_create} onClick ={handleCreateLead} onchange={handleSearch} />
          <Tooltip title="Filter By Status" placement="top-start">
            <IconButton onClick={handleClick("bottom-start")} sx={{mr: {lg:4, xs:1}}}>
              {!openPopper ? <FilterIcon /> : <FilterCloseIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {
      filterlead ? <Box sx={{ml:4, display:'flex', justifyContent:'space-between', fontSize:'10px',width:'25%',p:0.5,mt:0.5, border:'1px solid black', borderRadius:'10px'}}>
        <Typography sx={{fontSize:'12px'}}>
       Showing Leads for status  <span style={{color:'blue', marginLeft:'10px'}}>{filterlead}</span> 
      </Typography>
      <Typography onClick={handleCloseFilter} sx={{color:"red", fontSize:'12px', cursor:'pointer', fontWeight:600, mr:0.5}}>
         Clear Filter X
      </Typography>
      </Box>:""
    }

      <CardBody sx={{mt:1, ml:{xs:1, sm:1, md:1, xl:4, lg:4}, mr:{xs:1, sm:1, md:1, xl:4, lg:4}}}> 
        <Paper sx={{padding:'10px', mb: 2}}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              sx={{ minWidth: 750}}
              size='small'
              stickyHeader aria-label="sticky table">

              <EnhancedTableHead
                numSelected={fetchleadlist?.data?.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={fetchleadlist?.data?.length}
              />
              {loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
              <TailSpin
                height="30"
                width="30"
                color="blue"
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              /> </Box>:<>
                <TableBody>
                  {fetchleadlist?.data?.length === 0? <TableRow>
                      <TableCell colSpan={12} style={{textAlign: 'center', color:'red', fontWeight:600}}>
                        No leads available
                      </TableCell>
                    </TableRow>:(   
                      fetchleadlist?.data?.map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                            hover
                            tabIndex={-1}
                            key={row?.lead_no}
                          >
                            <TableCell id={labelId} scope="row" padding="none" align="left" sx={{ padding: '3px' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Tooltip title={row?.type ==="Hot"? "Hot": row?.type ==="Cold" ? "Cold" :"Warm"} placement="top-start">
                                <svg width="5" height="40" viewBox="0 0 5 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="5" height="40" rx="2.5" fill={row?.type === "Hot" ? "#F16500" : row?.type === "Cold" ? "#B0AEFF" : "#FFC700"} />
                                </svg>
                                </Tooltip>
                                <span>
                                  <H4TableID onClick={() => handleLeadDetails(row)} sx={{color:themeColor.palette.LeadID, paddingLeft:'5px', cursor:'pointer'}}>
                                    {row?.lead_no} {row?.lead?.lead_no}
                                  </H4TableID>
                                </span>
                              </div>
                            </TableCell>
                            {/* <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              align='left'
                              sx={{padding:'3px'}}
                            >
                              <svg width="10" height="30" viewBox="0 0 10 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="10" height="30" rx="6" fill="#FE8731"/>
                              </svg><span> <Typography sx={{color:'#B31312',fontSize:'14px', fontWeight: 600, "&:hover": {cursor:'pointer'}}} onClick={()=> openLeadDetailsPage(row)} >{row.lead_no}</Typography></span>   TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"
                            </TableCell> */}
                            
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>
                            <T6TableText>
                              {row?.contact?.first_name} {row?.contact?.last_name} 
                              </T6TableText>
                              </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px', maxWidth:'150px'}}>
                                <T6TableText>
                                  {row?.created_by?.firstname} {row?.created_by?.lastname} {row?.lead?.created_by?.firstname} {row?.lead?.created_by?.lastname} 
                                </T6TableText>
                            </TableCell>

                            {/*    assigned_to   "&:hover": (row?.status ==='Converted' || row?.status === 'Lost' || row?.status ==="Closed-No-Action")? {cursor:'default'}:{cursor:'pointer'} onClick={()=> permissionsDetails?.perm_assign_edit || (checkAdminRole && permissionsDetails?.perm_assign_edit)? (row?.status ==='Converted' || row?.status === 'Lost' || row?.status ==="Closed-No-Action" || !findIds(row?.created_by?.id, row?.assigned_to?.id)) ? "" :handleAssign(row): '' */}
                            <TableCell align="left" sx={{ padding: '3px', fontSize: '12px'}}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '4px',maxWidth:'150px', flex: 1}}>
                                <T6TableText>
                                  {`${row?.assigned_to?.firstname} ${row?.assigned_to?.lastname}`} 
                                 </T6TableText>
                                  </span>
                               
                                {/* <span style={{flexShrink: 0}}>
                                  {(checkAdminRole && permissionsDetails?.perm_assign_edit) || permissionsDetails?.perm_assign_edit ? <>
                                    <Tooltip title="Assign Lead to" placement="top-start">
                                      <IconButton>
                                        {row?.status ==='Converted' || row?.status === 'Lost' || row?.status ==="Closed-No-Action" ? <DisableAssignIcon/>: findIds(row?.created_by?.id, row?.assigned_to?.id) ? <AssignIcon /> :  <DisableAssignIcon/> } 
                                      </IconButton> 
                                    </Tooltip></>: " "}
                                </span> */}
                              </div>
                            </TableCell>       
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>
                            <T6TableText>
                              {row?.service_provider} {row?.lead?.service_provider} 
                              </T6TableText>
                              </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>
                            <T6TableText>
                              {row?.product} {row?.lead?.product}
                              </T6TableText>
                              </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>
                            <T6TableText>
                              {moment(row?.createdAt).format('D/M/YYYY')}
                              </T6TableText>
                              </TableCell>
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px', fontWeight:600, color: (row?.status || row?.lead?.status)==='Open'? '#CBB279' : (row?.status || row?.lead?.status)==='WIP'? '#0094C2':(row?.status || row?.lead?.status)==='Converted'? '#038600':'#E70707'}}>
                          
                              {row?.status} {row?.lead?.status}
                          
                              </TableCell> 
                              {
                                (state?.For == 'dashboard' && listname == 'expiring_lead') ? <TableCell align='center' sx={{padding:'3px', fontSize:'12px',fontWeight:600,
                                color:`${
                                  (
                                      () => {
                                          if (row?.expired_in <=2) {
                                              return '#E70707'
                                          } else if (row?.expired_in > 2 && row?.expired_in < 3 ){
                                              return '#ED9C00'
                                          } else {
                                              return "#747600"
                                          }
                                      }
                                  )()
                              }`
                                }}>
                                  {row?.expired_in} Days
                                </TableCell> : ''
                              }

                              {/* {(checkAdminRole && permissionsDetails?.perm_validate_edit) || permissionsDetails?.perm_validate_edit ? <>  */}
                              <TableCell align="center" sx={{ padding:'3px', fontSize:'12px'}}> 
                                  {row?.validated_status ==="Validated" || row?.status ==='Converted' ? <div>
                                  <Tooltip title="Lead is Validated" placement="top-start">
                                    <IconButton>
                                      <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M4.74789 5.85463C4.79433 5.90112 4.84947 5.938 4.91017 5.96316C4.97087 5.98832 5.03594 6.00128 5.10164 6.00128C5.16735 6.00128 5.23241 5.98832 5.29311 5.96316C5.35381 5.938 5.40896 5.90112 5.45539 5.85463L11.2479 0.147132C11.2943 0.100643 11.3495 0.063764 11.4102 0.0386019C11.4709 0.0134397 11.5359 0.000488281 11.6016 0.000488281C11.6674 0.000488281 11.7324 0.0134397 11.7931 0.0386019C11.8538 0.063764 11.909 0.100643 11.9554 0.147132L13.4554 1.62213C13.5019 1.66857 13.5388 1.72371 13.5639 1.78441C13.5891 1.84511 13.602 1.91017 13.602 1.97588C13.602 2.04159 13.5891 2.10665 13.5639 2.16735C13.5388 2.22805 13.5019 2.2832 13.4554 2.32963L5.43039 10.3546C5.38396 10.4011 5.32881 10.438 5.26811 10.4632C5.20741 10.4883 5.14235 10.5013 5.07664 10.5013C5.01094 10.5013 4.94587 10.4883 4.88517 10.4632C4.82447 10.438 4.76933 10.4011 4.72289 10.3546L0.247893 5.85463C0.154196 5.76087 0.101562 5.63375 0.101562 5.50119C0.101562 5.36864 0.154196 5.24152 0.247893 5.14776L1.74789 3.64776C1.79433 3.60127 1.84947 3.56439 1.91017 3.53923C1.97087 3.51407 2.03594 3.50111 2.10164 3.50111C2.16735 3.50111 2.23241 3.51407 2.29311 3.53923C2.35381 3.56439 2.40896 3.60127 2.45539 3.64776L4.74789 5.85463Z" fill="#03A500"/>
                                      </svg> 
                                      </IconButton>
                                   </Tooltip>                                 
                                  </div> :<>
                                  <Typography sx={{color:'#9C9C9C',padding:'3px', fontSize:'12px'}}>Pending</Typography>
                                    {/* <CustemBtn sx={{fontSize:'12px', backgroundColor:themeColor.palette.TableButton, color:'white', borderRadius:'20px', paddingleft:'20px', paddingRight:'20px',
                                        "&:hover": {
                                          backgroundColor:themeColor.palette.TableButton
                                      }}} variant='contained' text='Validate' disabled={row?.status ==="Closed-No-Action" || row?.status ==='Lost' || !findIds(row.created_by?.id, row.assigned_to?.id)} onClick={()=>handleValidate(row)}  /> */}
                               </>}
                                </TableCell> 
                                {/* </> : <TableCell align={row?.validated_status === "Validated" ? 'center':'left'} sx={{padding:'3px', fontSize:'12px'}}>
                                  {row?.validated_status=== "Validated" ? <><div style={{alignItems:'center'}}>
                                    <Tooltip title="Lead is Validated" placement="top-start">
                                      <IconButton>
                                          <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M4.74789 5.85463C4.79433 5.90112 4.84947 5.938 4.91017 5.96316C4.97087 5.98832 5.03594 6.00128 5.10164 6.00128C5.16735 6.00128 5.23241 5.98832 5.29311 5.96316C5.35381 5.938 5.40896 5.90112 5.45539 5.85463L11.2479 0.147132C11.2943 0.100643 11.3495 0.063764 11.4102 0.0386019C11.4709 0.0134397 11.5359 0.000488281 11.6016 0.000488281C11.6674 0.000488281 11.7324 0.0134397 11.7931 0.0386019C11.8538 0.063764 11.909 0.100643 11.9554 0.147132L13.4554 1.62213C13.5019 1.66857 13.5388 1.72371 13.5639 1.78441C13.5891 1.84511 13.602 1.91017 13.602 1.97588C13.602 2.04159 13.5891 2.10665 13.5639 2.16735C13.5388 2.22805 13.5019 2.2832 13.4554 2.32963L5.43039 10.3546C5.38396 10.4011 5.32881 10.438 5.26811 10.4632C5.20741 10.4883 5.14235 10.5013 5.07664 10.5013C5.01094 10.5013 4.94587 10.4883 4.88517 10.4632C4.82447 10.438 4.76933 10.4011 4.72289 10.3546L0.247893 5.85463C0.154196 5.76087 0.101562 5.63375 0.101562 5.50119C0.101562 5.36864 0.154196 5.24152 0.247893 5.14776L1.74789 3.64776C1.79433 3.60127 1.84947 3.56439 1.91017 3.53923C1.97087 3.51407 2.03594 3.50111 2.10164 3.50111C2.16735 3.50111 2.23241 3.51407 2.29311 3.53923C2.35381 3.56439 2.40896 3.60127 2.45539 3.64776L4.74789 5.85463Z" fill="#03A500"/>
                                          </svg> 
                                      </IconButton>
                                   </Tooltip>                                 
                                  </div></> :<Typography sx={{color:'#9C9C9C',padding:'3px', fontSize:'12px'}}>Pending</Typography>} </TableCell>}    */}
                          
                            {/* Edit the lead */}
                            {/* {(checkAdminRole && permissionsDetails?.perm_lead_edit) || permissionsDetails?.perm_lead_edit ?
                              <><TableCell align="left">     
                                {
                                  (user?.data?.user?.id == row?.assigned_to?.id) ? <Typography dis sx={{padding:'3px', fontSize:'12px'}} onClick={()=>handleClickEditLead(row)} >
                                  <EditIcon w={25} h={25} />
                              </Typography> : <Typography dis sx={{padding:'3px', fontSize:'12px'}} >
                                    <DisableEditIcon w={25} h={25} />
                                </Typography>
                                }
                              </TableCell> </> 
                          : <></>}  */}

                     {/* Assign button */}
                            {/* {permissionsDetails?.perm_assign_edit ? <> 
                                 <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}><CustemBtn 
                                    sx={{backgroundColor: '#FFC700', fontSize:'12px', color:'white', borderRadius:'20px', color:'#000000' ,
                                    "&:hover": {
                                      backgroundColor: '#FFC700' 
                                 }}} variant='contained' disabled={row?.status ==='Converted' || row?.status === 'Lost' || row?.status ==="Closed-No-Action"} onClick={()=> permissionsDetails?.perm_assign_edit ? handleAssign(row) : handleChangeLeadNotAssign()} text='Assign' /> </TableCell> </>: <></>
                            }  */}
                     {/* Validated button */}
                                 
                          </TableRow>
                          );
                    }))}
                </TableBody>
                </>}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={fetchleadlist?.recordsTotal ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* <Link to='/dashboard/profile' state={state?.data?.company?.id} style={{textDecoration:'none'}} >
          <Typography display='flex' justifyContent='start' sx={{color:'#000000', fontWeight:600, fontSize:'13px', letterSpacing:0.5, mt:3,textAlign:'start'}}>
            <LessThanIcon/> Back to Company Profile
          </Typography>
        </Link> */}
      </CardBody>
    
      {/* Open assign model to assign lead to user*/}
      {openAssignModal && <AssignModal title={leadName} taskNumber={leadAssigned?.lead_no} taskAssignedFromUser={leadAssigned} setUpdate={setUpdate} update={update} openAssignModal={openAssignModal} setOpenAssignModal= {setOpenAssignModal} saveAssignedManagerOrExecutiveName={saveAssignedManagerOrExecutiveName}/>}
    </>
  );
}
