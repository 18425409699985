const headCells = [
    {
        id: 'Task ID',
        numeric: false,
        disablePadding: true,
        label: 'Task ID',
    },
    {
        id: 'Opportunity ID',
        numeric: false,
        disablePadding: true,
        label: 'Opportunity ID',
    },
    {
        id: 'Company Name',
        numeric: false,
        disablePadding: true,
        label: 'Company Name',
    },
    {
        id: 'Contact Name',
        numeric: false,
        disablePadding: true,
        label: 'Contact Name',
    },
    {
        id: 'Created By',
        numeric: false,
        disablePadding: true,
        label: 'Created By',
    },
    {
        id: 'Assigned to',
        numeric: true,
        disablePadding: true,
        label: 'Assigned to',
    },
    {
        id: 'Type',
        numeric: true,
        disablePadding: true,
        label: 'Type',
    },
    {
        id: 'Start Date',
        numeric: true,
        disablePadding: true,
        label: 'Start Date',
    },
    {
        id: 'End Date',
        numeric: true,
        disablePadding: true,
        label: 'End Date',
    },
    {
        id: 'Stage',
        numeric: true,
        disablePadding: true,
        label: 'Stage',
    },
    {
        id: 'Status',
        numeric: true,
        disablePadding: true,
        label: 'Status',
    },
];

export { headCells }