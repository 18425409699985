import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Box, Grid} from "@mui/material"
import { ComapanyName, DatePicker, DropDown, InputBox, SelectDropDown } from '../../components/custom';
import CustemBtn from '../../components/custom/CustemBtn'
import SwitchBtn from '../../components/custom/Switch';
import TextArea from '../../components/custom/TextArea';
import dayjs from 'dayjs';
import validate from '../../validation/validation';
import { useDispatch } from 'react-redux';
import { editCompany } from "../../Redux/Thunk/createcompanythunk";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditCompanypopup({open,setOpen,state,setState,update,setUpdate}) {
  const date =  new Date(state.doi)
  const dispatch = useDispatch();
  const [error, setError] = React.useState([]);
  const [turnoverondate, setTurnoverDate] = React.useState(dayjs(new Date(state.doi)))
  const [value, setValue] = React.useState(dayjs(date))
  console.log('value',value)
  
  const handleClose = () => {
    setOpen(false);
    setError([])
  };

  const handleChangeState=(e)=> {
    setState({...state,[e.target.name]:e.target.value})
  }
  
  const changeDoi= (newValue)=>{
    setValue(newValue.$d);
  }

  const chnageTurnoverDate = (newValue)=>{
    setTurnoverDate(newValue.$d);
  }

  let validation_input = [
    {tagid: 'name', text:state.name, regex_name: 'text', required: true, errmsg: 'Please Enter Name.', allow_numbers: false, other_chars: true, min:1, max: 20},
    //{tagid: 'companyFullName', text:companyDetails.companyName, regex_name: 'text', required: true, errmsg: 'Please Enter Full Name.', allow_numbers: false, other_chars: true, min:1, max: 20},
    {tagid: 'turnover', text: state.turnover, required: true, regex_name:'min_number', errmsg: 'Please Enter the number', min:1},
    {tagid: 'website', text: state.website, required: true, regex_name:'website', errmsg: 'Please Enter correct website '},
    {tagid: 'pan', text: state.pan, required: true, regex_name:'pan_card', errmsg: 'Please Enter PAN Number'},
    {tagid: 'tan', text: state.tan, required: true, regex_name:'pan_card', errmsg: 'Please Enter TAN Number'},
    {tagid: 'cin', text: state.cin, required: true, regex_name:'text', errmsg: 'Please Enter CIN Number', max:30, min:5, allow_numbers: true, other_chars: true},
    //{tagid: 'summary', text: companyDetails.summary, required: true, regex_name:'text', errmsg: 'Your text must be 50 characters', min:50},
    {tagid: 'brand', text: state.brand, required: true, regex_name:'text', errmsg: 'Please Enter Brand'},
  ]

   console.log(validate(validation_input))
  
   const handleUpdate = ()=>{
    if(validate(validation_input).length==0){
      state.doi = value
      state.turnoverondate = turnoverondate
      dispatch(editCompany(state)).then(()=>{
        setUpdate(!update)
        setOpen(false);
        setError([])
      })
      setUpdate(!update)
    }else{
      setError(validate(validation_input))
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
         
        <BootstrapDialogTitle sx={{fontWeight:600,fontSize:{lg:'24px',xs:'20px',sm:'24px'}}} id="customized-dialog-title" onClose={handleClose}>
          Organisation Details
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={{flex:1}} >
              <Typography sx={{fontSize:18,fontWeight:600}}>Organization </Typography>  
          </Box>
            <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>

                <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'end'}} spacing={2} >
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>Type<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                onchange={handleChangeState}
                                Default={state.company_type}
                                name='company_type'
                                options={['IT','Manufacturing']} 
                                label='Select company'
                                sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                                />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6}>
                            <Typography>Name<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox 
                              name="name"
                              onchange={handleChangeState}
                              sx={{border:"1px solid #B0AEFF",background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                              placeholder ="Mobigic Technologies"
                              Default={state.name}
                              />
                              {error.map((err)=>{
                                    if( err.tagid=='name'){
                                        return <small style={{color:'red'}}>{err.errmsg}</small>
                                    }
                                })}
                        </Grid>
                        <Grid sx={{mt:0.5}} item xs={12} md={6} sm={6} lg={2}>
                            <CustemBtn variant='contained' text='Validate' sx={{height:"40px",borderRadius:'8px',backgroundColor:'#049E01'}} />
                        </Grid>
                        
                </Grid>
            </Box>
            <Box style={{marginTop:'20px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={2} >
                        <Grid item xs={12} md={6} sm={6} lg={8}>
                            <Typography>Final Name<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="Mbigic Technology Private Limited"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>Industry Type<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                name='industry_type'
                                options={['IT','Manufacturing',"Government"]} 
                                onchange={handleChangeState}
                                Default={state.industry_type}
                                label='Select industry'
                                sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                                />
                        </Grid>
                </Grid>
            </Box>
            <Box style={{marginTop:'20px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={2} >
                        <Grid item xs={12} md={6} sm={6} lg={5}>
                            <Typography>Website<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox 
                            sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                            placeholder ="JIO Limited"
                            name='website'
                            onchange={handleChangeState}
                            Default={state.website}
                            />
                              {error.map((err)=>{
                                    if( err.tagid=='website'){
                                        return <small style={{color:'red'}}>{err.errmsg}</small>
                                    }
                                })}
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography> Incorporation Date<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <DatePicker 
                                value={value} 
                                onChange={changeDoi} 
                                style={{background:'#FFFFFF',fontSize:'14px',marginTop:'8px'}} >DD-MM-YY</DatePicker>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={3}>
                            <Typography>Brand<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox
                             sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                             placeholder ="Mobigic"
                             name='brand'
                             onchange={handleChangeState}
                             Default={state.brand}
                             />
                               {error.map((err)=>{
                                    if( err.tagid=='brand'){
                                        return <small style={{color:'red'}}>{err.errmsg}</small>
                                    }
                                })}
                        </Grid>
                </Grid>
            </Box>
           
                <Typography sx={{mt:3,fontSize:18,fontWeight:600}}>Identification</Typography>
          
            <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
               
                <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={2} >
                        <Grid item xs={12} md={6} sm={6} lg={5}>
                            <Typography>CIN<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox 
                            sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                            placeholder ="L21091KA2019OPC141331"
                            onchange={handleChangeState}
                            Default={state.cin}
                            name='cin'
                            />
                              {error.map((err)=>{
                                    if( err.tagid=='cin'){
                                        return <small style={{color:'red'}}>{err.errmsg}</small>
                                    }
                                })}
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={4}>
                            <Typography>TAN<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox 
                             sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                             placeholder ="ABCD12345X"
                             onchange={handleChangeState}
                             Default={state.tan}
                             name='tan'
                             />
                               {error.map((err)=>{
                                    if( err.tagid=='tan'){
                                        return <small style={{color:'red'}}>{err.errmsg}</small>
                                    }
                                })}
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={3}>
                            <Typography>PAN<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox 
                             sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                             placeholder ="AAAAA4567A"
                             onchange={handleChangeState}
                             Default={state.pan}
                             name='pan'
                             />
                              {error.map((err)=>{
                                    if( err.tagid=='pan'){
                                        return <small style={{color:'red'}}>{err.errmsg}</small>
                                    }
                                })}
                        </Grid>
                </Grid>
            </Box>
            <Typography sx={{mt:3,fontSize:18,fontWeight:600}}>Turnover</Typography>
            <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Grid container sx={{flex:3,justifyContent:'flex-start'}} spacing={2} >
                        <Grid item xs={12} md={6} sm={6} lg={6}>
                            <Typography>Amount in (cr)<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <InputBox 
                             sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                             placeholder ="L21091KA2019OPC141331"
                             onchange={handleChangeState}
                             Default={state.turnover}
                             name='turnover'
                             />
                               {error.map((err)=>{
                                    if( err.tagid=='turnover'){
                                        return <small style={{color:'red'}}>{err.errmsg}</small>
                                    }
                                })}
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6}>
                            <Typography>As on Date<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <DatePicker 
                                value={turnoverondate} 
                                onChange={chnageTurnoverDate}
                                style={{background:'#FFFFFF',fontSize:'14px',marginTop:'8px'}} >DD-MM-YY</DatePicker>
                        </Grid>
                </Grid>
            </Box>
            <Typography sx={{mt:3,fontSize:18,fontWeight:600}}>Summary</Typography>
            <Box style={{marginTop:'10px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                <Grid container sx={{flex:3}} spacing={2}>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                    <TextArea 
                    placeholder="Write note about company" 
                    Default={state.summary}
                    style={{width:'100%', height:60}} 
                    onchange={handleChangeState}
                    name = 'summary'
                    />
                    
                </Grid>
                </Grid>
            </Box>
            
        </DialogContent>
          <DialogActions sx={{mt:2,mb:2}}>
            <CustemBtn onClick={handleUpdate} variant="contained" sx={{borderRadius:"10px",pl:5,pr:5,fontSize:'16px',backgroundColor:'#7673F6'}} text="Save" />
          </DialogActions>
        </BootstrapDialog>
    </div>
  );
}