import React from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


const DisabledButton = () => {
  return (
    <Stack direction="row" m={10} spacing={2}>
     <Button  variant="contained" sx={{borderRadius:'10px',pl:'30px',pr:'30px',fontSize:'16px'}}>Create Opportunity</Button>
    </Stack>
  )
}

export default DisabledButton
