import { Card, CardContent, styled, Typography } from '@mui/material';
import { Box } from '@mui/material';
import {AnniversaryIcon} from '../custom';
import { useDispatch } from 'react-redux';
import { sendcompanywishthunk } from '../../Redux/Thunk/dashboardthunk';
import Swal from 'sweetalert';

const CardBody = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    gap:2
})

export default function AnniversaryWish({ name, id }) {
    const dispatch = useDispatch()
    const handleSendWish = () => {
     dispatch(sendcompanywishthunk({id:id})).then((res)=>{
        if(res?.payload.errormsg) {
        return sweetalert('error', res?.payload.errormsg)
        }
        sweetalert('success', res?.payload?.data.message)
     })
    }

    
const sweetalert = (variant, text) => {
    Swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };
    return (
        <>
            <Card sx={{
                width: "250px",
                background: "#FFFDFB",
                borderRadius: "12px",
                boxShadow: "0px 4px 20px #efefef",
            }}>
                <Box sx={{p:2}}>
                    <CardBody >
                    <Box sx={{
                          display:'flex',
                          justifyContent:'center',
                          alignItems:'center',
                          height:'3rem',
                          width:'3rem',
                          boxShadow: '0px 4px 20px #e4e4e4',
                          background: 'linear-gradient(135.71deg, rgba(255, 255, 255, 0.17) 4.26%, #FFD396 95.75%)',
                          borderRadius: '10px',
                           }}>
                           <AnniversaryIcon height={40} width={40} />
                      </Box>
                        <Box sx={{ flex: 1.8,  alignSelf: "center",textAlign:"center", fontWeight: "600" }}>
                            <Typography sx={{fontWeight: "600",fontSize:'10px'}}>Anni. Wishes</Typography>
                            <Box sx={{fontSize:'10px'}}>To</Box>
                            <Typography sx={{color: "#FE8731",fontWeight: "600",fontSize:'8px'}}>{name}</Typography>
                        </Box>
                        <Box sx={{ flex:1.2,alignSelf:"flex-end"}}>
                            <Box sx={{background:"lightgrey",padding:"2px",borderRadius:"5px",boxShadow: "0px 4px 20px #efefef"}}>
                            <Typography  sx={{color: "#FFA40B",cursor:'pointer',fontSize:"8px",fontWeight:"600", textAlign:'center'}}>Send Wishes</Typography></Box>
                        </Box>
                    </CardBody>
                </Box>
            </Card>
        </>
    )
}