import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid, Box, Toolbar, IconButton } from '@mui/material';
import { CustemBtn, DatePicker, InputBox, SelectDropDown, TextArea } from '../../components/custom';
import dayjs from 'dayjs';
import validate from "../../validation/validation";
import DisplayError from '../../validation/DisplayError';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { createTaskForLead } from '../../Redux/Thunk/createTaskthunk';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import {userList} from '../../Redux/Thunk/createuserthunk'
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import { getalertlist } from '../../Redux/Thunk/alertthunk';
import AdminRole from '../../adminrole/Adminrole';
import DialogHeaderCompanyName from '../../components/custom/DialogHeaderCompanyName';
import {T1ButtonText, T6TableText, H5FormLabel, H2POPupMainText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';

const Types = [
    { value: 'Call', label: "Call" }, { value: 'Demo', label: "Demo" }, { value: 'Email', label: "Email" }, { value: 'Meeting', label: "Meeting" }, { value: 'Task', label: "Task" }
]

const tasktypes = [
    { value: "Customer Followup", label: "Customer Followup" }, { value: "Customization", label: "Customization" }, { value: "Demo/Training", label: "Demo/Training" },
    { value: "Marketing Followup", label: "Marketing Followup" }, { value: "Meeting/Discussion", label: "Meeting/Discussion" },
    { value: "Other", label: "Other" }, { value: "Server Support", label: "Server Support" }, { value: "Support", label: "Support" }, { value: "Testing", label: "Testing" }
]

const task_status = [{ value: "Assigned", label: "Assigned" }, { value: "Completed", label: "Completed" }, { value: "Customer Side Pending", label: "Customer Side Pending" },
{ value: "Inprocess", label: "Inprocess" }, { value: "Pending Input", label: "Pending Input" }, { value: "Roll Over", label: "Roll Over" }]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 50,
        },
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, title, taskedit, storeCompanyDetails,...other } = props;
  
    return (
      <>
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
          {children}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                display:'flex', justifyContent:'center', 
                right: 10,
              }}
            >
              {/* <CloseIcon w={35} h={35}/> */}
             {Object.keys(taskedit).length > 0 ? '': <CloseIcon w={35} h={35}/>}
            </IconButton>
          ) : null}
        </DialogTitle> 
      </>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const CreateTaskForm = ({title, user_ids, openTaskPopup, selectedOpportunityDetails, setOpenTaskPopup,update, companyName, from, setUpdate, For, taskedit, setTaskEdit, selectedLeadDetails, task_for }) => {
    // const [open, setOpen] = React.useState(false);
   
    const navigate = useNavigate()
    const [error, setError] = useState([])
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const {getuserlist} = useSelector((state) => state.user)
    const [userLists, setUserLists] = useState([])
    const [status, setStatus]= useState('')
    const [loader, setLoader] = useState(false)
    const [createTask, setCreateTask] = useState({
        task_type: '',
        subject: '',
        assigned_to: '',
        // status: 'Assigned',
        missing_deadline:'',
        closing_remark:''
    })
    const [startDate, setStartDate] = useState(Object.keys(taskedit)?.length > 0 ? dayjs(new Date(taskedit?.start_date)) : dayjs(new Date()).$d)
    const [endDate, setEndDate] = useState(Object.keys(taskedit)?.length > 0 ? dayjs(new Date(taskedit?.end_date)) : dayjs(new Date()).endOf('day').$d)
    const [followupForLead, getFollowUpForLead] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    let currentDate = taskedit ? new Date() : ''
    let task_end_date = taskedit ? new Date(taskedit.end_date) : ''

    useEffect(()=>{
      if(Object.keys(taskedit)?.length > 0){
        setCreateTask({
            task_type: taskedit ? taskedit?.task_type : '',
            subject: taskedit ? taskedit?.subject : '',
            assigned_to: taskedit ? taskedit?.assigned_to?.id : '',
            // status: taskedit ? taskedit?.status : '',
            missing_deadline: taskedit ? taskedit?.missing_deadline : '',
            closing_remark: taskedit ? taskedit?.closing_remark : ''
        })
        setStatus(taskedit ? taskedit?.status : '')
      }
    },[])

    useEffect(() => {
        setLoader(true)
        dispatch(userList({For:'task'})).then((res) =>{
            setUserLists(res?.payload?.data)
            setLoader(false)
        })
    },[taskedit.id])

    const findIds= (id1, id2) => {
        if (user_ids?.includes(id1) || user_ids?.includes(id2)) {
          return true 
        } else {
          return false
        }
    }

    let validation_input = [
        // {tagid:'value', text:createTask?.min, regex_name: 'free_text', required: true, errmsg: 'Please Select minute.'},
        // { tagid: 'follow_up_id', text: createTask?.follow_up_id, regex_name: 'text', required: true, errmsg: 'Please Select Follow-up ID.' },
        { tagid: 'task_type', text: createTask?.task_type, regex_name: 'text', required: true, errmsg: 'Please Select Type.', other_chars: ['/'] },
        { tagid: 'subject', text: createTask?.subject, regex_name: 'free_text', required: true, min:100, max:2000, errmsg: 'Add requirement description between 100 to 2000 characters.' },
        // { tagid: 'start_date', text: createTask?.type, required: true, regex_name: 'number', errmsg: 'Please Select Start Date.' },
        // { tagid: 'end_date', text: createTask?.type, required: true, regex_name: 'number', errmsg: 'Please Select End Date.' },
        { tagid: 'assigned_to', text: createTask?.assigned_to, required: true, regex_name: 'text', errmsg: 'Please Select User.', allow_numbers: true },
        For === 'edit' ? { tagid: 'status', text: status, required: true, regex_name: 'text', errmsg: 'Please Select Status.' } :<></>,
        For === 'edit' ? currentDate > task_end_date ? { tagid: 'missing_deadline', text: createTask?.missing_deadline, required: true, regex_name: 'free_text', errmsg: 'Please add the missing deadline remark between 100 to 2000 characters', min:100, max:2000} : '' : '',
        For === 'edit' ? { tagid: 'closing_remark', text: createTask?.closing_remark,  required: true, regex_name: 'free_text', errmsg: 'Please add the closing remark between 100 to 2000 characters', min:100, max:2000} : ''
    ]
   
    const handleClose = () => {
        setOpenTaskPopup(false);
        setCreateTask({
            task_type: '',
            subject: '',
            assigned_to: '',
            // status: '',
            missing_deadline: '',
            closing_remark: ''
        })
        setStatus('')
        setError([])
    };

    const handleOfCreateTaskDetailsForStatus =(event) =>{
        setStatus(event.target.value)
    }

    const handleOfCreateTaskDetails = (event) => {
        setCreateTask({ ...createTask, [event.target.name]: event.target.value })
    }

    const SubmitTask = () => {
        console.log(taskedit, createTask, "taskedit")
        console.log(error,"errorrrrrrrrrrrr")
        if (validate(validation_input).length == 0 || For === 'update') {
            createTask.start_date = startDate
            createTask.end_date = endDate
            if (For === 'edit' || For === 'update') {
                createTask.operationmode = 'edit'
                createTask.task_for = taskedit?.task_for
                createTask.taskid = taskedit?.id
                createTask.status= status
            } else {
                createTask.operationmode = 'create'
                createTask.task_for_id = selectedOpportunityDetails?.id ? selectedOpportunityDetails?.id : selectedLeadDetails?.id
                createTask.task_for = selectedOpportunityDetails?.id ? 'Opportunity':'Lead'   
                createTask.status= "Assigned"
            }
            console.log(createTask, "createTaskcreateTask")
            setDisableButton(true)
            dispatch(createTaskForLead(createTask)).then((res) => {
                if (res.payload.errormsg) {
                    sweetalert('error', res?.payload?.errormsg)
                    setDisableButton(false)
                } else {
                    // if (For === 'update') {
                    //     sweetalert('success', "Task Assigned Successfully")
                    //     setUpdate(!update)
                    // }
                     setDisableButton(false)
                    if((title?.title === "Task List" && title.operation==="createTask" )|| (title?.title ==="companyProfilePage" && title?.operation==="createTask") ||  (title==="companyProfilePage" && For==="edit")|| title ==="CreateFromDashboard" || title ==="CreateFromDashboard"){
                        swal({
                        buttons: {
                            Cancel: {
                                text: "Dashboard",
                                value: "Cancel",
                                className: "popup-blue",
                            },
                            Delete: {
                                text: "Task List",
                                value: "taskList",
                                className: 'popup-gray',
                            },
                        },  
                        title: (createTask?.operationmode === 'create') ? `Task (${res?.payload?.data?.taskid}) Successfully Created.` : `Task Successfully Closed.`,
                        className: "custom-modal",
                        closeOnClickOutside: false,
                        
                    }).then((value) => {
                        if (value == 'taskList') {
                            if(title?.title === "Task List" || title ==="CreateFromDashboard" ){
                                navigate('/dashboard/tasklist')  
                            }else{
                                navigate('/dashboard/profile/companyTask',{state:title?.title ==="companyProfilePage" ? title?.companyName: taskedit?.lead?.company})
                                setUpdate(!update)
                            }    
                        } else {
                            navigate('/dashboard')
                            setDisableButton(false)
                        }
                    })

                     }else if(title?.title ==="Alert List"|| title==="Alert List"){
                        sweetalert('success', res?.payload?.data?.msg)
                        // handleClose()
                        dispatch(getalertlist({start:0, length:6, name:"task"}))
                        navigate('/dashboard/alertlist')
                        // setUpdate(!update)
                     }else if(title === "Lead List" || title ==="LeadDetailsPage" ||  title ==='companyProfilePageLeadDetails'|| title ==="DashboardLeadDetails"|| title==='companyProfilePageOpportunityDetails'){
                        setOpenTaskPopup(false)
                        sweetalert('success', `Task (${res?.payload?.data?.taskid}) Successfully Created.`)
                     }else{
                        if (For === 'update') {
                            sweetalert('success', "Task Assigned Successfully")
                            setUpdate(!update)
                        }else if(For === 'edit'){
                            sweetalert('success', res?.payload?.data?.msg)
                            setUpdate(!update)
                     }else{
                        sweetalert('success', `Task (${res?.payload?.data?.taskid}) Successfully Created.`)
                        // setUpdate(!update)
                     }}
                }
                handleClose()
            })

        } else {
            setError(validate(validation_input))
        }
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };

    const changeStartDate = (newValue) => {
        setStartDate(newValue?.$d);
        setEndDate(newValue?.$d);
    }

    const changeEndDate = (newValue) => {
        setEndDate(newValue?.$d);
    }

    switch (For) {
        case 'update':
            return <>
                <Dialog
                    open={openTaskPopup}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogContent>

                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={{ lg: 2, md: 2, sm: 2, xs: 2 }}>
                            <Grid item xs={12} md={4} sm={4} lg={6}>
                                <Typography fontWeight="bold">Assigned To<span style={{ color: '#FF0000' }}>*</span></Typography>
                                <SelectDropDown
                                    id='assigned_to'
                                    disabled={createTask.closing_remark !== ''}
                                    name='assigned_to'
                                    placeholder='Select Field'
                                    options={userLists.map((user) => {
                                        return {
                                            value: user.id,
                                            label: `${user.firstname} ${user.lastname}`
                                        }
                                    })}
                                    onchange={handleOfCreateTaskDetails}
                                    //  label='Select industry'
                                    value={createTask?.assigned_to}
                                    sx={{ p: 0.1, mt: 1, backgroundColor: `${createTask.closing_remark !== '' ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='assigned_to' />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <Box sx={{ mt: 4, mr: { xs: 1, sm: 1, xl: 6, lg: 2 }, mb: 2, display: 'flex', justifyContent: 'end' }}>
                        <CustemBtn disabled={taskedit?.closing_remark === undefined ? false : taskedit?.closing_remark !== ''} variant="contained" onClick={SubmitTask} sx={{ borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px' }} text='Assign' />
                    </Box>
                </Dialog>
            </>
    }

    return (
        <div>
           <BootstrapDialog
                open={openTaskPopup}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
            >
            {/* ===================== Display Company name =============== */}
            
            {Object.keys(taskedit)?.length === 0 ?'' :<DialogHeaderCompanyName label={Object.keys(taskedit)?.length > 0 ? (taskedit?.lead?.company ? `${taskedit?.lead?.company?.company_name} ${taskedit?.lead?.company?.company_type}` : `${taskedit?.oppo_id?.company?.company_name} ${taskedit?.oppo_id?.company?.company_type}`) || (`${taskedit?.lead?.company?.company_name} ${taskedit?.lead?.company?.company_type}`) : (title==="Lead List"|| title ==="LeadDetailsPage" || title ==='companyProfilePageLeadDetails' ||title ==="DashboardLeadDetails" ||title==="companyProfilePageOpportunityDetails" ) ? `${companyName?.company_name} ${companyName?.company_type}` :''} onClose={handleClose} title={title} /> }
            <BootstrapDialogTitle sx={{display:'flex', p:Object.keys(taskedit)?.length === 0 ? 1: 0, pl:1.5, mt:Object.keys(taskedit)?.length === 0 ? 1: 0, justifyContent:'start',  alignItems:'end',borderRadius:'10px', fontWeight:600, color:'#000000', letterSpacing: "0.04em"}} id="customized-dialog-title" onClose={handleClose} title={title} taskedit={taskedit}>{Object.keys(taskedit)?.length > 0 && taskedit?.closing_remark !== '' ? 'Closed Task' :  Object.keys(taskedit)?.length === 0 ? 'Create New Task' : 'Open Task'}</BootstrapDialogTitle>
           
            <DialogContent dividers>
            {/* ============== Create task ==========================   */}
            {Object.keys(taskedit)?.length === 0 ? <>
                    <Grid container sx={{display: 'flex', justifyContent: 'space-between' }} spacing={{ lg: 2, md: 2, sm: 2, xs: 2 }}>
                        <Grid item xs={12} md={4} sm={4} lg={4}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel, mb:0.5}}>Type<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <SelectDropDown
                                name='task_type'
                                placeholder ='Select Type'
                                options={tasktypes}
                                onchange={handleOfCreateTaskDetails}
                                // disabled={Object.keys(taskedit)?.length >0} 
                                value={createTask?.task_type}
                                sx={{ p: 0.1, backgroundColor:'#ffffff', borderRadius: "10px" }}
                                MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='task_type' />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} lg={4}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel, mb:0.5}}>Start Date<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <DatePickerOpenTo
                                name='startDate'
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={startDate}
                                // disabled='true'
                                onChange={changeStartDate}
                                minDate={new Date()}
                                style={{ backgroundColor: '#ffffff', fontSize: '14px', lineHeight: '17px', width: '100%', p: 0.1, mt: 1, pl: 1 }} 
                                // disabled={Object.keys(taskedit)?.length > 0}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} lg={4}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel, mb:0.5}}>End Date<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <DatePickerOpenTo
                                openTo="day"
                                views={['year', 'month', 'day']}
                                name='endDate'
                                value={endDate}
                                onChange={changeEndDate}
                                // disabled={Object.keys(taskedit)?.length > 0}
                                minDate={startDate}
                                style={{backgroundColor: `${Object.keys(taskedit)?.length > 0 ?'#E9E9FF' : '#ffffff'}`, fontSize: '14px', lineHeight: '17px', width: '100%', p: 0.1, mt: 1, pl: 1 }}
                            />
                        </Grid>

                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft:'15px', paddingRight:'15px'}} spacing={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
                            <Grid item xs={12} md={4} sm={4} lg={12}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel, mt:2}}>Requirement<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                    <TextArea disabled={Object.keys(taskedit)?.length > 0} value={createTask?.subject?.replace(/\s\s+/g, " ")} onChange={handleOfCreateTaskDetails} name='subject' style={{ width: '100%', backgroundColor: `${Object.keys(taskedit)?.length > 0 ? '#E9E9FF' : '#ffffff'}`, fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding:'5px'}} placeholder="Write a note about Requirement."></TextArea>
                                </Grid>
                                <DisplayError error={error} fortagid='subject' /> 
                            </Grid>
                            {Object.keys(taskedit)?.length === 0 ? <div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                <span> {createTask?.subject?.length}/2000</span>
                            </div> :<></>}
                        </Grid>
                           
                        <Grid item xs={12} md={4} sm={4} lg={6}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Assign To<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <SelectDropDown
                                disabled={Object.keys(taskedit)?.length > 0}
                                name='assigned_to'
                                placeholder={userLists.length > 0 ? 'Select User' : 'Loading...'}
                                options={ userLists ? userLists.map((user) => ({
                                            value: user.id,
                                            label: `${user.firstname} ${user.lastname}`,
                                        })) : [] 
                                }
                                onchange={handleOfCreateTaskDetails}
                                value={userLists?.length > 0 ? createTask?.assigned_to : ''}
                                sx={{p: 0.1, mt: 0.5, backgroundColor: `${Object.keys(taskedit)?.length > 0 ? '#E9E9FF' : '#ffffff'}`, borderRadius: '10px'}}
                                MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='assigned_to' />
                        </Grid>

                        {/* {(Object.keys(taskedit)?.length > 0 )? <>
                            <Grid item xs={12} md={4} sm={4} lg={6}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Stage<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                <SelectDropDown
                                    name='status'
                                    disabled={taskedit?.closing_remark !== '' || !(user?.data?.user?.id == taskedit?.created_by?.id || user?.data?.user?.id == taskedit?.assigned_to?.id)}
                                    placeholder='Select Field'
                                    options={task_status}
                                    onchange={handleOfCreateTaskDetailsForStatus}
                                    value={status}
                                    sx={{ p: 0.1, mt: 0.5, backgroundColor:`${(taskedit?.closing_remark !== '' || !(user?.data?.user?.id == taskedit?.created_by?.id || user?.data?.user?.id == taskedit?.assigned_to?.id)) ? '#E9E9FF' : '#ffffff'}`, borderRadius: "10px" }}
                                    MenuProps={MenuProps}
                                />
                                <DisplayError error={error} fortagid='status' />
                            </Grid></>:<></>}  */}

                        {/* {
                            (For !== 'create' && ((((user?.data?.user?.id === taskedit?.assigned_to?.id) && permissionsDetails?.perm_task_create)) || (checkAdminRole && permissionsDetails?.perm_task_create))) ? (currentDate > task_end_date && (taskedit?.closing_remark === '') || taskedit?.missing_deadline !== '') ? 
                           <> <Grid item xs={12} md={4} sm={4} lg={12}> 
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Missing Deadline Remark<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                    <TextArea disabled={taskedit?.closing_remark !== ''} value={createTask.missing_deadline?.replace(/\s\s+/g, " ")} onChange={handleOfCreateTaskDetails} name='missing_deadline' style={{ width: '100%', backgroundColor: `${taskedit?.closing_remark !== '' ? '#E9E9FF' : '#ffffff'}`, fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }} placeholder="Write a note about Missing Deadline."></TextArea>
                                </Grid>
                                <DisplayError error={error} fortagid='missing_deadline' />
                            </Grid>
                            {taskedit?.closing_remark =="" ?<div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                <span>{createTask.missing_deadline?.length}/2000</span>
                            </div> :<></>} </>: '' : ''
                        }
                        {
                            For === 'edit' && taskedit?.closing_remark === "" && (((user?.data?.user?.id === taskedit?.assigned_to?.id) && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create)) ? 
                             <><Grid item xs={12} md={4} sm={4} lg={12}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Closing Remark<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                    <TextArea value={createTask?.closing_remark?.replace(/\s\s+/g, " ")} onChange={handleOfCreateTaskDetails} name='closing_remark' style={{ width: '100%', backgroundColor: `${taskedit?.closing_remark !== '' ? '#E9E9FF' : '#ffffff'}`, fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }}placeholder="Write a note about Closing Remark."></TextArea>
                                </Grid>
                                <DisplayError error={error} fortagid='closing_remark' />
                            </Grid> 
                             {taskedit?.closing_remark == "" ?<div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                             <span>{createTask.closing_remark?.length}/2000</span>
                            </div> :<></>} </>: For === 'edit' && taskedit?.closing_remark !== "" ?<Grid item xs={12} md={4} sm={4} lg={12}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Closing Remark<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                    <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                        <TextArea disabled={taskedit?.closing_remark !== ''} value={createTask?.closing_remark?.replace(/\s\s+/g, " ")} onChange={handleOfCreateTaskDetails} name='closing_remark' style={{ width: '100%', backgroundColor: `${taskedit?.closing_remark !== '' ? '#E9E9FF' : '#ffffff'}`, fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }}placeholder="Write a note about Closing Remark."></TextArea>
                                    </Grid>
                                    <DisplayError error={error} fortagid='closing_remark' />
                                </Grid>:<></>
                        } */}
                        
                    </Grid>
                    </> :<>
                    {/* =============== Edit task =============================== */}
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', gap:"4px" }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}>
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <H5FormLabel sx={{ color: themeColor.palette.InputLabel }}>
                                Task ID
                            </H5FormLabel>
                            {
                                taskedit?.task_no ?  <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                {taskedit?.task_no}
                            </T5Normaltext> : ''
                            }
                        </Grid>
                        <Grid  item xs={12} md={4} sm={4} lg={4}>
                            <H5FormLabel sx={{ color: themeColor.palette.InputLabel }}>
                                Type
                            </H5FormLabel>
                            {
                                taskedit?.task_type ? <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                {taskedit?.task_type}
                            </T5Normaltext> : ''
                            }
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} lg={4}>
                            <H5FormLabel sx={{ color: themeColor.palette.InputLabel }}>
                                Start Date
                            </H5FormLabel>
                            {
                                taskedit?.start_date ? <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                {moment(taskedit?.start_date).format("DD MMM YYYY")}
                            </T5Normaltext> : ''
                            }
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} lg={3}>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel }}>
                                End Date
                            </H5FormLabel>
                            {
                                taskedit?.end_date ? <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                               {moment(taskedit?.end_date).format('DD MMM YYYY')}
                            </T5Normaltext> : ''
                            }
                        </Grid>

                        <Grid item xs={12} md={5} sm={5} lg={5}>
                            {taskedit?.closed_on != 0 ? <>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel }}>
                                    Closed On
                                </H5FormLabel>
                                {
                                    taskedit?.closed_on != 0  ? <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                                    { taskedit?.closed_on != 0 ? moment(Number(taskedit?.closed_on)).format('DD MMM YYYY') : '-'}
                                    </T5Normaltext> : ''
                                } </> :''}
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} lg={3}></Grid>
                       
                        {/* <Grid container sx={{ display: 'flex',flexWrap:'wrap', justifyContent: 'space-between', mb:0.5 }} spacing={{ lg: 1, md: 2, sm: 2, xs: 2 }}> */}
                            <Grid item xs={12} md={12} sm={12} lg={12}>
                                <H5FormLabel sx={{ color: themeColor.palette.InputLabel, mt: 1 }}>
                                    Requirement
                                </H5FormLabel>
                                <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                                    {taskedit?.subject}
                                </T5Normaltext>
                            </Grid>
                        {/* </Grid> */}

                        <Grid item xs={12} md={4} sm={4} lg={5}>
                            <H5FormLabel sx={{ color: themeColor.palette.InputLabel}}>
                                Assign To
                            </H5FormLabel>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <T5Normaltext sx={{
                                    color: themeColor.palette.primarytext2,
                                    whiteSpace: 'pre-line', // Allow line breaks
                                    wordBreak: 'break-word', // Break long words
                                    maxWidth: 'auto',}}>
                                    {`${taskedit?.assigned_to?.firstname} ${taskedit?.assigned_to?.lastname}`}
                                </T5Normaltext>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={4} sm={4} lg={6}>
                            {((((user?.data?.user?.id === taskedit?.assigned_to?.id) && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create)) && taskedit?.closing_remark === '') ?
                            <>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Stage<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                    <SelectDropDown
                                        name='status'
                                        // disabled={taskedit?.closing_remark !== ''}
                                        placeholder='Select Field'
                                        options={task_status}
                                        onchange={handleOfCreateTaskDetailsForStatus}
                                        value={status}
                                        sx={{ p: 0.1, mt: 0.5, backgroundColor:'#ffffff', borderRadius: "10px" }}
                                        MenuProps={MenuProps}
                                    />
                                    <DisplayError error={error} fortagid='status' />
                                </> :<>
                                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Stage</H5FormLabel>
                                        <T5Normaltext sx={{ color: themeColor.palette.primarytext2}}>
                                            {status ? status : '__'}
                                        </T5Normaltext>
                            </>}

                           {/* {(user?.data?.user?.id !== taskedit?.created_by?.id || user?.data?.user?.id !== taskedit?.assigned_to?.id || taskedit?.closing_remark !== '') ? <> 
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Stage</H5FormLabel>
                                <T5Normaltext sx={{ color: themeColor.palette.primarytext2}}>
                                    {status ? status : '__'}
                                </T5Normaltext>
                           </> :<>
                            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Stage<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                            <SelectDropDown
                                name='status'
                                // disabled={taskedit?.closing_remark !== ''}
                                placeholder='Select Field'
                                options={task_status}
                                onchange={handleOfCreateTaskDetailsForStatus}
                                value={status}
                                sx={{ p: 0.1, mt: 0.5, backgroundColor:'#ffffff', borderRadius: "10px" }}
                                MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='status' />
                            </>} */}
                        </Grid>

                        {/* ======= Missing Deadline remark ================= 
                        && ((currentDate > task_end_date ) && ((taskedit?.closing_remark === '') || taskedit.missing_deadline === ''))
                        */}
                        {
                            (For === 'edit' && ((((user?.data?.user?.id === taskedit?.assigned_to?.id) && permissionsDetails?.perm_task_create)) || (checkAdminRole && permissionsDetails?.perm_task_create))) ? (currentDate > task_end_date && taskedit?.closing_remark === '' && taskedit?.missing_deadline == '') ? 
                            <> <Grid item xs={12} md={4} sm={4} lg={12}> 
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Missing Deadline Remark<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                    <TextArea value={createTask.missing_deadline?.replace(/\s\s+/g, " ")} onChange={handleOfCreateTaskDetails} name='missing_deadline' style={{ width: '100%', backgroundColor: '#ffffff', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }} placeholder="Write a note about Missing Deadline."></TextArea>
                                </Grid>
                                <DisplayError error={error} fortagid='missing_deadline' />
                            </Grid>
                            {taskedit?.closing_remark =="" ?<div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                <span>{createTask.missing_deadline?.length}/2000</span>
                            </div> :<></>} 
                            </> : ((For === 'edit' && currentDate > task_end_date && (taskedit?.closing_remark !== '' && taskedit?.missing_deadline !== ''))) ? <>
                                <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Missing Deadline Remark</H5FormLabel>
                                    <T5Normaltext sx={{ color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                                        {taskedit.missing_deadline}
                                    </T5Normaltext>
                                </Grid>
                             </> :<></>:<></>

                        //     (For !== 'create' && ((((user?.data?.user?.id === taskedit?.assigned_to?.id) && permissionsDetails?.perm_task_create)) || (checkAdminRole && permissionsDetails?.perm_task_create)))  ? (currentDate > task_end_date) && (taskedit?.closing_remark === '') ? <></>:
                        //    <> <Grid item xs={12} md={4} sm={4} lg={12}> 
                        //         <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Missing Deadline Remark<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                        //         <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                        //             <TextArea value={createTask.missing_deadline?.replace(/\s\s+/g, " ")} onChange={handleOfCreateTaskDetails} name='missing_deadline' style={{ width: '100%', backgroundColor: '#ffffff', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }} placeholder="Write a note about Missing Deadline."></TextArea>
                        //         </Grid>
                        //         <DisplayError error={error} fortagid='missing_deadline' />
                        //     </Grid>
                        //     {taskedit?.closing_remark =="" ? <div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                        //         <span>{createTask.missing_deadline?.length}/2000</span>
                        //     </div> :<></>} 
                        //     </>: <>
                        //             <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                        //             <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Missing Deadline Remark jjjjj</H5FormLabel>
                        //                 <T5Normaltext sx={{ color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                        //                     {createTask.missing_deadline}
                        //                 </T5Normaltext>
                        //             </Grid>
                        //     </>
                        }

                        {/* ============= Closing remark ========================== */}
                        {
                            For === 'edit' && taskedit?.closing_remark === "" && (((user?.data?.user?.id === taskedit?.assigned_to?.id) && permissionsDetails?.perm_task_create) || (checkAdminRole && permissionsDetails?.perm_task_create)) ? 
                             <><Grid item xs={12} md={4} sm={4} lg={12}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Closing Remark<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                                {/* <Typography fontWeight="bold">Closing Remark<span style={{ color: '#FF0000'}}>*</span></Typography> */}
                                <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                    <TextArea value={createTask?.closing_remark?.replace(/\s\s+/g, " ")} onChange={handleOfCreateTaskDetails} name='closing_remark' style={{ width: '100%', backgroundColor: `${taskedit?.closing_remark !== '' ? '#E9E9FF' : '#ffffff'}`, fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding: '4px' }}placeholder="Write a note about Closing Remark."></TextArea>
                                </Grid>
                                <DisplayError error={error} fortagid='closing_remark' />
                            </Grid> 
                             {taskedit?.closing_remark == "" ?<div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                             <span>{createTask.closing_remark?.length}/2000</span>
                            </div> :<></>} </>: For === 'edit' && taskedit?.closing_remark !== "" ?<Grid item xs={12} md={4} sm={4} lg={12}>
                                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Closing Remark</H5FormLabel>
                                    <Grid mt={0.5} item xs={12} md={10} sm={10} lg={12}>
                                        <T5Normaltext sx={{ color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all'}}>
                                            {taskedit?.closing_remark}
                                        </T5Normaltext>
                                    </Grid>
                                </Grid>:<></>
                        }
                    </Grid>
                    </>}
                    
            </DialogContent>

            {/* ============= Create and closed task button ================== */}
            <DialogActions sx={{ mt:1.5, mb:1.5}}>
                {(Object.keys(taskedit).length === 0 && ((checkAdminRole && permissionsDetails?.perm_task_create) || (permissionsDetails?.perm_task_create)))|| ((Object.keys(taskedit).length > 0 && taskedit?.closing_remark === '') && ((checkAdminRole && permissionsDetails?.perm_task_create) || (permissionsDetails?.perm_task_create && (user?.data?.user?.id == taskedit?.assigned_to?.id)))) ? 
                <Box sx={{mr: { xs: 1, sm: 1, xl: 6, lg: 2 }, display: 'flex', justifyContent: 'end' }}>
                    <CustemBtn disabled={disableButton} variant="contained" onClick={SubmitTask} sx={{ borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px' }} text={For === 'edit' ? 'Close' : "Create Task"} />   
                </Box> :<></>}
                 {/* { (taskedit?.closing_remark !== '' || (taskedit?.closing_remark === '' && (checkAdminRole && permissionsDetails?.perm_task_create) || (permissionsDetails?.perm_task_create && !findIds(taskedit?.created_by?.id, taskedit?.assigned_to?.id)))) ? '' :(checkAdminRole && permissionsDetails?.perm_task_create) || (permissionsDetails?.perm_task_create && findIds(taskedit?.created_by?.id, taskedit?.assigned_to?.id)) || (taskedit?.closing_remark === undefined ? false : taskedit?.closing_remark !== '')|| For === 'create'|| For === 'edit' ? <CustemBtn disabled={disableButton} variant="contained" onClick={SubmitTask} sx={{ borderRadius: "10px", pl: 5, pr: 5, fontSize: '16px' }} text={For === 'edit' ? 'Close' : "Create Task"} />:''
                   } */}
            </DialogActions>

            </BootstrapDialog>
        </div>
    );
}

export default CreateTaskForm



