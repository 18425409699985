import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";
import axios from 'axios'

export const auththunk = createAsyncThunk('data/login',
    async(data)=>{
      const res = await axiosInstance.post('/login',data);
        return res.data
    }

)

export const resetPassword = createAsyncThunk('data/resetPassword',
    async(data) => { 
        const res = await axiosInstance.post('/passwordresetreq', data);
          console.log(res.data)
          return res.data
    }
)

export const performResetPassword = createAsyncThunk('data/performResetPassword',
    async(data) => { 
        const res = await axiosInstance.post('/performresetpassword', data);
          console.log(res.data)
          return res.data
    }
)

export const updatePassword = createAsyncThunk('data/password',
    async(data) => { 
        const res = await axiosInstance.post('/updatepassword', data);
          return res.data
    }
)