import React,{useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import WhiteTaskIcon from './WhiteTaskIcon';
import WhiteContactIcon from './WhiteContactIcon';
import WhiteOppoIcon from './WhiteOppoIcon';
import WhiteLeadIcon from './WhiteLeadIcon';
import WhiteCompanyIcon from './WhiteCompanyIcon';
import WhiteUserIcon from './WhiteUserIcon';
import WhiteFollowupIcon from './WhiteFollowupIcon';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CreateAddressPopup from '../../pages/Addresspopup/CreateAddresspopup';
import { Box } from '@mui/material';
import WhiteAddressIcon from './WhiteAddressIcon';
import ContactForm from '../../pages/ContactForm/ContactForm';
import WhiteBugReportIcon from './WhiteBugReport';
import CreateAddressDropdown from '../../pages/Addresspopup/CreateAddressDropDown';
import CreateCompanyForm from '../../pages/Company/CreateCompanyForm';
import {emptyCompanyDetailsData, storeCompanyData, storeCompayName, storeAddressDetails } from '../../Redux/slices/createCompanySlice';
import themeColor from '../../Theme/themeColor';
import { storeContactDetails } from '../../Redux/slices/createContactSlice';
import { getIndustryTypesList } from '../../Redux/Thunk/createsettinglist';

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    right: 20,
    bottom:80,
    backgroundColor:'transparent'
  },
});

const dialogStyle = {
  position: 'fixed',
  bottom: 80,
  right: 20,
  backgroundColor:'transparent'
  // width: '100%',
  // maxWidth: '100%',
  // maxHeight: '70%',
  //borderRadius: '8px 8px 0 0',
};

function SimpleDialog(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {user} = useSelector(state => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const [openAddressModel, setOpenAddressModel] = useState(false)
  const [openContactModel, setOpenContactModel] = useState(false)
  const { onClose, selectedValue, open } = props;
  const [editCompanyDetails, setEditCompanyDetails] = React.useState({});
  const [editContactDetails, setEditContactDetails] = React.useState({});
  const [editAddressDetails, setEditAddressDetails] = useState({})
  const [openCompanyModel, setOpenCompanyModel] = useState(false)
  const classes = useStyles();
  const route = "CreateFromDashboard"

  const handleClose = () => {
    onClose(selectedValue);
  };

  //open Create address popup 
  const handleChangeCreateAddress=()=>{
    dispatch(storeCompanyData({}))
    setOpenAddressModel(true)
    onClose(selectedValue)
  }

  //create company model
  const handleChangeCreateCompany = () =>{
    // dispatch(getIndustryTypesList())
    setOpenCompanyModel(true)
    setOpenAddressModel(false)
    onClose(selectedValue)
  }

  const handleChangeCreateContact =()=>{
    setOpenContactModel(true)
    dispatch(storeCompayName({}))
    onClose(selectedValue)
  }
  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleChangeCreateLead =()=>{
    navigate('/dashboard/lead' ,{state:route})
    dispatch(storeAddressDetails({}))
    dispatch(storeContactDetails({}))
  }
 
  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{ style: dialogStyle }}
       // classes={{ paper: classes.paper }}
      >
        <List sx={{ pt:0, pb:0.5}}> 

        {/*   =========== Bug tracker link============   */}
        {/* {permissionsDetails?.perm_task_create ? <> */}
        <Box onClick={() => window.open('https://jaicrm-bug-tracker.web.app')}>
          {/* <Link style={{textDecoration:'none'}} to='https://jaicrm-bug-tracker.web.app'> */}
            <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px", height: "40px", mb: "4px"}} disableGutters>
              <ListItemButton>
                <ListItemAvatar>
                  <WhiteBugReportIcon/> 
                </ListItemAvatar>
                <ListItemText primary='Report Bug' sx={{color:'#ffffff'}} />
              </ListItemButton>
            </ListItem>
            </Box>
            {/* </Link>  */}
            {/* </> :""} */}

        {/* ----------- task ------------------ */} 
        {permissionsDetails?.perm_task_create ? <>
          <Link style={{textDecoration:'none'}} to='/dashboard/createtask' state={route}>
            <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px",height: "40px", mb: "4px"}} disableGutters>
              <ListItemButton>
                <ListItemAvatar>
                  <WhiteTaskIcon/> 
                </ListItemAvatar>
                <ListItemText primary='Task' sx={{color:'#ffffff'}} />
              </ListItemButton>
            </ListItem>
            </Link> 
            </> :""}
          
          {/* ----------- Follow up ------------------ */}
          {/* {permissionsDetails?.perm_followup_create ?<>
            <Link style={{textDecoration:'none'}} to='/dashboard/createfollowup' state={route}>
            <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px",height: "40px", mb: "4px"}} disableGutters>
              <ListItemButton>
                <ListItemAvatar>
                  <WhiteFollowupIcon/> 
                </ListItemAvatar>
                <ListItemText primary='Follow-up' sx={{color:'#ffffff'}} />
              </ListItemButton>
            </ListItem>
            </Link>
            </> :"" } */}
  
          {/* --------- Opportunity   -------------- */}
          {/* {permissionsDetails?.perm_opportunity_create ?<>
            <Link style={{textDecoration:'none'}} to='/dashboard/createopportunity' state={route}>
            <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px", height: "40px", mb: "4px"}} disableGutters>
              <ListItemButton
              >
                <ListItemAvatar>
                  <WhiteOppoIcon/> 
                </ListItemAvatar>
                <ListItemText primary='Opportunity' sx={{color:'#ffffff'}} />
              </ListItemButton>
            </ListItem>
            </Link> </> :"" } */}
            
            {/* --------- Lead  -------------- */}
          {permissionsDetails?.perm_lead_create ?<>
            <Box onClick={handleChangeCreateLead}>
            {/* <Link style={{textDecoration:'none'}} to='/dashboard/lead' state={route}> */}
            <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px", height: "40px", mb: "4px"}} disableGutters>
              <ListItemButton
              >
                <ListItemAvatar>
                  <WhiteLeadIcon/> 
                </ListItemAvatar>
                <ListItemText primary='Lead' sx={{color:'#ffffff'}} />
              </ListItemButton>
            </ListItem>
            {/* </Link> */}
            </Box>
             </> :"" }
            
            {/* --------- Contact -------------- */}
            {permissionsDetails?.perm_contact_create ? <>
              <Box onClick={handleChangeCreateContact}>
                {/* <Link style={{textDecoration:'none'}} to='/dashboard/createcontactdetails'> */}
                <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px", height: "40px", mb: "4px"}} disableGutters>
                  <ListItemButton>
                    <ListItemAvatar>
                        <WhiteContactIcon/> 
                    </ListItemAvatar>
                    <ListItemText primary='Contact' sx={{color:'#ffffff'}} />
                  </ListItemButton>
                </ListItem>
                {/* </Link>  */}
              </Box>
            </> :""}

            {/* --------- Address -------------- */}
          {permissionsDetails?.perm_address_create ? <>
          <Box onClick={handleChangeCreateAddress}>
            {/* <Link style={{textDecoration:'none'}} to='/dashboard/address' > */}
            <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px", height: "40px", mb: "4px"}} disableGutters>
              <ListItemButton>
                <ListItemAvatar>
                <WhiteAddressIcon />
                </ListItemAvatar>
                <ListItemText primary='Address' sx={{color:'#ffffff'}} />
              </ListItemButton>
            </ListItem>
            {/* </Link>  */}
            </Box>
            </> :""}

            {permissionsDetails?.perm_company_create ?<>
            {/* <Link style={{textDecoration:'none'}} to='/dashboard/company' > */}
            <Box onClick={handleChangeCreateCompany}>
            <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px", height: "40px", mb: "4.5px"}} disableGutters>
              <ListItemButton>
                <ListItemAvatar>
                  <WhiteCompanyIcon/> 
                </ListItemAvatar>
                <ListItemText primary='Company' sx={{color:'#ffffff'}} />
              </ListItemButton>
            </ListItem>
            </Box>
            {/* </Link> */}
             </> : " "}

          {/* --------- User -------------- */}
          {permissionsDetails?.perm_user_create ? <>
            <Link style={{textDecoration:'none'}} to='/dashboard/user' >
            <ListItem sx={{backgroundColor:themeColor.palette.BrandColor,p:0, borderRadius: "10px", height: "40px"}} disableGutters>
              <ListItemButton >
                <ListItemAvatar>
                  <WhiteUserIcon/> 
                </ListItemAvatar>
                <ListItemText primary='User' sx={{color:'#ffffff'}} />
              </ListItemButton>
            </ListItem>
            </Link> </> : ""}
        </List>
      </Dialog>

      {openAddressModel && <CreateAddressDropdown open={openAddressModel} setOpen={setOpenAddressModel} title={route} 
        setEditCompanyDetails={setEditAddressDetails} editCompanyDetails = {editAddressDetails}/>}

      {/* =================== open modal for contact details form ================= */}
      {openContactModel && <ContactForm open ={openContactModel} setOpenModal={setOpenContactModel}  
          title={route} editContactDetails={editContactDetails} setEditContactDetails= {setEditContactDetails} />}

      {/* ===========  create company ======  */} 
      {openCompanyModel && <CreateCompanyForm openAddressModel ={openAddressModel} setOpenAddressModel={setOpenAddressModel} openCompanyModel ={openCompanyModel} setOpenCompanyModel={setOpenCompanyModel}  
        setEditCompanyDetails={setEditCompanyDetails} editCompanyDetails={editCompanyDetails} title={route} />}
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({open,setOpen,handleClose}) {
 // const [open, setOpen] = React.useState(false);
//   const [selectedValue, setSelectedValue] = React.useState(emails[1]);


  return (
    <div>
      <Typography variant="subtitle1" component="div">
      
      </Typography>
      <br />

      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open simple dialog
      </Button> */}
      <SimpleDialog
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
