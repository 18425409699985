
import React, {useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import OpportunityCard from '../../components/custom/OpportunityCard'
import { Typography,Card,CardContent, Grid, Box, CircularProgress, Button} from '@mui/material';
import { CustemBtn, DeleteIcon, EditIcon, InputBox, Navbar, SettingMasterCard, FilterIcon, SelectDropDown} from '../../components/custom';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useDispatch, useSelector } from 'react-redux';
import { createProductFamilyTypes, getProductFamilyTypesList, getproductfamilyserviceproviderTypes } from '../../Redux/Thunk/createsettinglist';
import swal from 'sweetalert';
import {CardHeader, CardInfo} from './SettingMasterList.style'
import { TailSpin } from 'react-loader-spinner';

export default function ProductFamilyList ({permissionsDetails}){
    const dispatch = useDispatch();
    const {productFamily, getServiceProviderTypes} = useSelector(state => state.settingList)
    const [productFamilyType, setProductFamilyType]= useState([]) 
    const [openButton, setOpenButton] = useState(false)
    const [prevValue, setNewValue] = useState('')
    const [buttonStatus, setButtonStatus] = useState(false);
    const [indexValue, setIndexValue] = useState('') 
    const [inputFieldStatus, setInputFieldstatus] = useState(false)
    let initallySelectedValue = (getServiceProviderTypes??[])?.value?.map((name)=> ({value : name, label:name}))
    const [serviceProvider, setServiceProvider] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [buttonDisabledForSave, setButtonDisabledForSave] = useState(false)
    const [loader, setLoader] = useState(true)
    const [flag ,setFlag]= useState(false)
    const [values, setValues] = useState({
        value:'',
        index:''
    })

    useEffect(()=>{
        setLoader(true)
        dispatch(getProductFamilyTypesList({selected_serviceprovider:serviceProvider??''})).then((res)=>{
           setProductFamilyType(res?.payload?.value)  
            setButtonStatus(false)    
            setInputFieldstatus(false)
            setLoader(false)
        })
    },[productFamily, serviceProvider])
   
    const createProductFamilytype =(event) =>{
       setButtonStatus(true)
       setValues({value: event.target.value?.trimStart()})
    }
  
    const addProductFamilyType = () =>{  
            if(productFamilyType?.find(item =>item?.toUpperCase() === values?.value?.toUpperCase())){
                sweetAlert("error", "This field is already exit")
            }else if(values?.value === ''){
                sweetAlert("error", "Please Enter Product Family.")
            }else{
                setButtonDisabled(true)
                const value = [...productFamilyType, values?.value?.trim()]
                dispatch(createProductFamilyTypes({ setting_productfamily_list : value})).then((res) => {
                    if(res?.payload?.errormsg){
                        sweetAlert("error", res?.payload?.errormsg)
                        setButtonDisabled(false)
                    }else{
                        setButtonDisabled(false)
                        sweetAlert("success", `Product Family Added Successfully. Please Add Product for ${values?.value?.trim()}`)
                        setValues({ value:'', index:'' })
                        setButtonStatus(false)
                        setFlag(!flag)
                    }
                }) 
            } 
    }

    const sweetAlert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
        })
    };

    const editProductFamilyType = (index) =>{
        setOpenButton(true)
        setIndexValue(index)
        setNewValue(productFamilyType[index])
        setButtonStatus(false)
        setInputFieldstatus(true)
    }
  
    const handleChangeProductFamilyType =(event, index)=>{
        setValues({value:event.target.value?.trimStart(), index:index})
    }
  
    const handleOfServiceProvider=(event)=>{
        setServiceProvider(event.target.value)
        // dispatch(getproductfamilyserviceproviderTypes({selected_serviceprovider: event.target.value})).then((res)=>{
        //     console.log(res?.payload,"get dataaaaaaaa")
        // })
    }

    const saveServiceProvider = () =>{
      const data= productFamilyType?.slice();
      data.splice(values?.index, 1, values?.value);
    
      const isDuplicate = data?.some(
        (item, index) =>
          item?.toUpperCase() === values?.value?.toUpperCase() && index !== values?.index
      );
    
        if (isDuplicate) {
            sweetAlert("error", "This Product Family is already exit")
        }else if(values?.value === ''){
            sweetAlert("error", "Not valid.")
        }else{
            swal({
                buttons: {
                Cancel: {
                    text: "Cancel",
                    value: "Cancel",
                    className: "popup-blue",
                },
                Delete: {
                    text: "Confirm",
                    value: "Confirm",
                    className: "popup-gray",
                },
                },
                title: `Do you want to Save the Product Family option ${values.value} ?`,
                className: "custom-modal",
                closeOnClickOutside: false,
              }).then((value)=>{
                  if(value=="Confirm"){
                    setButtonDisabledForSave(true)
                    dispatch(createProductFamilyTypes({setting_productfamily_list :data, prev : prevValue, new : values.value})).then((res)=>{
                        if(res?.payload?.errormsg){
                            sweetAlert("error", res?.payload?.errormsg)
                            setButtonDisabledForSave(false)
                        }else{
                            sweetAlert("success", "Product Family Updated Successfully.")
                            setButtonDisabledForSave(false)
                            setOpenButton(false)
                            setButtonStatus(true)
                            setValues({ value:'', index:'' })
                            setFlag(!flag)
                        }})
                  }else{
                    setOpenButton(false)
                    setInputFieldstatus(false)
                    setButtonDisabledForSave(false)
                    setValues({ value:'', index:'' })
                  }
            })
        } 
    }
  
    const deleteProductFamilyType =(index, type) =>{
        swal({
            buttons: {
            Cancel: {
                text: "Cancel",
                value: "Cancel",
                className: "popup-blue",
            },
            Delete: {
                text: "Confirm",
                value: "Confirm",
                className: "popup-gray",
            },
            },
            title: `All the products listed under this ${type} will also be removed. Proceed with deletion ?`,
            className: "custom-modal",
            closeOnClickOutside: false,
          }).then((value)=>{
              if(value =="Confirm"){
                setLoader(true)
                const data= productFamilyType.slice();
                data.splice(index,1);
                dispatch(createProductFamilyTypes({setting_productfamily_list :data})).then((res)=>{
                    if(res?.payload?.errormsg){
                        sweetAlert("error", res?.payload?.errormsg)
                        setLoader(false)
                    }else{
                        sweetAlert("success", "Product Family Deleted Successfully.")
                        setFlag(!flag)
                        // setLoader(false)
                        setValues({ value:'', index:'' })
                    }
                })
              }
          })
    }

      
    return(
        <Card sx={{ maxWidth: '100%',borderRadius:'10px',boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
           
                <CardHeader>
                    <Typography sx={{color:'#444444',fontSize:'18px',fontWeight:600}}>
                        Product Family
                    </Typography>
                </CardHeader>
                {/* <Box style={{ minWidth: 120, marginTop:"10px"}}>
                    <Typography>
                        Select Service Provider
                    </Typography>
                    <SelectDropDown 
                        name='contact_id'
                        options={getServiceProviderTypes?.value?.map((name)=>({value : name, label:name}))} 
                        onchange={handleOfServiceProvider}
                        // defaultlabel='--Select Name--'
                        // Default={productFamily}
                        placeholder='Select Service Provider'
                        value = {serviceProvider}
                        // label ='Select Service Provider'
                        sx={{p:0.1, mt:1, pl:1,backgroundColor:'#ffffff',borderRadius:"10px"}}
                    />
                    <small style={{backgroundColor:'#f7ca25'}}>Select a service provider and add/edit product family</small>
                </Box> */}
                <CardContent style={{ display: "flex",
                    flexDirection: "column",
                    height: 300,
                    overflow: "hidden",
                    overflowY: "scroll"}}>
                {permissionsDetails?.perm_settings_create ? <><CardInfo>
                    <Typography>
                        Add Product Family
                    </Typography> 
                    <Typography sx={{pr:2}}>
                        Action
                    </Typography> 
                </CardInfo></>:<></>}
                {permissionsDetails?.perm_settings_create ?<><CardInfo>
                    <InputBox sx={{width:'78%', border:"2px solid #B0AEFF", borderRadius:'10px', pl:1, backgroundColor:!inputFieldStatus?'#FFFFF' :'#C1C1C1'}} name='industry' disable={inputFieldStatus} value={values.value} onchange ={createProductFamilytype} placeholder='Product Family'/>
                    <Button
                      id="validate" variant="contained" disabled={!buttonStatus} onClick={() => buttonDisabled ? '' : addProductFamilyType()}
                      sx={{borderRadius:"10px", pl:2, pr:2, fontSize:'16px'}}
                      >
                      {buttonDisabled ? <CircularProgress size={20} color="inherit" /> : 'ADD'}
                    </Button>
                    {/* <Typography>
                     <CustemBtn variant="contained" sx={{backgroundColor:'#84c75a'}} text="ADD" disabled={!buttonStatus} onClick={addProductFamilyType}/>
                    </Typography> */}
                </CardInfo></>:<></>}
                <Box sx={{ flexGrow: 1 }}>
                    { loader ? <Box sx={{height:'300px', alignItems:'center', display:'flex', mt:0.5, justifyContent:"center", borderRadius:'10px'}}>
                        <TailSpin
                          height="30"
                          width="30"
                          color="blue"
                          ariaLabel="tail-spin-loading"
                          radius="3"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        /> </Box>:<>
                {productFamilyType?.map((types, index) =>( <>
                    <CardInfo sx={{mt:2}} key={index}>
                        {openButton && indexValue===index ? <><InputBox sx={{width:'78%', border:"1px solid #B0AEFF", borderRadius:'10px', pl:1}} Default={types} onchange={(event)=>handleChangeProductFamilyType(event, index)}/>
                            <Button
                                id="validate" variant="contained" onClick={() => buttonDisabledForSave ? '' : saveServiceProvider()}
                                sx={{ textTransform: 'none', borderRadius:"10px", pl:2, pr:2, fontSize:'16px'}}
                                >
                                {buttonDisabledForSave ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                            </Button>
                       
                        </> :<> 
                            <InputBox sx={{width:'78%', borderBottom:"0.5px solid #F3F3F3", pl:1}} value={types}/>
                                {permissionsDetails?.perm_settings_edit ?<>
                                <Typography sx={{pl:1,pt:1}} onClick={()=> {editProductFamilyType(index) ; setValues({value:types, index:index})}}>
                                    <EditIcon w={20} h={20} />
                                </Typography> 
                                <Typography sx={{pr:1,pt:1}} onClick={() => deleteProductFamilyType(index, types)}>
                                    <DeleteIcon  w={20} h={20} />
                                </Typography></> :<></>}
                            </>}
                    </CardInfo> </>  
                ))}</>}
                </Box>
            </CardContent>
        </Card>
    )
}