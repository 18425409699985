import {Box} from '@mui/material'

export default function FloatingButton({onClick}) {
  return (
    <Box onClick={onClick} sx={{position:'fixed',right:40, bottom:30}}>
  <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
  <circle cx="38" cy="38" r="37.5" fill="#3107AA" stroke="#3107AA"/>
  <path d="M29.0967 38H46.9029" stroke="#F9F9F9" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M38 29.0977V46.9039" stroke="#F9F9F9" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </Box>
  );
}