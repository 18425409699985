import React, { useState, useEffect, useRef } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { ComapanyName, ContactCard, CustemBtn, DatePicker, DeleteIcon, EditIcon, FollowupCard, InputBox, Navbar, OpportunityCard, OpportunityIcon, OrganizationDetails, SelectDropDown, TextArea } from '../../components/custom';
import { Typography, Box, Grid, Card, IconButton } from '@mui/material';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import CountCard from '../../components/custom/CountCard';
import AddIcon from '../../components/custom/AddIcon';
import ContactForm from '../ContactForm/ContactForm';
import { useDispatch, useSelector } from 'react-redux';
import { enableDisableContact } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import validate from '../../validation/validation';
import DisplayError from '../../validation/DisplayError';
import { createTaskForLead } from '../../Redux/Thunk/createTaskthunk';
import { leaddetails } from '../../Redux/Thunk/createleadthunk';
import CreateTaskForm from '../Task/CreateTaskForm';
import moment from 'moment';
import AdminRole from '../../adminrole/Adminrole';

const ChildCard = styled('div')({
    backgroundColor: '#FFFFFF',
})

const task_status = [{ value: "Assigned", label: "Assigned" }, { value: "Completed", label: "Completed" }, { value: "Customer Side Pending", label: "Customer Side Pending" },
{ value: "Inprocess", label: "Inprocess" }, { value: "Pending Input", label: "Pending Input" }, { value: "Roll Over", label: "Roll Over" }]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 50,
        },
    },
};

const TaskDetails = ({For, title, displayLeadDetails, allTaskDetails, CompanyName}) => {
    // const [taskdetails, setTasDetails] = useState(leaddetails ? leaddetails?.gettask : '')
    // const [task, setTask] = useState(Task)
    const leaddetails = useSelector((state) => state.lead.leaddetail)
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user?.role?.permissions
    const task = (displayLeadDetails?.gettask??[])?.map((task) =>task)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [closeTask, setCloseTask] = useState({
        missing_deadline: '',
        closing_remark:'',
        status:''
    })
    const [error, setError] = useState([])
    const [openTaskPopup, setOpenTaskPopup] = useState(false) 
    const [taskedit, setTaskEdit]= useState({})
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())

    let currentDate =  new Date() 
    // const contactStatusChanges = (contact) => {
    //     contactStatusChangesFromProfilePage(contact)   
    // } 

    // useEffect(() => {
    //     // setTask({})
    //     dispatch(leaddetails(task?.task_for_id)).then((res)=>{
    //         let updated_task_data = res.payload.gettask.find((task)=> task.id === Task.id)
    //         setTask({...updated_task_data})
    //     })      
    // },[refresh])

    const handleClose = (e) => {
      setCloseTask({...closeTask,[e.target.name]:e.target.value})
    }

    let validation_input = [
        currentDate > new Date(task[0]?.end_date) ? { tagid: 'missing_deadline', text: closeTask?.missing_deadline, required: true, regex_name: 'free_text', errmsg: 'Please add missing deadline remark between 100 to 2000 characters.', min:100, max:2000}:'',
        (task[0]?.closing_remark == '') ? { tagid: 'closing_remark', text: closeTask?.closing_remark, required: true,  regex_name: 'free_text', errmsg: 'Please add closing remark between 100 to 2000 characters.', min:100, max:2000}:'',
        // { tagid: 'status', text: closeTask?.status, required: true, regex_name: 'text', errmsg: 'Please Select Status.' },   
    ]

    const handleCloseTask = () => {
      if(validate(validation_input).length == 0) {
            closeTask.task_type = task[0]?.task_type 
            closeTask.subject = task[0]?.subject 
            closeTask.start_date = task[0]?.start_date 
            closeTask.end_date = task[0]?.end_date 
            closeTask.operationmode = 'edit'
            closeTask.task_for = task[0]?.task_for
            closeTask.taskid = task[0]?.id
            closeTask.assigned_to=task[0]?.assigned_to?.id
            // closeTask.status= task[0]?.status
                dispatch(createTaskForLead(closeTask)).then((res) => {
                    if(res?.payload?.errormsg){
                        sweetalert('error', res?.payload?.errormsg) 
                        setError([])
                    }else{
                        sweetalert('success', res?.payload?.data?.msg)
                        setError([])
                        setCloseTask({
                            missing_deadline: '',
                            closing_remark:'',
                            status:''
                        })
                    }
                })    
            } else {
                setError(validate(validation_input))
            }
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };

    const handleCreateTask= () => {
        setOpenTaskPopup(true)
    }
console.log(displayLeadDetails,"displayLeadDetails")
    const handleSubmitAllTaskDetails =()=>{
        navigate('/dashboard/leadTaskList', {state: displayLeadDetails})   // lead id 
    }

    return (
        <>
        <Card style={{ flex: 1, marginTop: '20px', borderRadius: '10px' }}>
            <Grid sx={{ borderBottom: '1px solid #e4e4e4', mt: 1, ml: 2, mr: 2, display: "flex", justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Grid item xs={6}>
                    <Typography variant='h6' sx={{ fontSize: '22px', fontWeight: 600}}> Tasks Details</Typography>
                </Grid>
                <Grid item xs={6}>
                     {/*   lead is lost and converted then create follow up button is disable  */}
                {displayLeadDetails?.leaddetails?.status === 'Converted' || displayLeadDetails?.leaddetails?.status === 'Lost' || displayLeadDetails?.oppodetails?.opportunity_stage ==='Closed-Won' || displayLeadDetails?.oppodetails?.opportunity_stage ==="Closed-Lost" ? <></>:<> 
                     {(checkAdminRole && permissionsDetails?.perm_task_create) || permissionsDetails?.perm_task_create? <>
                        <Box sx={{ mb: 1, display: 'flex', justifyContent: 'end' }}>
                            <CustemBtn onClick={handleCreateTask} variant='contained' sx={{ borderRadius: "10px", fontSize: '16px', backgroundColor: '#7673F6' }} text="Create Task" />
                        </Box>
                    </>: <></>} </>}
                </Grid>
            </Grid>

            {task?.length > 0 ? <>
            <ChildCard sx={{ p: 2 }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }} spacing={{ lg: 2, md: 2, sm: 2, xs: 2 }}>
                    <Grid item xs={12} md={4} sm={4} lg={3}>
                        <Typography>Task ID</Typography>
                        <InputBox disable={task[0]?.task_no} value={task[0]?.task_no} sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={3}>
                        <Typography>Type</Typography>
                        <InputBox disable={task[0]?.task_type} value={task[0]?.task_type} name='organizationName' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={3}>
                        <Typography >Start Date</Typography>
                        <DatePicker
                            name='startDate'
                            value={task[0]?.start_date}
                            disabled={task[0]?.start_date}
                            maxDate={new Date()}
                            style={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }} >DD-MM-YY
                        </DatePicker>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4} lg={3}>
                        <Typography >End Date</Typography>
                        <DatePicker
                            value={task[0]?.end_date}
                            disabled={task[0]?.end_date}
                            maxDate={new Date()}
                            style={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }} >DD-MM-YY
                        </DatePicker>
                    </Grid>
                </Grid>
                <Box style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight:'10px'}}>
                    <Grid item xs={12} md={4} sm={4} lg={12}>
                        <Typography>Requirement</Typography>
                        <TextArea value={task[0]?.subject} disabled={task[0]?.subject} name='subject' style={{ width: '100%', backgroundColor: '#E9E9FF', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px',fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding:'5px'}} placeholder="Write a note about Lead."></TextArea>
                    </Grid>
                </Box>
                <Box style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Grid item xs={12} md={3} sm={4} lg={3}>
                        <Typography >Assign To</Typography>
                        <InputBox disabled={task[0]?.closing_remark !==''} value= {`${task[0]?.assigned_to?.firstname} ${task[0]?.assigned_to?.lastname}`} name='assigned_to' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        
                        {/* <SelectDropDown
                           disabled={task[0]?.closing_remark !==''}
                            value= {task[0]?.assigned_to?.id}
                            name='assigned_to'
                            options={getuserlist?.getuserlist?.data?.map((user) => {
                                return {
                                    value: user?.id,
                                    label: `${user?.firstname} ${user?.lastname}`
                                }
                            })}
                            placeholder='Select Field'
                            sx={{ p: 0.1, mt: 0.5, backgroundColor: '#E9E9FF', borderRadius: "10px" }}
                        /> */}
                    </Grid>
                    <Grid item xs={12} md={3} sm={4} lg={3}>
                        <Typography >Closed At</Typography>
                        <InputBox disabled={task[0]?.closing_remark !==''} value= {task[0].closed_on !=0 ?moment(Number(task[0]?.closed_on)).format('D/M/YYYY'):0} name='closed_at' sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} sm={4} lg={3}>
                        <Typography>Stage</Typography>
                        <SelectDropDown
                            name='status'
                            disabled={task[0]?.closing_remark !== ''}
                            placeholder='Select Field'
                            options={task_status}
                            onchange={handleClose}
                            value={task[0]?.status ? task[0]?.status : closeTask?.status}
                            sx={{ p: 0.1, backgroundColor: task[0]?.closing_remark !== '' ?'#E9E9FF': '#ffffff', borderRadius: "10px" }}
                            // MenuProps={MenuProps}
                        />
                        <DisplayError error={error} fortagid='status' />
                        {/* <InputBox disabled={task[0]?.closing_remark !==''} value={task[0]?.status} name = 'status' onchange={handleClose}sx={{ border: "1px solid #B0AEFF", background: '#E9E9FF', height: "40px", color: '#444444', fontSize: '14px', lineHeight: '17px', width: '100%', borderRadius: '8px', p: 0.1, pl: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        /> */}
                    </Grid>
                </Box>
                {((currentDate >  new Date(task[0]?.end_date) && task[0]?.closing_remark==='') || (task[0]?.missing_deadline !== '')) ? <Box style={{ paddingRight:'10px', marginTop: '20px', display: 'flex', flexDirection: 'row' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} sm={4} lg={12}>
                        <Typography>Missing Deadline<span style={{ color: '#FF0000' }}>*</span></Typography>
                        <TextArea disabled={task[0]?.missing_deadline !== ''} value={task[0]?.missing_deadline ==="" ? closeTask?.missing_deadline?.replace(/\s\s+/g, " ") : task[0]?.missing_deadline} name='missing_deadline' onChange={handleClose} style={{ width: '100%', paddingTop: '5px', paddingLeft: '5px', backgroundColor: task[0]?.missing_deadline !== ''?'#E9E9FF' :'#FFFFFF', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px' }} placeholder="Write a note about Missing Deadline."></TextArea>
                        <DisplayError error={error} fortagid='missing_deadline' />
                    </Grid>
                    {task[0]?.missing_deadline ===""  ? <><div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                            <span>Characters: {closeTask?.missing_deadline?.length}/2000</span>
                        </div></>:<></>}
                    </Grid>
                    </Box> : ''
                }

                <Box style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight:'10px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} sm={4} lg={12}>
                            <Typography>Closing Remark<span style={{ color: '#FF0000' }}>*</span></Typography>
                            <TextArea disabled={task[0]?.closing_remark !== ''} value={task[0]?.closing_remark === ''? closeTask?.closing_remark?.replace(/\s\s+/g, " "): task[0]?.closing_remark} name='closing_remark' onChange={handleClose} style={{ width: '100%', paddingTop: '5px', paddingLeft: '5px', backgroundColor: task[0]?.closing_remark !== ''? '#E9E9FF' :'#FFFFFF', fontFamily: 'Montserrat', fontStyle: 'normal', border: "1px solid #B0AEFF", height: '80px', borderRadius: '8px', marginBottom: '5px', fontWeight: 400, fontSize: '14px', lineHeight: '17px' }} placeholder="Write a note about Closing Remark."></TextArea> 
                            <DisplayError error={error} fortagid='closing_remark' />
                        </Grid>
                        {task[0]?.closing_remark === '' ? <><div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                            <span>Characters: {closeTask?.closing_remark?.length}/2000</span>
                        </div></>:<></>}
                    </Grid>
                </Box>

                <Box sx={{ mt: 3, borderBottom: '1px solid #e4e4e4', display: 'flex', justifyContent: 'end' }}>
                    <CustemBtn disabled={task[0]?.closing_remark !== ''} onClick={handleCloseTask} variant='contained' sx={{ mb:1, borderRadius: "10px", fontSize: '16px', backgroundColor: '#7673F6' }} text="Close Task" />
                </Box>

                <Box sx={{mt:1, display: 'flex', justifyContent: 'end'}}>
                    {/* <Link style={{textDecoration:'none'}} to='/dashboard/leadtasklist' state={allTaskDetails}> */}
                        <Typography fontSize='14px' alignItems='center' onClick={handleSubmitAllTaskDetails} sx={{color:'#FE8731', cursor:'pointer', fontWeight:'600'}}>See All</Typography>
                    {/* </Link> */}
                </Box>
            </ChildCard>
            </>:<><Typography style={{display:'flex', justifyContent:'center',flexDirection:'row', color:'red',padding:'10px'}}>No Task Available</Typography></>} 
        </Card>

         {openTaskPopup ? <CreateTaskForm title={title} For='create' taskedit={taskedit} selectedOpportunityDetails={displayLeadDetails?.oppodetails} selectedLeadDetails={displayLeadDetails?.leaddetails} companyName ={CompanyName} openTaskPopup={openTaskPopup} setOpenTaskPopup={setOpenTaskPopup}  /> :''}  
         {/* task_for= {{value:'Lead'}} */}
        </>
    )
}

export default TaskDetails
