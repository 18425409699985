import React, { useState, useEffect} from 'react';
import { ComapanyName, CompanyName, ContactCard, CustemBtn, DatePicker, InputBox, TextArea } from '../../components/custom';
import { Typography, Box, Grid, Card, IconButton, Tooltip } from '@mui/material';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { createTaskForLead } from '../../Redux/Thunk/createTaskthunk';
import TableListTitle from '../../components/custom/TableListTitle';
import { LeadTaskDetails } from './LeadTaskDetails';
import { leaddetails } from '../../Redux/Thunk/createleadthunk';
import CreateTaskForm from '../Task/CreateTaskForm';
import { oppodetails } from '../../Redux/Thunk/createopportunitythunk';
import { TailSpin} from 'react-loader-spinner';
import AdminRole from '../../adminrole/Adminrole';

// Display Lead number agaist task list
const LeadTaskList = () =>{
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {state}= useLocation()
    const {createTask} = useSelector((state)=> state?.task)
    const leadDetails = useSelector((state) => state.lead.leaddetail)
    // const taskDetails = leadDetails?.gettask?.map((task) =>task)
    const [taskDetails, setTaskDetails] =useState([])
    const {user} = useSelector((state) => state.auth)
    const [openTaskPopup, setOpenTaskPopup] = useState(false)
    const [taskedit, setTaskEdit]= useState({})
    const permissionsDetails = user?.data?.user?.role?.permissions
    const [page, setPage] = React.useState(1);
    const [loader, setLoader] = useState(false)
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
    const Title= "Task List"
    const tooltipTitle = "New Task"
    const title ="LeadDetailsPage"
   
    const indexOfLastItem = page * 4;
    const indexOfFirstItem = indexOfLastItem - 4;
    const currentItems = taskDetails?.gettask?.map((task) =>task)?.slice(indexOfFirstItem, indexOfLastItem);
    
    useEffect(()=>{
        setLoader(true)
        if(state?.leaddetails){
            dispatch(leaddetails(state?.leaddetails?.id)).then((res)=>{
                setTaskDetails(res?.payload)
                setLoader(false)
            }) 
        }else{
            dispatch(oppodetails(state?.oppodetails?.id)).then((res)=>{
                setTaskDetails(res?.payload)
                setLoader(false)
            })
        }    
    },[createTask])
console.log(taskDetails,"taskDetails")
    // const handleClose = (e) => {
    //   setCloseTask({...closeTask,[e.target.name]:e.target.value})
    // }

    // let validation_input = [
    //     currentDate > new Date(task?.end_date) ? { tagid: 'missing_deadline', text: closeTask?.missing_deadline, required: true, regex_name: 'text', errmsg: 'Please add missing deadline remark.'}:'',
    //     (task?.closing_remark == '') ? { tagid: 'closing_remark', text: closeTask?.closing_remark, required: true, regex_name: 'text', errmsg: 'Please add closing remark.' }:''
    // ]

    const handleCloseTask = (task, setError) => {    
            dispatch(createTaskForLead(task)).then((res) => {
                if(res?.payload?.errormsg){
                    sweetalert('error', res?.payload?.errormsg)
                    setError([]) 
                }else{
                    sweetalert('success', res?.payload?.data?.msg) 
                    setError([])
                    // swal({
                    //     buttons: {
                    //         Cancel: {
                    //             text: "Go To Dashboard",
                    //             value: "Cancel",
                    //             className: "popup-blue",
                    //         },
                    //         Delete: {
                    //             text: "Go To Task List",
                    //             value: "taskList",
                    //             className: 'popup-gray',
                    //         },
                    //     },
                    //     title: res?.payload?.data,
                    //     className: "custom-modal",
                    //     closeOnClickOutside: false,
                    // }).then((value) => {
                    //     if (value == 'taskList') {
                    //         navigate('/dashboard/tasklist')
                    //     } else {
                    //         navigate('/dashboard')
                    //     }
                    // })
                }
            })
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };

    const handleCreateTask=()=>{
        setOpenTaskPopup(true)
    }
   
    return (
        <>
        { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
            <TailSpin
                height="30"
                width="30"
                color="blue"
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            /> </Box>:<>
            <CompanyName style={{background:'#3107AA'}} sx={{color:'#FFFFFF',letterSpacing:"0.04em"}} label={`${taskDetails?.company?.company_name} ${taskDetails?.company?.company_type}`}/>
            <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
                <Grid sx={{ borderBottom: '1px solid #e4e4e4', mt: 2, ml:4 , mr: 4, display: "flex", flexDirection: { lg: 'row', sm: 'row', xs: 'column-reverse' }, justifyContent: { lg: 'space-between', sm: 'space-between', xs: 'start' }, alignItems: { lg: 'flex-end', md: 'flex-end', xs: 'center' }}}>
                    <Typography variant='h6' sx={{ fontSize: { lg: '22px', md: '20px', sm: '18px' }, fontWeight: 600 }}>{taskDetails?.leaddetails? `Task List  For ${taskDetails?.leaddetails?.lead_no}` : `Task List  For ${taskDetails?.oppodetails?.opportunity_no}` }</Typography>
                </Grid>
        
                {/* Search component and create new task */}
                <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mr:4 }}>
                    {/* <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={} onClick ={handleCreateTask} onchange={handleSearch} /> */}
                    {taskDetails?.leaddetails?.status ==="Converted" || taskDetails?.leaddetails?.status ==="Lost" || taskDetails?.oppodetails?.opportunity_stage ==='Closed-Won' || taskDetails?.oppodetails?.opportunity_stage ==="Closed-Lost"? <></>:
                    (checkAdminRole && permissionsDetails?.perm_task_create) || permissionsDetails?.perm_task_create ?<>
                        <Tooltip title={tooltipTitle}>
                            <IconButton onClick={handleCreateTask}> 
                            <svg width="34" height="34" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="21" cy="21" r="20.5" fill="#A484FE" stroke="#A484FE"/>
                                <path d="M14.125 21H27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21 14.125V27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </IconButton>
                        </Tooltip>
                    </>:<></>}
                    </Grid>
            </Grid>

            <Box sx={{mt:2, p:2, ml:{xs:1, sm:1, xl:3, lg:2}, mr:{xs:1, sm:1, xl:3, lg:2}, borderRadius:'10px', backgroundColor:'#D8D7FF'}}>
            <Grid container spacing={{xs: 2, md:2, lg:2, sm:2}} columns={{ xs: 2, sm: 2, md:12, lg:8}}>
                {/* < Create Followup/> */}
                {currentItems?.map((task)=>( 
                    <>
                        <Grid item xs={2} sm={4} md={4} lg={4}>
                            <LeadTaskDetails task={task} handleCloseTask={handleCloseTask}/>
                        </Grid> 
                    </>))}
                    </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1, pt: 4, pb: 4 }}>
                <PaginationFooter number={4} list={taskDetails?.gettask?.map((task) =>task)?.length} currentPage={page} setCurrentPage={setPage}/>
            </Box>

            {openTaskPopup ? <CreateTaskForm title={title} For='create' taskedit={taskedit} selectedOpportunityDetails={taskDetails?.oppodetails} selectedLeadDetails={taskDetails?.leaddetails} companyName ={leadDetails?.leaddetails?.company} openTaskPopup={openTaskPopup} setOpenTaskPopup={setOpenTaskPopup} task_for= {{value:'Lead'}} /> :''}
        </>}
    </>
    )
}

export default LeadTaskList