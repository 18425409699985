const themeColor = {
    palette: {
        TableButton: '#4642FF',
        BrandColor: '#3107AA', 
        Primarytext1: '#333333',
        primarytext2 :'#200E32',
        InputLabel:'#A3A3A3',
        mainSkyBlue:'#1F78FF',
        LeadID :'#C72424',
        FollowupID:'#4F709C',
        White:'#F9F9F9',
        PrimaryRed :'#E70707',
        backgroundColor:'#F0F0F0',
        OpportunityID:'#FF6B00',
        PrimaryGold:'#ED9C00',
      },
};
export default themeColor;