
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";

export const createIndustryTypesList = createAsyncThunk('data/createdropdown',
    async(data) => {
        const res = await axiosInstance.post('/saveindustrytypes', data);
         return res.data.data
    }
)

export const getIndustryTypesList = createAsyncThunk('data/getIndustry',
    async() => {
        const res = await axiosInstance.get('/getindustrytype');  
        return res.data.data
    }
)

// Get Service Provider List
export const getServiceProviderTypesList = createAsyncThunk('data/getServiceProvider',
    async(data) => {
         const res = await axiosInstance.get(`/getserviceprovidertype`, data); 
         return res.data.data
    }
)

//Creat , edit and delete Service Provider types
export const createServiceProviderTypes = createAsyncThunk('data/createServiceProvider',
    async(data) => {
         const res = await axiosInstance.post('/saveserviceprovidertypes', data);   
         return res.data.data
    }
)

// Get Product Family types
export const getProductFamilyTypesList = createAsyncThunk('data/getProductFamily',
    async(data) => {
         const res = await axiosInstance.post('/getproductfamilytype', data);  
         return res.data.data
    }
)

// Create, edit and delete types for product family
export const createProductFamilyTypes = createAsyncThunk('data/createProductFamily',
    async(data) => {
         const res = await axiosInstance.post('/saveproductfamilytypes', data);  
         return res.data
    }
)

export const getProductsProductFamilyTypes = createAsyncThunk('data/getProductsProductFamily',
    async(data) => {
         const res = await axiosInstance.post('/getproductsforproductfamily', data);  
         return res.data.data
    }
)

export const getproductfamilyserviceproviderTypes = createAsyncThunk('data/getproductfamilyserviceprovider',
    async(data) => {
        const res = await axiosInstance.post('/getproductfamilyforserviceprovider', data);  
        return res.data.data
    }
)

// Get Lead source type list
// export const getLeadSourceTypesList = createAsyncThunk('data/getServiceProvider',
//     async() => {
//          const res = await axiosInstance.get('/getproductfamilytype');
//          console.log(res.data.data)   
//          return res.data.data
//     }
// )

// // Create, edit and delete types for product family
// export const createProductFamilyTypes = createAsyncThunk('data/createProductFamily',
//     async(data) => {
//          const res = await axiosInstance.post('/saveproductfamilytypes', data);
//          console.log(res.data.data)   
//          return res.data.data
//     }
// )

//Get Product Types
export const getProductTypesList = createAsyncThunk('data/getProduct',
    async(data) => {
         const res = await axiosInstance.post('/getproducttype', data); 
         return res.data.data
    }
)

// Create, edit and delete types for product family
export const createProductTypes = createAsyncThunk('data/createProduct',
    async(data) => {
         const res = await axiosInstance.post('/saveproducttypes', data);   
         return res.data
    }
)

// Get Opportunity Types
export const getOpportunityList = createAsyncThunk('data/getOpportunity',
    async() => {
         const res = await axiosInstance.get('/getopportunitytype');   
         return res.data.data
    }
)

// Create, edit and delete types for Opportunity
export const createOpportunityTypes = createAsyncThunk('data/createOpportunity',
    async(data) => {
         const res = await axiosInstance.post('/saveopportunitytypes', data);
         console.log(res.data.data)   
         return res.data.data
    }
)

// Get Order Types
export const getOrderTypesList = createAsyncThunk('data/getOrder',
    async(data) => {
         const res = await axiosInstance.post('/getordertype', data);   
         return res.data.data
    }
)

// Create, edit and delete types for Opportunity
export const createOrderTypes = createAsyncThunk('data/createOrder',
    async(data) => {
         const res = await axiosInstance.post('/saveordertypes', data);
         console.log(res.data.data)   
         return res.data.data
    }
)


export const getordertypesforopportunity = createAsyncThunk('data/getOrderTypesForOpportunity',
    async(data) => {
         const res = await axiosInstance.post('/getordertypesforopportunitytype', data);  
         console.log(res.data)
         return res.data.data
    }
)

//Follow up Create ,edit 
export const CreateEditFollowup = createAsyncThunk('data/followup',
    async(data) => {
         const res = await axiosInstance.post('/mixinmanagefollowup', data);  
         return res.data
    }
)

//Display Followup List
export const FetchFollowupList = createAsyncThunk('data/followupList',
    async(data) => {
         const res = await axiosInstance.post('/followuplist', data);  
         return res.data
    }
)

//Company wise Followup List
export const CompanyFollowupList = createAsyncThunk('data/companywisefollowupList',
    async(data) => {
         const res = await axiosInstance.post('/companyFollowupList', data);  
         return res.data
    }
)