
const LeadWIPStatusIcon = () => {

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3624_88649)">
        <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" fill="#630000" stroke="#630000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.3737 13.6673C23.1561 13.6673 23.7904 14.3016 23.7904 15.084V17.9173C23.7904 18.6997 23.1561 19.334 22.3737 19.334H21.6215C21.2729 22.1293 18.8884 24.2923 15.9987 24.2923V22.8757C18.3459 22.8757 20.2487 20.9729 20.2487 18.6257V14.3757C20.2487 12.0284 18.3459 10.1257 15.9987 10.1257C13.6515 10.1257 11.7487 12.0284 11.7487 14.3757V19.334H9.6237C8.84129 19.334 8.20703 18.6997 8.20703 17.9173V15.084C8.20703 14.3016 8.84129 13.6673 9.6237 13.6673H10.3759C10.7244 10.872 13.109 8.70898 15.9987 8.70898C18.8884 8.70898 21.2729 10.872 21.6215 13.6673H22.3737ZM12.995 19.1816L13.7459 17.9801C14.399 18.3892 15.1712 18.6257 15.9987 18.6257C16.8262 18.6257 17.5984 18.3892 18.2515 17.9801L19.0025 19.1816C18.1316 19.727 17.102 20.0423 15.9987 20.0423C14.8954 20.0423 13.8658 19.727 12.995 19.1816Z" fill="#F9F9F9"/>
        </g>
        <defs>
        <clipPath id="clip0_3624_88649">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )}

    export default LeadWIPStatusIcon