
import React, {useState, useEffect} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import OpportunityCard from '../../components/custom/OpportunityCard'
import { Typography,Card,CardContent, Grid, Box, CircularProgress, Button} from '@mui/material';
import { CustemBtn, DeleteIcon, EditIcon, InputBox, Navbar, SettingMasterCard, FilterIcon} from '../../components/custom';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useDispatch, useSelector } from 'react-redux';
import { createServiceProviderTypes, getServiceProviderTypesList } from '../../Redux/Thunk/createsettinglist';
import swal from 'sweetalert';
import { CardHeader, CardInfo} from './SettingMasterList.style'
import { TailSpin } from 'react-loader-spinner';

export default function ServiceProviderList ({permissionsDetails}){
    const dispatch = useDispatch();
    const {service} = useSelector(state => state.settingList)
    const [serviceProviderType, setServiceProviderType]= useState([]) 
    const [openButton, setOpenButton] = useState(false)
    const [prevValue, setNewValue] = useState('')
    const [buttonStatus, setButtonStatus] = useState(false);
    const [indexValue, setIndexValue] = useState('') 
    const [inputFieldStatus, setInputFieldstatus] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [flag ,setFlag]= useState(false)
    const [loader, setLoader] = useState(true)
    const [buttonDisabledForSave, setButtonDisabledForSave] = useState(false)
    const [values, setValues] = useState({
        value:'',
        index:''
    })

    useEffect(()=>{
        dispatch(getServiceProviderTypesList()).then((res)=>{
            setServiceProviderType(res?.payload?.value) 
            setButtonStatus(false)    
            setInputFieldstatus(false)
            setLoader(false)
        })
    },[flag])
   
    const createServiceProvider =(event) =>{
       setButtonStatus(true)
       setValues({value: event.target.value?.trimStart()})
    }
  
    const addServiceProviderType = () =>{  
        if(serviceProviderType?.find(item =>item?.toUpperCase() === values?.value?.toUpperCase())){
            sweetAlert("error", "This field is already exit")
        }else if(values?.value === ''){
            sweetAlert("error", "Not valid.")
        }else{
            setButtonDisabled(true)
            const value = [...serviceProviderType, values?.value?.trim()]
            dispatch(createServiceProviderTypes({setting_serviceprovider_list: value})).then((res)=>{
                console.log(res.payload)
                sweetAlert("success", `Service Provider Added Successfully. Please Add Product Family for ${values?.value?.trim()}`)
                setValues({ value:'', index:'' })
                setButtonDisabled(false)
                setButtonStatus(false)
                setLoader(true)
                setFlag(!flag)
            }) 
        }
    }

    const sweetAlert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
        })
    };
    console.log(prevValue)
    const editServiceProviderType = (index) =>{
        setOpenButton(true)
         setNewValue(serviceProviderType[index])
        setIndexValue(index)
        setButtonStatus(false)
        setInputFieldstatus(true)
    }
  
    const handleChangeServiceProvider =(event, index)=>{
        setValues({value:event.target.value.trimStart(), index:index})
    }
  
    const saveServiceProvider =() =>{
      const data= serviceProviderType?.slice();
      data.splice(values?.index, 1, values?.value);
     
      const isDuplicate = data?.some(
        (item, index) =>
          item?.toUpperCase() === values?.value?.toUpperCase() && index !== values?.index
      );
    
        if (isDuplicate) {
            sweetAlert("error", "This Service Provider is already exit")
        }else if(values?.value === ''){
            sweetAlert("error", "Please Enter Service Provider.")
        }else{
            swal({
                buttons: {
                Cancel: {
                    text: "Cancel",
                    value: "Cancel",
                    className: "popup-blue",
                },
                Delete: {
                    text: "Confirm",
                    value: "Confirm",
                    className: "popup-gray",
                },
                },
                title: `Do you want to Save the Service Provider option ${values.value} ?`,
                className: "custom-modal",
                closeOnClickOutside: false,
              }).then((value)=>{
                  if(value=="Confirm"){
                    
                    setLoader(true)
                    setButtonDisabledForSave(true)
                    dispatch(createServiceProviderTypes({setting_serviceprovider_list : data, prev : prevValue, new : values.value })).then((res)=>{
                        sweetAlert("success", "Service Provider Updated Successfully.") 
                        setOpenButton(false)
                        setButtonStatus(true)
                        setButtonDisabledForSave(false)
                        setValues({ value:'', index:'' })
                        setFlag(!flag)
                    }) 
                  }else{
                    setOpenButton(false)
                    setInputFieldstatus(false)
                    setValues({ value:'', index:'' })
                  }
              })
         
        }
    }
    
    const deleteServiceProvider =(index, type) =>{
        swal({
            buttons: {
            Cancel: {
                text: "Cancel",
                value: "Cancel",
                className: "popup-blue",
            },
            Delete: {
                text: "Confirm",
                value: "Confirm",
                className: "popup-gray",
            },
            },
            title: `All the products listed under this ${type} will also be removed. Proceed with deletion ?`,
            className: "custom-modal",
            closeOnClickOutside: false,
          }).then((value)=>{
              if(value =="Confirm"){
                const data= serviceProviderType.slice();
                data.splice(index,1);
                setLoader(true)
               
                dispatch(createServiceProviderTypes({setting_serviceprovider_list :data})).then((res)=>{
                    if(res?.payload?.errormsg){
                        sweetAlert("error", res?.payload?.errormsg)
                        setLoader(false)
                      }else{
                          sweetAlert("success", "Service Provider Deleted Successfully.")
                          setFlag(!flag)
                          // setLoader(false)
                          setValues({ value:'', index:'' })
                      }
                })
              }
          })
    }

      
    return(
        <Card sx={{ maxWidth: '100%',borderRadius:'10px',boxShadow:' 0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
            
                <CardHeader>
                    <Typography sx={{color:'#444444',fontSize:'18px',fontWeight:600}}>
                        Service Provider
                    </Typography>
                </CardHeader>
                <CardContent style={{ display: "flex",
                flexDirection: "column",
                height: 300,
                overflow: "hidden",
                overflowY: "scroll"}}>
                {permissionsDetails?.perm_settings_create ?<><CardInfo>
                    <Typography>
                        Add Service Provider
                    </Typography> 
                    <Typography sx={{pr:2}}>
                        Action
                    </Typography> 
                </CardInfo></>:<></>}
                {permissionsDetails?.perm_settings_create ?<><CardInfo>
                    <InputBox sx={{width:'78%', border:"2px solid #B0AEFF", borderRadius:'10px', pl:1, backgroundColor:!inputFieldStatus?'#FFFFF' :'#C1C1C1'}} name='industry' disable={inputFieldStatus} value={values.value} onchange ={createServiceProvider} placeholder='Service Provider'/>
                    <Typography>
                    <Button
                      id="validate" variant="contained" disabled={!buttonStatus} onClick={() => buttonDisabled ? '' : addServiceProviderType()}
                      sx={{borderRadius:"10px", pl:2, pr:2, fontSize:'16px'}}
                      >
                      {buttonDisabled ? <CircularProgress size={20} color="inherit" /> : 'ADD'}
                    </Button>
                    {/* <CustemBtn variant="contained" sx={{backgroundColor:'#84c75a'}} text="ADD" disabled={!buttonStatus} onClick={addServiceProviderType}/>  */}
                    </Typography>
                </CardInfo></>:<></>}
                <Box sx={{ flexGrow: 1 }}>
                    { loader ? <Box sx={{height:'300px', alignItems:'center', display:'flex', mt:0.5, justifyContent:"center", borderRadius:'10px'}}>
                        <TailSpin
                          height="30"
                          width="30"
                          color="blue"
                          ariaLabel="tail-spin-loading"
                          radius="3"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        /> </Box>:<>
                {serviceProviderType?.map((types, index) =>( <>
                    <CardInfo sx={{mt:2}} key={index}>
                        {openButton && indexValue===index ? <><InputBox sx={{width:'78%', border:"1px solid #B0AEFF", borderRadius:'10px', pl:1}} Default={types} onchange={(event)=>handleChangeServiceProvider(event, index)}/>
                        <Button
                            id="validate" variant="contained" onClick={() => buttonDisabledForSave ? '' : saveServiceProvider()}
                            sx={{textTransform: 'none', borderRadius:"10px", pl:2, pr:2, fontSize:'16px'}}
                            >
                            {buttonDisabledForSave ? <CircularProgress size={20} color="inherit" /> : 'Save'}
                        </Button>
                       
                        </> :<> 
                            <InputBox sx={{width:'78%', borderBottom:"0.5px solid #F3F3F3", pl:1}} value={types} onchange={(event)=>handleChangeServiceProvider(event, index, types)}/>
                                {permissionsDetails?.perm_settings_edit ?<>
                                    <Typography sx={{pl:1,pt:1}} onClick={()=> {editServiceProviderType(index) ; setValues({value:types, index:index})}}>
                                        <EditIcon w={20} h={20} />
                                    </Typography>  
                                <Typography sx={{pr:1,pt:1}} onClick={() => deleteServiceProvider(index, types)}>
                                    <DeleteIcon  w={20} h={20} />
                                </Typography></> :<></>}
                            </>}
                    </CardInfo> </>  
                ))}
                </>}
                </Box>
            </CardContent>
        </Card>
    )
}