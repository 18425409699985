import React,{useState} from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Paper, Grid,Table,Typography, Tooltip, IconButton } from '@mui/material';
import { EditIcon, Navbar,PopperFilter, InputBox, CustemBtn, CompanyName, LessThanIcon, DisableAssignIcon, AssignIcon } from '../../../components/custom';
import FilterIcon from '../../../components/custom/FilterIcon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComanyLeads, fetchopportunitylist, opportunityApprovedForUser, opportunityAssignedForUser} from '../../../Redux/Thunk/createopportunitythunk';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { headCells } from './headcells';
import AssignModal from '../../../components/custom/AssignModal';
import { leadAssignedToUser } from '../../../Redux/Thunk/createleadthunk';
import swal from 'sweetalert';
import FilterCloseIcon from '../../../components/custom/FilterCloseIcon';
import TableListTitle from '../../../components/custom/TableListTitle';
import TableListSearch from '../../../components/custom/TableListSearch';
import { displayContactList } from '../../../Redux/Thunk/createcontact';
import { H4TableID, H6TableCompany, T6TableText } from '../../../Theme/theme';
import themeColor from '../../../Theme/themeColor';
import moment from 'moment';
import AdminRole from '../../../adminrole/Adminrole';
import { TailSpin } from 'react-loader-spinner';

const CardBody = styled('div')({
  //  backgroundColor:'#F0EFFD',
  //  padding:'10px',
  //  borderRadius:'10px',
})

export default function CompanyWiseOpportunity (){
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {user} = useSelector((state) => state.auth)
  const {state} = useLocation()
  const permissionsDetails = user?.data?.user.role?.permissions
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const [order, setOrder] = useState('asc');
  const [opportunitylist, setOpportunitylist]= useState([])
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [user_ids, setUserId] = useState([])
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = React.useState('')
  const [openAssignModal,setOpenAssignModal] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [filterOpportunity, setFilterOpportunity] = useState('')
  const [opportunityAssigned, setOpportunityAssigned]= useState({})
  const [loader, setLoader] = useState(false)
  const opportunityName = "Opportunity"
  const leadName = 'Opportunity'
  const route = 'companyProfilePage'
  const title = 'companyProfilePageOpportunityDetails'
  const tooltipTitle = "New Opportunity"
  const placeholderTitle = "Search Opportunity"

console.log(state,"state")
  React.useEffect(()=> {
     getOpportunityDetailsList()
  },[update])

  const getOpportunityDetailsList=() =>{
    if(!state || state === 'undefined') {
      navigate('/dashboard')
      return
    } else {
      setLoader(true)
      setOpportunitylist([])
      dispatch(fetchopportunitylist({start: page*rowsPerPage, length:rowsPerPage, company_id: state?.id, search:search, filterOpportunity:filterOpportunity, col_sort: orderBy.split(' ').join(''), order: order.toUpperCase()})).then((res)=>{
        setOpportunitylist(res?.payload)
        setLoader(false)
      })
    }
  }

  const handleAssign = (row) => {
    setOpportunityAssigned(row)
    setOpenAssignModal(true)
  }

  const handleChangeLeadNotAssign =()=>{
    sweetalert('error', "You have no permissions to access this. Please contact your administrator for more information.")
 }

  const handlecreate=()=>{
    navigate('/dashboard/user')
  }
  
  const createNewOpportunityDetails =()=>{
    dispatch(fetchComanyLeads(state?.id)).then((res) =>{
      if(res?.payload){
        dispatch(displayContactList(state?.id))
        navigate('/dashboard/createopportunity',{state :{title:title, companyName:state, operation:'createOpp'}}) 
      } 
    })  
  }
   
  const handleClickOpen = (opportunity) => {
    if(opportunity?.opportunity_stage ==="Closed-Lost"){
      sweetalert('error',"This opportunity is lost")
    }else{
      navigate('/dashboard/opportunity', {state: {opportunity: opportunity, operation:'editOpp',title:title}})
    }
  };
  
  const openOpportunityDetails =(row) =>{
    navigate('/dashboard/opportunitydetails', { state: { oppDetails: row?.id, title: title}})
  }

  const findIds= (id1, id2) => {
    if (user_ids.includes(id1) || user_ids.includes(id2)) {
      return true 
    } else {
      return false
    }
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
  
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const approveOpportunity =(opportunity) =>{
    swal({
      buttons: {
      Cancel: {
          text: "No",
          value: "Cancel",
          className: "popup-blue",
      },
      Delete: {
          text: "Yes",
          value: "yes",
          className: 'popup-gray',
      },
      },
      title: `Do You Want to Approve the Opportunity.`,
      className: "custom-modal-user",
      closeOnClickOutside: false,
    }).then((value)=>{
        if(value =='yes'){
          dispatch(opportunityApprovedForUser({user_id: opportunity?.assigned_to?.id, opportunity_id:opportunity?.id })).then((res)=>{
            setUpdate(!update)
            if(res?.payload?.errormsg){
              sweetalert('error', res?.payload?.errormsg)
            }
            // setApproveButtonDisable(true)
          })
        }else{
         navigate('/dashboard/opportunitylist')
        }
    })
  }

  const handleClickForFilterList = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget)
    setOpenopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    
    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => (
            <TableCell
              sx ={{ fontWeight:600, fontSize:'13px', zIndex: !openPopper ? 1 : 0 }}
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'center'}
              padding={headCell.disablePadding ? 'normal' : 'none'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

          {/* {permissionsDetails?.perm_opportunity_edit ? <TableCell sx ={{ fontWeight:600, fontSize:'13px' }}>Edit</TableCell> :<></>} */}
          {/* {permissionsDetails?.perm_assign_edit ? <><TableCell sx ={{ fontWeight:600, fontSize:'13px' }}>Assign</TableCell></> :<></>}  */}
          {/* {permissionsDetails?.perm_approve_edit?<><TableCell align='left' sx ={{ fontWeight:600, fontSize:'13px'}}>Approval</TableCell></> :<TableCell sx ={{ fontWeight:600, fontSize:'13px' }}>Approval</TableCell> } */}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setUpdate(!update)
  };
  
  const handleSearch = (e) => {
    setSearch(e.target.value)
    setUpdate(!update)
  }
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setUpdate(!update)
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setUpdate(!update)
  };
  
  const assignOpportunity =(row)=>{
    // setOpenAssignModal(true)
    // setOpportunityAssigned(row)
  }

  const saveAssignedManagerOrExecutiveName =(managerList, selectManagerName)=>{
    if(selectManagerName ===''){
      sweetalert ('error', "Please Select Name") 
    }else{
        let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectManagerName)
        dispatch(opportunityAssignedForUser({opportunity_id:opportunityAssigned?.id, user_id:findUser?.id})).then((res)=>{
        sweetalert ('success', res.payload.data) 
        setOpenAssignModal(false)
        setUpdate(!update)
      })
    }
  }

  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  }

  const handleFilter = (e)=> {
    setPage(0)
    if (e.target.value == "All Opportunity") {
      // console.log('hii', e.target.value)
      setFilterOpportunity('')
      setOpenopper(false)
      setUpdate(!update)
    } else {
      setFilterOpportunity(e.target.value)
      setOpenopper(false)
      setUpdate(!update)
    }  
  }
  
  const handleCloseFilter = () => {
    setFilterOpportunity('')
    setUpdate(!update)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <>
     <PopperFilter filtervalues={[{ name: 'All Opportunity' }, { name: 'Identified' }, { name: 'Qualified' }, { name: 'Feasible' }, { name: 'Solution' }, { name: 'Commercials' }, { name: 'Proposal' }, { name: 'Negotiation' }, { name: 'VerbalConfirmation' }, { name: 'ClosedWon' }, { name: 'ClosedLost' }, { name: 'ClosedNoAction' }]} open={openPopper} anchorEl={anchorEl} placement={placement} onClick={handleFilter} />

      <CompanyName sx={{"&:hover": {cursor:'pointer'} }} label={`${state?.company_name} ${state?.company_type}`} companyID={state.id} />    
    
      <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
       {/* Opportunity Title */}
        <TableListTitle title={"Opportunity List"} />

     {/* Create new Opportunity, search bar and filter  */}
        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mb:0.5}}>
          {/* <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={permissionsDetails?.perm_opportunity_create} onClick ={createNewOpportunityDetails} onchange={handleSearch} />     */}
          <TableListSearch For='Oppo' tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={permissionsDetails?.perm_opportunity_create} onClick={createNewOpportunityDetails} onchange={handleSearch} />
            <Tooltip title="Filter By Status" placement="top-start">
              <IconButton onClick={handleClickForFilterList("bottom-start")} sx={{mr: {lg:4, xs:1}}}>
                {!openPopper ? <FilterIcon /> : <FilterCloseIcon />}
              </IconButton>
            </Tooltip>
        </Grid>
      </Grid>
      {
      filterOpportunity ? <Box sx={{ml:4, display:'flex', justifyContent:'space-between', fontSize:'10px',width:'32%',p:0.5,mt:0.5, border:'1px solid black', borderRadius:'10px'}}>
        <Typography sx={{fontSize:'12px'}}>
       Showing Oppo. for status  <span style={{color:'blue', marginLeft:'10px'}}>{filterOpportunity}</span> 
      </Typography>
      <Typography onClick={handleCloseFilter} sx={{color:"red", fontSize:'12px', cursor:'pointer', fontWeight:600, mr:0.5}}>
         Clear Filter X
      </Typography>
      </Box>:""
    }

    {/* Display Opportunity List */}
      {/* <Grid container alignItems="center" justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
      <TableListTitle title={Title} />

        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Tooltip title={tooltipTitle}>
              <IconButton sx={{mr: {lg:4, xs:1}}} onClick={createNewOpportunityDetails}> 
                <svg width="32" height="32" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="21" cy="21" r="20.5" fill="#A484FE" stroke="#A484FE"/>
                    <path d="M14.125 21H27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                   <path d="M21 14.125V27.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </IconButton>
          </Tooltip>
        </Grid>
      </Grid> */}
      
      <CardBody sx={{mt:1, ml:{xs:1, sm: 1, md: 1, xl:4, lg: 4}, mr:{xs:1, sm:1, md:1, xl:4, lg:4}}}> 
        <Paper sx={{padding:'10px', mb: 2 }}>
        <TableContainer sx={{ maxHeight: 440}}>
            <Table
              sx={{ minWidth: 750 }}
              size= 'small'
              stickyHeader aria-label="sticky table">
                
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={opportunitylist?.data?.length}
              />

            {loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
              <TailSpin
                height="30"
                width="30"
                color="blue"
                ariaLabel="tail-spin-loading"
                radius="3"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              /> </Box>:<>
              <TableBody>
                {opportunitylist?.data?.length === 0? <TableRow>
                    <TableCell colSpan={13} style={{textAlign: 'center', color:'red', fontWeight:600}}>
                      No opportunities available
                    </TableCell>
                    </TableRow>:( 
                      opportunitylist?.data?.map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row?.opportunity_no}
                          selected={isItemSelected}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align='center'
                            sx={{ padding: '3px' }}
                          >
                            <H4TableID onClick={() => openOpportunityDetails(row)} sx={{ color: "#FE8731", fontSize: '14px', fontWeight: 600, "&:hover": { cursor: 'pointer' } }}>
                              {row?.opportunity_no}
                            </H4TableID>
                          </TableCell>
                          {/* <TableCell align='center' sx={{ padding: '3px', fontWeight: 600, fontSize: '12px', maxWidth: 200, "&:hover": { cursor: 'pointer' } }} onClick={() => openCompanyProfilePage(row)}>
                            <H6TableCompany sx={{ color: themeColor.palette.BrandColor }} >
                              {row?.company?.company_type === "NGO" || row?.company?.company_type === "OTHERS" || row?.company?.company_type === "TRUST" || row?.company?.company_type === "PROPRIETOR" || row?.company?.company_type === "FOREIGN ENTITY" ? `${row?.company?.company_name} (${row?.company?.company_type})` : `${row?.company?.company_name} ${row?.company?.company_type}`}
                            </H6TableCompany>
                          </TableCell> */}
                          <TableCell align="center" sx={{ padding: '3px' }}>
                            <T6TableText>
                              {row?.contact?.first_name} {row?.contact?.last_name}
                            </T6TableText>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '3px', maxWidth: 150}}>
                            <T6TableText>
                              {row?.created_by?.firstname} {row?.created_by?.lastname}
                            </T6TableText>
                          </TableCell>
                          <TableCell align="center" sx={{padding: '3px', fontSize: '12px', maxWidth: 150}}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '4px', whiteSpace: 'pre-line', flex: 1}}>
                                  <T6TableText>
                                    {`${row?.assigned_to?.firstname} ${row?.assigned_to?.lastname}`} 
                                  </T6TableText>
                                  </span>
                                {/* <span style={{whiteSpace: 'pre-line', flex: 1 }}>{row?.assigned_to?.lastname}</span>   ":hover":(row?.opportunity_stage ==='ClosedWon' || row?.opportunity_stage === 'ClosedLost' || row?.opportunity_stage ==="Closed-No-Action") ? 'default':'pointer' */}
                                {/* <span style={{flexShrink: 0}}>
                                  {(checkAdminRole && permissionsDetails?.perm_assign_edit) || permissionsDetails?.perm_assign_edit ? <>
                                    <Tooltip title="Assign Opportunity to" placement="top-start">
                                      <IconButton onClick={()=> (row?.opportunity_stage ==='ClosedWon' || row?.opportunity_stage === 'ClosedLost' || row?.opportunity_stage ==="Closed-No-Action") ? '' : handleAssign(row)}>
                                        {row?.opportunity_stage ==='ClosedWon' || row?.opportunity_stage === 'ClosedLost' || row?.opportunity_stage ==="Closed-No-Action" ? <DisableAssignIcon/>: <AssignIcon /> }
                                      </IconButton> 
                                    </Tooltip></>: " "}
                                </span> */}
                              </div>
                            </TableCell>   
                          <TableCell align="center" sx={{ padding: '3px' }}>
                            <T6TableText>
                              {row?.service_provider}
                            </T6TableText>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '3px' }}>
                            <T6TableText>
                              {row?.product}
                            </T6TableText>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '3px' }}>
                            <T6TableText>
                              {row?.opportunity_type}
                            </T6TableText>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '3px' }}>
                          <T6TableText>
                            {moment(row?.createdAt).format('D/M/YYYY')}
                            </T6TableText>
                            </TableCell>
                          <TableCell align="center" sx={{ padding: '3px', height:'40px', color:(row?.opportunity_stage)==='ClosedLost'? '#E70707':(row?.opportunity_stage)==='ClosedWon'? '#088F05':'#3B8AFF' }}>
                          <T6TableText sx={{fontWeight:600, fontSize:'14px'}}>
                            {row?.opportunity_stage}
                            </T6TableText>
                            </TableCell>
                            
                          {/* sx={{color:(( row?.status =="Identified" || "Qualified" || "Solution") ? "#FFC700" : 
                                  ("Closed Won" )? "#049E01" : "#3C8AFF" )}} */}

                          {/* {
                            permissionsDetails?.perm_opportunity_edit ? <>
                              <TableCell align="center" sx={{ padding: '3px', fontSize: '14px' }}>
                                <IconButton onClick={() => handleClickOpen(row)}>
                                  <EditIcon w={25} h={25} />
                                </IconButton>
                              </TableCell> </> : <>
                            </>
                          } */}

                          {/*  Permissions for assign button  */}
                          {
                            // permissionsDetails?.perm_assign_edit ? <><TableCell align="center" sx={{ padding: '3px', fontSize: '14px' }}>
                            //   <CustemBtn
                            //     sx={{
                            //       backgroundColor: '#FFC700', color: 'white', borderRadius: '20px',
                            //       "&:hover": {
                            //         backgroundColor: "#FFC700"
                            //       }
                            //     }} variant='contained' text={row?.approval_status === "Approved" && (row?.opportunity_stage === "Closed-Lost" || row?.opportunity_stage === "Closed-Won") ? 'Assigned' : 'Assign'} onClick={() => assignOpportunity(row)} disabled={row?.approval_status === "Approved" && (row?.opportunity_stage === "Closed-Lost" || row?.opportunity_stage === "Closed-Won")}
                            //   />
                            // </TableCell>
                            // </> : <></>
                          }

                          {/*  Permissions for approve button  */}
                          {/* <TableCell align="center" sx={{padding:'3px', fontSize:'14px'}}> 
                                {permissionsDetails?.perm_approve_edit ? <><CustemBtn sx={{backgroundColor:'green',color:'white',borderRadius:'20px',
                                        "&:hover": {
                                          backgroundColor:"green"
                                      }}} variant='contained' text={row?.approval_status ==="Approved" || ((row?.opportunity_stage ==="Closed-Lost" && row?.approval_status ==="Approved") || ( row?.approval_status ==="Approved" && row?.opportunity_stage ==="Closed-Won"))? 'Approved': 'Approve'} disabled={(row?.approval_status ==="Approved" || row?.approval_status ==="Rejected") || (( row?.approval_status ==="Approved" && row?.opportunity_stage ==="Closed-Lost")|| (row?.approval_status ==="Approved" && row?.opportunity_stage ==="Closed-Won"))} onClick={()=> approveOpportunity(row)}/></>:<>
                                  {row?.approval_status}</>}
                                </TableCell>     */}

                          {/* permission for reject button    */}
                          {/* <TableCell align="center" sx={{padding:'3px', fontSize:'14px'}}> 
                                {permissionsDetails?.perm_approve_edit ? <><CustemBtn sx={{backgroundColor:'green',color:'white',borderRadius:'20px',
                                        "&:hover": {
                                          backgroundColor:"green"
                                      }}} variant='contained' text={row?.approval_status ==="Rejected" || ((row?.opportunity_stage ==="Closed-Lost" && row?.approval_status ==="Rejected") || ( row?.approval_status ==="Approved" && row?.opportunity_stage ==="Closed-Won"))? 'Rejected': 'Reject'} disabled={(row?.approval_status ==="Rejected" || row?.approval_status ==="Approved") || (( row?.approval_status ==="Rejected" && row?.opportunity_stage ==="Closed-Lost")|| (row?.approval_status ==="Rejected" && row?.opportunity_stage ==="Closed-Won"))} onClick={()=> rejectOpportunity(row)}/></>:<>
                                  {row?.approval_status}</>}
                                </TableCell>  */}
                        </TableRow> 
                        );
                  }))}
              </TableBody>
              </>}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={opportunitylist?.recordsTotal ??0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        
        {/* <Link to='/dashboard/profile' state={state?.id} style={{textDecoration:'none'}} >
          <Typography display='flex' justifyContent='start' sx={{color:'#000000', fontWeight:600, fontSize:'13px', letterSpacing:0.5, mt:3,textAlign:'start'}}>
            <LessThanIcon/> Back to Company Profile
          </Typography>
        </Link> */}

          </CardBody>
      

      {openAssignModal && <AssignModal  taskNumber={opportunityAssigned?.opportunity_no} title={opportunityName} taskAssignedFromUser={opportunityAssigned} setUpdate={setUpdate} update={update} openAssignModal={openAssignModal} setOpenAssignModal= {setOpenAssignModal} saveAssignedManagerOrExecutiveName={saveAssignedManagerOrExecutiveName}/>}
    </>
  );
}
