import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import { IconButton, Snackbar, Tooltip, Typography } from '@mui/material';
import { DeleteIcon, EditIcon, Navbar } from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect,useState } from 'react';
import { getuserlistthunk,useractivestatusthunk,userdeletethunk } from '../../Redux/Thunk/createuserthunk';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { headCells } from './headcells';
import PopperFilter from '../../components/custom/PopperFilter';
import FilterCloseIcon from '../../components/custom/FilterCloseIcon';
import { getAllRoles } from '../../Redux/Thunk/createrolethunk';
import swal from 'sweetalert';
import TableListSearch from '../../components/custom/TableListSearch';
import TableListTitle from '../../components/custom/TableListTitle'
import { TailSpin} from 'react-loader-spinner';
import moment from 'moment';
import { CheckCircle } from '@mui/icons-material';
import { H6TableCompany, H7TablePrimeContent, T6TableText } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor';
import AdminRole from '../../adminrole/Adminrole';

const Item = styled('div')({
  display:'flex',
  justifyContent:'center'
})

const CardBody = styled('div')({
  //  backgroundColor:'#F0EFFD',
  //  padding:'10px',
  //  borderRadius:'10px',
})

const Search = styled('div')({
  display:'flex',
  padding:'0px 10px',
  borderRadius:'8px',
  justifyItems:'center',
  border:'1px solid #B0AEFF ',
  flexDirection:'row',
  backgroundColor:'#ffffff',
})

export default function UserList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {user} = useSelector((state) => state?.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const login_id = user?.data?.user?.id
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const getuserlist = useSelector((state) => state.user.getuserlist)
  //console.log(getuserlist)
  // const { getuserlist } = useSelector((state)=>state?.user)
  //const [getuserlist, setGetUserList] = useState([])
  const { getallroles } = useSelector ((state) => state?.role)
  const [order, setOrder] = React.useState('ASC');
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [filterRole, setFilterRole ] = React.useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const allroles = getallroles?.filter((role)=> role.role !='god').map((role)=>{return {name: role?.role}})
  const [loader, setLoader] = useState(false)
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const Title= "User List"
  const tooltipTitle = "New User"
  const placeholderTitle = "Search User"

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    console.log(newPlacement)
    setOpenopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
 
  useEffect(() => {
    dispatch(getAllRoles())
  },[])
 
  useEffect(() => {
    getuserdata()
  },[update, order])

 function getuserdata(){
     open ? setLoader(false): setLoader(true)
     dispatch(getuserlistthunk({start:page*rowsPerPage, length:rowsPerPage , search:search, filterRole:filterRole, col_sort:orderBy.split(' ').join(''), order:order.toUpperCase() })).then((res)=>{
     // setGetUserList(res?.payload) 
     open ? setLoader(false): setLoader(false)
     setOpen(false)
    })
 }

 const handleChangePage = (event, newPage) => {
  setPage(newPage)
  setUpdate(!update)
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
  setUpdate(!update)
};

const handlecreate=()=>{
  navigate('/dashboard/user')
}

const handleClickEditUser = (userDetails) => {
  // if(userDetails?.enabled == false ){
  //   sweetalert('error', " User is Disabled")
  // }else{
    navigate('/dashboard/user', {state: userDetails})
  // } 
};

const handleUserStatus=(userDetails)=>{
  swal({
    buttons: {
      Cancel: {
        text: "Cancel",
        value: "Cancel",
        className: "popup-blue",
      },
      Delete: {
        text: "Ok",
        value: "Ok",
        className: "popup-gray",
      },
    },
    title: `Do You Want to ${userDetails?.enabled === false ?"Enable" : "Disable"} ${userDetails?.firstname} ${userDetails?.lastname} ?`,
    className: "custom-modal-user",
    closeOnClickOutside: false,
  }).then((value)=>{
    if(value=='Ok'){
      dispatch(useractivestatusthunk({userid: userDetails.id, set_user_status:!userDetails.enabled})).then((res)=>{
        setOpen(true)
        setUpdate(!update)
      })
    }else{
     console.log('helllo')
    }
  })  
}

const sweetalert = (variant, text) => {
  swal({
    icon: variant==='error' ? 'error' :'success',
    title: text , 
    button: "Ok!",
  }); 
};

// const handleClickDelete=(userDetails)=>{
//   swal({
//     buttons: {
//       Cancel: {
//         text: "Cancel",
//         value: "Cancel",
//         className: "popup-blue",
//       },
//       Delete: {
//         text: "Ok",
//         value: "Ok",
//         className: "popup-gray",
//       },
//     },
//     title: `Do You Want to Delete of ${userDetails?.firstname} ${userDetails?.lastname} ?`,
//     className: "custom-modal-user",
//     closeOnClickOutside: false,
//   }).then((value)=>{
//     if(value=='Ok'){
//       dispatch(userdeletethunk({userid:userDetails.id})).then((res)=>{
//         setUpdate(!update)
//       })
//     }else{
//       navigate('/dashboard/userlist')
//     }
//   }) 
// }

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
 
  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            sx ={{ fontWeight:700, fontSize:'13px' }}
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'normal' : 'none'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell sx ={{ fontWeight:600, fontSize:'13px', zIndex: 0, alignItems:'left'}}>Mobile No</TableCell>
        {(checkAdminRole && permissionsDetails?.perm_user_edit) || permissionsDetails?.perm_user_edit ? <><TableCell sx ={{zIndex: !openPopper ? 1 : 0, fontWeight:600, alignItems:'left', fontSize:'13px' }}>Access</TableCell> 
        <TableCell sx ={{ fontWeight:600, fontSize:'13px', zIndex: 0, alignItems:'left'}}>Edit</TableCell> </>:<></>}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};


const handleSearch = (e) => {
  setSearch (e.target.value)
  dispatch(getuserlistthunk({start:page*rowsPerPage, length:rowsPerPage , search:e.target.value, filterRole:filterRole }))
  setUpdate(!update)
}
 
const handleFilter = (e)=> {
  if(e.target.value === "All Roles"){
    setFilterRole("")
    setOpenopper(false)
    setUpdate(!update)
  }else{
    setFilterRole(e.target.value)
    setOpenopper(false)
    setUpdate(!update)
  }
  //dispatch(getuserlistthunk({start:page*rowsPerPage, length:rowsPerPage , search:search, filterRole:e.target.value })) 
}

const handleCloseFilter = () => {
  setFilterRole('')
  setUpdate(!update)
}

const handleCopyEmail = (email) => {
  navigator.clipboard.writeText(email)
    .then(() => {
      setSnackbarOpen(true)
    })
    .catch((error) => {
      setSnackbarOpen(false)
    });
}

const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getuserlist?.data?.length) : 0;
 
  return (
    <> 
    <PopperFilter filtervalues ={[{name:"All Roles"}, ...allroles]} open={openPopper} anchorEl={anchorEl} placement={placement} onClick={handleFilter} />
    <Grid container display= 'flex' alignItems="center" alignContent='center' justifyContent="space-between" sx={{ borderBottom: '1px solid #e4e4e4', mt:1}}>
       {/* User Title */}
        <TableListTitle title={Title} />

     {/* Create new User, search bar and filter  */}
        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mb:0.5 }}>
          <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} permissionsDetails={(checkAdminRole && permissionsDetails?.perm_user_create) || permissionsDetails?.perm_user_create} onClick ={handlecreate} onchange={handleSearch} />    
            <Tooltip title="Filter By Role" placement="bottom-start">
              <IconButton onClick={handleClick("bottom-start")} sx={{mr: {lg:4, xs:1}}}>
                {!openPopper ? <FilterIcon /> : <FilterCloseIcon />}
              </IconButton>
            </Tooltip>
        </Grid>
      </Grid>
      {
      filterRole ? <Box sx={{ml:4, display:'flex', justifyContent:'space-between', fontSize:'10px',width:'25%',p:0.5,mt:0.5, border:'1px solid black', borderRadius:'10px'}}>
        <Typography sx={{fontSize:'12px'}}>
          Showing Leads for status  <span style={{color:'blue', marginLeft:'10px'}}>{filterRole}</span> 
        </Typography>
        <Typography onClick={handleCloseFilter} sx={{color:"red", fontSize:'12px', cursor:'pointer', fontWeight:600, mr:0.5}}>
          Clear Filter X
        </Typography>
        </Box>:""
    }

    <CardBody sx={{mt:1, ml:{xs:1, sm:1, xl:4, lg:4}, mr:{xs:1, sm:1, xl:4, lg:4}}}>
      <Paper sx={{padding:'10px'}}>

      <TableContainer sx={{ maxHeight: 440}}>
          <Table
            sx={{ minWidth: 750 }}
            size= 'small'
            stickyHeader aria-label="sticky table">   
            
            <EnhancedTableHead
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={getuserlist?.data?.length}
            />
            { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                <TailSpin
                  height="30"
                  width="30"
                  color="blue"
                  ariaLabel="tail-spin-loading"
                  radius="3"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                /> </Box>:<>
                  <TableBody>
                  <>
                  {getuserlist?.data?.length === 0 ? <TableRow>
                      <TableCell colSpan={8} style={{ textAlign: 'center', color: 'red', fontWeight: 600 }}>
                        No User available
                      </TableCell>
                    </TableRow> : (  // user.id != login_id && 
                      getuserlist?.data?.filter((user) => user?.role?.role?.toLowerCase() != 'god').map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row?.name}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              align='left'
                              sx={{padding:'3px', fontSize:'14px', fontWeight:600, maxWidth:120}}
                            >
                              <H7TablePrimeContent>
                                {row?.firstname} {row?.lastname}
                              </H7TablePrimeContent>
                            </TableCell>
                            
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>
                              <H7TablePrimeContent>
                                {row?.role?.role}
                              </H7TablePrimeContent>
                            </TableCell>
                              
                              <TableCell align="left" sx={{padding:'3px', fontSize:'12px', maxWidth:'120'}}>
                                <H7TablePrimeContent>
                                  {row?.reporting_to ? `${row?.reporting_to?.firstname} ${row?.reporting_to?.lastname}` : 'NA'}
                                </H7TablePrimeContent>
                              </TableCell>
                            
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}>
                              <T6TableText sx={{color:themeColor.palette.Primarytext1}}>
                                {moment(row?.createdAt).format('D/M/YYYY')}
                              </T6TableText>
                            </TableCell>

                            {/* <TableCell align="left" sx={{padding:'3px', fontSize:'14px'}}>{row?.designation}</TableCell> */}
                            <TableCell align="left" sx={{padding:'3px', fontSize:'12px', maxWidth:100}}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '4px', whiteSpace: 'pre-line', wordBreak: 'break-all', flex: 1 }}>
                                  <T6TableText sx={{color:themeColor.palette.Primarytext1}}>
                                  {row?.email}
                                  </T6TableText>
                                </span>
                                  <span style={{marginRight:'6px'}}>
                                    <Tooltip title="Copy Email" placement="top-start">
                                      <IconButton onClick={()=> handleCopyEmail(row?.email)}>
                                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M3.41653 2.99967V0.999674C3.41653 0.631488 3.71501 0.333008 4.0832 0.333008H12.0832C12.4514 0.333008 12.7499 0.631488 12.7499 0.999674V10.333C12.7499 10.7012 12.4514 10.9997 12.0832 10.9997H10.0832V12.9991C10.0832 13.3676 9.78327 13.6663 9.412 13.6663H1.42111C1.05039 13.6663 0.75 13.3699 0.75 12.9991L0.751733 3.66692C0.7518 3.29841 1.05176 2.99967 1.42295 2.99967H3.41653ZM2.08495 4.33301L2.08346 12.333H8.74987V4.33301H2.08495ZM4.74987 2.99967H10.0832V9.66634H11.4165V1.66634H4.74987V2.99967Z" fill="#676666"/>
                                        </svg>
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                  </div>
                            </TableCell>
                            <TableCell align="left"sx={{padding:'3px', fontSize:'12px', pl:2}}>
                              <T6TableText sx={{color:themeColor.palette.Primarytext1}}>
                                {row?.primary_phone}
                              </T6TableText>
                            </TableCell>  
                            {(checkAdminRole && permissionsDetails?.perm_user_edit) || permissionsDetails?.perm_user_edit ? <>
                            {/* row.enabled== true means disable and false means enable */}
                              <TableCell align="left" sx={{padding:'3px', fontSize:'12px'}}><CustemBtn variant="contained" onClick={()=>handleUserStatus(row)} 
                              text={row.enabled?'Enabled':'Disabled'}  sx={{color:"#FFFFFF",backgroundColor: row.enabled== true ? themeColor.palette.TableButton :"#7D7D7D", borderRadius:'20px',pl:2, pr:2,
                              "&:hover": {
                                backgroundColor:row.enabled== true ? themeColor.palette.TableButton :"#7D7D7D"
                              }
                              }}
                              /></TableCell>
                              <TableCell align="left"> 
                                <Typography onClick={()=>handleClickEditUser(row)} >
                                    <EditIcon w={18} h={18} />
                                  </Typography>  
                              </TableCell>
                            </> :<></>}
                          </TableRow>
                        );
                      }))}
                    </>
                  </TableBody>
             </>}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={getuserlist?.recordsTotal ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </CardBody>

    {/* Display Msg*/}
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000} 
        onClose={() => setSnackbarOpen(false)}
        message="Email copied"
        action={
          <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
            <CheckCircle />
          </IconButton>
        }
    />

    {/* edit user popup */}
    {/* <EditUser open={open} setOpen={setOpen} state={state} setState={setState} update={update} setUpdate={setUpdate} /> */}

    {/* <Box sx={{display:'flex',justifyContent:'center',p:5}}>
       <PaginationFooter/>
    </Box> */}
    </>
  );
}
