import React, { useState } from 'react'
import { H7TablePrimeContent, T2popupSubText } from '../../Theme/theme'
import { Box, CircularProgress, Grid, Typography, Button, styled, Tooltip} from '@mui/material'
import { InputBox, SelectDropDown, TextArea } from '../../components/custom'
import themeColor from '../../Theme/themeColor'
import { BillingFrequency, Months, BillBy, BillType } from '../Opportunity/OpportunityValues'
import swal from 'sweetalert'
import { createcommercials } from '../../Redux/Thunk/createopportunitythunk'
import { useDispatch, useSelector } from 'react-redux'
import { EditCommercial, Identified, QualifiedPending, Rejected } from './Icons'
import { ActionButtons, ApproveBtn, IFChild1, IFChild2, IFIconBG, IFParent, RejectBtn } from './NewOppoDetailscss';
import DisplayError from '../../validation/DisplayError';
import validate from "../../validation/validation";


const CardInfo = styled('div')({
    display:'flex',
  })

const CreateCommercials = ({ opportunity_id, For, commercials, editOpen, name, status, opp_stage, openCommercial, OpenCommercial}) => {
  
    

    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user.role.permissions
    const [error, setError] = useState([]);
    const [openEditButton, setOpenEditButton]= useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [reqremark, setReqRemark] = useState('');
    const [opportunityDetails, setOpportunityDetails] = useState({
        otc: commercials?.createdAt ? commercials?.otc : '',
        arc: commercials?.createdAt ? commercials?.arc : '',
        months: commercials?.createdAt ? commercials?.months : '',
        bill_frequency: commercials?.createdAt ? commercials?.bill_frequency : '',
        billed_by: status?.commercials ? status?.commercials?.billed_by : commercials?.createdAt ? commercials?.billed_by : '',
        bill_type: commercials?.createdAt ? commercials?.bill_type : '',
    })

    const handleOfCreateOpportunityDetails = (event) => {
        setOpportunityDetails({ ...opportunityDetails, [event.target.name]: event.target.value })
    }

    let validation_input = [
        {tagid: 'otc', text:opportunityDetails?.otc, regex_name: 'min_number',min:1, required: true, errmsg: 'Please Enter One Time Charges.'},
        {tagid: 'arc', text:opportunityDetails?.arc, regex_name: 'min_number',min:1, required: true, errmsg: 'Please Enter Recurring Charges.'},
        {tagid: 'months', text:opportunityDetails?.months, regex_name:'min_number', min:1, errmsg: 'Please Select Contract(period).', required: true},
        { tagid: 'bill_frequency', text: opportunityDetails?.bill_frequency, regex_name: 'text', required: true, errmsg: 'Please Select Bill Frequency.' },
        { tagid: 'bill_type', text: opportunityDetails?.bill_type, regex_name: 'text', required: true, errmsg: 'Please Select Type.' },
        { tagid: 'billed_by', text: opportunityDetails?.billed_by, regex_name: 'text', required: true, errmsg: 'Please Select Billed By.' },
        For == 'reject' ?  {tagid: 'reqremark', text: reqremark, required: true, regex_name: 'free_text', errmsg: 'Please add the request remark between 30 to 1000 characters.', min:30, max:1000 } : '',

    ]

    const handleCreateCommercials = () => {
        if (validate(validation_input).length == 0) {
            setDisabledButton(true)
            opportunityDetails.opportunity_id = opportunity_id;
            opportunityDetails.req_remark = reqremark
            dispatch(createcommercials(opportunityDetails)).then((res) => {
                if (res.payload.errormsg) {
                    setDisabledButton(false)
                    sweetalert('error', res.payload.errormsg)
                } else {
                    sweetalert('success', res.payload.data)
                    setOpenEditButton(true)
                    setDisabledButton(false)
                }
            })
        } else {
            setError(validate(validation_input))
        }
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant === 'error' ? 'error' : 'success',
            title: text,
            button: "Ok!",
        });
    };

    const handleChangededitCommercials =() =>{
       
    }

    const handleRequestRemark =(e) => {
        setError([])
        setReqRemark(e.target.value)
    } 

    return (
        <Box sx={{ borderBottom: commercials?.createdAt ? '': '0.5px solid #AAAA' }}>
           
            <Box sx={{display:'flex', justifyContent:'start', flexDirection:'row'}}>
                {
                commercials?.createdAt ? '': <>
                <IFChild1>
                <IFIconBG>
                <QualifiedPending />
                    {/* {status?.approval_status == 'Rejected'? <Rejected /> : status?.approval_status == 'Pending'? <QualifiedPending /> :<Identified />} */}
                </IFIconBG>
                </IFChild1>
                <T2popupSubText sx={{color: themeColor.palette.primarytext2}}>
                    Commercials
                </T2popupSubText>
                </>
            }
            {/* {(!permissionsDetails.perm_approve_edit && name == 'EditIcon') ? <Box sx={{display:'flex', ml:'280px'}}>
                        <IFIconBG onClick={handleChangededitCommercials}>
                        <EditCommercial/>
                        </IFIconBG>
                    </Box> :''} */}
                </Box>
           
            <Box sx={{mt:1}}>
                <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3}}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight: 600 }}>
                            One Time Charges (Rs)<span style={{ color: 'red' }}>*</span>
                        </H7TablePrimeContent >
                        <InputBox disable={commercials?.createdAt} name='otc' value={opportunityDetails?.otc} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="1000" />
                        <DisplayError error={error} fortagid='otc' />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6} sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                        <Tooltip title='Annual Recurring Charges (Rs)' placement = "top-start">
                            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight: 600, maxWidth: '230px', 
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                ":hover":{cursor:'pointer'},
                                "&:hover": {
                                    maxWidth: 'none', 
                                } }}>
                                Annual Recurring Charges (Rs)<span style={{ color: 'red' }}>*</span>
                            </H7TablePrimeContent>
                        </Tooltip>
                        <InputBox disable={commercials?.createdAt} name='arc' value={opportunityDetails?.arc} onchange={handleOfCreateOpportunityDetails} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder="1000" />
                        <DisplayError error={error} fortagid='arc' />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
                <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight: 600 }}>
                            Contract Period(months)<span style={{ color: 'red' }}>*</span>
                        </H7TablePrimeContent>
                        <SelectDropDown
                            disabled={commercials?.createdAt}
                            placeholder='--Select months--'
                            name='months'
                            options={Months}
                            onchange={handleOfCreateOpportunityDetails}
                            value={opportunityDetails?.months}
                            // label='Select industry'
                            sx={{ p: 0.1, mt: 1, pl: 1, borderRadius: "10px" }}
                        />
                        <DisplayError error={error} fortagid='months' />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight: 600 }}>
                            Billing Frequency<span style={{ color: 'red' }}>*</span>
                        </H7TablePrimeContent>
                        <SelectDropDown
                            disabled={commercials?.createdAt}
                            placeholder='--Select Frequency--'
                            name='bill_frequency'
                            options={BillingFrequency}
                            onchange={handleOfCreateOpportunityDetails}
                            value={opportunityDetails?.bill_frequency}
                            // label='Select industry'
                            sx={{ p: 0.1, mt: 1, pl: 1, borderRadius: "10px" }}
                        // MenuProps={MenuProps}
                        />
                        <DisplayError error={error} fortagid='bill_frequency' />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ marginTop: '10px' }}>
                <Grid container spacing={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight: 600 }}>
                            Billed By<span style={{ color: 'red' }}>*</span>
                        </H7TablePrimeContent>
                        <SelectDropDown
                            disabled={commercials?.createdAt }   //|| status?.opportunity_type !='New'
                            placeholder='--Select Billed By--'
                            name='billed_by'
                            options={BillBy}
                            onchange={handleOfCreateOpportunityDetails}
                            value={opportunityDetails?.billed_by}
                            // label='Select industry'
                            sx={{ p: 0.1, mt: 1, pl: 1, borderRadius: "10px" }}
                        />
                        <DisplayError error={error} fortagid='billed_by' />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, fontWeight: 600 }}>
                            Billing Type<span style={{ color: 'red' }}>*</span>
                        </H7TablePrimeContent>
                        <SelectDropDown
                            disabled={commercials?.createdAt}
                            placeholder='--Select Type--'
                            name='bill_type'
                            options={BillType}
                            onchange={handleOfCreateOpportunityDetails}
                            value={opportunityDetails?.bill_type}
                            // label='Select industry'
                            sx={{ p: 0.1, mt: 1, pl: 1, borderRadius: "10px" }}
                        // MenuProps={MenuProps}
                        />
                        <DisplayError error={error} fortagid='bill_type' />
                    </Grid>

                </Grid>
            </Box>
            {
                For == 'reject' ? <>
                     <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',mt:0.5 }}>
                <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                    Request Remark<span style={{ color: 'red' }}>*</span>
                </H7TablePrimeContent>
            </Box>
            <CardInfo sx={{ pr: 1, pb: 0.2 }}>
                <TextArea onChange={handleRequestRemark} name='reqremark' value={reqremark?.replace(/\s\s+/g, " ")} style={{ fontFamily: 'Montserrat', marginTop: '5px', fontStyle: 'normal', width: '100%', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', border: '0.5px solid #B0AEFF', background: '#FEFEFE', p: 0.1, paddingLeft: '10px' }} placeholder="Write a note about reject remark."></TextArea>
            </CardInfo>
            <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginLeft: 'auto', fontSize: '12px' }}>
                <span>{reqremark.length}/1000</span>
            </div>
            <DisplayError error={error} fortagid='reqremark' />
                </>:''
            }
            {
                commercials?.createdAt ? '' : <>
                {/* <Button
                    id="validate" variant="contained" onClick={disabledButton ? '' : handleCreateCommercials}
                    sx={{
                        // backgroundColor: disabledButton ? themeColor.palette.backgroundColor :'',
                        color: themeColor.palette.BrandColor, marginBottom: '5px', marginTop: '10px',
                        backgroundColor: disabledButton ? themeColor.palette.backgroundColor :'#FFFFFF',
                        ":hover":{cursor:'pointer', boxShadow:'none', backgroundColor: disabledButton ? themeColor.palette.backgroundColor :'#FFFFFF'},
                        display: 'flex',
                        padding: '4px 0px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '6px',
                        flex: '1 0 0',
                        borderRadius: '8px',
                        border: '1px solid #3107AA',
                        boxShadow:'none',
                        textTransform: 'none', 
                    }}
                    >
                    {disabledButton ? <CircularProgress size={20} color="inherit" /> : ' Save'}
                    </Button> */}
                <ApproveBtn onClick={disabledButton ? '' : handleCreateCommercials} sx={{":hover":{cursor:'pointer'}, backgroundColor: disabledButton ? themeColor.palette.backgroundColor :'',color: themeColor.palette.BrandColor, marginBottom: '5px', marginTop: '10px' }}>
                    {disabledButton ? <CircularProgress size={20} color="inherit" /> : ' Save'}
                </ApproveBtn></>
            }

        </Box>
    )
}

export default CreateCommercials
