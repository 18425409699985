import React,{useState, useEffect} from 'react';
import { Box, Button, Card, Grid, Paper, Tooltip, Snackbar, Typography, styled, IconButton} from '@mui/material';
import { AssignIcon, CompanyIcon, CustemBtn, DisableAssignIcon, EditIcon } from '../../components/custom';
import {H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import LeadDetailsCompanyIcon from './LeadDetailsCompanyIcon';
import LeadDetailsContactIcon from './LeadDetailsContactIcon';
import UpdateStatus from './UpdateStatus';
import CreateFollowup from './CreateFollowup';
import { leadAssignedToUser, leadValidate, leaddetails } from '../../Redux/Thunk/createleadthunk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin} from 'react-loader-spinner';
import ThermometerIcon from '../../components/custom/ThermometerIcon';
import moment from 'moment';
import { CheckCircle } from '@mui/icons-material';
import { displayCompanyDetails } from '../../Redux/Thunk/createcompanythunk';
import { getContactList } from '../../Redux/Thunk/createcontact';
import swal from 'sweetalert';
import ContactForm from '../ContactForm/ContactForm';
import AssignModal from '../../components/custom/AssignModal';
import CallIcon from '../../components/custom/CallIcon';
import EmailIcon from '../../components/custom/EmailIcon';
import CompanyNameDetails from '../../components/custom/CompanyNameDetails';
import DisableEditIcon from '../../components/custom/DisableEditIcon';
import ContactNameDetails from '../../components/custom/ContactNameDetails';
import OpportunityForm from '../Opportunity/OpportunityForm';
import AdminRole from '../../adminrole/Adminrole';
import { getresettime, sendreminder } from '../../Redux/Thunk/reminders';
import ReminderIcon from '../../components/custom/ReminderIcon';
import { getServiceProviderTypesList } from '../../Redux/Thunk/createsettinglist';

const LoginContainer = styled('div')({
    minHeight:'100vh',
    overflowY: 'auto',
    // marginTop: "0.2px",
    // marginBottom:'0.2px',
     marginRight:'15px',
    // padding: "10px",
    // background: '#F9F9F9',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
})

const CardBody = styled('div')({
    backgroundColor:'#FFFFFF',
    padding:'10px',
    borderRadius:'10px',
})

const ChildCard = styled('div')({
    backgroundColor:'#FFFFFF',
    borderRadius:'10px',
})

const CardInfo = styled('div')({
    display:'flex',
    flexWrap:'wrap',
    justifyContent:'space-between',  
})
const ActionButtons = styled(Typography)(({ theme }) => ({
    display: 'flex',
    padding: '4px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    flex: '1 0 0',
    marginTop:'5px'
}));

const ApproveBtn = styled(Typography)(({ theme }) => ({
    display: 'flex',
    padding: '4px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    flex: '1 0 0',
    borderRadius: '8px',
    border: '1px solid #3107AA'
}));

const RejectBtn = styled(Typography)(({ theme }) => ({
    display: 'flex',
    padding: '4px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    flex: '1 0 0',
    borderRadius: '8px',
    border: '1px solid #E70707'
}));

export default function NewLeadDetails() {
   const {state} = useLocation()
   const {user} = useSelector((state) => state.auth)
   const permissionsDetails = user?.data?.user?.role?.permissions
   const navigate = useNavigate()
   const dispatch = useDispatch()
   const {updateCreateEditFollowup} = useSelector((state) => state.settingList)
   const {updateContact} = useSelector((state) => state.contact)
   const [loader, setLoader] = useState(state?.loader ?state?.loader :true)
   const [displayLeadDetails, setDisplayLeadDetails] = useState([])
   const [openContactModel, setOpenContactModel] = useState(false)
   const [editContactDetails, setEditContactDetails] = React.useState({});
   const [snackbarOpen, setSnackbarOpen] = useState(false)
   const [update, setUpdate] = useState(false)
   const [leadAssigned, setLeadAssigned]= useState({})
   const [openAssignModal,setOpenAssignModal] = useState(false)
   const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
   const [resettime, setResetTime] = useState('')
   const [disable, setDisable] = useState(false)
   
//    const user_ids = [user?.data?.user?.id]
   const [user_ids, setUser_ids] = useState([])
   const title ="LeadDetailsPage"
   const leadName = 'Lead'

    useEffect(()=>{
        if(!state || state === 'undefined') {
            navigate('/dashboard')
            return
          } else {
            setLoader(true)
            dispatch(leaddetails(state?.leadDetails?.id)).then((res)=>{
                setDisplayLeadDetails(res?.payload)
                setUser_ids(res?.payload?.all_ids)
                setLoader(false)
           }) 
        }
       },[update, updateCreateEditFollowup, updateContact, state])

    const handleClickCompanyWebsite = (website)=>{
        if (website?.length > 0) {
          window.open(website, '_blank');
        }
    }

    const handleClickEditLeadDetails = () => {
        dispatch(getServiceProviderTypesList())
        dispatch(displayCompanyDetails(displayLeadDetails?.leaddetails?.company?.id))
        getContactList(displayLeadDetails?.leaddetails?.company?.id)
        navigate('/dashboard/lead', {state: {lead :displayLeadDetails?.leaddetails, operation:'editLead', title:title}})  
    };

    const handleClickEditContact = (contact) => {
        setOpenContactModel(true)
        setEditContactDetails(contact)
    };

    const findIds= (id1, id2) => {
        if (user_ids.includes(id1) || user_ids.includes(id2)) {
          return true 
        } else {
          return false
        }
    }

    useEffect(() => {
        // setLoader(true)
        if(displayLeadDetails?.leaddetails?.id) {
             dispatch(getresettime({id :displayLeadDetails?.leaddetails?.id, stage : "Lead"})).then((res) => {
            setResetTime(res.payload.data)
        }) 
        }
      
    }, [displayLeadDetails?.leaddetails?.id])

    const handleReminder = (id) => {
        setDisable(true)
        dispatch(sendreminder({lead_id : id, stage:'Lead'})).then((res) => {
            if (res.payload.errormsg) {
               return sweetalert('error', res.payload.errormsg)
            } else {
               sweetalert('success', res.payload.data)
               dispatch(getresettime({id :displayLeadDetails?.leaddetails?.id, stage : "Lead"})).then((res) => {
                   setResetTime(res.payload.data)
               }) 
               
            }
        })
       }

    const handleValidate =(lead)=>{
        swal({
          buttons: {
              Cancel: {
                  text: "No",
                  value: "cancel",
                  className: "popup-gray",
              },
              Delete: {
                  text: "Yes",
                  value: "yes",
                  className: "popup-blue",
              },
          },
          title:  `Do You Want to Validate the Lead (${lead?.lead_no}) ?`,
          className: "custom-modal",
          closeOnClickOutside: false,
        }).then((value) => {
            if (value == 'yes') {
              dispatch(leadValidate({lead_id:lead?.id})).then((res)=>{
                if(res?.payload?.errormsg) {
                 return sweetalert('error', res?.payload?.errormsg)
                }    
                sweetalert('success', res?.payload?.data)         
                setUpdate(!update)
              })
            } else {
              console.log('hello')
            }
        })  
    }

    const sweetalert = (variant, text) => {
        swal({
          icon: variant==='error' ? 'error' :'success',
          title: text , 
          button: "Ok!",
        }); 
    }

    const handleAssign = () => {
        setLeadAssigned(displayLeadDetails?.leaddetails)
        setOpenAssignModal(true)
    }
    
    const handleChangeLeadNotAssign =()=>{
        sweetalert('error', "You have no permissions to access this. Please contact your administrator for more information.")
    }

    const saveAssignedManagerOrExecutiveName = (managerList, selectManagerName)=>{
        if(selectManagerName ===''){
          sweetalert ('error', "Please Select Name") 
        }else{
            let findUser= managerList.find((item)=> `${item?.firstname} ${item?.lastname}` === selectManagerName)
            dispatch(leadAssignedToUser({lead_id:leadAssigned?.id, user_id:findUser?.id})).then((res)=>{
            sweetalert ('success', res.payload.data) 
            setOpenAssignModal(false)
            setUpdate(!update)
           })
        }
    }

   

    return(
        <>
        { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
        <TailSpin
          height="30"
          width="30"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        /> </Box>:<>
        <LoginContainer>
            {/* Display Image */}
            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                {/* Grid Item 1 */}
                <Grid item xs={12} md={10} sm={10} lg={4}>
                    {/* <CardBody sx={{ mr: { xs: 1, sm: 1, xl: 2, lg: 2 } }}> */}
                        <Grid sx={{ pl:1, mb: 1, pr:1, display: 'flex', justifyContent: 'space-between', mt:2, alignItems: 'flex-end' }}>
                            <Grid item xs={8}>
                                {/* Thermometer Icon */}
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={displayLeadDetails?.leaddetails?.type === "Hot"? "Hot": displayLeadDetails?.leaddetails?.type ==="Cold" ? "Cold" :"Warm"} placement="top-start">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
                                    <path d="M6.97674 11.3023V2.32558C6.97674 1.02326 5.95349 0 4.65116 0C3.34884 0 2.32558 1.02326 2.32558 2.32558V11.3023C0.930233 12.093 0 13.6279 0 15.3488C0 17.907 2.09302 20 4.65116 20C7.2093 20 9.30233 17.907 9.30233 15.3488C9.30233 13.6279 8.37209 12.1395 6.97674 11.3023Z" fill={displayLeadDetails?.leaddetails?.type === "Hot" ? "#F16500" : displayLeadDetails?.leaddetails?.type === "Cold" ? "#B0AEFF" : "#FFC700"} fill-opacity="0.3"/>
                                    <path d="M4.6523 18.6038C2.83834 18.6038 1.39648 17.162 1.39648 15.348C1.39648 14.1852 2.00114 13.1155 3.02439 12.5108L3.72207 12.0922V2.32476C3.72207 1.81314 4.14067 1.39453 4.6523 1.39453C5.16393 1.39453 5.58253 1.81314 5.58253 2.32476V12.1387L6.28021 12.5573C7.30346 13.162 7.90811 14.2317 7.90811 15.3945C7.90811 17.162 6.46625 18.6038 4.6523 18.6038Z" fill={displayLeadDetails?.leaddetails?.type === "Hot" ? "#F16500" : displayLeadDetails?.leaddetails?.type === "Cold" ? "#B0AEFF" : "#FFC700"}/>
                                    <path d="M6.32644 12.6504C6.65203 13.162 6.88458 13.7667 6.88458 14.4178C6.88458 16.2318 5.44272 17.6736 3.62877 17.6736C2.97761 17.6736 2.37296 17.4876 1.86133 17.1155C2.46598 17.9992 3.44272 18.6039 4.559 18.6039C6.37296 18.6039 7.81482 17.162 7.81482 15.3481C7.81482 14.2318 7.21016 13.2085 6.32644 12.6504Z" fill={displayLeadDetails?.leaddetails?.type === "Hot" ? "#F16500" : displayLeadDetails?.leaddetails?.type === "Cold" ? "#B0AEFF" : "#FFC700"}/>
                                </svg>
                                </Tooltip>
                                <span>
                                    <H3SubHeader sx={{ml:0.5, color: themeColor.palette.LeadID}}>
                                        {displayLeadDetails?.leaddetails?.lead_no}
                                    </H3SubHeader>
                                </span>
                                </div>
                            </Grid>
                            
                            <Grid sx={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end'}} item xs={6} lg={6} md={6}>
                                {(checkAdminRole && permissionsDetails?.perm_validate_edit) || (permissionsDetails?.perm_validate_edit && findIds(displayLeadDetails?.leaddetails?.assigned_to?.id))? <>
                                    {displayLeadDetails?.leaddetails?.validated_status ==="Validated" || displayLeadDetails?.leaddetails?.status ==='Converted' ? <div>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ flexShrink: 0 }}>
                                                <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.74789 5.85463C4.79433 5.90112 4.84947 5.938 4.91017 5.96316C4.97087 5.98832 5.03594 6.00128 5.10164 6.00128C5.16735 6.00128 5.23241 5.98832 5.29311 5.96316C5.35381 5.938 5.40896 5.90112 5.45539 5.85463L11.2479 0.147132C11.2943 0.100643 11.3495 0.063764 11.4102 0.0386019C11.4709 0.0134397 11.5359 0.000488281 11.6016 0.000488281C11.6674 0.000488281 11.7324 0.0134397 11.7931 0.0386019C11.8538 0.063764 11.909 0.100643 11.9554 0.147132L13.4554 1.62213C13.5019 1.66857 13.5388 1.72371 13.5639 1.78441C13.5891 1.84511 13.602 1.91017 13.602 1.97588C13.602 2.04159 13.5891 2.10665 13.5639 2.16735C13.5388 2.22805 13.5019 2.2832 13.4554 2.32963L5.43039 10.3546C5.38396 10.4011 5.32881 10.438 5.26811 10.4632C5.20741 10.4883 5.14235 10.5013 5.07664 10.5013C5.01094 10.5013 4.94587 10.4883 4.88517 10.4632C4.82447 10.438 4.76933 10.4011 4.72289 10.3546L0.247893 5.85463C0.154196 5.76087 0.101562 5.63375 0.101562 5.50119C0.101562 5.36864 0.154196 5.24152 0.247893 5.14776L1.74789 3.64776C1.79433 3.60127 1.84947 3.56439 1.91017 3.53923C1.97087 3.51407 2.03594 3.50111 2.10164 3.50111C2.16735 3.50111 2.23241 3.51407 2.29311 3.53923C2.35381 3.56439 2.40896 3.60127 2.45539 3.64776L4.74789 5.85463Z" fill="#03A500"/>
                                                </svg>
                                            </span>   
                                                <T5Normaltext sx={{flex:1, color:'#03A500', fontWeight:600}}>Validated</T5Normaltext>
                                         </span>                                
                                        </div> :<> {
                                           (displayLeadDetails?.leaddetails?.validated_status !== "Validated" ) ? <CustemBtn id='validate' sx={{fontSize:'12px', mb: 0.1, padding: '1px 20px',
                                            alignItems: 'center', gap: '2px', fontWeight: 600, backgroundColor:'#ED9C00', color:'#FFFFFF', border:'1px solid #FFFFFF', borderRadius:'20px',
                                                "&:hover": {
                                                    backgroundColor:"#ED9C00"
                                                }}} variant='contained' text='Validate' disabled={(displayLeadDetails?.leaddetails?.status ==="Closed-No-Action" || displayLeadDetails?.leaddetails?.status ==='Lost')} onClick={()=>handleValidate(displayLeadDetails?.leaddetails)}/> : <T5Normaltext sx={{flex:1, color:'#ED9C00', fontWeight:600}}>Pending Validation</T5Normaltext>
                                                
                                            }
                                            
                                        </> }</> :<>
                                       
                                        {displayLeadDetails?.leaddetails?.validated_status ==="Validated" || displayLeadDetails?.leaddetails?.status ==='Converted' ? <div>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ flexShrink: 0 }}>
                                                <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.74789 5.85463C4.79433 5.90112 4.84947 5.938 4.91017 5.96316C4.97087 5.98832 5.03594 6.00128 5.10164 6.00128C5.16735 6.00128 5.23241 5.98832 5.29311 5.96316C5.35381 5.938 5.40896 5.90112 5.45539 5.85463L11.2479 0.147132C11.2943 0.100643 11.3495 0.063764 11.4102 0.0386019C11.4709 0.0134397 11.5359 0.000488281 11.6016 0.000488281C11.6674 0.000488281 11.7324 0.0134397 11.7931 0.0386019C11.8538 0.063764 11.909 0.100643 11.9554 0.147132L13.4554 1.62213C13.5019 1.66857 13.5388 1.72371 13.5639 1.78441C13.5891 1.84511 13.602 1.91017 13.602 1.97588C13.602 2.04159 13.5891 2.10665 13.5639 2.16735C13.5388 2.22805 13.5019 2.2832 13.4554 2.32963L5.43039 10.3546C5.38396 10.4011 5.32881 10.438 5.26811 10.4632C5.20741 10.4883 5.14235 10.5013 5.07664 10.5013C5.01094 10.5013 4.94587 10.4883 4.88517 10.4632C4.82447 10.438 4.76933 10.4011 4.72289 10.3546L0.247893 5.85463C0.154196 5.76087 0.101562 5.63375 0.101562 5.50119C0.101562 5.36864 0.154196 5.24152 0.247893 5.14776L1.74789 3.64776C1.79433 3.60127 1.84947 3.56439 1.91017 3.53923C1.97087 3.51407 2.03594 3.50111 2.10164 3.50111C2.16735 3.50111 2.23241 3.51407 2.29311 3.53923C2.35381 3.56439 2.40896 3.60127 2.45539 3.64776L4.74789 5.85463Z" fill="#03A500"/>
                                                </svg>
                                            </span>   
                                                <T5Normaltext sx={{flex:1, color:'#03A500', fontWeight:600}}>Validated</T5Normaltext>
                                         </span>                                 
                                        </div> :<><T5Normaltext sx={{flex:1, color:'#ED9C00', fontWeight:600}}>Pending Validation </T5Normaltext></>}
                                </>}
                                       {
                                          ((displayLeadDetails?.leaddetails?.validated_status ==="Validated" || displayLeadDetails?.leaddetails?.status ==='Converted' || displayLeadDetails?.leaddetails?.status ==='Lost'))  ? '' :((resettime.length > 0 ? new Date().getTime() > resettime[0].reset_time : true) && (user?.data?.user?.id == displayLeadDetails?.leaddetails?.assigned_to.id) && displayLeadDetails?.leaddetails?.validated_status !='Validated') &&(!permissionsDetails?.perm_validate_edit) ?  <Typography sx={{pl:2, cursor:'pointer'}} onClick={ disable ? '' : () => handleReminder(displayLeadDetails?.leaddetails?.id)} ><ReminderIcon /></Typography>:""
                                        }
                               
                                {/* <H3SubHeader sx={{color:'#ED9C00',padding:'3px', fontSize:'12px'}}>Pending Validation</H3SubHeader> */}
                            </Grid>
                        </Grid>
                                        
                    {/*   Company Details  */}
                        <CardBody sx={{ mt: 0, ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl: 1, lg: 1 } }}>
                            <CompanyNameDetails getCompanyDetails ={displayLeadDetails} title={title} />
                        </CardBody>

                    {/*     Contacts  Details  */}
                        <ContactNameDetails contactDetails={displayLeadDetails?.leaddetails}/>
                       
                        {/* <Grid sx={{pl:2, mt: 2, mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                            <Grid item xs={10}>
                                <H3SubHeader sx={{color: themeColor.palette.Primarytext1}}>
                                    Validation
                                </H3SubHeader>
                            </Grid>
                        </Grid>

                        <CardBody sx={{ mt: 1, ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl: 1, lg: 1 } }}>
                        <ActionButtons>
                        <ApproveBtn sx={{ color:themeColor.palette.BrandColor, backgroundColor: themeColor.palette.backgroundColor , ":hover":{cursor:'pointer'}}}>
                           Approve
                        </ApproveBtn >
                        <RejectBtn sx={{ color: themeColor.palette.PrimaryRed, ":hover":{cursor:'pointer'}}}>
                           Reject
                        </RejectBtn>
                     </ActionButtons>
                        </CardBody>   */}

                    {/*      Lead info       */}
                        <Box>
                            <Grid sx={{pl:2, mt: 2, mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <Grid item xs={10}>
                                    <H3SubHeader sx={{color: themeColor.palette.Primarytext1, LineHeight :"21.94px"}}>
                                        Lead Info
                                    </H3SubHeader>
                                </Grid>

                                <Grid item xs={2} sx={{display: 'flex', mr:1, alignItems: 'end', justifyContent: 'flex-end'}} >
                                    {displayLeadDetails?.leaddetails?.validated_status === "Validated" || displayLeadDetails?.leaddetails?.status === 'Converted' || displayLeadDetails?.leaddetails?.status === "Closed-No-Action"|| displayLeadDetails?.leaddetails?.status === 'Lost' ? <></> : 
                                        ((checkAdminRole && permissionsDetails?.perm_lead_edit) || (permissionsDetails?.perm_lead_edit && user?.data?.user?.id == displayLeadDetails?.leaddetails?.assigned_to?.id)) ? 
                                        <Typography id='edit_lead' onClick={handleClickEditLeadDetails} dis sx={{padding:'3px', fontSize:'12px', textAlign:'right', ":hover":{cursor:'pointer'}}} >
                                            <EditIcon w={20} h={20} />
                                        </Typography> :""
                                    }    
                                </Grid>
                            </Grid>

                            <ChildCard sx={{ml:1, mr:1}}>
                                <CardInfo sx={{ pt:1, pl: 1, pr: 2, pb: 0.2}}>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel}}>
                                        Created by: 
                                    </H7TablePrimeContent>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel }}>
                                         Assigned to:  
                                    </H7TablePrimeContent>
                                </CardInfo>
                                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex: 1,
                                        flex: 1,
                                        mt: 0.2,
                                        color: themeColor.palette.primarytext2,
                                        whiteSpace: 'pre-line', // Allow line breaks
                                        wordBreak: 'break-word', // Break long words
                                        maxWidth: 'auto', // Limit max width to 150px
                                        }}>
                                            {displayLeadDetails?.leaddetails?.created_by?.firstname} {displayLeadDetails?.leaddetails?.created_by?.lastname}
                                    </T5Normaltext>

                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2}}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ flexShrink: 0, maxWidth: 'auto',  flex: 1,
                                            whiteSpace: 'pre-line', // Allow line breaks
                                            wordBreak: 'break-word', }}> {displayLeadDetails?.leaddetails?.assigned_to?.firstname} {displayLeadDetails?.leaddetails?.assigned_to?.lastname}
                                         </span>
                                     
                                        {(checkAdminRole && permissionsDetails?.perm_assign_edit) || (permissionsDetails?.perm_assign_edit && findIds(displayLeadDetails?.leaddetails?.assigned_to?.id)) ? <>
                                            {displayLeadDetails?.leaddetails?.status ==='Converted' || displayLeadDetails?.leaddetails?.status === 'Lost' || displayLeadDetails?.leaddetails?.status ==="Closed-No-Action" ? <></>:<>
                                            <Tooltip title="Assign Lead" placement="top-start">
                                                <IconButton id='assign_lead' onClick={(checkAdminRole && permissionsDetails?.perm_assign_edit) || (permissionsDetails?.perm_assign_edit && findIds(displayLeadDetails?.leaddetails?.assigned_to?.id))? handleAssign:''}>
                                                    <AssignIcon /> 
                                                </IconButton> 
                                            </Tooltip></>}
                                            </>:<></>}
                                        </span> 
                                    </T5Normaltext>
                                </CardInfo>

                                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2}}>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel}}>
                                        Lead Name
                                    </H7TablePrimeContent>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel }}>
                                        Source
                                    </H7TablePrimeContent>
                                </CardInfo>
                                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                {/* <Tooltip title={displayLeadDetails?.leaddetails?.lead_name} placement="top-start"> */}
                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line',
                                        wordBreak: 'break-word', 
                                        maxWidth: 'auto'}}
                                    >
                                        {displayLeadDetails?.leaddetails?.lead_name}
                                    </T5Normaltext>
                                    {/* </Tooltip> */}
                                    <T5Normaltext sx={{flex:1, marginLeft:'0px', color: themeColor.palette.primarytext2}}>
                                        {displayLeadDetails?.leaddetails?.lead_source}
                                    </T5Normaltext>
                                </CardInfo>
                                    
                                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel }}>
                                        Data Source
                                    </H7TablePrimeContent>
                                    <H7TablePrimeContent style={{flex:1,color: themeColor.palette.InputLabel}}>
                                        Service Provider
                                    </H7TablePrimeContent>  
                                </CardInfo>

                                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex:1,color: themeColor.palette.primarytext2}}>
                                        {displayLeadDetails?.leaddetails?.data_source}
                                    </T5Normaltext>
                                    <T5Normaltext sx={{flex:1,color: themeColor.palette.primarytext2}}>
                                        {displayLeadDetails?.leaddetails?.service_provider}
                                    </T5Normaltext> 
                                </CardInfo>

                                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel }}>
                                        Product Family
                                    </H7TablePrimeContent>
                                    <H7TablePrimeContent style={{flex:1,color: themeColor.palette.InputLabel}}>
                                        Product
                                    </H7TablePrimeContent>
                                </CardInfo>

                                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2}}>
                                        {displayLeadDetails?.leaddetails?.product_family}
                                    </T5Normaltext>
                                    <T5Normaltext sx={{flex:1,color: themeColor.palette.primarytext2}}>
                                        {displayLeadDetails?.leaddetails?.product}
                                    </T5Normaltext> 
                                </CardInfo>

                                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                        <H7TablePrimeContent style={{flex:1, whiteSpace: 'pre-line', wordBreak:'break-all', color: themeColor.palette.InputLabel}}>
                                            Address (<span style={{color:themeColor.palette.InputLabel, fontSize:'12px',}}>{displayLeadDetails?.leaddetails?.branch?.register_address == true ? "Registered Office Address" : "" ||displayLeadDetails?.leaddetails?.branch?.head_office == true? "Head Office Address" :'' || 
                                    (displayLeadDetails?.leaddetails?.branch?.register_address == false && displayLeadDetails?.leaddetails?.branch?.head_office == false ) ? "Branch Office Address" :'' }</span>)
                                        </H7TablePrimeContent>
                                </CardInfo>
                                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak:'break-all'}}>
                                        {displayLeadDetails?.leaddetails?.branch?.address} {displayLeadDetails?.leaddetails?.branch?.city} {displayLeadDetails?.leaddetails?.branch?.state} {displayLeadDetails?.leaddetails?.branch?.country} {displayLeadDetails?.leaddetails?.branch?.pincode}
                                    </T5Normaltext>
                                </CardInfo>
                                
                                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                                    <H7TablePrimeContent style={{flex:1, color: themeColor.palette.InputLabel, marginLeft: '2px' }}>
                                        Lead Summary
                                    </H7TablePrimeContent>
                                </CardInfo>
                                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                                    <T5Normaltext sx={{flex:1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak:'break-all'}}>
                                        {displayLeadDetails?.leaddetails?.summary}
                                    </T5Normaltext>
                                </CardInfo>
                            </ChildCard>                  
                        </Box>
                    {/* </CardBody> */} 
             
                </Grid>

                {/* Grid Item 2 */}
                <Grid item xs={12} md={10} sm={10} lg={8}>
                    <UpdateStatus leadStatus = {displayLeadDetails}/> 
                    <CreateFollowup user_ids={user_ids} leadDetails = {displayLeadDetails} setUpdate= {setUpdate} update ={update}/>
                </Grid>

            </Grid>
        </LoginContainer>
       </>}

    {/* =================== open modal for contact details form ================= */}

    {/* {openOpportunityModel && <OpportunityForm setEditOpportunityDetails={setEditOpportunityDetails} editOpportunityDetails={editOpportunityDetails}  setOpenOpportunityModel={setOpenOpportunityModel} openOpportunityModel={openOpportunityModel}/>} */}

    {openContactModel && <ContactForm open={openContactModel} setOpenModal={setOpenContactModel} title={title}
        dropdownValues={displayLeadDetails?.company} editContactDetails={editContactDetails} setEditContactDetails={setEditContactDetails} />}
     
     {openAssignModal && <AssignModal title={leadName} taskNumber={displayLeadDetails?.leaddetails?.lead_no} taskAssignedFromUser={leadAssigned} setUpdate={setUpdate} update={update} openAssignModal={openAssignModal} setOpenAssignModal= {setOpenAssignModal} saveAssignedManagerOrExecutiveName={saveAssignedManagerOrExecutiveName}/>}
       </>
    )
}