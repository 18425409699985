const headCells = [
    {
      id: 'Followup ID',
      numeric: false,
      disablePadding: false,
      label: 'Followup ID',
    },
    {
      id:'Lead ID',
      numeric: false,
      disablePadding: false,
      label:'Lead ID',
    },
    {
      id: 'Contact Name',
      numeric: false,
      disablePadding: false,
      label: 'Contact Name',
    },
    {
      id: 'Created By',
      numeric: false,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'Assigned to',
      numeric: false,
      disablePadding: false,
      label: 'Assigned to',
    },
    {
      id: 'Type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
    },
    // {
    //   id: 'Schedule Date & Time',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Schedule Date & Time',  
    // },
    // {
    //   id: 'Status',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Status',   
    // },
    // {
    //   id: 'Assign',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Assign',   
    // },
    
  ];
  
  export { headCells }