import React,{useState,useEffect} from 'react';
import { Navbar, DropDown, CustemBtn, CompanyName, TextArea, InputBox} from '../../components/custom';
import {Grid, Box, styled, Card, Avatar, Typography, TextField,} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getAllCompanyName} from "../../Redux/Thunk/createcompanythunk";
import { fetchComanyLeads } from '../../Redux/Thunk/createopportunitythunk';
import validate from '../../validation/validation';
import LeadDeatilsForOppCreation from './LeadDetailsForOpportunity';
import { displayContactList } from '../../Redux/Thunk/createcontact';
import DisplayError from '../../validation/DisplayError';
import { leaddetails } from '../../Redux/Thunk/createleadthunk';
import swal from 'sweetalert';

const MainContainer = styled('div')({
    width:'100%',
    maxHeight:'100%',
    height:'auto',
})

const CardBody = styled('div')({
    backgroundColor:'#F0EFFD',
    padding:'20px',
    borderRadius:'10px',
 })
 
const Container = styled('div')({
    background:'#F5F4FF',
    borderRadius:'10px',
    padding:'0px 20px 20px',
    margin:'30px',
    gap:'10px',
    // height:'200px',
})

////   Create New opportunity //
const CreateNewOpportunity = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {state} = useLocation()
    const [companyNames, setCompanyNames]= useState([])
    const [dropdownValues, setDropdownValues]= useState('')
    const {allCompanyName, update,} = useSelector((state)=> state.company)
    const  {lead}  = useSelector((state) => {
        if(state.opportunity.companyleads==null){
            return []
        }else{
            return state.opportunity.companyleads
        }
    })
    const selectStatusOfLead = lead?.filter((status)=>(status?.validated_status === "Validated" && status?.status === 'WIP') || (status?.validated_status === "Validated" && status?.status === 'Open')) 
    const { getContactList } = useSelector((state) => state?.contact)
    const [leadDropdown, setLeadDropdown] = useState(undefined)
    const [error,setError] = useState([])
    const [selectedLeadDetails, setSelectedLeadDeails ] = useState('')
    const [showLeadDetails, setShowLeadDetails ] = useState(false) //Show Lead Details
    const [displayLeadID, setDisplayLeadID] = useState(false)  // Display Lead id dropdown
    const [buttonStatus, setButtonStatus] = useState(false)    //Buttons are disable/ Enable status
    const [contactName, setContactName] = useState("")
    const allContactDisabled = dropdownValues?.contact?.every(obj => obj.enabled === false)

    console.log(state,"state")
    console.log(getContactList,"getContactList")
    console.log(dropdownValues,"dropdownValues")
    useEffect(()=>{
        dispatch(getAllCompanyName());
        // displayAllCompanyName();
    },[])

    const displayAllCompanyName = () =>{ 
       let displayName = allCompanyName?.map((name ,index)=> `${name?.company_name} ${name?.company_type}`)
       let displayDropdownList = []
       for(let i= 0; i< allCompanyName?.length; i++){
          displayDropdownList.push({
             value: `${allCompanyName[i]?.company_name} ${allCompanyName[i]?.company_type}`,
             label: `${allCompanyName[i]?.company_name} ${allCompanyName[i]?.company_type}`,
             id : allCompanyName[i]?.id
          })
        setCompanyNames( displayDropdownList)
       }
    }

    let validation_input = [
        {tagid: 'company', text:dropdownValues?.value??'', regex_name:'text', required: true, allow_numbers: true, other_chars: true, errmsg: 'Please Select Company.'},
        //{tagid: 'companyFullName', text:companyDetails.companyName, regex_name: 'text', required: true, errmsg: 'Please Enter Full Name.', allow_numbers: false, other_chars: true, min:1, max: 20},
        {tagid: 'lead', text:leadDropdown?.value ?? '', regex_name:'free_text', required: true, errmsg: 'Please Select Lead ID.' },
    ]

// Select company name and lead id
    const handleCreateNewOpportunity=()=>{
        if( validate(validation_input).length==0 ){
            if(allContactDisabled === true){
                sweetalert('error', `All contacts are disable for ${dropdownValues?.label}. Please add new contact.`)
            }else{
                dispatch(leaddetails(selectedLeadDetails?.id)).then((res) =>{
                    const allTaskClosed = res?.payload?.gettask?.every(obj => obj.closing_remark !='')
                    if((res?.payload?.getfollowup?.find((status)=> status.closed_on === 0)) && (allTaskClosed === false)){
                        sweetalert('warning',`Following lead has open followup and open Task, Please close first then create new opportunity.`)
                    }else if (res.payload?.getfollowup?.find((status)=> status.closed_on === 0)){
                        sweetalert('warning',`Following lead has open followup, Please close first then create new opportunity.`)
                    }else if (allTaskClosed === false){
                        sweetalert('warning',`Following lead has open Task, Please close first then create new opportunity.`)
                    }else{
                        setError([])
                        setShowLeadDetails(true)
                    }
                })
            }
        } else {
            setError(validate(validation_input))
        
        } 
    }

    const sweetalert = (variant, text) => {
        swal({
            icon: variant==='error' ? 'error' : variant==='success'? 'success' :'warning',
            title: text,
            button: "Ok!",
        });
    }

    const handleCompany = (e,value,reason) =>{
        if(value === null){
            setShowLeadDetails(false)
            setDisplayLeadID(false) 
            // setButtonStatus(false) 
            setDropdownValues('')
        }else{
            setLeadDropdown('')
            setDropdownValues(value)   
            if(value.id) {
                dispatch(fetchComanyLeads(value?.id))  
                dispatch(displayContactList(value?.id))
            } 
            setDisplayLeadID(true)  
            setShowLeadDetails(false)
        }      
    }
    
    const handleLead = (e, value, reason) =>{
        if(value === null){
            setShowLeadDetails(false)
        }else{
            setLeadDropdown(value) 
            //  const leadDetails = lead.filter( (lead) => lead.lead_no ? lead.lead_no:'' == value.value )
             const leadDetails = lead?.find((lead)=> lead?.lead_no == value?.value)
             setSelectedLeadDeails(leadDetails)
             const findContatcName = getContactList?.find((name)=> name.id == leadDetails?.contact)
             setContactName(findContatcName)

             if(allContactDisabled === true){
                sweetalert('error', `All contacts are disable. Please add new contact.`)
            }else{
                dispatch(leaddetails(leadDetails?.id)).then((res) =>{
                    const allTaskClosed = res?.payload?.gettask?.every(obj => obj.closing_remark !='')
                    if((res?.payload?.getfollowup?.find((status)=> status.closed_on === 0)) && (allTaskClosed === false)){
                        sweetalert('warning',`Following lead has open followup and open Task, Please close first then create new opportunity.`)
                    }else if (res.payload?.getfollowup?.find((status)=> status.closed_on === 0)){
                        sweetalert('warning',`Following lead has open followup, Please close first then create new opportunity.`)
                    }else if (allTaskClosed === false){
                        sweetalert('warning',`Following lead has open Task, Please close first then create new opportunity.`)
                    }else{
                        setError([])
                        setShowLeadDetails(true)
                    }
                })
            }
            //  setShowLeadDetails(true)
            
            // setButtonStatus(true)
        }
    }

    const handleSave = () => {
        // dispatch(displayContactList(dropdownValues ? dropdownValues?.company?.id :state?.companyName?.company?.id)).then((res)=>{  
        // })
        navigate('/dashboard/opportunity', {state:{title:state, company:dropdownValues? dropdownValues:state?.companyName, leadDetails:selectedLeadDetails, contactLists:getContactList, operation:'createOpp'}})
    }
    
    return(
        <> 
         {/*  Nav bar */}
         {showLeadDetails || state?.title ==='companyProfilePageOpportunityDetails'? <CompanyName style={{background:'#F3F3F3'}} sx={{color:'#3107AA',letterSpacing: "0.04em"}} label={dropdownValues?.value?dropdownValues?.value: `${state?.companyName?.company_name} ${state?.companyName?.company_type}`  } /> :<></>} 
            <Grid sx={{ borderBottom:'1px solid #e4e4e4',mt:2, ml:7, mr:6, display:"flex",flexDirection:{lg:'row',sm:'row',xs:'column-reverse'}, justifyContent:{lg:'space-between',sm:'space-between',xs:'start'},alignItems:{lg:'flex-end',md:'flex-end',xs:'center'}}}> 
                <Typography variant='h6' sx={{fontSize:{lg:'22px',md:'20px',sm:'18px'},fontWeight:600}}>Create Opportunity</Typography>
            </Grid>

                {/* {showLeadDetails ? <>
                    <Grid sx={{m:1, ml:6, mr:6, display:"flex", flexDirection:'row', justifyContent:'start'}}>
                        <Grid item xs={4} md={6} sm={6} lg={2} >
                        <Typography sx={{fontSize: {lg:'22px', md:'20px', sm:'18px'}, fontWeight:600}}>Lead Details</Typography>
                        </Grid>
                    </Grid>
                </>:<></>} */}

            <CardBody sx={{mt:showLeadDetails ? 2 :2, pb:4, ml:{xs:1,sm:1,xl:6,lg:6}, mr:{xs:1,sm:1,xl:6,lg:6} }}>
                    <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                        {state?.title ==='companyProfilePageOpportunityDetails' ?<></>:<>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Company Name</Typography>  
                                <DropDown sx={{background:'#FFFFFF',mt:1}} 
                                    placeholder = 'Select Company'
                                    options ={allCompanyName?.map((name)=>{return {label:`${name?.company_name} ${name?.company_type}`, value:`${name?.company_name} ${name?.company_type}`, id: name?.id, contact: name?.contact}})}
                                    // options = {companyNames}
                                    onChange = {handleCompany}
                                    getOptionLabel={(option) =>(option ? option.label :"")}
                                    value = {dropdownValues}
                                />
                                <DisplayError error={error} fortagid='company'/>
                            </Grid>
                        </>}
                        {displayLeadID || state?.title ==='companyProfilePageOpportunityDetails' ? <>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Lead ID</Typography>  
                                <DropDown sx={{background:'#FFFFFF',mt:1}} 
                                    placeholder = 'Select Lead ID'
                                    options = {selectStatusOfLead?.map((lead) => {return {value:lead?.lead_no ? lead.lead_no:'',label:lead.lead_no}})}
                                    onChange = {handleLead}
                                    getOptionLabel={(option) =>(option ? option.label :"")}
                                    value= {leadDropdown }
                                />
                                <DisplayError error={error} fortagid='lead'/>
                            </Grid></> :<> </>}

                            {showLeadDetails ? <>
                                <Grid item xs={12} md={3} sm={3} lg={3}>
                                    <Typography>Contact Name</Typography>  
                                    <InputBox value={`${contactName?.first_name ??''} ${contactName?.last_name ??''}`} disabled={true} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}}/>
                                </Grid></> :<></>}
                    </Grid>

                    {showLeadDetails ? <><Grid item xs={12} md={4} sm={4} lg={2}>
                    <Typography sx={{borderBottom:'1px solid #e4e4e4', mt:1, fontWeight:600, fontSize:'18px', lineHeight:'29px'}}>Lead Details</Typography>
                </Grid>
                 </>:<></>}

                {showLeadDetails ? <>
                        {/*        Lead    */}
                    <Box style={{marginTop:'20px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={2} sm={2} lg={2}>
                                <Typography sx={{fontWeight: {xs:600, lg:400, md:600}}}>Lead</Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Name</Typography>
                                <InputBox value={selectedLeadDetails?.lead_name} disabled={true} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="Vodafone limited Mr, Ramesh Jadhav"/>
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Source</Typography>
                                <InputBox value={selectedLeadDetails?.lead_source} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="Parent Organization "/>
                            </Grid>
                            <Grid item xs={12} md={3} sm={3}l lg={3}>
                                <Typography>Data Source</Typography>
                                <InputBox value={selectedLeadDetails?.data_source} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="Mobigic Batabase "/>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box style={{marginTop:'20px'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={0} md={2} sm={2} lg={2}>
                            </Grid>
                            <Grid item xs={12} md={4} sm={4} lg={4}>
                                <Typography>Service Provider</Typography>
                                <InputBox value={selectedLeadDetails?.service_provider} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="TTML"/>
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Product Family</Typography>
                                <InputBox value={selectedLeadDetails?.product_family} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="Network Service"/>
                                {/* <Typography>{errors.email_address ? errors.email_address : ""}</Typography> */}
                            </Grid>
                            <Grid item xs={12} md={3} sm={3} lg={3}>
                                <Typography>Product</Typography>
                                <InputBox value={selectedLeadDetails?.product} sx={{width:'100%', border:"1px solid #B0AEFF", color:'#444444', fontSize:'14px', background:'#E9E9FF',borderRadius:'8px', p:0.5, mt:1, pl:1,mr:3}} placeholder ="ILL"/>
                            </Grid>
                        </Grid>
                    </Box>

        {/*         summary           */}
                    <Box style={{marginTop:'30px'}}>
                        <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                            <Grid item xs={12} md={2} sm={2} lg={2}>
                                <Typography sx={{fontWeight: {xs:600, lg:400, md:600}}}>Summary</Typography>  
                            </Grid>
                            <Grid item xs={12} md={10} sm={10} lg={10}>
                                <TextArea value={selectedLeadDetails?.summary} style={{width:'100%', fontFamily: 'Montserrat',fontStyle: 'normal', background:'#E9E9FF', border:"1px solid #B0AEFF", height:'80px',borderRadius:'8px',marginBottom:'5px',fontWeight: 400, fontSize: '14px', lineHeight: '17px',padding:'4px'}} placeholder ="Write a note about Lead."></TextArea>
                            </Grid>
                        </Grid>
                    </Box> 
                </>
                :<></>}
            </CardBody>

            {showLeadDetails ?<><Box sx={{mt:4,mr:{xs:1,sm:1,xl:6,lg:6},mb:2,display:'flex',justifyContent:'end'}}>
                <CustemBtn onClick={showLeadDetails ? ()=> handleSave() : ()=> handleCreateNewOpportunity ()} variant="contained" sx={{borderRadius:"10px",pl:5,pr:5,fontSize:'16px',backgroundColor:'#7673F6'}} text="Proceed" /> 
            </Box> </>:<></>}
        </>
    )
}

export default CreateNewOpportunity
