import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import OpportunityCard from '../../components/custom/OpportunityCard'
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { EditIcon, ExecutiveCard, FilterCloseIcon, Navbar } from '../../components/custom';
import FilterIcon from '../../components/custom/FilterIcon';
import PaginationFooter from '../../components/custom/PaginationFooter';
import { getperformance } from '../../Redux/Thunk/dashboardthunk';
import { useDispatch, useSelector } from 'react-redux';
import PopperFilter from '../../components/custom/PopperFilter';
import TableListSearch from '../../components/custom/TableListSearch';
import { TailSpin } from 'react-loader-spinner';


const Item = styled('div')({
  display:'flex',
  justifyContent:'center'
})

const CardBody = styled('div')({
   backgroundColor:'#F0EFFD',
   padding:'20px',
   borderRadius:'10px',
})

const Search = styled('div')({
  display:'flex',
  padding:'0px 10px',
  borderRadius:'10px',
  justifyItems:'center',
  // border:'1px solid #B0AEFF ',
  flexDirection:'row',
  backgroundColor:'#ffffff',
  boxShadow:'0px 4px 20px #e4e4e4'
})

const CardDetails = styled('div')({
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  marginTop:"10px"
})

const CardInfo = styled('div')({
  display:'flex',
  flexDirection:'column',
  alignItems:'flex-start',
})

const CardHeader = styled('div')({
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  borderBottom:'0.5px solid #D4D4D4'
})

const CardHeaders =  <>
                  <CardHeader>
                      <Typography sx={{color:'#444444',fontSize:'20px',fontWeight:'600',flex:1}}>
                       Name of Company  
                      </Typography>
                      <Typography >
                          <EditIcon w={22} h={22} />
                      </Typography>
                  </CardHeader>
                      <Box sx={{display:'flex',pt:1,justifyContent:'space-between'}}>
                      <Typography sx={{fontSize:'18px',color:'#FE8731',fontWeight:600}}>
                       
                      </Typography>
                      <Typography sx={{color:'#3C8AFF',fontSize:'16px',fontWeight:600,letterSpacing:1}} >
                       {/* Status goes here if you have status */}
                      </Typography>
                      </Box>
                   </>

const CardView = <>
                <Box sx={{display:'flex',justifyContent:'space-between'}}>
                    <Typography sx={{color:"#FE8731",fontWeight:600}}>
                      OOP-01523
                    </Typography>
                    <Typography sx={{color:"#3C8AFF"}}>
                      commercials
                    </Typography>
                </Box>
                <CardDetails>
                  <CardInfo>
                  <Typography sx={{color:"#444444"}}>
                    Contact Name
                  </Typography>
                      <InputBox Default="Role" sx={{width:'100%',color:'#7673F6', backgroundColor:'#E9E9FF', borderRadius:'8px',p:0.1,mt:1,pl:1.5}} />
                  </CardInfo>
                  <CardInfo sx={{paddingLeft:'20px'}}>
                      <Typography sx={{color:"#444444",}}>
                      Created By
                      </Typography>
                      <InputBox Default="8830618305" sx={{width:'100%',color:'#00a107', backgroundColor:'#E9E9FF', borderRadius:'8px',p:0.1,mt:1,pl:1.5}} />
                  </CardInfo>
                </CardDetails>
                <CardDetails>
                  <CardInfo>
                    <Typography sx={{color:"#444444"}}>
                      Date of Creation
                    </Typography>
                        <InputBox Default="DD-MM-YYYY" sx={{width:'100%',color:'#7673F6', backgroundColor:'#E9E9FF', borderRadius:'8px',p:0.1,mt:1,pl:1.5}} />
                    </CardInfo>
                  <CardInfo sx={{paddingLeft:'20px'}}>
                      <Typography sx={{color:"#444444",}}>
                      Date of Billing
                      </Typography>
                      <InputBox Default="DD-MM-YYYY" sx={{width:'100%',color:'#00a107', backgroundColor:'#E9E9FF', borderRadius:'8px',p:0.1,mt:1,pl:1.5}} />
                  </CardInfo>
                </CardDetails>
                 </>

export default function ExecutiveList() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const dispatch = useDispatch()
  const { getallroles } = useSelector ((state) => state?.role)
  const allroles = getallroles?.map((role)=>{return {name: role?.role}})
  const [userdata, serUserData] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopper, setOpenopper] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [update, setUpdate] = React.useState(false);
  const [loader, setLoader] = React.useState(false)
  const [filterRole, setFilterRole ] = React.useState('')
  const [performancedate, setPerformanceDate] = React.useState('This Month')
  var today = new Date();
  var year = today.getFullYear();
  var month = today.getMonth();
  var startDate = new Date(year, month, 1);
  startDate.setHours(0, 0, 0, 0);
  
  var endDate = new Date(year, month + 1, 0);
  endDate.setHours(23, 59, 59, 999);

 
  const [performstartdate, setPerformStartDate] = React.useState(startDate.getTime())
  const [performsenddate, setPerformEndDate] = React.useState(endDate.getTime())

  const Title= "User List"
  const tooltipTitle = "New User"
  const placeholderTitle = "Search User"
  
  React.useEffect(() => {
    setLoader(true)
    dispatch(getperformance({start:(currentPage*6 - 6), length:6, search:search, filterRole:filterRole, start_date:performstartdate, end_date:performsenddate})).then((res) => {
      serUserData(res?.payload?.data)
      setLoader(false)
    })
  }, [currentPage, search, update]) 

  const handleFilter = (e)=> {
    if(e.target.value === "All Roles"){
      setFilterRole("")
      setOpenopper(false)
      setUpdate(!update)
    }else{
      setFilterRole(e.target.value)
      setOpenopper(false)
      setUpdate(!update)
    }
  }

  const handlePerformance = (e) => {
    setPerformanceDate(e.target.value)
    var today = new Date();
    if(e.target.value === 'This Week') {
      var currentDay = today.getDay();
      var startDate = new Date(today);
      startDate.setDate(today.getDate() - currentDay);
      startDate.setHours(0, 0, 0, 0)
      setPerformStartDate(startDate.getTime())
      var endDate = new Date(today)
      endDate.setDate(startDate.getDate() + 6);
      endDate.setHours(23, 59, 59, 999)
      setPerformEndDate(endDate.getTime())
      setUpdate(!update)
    } else if(e.target.value == 'This Month') {
      var year = today.getFullYear();
      var month = today.getMonth();
      var startDate = new Date(year, month, 1);
      startDate.setHours(0, 0, 0, 0);
      setPerformStartDate(startDate.getTime())
      var endDate = new Date(year, month + 1, 0);
      endDate.setHours(23, 59, 59, 999);
      setPerformEndDate(endDate.getTime())
      setUpdate(!update)
    } else if(e.target.value == 'Last Month') {
        var year = today.getFullYear();
        var month = today.getMonth();
        var lastMonthStartDate = new Date(year, month - 1, 1);
        lastMonthStartDate.setHours(0, 0, 0, 0);
        var lastMonthEndDate = new Date(year, month, 0);
        lastMonthEndDate.setHours(0, 0, 0, 0);
        setPerformStartDate(lastMonthStartDate.getTime())
        setPerformEndDate(lastMonthEndDate.getTime())
        setUpdate(!update)
    } else if(e.target.value == 'Last 3 Months') {
        var year = today.getFullYear();
        var month = today.getMonth();
        var lastThreeMonthsStartDate = new Date(year, month - 3, 1);
        lastThreeMonthsStartDate.setHours(0, 0, 0, 0);
        setPerformStartDate(lastThreeMonthsStartDate.getTime())
        var lastMonthEndDate = new Date(year, month, 0);
        lastMonthEndDate.setHours(23, 59, 59, 999);
        setPerformEndDate(lastMonthEndDate.getTime())
        setUpdate(!update)
    } else {
      setPerformStartDate(new Date("2010-01-01").getTime())
      setPerformEndDate(new Date().getTime())
      setUpdate(!update)
    }
  }

  const handleSearch = (e) => {
    setSearch (e.target.value)
    dispatch(getperformance({start:(currentPage*5 - 5), length:5, search:e.target.value, filterRole:filterRole, start_date:performstartdate, end_date:performsenddate})).then((res) => {
      serUserData(res?.payload?.data)
    })
   // dispatch(getuserlistthunk({start:page*rowsPerPage, length:rowsPerPage , search:e.target.value, filterRole:filterRole }))
    setUpdate(!update)
  }

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenopper((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  return (
    <>
  {/* <Navbar/> */}
  <PopperFilter filtervalues ={[...allroles, {name:"All Roles"}]} open={openPopper} anchorEl={anchorEl} placement={placement} onClick={handleFilter} />

    <Grid  sx={{mt:1,ml:5,mr:5, display:"flex", flexDirection:{xl:'row',md:'row',sm:'column',xs:'column'} ,justifyContent:'space-between',alignItems:'center'}} >
      <Grid >
      {/* <CustemBtn sx={{borderRadius:'8px',pl:4,pr:4}} text="Create New User" variant="contained" /> */}
      </Grid>
      <Grid sx={{marginTop:{xl:'none',xs:'10px',sm:'10px'}}} >
       {/* <Search> 
            <InputBox  placeholder="Search user"/>
              <Typography sx={{pl:4,pt:1}}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#7673f6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.7051 13.6978L17.502 17.4946" stroke="#7673f6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </Typography>
        </Search> */}
      </Grid>
    </Grid>
 
    <Grid sx={{ borderBottom:'1px solid #e4e4e4',mt:3,ml:5,display:"flex",justifyContent:'space-between',alignItems:'flex-end'}}>
      <Grid item xs={10}>
        <Typography variant='h6' sx={{fontSize:'28px',fontWeight:600}}>Analytics</Typography>
      </Grid>
      <Grid>
      <Box>
        <CustemBtn value='This Week' onClick={handlePerformance} variant='h6' text="This Week" sx={{ fontSize: '14px', color:`${performancedate == 'This Week' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'This Week' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'This Week' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        <CustemBtn value='This Month' onClick={handlePerformance} variant='h6' text="This Month" sx={{ fontSize: '14px',marginLeft:'5px', color:`${performancedate == 'This Month' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'This Month' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'This Month' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        <CustemBtn value='Last Month' onClick={handlePerformance} variant='h6' text="Last Month" sx={{ fontSize: '14px',marginLeft:'5px', color:`${performancedate == 'Last Month' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'Last Month' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'Last Month' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        <CustemBtn value='Last 3 Months' onClick={handlePerformance} variant='h6' text="Last 3 Months" sx={{ fontSize: '14px',marginLeft:'5px', color:`${performancedate == 'Last 3 Months' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'Last 3 Months' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'Last 3 Months' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        <CustemBtn value='All' onClick={handlePerformance} variant='h6' text="All" sx={{ fontSize: '14px',marginLeft:'5px', color:`${performancedate == 'All' ? '#ffffff' : '#5F5BFF'}`, "&:hover":{backgroundColor:`${performancedate == 'All' ? '#5F5BFF':''}`}, fontWeight:600, borderRadius:'18px', cursor: 'pointer',backgroundColor:`${performancedate == 'All' ?'#5F5BFF':''}`, border:'1px solid #5F5BFF' }}></CustemBtn>
        </Box>
      </Grid>
      <Grid item xs={2}>
      <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mb:0.5 }}>
          <TableListSearch tooltipTitle={tooltipTitle} placeholderTitle={placeholderTitle} onchange={handleSearch} />    
            <Tooltip title="Filter By Role" placement="bottom-start">
              <IconButton onClick={handleClick("bottom-start")} sx={{mr: {lg:4, xs:1}}}>
                {!openPopper ? <FilterIcon /> : <FilterCloseIcon />}
              </IconButton>
            </Tooltip>
        </Grid>
      </Grid>
    </Grid>
   
    <CardBody sx={{mt:3,ml:{xs:1,sm:1,xl:5},mr:{xs:1,sm:1,xl:5} }}>
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 2, md: 8,lg:12 }}>
      { loader ? <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
                <TailSpin
                  height="30"
                  width="30"
                  color="blue"
                  ariaLabel="tail-spin-loading"
                  radius="3"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                /> </Box> :
        
         userdata?.performance_data?.length == 0 ?<Grid item xs={2} sm={12} md={12} xl={12} lg={12}>
                                <Typography sx={{color:'red', fontSize:'18px', fontWeight:600, textAlign:'center'}}>No Data Available !</Typography>
                             </Grid> : userdata?.performance_data?.map((emp) => {
                                        return  <Grid item xs={2} sm={4} md={4}>
                                                  <ExecutiveCard emp={emp} sx={{backgroundColor:'#FBFFEE'}}/>
                                                </Grid>
                                      })
        }
      </Grid>
    </Box>
    </CardBody>
    <Box sx={{display:'flex',justifyContent:'center',p:5}}>
       <PaginationFooter number={6} list={userdata.totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
    </Box>
    </>
  );
}