import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";
import axios from "axios"

export const createuserthunk = createAsyncThunk('data/createUser',
    async(data)=>{
    const res = await axiosInstance.post('/usercreate',data);
        console.log(res.data)
        return res.data
    }
  
)

export const getuserlistthunk = createAsyncThunk('data/user',
    async(data)=>{
    const res = await axiosInstance.post('/getalluser',data);
        return res.data
    }
  
)

export const usereditthunk = createAsyncThunk('data/edit',
    async(data)=>{
    const res = await axiosInstance.post('/useredit',data);
        return res.data
    }
  
)

export const useractivestatusthunk = createAsyncThunk('data/status',
    async(data)=>{
    const res = await axiosInstance.post('/useractivestatus',data);
        return res.data.data
    }
  
)

export const userdeletethunk = createAsyncThunk('data/delete',
    async(data)=>{
    const res = await axiosInstance.post('/userdelete',data);
        return res.data
    }
  
)

export const userList = createAsyncThunk('data/user',
    async(data)=>{
    const res = await axiosInstance.post('/userlist',data);
        return res.data
    } 
)

