import { Typography, styled } from '@mui/material';
import { createTheme } from '@mui/material/styles';
const theme = createTheme({
  palette: {
    primary: {
      main:'#3107AA', // Change this to your desired primary color
    },
  },
    typography: {
     "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
     "fontSize": 14,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    },
    TextArea:{
      "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
    },  

  });

  const Header = styled(Typography)(({ theme }) => ({
    // borderBottom: "0.5px solid #B9B9B9",
     fontSize:'16px',
     color:'#333333'
 }));

  const H1FormTitle = styled(Typography)(({ theme }) => ({
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  }));

  const H3SubHeader = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  }));

  const T6TableText = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  }));

  const H5FormLabel  = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  }));

  const H7TablePrimeContent = styled(Typography)(({ theme }) => ({
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  }));

  const T2popupSubText = styled(Typography)(({ theme }) => ({
    color: '#333333',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%'
  }));

  const T4MedNormalText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0.28px"
  }));

  const T5Normaltext  = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0.28px"
  })); 

  const H2POPupMainText  = styled(Typography)(({ theme }) => ({
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  })); 

  const H6TableCompany  = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal'
  }));
  
  const H4TableID  = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal'
  }));

  const T1MedNormalText = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  }));

  const T1ButtonText = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterspacing: "0.8px"
  }));

  export {theme, T1ButtonText, T1MedNormalText, Header,H6TableCompany, H1FormTitle,H4TableID, H2POPupMainText, T2popupSubText, H3SubHeader, T5Normaltext, T6TableText, H5FormLabel, T4MedNormalText, H7TablePrimeContent} 
