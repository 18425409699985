import React,{useState, useEffect}from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import CloseIcon from '../../components/custom/CloseIcon';
import {Box, Grid, Typography, IconButton, DialogActions, DialogContent, DialogTitle, Dialog, fabClasses, Toolbar, Tooltip, Button, CircularProgress} from "@mui/material"
import { ComapanyName, DropDown, InputBox, CustemBtn, DatePicker, CompanyName, SelectDropDown} from '../../components/custom';
import  validate  from "../../validation/validation";
import { useNavigate } from 'react-router';
import swal from 'sweetalert';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import {createContactForm, displayContactList, editContact} from '../../Redux/Thunk/createcontact';
import DisplayError from '../../validation/DisplayError';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import {displayCompanyDetails, getAllCompanyName } from '../../Redux/Thunk/createcompanythunk';
import WarningIcon from '../../components/custom/WarningIcon';
import moment from 'moment';
import { main_search } from '../../Redux/Thunk/dashboardthunk';
import displayname from '../../displaycompanyname/displaycompany';
import {H1FormTitle, H2POPupMainText, H5FormLabel} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import DialogHeaderCompanyName from '../../components/custom/DialogHeaderCompanyName';
import { storeContactDetails } from '../../Redux/slices/createContactSlice';
import { TailSpin } from 'react-loader-spinner';

const designationLists = [
  {
    value: 'Director',
    label: 'Director',
  },
  {
      value: 'Chairman ',
      label: 'Chairman ',
  },
  {
      value: 'Managing Director ',
      label: 'Managing Director ',
  },
  {
    value: 'Chief Executive Officer (CEO) ',
    label: 'Chief Executive Officer (CEO) ',
  },
  {
      value: 'Chief Financial Officer (CFO) ',
      label: 'Chief Financial Officer (CFO) ',
  },
  {
      value: 'Secretary',
      label: 'Secretary',
  },
  {
    value: 'Chief Operating Officer (COO)',
    label: 'Chief Operating Officer (COO)',
  },
  {
      value: 'Chief Technology Officer (CTO)',
      label: 'Chief Technology Officer (CTO)',
  },
  {
      value: 'Vice President ',
      label: 'Vice President ',
  },
  {
    value: 'Manager ',
    label: 'Manager',
  },
]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, title, editContact, storeCompanyDetails,...other } = props;

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {!onClose || storeCompanyDetails?.title === 'Create Company' || (title === "Contact List" && Object.keys(editContact).length > 0) || title === "CreateFromLead" || title === 'CompanyProfilePage' || title === "CreateFromProfilePage" ? <></> : (
          <IconButton
            id ='close_contact'
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              display:'flex', justifyContent:'center', 
              right: 10,
              // top: 11,
              // color: ((title === "CreateFromProfilePage" || title==="Create Address" ||title==="Create Company") && Object.keys(editContact).length === 0) ? '#FFFFFF':Object.keys(editContact).length === 0 ?'#000000' : '#FFFFFF' 
            }}
          >
            <CloseIcon w={35} h={35}/>
          </IconButton>
        )}
      </DialogTitle> 
    </>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ContactForm({title, open, setOpen, setOpenAddressModel, openAddressModel, setOpenModal, dropdownValues, profilePage, editContactDetails, setEditContactDetails, setRefresh1, refresh1}) {
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const {storeCompanyDetails} = useSelector(state => state.company)
   const {createcontact} = useSelector(state=> state.contact)
   const [allCompanyName, setallCompanyName] = useState([])
   const [error, setError] = useState([]);
   const [searchinput, setSearchInput] = useState('')
   const [disableButton, setDisableButton] = useState(false)
   const [editAllContactDetails, setEditAllContactDetails] = useState(editContactDetails ? editContactDetails : storeCompanyDetails?.editContactDetails)
   const [companyName, setCompanyName]= useState(title === 'CreateFromProfilePage'|| title === 'CreateFromLead' ? dropdownValues : storeCompanyDetails?.title === "Create Company" ? storeCompanyDetails?.companyName :'')
   const [value, setValue] = useState(Object.keys(editAllContactDetails).length === 0 ? null : editAllContactDetails?.dob === 0 ? null : new Date(editAllContactDetails?.dob).toISOString().split('T')[0]);
   const [loader, setLoader] = useState(false)
   const [buttonStatus, setButtonStatus] = useState(true);
   const [contactFormDetails, setContactFormDetails]= useState({
      firstname:'',
      lastname: '',
      linkdin:'',
      twitter:'',
      department:'',
      officephone: '', 
      phone: '',
      email: '',
      // dob:'',
      designation:'',
      extendedDesignation:''
    })
    const maxDateValue = new Date();
    maxDateValue.setFullYear(maxDateValue.getFullYear() - 18)
    const route = 'Create Contact'

    const wordsToRemove = ["TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"];

    const longText = `If required designation is not available in the list, then please select appropriate nearest designation from the list and add required designation in the Extended Designation field.`;  
 
    useEffect(() => {
      if(searchinput.length > 1) {
        let timeout_func = setTimeout(() => {
        dispatch(main_search({searchinput, selectedoption:"Company"})).then((res) => {
          setallCompanyName(res.payload.data) 
          setButtonStatus(false)
        })
      }, 1000);
      return () => {
        clearTimeout(timeout_func)
      }
      } else {
        setallCompanyName([]) 
        setButtonStatus(false)
      }
    }, [searchinput])

console.log(storeCompanyDetails,"storeCompanyDetails")    
   

    useEffect(()=>{
      if(Object.keys(editAllContactDetails).length > 0){
          setContactFormDetails({
          firstname: editAllContactDetails ? editAllContactDetails?.first_name:'',
          lastname: editAllContactDetails ? editAllContactDetails?.last_name:'',
          linkdin: editAllContactDetails ? editAllContactDetails?.linkdin:'',
          twitter: editAllContactDetails ? editAllContactDetails?.twitter:'',
          department: editAllContactDetails ? editAllContactDetails?.department:'',
          officephone : editAllContactDetails ? (editAllContactDetails?.officephone):'', 
          phone : editAllContactDetails ? editAllContactDetails?.phone:'',
          email: editAllContactDetails ? editAllContactDetails?.email:'',
          // dob : editAllContactDetails ? editAllContactDetails?.dob:'',
          designation: editAllContactDetails ? editAllContactDetails?.designation:'',
          extendedDesignation: editAllContactDetails ? editAllContactDetails?.extendeddesignation:''
      })}
    },[])
    let check_first_digit = contactFormDetails?.phone ? contactFormDetails?.phone[0] : 0
  
    let validation_input = [
      ((title ==="CreateFromDashboard" || title === "Contact List") && Object.keys(editAllContactDetails).length===0) ? {tagid:'companyName', text:companyName?.value ?? '', regex_name: 'free_text', required: true, errmsg: 'Please Select Company Name.', min:10, max: 100} :'',
      {tagid:'department', text:contactFormDetails.department, regex_name: 'free_text', errmsg:'Please Enter Department.', min:1},
      contactFormDetails.linkdin ?  {tagid:'linkdin', text: !contactFormDetails?.linkdin.startsWith( 'https://www.linkedin.com'), regex_name:'free_text',  errmsg:'Please start url with https://www.linkedin.com'}:'',
      contactFormDetails.twitter ?  {tagid:'twitter', text: !contactFormDetails?.twitter.startsWith('https://www.twitter.com'), regex_name:'free_text',  errmsg:'Please start url with https://www.twitter.com'}:'',
      {tagid:'email', text: contactFormDetails?.email, regex_name:'email',  required: true, errmsg:'Please Enter Valid Email.'},
      {tagid:'firstname', text:contactFormDetails?.firstname, regex_name: 'text', required: true, errmsg:'Please Enter Valid First Name.'},
      {tagid:'lastname', text:contactFormDetails?.lastname, regex_name: 'text', required: true, errmsg:'Please Enter Valid Last Name.'},
      // {tagid:'officephone', text:contactFormDetails.officephone,  regex_name: 'exact_x_digits', errmsg:'Please Enter Correct Number', x_count:10 },
        Number(check_first_digit) <= 5 ? {tagid:'phone', text:contactFormDetails?.phone, required:true, regex_name: 'exact_x_digits', errmsg:'Please Enter Valid Number' } : {tagid:'phone', text:contactFormDetails?.phone, regex_name: 'exact_x_digits', required: true, errmsg:'Please Enter Valid Number', x_count:10 },
      (contactFormDetails.officephone?.length < 10 && contactFormDetails.officephone?.length > 0 ) ?  {tagid:'officephone', text:contactFormDetails?.officephone, required:true, regex_name: 'exact_x_digits', errmsg:'Please Enter Valid Number' } : '',
      {tagid:'designation', text: contactFormDetails?.designation?.trim(), required: true, regex_name:'text', allow_numbers: true, other_chars: ['()'], errmsg:'Please Select Designation'},
      // {tagid:'linkdin', text:contactFormDetails.linkdin, required: true, regex_name:'website', errmsg:'Please Enter Correct Link'},  //min:1, max: 300
      // {tagid:'twitter', text:contactFormDetails.twitter, required: true, regex_name:'website', errmsg:'Please Enter Correct Link'},  //min:1, max: 300
       {tagid:'birthDate', text: value === null ?'': value, regex_name:'free_text', errmsg: 'Please Select Birth Date', min:1},
       {tagid:'extendedDesignation', text: contactFormDetails?.extendedDesignation?.trim(), regex_name:'free_text', allow_numbers: true, other_chars: ['()'], errmsg:'Please Select Extended Designation'},
    ]
  
    const createContactDetails = (event) =>{
      setContactFormDetails({...contactFormDetails, [event.target.name]:event.target.value})
    }

    const handleChange = (newValue) => {
      const dateMoment = moment(newValue?.$d)
      const dateOnly = dateMoment.format('YYYY-MM-DD');
      setValue(dateOnly)
      // const dateStr = new Date(newValue?.$d).toISOString()
      // setValue(dateStr)
    };

    const submitContactForm = () =>{
      if(validate(validation_input).length == 0){
        setDisableButton(true)
        if(Object.keys(editAllContactDetails).length === 0 ){ 
            setLoader(true)
            contactFormDetails.dob = value
            contactFormDetails.company_id = companyName?.id
            dispatch(createContactForm(contactFormDetails)).then((res)=>{
              if(storeCompanyDetails?.title === "Create Company" ){        // || title==="Create Address"        //this condition is used for to show swal opoup 
                if(res?.payload?.errormsg) {
                  setDisableButton(false)
                  setLoader(false)
                  setError([])
                  return sweetalert('error', res.payload.errormsg )
                }  
                setEditContactDetails({})
                setContactFormDetails({})
                setLoader(false)
                swal({
                  buttons: {
                  Cancel: {
                      text: "Dashboard",
                      value: "Cancel",
                      className: "popup-blue",
                  },
                  Delete: {
                      text: "Company Profile",
                      value: "profile",
                      className: "popup-gray",
                  },
                  },
                  title: `${contactFormDetails?.firstname} ${contactFormDetails?.lastname} Successfully Added for Contact of ${companyName?.label ? companyName?.label:`${companyName?.company_name} (${companyName?.company_type})`}`,
                  className: "custom-modal",
                  closeOnClickOutside: false,
              }).then((value)=>{
                  if(value=='profile'){
                    setDisableButton(false)
                    setError([])
                    setOpenModal(false)  
                    setOpen(false)
                    setOpenAddressModel(false)
                    navigate('/dashboard/profile',{state: companyName?.id})
                  }else{
                    setDisableButton(false)
                    setOpen(false)
                    setOpenModal(false);
                    setOpenAddressModel(false)
                  }
              }) 
              }else{
                if(res?.payload?.errormsg) {
                  setDisableButton(false)
                  setLoader(false)
                  setError([])
                  sweetalert('error', res?.payload?.errormsg )
                  return
                } else {
                  setLoader(false)
                  sweetalert ('success', res?.payload?.data.msg) 
                  setOpenModal(false);
                  setDisableButton(false)
                  setError([])
                  setEditContactDetails({})
                  emptyContactDetails()

                  dispatch(storeContactDetails({contactDetails:res?.payload?.data?.createcontact, title: route}))
                  dispatch(displayContactList(companyName?.id))
                  dispatch(displayCompanyDetails(companyName?.id))
                  setRefresh1(!refresh1)
                } 
              } 
            })
        }else{
          setLoader(true)
          contactFormDetails.dob = value
          contactFormDetails.contact_id = editAllContactDetails?.id
          contactFormDetails.company_id = companyName?.id ? companyName?.id :companyName?.id
          dispatch(editContact(contactFormDetails)).then((res)=>{
           console.log(res, 'res')
            if(res?.payload?.errormsg) {
              setDisableButton(false)
              setLoader(false)
              setError([])
              return sweetalert('error', res.payload.errormsg )
            }
            setDisableButton(false)
            setLoader(false)
            setError([])
            sweetalert ('success', res?.payload?.data) 
            setOpenModal(false);
            emptyContactDetails()
          }) 
        }
      }else{
          setError(validate(validation_input))
      }
    }

  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };

  const handleClose = () => {
    if( title ==="CreateFromDashboard" || title === "CreateFromProfilePage"|| title === "Contact List" || title === 'CreateFromLead'){
      setOpenModal(false)
    }else{
        setOpen(false)
        setOpenModal(false);
     
      emptyContactDetails()
    }
  };

  const emptyContactDetails =() =>{
    setContactFormDetails({
      firstname:'',
      lastname: '',
      linkdin:'',
      twitter:'',
      department:'',
      officephone: '', 
      phone: '',
      email: '',
      dob:'',
      designation:'',
      extendedDesignation:''
    })
  }
 
  // const companyNameList = (title === "CreateFromProfilePage" || title ==="LeadDetailsPage" || (title ==="Contact List" && Object.keys(editAllContactDetails)?.length > 0)) ? dropdownValues?.company_name:''
  // const companyTypeList = (title === "CreateFromProfilePage" || title ==="LeadDetailsPage" || (title ==="Contact List" && Object.keys(editAllContactDetails)?.length > 0)) ? dropdownValues?.company_type:''
  // const remainingNames = `${companyNameList} ${companyTypeList}`?.split(' ')?.map(word => (wordsToRemove.includes(word) ? `(${word})` : word))?.join(' ');

  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >        
                               
        {title === "CreateFromDashboard" || (title === "Contact List" && Object.keys(editAllContactDetails).length === 0) ? <></> : <DialogHeaderCompanyName label={title === 'CreateFromLead'? dropdownValues?.label : storeCompanyDetails?.title === "Create Company" ? storeCompanyDetails?.companyName?.label :`${dropdownValues?.company_name} ${dropdownValues?.company_type}`} onClose={handleClose} title={title} storeCompanyDetails ={storeCompanyDetails}/>}
        <BootstrapDialogTitle sx={{display:'flex', p:0, pl:1, mt: (title === "Contact List" && Object.keys(editAllContactDetails).length === 0) || title ==="CreateFromDashboard"? 2:0, justifyContent:'start',  alignItems:'end',borderRadius:'10px', fontWeight:600, color:'#000000', letterSpacing: "0.04em"}} id="customized-dialog-title" onClose={handleClose} storeCompanyDetails ={storeCompanyDetails} title={title} editContact={editAllContactDetails}>{Object.keys(editAllContactDetails)?.length === 0 ? 'Create Contact' :'Edit Contact'}</BootstrapDialogTitle>
          <DialogContent dividers>

          { title ==="CreateFromDashboard" || title ==="Contact List" && Object.keys(editAllContactDetails).length === 0 ?<>
          <Grid container spacing={{xs:1, sm:2, md:2, lg:3}}>
                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Company Name<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                    <div style={{ position: 'relative' }}>
                        <DropDown sx={{background:'#FFFFFF', mt:1}}
                            id='company_name' 
                            name='companyName'
                            options={allCompanyName?.length > 0 ? displayname(allCompanyName) : []}
                            onChange = {(e, value, reason) => {
                                if(value === null){
                                  setCompanyName('')
                                  setallCompanyName([])
                                  emptyContactDetails()
                                }else{
                                  setCompanyName(value)
                                }
                            }}
                            onSearch={(e) =>{
                              if(e.target.value == '') {
                                  e.preventDefault()
                                  setallCompanyName([])
                                  setButtonStatus(false)
                              } else {
                                  setSearchInput(e.target.value.trim())
                                  setButtonStatus(true)
                              }
                            }}
                            searchinput={searchinput}
                            clearOnBlur={true}
                            getOptionLabel={(option) =>(option.label ? option.label :"")}
                            value = {companyName}
                            placeholder ='Select Company '
                        />
                         {buttonStatus && <Box style={{display: 'flex', alignItems: 'end', position: 'absolute', top: '50%', left: '80%', transform: 'translate(-50%, -50%)'}}>
                                    <TailSpin
                                    height="25"
                                    width="40"
                                    color="blue"
                                    ariaLabel="tail-spin-loading"
                                    radius="2"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    /> <span>
                                    <Typography variant="caption" color="textSecondary">
                                        Loading...
                                    </Typography></span>
                             </Box>}
                      </div>
                        <DisplayError error={error} fortagid='companyName' />
                  </Grid>
            </Grid>
            </>:<>
             {Object.keys(editAllContactDetails).length > 0  && title ==="CompanyProfilePage" ? <></> :<>
               
              </>}</>}

          <Box style={{marginTop:(title ==="CreateFromDashboard" || title ==="Contact List" && Object.keys(editAllContactDetails).length === 0) ?'15px' :"0px", display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                      <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>First Name<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <InputBox  sx={{border:"1px solid #B0AEFF", height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}}  
                          placeholder ="First Name"
                          name='firstname'
                          value={contactFormDetails?.firstname?.trim()}
                          onchange={createContactDetails}
                        />
                        <DisplayError error={error} fortagid='firstname' />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                      <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Last Name<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <InputBox  sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                          placeholder ="Last Name"
                          name='lastname'
                          value={contactFormDetails?.lastname?.trim()}
                          onchange={createContactDetails}
                        />
                        <DisplayError error={error} fortagid='lastname' />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Date of Birth</H5FormLabel>  
                        <DatePickerOpenTo
                            openTo="year"
                            views={['year', 'month', 'day']}
                            name='birthDate'
                            value={value} 
                            maxDate={maxDateValue}
                            onChange={handleChange}
                            style={{background:'#FFFFFF', fontSize:'14px', marginTop:'8px'}} 
                        />
                        <DisplayError error={error} fortagid='birthDate' />
                    </Grid>  
            </Grid>
          </Box>

          <Box style={{marginTop:'20px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Email<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <InputBox  sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                          placeholder ="abc@mail.com"
                          name='email'
                          value={contactFormDetails?.email?.toLowerCase().trim()}
                          onchange={createContactDetails}
                        />
                        <DisplayError error={error} fortagid='email' />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                       <H5FormLabel sx={{color: themeColor.palette.InputLabel, display:'flex', justifyItems:'space-between', alignContent:'center'}}>Designation<span style={{color:'#FF0000'}}>*</span>
                        <Tooltip title={longText}>
                          <span style={{alignItems:'center', display:'flex'}}><WarningIcon/></span>
                        </Tooltip></H5FormLabel>  
                          <SelectDropDown 
                              id ='select_designation'
                              placeholder ="Select Designation"
                              name='designation'
                              options={designationLists} 
                              onchange={createContactDetails}
                              value={contactFormDetails.designation}
                              sx={{p:0.1, mt:1, backgroundColor:'#ffffff',borderRadius:"10px"}}
                              MenuProps={MenuProps} 
                          /> 
                        <DisplayError error={error} fortagid='designation' />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Extended Designation</H5FormLabel>  
                        <InputBox  sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                          placeholder ="Extended Designation"
                          name="extendedDesignation"
                          value={contactFormDetails?.extendedDesignation}
                          onchange={createContactDetails}
                        />
                        <DisplayError error={error} fortagid='extendedDesignation' />
                    </Grid>        
            </Grid>
          </Box>
          <Box style={{marginTop:'20px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                      <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Mobile No<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                        <InputBox  sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                          placeholder ="6789123456"
                          name='phone'
                          value={contactFormDetails?.phone}
                          onchange={(e)=>{
                            if(e.target.value.length <=10)createContactDetails(e)
                           }} 
                           onKeyPress={(e) => {
                            const keyCode = e.keyCode || e.which;
                            const keyValue = String.fromCharCode(keyCode);
                            const numericRegex = /^[0-9]*$/;
                            if (!numericRegex.test(keyValue)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <DisplayError error={error} fortagid='phone' />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Office Phone No</H5FormLabel>  
                        <InputBox sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                          placeholder ="012345678912"
                          name='officephone'
                          value={contactFormDetails?.officephone}
                          // value={contactFormDetails?.officephone == 0 ? '' : contactFormDetails?.officephone}
                          onchange={(e)=>{
                            if(e.target.value.length <=12)createContactDetails(e)
                           }}
                           onKeyPress={(e) => {
                            const keyCode = e.keyCode || e.which;
                            const keyValue = String.fromCharCode(keyCode);
                            const numericRegex = /^[0-9]*$/;
                            if (!numericRegex.test(keyValue)) {
                              e.preventDefault();
                            }
                          }}
                        />
                          <DisplayError error={error} fortagid='officephone' />
                    </Grid>
                    
                    <Grid item xs={12} md={6} sm={6} lg={4}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Department</H5FormLabel>  
                        <InputBox sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                          placeholder ="Department"
                          name='department'
                          value={contactFormDetails?.department?.replace(/\s\s+/g, " ")}
                          onchange={createContactDetails}
                        />
                        <DisplayError error={error} fortagid='department' />
                    </Grid>
            </Grid>
          </Box>
          <Box style={{marginTop:'20px',display:'flex',flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>LinkedIn Profile Link</H5FormLabel>  
                        <InputBox sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} 
                          placeholder ="https://www.linkedin.com"
                          name='linkdin'
                          value={contactFormDetails?.linkdin?.trim()}
                          onchange={createContactDetails}
                        />
                        <DisplayError error={error} fortagid='linkdin' />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                        <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Twitter Profile Link</H5FormLabel>  
                        <InputBox  sx={{border:"1px solid #B0AEFF",height:'40px', color:'#444444', fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}}   
                          placeholder ="https://www.twitter.com"
                          name='twitter'
                          value={contactFormDetails?.twitter?.trim()}
                          onchange={createContactDetails}
                        />
                        <DisplayError error={error} fortagid='twitter' />
                    </Grid>
            </Grid>
          </Box>
          </DialogContent>
          
          <DialogActions sx={{mt:1.5, mb:1.5, mr:1.5}}>
            <Button
              id="save_contact"
              variant="contained"
              disabled={loader}
              onClick={submitContactForm}
              sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3107AA',
                  '&:hover': { backgroundColor: '#3107AA', color: '#FFFFFF' },
                  fontSize: '16px', 
                  fontWeight: '500',
                  borderRadius:"10px",pl:5,pr:5,
                  textTransform: 'none', 
                  padding: '4px 25px', 
                  boxShadow:'0px 4px 6px #e1e1e1'
              }}
              >
              {loader ? <CircularProgress size={20} color="inherit" /> : 'Save'}
            </Button>
          </DialogActions>
        </BootstrapDialog>
    </div>
  );
}

     