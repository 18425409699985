import * as React from 'react';
import { styled, Box, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ProgressBar from './ProgressBar';
import moment from 'moment';
import CallIcon from '../../components/custom/CallIcon';
import MeetingIcon from '../../components/custom/MeetingIcon';
import EmailIcon from '../../components/custom/EmailIcon';
import DemoIcon from '../../components/custom/DemoIcon';
import FollowupTaskIcon from '../../components/custom/FollowupTaskIcon';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardDetails = styled('div')({
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'start',
  alignItems: 'start',
})

const CardInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

export default function FollowupCard({ width, height, followup, task, For, onClick }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return <>
          <Card sx={{ maxWidth: { width }, maxHeight: { height },minHeight:200, borderRadius: '10px', boxShadow: ' 0px 4px 20px rgba(0, 0, 0, 0.2)' }}>
               <CardContent>
               <CardDetails>
               <Typography sx={{wordBreak:'break-all', fontWeight: 600, letterSpacing: 1,fontSize: `${For == 'dash'?'10px':'12px' }`}}>
                 {`${followup?.company?.company_name ?? ''} ${followup?.company?.company_type ?? ''}`}{`${task?.lead_id?.company?.company_name ?? ''} ${task?.lead_id?.company?.company_type ?? ''}`}
                </Typography>
                <CardInfo>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1.5 }}>
                    <Typography onClick={() => onClick(followup?.followup_no ? followup : task)} variant='h6' sx={{ color: followup?.followup_no ? '#4F709C':"#7673F6", mr:1,fontWeight: 600, cursor:'pointer', fontSize: `${For == 'dash'?'10px':'14px'}` }}>
                      {followup?.followup_no} {task?.task_no}
                    </Typography>
                    <Typography variant='h6' sx={{color: followup?.lead_id?.lead_no ? "#C72424" :"#FE8731", fontWeight: 600, cursor:'pointer', fontSize: `${For == 'dash'?'8px':'14px'}` }}>
                      {followup?.lead_id?.lead_no} {followup?.oppo_id?.opportunity_no}{task?.lead_id?.lead_no} {task?.oppo_id?.opportunity_no}
                    </Typography>
                  </Box>
                </CardInfo>
               
                <Typography sx={{ color: '#FFCE00', mt:1, fontWeight: 600, letterSpacing: 1,fontSize: `${For == 'dash'?'10px':'14px' }`}}>
                { followup?.followup_type ? <>
                    <Tooltip title={followup?.followup_type ==="Call" ?
                        "Call": followup?.followup_type ==="Meeting"? "Meeting": followup?.followup_type ==="Email"? "Email":followup?.followup_type  === "Demo" ? "Demo": "Task" } placement="top-start">
                      <IconButton>
                        {followup?.followup_type === "Call" ?
                        <CallIcon/> : followup?.followup_type ==="Meeting"? <MeetingIcon/>:followup?.followup_type ==="Email"? <EmailIcon/>:followup?.followup_type  === "Demo" ? <DemoIcon/>:<FollowupTaskIcon />}
                      </IconButton>
                    </Tooltip></> : task?.task_type}
                </Typography>
              </CardDetails>
              <CardInfo>
                <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1.5 }}>
                  <Typography sx={{fontSize:`${For == 'dash'?'12px':'14px'}`}}>
                    Date {followup?.time && '& time'}
                  </Typography>
                  <Typography sx={{fontSize:`${For == 'dash'?'10px':'14px'}`, color: '#F16500', pt: 0.5 }} >
                    { followup?.followup_date ? moment(Number(followup?.followup_date)).format('D/M/YYYY h:mm A') : moment(task?.end_date).format('D/M/YYYY h:mm A')}
                  {/* {followup?.time ? new Date(Number(followup?.time)).toLocaleString(): new Date(task?.end_date).toLocaleDateString()}  */}
                  </Typography>
                </Box>
              </CardInfo>
              <Typography sx={{ pt: 1, fontSize:`${For == 'dash'?'12px':'15px'}`}}>
                Remaining Days
              </Typography>
              <Typography sx={{pt:1}}>
                <ProgressBar For={For} time={followup?.followup_date ? followup?.followup_date : task?.end_date} /> 
              </Typography>
              {/* <CardHeaders>
        <Typography variant='h6' sx={{color:"#7673F6",fontWeight:600}}>
            TSK-22-01523
        </Typography>
        <Typography sx={{color:'#FFCE00',fontWeight:600,letterSpacing:1}}>
           Assigned
        </Typography>
       </CardHeaders>
       <CardBody>
       <Box sx={{display:'flex',flexDirection:'column',pt:1.5}}>
       <Typography  sx={{fontSize:'1.2rem'}}>
            End Date
        </Typography>
        <Typography sx={{color:'#F16500',pt:0.5}} >
            DD-MM-YYYY
        </Typography>
       </Box>
       <Box sx={{display:'flex',flexDirection:'column',pt:1.5}}>
       <Typography sx={{fontSize:'1.2rem'}}>
            Assigned By
        </Typography>
        <Typography sx={{color:'#F16500',pt:0.5}}>
            Manoj Gholap
        </Typography>
       </Box>
       </CardBody>
       <Typography  sx={{pt:2,fontSize:'1.2rem'}}>
          Remaining Days
        </Typography>
        <Typography>
            <ProgressBar/>
        </Typography> */}
        </CardContent>
          </Card>
        </>
}