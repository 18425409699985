import React, { useState } from 'react'
import { H1FormTitle, H3SubHeader, H5FormLabel, T5Normaltext } from '../../../Theme/theme'
import { IFParent } from './CAF.Ser.IDcss'
import themeColor from '../../../Theme/themeColor'
import { CustemBtn, SelectDropDown } from '../../../components/custom'
import OpportunityForm from '../../Opportunity/OpportunityForm';
import { useDispatch, useSelector } from 'react-redux'
import { getServiceDetails } from '../../../Redux/Thunk/createopportunitythunk'
import AdminRole from '../../../adminrole/Adminrole'
import { displayCompanyDetails } from '../../../Redux/Thunk/createcompanythunk'
import { getServiceProviderTypesList } from '../../../Redux/Thunk/createsettinglist'

const Header = ({name, service, modified_oppo, setOppo_id, For}) => {
  const [openOpportunityModel, setOpenOpportunityModel] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const id = user?.data?.user?.id
  const permissionsDetails = user?.data?.user?.role.permissions
  const dispatch =  useDispatch()
  const [selectedid, setSelectedId] = useState('')
  const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())
  const title = 'changerequest'
  let [checkconditions] = useState(false)

  let find_current_oppo
  
  let check= []

  // if (service?.opportunity_type == 'New') {
  //    check = modified_oppo.filter((oppo) => (oppo.opportunity_type != 'New' && (oppo.servicedetails.status == 'Approved' || oppo.servicedetails.status == 'Pending')))
  // }

   if (modified_oppo?.length > 0 ) {
   // find_current_oppo = modified_oppo.find((id) => id.id == service.id)
    checkconditions =  (( modified_oppo[0]?.opportunity_stage == 'ClosedLost') ) || (modified_oppo[0]?.servicedetails?.status == 'Approved')  // modified_oppo[0]?.cofdetails?.status == 'Rejected' || || modified_oppo[0]?.servicedetails?.status == 'Rejected'
   }

   //console.log(checkconditions, modified_oppo , 'check')
  
  const handleClickEditOppDetails =(id) =>{
    dispatch(displayCompanyDetails(id))
    dispatch(getServiceProviderTypesList())
    setOpenOpportunityModel(true)
}

const handleSelectOpportunity = (e) => {
  setOppo_id(e.target.value)
  setSelectedId(e.target.value)
  dispatch(getServiceDetails(e.target.value))
}


  return (
    <>
      <IFParent>
        <H1FormTitle sx={{color: themeColor.palette.Primarytext1 }}>
             {name} 
        </H1FormTitle>
       
        <T5Normaltext>
          {/* Changed Request button is enabled for service is apporved and modified opp cof is approved */}
             {(((service?.servicedetails?.status == "Approved" && id == service.assigned_to && For == 'Service') || (checkAdminRole && permissionsDetails?.perm_opportunity_create && For == 'Service') || (checkconditions && (id == service?.assigned_to)))) ? 
             <CustemBtn 
             disabled={!checkconditions}
             onClick={() => handleClickEditOppDetails(service?.company?.id)} sx={{color:"#ffffff", backgroundColor:`${!checkconditions ? '#CECECE' :'#3107AA'}`}} text="Change Request" 
             /> : ''}
        </T5Normaltext>
        {/* backgroundColor:`${check.length > 0 ? '#CECECE' :'#3107AA'}`
         (checkAdminRole && permissionsDetails?.perm_opportunity_create && For == 'Service')
        */}
      </IFParent>
       {openOpportunityModel && <OpportunityForm title = {title} editOpportunityDetails={service} setOpenOpportunityModel={setOpenOpportunityModel} openOpportunityModel={openOpportunityModel}/>}
       </>
  )
}

export default Header
