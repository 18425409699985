import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Grid, Box, styled, IconButton,Button, Toolbar, Checkbox, FormControlLabel, Tooltip} from '@mui/material';
import { CompanyName, CustemBtn, DatePicker, SelectDropDown, TaskIcon, TextArea } from '../../components/custom';
import dayjs from 'dayjs';
import validate  from "../../validation/validation";
import DisplayError from '../../validation/DisplayError';
import { useDispatch, useSelector } from 'react-redux';
import { CreateEditFollowup } from '../../Redux/Thunk/createsettinglist';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { getAllWorkingDays } from '../../Redux/Thunk/createTaskthunk';
import { leadLost } from '../../Redux/Thunk/createleadthunk';
import { TailSpin} from 'react-loader-spinner';
import CallIcon from '../../components/custom/CallIcon';
import MeetingIcon from '../../components/custom/MeetingIcon';
import EmailIcon from '../../components/custom/EmailIcon';
import DemoIcon from '../../components/custom/DemoIcon';
import FollowupTaskIcon from '../../components/custom/FollowupTaskIcon';
import { addDays } from 'date-fns';

// const Hours =[
//     { value:'1',label:"1"},{ value:'2',label:"2"}, { value:'3',label:"3"}, { value:'4',label:"4"},{ value:'5',label:"5"}
//     ,{ value:'6',label:"6"} ,{ value:'7',label:"7"} ,{ value:'8',label:"8"} ,{ value:'9',label:"9"} ,{ value:'10',label:"10"}
//     ,{ value:'11',label:"11"} ,{ value:'12',label:"12"}
// ]

// const Minutes =[
//     { value:'01',label:"01"},{ value:'02',label:"02"}, { value:'03',label:"03"}, { value:'04',label:"04"},{ value:'05',label:"05"}
//     ,{ value:'06',label:"06"} ,{ value:'07',label:"07"} ,{ value:'08',label:"08"} ,{ value:'09',label:"09"} ,{ value:'10',label:"10"}
//     ,{ value:'11',label:"11"} ,{ value:'12',label:"12"},{ value:'13',label:"13"},{ value:'14',label:"14"},{ value:'15',label:"15"}
//     ,{ value:'16',label:"16"},{ value:'17',label:"17"},{ value:'18',label:"18"},{ value:'19',label:"19"},{ value:'20',label:"20"},{ value:'21',label:"21"}
//     ,{ value:'22',label:"22"},{ value:'23',label:"23"},{ value:'24',label:"24"},{ value:'25',label:"25"},{ value:'26',label:"26"},{ value:'27',label:"27"},{ value:'28',label:"28"}
//     ,{ value:'29',label:"29"},{ value:'30',label:"30"},{ value:'31',label:"31"},{ value:'32',label:"32"},{ value:'33',label:"33"}
//     ,{ value:'34',label:"34"},{ value:'35',label:"35"},{ value:'36',label:"36"},{ value:'37',label:"37"},{ value:'38',label:"38"}
//     ,{ value:'39',label:"39"},{ value:'40',label:"40"},{ value:'41',label:"41"},{ value:'42',label:"42"},{ value:'43',label:"43"}
//     ,{ value:'44',label:"44"},{ value:'45',label:"45"},{ value:'46',label:"46"},{ value:'47',label:"47"},{ value:'48',label:"48"}
//     ,{ value:'49',label:"49"},{ value:'50',label:"50"},{ value:'51',label:"51"},{ value:'52',label:"52"},{ value:'53',label:"53"}
//     ,{ value:'54',label:"54"},{ value:'55',label:"55"},{ value:'56',label:"56"},{ value:'57',label:"57"},{ value:'58',label:"58"},{ value:'59',label:"59"},{ value:'60',label:"60"}
// ]

// const MorningEvening=[
//     { value:'AM',label:"AM"},{ value:'PM',label:"PM"}
// ]

const Types= [
    { value:'Call',label:"Call"}, { value:'Demo',label:"Demo"}, { value:'Email',label:"Email"},{ value:'Meeting',label:"Meeting"},{ value:'Task', label:"Task"}
]

const callResponces =[
    { value:'Contacted Call Back',label:"Contacted Call Back"}, { value:'Contacted Discussed',label:"Contacted Discussed"}, { value:'Next',label:"Next"},{ value:'No Response',label:"No Response"},{ value:'No Valid Number',label:"No Valid Number"},{ value:'Not Contactable',label:"Not Contactable"}
    ,{ value:'Not Reachable',label:"Not Reachable"}, { value:'Phone Switched Off',label: "Phone Switched Off"}
]

const CardBody = styled('div')({
    padding:'5px',
    borderRadius:'10px',
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  function BootstrapDialogTitle(props) {
    const { children, onClose, title, ...other } = props;
  
    return (
      <>
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
          {children}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                // alignItems:'center',
                right: 10,
                top: 11,
                // color: (theme) => theme.palette.grey[500],
                 color:'#FFFFFF',
                //  background:'#FFFFFF'
              }}
            >
              <CloseIcon />
            </IconButton>
        </DialogTitle>
      </>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  
const CreateFollowUp = ({companyName, title, followupClosed, open, setUpdate, update, setOpenFollowupPopup, followUpFor, selectedLeadDetails, selectedOpportunityDetails, editFollowupDetails}) => {
    // const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {user} = useSelector((state) => state.auth)
    const [error,setError] = useState([])
    const [value, setValue] = useState(dayjs());   //'2014-08-18T21:11:54'
    const [createFollowUpType, setCreateFollowUpType] = useState('')
    const [selectedAmPm, setSelectedAmPm] = useState('AM')
    const [MissingDeadLine, setMissingDeadLine] = useState('')
    const [closingRemark, setclosingRemark] = useState('');
    const [callResponce, setCallResponce] = useState('')
    const [disableButton, setDisableButton] = useState(false)
    const [checked, setChecked] = useState(false);
    const wordsToRemove = ["TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"];
    const [createNewFollowupDate, setCreateNewFollowupDate] = useState('');   
    const [createNewFollowUpType, setCreateNewFollowUpType] = useState('')
    const [createNewFollowupHour, setCreateNewFollowupHour] = useState('');
    const [createNewFollowupMinute, setCreateNewFollowupMinute] = useState('');
    const [createNewFollowupAmPm, setCreateNewFollowupAmPm] = useState('AM')
    const currentDate = new Date()
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedMinute, setSelectedMinute] = useState('');
    const [selectedHour, setSelectedHour] =useState('')
    const [displayWorkingDays, setDisplayWorkingDays] = useState([])
    const [leadLostButton, setLeadLostButton] = useState(false)
    const [leadLostReason, setLeadLostReason] = useState('')
    const [convertToOpportunity, setConvertToOpportunity]= useState(false)
    const [createNewFollowup, setCreateNewFollowup] = useState(false)
    const [editFollowupForDate, setEditFollowupForDate] = useState('')
    const [editFollowupForHour, setEditFollowupForHour] =useState('')
    const [createNewFollowupPage, setCreateNewFollowupPage] =useState(false)
    const [openOpporunityModel, setOpenOpporunityModel] =useState(false)
    const [loader, setLoader]= useState(false)
    const leadDetails = (title === "Lead List" || title === "companyProfilePageLeadDetails"|| title ==="FollowupLeadDetails") ? selectedLeadDetails : editFollowupDetails?.responseFollowup
    const route ="Create followup"
    const [startDate, setStartDate] = useState('')
    const [showcalender, setShowCalender] = useState(false)

    useEffect(()=>{
        dispatch(getAllWorkingDays({workingdays:7})).then((res)=>{
         setDisplayWorkingDays(res?.payload?.data)
        }) 
    },[])

    useEffect(() => {
        if(Object.keys(editFollowupDetails)?.length > 0){
            const time= (editFollowupDetails ?? {})?.followup_date
            const momentObj = moment(time);
            // moment(timestamp).format('YYYY-MM-DD')
            setEditFollowupForDate(momentObj.format('DD MMM'))
            // setSelectedHour(value <= "09" ? Number(value.charAt(1)) :value)
            // setSelectedMinute(moment(momentObj).format('mm'))
          
            // setSelectedAmPm(moment(momentObj).format('A'))
            setEditFollowupForHour(moment(momentObj).format("hh:mm A"))
            // const date = new Date(Number(time));
            // const timeString = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
            // const amPmString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).slice(-2);
            // const [Hour, Minute] = timeString.split(':');
            // setSelectedMinute(Minute?.slice(0,2))
            // setSelectedHour(Hour)
            // setSelectedAmPm(amPmString)
             setCreateFollowUpType((editFollowupDetails ?? {})?.followup_type)
            //  setSelectedDate(moment(date).format('YYYY-MM-DD'))
             setMissingDeadLine((editFollowupDetails ?? {})?.missing_deadline_mark)
             setclosingRemark((editFollowupDetails ?? {})?.summary)
             setCallResponce((editFollowupDetails ?? {})?.call_response)
        }
    },[])
   
     const handleHourChange = (e) => {
    //     if(checked === true){
    //         setCreateNewFollowupHour(e.target.value)
    //     }else{
    //         setSelectedHour(e.target.value);
    //     } 
    };
  
     const handleMinuteChange = (e) => {
    //     if(checked === true){
    //         setCreateNewFollowupMinute(e.target.value)
    //     }else{
    //         setSelectedMinute(e.target.value);
    //     }
    };

     const handleClickOpen = () => {
    //     setOpenFollowupPopup(true);
     };
  
     const handleAmPm= (e) => {
    //     if(checked === true){
    //         setCreateNewFollowupAmPm(e.target.value)
    //     }else{
    //         setSelectedAmPm(e.target.value);
    //     }
    };
  
    console.log(editFollowupDetails, 'editFollowupDetails')
//console.log(typeof(createNewFollowupHour), typeof(createNewFollowupMinute), "JJJJJJJJJJJJJJJJJJJJJJJJJJ")
    let validation_input = [
        Object.keys(editFollowupDetails)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ?'value':'date', text:createNewFollowup === true ? startDate ? '1233':createNewFollowupDate :startDate ? '1233': selectedDate, regex_name: 'free_text', required: true, errmsg: 'Please Select Date.'} :<></>,
        Object.keys(editFollowupDetails)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ? "createMin":'min', text: createNewFollowup === true ? createNewFollowupMinute: selectedMinute, regex_name: 'any_number', required: true, errmsg: 'Please Select Minute.', min:1}:<></>,
        Object.keys(editFollowupDetails)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ? "createHr":'hr', text: createNewFollowup === true ? createNewFollowupHour?.toString(): selectedHour?.toString(), regex_name: 'any_number', required: true, errmsg: 'Please Select Hour.', min:1}:<></>,
        Object.keys(editFollowupDetails)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ? "createampm":'am_pm', text: createNewFollowup === true ? createNewFollowupAmPm: selectedAmPm, regex_name: 'text', required: true, errmsg: 'Please Select AM/PM'}:<></>,
        Object.keys(editFollowupDetails)?.length === 0 || createNewFollowup === true? {tagid:createNewFollowup === true ? "createType":'type', text: createNewFollowup === true ? createNewFollowUpType :createFollowUpType, required: true, regex_name:'text', errmsg: 'Please Select Type.'} :<></>,
        currentDate >  new Date(editFollowupDetails?.followup_date) ? {tagid:'missingDeadLineRemark', text: MissingDeadLine, required: true, regex_name: 'free_text', errmsg: 'Please add the missing dead line remark between 100 to 2000 characters', min:100, max:2000 } :<></>,
        Object.keys(editFollowupDetails)?.length > 0? {tagid:'closingRemark', text: closingRemark, required: true,regex_name:'free_text', errmsg: 'Please add the closing Remark between 100 to 2000 characters', min:100, max:2000 } :<></>,
        editFollowupDetails?.followup_type ==="Call" ? {tagid:'callResponce', text: callResponce, required: true, regex_name:'text', allow_numbers: true, other_chars: true, errmsg:'Please Select Type.'} :<></>,
    ]
  console.log(error)
    // let validation_input1 = [
    //     deadLineMark ? {tagid:'missingDeadLineRemark', text: MissingDeadLine, required: true, regex_name: 'free_text', errmsg: 'Please add the missing dead line remark between 100 to 2000 characters', min:100, max:2000 } :<></>,
    //     {tagid:'closingRemark', text: closingRemark, required: true,regex_name:'free_text', errmsg: 'Please add the closing Remark between 100 to 2000 characters', min:100, max:2000 },
    //     editFollowupDetails?.followup_type ==="Call" ? {tagid:'callResponce', text: callResponce, required: true, regex_name:'text', allow_numbers: true, other_chars: true, errmsg:'Please Select Type.'} :<></>,
    // ]

    let validation_input2 = [
        { tagid: 'summary', text:leadLostReason, required: true, regex_name: 'free_text', errmsg: 'Please add the lost reason between 100 to 2000 characters', min:100, max:2000},  //min:1, max: 300
    ]

    const handleChangeForCreateFollowup = (event) => {
        setChecked(event.target.checked); 
    }; 

    const handleClose = () => {
        setOpenFollowupPopup(false);
    };

    const handleOfCreateFollowUpDetails =(event)=>{
        if(createNewFollowup === true){
            setCreateNewFollowUpType(event.target.value)
        }else{
            setCreateFollowUpType(event.target.value)
        }
    }
  
    const changeScheduledDate= (newValue) =>{
    //     if(checked === true){
    //         setCreateNewFollowupDate(newValue?.$d);
    //     }else{
    //         setValue(newValue?.$d);
    //     }
     }

    const handleCalender =() => {
        setSelectedDate('')
        setCreateNewFollowupDate('')
        setShowCalender(true)
    }

    const changeStartDate = (newValue) => {
        setShowCalender(false)
        if(createNewFollowup) {
         setCreateNewFollowupDate(newValue?.$d.toString());
        } else {
            setSelectedDate(newValue?.$d.toString())
        }
        
        setStartDate(newValue?.$d);
    }

    const handleOfCallResponce =(event)=>{
        setCallResponce(event.target.value)
    }

    const handleOfMissingDeadLine =(event)=>{
        setMissingDeadLine(event.target.value)
    }

    const handleOfLeadLost =(event)=>{
      setLeadLostReason(event.target.value)
    }

    const handleOfClosingRemark=(event)=>{
        setclosingRemark(event.target.value)
    }
console.log(selectedDate, selectedAmPm, selectedHour,selectedMinute,"Allllllllllllllllll")

    const callFollowupAPI=(timestamp)=>{
        const myJson= {
            create :{
                // companyid:checked === true ? (title==="Alert List" )? editFollowupDetails?.company?.id :editFollowupDetails?.company :(title ==="Opportunity List"|| title==='companyProfilePageOpportunityDetails'||title==="OpportunityDetailsPage") ? selectedOpportunityDetails?.company?.id :(title ==="Lead List"|| title ==="companyProfilePageLeadDetails" || title==="LeadDetailsPage" ||title==="DashboardLeadDetails")? selectedLeadDetails?.company?.id : selectedLeadDetails?.company,
                companyid:createNewFollowup === true ? (title==="Alert List" )? editFollowupDetails?.company?.id :editFollowupDetails?.company :(title ==="Opportunity List"|| title==='companyProfilePageOpportunityDetails'||title==="OpportunityDetailsPage") ? selectedOpportunityDetails?.company?.id :selectedOpportunityDetails?.company ? selectedOpportunityDetails?.company :(title ==="Lead List"|| title ==="companyProfilePageLeadDetails" || title ==="LeadDetailsPage" ||title === "DashboardLeadDetails"|| title ==="FollowupLeadDetails")? selectedLeadDetails?.company?.id : selectedLeadDetails?.company,
                // followup_for_id:createNewFollowup === true ? editFollowupDetails?.followup_for_id : selectedOpportunityDetails?.id ? selectedOpportunityDetails?.id :selectedLeadDetails?.id ,
                followup_for_id:createNewFollowup === true ? editFollowupDetails?.followup_for_id : Object.keys(editFollowupDetails).length >0 && (leadLostButton === true || convertToOpportunity === true)?'':selectedLeadDetails?.id ,  
                operationmode:'create',
                followup_date: timestamp,
                followup_type: createNewFollowup === true ? createNewFollowUpType :createFollowUpType,
                followup_for: createNewFollowup === true ? editFollowupDetails?.followup_for: selectedLeadDetails?.id ? 'lead' :'opportunity',
                // time: timestamp, 
            },
            edit:{
                followupid: editFollowupDetails?.id, 
                operationmode:'edit',
                summary: closingRemark,
                missing_deadline_mark: MissingDeadLine, 
                call_response: callResponce ,
            }
        }

        setDisableButton(true)
        console.log(myJson,"jsonvalueeeeeeeeeee")

        dispatch(CreateEditFollowup(myJson)).then((res)=>{
            setOpenFollowupPopup(false)
            setDisableButton(false)
                if(res?.payload?.errormsg){
                    sweetalert("error", res?.payload?.errormsg)
                    setLoader(false)
                }else{
                    setLoader(false)
                    if(myJson.create?.followup_for_id && myJson.edit?.followupid){
                        if(title === 'companyProfilePage'){
                            setOpenFollowupPopup(false)
                            navigate('/dashboard/profile/companyFollowup',{state:title?.companyName})
                            sweetalert('success',res?.payload?.data)
                        }else if(title?.title==="Alert List"){
                            setOpenFollowupPopup(false)
                            sweetalert('success', res?.payload?.data)
                            navigate('/dashboard/alertlist', {state:"followup"})
                        }else if(title === 'Lead List'|| title === "companyProfilePageLeadDetails"|| title ==="FollowupLeadDetails"){
                            setOpenFollowupPopup(false)
                            sweetalert('success', res?.payload?.data)
                        }else{
                            setOpenFollowupPopup(false)
                            setUpdate(!update)
                            sweetalert('success',res?.payload?.data)
                        }
                    }else if(myJson.create?.followup_for_id){ 
                        if(title?.title === "Follow-Up List"|| title ==="CreateFromDashboard" || title === 'companyProfilePage'){
                            swal({
                                buttons: {
                                Cancel: {
                                    text: "Dashboard",
                                    value: "Cancel",
                                    className: "popup-blue",
                                },
                                Delete: {
                                    text: "Followup List",
                                    value: "followupList",
                                    className: 'popup-gray',
                                },
                                },
                                title: `Follow-up (${res?.payload?.data?.followupid}) Successfully Created.`,
                                className: "custom-modal",
                                closeOnClickOutside: false,
                            }).then((value)=>{
                                if(value =='followupList'){
                                    if(title === 'companyProfilePage'){
                                        navigate('/dashboard/profile/companyFollowup',{state:title?.companyName})
                                    }else{
                                        navigate('/dashboard/followuplist')
                                    }       
                                }else{
                                    navigate('/dashboard')
                                }
                            })}else if(title==="Lead List" || title==="companyProfilePageLeadDetails"|| title==="DashboardLeadDetails"|| title ==="LeadDetailsPage" || title==='companyProfilePageOpportunityDetails'||title ==="FollowupLeadDetails"){
                                setOpenFollowupPopup(false)
                                sweetalert('success', `Follow-up (${res?.payload?.data?.followupid}) Successfully Created.`)
                            }else if(title?.title==="Alert List"){
                                setOpenFollowupPopup(false)
                                sweetalert('success', `Follow-up (${res?.payload?.data?.followupid}) Successfully Created.`)
                                navigate('/dashboard/alertlist', {state:"followup"})
                            }
                            else{
                                setOpenFollowupPopup(false)
                                sweetalert('success', `Follow-up (${res?.payload?.data?.followupid}) Successfully Created.`)
                                navigate('/dashboard/profile/companyFollowup', {state:title?.companyName})
                            }
                    }else{
                        if(convertToOpportunity === true){
                            swal({
                                buttons: {
                                    Delete: {
                                        text: "Convert to Opportunity",
                                        value: "Yes",
                                        className: "popup-gray1",
                                    },
                                },
                                title: `Followup ${editFollowupDetails?.followup_no} for Lead ${leadDetails?.lead_no} is Successfully Closed. Please Convert this Lead into Opportunity.`,
                                className: "custom-modal",
                                closeOnClickOutside: false,
                            }).then((value) => {
                                if (value == 'Yes') {
                                    navigate('/dashboard/opportunity', {state :{title: route, company: leadDetails?.company, leadDetails:leadDetails, operation:'createOpp'}})
                                }
                            })
                        }else{
                        setOpenFollowupPopup(false)
                        setUpdate(!update)
                        sweetalert('success',`Followup ${editFollowupDetails?.followup_no} Closed successfully.`)
                    }
                    }
                }
        })
    }

    const SubmitFollowUp = () => {
        if(validate(validation_input).length == 0){
            setLoader(true)
            const date = new Date(selectedDate);
            date.setHours(
            parseInt(selectedHour) + (selectedAmPm === 'PM' && selectedHour !== '12' ? 12 : 0),
            parseInt(selectedMinute),  0, 0 );
            const timestamp = date?.getTime();

            const createdate = new Date(createNewFollowupDate);
            createdate.setHours(
            parseInt(createNewFollowupHour) + (createNewFollowupAmPm === 'PM' && createNewFollowupHour !== '12' ? 12 : 0),
            parseInt(createNewFollowupMinute),  0, 0 );
            const timestamp1 = createdate?.getTime();

            const now = moment()
            if(timestamp <= now.valueOf() || timestamp1 <= now.valueOf()){
                sweetalert("error","Time selection can not be for the past, please select future time.")
                setLoader(false)
            }else{
              if(leadLostButton === true){
                swal({
                    buttons: {
                        Delete: {
                            text: "No",
                            value: "No",
                            className: "popup-gray",
                        },
                        Cancel: {
                            text: "Yes",
                            value: "Yes",
                            className: "popup-blue",
                        }
                    },
                    title: `Do you want close the Follow-up (${editFollowupDetails?.followup_no}) with LOST status for Lead (${leadDetails?.lead_no})`,
                    className: "custom-modal",
                    closeOnClickOutside: false,
                }).then((value) => {
                    if (value == 'Yes') {
                        callFollowupAPI(timestamp)
                        dispatch(leadLost({lead_id: leadDetails?.id , lost_reason:closingRemark }))
                    } else {
                      console.log("HEllooo")
                      setLoader(false)
                    }
                })
              }else if(createNewFollowup=== true){
                callFollowupAPI(timestamp1)
              }else{
                callFollowupAPI(timestamp)
              } 
     }    
    }else{
        setError(validate(validation_input))
    } 
    }

    const sweetalert = (variant, text) => {
        swal({
          icon: variant==='error' ? 'error' :'success',
          title: text , 
          button: "Ok!",
        }); 
    };

      // Handler for selecting date
    const sumbitDate = (label) => {
        // if(moment(label?.day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')){
        //     console.log("JEEEEEEEEEE")
        //     setSelectedDate(label?.day)
        //     const now = moment();
        //     setSelectedHour(now.format('hh'))
        //     setSelectedMinute(now.format('mm'))
        //     setSelectedAmPm(now.format('A'))
        // }else{
            if(createNewFollowup === true){
                setShowCalender(false)
                setStartDate('')
                setCreateNewFollowupDate(label?.date);
                setCreateNewFollowupHour('')
                setCreateNewFollowupMinute('')
            }else{
                setShowCalender(false)
                setStartDate('')
                setSelectedDate(label?.date)
                setSelectedHour('')
                // setSelectedAmPm('')
                setSelectedMinute('')
            }
    };
    
    // Handler for selecting minute
    const sumbitMinute = (label) => {
        if(createNewFollowup === true){
            setCreateNewFollowupMinute(label)
            setSelectedMinute('')
        }else{
            setSelectedMinute(label);
            setCreateNewFollowupMinute('')
        }
    };

    const handleButtonClick = (number) => {
         // Handle button click event
        if(createNewFollowup === true){
            setCreateNewFollowupHour(number)
            selectedHour('')
        }else{
            setSelectedHour(number,"numbeeeeeee")
            setCreateNewFollowupHour('')
        }
    };

    const renderHoursButtons = () => {
        const buttons = [];
        for (let i = 1; i <= 12; i++) {
          buttons.push(
            <Button
                variant={selectedHour=== i || createNewFollowupHour === i ? 'contained' : 'outlined'}
                // disabled={moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? selectedHour >= i :''}
                onClick={() => handleButtonClick(i)}
                sx={{
                    padding: '5px 5px',
                    minWidth: '50px',
                    color: selectedHour === i || createNewFollowupHour === i ? '#FFFFFF' : '#444444',
                    background: selectedHour === i || createNewFollowupHour === i ? '#8AA000' : 'transparent',
                    marginLeft:'12px',
                    "&:hover": {
                        background: selectedHour === i || createNewFollowupHour === i ? '#8AA000' : 'transparent', 
                    }
                }} 
            >
                {i}
            </Button>
          );
        }
        return buttons;
    };

    const renderMinutesButtons = () => {
        const numbers = ['00', '15', '30', '45'];
        return numbers.map((number) => (
          <Grid item key={number} xs={1}>
            <Button
                variant={selectedMinute === number || createNewFollowupMinute === number ? "contained" : "outlined"}
                onClick={() => sumbitMinute(number)}
                fullWidth
                // disabled={moment(selectedDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? selectedMinute >= number :''}
                sx={{
                    borderRadius: '5px',
                    background: selectedMinute === number || createNewFollowupMinute === number ? '#8AA000' : 'transparent',
                    color: selectedMinute === number ||createNewFollowupMinute === number ? '#FFFFFF' : '#6E7100',
                    fontWeight: selectedMinute === number || createNewFollowupMinute === number ? 600 : 400,
                    minWidth:'50px',
                    "&:hover": {
                        background: selectedMinute === number || createNewFollowupMinute === number ? '#8AA000' : 'transparent', 
                    }
                }}
                >
                {number}
            </Button>
          </Grid>
        ));
    };

    const submitAmPm=(number, buttonType)=>{
        if(buttonType === "AM"){
            if(createNewFollowup === true){
                setCreateNewFollowupAmPm(buttonType)
            }else{
                setSelectedAmPm(buttonType)
            }
        }else{
            if(createNewFollowup === true){
                setCreateNewFollowupAmPm(buttonType)
            }else{
                setSelectedAmPm(buttonType)
            }       
        }
    }

    const handleClickCreateNewFollowup = ()=>{
        setConvertToOpportunity(false) 
        setLeadLostButton(false)
        setDisableButton(true)
        setCreateNewFollowupPage(true)
        setCreateNewFollowup(true)
    }
    
    const handleClickConvertToOpportunity = ()=>{
        setConvertToOpportunity(true) 
        setLeadLostButton(false)
        setCreateNewFollowupPage(false)
        setDisableButton(true)
        setCreateNewFollowup(false)
    }
console.log(selectedLeadDetails,"selectedLeadDetails")
    const companyNameList = Object.keys(editFollowupDetails).length > 0
    ? (title === "Alert List" 
        ? editFollowupDetails?.company?.company_name
        : title === "Lead List"? companyName?.company_name : leadDetails?.company?.company_name)
    : (title === "Lead List" || title === "companyProfilePageLeadDetails" || title === "DashboardLeadDetails" || title === "companyProfilePageOpportunityDetails")
        ? companyName?.company_name
        : '';

    const companyTypeList = Object.keys(editFollowupDetails).length > 0
    ? (title === "Alert List"
        ? editFollowupDetails?.company?.company_type
        : title === "Lead List"? companyName?.company_type : leadDetails?.company?.company_type)
    : (title === "Lead List" || title === "companyProfilePageLeadDetails" || title === "DashboardLeadDetails" || title === "companyProfilePageOpportunityDetails")
        ? companyName?.company_type
        : '';

    const remainingNames = `${companyNameList} ${companyTypeList}`?.split(' ')?.map(word => (wordsToRemove.includes(word) ? `(${word})` : word))?.join(' ');

    return (
      <div>
        <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <BootstrapDialogTitle sx={{backgroundColor:'#3107AA', display:'flex', color:'white', justifyContent:'center', fontWeight:600, p:1, borderRadius:'10px', m:1, letterSpacing: "0.04em"}} id="customized-dialog-title" onClose={handleClose} title={title}>{remainingNames}</BootstrapDialogTitle> 

        <DialogContent dividers>
         
        {/* {Object.keys(editFollowupDetails)?.length===0 ? 
            <Typography sx={{pl:1, fontWeight:600, fontSize:'18px'}} onClose={handleClose}>
            Create  Follow-Up
            </Typography> :
            <Typography sx={{pl:1, fontWeight:600, fontSize:'18px'}}  onClose={handleClose}>
                Close Follow-Up
            </Typography> }  */}

        {Object.keys(editFollowupDetails)?.length === 0 ? <>

    {/*  Buttons for 11 days  */}
                     <Box style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                     <Grid container spacing={2}>
                     <Grid item xs={12} md={4} sm={2} lg={12}>
                        <Typography sx={{fontWeight:600, fontSize:'18px'}}  onClose={handleClose}>
                            Create  Follow-Up
                        </Typography> 
                     </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{fontWeight:600}}> Scheduled Date & Time <span style={{color:'#FF0000'}}>*</span></Typography>
                        </Grid>
                        {displayWorkingDays.map((label, index) => (
                            <Grid item xs={6} sm={4} md={3} lg={2} key={index} sx={{ paddingTop: '10px' }}>
                                <Button
                            variant={selectedDate === label?.date? 'contained' : 'outlined'}
                            onClick={() => sumbitDate(label)}
                            fullWidth
                            name='date'
                            sx={{
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border:label?.holiday ===true ?'1px solid red':'1px solid #8AA000',
                                bgcolor: selectedDate === label?.date ? '#8AA000' :'transparent',
                                // color: selectedDate === label?.day? '#FFFFFF' : '#6E7100',
                                fontWeight: selectedDate === label?.date ? 600 : 400,
                                "&:hover": {
                                 bgcolor: selectedDate === label?.date ? '#8AA000' :'transparent'}
                            }}
                            >
                            <Typography  sx={{ fontSize:'14px', lineHeight: 1, mb:0.5, color: selectedDate === label?.date ? '#FFFFFF' : '#6E7100', fontWeight:600}}>{moment(label?.date).format("dddd")}</Typography>
                            <Typography sx={{ fontSize:'12px', lineHeight: 1, color: selectedDate === label?.date ? '#FFFFFF' : 'black', fontWeight:600,mt:0.5 }}>{moment(label?.date).format(" Do MMMM ")}</Typography>
                               </Button>
                            </Grid>
                        ))} 
                         <Grid item xs={6} sm={4} md={3} lg={2} sx={{ paddingTop: '10px' }}>
                              <Button
                              
                            variant={startDate ? 'contained' : 'contained'}
                           // onClick={() => sumbitDate(label)}
                            fullWidth
                            name='date'
                            sx={{
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border:'1px solid #8AA000',
                                background: startDate ? '#8AA000' :'#344feb',
                                // color: selectedDate === label?.day? '#FFFFFF' : '#6E7100',
                                fontWeight: startDate ? 600 : 400,
                                "&:hover": {
                                 bgcolor: startDate? '#8AA000' :'#344feb'}
                            }}
                            >
                             {
                              (
                               () => {
                                if(showcalender) {
                                 return (
                                    <>
                                     <Typography  sx={{ fontSize:'14px', lineHeight: 1, mb:0.5, color: '#FFFFFF', fontWeight:600}}>Select Date</Typography>
                                         <Typography  sx={{ fontSize:'12px', lineHeight: 1, color:'#ffffff', fontWeight:600,mt:0.5 }}>Show More</Typography>
                                <DatePickerOpenTo
                                open={true}
                                name='startDate'
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={startDate}
                                onChange={changeStartDate}
                                minDate={addDays(new Date(displayWorkingDays[displayWorkingDays?.length - 1].date), 1)}
                                maxDate={addDays(new Date(displayWorkingDays[displayWorkingDays?.length - 1].date), 23)}
                                style={{ height:'0px', width:'0px', visibility:'hidden',backgroundColor:  '#ffffff', fontSize: '14px', lineHeight: '17px', width: '100%', p: 0.1, mt: 1, pl: 1 }} 
                            
                            />
                                    </>
                                 )
                                } else if(startDate) {
                                    return (
                                        <>
                                            <Typography sx={{ fontSize:'14px', lineHeight: 1, mb:0.5, color: startDate ? '#FFFFFF' : '#6E7100', fontWeight:600}}>{moment(startDate).format("dddd")}</Typography>
                                            <Typography sx={{ fontSize:'12px', lineHeight: 1, color: startDate ? '#FFFFFF' : 'black', fontWeight:600,mt:0.5 }}>{moment(startDate).format(" Do MMMM ")}</Typography>
                                        </>
                                    )
                                } 
                                else {
                                    return (
                                        <Box onClick={() => handleCalender()}>
                                         <Typography  sx={{ fontSize:'14px', lineHeight: 1, mb:0.5, color: '#FFFFFF', fontWeight:600}}>Select Date</Typography>
                                         <Typography  sx={{ fontSize:'12px', lineHeight: 1, color:'#ffffff', fontWeight:600,mt:1 }}>Show More</Typography>
                                        </Box>
                                    )
                                }
                               }
                              )()
                             }
                               </Button>     
                         </Grid>  
                    </Grid> 
                </Box>
                <div>
                <DisplayError error={error} fortagid='date' /> </div> 

    {/*  Buttons for hours , minutes and am/pm   */}
                <Box style={{marginTop:"30px", display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} container >
                            <Typography variant="body1" style={{textAlign: 'center', color: '#6E7100', fontWeight: 600 }}>Hours</Typography>
                            <Grid item xs={11} container spacing={1} justifyContent="space-between">
                                {renderHoursButtons()} 
                            </Grid>
                            <div style={{marginLeft:'50px'}}><DisplayError error={error} fortagid='hr'/></div>
                        </Grid>
                    </Grid>
                </Box>

                   {/* Minutes buttons are display */}
                   <Box style={{marginTop:"30px", display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                        <Grid container spacing={2}>
                    <Grid item xs={1} sx={{ textAlign: 'center' }}>
                        <Typography variant="body1" style={{ color: '#6E7100', fontWeight: 600}}>Minutes</Typography>
                    </Grid>
                    <Grid item xs={9} container spacing={2}>
                        <Grid item xs={4} container spacing={2} justifyContent="space-between">
                            {renderMinutesButtons()}
                        </Grid>   
                   </Grid>
                  
                    <Grid item xs={2} container spacing={1} justifyContent="space-between">
                        <Button variant="outlined" style={{ minWidth: '50px', background: selectedAmPm ==='AM'? '#8AA000' : 'transparent',marginTop:'auto',color:selectedAmPm ==='AM'? '#FFFFFF':'#444444'}} onClick={()=>submitAmPm(null, 'AM')}>
                            AM
                        </Button>
                        <Button variant="outlined" style={{ minWidth: '50px', marginTop:'auto', background : selectedAmPm ==='PM'? '#8AA000': '#FFFFFF', color: selectedAmPm ==='PM'? '#FFFFFF':'#444444'}} onClick={()=> submitAmPm(null, 'PM')}>
                            PM
                        </Button>
                        <DisplayError error={error} fortagid='am_pm' />
                    </Grid>
                        <div style={{marginLeft:'90px'}}><DisplayError error={error} fortagid='min'/></div>
                        <div style={{ marginLeft: 'auto', fontSize:'14px'}}>
                         <span>{selectedHour} {':'} {selectedMinute} {selectedAmPm}</span>
                        </div>
                    </Grid> 
                  </Box>

                <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={4} lg={6}>
                    <Typography sx={{fontWeight:600}}>Type<span style={{color:'#FF0000'}}>*</span></Typography>  
                    <SelectDropDown 
                        name='type'
                        placeholder ='Select Type'
                        options={Types} 
                        onchange={handleOfCreateFollowUpDetails}
                        value={createFollowUpType}
                        disabled={Object.keys(editFollowupDetails)?.length > 0}
                        sx={{p: 0.1, mt: 0.5, backgroundColor: Object.keys(editFollowupDetails)?.length > 0 ? "#E9E9FF" : '#ffffff', borderRadius: "10px"}}
                    />
                    <DisplayError error={error} fortagid='type' />
                </Grid>
                </Grid>
                </> :<>
                <Grid container sx={{display:'flex',flexDirection:'row', flexWrap:'wrap',justifyContent:'space-between'}} >
                    <Grid item xs={12} md={4} sm={12} lg={12}>
                        <Typography sx={{pb:1, fontWeight:600, fontSize:'18px'}}  onClose={handleClose}>
                            Close Follow-Up
                        </Typography> 
                    </Grid>
                    <Grid item xs={12} md={4} sm={2} lg={2}>
                        <Typography sx={{fontWeight:600, fontSize:'14px'}}> Follow-up ID</Typography> 
                        <Typography sx={{fontWeight:600, fontSize:'16px', mt:0.2, color:'#4F709C'}}>{editFollowupDetails?.followup_no}</Typography>  
                    </Grid>
                    <Grid item xs={12} md={4} sm={2} lg={2}>
                        <Typography sx={{fontWeight:600, fontSize:'14px'}}>Lead ID</Typography> 
                        <Typography sx={{mt:0.2, fontWeight:600, fontSize:'16px', color:'#C72424'}}>{leadDetails?.lead_no}</Typography>  
                    </Grid>

                    <Grid item xs={12} md={4} sm={2} lg={3}>
                        <Typography sx={{fontWeight:600, fontSize:'14px'}}> Scheduled Date & Time</Typography> 
                        <Typography sx={{mt:0.2, fontSize:'16px'}}> {editFollowupForDate} {editFollowupForHour}</Typography>  
                    </Grid>
                    <Grid item xs={12} md={4} sm={2} lg={1}>
                        <Typography sx={{fontWeight:600, fontSize:'14px'}}>Type</Typography> 
                        <Typography sx={{mt:0.2}}> 
                            <Tooltip title={createFollowUpType?.followup_type ==="Call" ?"Call": 
                                createFollowUpType?.followup_type ==="Meeting"? "Meeting": createFollowUpType?.followup_type ==="Email"? "Email":createFollowUpType?.followup_type  === "Demo" ? "Demo": "Task" } placement="top-start">
                                <IconButton>
                                    {createFollowUpType ==="Call" ? <CallIcon/> :createFollowUpType ==="Meeting"? <MeetingIcon/>:createFollowUpType ==="Email"? <EmailIcon/> :createFollowUpType === "Demo" ? <DemoIcon/>:<FollowupTaskIcon />}
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sm={2} lg={2}>
                        <Typography sx={{fontWeight:600, fontSize:'14px'}}>Validation</Typography> 
                        <Typography sx={{mt:0.2}}>{leadDetails?.validated_status ==="Validated" ? <>
                         <Tooltip title="Lead is Validated" placement="top-start">
                         <IconButton>
                           <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M4.74789 5.85463C4.79433 5.90112 4.84947 5.938 4.91017 5.96316C4.97087 5.98832 5.03594 6.00128 5.10164 6.00128C5.16735 6.00128 5.23241 5.98832 5.29311 5.96316C5.35381 5.938 5.40896 5.90112 5.45539 5.85463L11.2479 0.147132C11.2943 0.100643 11.3495 0.063764 11.4102 0.0386019C11.4709 0.0134397 11.5359 0.000488281 11.6016 0.000488281C11.6674 0.000488281 11.7324 0.0134397 11.7931 0.0386019C11.8538 0.063764 11.909 0.100643 11.9554 0.147132L13.4554 1.62213C13.5019 1.66857 13.5388 1.72371 13.5639 1.78441C13.5891 1.84511 13.602 1.91017 13.602 1.97588C13.602 2.04159 13.5891 2.10665 13.5639 2.16735C13.5388 2.22805 13.5019 2.2832 13.4554 2.32963L5.43039 10.3546C5.38396 10.4011 5.32881 10.438 5.26811 10.4632C5.20741 10.4883 5.14235 10.5013 5.07664 10.5013C5.01094 10.5013 4.94587 10.4883 4.88517 10.4632C4.82447 10.438 4.76933 10.4011 4.72289 10.3546L0.247893 5.85463C0.154196 5.76087 0.101562 5.63375 0.101562 5.50119C0.101562 5.36864 0.154196 5.24152 0.247893 5.14776L1.74789 3.64776C1.79433 3.60127 1.84947 3.56439 1.91017 3.53923C1.97087 3.51407 2.03594 3.50111 2.10164 3.50111C2.16735 3.50111 2.23241 3.51407 2.29311 3.53923C2.35381 3.56439 2.40896 3.60127 2.45539 3.64776L4.74789 5.85463Z" fill="#03A500"/>
                               </svg> 
                           </IconButton>
                        </Tooltip> </> : leadDetails?.validated_status}</Typography>  
                    </Grid>
                </Grid>
                </>}
                
                 {Object.keys(editFollowupDetails).length >0 ? <>
                    {editFollowupDetails?.followup_type ==="Call" ? <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} sm={4} lg={6}>
                            <Typography style={{marginTop:'5px', fontSize:'14px', fontWeight:600}}>Call Response<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                name='callResponce'
                                disabled={followupClosed}
                                placeholder ='Select Field'
                                options={callResponces} 
                                onchange={handleOfCallResponce}
                                //  label='Select industry'
                                value={callResponce}
                                sx={{p:0.1, mt:0.5, backgroundColor: followupClosed ? "#E9E9FF" :'#ffffff', borderRadius:"10px"}}
                                MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='callResponce' />
                        </Grid>
                        </Grid>
                    </>:<></>}

                    {((currentDate > new Date(editFollowupDetails?.followup_date) && closingRemark ===''|| (currentDate > new Date(editFollowupDetails?.followup_date) && closingRemark !=='')) || MissingDeadLine !== "" )? 
                       <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingRight:'15px'}} spacing={{ xs: 2, sm: 2, md: 2, lg: 1 }}>
                            <Grid item xs={12} md={6} sm={6} lg={12}>
                                <Typography style={{marginTop:'10px', fontSize:'14px', fontWeight:600}}>Missing Deadline Remark<span style={{color:'#FF0000'}}>*</span></Typography>  
                                <Box marginRight="10px">
                                    <TextArea name='missingDeadLineRemark' disabled={followupClosed} value={MissingDeadLine?.replace(/\s\s+/g, " ")} onChange={handleOfMissingDeadLine} style={{width:'100%', fontFamily: 'Montserrat',fontStyle: 'normal', background:followupClosed? "#E9E9FF" :'#FFFFFF', border:"1px solid #B0AEFF", height:'80px',borderRadius:'8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding:'5px'}} placeholder ="Write a note about Missing Deadline remark."></TextArea>
                                </Box> 
                                <DisplayError error={error} fortagid='missingDeadLineRemark' />
                            </Grid>
                            {followupClosed ? <></> :<div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                                <span>Characters: {MissingDeadLine?.length}/2000</span>
                            </div>}
                        </Grid>
                    :<></>}

                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingRight:'10px'}} spacing={{ xs: 2, sm: 2, md: 2, lg: 1 }}> 
                        <Grid item xs={12} md={6} sm={6} lg={12}>
                            <Typography style={{marginTop:'10px', fontSize:'14px', fontWeight:600}}>Closing Remark<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <Box marginRight="10px">
                                <TextArea name='closingRemark' disabled={followupClosed} value={closingRemark?.replace(/\s\s+/g, " ")} onChange={handleOfClosingRemark} style={{width:'100%', fontFamily: 'Montserrat',fontStyle: 'normal', background: followupClosed? "#E9E9FF" :'#ffffff', border:"1px solid #B0AEFF", height:'80px',borderRadius:'8px', fontWeight: 400, fontSize: '14px', lineHeight: '17px', padding:'5px'}} placeholder ="Write a note about closing remark."></TextArea>
                            </Box>
                            <DisplayError error={error} fortagid='closingRemark' />
                        </Grid>
                        {followupClosed ? <></> :<div style={{ marginLeft: 'auto', fontSize:'12px'}}>
                            <span>Characters: {closingRemark?.length}/2000</span>
                        </div>}
                    </Grid>
 
                    {followupClosed ?<><Typography sx={{mt:2}}>{leadDetails?.status ==="Lost" ? `This follow-up closed with of status Lead Lost.` : leadDetails?.status ==="Converted" ? "This follow-up closed with of status Convert to Opportunity ":'' }</Typography></> :<>
                    <Box style={{marginTop:'10px',display:'flex',justifyContent: 'space-between', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6} lg={12}>
                            <Typography>Close this follow-up with following options</Typography>
                        </Grid>
                        <Box sx={{mt:1, mr:{xs:1, sm:1, xl:3, lg:3}, mb:3, display:'flex', justifyContent:'center'}}>
                                 <CustemBtn
                                    disabled={followupClosed}
                                    onClick={()=> {setLeadLostButton(true) 
                                        setConvertToOpportunity(false)
                                        setCreateNewFollowupPage(false)
                                        setDisableButton(true)
                                        setCreateNewFollowup(false)
                                        setError([])}}
                                    sx={{display:'flex',flexDirection:'row', border:'1px solid #E70707', fontWeight:'600', "&:hover": {color:leadLostButton? '#FFFFFF':'#E70707', backgroundColor: leadLostButton ?'#E70707':'#FFFFFF'}, color:leadLostButton? '#FFFFFF':'#E70707',borderRadius: '10px', ml:2, pl:8, pr:8,mr:5, fontSize: '16px', backgroundColor: leadLostButton ?'#E70707':'#FFFFFF' }}
                                    text="Lead Lost"
                                />     
                           {leadDetails?.validated_status==='Pending' ?<></> :<>
                                <CustemBtn 
                                    disabled={followupClosed}
                                    onClick={handleClickConvertToOpportunity}
                                    sx={{display:'flex',flexDirection:'row', color:convertToOpportunity?'#FFFFFF':'#038600', "&:hover": {color:convertToOpportunity? '#FFFFFF':'#038600', backgroundColor: convertToOpportunity ?'#038600':'#FFFFFF'}, border:'1px solid #038600', fontWeight:'600',borderRadius: '10px', pl:5, pr:5, mr:5, fontSize: '16px', backgroundColor:convertToOpportunity ? '#038600':'#FFFFFF' }}
                                    text='Convert to Opportunity'
                                />
                            </>}
                                <CustemBtn 
                                    disabled={followupClosed}
                                    onClick={handleClickCreateNewFollowup}
                                    sx={{display:'flex',flexDirection:'row', color:createNewFollowupPage ? "#FFFFFF":'#A484FE', "&:hover": {color:createNewFollowupPage? '#FFFFFF':'#A484FE', backgroundColor: createNewFollowupPage ?'#A484FE':'#FFFFFF'}, border:'1px solid #A484FE', fontWeight:'600', borderRadius: '10px', ml:leadDetails?.validated_status==='Pending' ? 2: 0, pl:5, pr:5, fontSize: '16px', backgroundColor: createNewFollowupPage ? "#A484FE":'#FFFFFF' }}
                                    text='Open New Follow-Up'
                                 />
                                {/* </Grid> */}
                            {/* </Grid> */}
                            </Box>
                        </Grid>
                    </Box>
                    </>}

                    {/* {followupClosed ?<></>:<>
                    <Grid item xs={12} md={6} sm={6} lg={12}>
                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChangeForCreateFollowup} />}
                            label="Create New Followup"/>  
                    </Grid></>} */}
                    </>
                    :<></>}
   
                {/* check box is on then followup is created. */}
                    {createNewFollowup === true  ? <>   
                     <Box style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                         <Grid container spacing={2}>
                         <Grid item xs={12}>
                            <Typography sx={{fontWeight:600, fontSize:'18px', borderBottom:'1px solid #e4e4e4'}} onClose={handleClose}>
                                Create  Follow-Up for Lead <span style={{color:'#C72424'}}> ({leadDetails?.lead_no})</span>
                            </Typography> 
                        </Grid>
                         <Grid item xs={12}>
                             <Typography sx={{fontWeight:600, fontSize:'14px'}}>Scheduled Date & Time<span style={{color:'#FF0000'}}>*</span></Typography>  
                            </Grid>
                             {/* <Grid item xs={12}> */}
                                {displayWorkingDays.map((label, index) => (
                            <Grid item xs={6} sm={4} md={3} lg={2} key={index} sx={{ paddingTop: '10px' }}>
                                <Button
                            variant={createNewFollowupDate === label?.date? 'contained' : 'outlined'}
                            onClick={() => sumbitDate(label)}
                            fullWidth
                            name='date'
                            sx={{
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border:label?.holiday ===true ?'1px solid red':'1px solid #8AA000',
                                background: createNewFollowupDate === label?.date ? '#8AA000' :'transparent',
                                // color: selectedDate === label?.day? '#FFFFFF' : '#6E7100',
                                fontWeight: createNewFollowupDate === label?.date ? 600 : 400,
                                "&:hover": {
                                 bgcolor: createNewFollowupDate === label?.date ? '#8AA000' :'transparent'}
                            }}
                            >
                            <Typography  sx={{ fontSize:'14px', lineHeight: 1, mb:0.5, color: createNewFollowupDate === label?.date ? '#FFFFFF' : '#6E7100', fontWeight:600}}>{moment(label?.date).format("dddd")}</Typography>
                            <Typography sx={{ fontSize:'12px', lineHeight: 1, color: createNewFollowupDate === label?.date ? '#FFFFFF' : 'black', fontWeight:600,mt:0.5 }}>{moment(label?.date).format(" Do MMMM ")}</Typography>
                              
                               </Button>
                            </Grid>
                        ))} 
                          <Grid item xs={6} sm={4} md={3} lg={2} sx={{ paddingTop: '10px' }}>
                              <Button
                              
                            variant={startDate ? 'contained' : 'contained'}
                           // onClick={() => sumbitDate(label)}
                            fullWidth
                            name='date'
                            sx={{
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border:'1px solid #8AA000',
                                background: startDate ? '#8AA000' :'#344feb',
                                // color: selectedDate === label?.day? '#FFFFFF' : '#6E7100',
                                fontWeight: startDate ? 600 : 400,
                                "&:hover": {
                                 bgcolor: startDate? '#8AA000' :'#344feb'}
                            }}
                            >
                             {
                              (
                               () => {
                                if(showcalender) {
                                 return (
                                    <>
                                     <Typography  sx={{ fontSize:'14px', lineHeight: 1, mb:0.5, color: '#FFFFFF', fontWeight:600}}>Select Date</Typography>
                                         <Typography  sx={{ fontSize:'12px', lineHeight: 1, color:'#ffffff', fontWeight:600,mt:0.5 }}>Show More</Typography>
                                <DatePickerOpenTo
                                open={true}
                                name='startDate'
                                openTo="day"
                                views={['year', 'month', 'day']}
                                value={startDate}
                                onChange={changeStartDate}
                                minDate={addDays(new Date(displayWorkingDays[displayWorkingDays?.length - 1].date), 1)}
                                maxDate={addDays(new Date(displayWorkingDays[displayWorkingDays?.length - 1].date), 23)}
                                style={{ height:'0px', width:'0px', visibility:'hidden',backgroundColor:  '#ffffff', fontSize: '14px', lineHeight: '17px', width: '100%', p: 0.1, mt: 1, pl: 1 }} 
                            
                            />
                                    </>
                                 )
                                } else if(startDate) {
                                    return (
                                        <>
                                            <Typography sx={{ fontSize:'14px', lineHeight: 1, mb:0.5, color: startDate ? '#FFFFFF' : '#6E7100', fontWeight:600}}>{moment(startDate).format("dddd")}</Typography>
                                            <Typography sx={{ fontSize:'12px', lineHeight: 1, color: startDate ? '#FFFFFF' : 'black', fontWeight:600,mt:0.5 }}>{moment(startDate).format(" Do MMMM ")}</Typography>
                                        </>
                                    )
                                } 
                                else {
                                    return (
                                        <Box onClick={() => handleCalender()}>
                                         <Typography  sx={{ fontSize:'14px', lineHeight: 1, mb:0.5, color: '#FFFFFF', fontWeight:600}}>Select Date</Typography>
                                         <Typography  sx={{ fontSize:'12px', lineHeight: 1, color:'#ffffff', fontWeight:600,mt:1 }}>Show More</Typography>
                                        </Box>
                                    )
                                }
                               }
                              )()
                             }
                               </Button>     
                         </Grid>
                         </Grid>
                      
                         {/* </Grid> */}
                    </Box> 
                    <DisplayError error={error} fortagid='value' />
                    
                    <Box style={{marginTop:"30px", display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={12} lg={1}>
                                <Typography variant="body1" style={{textAlign: 'center', fontSize:'14px', color: '#6E7100', fontWeight: 600 }}>Hours</Typography>
                            </Grid>
                            
                            <Grid item xs={11} container spacing={1} justifyContent="space-between">
                                {renderHoursButtons()} 
                            </Grid>
                            <div style={{marginLeft:'90px'}}><DisplayError error={error} fortagid='createHr' /></div>
                        </Grid>  
                    </Box>
     
                    <Box style={{marginTop:"30px", display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <Typography variant="body1" style={{ textAlign: 'center', fontSize:'14px', color: '#6E7100', fontWeight: 600}}>Minutes</Typography>
                            </Grid>
                            <Grid item xs={9} container spacing={2}>
                            <Grid item xs={4} container spacing={2} justifyContent="space-between">
                                {renderMinutesButtons()}
                            </Grid>            
                        </Grid>
                       
                        <Grid item xs={2} container spacing={1} justifyContent="space-between">
                            <Button variant="outlined" style={{ minWidth: '50px', background: createNewFollowupAmPm ==='AM'? '#8AA000' : 'transparent',marginTop:'auto',color:createNewFollowupAmPm ==='AM'? '#FFFFFF':'#444444'}} onClick={()=>submitAmPm(null, 'AM')}>
                                AM
                            </Button>
                            <Button variant="outlined" style={{ minWidth: '50px', marginTop:'auto', background : createNewFollowupAmPm ==='PM'? '#8AA000': '#FFFFFF', color: createNewFollowupAmPm ==='PM'? '#FFFFFF':'#444444'}} onClick={()=> submitAmPm(null, 'PM')}>
                                PM
                            </Button>
                            <DisplayError error={error} fortagid='createampm' />
                        
                            </Grid>
                            <div style={{marginLeft:'90px'}}><DisplayError error={error} fortagid='createMin' /></div>
                            <div style={{ marginLeft: 'auto', fontSize:'14px'}}>
                                <span>{createNewFollowupHour} {':'} {createNewFollowupMinute} {createNewFollowupAmPm}</span>
                            </div>    
                        </Grid>         
                   </Box>
                   
                   <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6} lg={6}>
                            <Typography sx={{mt:2, fontSize:'14px', fontWeight:600}}>Type<span style={{color:'#FF0000'}}>*</span></Typography>  
                            <SelectDropDown 
                                name='createType'
                                placeholder ='Select Type'
                                options={Types} 
                                onchange={handleOfCreateFollowUpDetails}
                                value={createNewFollowUpType}
                                //  disabled={Object.keys(editFollowupDetails)?.length >0}
                                sx={{p:0.1, mt:0.5, backgroundColor:'#ffffff', borderRadius:"10px"}}
                                // MenuProps={MenuProps}
                            />
                            <DisplayError error={error} fortagid='createType' />
                        </Grid>
                    </Grid>
                     </> :<></>}
            {/* </Grid>     */}
            {/* </CardBody> */}
          </DialogContent>
            <Box sx={{mt:2, mr:{xs:1, sm:1, xl:2, lg:2}, mb:2, display:'flex', justifyContent:'end' }}>  
                {loader ? 
                (<TailSpin
                    height="30"
                    width="30"
                    color="blue"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />) :  <CustemBtn variant="contained" disabled={Object.keys(editFollowupDetails)?.length === 0 ? disableButton : followupClosed || !disableButton} onClick={SubmitFollowUp} sx={{borderRadius:"10px", pl:3, pr:3, fontSize:'16px',backgroundColor:'#7673F6'}} text="Save"/>}
            </Box>
            {/* // <Box sx={{mt:2, mr:{xs:1, sm:1, xl:3, lg:4}, mb:2, display:'flex', justifyContent:'end' }}>  
            //     <CustemBtn variant="contained" disabled={Object.keys(editFollowupDetails)?.length === 0 ? disableButton : followupClosed || !disableButton} onClick={SubmitFollowUp} sx={{borderRadius:"10px", pl:3, pr:3, fontSize:'16px',backgroundColor:'#7673F6'}} text="Save"/>
            // </Box>  */}
        </BootstrapDialog> 
      </div>
    );
}

export default CreateFollowUp


{/* <Dialog
open={open}
//   onClose={handleClose}
aria-labelledby = "alert-dialog-title"
aria-describedby = "alert-dialog-description"
fullWidth
maxWidth="md"
>
  <DialogTitle sx={{borderBottom:'0.5px solid #E8DADA', padding: '0px'}} >
      <Toolbar
          children={
          <>
              <Box>
                  <Typography sx={{ display: { lg: 'row', sm: 'row', xs: 'column-reverse' }, alignItems: { lg: 'flex-end', md: 'flex-end', xs: 'center' },
                      display:'flex', justifyContent:'center', fontWeight: 600, color: 'blue', p: 0, mt: 1, fontSize:'22px',
                      letterSpacing: '0.04em'}}>
                      not used  {Object.keys(editFollowupDetails).length > 0 ? (title==="Alert List" )? `${editFollowupDetails?.company?.company_name} ${editFollowupDetails?.company?.company_type}`:`${editFollowupDetails?.responseFollowup?.company?.company_name} ${editFollowupDetails?.responseFollowup?.company?.company_type}`:(title==="Lead List" || title==="companyProfilePageLeadDetails" || title==="DashboardLeadDetails" || title==="companyProfilePageOpportunityDetails")? `${companyName?.company_name} ${companyName?.company_type}` :''} 
                      {remainingNames}
                  </Typography>
              <Grid
                  sx={{ mr: 1, mt:1, display: { lg: 'row', sm: 'row', xs: 'column-reverse' }, justifyContent: { lg: 'space-between', sm: 'space-between', xs: 'start' },
                  alignItems: { lg: 'flex-end', md: 'flex-end', xs: 'start' }}}>
                  {Object.keys(editFollowupDetails).length > 0 ? (
                  <Typography variant="h6" sx={{ fontSize: { lg: '20px', md: '16px', sm: '12px' }, fontWeight: 600 }}>
                      Close Follow-Up
                  </Typography>
                  ) : (
                  <Typography variant="h6" sx={{ fontSize: { lg: '20px', md: '16px', sm: '12px' }, fontWeight: 600 }}>
                      Create Follow-Up
                  </Typography>
                  )}
              </Grid>
              </Box>
              <div style={{ position: 'absolute', right: 25 }}>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
              </IconButton>
              </div>
          </>
          }
          >
      </Toolbar>     
  </DialogTitle> */}
