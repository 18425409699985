import React from 'react'

const ForwardIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_3649_80492)">
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#3107AA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.25 12H15.75" stroke="#3107AA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.75 9L15.75 12L12.75 15" stroke="#3107AA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3649_80492">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ForwardIcon
