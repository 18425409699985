import React from 'react'

const AssignIcon = () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.39154 9.58176H11.25V10.7484H0.75V8.27358L6.52471 2.49888L8.99956 4.97372L4.39154 9.58176ZM7.34966 1.67391L8.58708 0.436477C8.81493 0.208674 9.18424 0.208674 9.41203 0.436477L11.062 2.0864C11.2898 2.3142 11.2898 2.68355 11.062 2.91135L9.82451 4.14879L7.34966 1.67391Z" fill="#858585"/>
    </svg>    
  )
}

export default AssignIcon