import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import CustemBtn from './CustemBtn';


export default function PopperFilter({open, anchorEl, placement,filtervalues, onClick}) {
  return (
    <Box sx={{ width: 500, zIndex:1200}}>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{pb:0.5,border:'1px solid lightgrey', minWidth:'120px',zIndex:1000}} >
             {filtervalues?.map ((role) => {
                return <Box >
                    <CustemBtn onClick={onClick} name={role.name} value={role.name} text={role.name} sx={{minWidth: '0px', textAlign:'left', color:'#444444', "&:hover": {backgroundColor: '#F0F0F0'}, backgroundColor:'#FFFFFF', fontSize:'15px', boxShadow:'none'}} />
                </Box>
             }) }
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
