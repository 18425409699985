import React ,{useState, useEffect} from 'react'
import { SidebarContent } from './CAF.Ser.IDcss'
import { Grid, Box, styled, Tooltip} from '@mui/material'
import DatePickerOpenTo from '../../../components/custom/DatePickerYearMonth'
import { CustemBtn, InputBox, SelectDropDown } from '../../../components/custom'
import { useDispatch, useSelector } from 'react-redux'
import { storeOtherCAFID } from '../../../Redux/slices/createOpportunitySlice'
import CompanyNameDetails from '../../../components/custom/CompanyNameDetails'
import ContactNameDetails from '../../../components/custom/ContactNameDetails'
import { H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T1ButtonText, T2popupSubText } from '../../../Theme/theme';
import themeColor from '../../../Theme/themeColor'
import moment from 'moment'
import { getServiceDetails } from '../../../Redux/Thunk/createopportunitythunk'
import { useNavigate } from 'react-router-dom'

const LoginContainer = styled('div')({
  minHeight: '100vh',
  overflowY: 'auto',
  // marginTop: "0.2px",
  // marginBottom:'0.2px',
  //  marginRight:'20px',
  // padding: "10px",
  background: '#F9F9F9',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})

const CardBody = styled('div')({
  backgroundColor: '#FFFFFF',
  padding: '20px',
  borderRadius: '10px',
})

const ChildCard = styled('div')({
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
})

const CardInfo = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
})

const CafseridDetails = ({name,For,modified_oppo,  serviceDetails, opportunitydetails, cofDetails, setOppo_id}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [otherCafID, setOtherCafID] = useState('')
  const {errormsgCaf} = useSelector((state)=>state.opportunity)
  const {storeOtherCafID} = useSelector((state)=>state?.opportunity)
  const [selectedid, setSelectedId] = useState(opportunitydetails?.id)
  const { user } = useSelector((state) => state.auth)
  const title = 'caf'
  const permissionsDetails = user?.data?.user.role.permissions
  const Title = "COF List"
//   console.log(errormsgCaf,otherCafID.length, "errormsgCaf")
//  console.log(selectedid, "storeOtherCafID")
 
  const handleChangedOtherCafID =(e)=>{
    setOtherCafID(e.target.value)
    // console.log(e.target.value,"hhhhhhhhhhhhh")
    dispatch(storeOtherCAFID(e.target.value))
  }

  const handleSelectOpportunity = (e) => {
    // console.log(e.target.value)
    setOppo_id(e.target.value)
    setSelectedId(e.target.value)
    dispatch(getServiceDetails(e.target.value))
  }

//  const handleChangedOpenOpportunityPage = () =>{
//   navigate('/dashboard/opportunitydetails', { state: { oppDetails: opportunitydetails?.id, title: Title } })
//  }

  return (
<>
       {/*   company Details   */}
        
      

       <div>
          {/* <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}> */}
          <Grid sx={{ pl: 1, mb: 1, pr: 1, display: 'flex', flexDirection: 'row' }}>
          {/* <Grid item xs={3}>
            <H3SubHeader sx={{ ml: 0.5, color: themeColor.palette.OpportunityID }} onClick={handleChangedOpenOpportunityPage}>
              {opportunitydetails?.opportunity_no}
            </H3SubHeader>
          </Grid> */}
          <Grid item xs={6}>
            <H3SubHeader sx={{ ml: 0.5, color: themeColor.palette.BrandColor }}>
              {opportunitydetails?.servicedetails?.service_id}
            </H3SubHeader>
          </Grid>
  
</Grid>

          <CardBody sx={{ mt: 0, mb:1, ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl: 1, lg: 1 } }}>
              <CompanyNameDetails getCompanyDetails={opportunitydetails} title={title} />
          </CardBody>
          {
          (For =='Service' && modified_oppo.length > 1) ?   
          <Box sx={{pl:2, mt: 2, mb: 1, display:'flex', gap:'15px'}}>
            {
              modified_oppo.map((oppo_no) => {
                return   <Box>
                  <CustemBtn onClick={(e) => handleSelectOpportunity(e)} value={oppo_no.id} text={oppo_no.opportunity_no} sx={{color: themeColor.palette.OpportunityID, "&:hover": {backgroundColor: '#F0F0F0'}, backgroundColor:'#F0F0F0', border:`${selectedid == oppo_no.id ? '1px solid #3107AA': ''}`, fontSize:'15px', boxShadow:'none', fontWeight:600 }} />
                  {/* <T2popupSubText onClick={(e) => handleSelectOpportunity(e)} sx={{color: themeColor.palette.OpportunityID , cursor:'pointer'}} >{oppo_no.opportunity_no}</T2popupSubText> */}
                </Box>
              })
            }
          </Box>
      //     <SelectDropDown
      //     // disabled={}
      //     name='oppo_id'
      //      options={modified_oppo.map((opp) => { return {value:opp.id, label:opp.opportunity_no}})}
      //     onchange={handleSelectOpportunity}
      //     placeholder='--Select Opportunity--'
      //     value={selectedid}
      //     sx={{pl:2, mt: 2, mb: 1,ml:1, borderRadius: "10px", background:'#ffffff', width:"250px" }}
      // /> 
      : ''
        }
          {/*     Contacts  Details  */}
          <ContactNameDetails contactDetails={opportunitydetails}/>
         
          {/* <CardBody sx={{ mt: 1, ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl: 1, lg: 1 } }}> */}
            <Grid sx={{pl:2, mt: 2, mb: 1, display: 'flex' }}>
                <Grid item xs={12}>
                    <H3SubHeader sx={{ color: themeColor.palette.Primarytext1, LineHeight: "21.94px" }}>
                        Opportunity Info
                    </H3SubHeader>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                      
                      </H7TablePrimeContent>
                </Grid>
            </Grid>

            <ChildCard sx={{ml:1, pt:1, mr:1}}>
                <CardInfo sx={{pl: 1, pr: 2, pb: 0.2}}>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                      Opportunity Name
                    </H7TablePrimeContent>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                        Order Type
                    </H7TablePrimeContent>
                </CardInfo>

                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                        {opportunitydetails?.opportunity_name}
                    </T5Normaltext>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                        {opportunitydetails?.opportunity_type}
                    </T5Normaltext>
                </CardInfo>

                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                        Exp. Date of Closer
                    </H7TablePrimeContent>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                        Line of Business
                    </H7TablePrimeContent>
                </CardInfo>

                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                        {moment(opportunitydetails?.closure_date).format('DD/MM/YYYY')}
                    </T5Normaltext>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                        {opportunitydetails?.lineofbusiness}
                    </T5Normaltext>
                </CardInfo>

                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                        Service Provider
                    </H7TablePrimeContent>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                        Product Family
                    </H7TablePrimeContent>
                </CardInfo>

                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                        {opportunitydetails?.service_provider}
                    </T5Normaltext>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                        {opportunitydetails?.product_family}
                    </T5Normaltext>
                </CardInfo>

                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                        Product
                    </H7TablePrimeContent>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel }}>
                        Service Provider Opportunity ID
                    </H7TablePrimeContent>
                </CardInfo>

                <CardInfo sx={{ pl: 1, pr: 2, pb: 2 }}>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                        {opportunitydetails?.product}
                    </T5Normaltext>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2 }}>
                        {opportunitydetails?.service_opportunityid}
                    </T5Normaltext>
                </CardInfo>

                <CardInfo sx={{ pl: 1, pr: 2, pb: 0.2 }}>
                    <H7TablePrimeContent style={{ flex: 1, color: themeColor.palette.InputLabel, marginLeft: '2px' }}>
                        Opportunity Summary
                    </H7TablePrimeContent>
                </CardInfo>
                <CardInfo sx={{ pl: 1, pr: 2, pb: 1 }}>
                    <T5Normaltext sx={{ flex: 1, color: themeColor.palette.primarytext2, whiteSpace: 'pre-line', wordBreak: 'break-all' }}>
                        {opportunitydetails?.summary}
                    </T5Normaltext>
                </CardInfo>

            </ChildCard>
          {/* </CardBody> */}
      {/* {
        name == 'cof' ? <>
          <Grid item lg={6} md={6} >
            <H7TablePrimeContent style={{ color: themeColor.palette.InputLabel, paddingBottom:'2px'}}>
              Opportunity ID
            </H7TablePrimeContent>
            <T5Normaltext sx={{ color: themeColor.palette.OpportunityID, fontWeight:600, paddingBottom:'5px' }}>
              {cofDetails?.oppo_details?.opportunity_no}
            </T5Normaltext>
          </Grid>
          <Grid item lg={6} md={6} >
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel,pb:0.2}}>
              Lead ID
            </H7TablePrimeContent>
            <T5Normaltext sx={{ color: themeColor.palette.LeadID, fontWeight:600, pb:2 }}>
              {cofDetails?.lead_details?.lead_no}
            </T5Normaltext>
          </Grid>
          <Grid item lg={6} md={6} >
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2 }}>
              CAF ID
            </H7TablePrimeContent>
            <T5Normaltext sx={{ color: themeColor.palette.OpportunityID, fontWeight:600, pb:2}}>
              {cofDetails?.oppo_details?.cofdetails?.cof_id}
            </T5Normaltext>
          </Grid>
          {cofDetails?.oppo_details?.commercials?.billed_by !=='Mobigic' ? <Grid item lg={6} md={6} >
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2}}>
              Other CAF ID<span style={{ color: 'red' }}>*</span>
            </H7TablePrimeContent>
           {!permissionsDetails.perm_approve_edit && cofDetails?.oppo_stages?.length === 0 ? <><InputBox value={otherCafID} onchange={handleChangedOtherCafID} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', backgroundColor: '#ffffff', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder= "Enter CAF ID" />
            {errormsgCaf?.value === true ? <small style={{color:'red'}}>Please Enter Caf ID</small> :''} </>:
              <T5Normaltext sx={{ color: themeColor.palette.OpportunityID, fontWeight:600 }}>
                {cofDetails?.oppo_details?.cofdetails?.third_party_cof_id}
            </T5Normaltext>} 
            </Grid> :<> </>}

        </> : <>
        <Grid item lg={4} md={4} >
          <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel,pb:0.2}}>
              Opportunity ID
            </H7TablePrimeContent>
            <T5Normaltext sx={{ color: themeColor.palette.OpportunityID, fontWeight:600, pb:2 }}>
              {serviceDetails?.oppo_details?.opportunity_no}
            </T5Normaltext>
          </Grid>
          <Grid item lg={4} md={4}>
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2}}>
              Lead ID
            </H7TablePrimeContent>
            <T5Normaltext sx={{ color: themeColor.palette.LeadID, fontWeight:600, pb:2}}>
              {serviceDetails?.lead_details?.lead_no}
            </T5Normaltext>
          </Grid>
          <Grid item lg={4} md={4}>
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2}}>
              CAF ID
            </H7TablePrimeContent>
            <T5Normaltext sx={{ color: themeColor.palette.primarytext2, pb:2}}>
              {serviceDetails?.oppo_details?.cofdetails?.cof_id}
            </T5Normaltext>
          </Grid>
          <Grid item lg={4} md={4} >
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2}}>
              Service ID
            </H7TablePrimeContent>
            <T5Normaltext sx={{ color: themeColor.palette.primarytext2, pb:2}}>
              {serviceDetails?.oppo_details?.servicedetails?.service_id}
            </T5Normaltext>
          </Grid>
          {serviceDetails?.oppo_details?.commercials?.billed_by !=='Mobigic' ? <Grid item lg={6} md={6} >
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb:0.2}}>
              Other Service ID<span style={{ color: 'red' }}>*</span>
            </H7TablePrimeContent>
              {!permissionsDetails.perm_approve_edit && serviceDetails?.oppo_stages?.length === 0 ? <InputBox value={otherCafID} onchange={handleChangedOtherCafID} sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', backgroundColor: '#ffffff', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }} placeholder= "Enter Service ID" /> :
              <></>}
              {serviceDetails?.oppo_stages?.length > 0 ?<></>: <T5Normaltext sx={{ color: themeColor.palette.OpportunityID, fontWeight:600 }}>
                {serviceDetails?.oppo_details?.servicedetails?.third_party_service_id}
              </T5Normaltext>} 
            {errormsgCaf?.value === true ? <small style={{color:'red'}}>Please Enter Service ID</small> :''}
            </Grid> :<> </>}
          <Grid item lg={4} md={4}>
            
          </Grid>
          </>
      } */}
     
    </div>
    </>
  )
}

export default CafseridDetails
