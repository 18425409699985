import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "./axioshelper";

export const sendreminder = createAsyncThunk('data/sendreminder',
    async(data)=>{
    const res = await axiosInstance.post('/sendreminder', data);
        return res.data
    }
)

export const getresettime = createAsyncThunk('data/getresettime',
    async(data)=>{
    const res = await axiosInstance.post(`/getresettime`, data);
        return res.data
    }
)