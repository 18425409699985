import { createSlice } from '@reduxjs/toolkit'
import { getalertlist } from '../Thunk/alertthunk'

const initialState = {
    alertlist_data : []
}

export const AlertSlice = createSlice({
          name:'alert',
          initialState,
          reducers:{
          },
          extraReducers:(builder)=>{
            builder.addCase(getalertlist.fulfilled,(state,action)=>{
                state.alertlist_data = action.payload 
            })
         },
})


export default AlertSlice.reducer
