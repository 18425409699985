import React from 'react'

const Identified = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4413_121939)">
        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#630000" stroke="#630000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.75 7.5L17.3192 7.5C17.4573 7.5 17.5693 7.61193 17.5693 7.75C17.5693 7.79351 17.5578 7.83626 17.5363 7.87404L15.75 11L17.5363 14.1259C17.6047 14.2459 17.5631 14.3985 17.4432 14.467C17.4054 14.4886 17.3627 14.5 17.3192 14.5H8.75V17H7.75L7.75 7.5Z" fill="#F9F9F9" />
      </g>
      <defs>
        <clipPath id="clip0_4413_121939">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const QualifiedPending = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4413_6364)">
        <path d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z" fill="#F9F9F9" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <g clip-path="url(#clip1_4413_6364)">
          <path d="M7 12C7 11.7066 7.22386 11.4688 7.5 11.4688H9C9.27614 11.4688 9.5 11.7066 9.5 12C9.5 12.2934 9.27614 12.5312 9 12.5312H7.5C7.22386 12.5312 7 12.2934 7 12ZM14.5 12C14.5 11.7066 14.7239 11.4688 15 11.4688L16.5 11.4688C16.7762 11.4688 17 11.7066 17 12C17 12.2934 16.7762 12.5313 16.5 12.5313L15 12.5313C14.7239 12.5313 14.5 12.2934 14.5 12ZM12 6.6875C12.2761 6.6875 12.5 6.92534 12.5 7.21875L12.5 8.8125C12.5 9.10591 12.2761 9.34375 12 9.34375C11.7238 9.34375 11.5 9.10591 11.5 8.8125L11.5 7.21875C11.5 6.92534 11.7238 6.6875 12 6.6875ZM12 14.6563C12.2761 14.6563 12.5 14.8941 12.5 15.1875V16.7812C12.5 17.0746 12.2761 17.3125 12 17.3125C11.7238 17.3125 11.5 17.0746 11.5 16.7812V15.1875C11.5 14.8941 11.7238 14.6563 12 14.6563ZM15.5356 8.24348C15.7308 8.45098 15.7308 8.78732 15.5356 8.99477L14.4749 10.1218C14.2796 10.3292 13.963 10.3292 13.7677 10.1218C13.5725 9.91426 13.5725 9.57793 13.7677 9.37047L14.8285 8.24348C15.0237 8.03603 15.3403 8.03603 15.5356 8.24348ZM10.2322 13.8783C10.4275 14.0857 10.4275 14.4221 10.2322 14.6296L9.17158 15.7565C8.97631 15.964 8.65973 15.964 8.46446 15.7565C8.2692 15.549 8.2692 15.2127 8.46446 15.0052L9.52513 13.8783C9.72039 13.6708 10.037 13.6708 10.2322 13.8783ZM15.5356 15.7565C15.3403 15.964 15.0237 15.964 14.8285 15.7565L13.7677 14.6296C13.5725 14.4221 13.5725 14.0857 13.7677 13.8783C13.963 13.6708 14.2796 13.6708 14.4749 13.8783L15.5356 15.0052C15.7308 15.2127 15.7308 15.549 15.5356 15.7565ZM10.2322 10.1218C10.037 10.3292 9.72039 10.3292 9.52513 10.1218L8.46446 8.99477C8.2692 8.78732 8.2692 8.45098 8.46446 8.24348C8.65973 8.03603 8.97631 8.03603 9.17158 8.24348L10.2322 9.37047C10.4275 9.57793 10.4275 9.91426 10.2322 10.1218Z" fill="url(#paint0_linear_4413_6364)" />
        </g>
      </g>
      <defs>
        <linearGradient id="paint0_linear_4413_6364" x1="7" y1="12" x2="17" y2="12" gradientUnits="userSpaceOnUse">
          <stop offset="0.223958" />
          <stop offset="1" stop-opacity="0.31" />
        </linearGradient>
        <clipPath id="clip0_4413_6364">
          <rect width="24" height="24" fill="white" transform="matrix(0 -1 1 0 0 24)" />
        </clipPath>
        <clipPath id="clip1_4413_6364">
          <rect width="12.75" height="12" fill="white" transform="matrix(0 -1 1 0 6 18.375)" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Rejected = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM8.52313 7.10891C8.25459 7.30029 7.99828 7.51644 7.75736 7.75736C7.51644 7.99828 7.30029 8.25459 7.10891 8.52313L15.4769 16.8911C15.7454 16.6997 16.0017 16.4836 16.2426 16.2426C16.4836 16.0017 16.6997 15.7454 16.8911 15.4769L8.52313 7.10891Z" fill="#E70707" />
    </svg>
  )
}
const EditCommercial = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.35677 3.44397H3.81973C3.47593 3.44397 3.14622 3.5903 2.90311 3.85077C2.66001 4.11123 2.52344 4.4645 2.52344 4.83286V14.5551C2.52344 14.9234 2.66001 15.2767 2.90311 15.5372C3.14622 15.7976 3.47593 15.944 3.81973 15.944H12.8938C13.2376 15.944 13.5673 15.7976 13.8104 15.5372C14.0535 15.2767 14.1901 14.9234 14.1901 14.5551V9.69397" stroke="#200E32" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.5143 2.40279C13.7906 2.12652 14.1653 1.97131 14.556 1.97131C14.9467 1.97131 15.3214 2.12652 15.5977 2.40279C15.8739 2.67905 16.0291 3.05375 16.0291 3.44445C16.0291 3.83515 15.8739 4.20985 15.5977 4.48612L9.00043 11.0833L6.22266 11.7778L6.9171 9.00001L13.5143 2.40279Z" stroke="#200E32" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}

const DragDropIcon = ({w, h}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4563_55257)">
        <path d="M25 28H7C6.73478 28 6.48043 27.8946 6.29289 27.7071C6.10536 27.5196 6 27.2652 6 27V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H19L26 11V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28Z" stroke="#3107AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19 4V11H26" stroke="#3107AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 18L16 15L19 18" stroke="#3107AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 23V15" stroke="#3107AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_4563_55257">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  )
}

const PreviewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
      <g clip-path="url(#clip0_4546_15573)">
        <path d="M25.5 28H7.5C7.23478 28 6.98043 27.8946 6.79289 27.7071C6.60536 27.5196 6.5 27.2652 6.5 27V5C6.5 4.73478 6.60536 4.48043 6.79289 4.29289C6.98043 4.10536 7.23478 4 7.5 4H19.5L26.5 11V27C26.5 27.2652 26.3946 27.5196 26.2071 27.7071C26.0196 27.8946 25.7652 28 25.5 28Z" stroke="#3107AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M19.5 4V11H26.5" stroke="#3107AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.5 17H20.5" stroke="#3107AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12.5 21H20.5" stroke="#3107AA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4546_15573">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}


export { Identified, QualifiedPending, Rejected, EditCommercial, PreviewIcon, DragDropIcon}
