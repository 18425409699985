
const CompanyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M2 27H30" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M18 27V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V27" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M28 27V13C28 12.7348 27.8946 12.4804 27.7071 12.2929C27.5196 12.1054 27.2652 12 27 12H18" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 9H12" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 17H14" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8 22H12" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 22H24" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22 17H24" stroke="#3107AA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default CompanyIcon