const FollowUpIcon = ({height,width}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.25625 18.9281L4.22812 21.4594C4.11886 21.5498 3.9862 21.6074 3.84553 21.6254C3.70486 21.6435 3.56195 21.6213 3.43338 21.5615C3.30481 21.5016 3.19585 21.4065 3.11914 21.2872C3.04244 21.1679 3.00112 21.0293 3 20.8875V6C3 5.80109 3.07902 5.61032 3.21967 5.46967C3.36032 5.32902 3.55109 5.25 3.75 5.25H20.25C20.4489 5.25 20.6397 5.32902 20.7803 5.46967C20.921 5.61032 21 5.80109 21 6V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H7.73438C7.55878 18.75 7.38907 18.8133 7.25625 18.9281V18.9281Z" stroke="#444444" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z" fill="#444444"/>
        <path d="M7.5 13.125C8.12132 13.125 8.625 12.6213 8.625 12C8.625 11.3787 8.12132 10.875 7.5 10.875C6.87868 10.875 6.375 11.3787 6.375 12C6.375 12.6213 6.87868 13.125 7.5 13.125Z" fill="#444444"/>
        <path d="M16.5 13.125C17.1213 13.125 17.625 12.6213 17.625 12C17.625 11.3787 17.1213 10.875 16.5 10.875C15.8787 10.875 15.375 11.3787 15.375 12C15.375 12.6213 15.8787 13.125 16.5 13.125Z" fill="#444444"/>
        </svg>
    )
}

export default FollowUpIcon