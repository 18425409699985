import React,{useState} from "react";
import {Grid, Box, styled, Card, Avatar, Button, Typography, TextField, CircularProgress} from '@mui/material';
import {InputBox, Label, Logo,CustemBtn, LessThanIcon} from '../../components/custom';
import { useNavigate, Link } from "react-router-dom";
import swal from 'sweetalert';
import { useDispatch } from "react-redux";
import  validate  from "../../validation/validation";
import { resetPassword } from "../../Redux/Thunk/auththunk";
import DisplayError from "../../validation/DisplayError";
import {LoginContainer, LogoBox, ForgotPasswordDetails} from './authorization.style'
import LoginImage from "../../components/custom/LoginImage";
import {H1FormTitle, T6TableText, H5FormLabel, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'

const ForgotPassword = () =>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [error,setError] = useState([]);
  const [displayErrorMsg, setDisplayErrorMsg] = useState("");
  const [loader, setLoader] = useState(false)

  let validation_input = [
    {tagid: 'email', text: email, regex_name: 'email', required: true, errmsg: 'Please enter registered email.'}]
   
  const handleChangeForEmail = (event) => {
    setEmail(event.target.value.trim())
  }
 
  const submitRegisteredEmailAddress = () =>{
    if(validate(validation_input).length==0){
      const emailAddress = {reset_email: email}
      setLoader(true)
      dispatch(resetPassword(emailAddress)).then((data)=>{
        if(data.payload.errormsg){
          setDisplayErrorMsg(data.payload.errormsg)
          setError([])
          setLoader(false)
        }else{
          setLoader(false)
          swal({
            buttons: {
            Cancel: {
                text: "Okay",
                value: "okay",
                className: "popup-blueOne",
            },
          },
            title: `Your request to reset password is granted. Kindly check your registered email to reset your password.`,
            className: "custom-modal",
            closeOnClickOutside: false,
          }).then((value)=>{
            if(value=='okay'){
              navigate('/')
            }
          })
        }
      }) 
    }else{
      setError(validate(validation_input))
      setDisplayErrorMsg(false)
    }  
  }

  return(
      <LoginContainer>
        {/* Display Image */}
        <Grid container>
        <Grid item xs={12} md={10} sm={10} lg={8} style={{display:'flex', justifyContent:"center"}}>
           <LoginImage />
            {/* <Image>
              <img alt="The house from the offer."
                src="/login_image.png" style={{width: '100%', 
                height:'auto',
                maxWidth:'100%'}} />
            </Image>   */}
          </Grid>

          {/* Employee Forget Password Details */}
          <Grid item xs={12} md={10} sm={10} lg={4} style={{background:'#F1F1F1', borderBottomRightRadius:'10px', borderTopRightRadius:'10px', width: '100%',height:'auto', maxWidth:'100%'}}>
              <LogoBox>
                <Logo width={172} height={45}/>
              </LogoBox>
              
              <ForgotPasswordDetails>
                  <H1FormTitle sx={{color:themeColor.palette.BrandColor, mt:1}}>
                     Forgot Password ?
                  </H1FormTitle>
                  <H5FormLabel sx={{color:themeColor.palette.Primarytext1, mt:3}}>
                     Registered Email
                  </H5FormLabel>
                  <InputBox name='email' value={email} onchange={handleChangeForEmail} sx={{width:'100%', border:"1px solid #B0AEFF", borderRadius:'10px', p:0.1, mt:1, pl:1}} placeholder ="User Email"/>
                  <DisplayError error={error} fortagid='email'/> 
                  <Button
                      id="validate"
                      variant="contained"
                      disabled={loader}
                      onClick={submitRegisteredEmailAddress}
                      sx={{
                          color: '#FFFFFF',
                          backgroundColor: '#3107AA',
                          '&:hover': { backgroundColor: '#3107AA', color: '#FFFFFF' },
                          fontSize: '16px', 
                          fontWeight: '500',
                          borderRadius:"10px",
                          textTransform: 'none', 
                          width:'100%', height:'40px',
                          marginTop:'20px',
                          boxShadow:'0px 4px 6px #e1e1e1'
                      }}
                      >
                      {loader ? <CircularProgress size={20} color="inherit" /> : 'Submit'}
                  </Button>
                  {/* <CustemBtn variant='contained' onClick={submitRegisteredEmailAddress} sx={{width:'100%', height:'40px', color:'#FFFFFF', fontSize:'16px', fontWeight:'500', borderRadius:'10px', marginTop:'40px'}} text="Submit"/> */}
                  {displayErrorMsg ? <small style={{color:'red'}}>{displayErrorMsg} </small>:'' }

                  <Link to='/' style={{textDecoration:'none'}} >
                    <H7TablePrimeContent display='flex' justifyContent='start' sx={{color:themeColor.palette.TableButton, mt:3,textAlign:'start'}}>
                      <LessThanIcon/> Back to Login
                    </H7TablePrimeContent>
                  </Link>
              </ForgotPasswordDetails> 
          </Grid>  
        </Grid>
      </LoginContainer>
  )}


export default ForgotPassword