import React ,{ useState, useEffect } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustemBtn from '../../components/custom/CustemBtn';
import InputBox from '../../components/custom/InputBox';
import {Typography, InputAdornment, IconButton,Tooltip } from '@mui/material';
import { DatePicker, DropDown, EyeCloseIcon, EyeOpenIcon,AddPlusIcon} from '../../components/custom';
import validate from '../../validation/validation';
import { json, useLocation, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import CreateRole from '../CreateRole/CreateRole';
import SelectDropDown from '../../components/custom/SelectDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { createuserthunk, userList, usereditthunk } from '../../Redux/Thunk/createuserthunk'
import { getUserRole } from '../../Redux/Thunk/createrolethunk';
import DisplayError from '../../validation/DisplayError';
import DatePickerOpenTo from '../../components/custom/DatePickerYearMonth';
import moment from 'moment';
import {H1FormTitle, H5FormLabel} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'

const CardBody = styled('div')({
  //  backgroundColor:'#F0EFFD',
   padding:'10px 20px 40px',
   borderRadius:'10px',
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const TypeList = [
  {
    value:'Admin',
    label:'Admin'
  },
  {
    value:'User',
    label:'User'
  }
]

export default function CreateUser() {
  const {state} = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state)=>state.auth)
  const { fetchuserrole } = useSelector((state) => state.role)
  const [error,setError] = useState([])
  const [editUserDetails, setEditUerDetails] = useState(state ? state :{})
  const [value, setValue] = useState(Object.keys(editUserDetails).length == 0 ? null : new Date(editUserDetails?.birth_date).toISOString().split('T')[0]);   //'2014-08-18T21:11:54'
  const [open, setOpen ] = useState(false)
  const [reserror, setResError] = useState('')
  const [showPassword, setShowPassword]= useState(true)
  const [reportingTo, setReportingTo]= useState([])
  const [buttonDisable, setButtonDisable] = useState(false)
  const [usercreate_reportingTo, setUsercreate_reportingTo] = useState('')
  const [userDetails, setUserDetails] = useState({
    firstname:'',
    lastname:'',
    usercreate_email:'',
    usercreate_primaryphone:'',
    usercreate_secondaryphone:'',
    // user_designation:'',
    usercreate_role_id:'',
    usercreate_password:'',
    // usercreate_reportingTo:'',
    type:'',
  })

  const maxDateValue = new Date();
  maxDateValue.setFullYear(maxDateValue.getFullYear() - 18)

  useEffect(() => {
    dispatch(getUserRole())
    dispatch(userList()).then((res)=>{
      console.log(res.payload.data,"/usereee")
      setReportingTo(res?.payload?.data)
    })

    if(Object.keys(editUserDetails)?.length > 0){
      setUserDetails({
        firstname: editUserDetails ? editUserDetails?.firstname :'',
        lastname: editUserDetails ? editUserDetails?.lastname :'',
        usercreate_email: editUserDetails ? editUserDetails?.email :'',
        usercreate_primaryphone: editUserDetails ? editUserDetails?.primary_phone :'',
        usercreate_secondaryphone: editUserDetails ? editUserDetails?.secondary_phone :'',
        // user_designation: editUserDetails ? editUserDetails?.designation :'',
        usercreate_role_id: editUserDetails ? editUserDetails?.role?.id :'',
        // usercreate_reportingTo: editUserDetails ? editUserDetails?.reporting_to?.id :'',
        type: editUserDetails ? editUserDetails?.type :'',     
      })
      setUsercreate_reportingTo(editUserDetails ? editUserDetails?.reporting_to?.id ??'' :'')
    }
  },[])
  
  const handleState = (e) =>{
    setUserDetails({...userDetails, [e.target.name]:e.target.value})
  }
  const handleStateReportingTo = (e) =>{
    setUsercreate_reportingTo(e.target.value)
  }

  let check_first_digit = userDetails.usercreate_primaryphone ? userDetails.usercreate_primaryphone[0] : 0
  
  let validation_input = [
    {tagid: 'firstname', text: userDetails?.firstname, required: true, regex_name: 'text', min:1, errmsg: 'Please enter First name.'},
    {tagid: 'lastname', text: userDetails?.lastname, required: true, regex_name: 'text', min:1, errmsg: 'Please enter Last name.'},
    Number(check_first_digit) <= 5 ? {tagid: 'usercreate_primaryphone', text: userDetails.usercreate_primaryphone, required: true, regex_name: 'exact_x_digits', errmsg: 'Please enter valid mobile number.'} : {tagid: 'usercreate_primaryphone', text: userDetails.usercreate_primaryphone, required: true, regex_name: 'exact_x_digits', errmsg: 'Please enter valid mobile number.', x_count:10},
    {tagid: 'usercreate_email', text: userDetails.usercreate_email, regex_name: 'email', required: true, errmsg: 'Please enter valid email ID.'},
    {tagid: 'usercreate_secondaryphone', text: userDetails.usercreate_secondaryphone, regex_name: 'phone', errmsg: 'Please enter valid phone number.', x_count:10},
    Object.keys(editUserDetails).length > 0 ? <></>:{tagid: 'usercreate_password', text: userDetails?.usercreate_password, required: true, regex_name:'password', errmsg: "Password must contains at least 8 characters, Including 1 UPPER case, number and special characters"},
    // {tagid: 'user_designation', text: userDetails?.user_designation, required: true, regex_name: 'text', allow_numbers: true, other_chars:true, errmsg: 'Please enter designation.'},
    userDetails?.type === 'User'|| userDetails?.type === '' ? {tagid: 'usercreate_reportingTo', text: usercreate_reportingTo, required: true, regex_name: 'free_text', errmsg: 'Please enter reporting to.'}:<></>,
    {tagid: 'usercreate_role_id', text: userDetails?.usercreate_role_id, required: true, regex_name: 'free_text',min:3, errmsg: 'Please select role.'},
    {tagid: 'value', text: value === null ? '': value, required: true, regex_name: 'free_text', errmsg: 'Please select birth date.', min:1},
    {tagid: 'type', text: userDetails?.type, required: true, regex_name: 'free_text', errmsg: 'Please enter type.'},
  ]
  console.log(userDetails, editUserDetails,  'user')
  const changeBirthDate= (newValue) =>{
    const dateMoment = moment(newValue?.$d)
    const dateOnly = dateMoment.format('YYYY-MM-DD')
    setValue(dateOnly)
  }
  
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  console.log(usercreate_reportingTo,"usercreate_reportingTo")
  const handleSaveUserDetails=()=>{
    if(validate(validation_input)?.length==0){
      // Create User
      setButtonDisable(true)
      if(Object.keys(editUserDetails)?.length==0 ){
        userDetails.usercreate_reportingTo = (userDetails?.type === 'User'|| userDetails?.type === '' )? usercreate_reportingTo :'';
        userDetails.birth_date = value
        dispatch(createuserthunk(userDetails)).then((data)=>{
          if(data?.payload?.errormsg){
            sweetalert("error",data?.payload?.errormsg)
            setButtonDisable(false)
          }else{
            swal({
              buttons: {
                Cancel: {
                  text: "Dashboard",
                  value: "Cancel",
                  className: "popup-blue",
                },
                Delete: {
                  text: "User List",
                  value: "profile",
                  className: "popup-gray",
                },
              },
              title: `${data?.payload?.data}`,
              className: "custom-modal-user",
              closeOnClickOutside: false,
            }).then((value)=>{
              if(value=='profile'){
                navigate('/dashboard/userlist')
                setButtonDisable(false)
              }else{
                navigate('/dashboard')
                setButtonDisable(false)
              }
            }) 
          }
        })
    }else{
      //user Edited
      userDetails.usercreate_id = editUserDetails?.id
      userDetails.birth_date = value
      userDetails.usercreate_reportingTo =  userDetails?.type === 'User'|| userDetails?.type === '' ? usercreate_reportingTo : ''
      dispatch(usereditthunk(userDetails)).then((res)=>{
        if (res?.payload?.errormsg) {
          sweetalert('error', res?.payload?.errormsg)
          setButtonDisable(false)
        } else {
        navigate('/dashboard/userlist')
        sweetalert('success', res?.payload?.data)
        setButtonDisable(false)
        }
        
      })
    }
    }else{
     setError(validate(validation_input))
    }  
  }
   
  const sweetalert = (variant, text) => {
    swal({
      icon: variant==='error' ? 'error' :'success',
      title: text , 
      button: "Ok!",
    }); 
  };

  const handleModalOpen=()=>{
    setOpen(true);
  }
  const handleCopy = (e) => {
    e.preventDefault();
  }
  // const handleClose = () => {
  //   setOpen(false);
  // };
  
  return (
    <>
    <div>
   
    <Grid sx={{ borderBottom:'1px solid #e4e4e4',mt:2, ml:6, mr:6, pb:0.5, display:"flex",flexDirection:{lg:'row',sm:'row',xs:'column-reverse'}, justifyContent:{lg:'space-between',sm:'space-between',xs:'start'},alignItems:{lg:'flex-end',md:'flex-end',xs:'center'}}}>   
      {Object.keys(editUserDetails).length > 0 ? <H1FormTitle sx={{color: themeColor.palette.Primarytext1}}>Edit User</H1FormTitle> : <>
        <Grid item xs={10}>
          <H1FormTitle sx={{color: themeColor.palette.Primarytext1}}>Create User</H1FormTitle>
        </Grid>
        </>
      }
    </Grid>

    <CardBody sx={{mt:2, pb:6, ml:{xs:1,sm:1,xl:6,lg:6}, mr:{xs:1,sm:1,xl:6,lg:6}}}>
        {/* <Grid sx={{flex:0.5,display:{xs:'none',md:'flex',lg:"flex"}}} >
            <Typography sx={{fontWeight:'600',fontSize:{lg:'20px',md:'18px',sm:'12px'}}}>User Details </Typography>  
        </Grid>
        <hr></hr> */}

      <Box style={{display:'flex', flexDirection:{sm:'column', xs:'column', lg:'row'}}}>
        <Grid sx={{flex:0.5,display:{xs:'none',md:'flex',lg:"flex"}}} >
          <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>User</H5FormLabel>  
        </Grid>

        <Grid container sx={{flex:3,justifyContent:'space-between',alignItems:'center'}} spacing={{lg:5,md:5,sm:2,xs:2}} >
                <Grid item xs={12} md={6} sm={6} lg={4}>
                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>First Name<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                    <InputBox 
                     name='firstname'
                     onchange={handleState}
                     value={userDetails?.firstname?.trim()}
                     sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'16px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="FirstName"
                    />
                   <DisplayError error={error} fortagid='firstname' />
                </Grid>

                <Grid item xs={12} md={6} sm={6} lg={4}>
                  <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Last Name<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                    <InputBox 
                     name='lastname'
                     value={userDetails?.lastname?.trim()}
                     onchange={handleState}
                     sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444',  fontSize:'16px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="lastName"/>
                    <DisplayError error={error} fortagid='lastname' />
                </Grid>

                {/* <Grid item xs={12} md={6} sm={6} lg={3}>
                    <Typography>Designation<span style={{color:'#FF0000'}}>*</span></Typography>  
                    <InputBox 
                      name='user_designation'
                      onchange={handleState}
                      value={userDetails?.user_designation}
                      sx={{border:"1px solid #B0AEFF", background:'#ffffff', color:'#444444',height:"40px", fontSize:'14px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="Ex.Manager"/>
                      <DisplayError error={error} fortagid='user_designation'/>
                </Grid> */}

                <Grid item xs={12} md={6} sm={6} lg={4}>
                  <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Birth Date<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                    <DatePickerOpenTo
                        name ='value'
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={value} 
                        onChange={changeBirthDate} 
                        maxDate={maxDateValue}
                        style={{background:'#ffffff', fontSize:'16px', lineHeight: '17px', p:0.1, mt:1, pl:1}}
                     />
                    <DisplayError error={error} fortagid='value'/>
                </Grid>          
        </Grid>
      </Box>

      <Box style={{marginTop:'30px', display:'flex', flexDirection:{sm:'column',xs:'column',lg:'row'}}}>
          <Grid sx={{flex:0.5,display:{xs:'none',md:'flex',lg:"flex"}}} >
            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Contact</H5FormLabel>  
          </Grid>
          <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={{lg:5,md:5,sm:2,xs:2}} >
                  <Grid item xs={12} md={6} sm={6} lg={4}>
                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Email<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                      <InputBox 
                        name='usercreate_email'
                        onchange={handleState}
                        value={userDetails?.usercreate_email?.trim()}
                        sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444',  fontSize:'16px',  lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="abc@gmail.com"/>
                        <DisplayError error={error} fortagid='usercreate_email' />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} lg={4}>
                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Mobile Number<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                      <InputBox 
                        name='usercreate_primaryphone'
                        onchange={(e)=>{
                          if(e.target.value.length <=10)handleState(e)
                        }} 
                        value={userDetails?.usercreate_primaryphone?.trim()}
                        sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'16px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="6789012345"/>
                        <DisplayError error={error} fortagid='usercreate_primaryphone' /> 
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} lg={4}>
                    <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Alternate No</H5FormLabel>  
                      <InputBox 
                        name='usercreate_secondaryphone'
                        onchange={(e)=>{
                          if(e.target.value.length <=10)handleState(e)
                        }} 
                        value={userDetails?.usercreate_secondaryphone?.trim()}
                        sx={{border:"1px solid #B0AEFF", background:'#ffffff', color:'#444444',height:"40px",  fontSize:'16px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="1234567890"/>
                        <DisplayError error={error} fortagid='usercreate_secondaryphone' />  
                  </Grid>
          </Grid>
      </Box>

    
      <Box style={{marginTop:'30px', display:'flex', flexDirection:{sm:'column',xs:'column', md:'row', lg:'row'}}}>
          <Grid sx={{flex:0.5,display:{xs:'none',md:'flex',lg:"flex"}}} >
            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Role</H5FormLabel>  
          </Grid>
          
          <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={{lg:4, md:5, sm:2, xs:2}}>
              <Grid item xs={12} md={6} sm={6} lg={4}>
                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Role<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                  <SelectDropDown 
                    name='usercreate_role_id'
                    options={fetchuserrole?.data?.filter((filter_god) => filter_god.role.toLocaleLowerCase() != 'god').map((role) => {return {value:role?.id, label:role?.role}})} 
                    onchange={handleState}
                    value = {userDetails?.usercreate_role_id}
                    placeholder='Select Role'
                    //  defaultlabel='Select role'
                    sx={{mt:1, fontSize:'16px', backgroundColor:'#ffffff', borderRadius:"10px"}}
                  />
                  <DisplayError error={error} fortagid='usercreate_role_id'/>     
              </Grid>
              {/* Add roles */}
             
              <Grid item xs={12} md={1} sm={1} lg={1}> 
                <Tooltip title="Create New Role">
                  <IconButton sx={{ mt: { xs: 1, sm: 4, xl: 4, lg: 3 }, p: 0 }} onClick={handleModalOpen}>
                    <AddPlusIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
             
              <Grid item xs={12} md={4} sm={4} lg={3}>
                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Type<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                  <SelectDropDown 
                    name='type'
                    options={TypeList} 
                    onchange={handleState}
                    value = {userDetails?.type}
                    placeholder='Select Type'
                    //  defaultlabel='Select role'
                    sx={{mt:1, fontSize:'16px', backgroundColor:'#ffffff', borderRadius:"10px"}}
                  />
                  <DisplayError error={error} fortagid='type'/>     
              </Grid>

              {userDetails?.type === '' || userDetails?.type === 'User' ? <Grid item xs={12} md={6} sm={6} lg={4}>
                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Reporting To<span style={{color:'#FF0000'}}>*</span></H5FormLabel>
                <SelectDropDown 
                  name='usercreate_reportingTo'
                  options={reportingTo?.filter((name) => name.id != user.id).map((name) => {return {value:name.id, label:`${name?.firstname} ${name?.lastname}`}})} 
                  onchange={handleStateReportingTo}
                  value = {usercreate_reportingTo}
                  placeholder='Select Name'
                  defaultlabel=''
                  sx={{ mt:1,backgroundColor:'#ffffff',borderRadius:"10px", fontSize:'16px',}}
                  MenuProps={MenuProps}
                />
                <DisplayError error={error} fortagid='usercreate_reportingTo'/>     
              </Grid> :<Grid item xs={12} md={6} sm={6} lg={3}></Grid>}

              {/* <Grid item xs={12} md={6} sm={6} lg={3}></Grid> */}
          </Grid>
      </Box>

      {Object.keys(editUserDetails).length > 0 ? <></> : <Box style={{marginTop:'30px', display:'flex', flexDirection:{sm:'column',xs:'column', md:'row', lg:'row'}}}>
          <Grid sx={{flex:0.5,display:{xs:'none',md:'flex',lg:"flex"}}} >
            <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Password</H5FormLabel>  
          </Grid>
          
          <Grid container sx={{flex:3,justifyContent:'space-between'}} spacing={{lg:4, md:5, sm:2, xs:2}}>
              <Grid item xs={12} md={6} sm={6} lg={4}>
                <H5FormLabel sx={{color: themeColor.palette.InputLabel}}>Password<span style={{color:'#FF0000'}}>*</span></H5FormLabel>  
                  <InputBox 
                    type={showPassword ?'password': 'text'}
                    name='usercreate_password'
                    onchange={handleState}
                    onCopy={handleCopy}
                    onPaste={(e) => e.preventDefault()}
                    value={userDetails?.usercreate_password?.trim()}
                    sx={{border:"1px solid #B0AEFF", background:'#ffffff',height:"40px", color:'#444444', fontSize:'16px', lineHeight: '17px',width:'100%', borderRadius:'8px', p:0.1, mt:1, pl:1}} placeholder ="Password"
                    endAdornment={
                      <InputAdornment position="end">
                          <IconButton style={{ml:1}}  aria-label="toggle password visibility"
                            onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <EyeCloseIcon /> : <EyeOpenIcon />}
                          </IconButton>
                          </InputAdornment> }/>
                  <DisplayError error={error} fortagid='usercreate_password' />  
              </Grid>  
          </Grid>
      </Box>}
    </CardBody>

    <Box sx={{mt:3, mr:{xs:1,sm:1,xl:6,lg:6},mb:2,display:'flex',justifyContent:'end' }}>
      <CustemBtn onClick={handleSaveUserDetails} disabled={buttonDisable} variant="contained" sx={{borderRadius:"10px", pl:5, pr:5, fontSize:'16px'}} text="Save" />
    </Box>

     {/* {reserror?<small style={{color:'red'}}> {reserror} </small>:''} */}
      <CreateRole open={open} setOpen={setOpen} handleModalOpen= {handleModalOpen} />
      </div>
    </>
  );
}