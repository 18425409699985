import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Paper, Tooltip, Snackbar, Typography, styled, IconButton, Divider, CircularProgress } from '@mui/material';
import { H1FormTitle, T6TableText, T1ButtonText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T2popupSubText } from '../../Theme/theme';
import themeColor from '../../Theme/themeColor'
import { CustemBtn, InputBox, TextArea } from '../../components/custom';
import { ActionButtons, ApproveBtn, IFChild1, IFChild2, IFIconBG, IFParent, RejectBtn } from './NewOppoDetailscss';
import { Identified, PreviewIcon, QualifiedPending, Rejected } from './Icons';
import moment, { now } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { createFeasibility, downloaddocument, opportunityApprovedForUser, sendrequsetforapproval, uploadstagedocument } from '../../Redux/Thunk/createopportunitythunk';
import ManagerActions from './ManagerActions';
import ExecutiveReq from './ExecutiveReq';
import OppStageName from './OppStageName';
import DocumentUpload from './DocumentUpload';
import CreateCommercials from './CreateCommercials';
import RejectActions from './RejectActions';
import DisplayComponent from './DisplayComponent';
import PreviewDoc from './PreviewDoc';
import AdminRole from '../../adminrole/Adminrole';

export default function OpportunityJourney(props) {
   const dispatch = useDispatch()
   const { user } = useSelector((state) => state.auth)
   const permissionsDetails = user?.data?.user.role.permissions
   const [feasibility_remark, setFeasibility_remark] = useState('')
   const [error, setError] = useState([]);
   const [user_ids] = useState(props?.ids)
   const [loader, setLoader] = useState(false)
   const [hierchy, setHierachy] = useState(false)
   const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())

   useEffect(() => {
      findIds(props?.opp_stage?.assigned_to?.id)
   }, [props?.opp_stage?.opportunity_id])

   const findIds = (id1, id2) => {
      if (user_ids?.includes(id1) || user_ids?.includes(id2)) {
         setHierachy(true)
      } else {
         setHierachy(false)
      }
   }

 

   const handleFeasibility = () => {
      setLoader(true)
      dispatch(createFeasibility({ opportunity_id: props.opp_stage.id, remark: feasibility_remark })).then((res) => {
         if (res.payload.errormsg) {
            setFeasibility_remark('')
            sweetalert('error', res.payload.errormsg)
            setLoader(false)
         } else {
            setFeasibility_remark('')
            sweetalert('success', res.payload.data)
            setLoader(false)
         }
      })
   }

   // downloadfile 

   const downloadFile = (document) => {
      console.log(document.url)
      //  window.open(`localhost:1337/${document.url}`, '_blank')
      // dispatch(downloaddocument(document.id)).then((res) => {
      //    if (res.payload.errormsg) {
      //       sweetalert('error', res.payload.errormsg)
      //    }
      // })
   }

   // request raised again
   const handleRaisedRequestAgain = () => {
      dispatch(sendrequsetforapproval({ opportunity_id: props.opp_stage.id, opportunity_stage: props.opp_stage?.opportunity_stage })).then((res) => {
         if (res.payload.errormsg) {
            return sweetalert('error', res.payload.errormsg)
         } else {
            return sweetalert('success', res.payload.data)
         }
      })
   }

   const sweetalert = (variant, text) => {
      swal({
         icon: variant === 'error' ? 'error' : 'success',
         title: text,
         button: "Ok!",
      });
   };

   return <>
      <DisplayComponent show={(user?.data?.user?.id == props?.opp_stage?.assigned_to?.id)} props={props} />
      <Box sx={{minHeight: '100vh', height:'900px', overflowY: 'auto', '::-webkit-scrollbar': { width: '0' }}}>
      {
         props?.stage_data?.map((stage) => {
            switch (stage.for_stage) {

               case 'Qualified': {
                  return <>
                   
                     <IFParent sx={{flexDirection: `${stage?.status == 'Pending' ? !permissionsDetails?.perm_approve_edit ? 'column':'column' : 'column'}` }}>
                        {/* <IFChild1>
                           <IFIconBG>
                              {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                           </IFIconBG> */}
                           <OppStageName stage={stage} opp_stage={props?.opp_stage} />
                        {/* </IFChild1> */}
                           <IFChild2>
                              <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel, mt:1}}>
                                 {stage?.req_remark ? 'Request Remark' : ''}
                              </H7TablePrimeContent>
                              <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt:0.5, mb:0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                                 {stage?.req_remark}
                              </T5Normaltext>
                           </IFChild2>
                        <IFChild2>
                           { 
                           // (permissionsDetails?.perm_create_opportunity && permissionsDetails?.perm_approve_edit) ? <>
                           //    <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props?.opp_stage?.id} opportunity_stage={props?.opp_stage?.opportunity_stage} /> 
                           //    <ExecutiveReq stage={stage} /></> :
                           //       (permissionsDetails?.perm_approve_edit && !permissionsDetails?.perm_create_opportunity) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props?.opp_stage?.id} opportunity_stage={props?.opp_stage?.opportunity_stage} />
                           //       : <ExecutiveReq stage={stage} />
                              ((checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy)) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props?.opp_stage?.id} opportunity_stage={props?.opp_stage?.opportunity_stage} />
                              : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                     </IFParent>
                     <Divider sx={{ mt: 2, mb: 3 }} />
                  </>

               }
                  break;
               case 'Feasible': {
                  return <>
                     <Box>
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1>
                              <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG> */}
                              <OppStageName stage={stage} opp_stage={props.opp_stage} />
                           {/* </IFChild1> */}

                        </IFParent>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                           {stage?.feasibility?.req_remark ? 'Request Remark' : ''}
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt: 0.5, mb: 0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.feasibility?.req_remark}
                        </T5Normaltext>
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props.opp_stage.id} opportunity_stage={props.opp_stage.opportunity_stage} />
                                 : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                  </>
               }
                  break;
               case 'Solution': {
                  return <>
                     <Box>
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1> */}
                              {/* <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG> */}
                              <OppStageName stage={stage} opp_stage={props.opp_stage} />
                           {/* </IFChild1> */}
                          
                        </IFParent>
                        <PreviewDoc stage={stage} />
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                           {stage?.req_remark ? 'Request Remark' : ''}
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt: 0.5, mb: 0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.req_remark}
                        </T5Normaltext>
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props.opp_stage.id} opportunity_stage={props.opp_stage.opportunity_stage} />
                                 : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                  </>
               }

                  break;
               case 'Commercials': {
                  return <>
                     {/* sx={{ borderBottom: '0.5px solid #AAAA' }} */}
                     <Box >
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1>
                              <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG> */}
                              <OppStageName stage={stage} opp_stage={props?.opp_stage} />
                           {/* </IFChild1> */}
                        </IFParent>
                        <H7TablePrimeContent sx={{ fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           <CreateCommercials commercials={stage?.commercials} opp_stage={stage} />
                        </H7TablePrimeContent>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                           {stage?.req_remark ? 'Request Remark' : ''}
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt: 0.5, mb: 0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.req_remark}
                        </T5Normaltext>
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props?.opp_stage.id} opportunity_stage={props?.opp_stage?.opportunity_stage} />
                                 : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                  </>
               }
                  break;

               // case 'Negotiation': {
               //    return <>
               //       {/* sx={{ borderBottom: '0.5px solid #AAAA' }} */}
               //       <Box > 
               //          <IFParent sx={{ flexDirection: `${permissionsDetails.perm_approve_edit ? 'column' : 'row'}` }}>
               //             <IFChild1>
               //                <IFIconBG>
               //                {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending/>:<Identified /> }
               //                </IFIconBG>
               //                <OppStageName stage={stage} opp_stage={props?.opp_stage}/>
               //             </IFChild1>
               //          </IFParent>
               //          <H7TablePrimeContent sx={{ fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
               //             <CreateCommercials commercials={stage?.commercials} opp_stage={stage}/>
               //          </H7TablePrimeContent>
               //          <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel }}>
               //       {stage?.req_remark ? 'Request Remark' : ''}
               //       </H7TablePrimeContent>
               //       <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt:0.5, mb:0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
               //          {stage?.req_remark}
               //       </T5Normaltext>
               //          <IFChild2>
               //             {
               //                permissionsDetails?.perm_approve_edit ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props.opp_stage.id} opportunity_stage={props.opp_stage.opportunity_stage} />
               //                   : <ExecutiveReq stage={stage} />
               //             }
               //          </IFChild2>
               //          <Divider sx={{mt: 2, mb: 3}}/>
               //       </Box>
               //    </>
               // }

               case 'NegotiationCommercials': {
                  return <>
                     {/* sx={{ borderBottom: '0.5px solid #AAAA' }} */}
                     <Box >
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1>
                              <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG> */}
                              <OppStageName stage={stage} opp_stage={props?.opp_stage} />
                           {/* </IFChild1> */}
                        </IFParent>
                        <H7TablePrimeContent sx={{ fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           <CreateCommercials commercials={stage?.commercials} opp_stage={stage} />
                        </H7TablePrimeContent>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                           {stage?.req_remark ? 'Request Remark' : ''}
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt: 0.5, mb: 0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.req_remark}
                        </T5Normaltext>
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props.opp_stage.id} opportunity_stage={props.opp_stage.opportunity_stage} />
                                 : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                  </>
               }
                  break;

                  case 'NegotiationProposal': {
                     return <>
                        {/* sx={{ borderBottom: '0.5px solid #AAAA' }} */}
                        <Box>
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1>
                              <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG> */}
                              <OppStageName stage={stage} opp_stage={props?.opp_stage} />
                           {/* </IFChild1> */}
                        </IFParent>
                        <PreviewDoc stage={stage} />
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                           {stage?.req_remark ? 'Request Remark' : ''}
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt: 0.5, mb: 0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.req_remark}
                        </T5Normaltext>
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit)|| (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props?.opp_stage.id} opportunity_stage={props?.opp_stage?.opportunity_stage} />
                                 : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                     </>
                  }
                   break;

               case 'Proposal': {
                  return <>
                     <Box>
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit)|| (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1>
                              <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG> */}
                              <OppStageName stage={stage} opp_stage={props?.opp_stage} />
                           {/* </IFChild1> */}
                        </IFParent>
                        <PreviewDoc stage={stage} />
                        <H7TablePrimeContent sx={{color: themeColor.palette.InputLabel }}>
                           {stage?.req_remark ? 'Request Remark' : ''}
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt: 0.5, mb: 0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.req_remark}
                        </T5Normaltext>
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props?.opp_stage.id} opportunity_stage={props?.opp_stage?.opportunity_stage} />
                                 : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                  </>
               }
                  break;
               case 'VerbalConfirmation': {
                  return <>
                     <Box>
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1>
                              <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG> */}
                              <OppStageName stage={stage} opp_stage={props?.opp_stage} />
                           {/* </IFChild1> */}
                        </IFParent>
                        <PreviewDoc stage={stage} />
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                           {stage?.req_remark ? 'Request Remark' : ''}
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt: 0.5, mb: 0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.req_remark}
                        </T5Normaltext>
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props.opp_stage.id} opportunity_stage={props.opp_stage.opportunity_stage} />
                                 : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                  </>
               }
                  break;
               case 'ClosedWon': {
                  return <>

                     {/* {
                        (props.opp_stage.opportunity_stage == 'ClosedWon' && props.opp_stage.approval_status == 'Approved') ?

                           !permissionsDetails.perm_approve_edit ? <DocumentUpload name='CAF' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''

                           : ''
                     } */}

                     <Box>
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1>
                              <IFIconBG>
                                 {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending /> : <Identified />}
                              </IFIconBG> */}
                              <OppStageName stage={stage} />
                           {/* </IFChild1> */}
                        </IFParent>
                        <PreviewDoc stage={stage} />
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                           {stage?.req_remark ? 'Request Remark' : ''}
                        </H7TablePrimeContent>
                        <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt: 0.5, mb: 0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.req_remark}
                        </T5Normaltext>
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props.opp_stage.id} opportunity_stage={props.opp_stage.opportunity_stage} />
                                 : <ExecutiveReq stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                  </>
               }
                  break;

               case 'ClosedLost': {
                  return <>
                     {/* {
                           (props.opp_stage.opportunity_stage == 'ClosedWon' && props.opp_stage.approval_status == 'Approved') ?
   
                              !permissionsDetails.perm_approve_edit ? <DocumentUpload name='CAF' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''
   
                              : ''
                        } */}

                     <Box>
                        <IFParent sx={{ flexDirection: `${(checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? 'column' : 'column'}` }}>
                           {/* <IFChild1>
                              <IFIconBG>
                                 <Rejected />
                              </IFIconBG> */}
                              <OppStageName stage={stage} />
                           {/* </IFChild1> */}
                        </IFParent>
                        {/* <T5Normaltext sx={{ whiteSpace: 'pre-line', wordBreak: 'break-all', mt:0.5, mb:0.5, fontWeight: 500, color: themeColor.palette.Primarytext1 }}>
                           {stage?.remarks}
                        </T5Normaltext> */}
                        <IFChild2>
                           {
                              (checkAdminRole && permissionsDetails?.perm_approve_edit) || (permissionsDetails?.perm_approve_edit && hierchy) ? <ManagerActions opportunity={props?.opp_stage} hierchy={hierchy} stage={stage} opportunity_id={props?.opp_stage?.id} opportunity_stage={props?.opp_stage?.opportunity_stage} />
                                 : <ExecutiveReq opportunity={props?.opp_stage} stage={stage} />
                           }
                        </IFChild2>
                        <Divider sx={{ mt: 2, mb: 3 }} />
                     </Box>
                  </>
               }
                  break;
               // case 'CAF': {
               //    return <>

               //       {
               //          (props.opp_stage.opportunity_stage == 'ClosedWon' && props.opp_stage.approval_status == 'Approved') ?

               //             !permissionsDetails.perm_approve_edit ? <DocumentUpload name='CAF' opportunity_stage={props.opp_stage.opportunity_stage} opportunity_id={props.opp_stage.id} /> : ''

               //             : ''
               //       } 

               //       <Box>
               //          <IFParent sx={{ flexDirection: `${permissionsDetails.perm_approve_edit ? 'column' : 'row'}` }}>
               //             <IFChild1>
               //                <IFIconBG>
               //                   {stage?.status === "Rejected" ? <Rejected /> : stage?.status === 'Pending' ? <QualifiedPending/>:<Identified /> }
               //                </IFIconBG>
               //                <OppStageName stage={stage} opp_stage={props.opp_stage}/> 
               //                {(stage?.for_stage == 'CAF' && stage?.status == 'Approved') ?  <> <Typography sx={{ml:3, color:'#333333', fontWeight:700, mr:0.5}}>Service ID : </Typography>  {props.opp_stage.service_id} </> :'' }

               //             </IFChild1>
               //          </IFParent>
               //          <PreviewDoc stage ={stage} />
               //          <IFChild2>
               //             {
               //                permissionsDetails.perm_approve_edit ? <ManagerActions hierchy={hierchy} stage={stage} opportunity_id={props.opp_stage.id} opportunity_stage={props.opp_stage.opportunity_stage} />
               //                   : <ExecutiveReq stage={stage} />
               //             }
               //          </IFChild2>
               //          <Divider sx={{mt: 2, mb: 3}}/>
               //       </Box>
               //    </>
               // }
               //    break;
            }
         })
      }
      
      <IFParent>
         <IFChild1>
            <IFIconBG>
               <Identified />
            </IFIconBG>
            <T2popupSubText sx={{ marginLeft: '5px' }}>
               Identified
            </T2popupSubText>
         </IFChild1>
         <IFChild2>
            <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
               On Date :
            </H7TablePrimeContent>
            <H7TablePrimeContent sx={{ color: themeColor.palette.primarytext2, fontWeight: 500 }}>
               {moment(props?.opp_stage?.createdAt).format('D MMM Y')}
            </H7TablePrimeContent>
         </IFChild2>
      </IFParent>
      </Box>
   </>
}