const headCells = [
  {
    id: 'Opportunity ID',
    numeric: false,
    disablePadding: true,
    label: 'Opportunity ID',
  },
  {
    id: 'Contact Name',
    numeric: false,
    disablePadding: true,
    label: 'Contact Name',
  },
  {
    id: 'Created By',
    numeric: false,
    disablePadding: true,
    label: 'Created By',
  },
  {
    id: 'Assigned to',
    numeric: false,
    disablePadding: true,
    label: 'Assigned to',
  },
  {
    id: 'Service Provider',
    numeric: false,
    disablePadding: true,
    label: 'Service Provider',
  },
  {
    id: 'Product',
    numeric: false,
    disablePadding: true,
    label: 'Product',   
  },
  {
    id: 'Opportunity type',
    numeric: false,
    disablePadding: true,
    label: 'Opportunity type',   
  },
  {
    id: 'Date of Creation',
    numeric: false,
    disablePadding: true,
    label: 'Date of Creation',   
  },
  {
    id: 'Status',
    numeric: false,
    disablePadding: true,
    label: 'Status',   
  },
];

  export { headCells }