import React from 'react'

const CallIcon = ({name}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4067_114869)">
    <path d="M14.3672 3C15.9606 3.41959 17.414 4.25457 18.5791 5.41967C19.7442 6.58476 20.5792 8.03821 20.9988 9.63158" stroke={name ? '#FFFFFF' : "#1B1C00"} stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 6C15.9563 6.52342 17.2661 7.83316 17.7895 9.78947" stroke={name ? '#FFFFFF' : "#1B1C00"} stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.3149 13.6258C14.4157 13.5587 14.5316 13.5179 14.6522 13.5069C14.7727 13.496 14.8941 13.5152 15.0053 13.563L19.2951 15.4851C19.4397 15.5469 19.5604 15.6538 19.6391 15.7899C19.7178 15.926 19.7503 16.0839 19.7318 16.2401C19.5904 17.2962 19.0703 18.2651 18.2682 18.9665C17.4661 19.6679 16.4365 20.0541 15.371 20.0532C12.09 20.0532 8.94339 18.7499 6.62338 16.4299C4.30337 14.1099 3 10.9633 3 7.68226C2.99919 6.61672 3.38539 5.58715 4.08679 4.78502C4.7882 3.9829 5.75705 3.46281 6.81318 3.32149C6.9693 3.30294 7.12723 3.33546 7.26333 3.41416C7.39943 3.49287 7.50638 3.61354 7.56817 3.75811L9.49022 8.05157C9.53744 8.16184 9.55668 8.28208 9.5462 8.40159C9.53573 8.52109 9.49587 8.63615 9.43018 8.73652L7.4863 11.0479C7.41735 11.1519 7.37657 11.2721 7.36796 11.3966C7.35935 11.5211 7.3832 11.6458 7.43718 11.7583C8.18945 13.2983 9.7813 14.8711 11.3259 15.6161C11.439 15.6698 11.5642 15.6931 11.6891 15.6837C11.814 15.6743 11.9343 15.6325 12.0381 15.5624L14.3149 13.6258Z" fill={name ? '#FFFFFF' : "#088F05"} stroke="#088F05" stroke-width="0.5761" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_4067_114869">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default CallIcon