import React, { useEffect, useState } from 'react'
import Header from './Header'
import { Box, Button, CircularProgress, Divider, Grid, styled } from '@mui/material'
import CafseridDetails from './CafseridDetails'
import CafSerIDFlow from './CafSerIDFlow'
import { Sidebar } from './CAF.Ser.IDcss'
import DocumentUpload from '../DocumentUpload'
import { CustemBtn, TextArea } from '../../../components/custom'
import { useDispatch, useSelector } from 'react-redux'
import { oppodetails, uploadCOFServiceID, validateThirdPartyId } from '../../../Redux/Thunk/createopportunitythunk'
import { H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T1ButtonText } from '../../../Theme/theme';
import themeColor from '../../../Theme/themeColor'
import { InputBox } from '../../../components/custom'
import dayjs from 'dayjs';
import { storeBillingStartDate, storeOtherCAFID } from '../../../Redux/slices/createOpportunitySlice'
import DatePicker from '../../../components/custom/DateRangePicker'
import moment from 'moment'
import DatePickerOpenTo from '../../../components/custom/DatePickerYearMonth'
import swal from 'sweetalert'
import DisplayError from '../../../validation/DisplayError'
import AdminRole from '../../../adminrole/Adminrole';

const CardInfo = styled('div')({
    display:'flex',
  })

const DisplayActions = ({ opportunity_id, opp_stage, For }) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth)
    const permissionsDetails = user?.data?.user.role.permissions
    const { errormsgCaf, errormsgBilling, cafService, storeOtherCafID } = useSelector((state) => state.opportunity)
    const [otherCafID, setOtherCafID] = useState('')
    const [billingStartDate, setBillingStartDate] = useState(dayjs())
    const [displayEndDate, setDisplayEndDate] = useState(false)
    const [billingEndDate, setBillingEndDate] = useState('')
    const [reqremark, setReqRemark] = useState('');
    const [remarkerror, setRemarkError] = useState([])
    const [validate, setValidate] = useState(false)
    const [loaderForValidate, setLoaderForValidate] = useState(false)
    const [checkAdminRole, setCheckAdminRole] = useState(AdminRole())

    const pastDate = new Date();
    pastDate.setDate(pastDate.getDate() - 30); 

   let show_display_rejected = opp_stage?.oppo_stages?.filter((ele) => (ele.status == "Rejected"))
   
    useEffect(()=>{
        if(For === 'COF'){
            // setOtherCafID('')
        }else{
            const dateMoment = billingStartDate
            const value = dateMoment.add(opp_stage?.oppo_details?.commercials?.months, 'months')
            setDisplayEndDate(true)
            setBillingEndDate(value.format('YYYY-MM-DD'))
            dispatch(storeBillingStartDate({startDate: dateMoment, endDate:value.format('YYYY-MM-DD')}))
            //  setOtherCafID('')
        }
    },[])

    const handleChangedOtherCafID =(e)=>{
        if(e.target.value?.length > 0 ){
            setOtherCafID(e.target.value)
            setValidate(false)
            dispatch(storeOtherCAFID(e.target.value)) 
        }else{
            setValidate(false)
            setOtherCafID('')
        }   
    }

    const handleChangedBillingStartDate = (newValue) => {
        const dateMoment = moment(newValue?.$d)
        const dateOnly = dateMoment.format('YYYY-MM-DD');
        
        // if(new Date() <= newValue?.$d) {
        setBillingStartDate(dateOnly)
        const value = dateMoment.add(opp_stage?.oppo_details?.commercials?.months, 'months')
        setDisplayEndDate(true)
        setBillingEndDate(value.format('YYYY-MM-DD'))
        dispatch(storeBillingStartDate({ startDate: dateOnly, endDate: value.format('YYYY-MM-DD') }))
        // }else{
        //     sweetalert('error', 'Date selection can not be for the past, please select Other Date.')
        // }
    }

    const handleChangedThirdPartyId = () =>{
        setLoaderForValidate(true)
        dispatch(validateThirdPartyId({third_party_id : otherCafID?.trim(), For: For})).then((res)=>{
            if (res.payload.errormsg) {
                sweetalert('error', res.payload.errormsg) 
                setLoaderForValidate(false)   
            } else {
                setLoaderForValidate(false)
                if(res?.payload?.data?.length === 0){
                    setValidate(true)
                }else{
                    swal({
                        buttons: {
                            Cancel: {
                                text: "Yes",
                                value: "profile",
                                className: "popup-blue",
                            },
                            Delete: {
                                text: "No",
                                value: "Cancel",
                                className: 'popup-gray',
                            },
                        },
                        title: For === 'COF' ? `Third Party COF Id already exit . Do you want to use ?` : 'Third Party Service Id already exit . Do you want to use ?',
                        className: "custom-modal",
                        closeOnClickOutside: false,
                    }).then((value) => {
                        if (value == 'profile') {
                            setValidate(true)
                        } else {
                            setValidate(false)
                        }
                    })
                }
            }
        })
    }

    const sweetalert = (variant, text) => {
        swal({
        icon: variant === 'error' ? 'error' : 'success',
        title: text,
        button: "Ok!",
        });
    };

      const handleRequestRemark =(e) => {
        setRemarkError([])
        setReqRemark(e.target.value)
    } 
   
    
    return(
    <>       
        {
            (checkAdminRole && permissionsDetails?.perm_cof_create)|| (permissionsDetails.perm_cof_create && user?.data?.user?.id == opp_stage?.oppo_details?.assigned_to)  || (checkAdminRole && permissionsDetails?.perm_service_create) || (permissionsDetails.perm_service_create && user?.data?.user?.id == opp_stage?.oppo_details?.assigned_to) ? 
        <>
                <Grid container spacing={3}>
               {opp_stage?.oppo_stages?.length === 0 ?'' : <> <Grid item lg={4} xs={3} md={3} sm={3} sx={{ flex: 1 }}>
                    <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb: 0.2 }}>
                    {For === 'COF' ? 'COF ID' : 'Service ID'}
                    </H7TablePrimeContent>
                    <T5Normaltext sx={{ color: themeColor.palette.OpportunityID, fontWeight: 600, pb: 2 }}>
                        {For === 'COF' ? opp_stage?.oppo_details?.cofdetails?.cof_id : opp_stage?.oppo_details?.servicedetails?.service_id}
                    </T5Normaltext>
                </Grid>
                </>}
                
                {opp_stage?.oppo_details?.commercials?.billed_by !== 'Mobigic' ? <Grid item lg={4} xs={3} md={3} sm={3} sx={{ flex: 1 }}>
                    {/* {opp_stage?.oppo_details?.commercials?.billed_by === 'Mobigic' ? (
                    <T5Normaltext sx={{ color: themeColor.palette.OpportunityID, fontWeight: 600 }}>
                        {For === 'COF' ? '': opp_stage?.oppo_details?.servicedetails?.service_id}
                    </T5Normaltext>) :<></>} */}
                    
                    {(((checkAdminRole && permissionsDetails?.perm_cof_create) || (permissionsDetails.perm_cof_create && user?.data?.user?.id == opp_stage?.oppo_details?.assigned_to) || (checkAdminRole && permissionsDetails?.perm_service_create) || (permissionsDetails.perm_service_create && user?.data?.user?.id == opp_stage?.oppo_details?.assigned_to))) && (opp_stage?.oppo_stages?.length === 0 || show_display_rejected?.length > 0)? (
                    <>
                        <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb: 0.2 }}>
                            Third Party {For === 'COF' ? 'COF': 'Service'} ID <span style={{ color: 'red' }}>*</span>
                        </H7TablePrimeContent>
                        <InputBox
                            value={otherCafID.trim()}
                            onchange={handleChangedOtherCafID}
                            sx={{ width: '100%', border: "1px solid #B0AEFF", color: '#444444', fontSize: '14px', backgroundColor: '#ffffff', borderRadius: '8px', p: 0.5, mt: 1, pl: 1, mr: 3 }}
                            placeholder={For === 'COF' ? 'Enter COF ID': 'Enter Service ID'}
                        />
                        {errormsgCaf?.value === true ? <small style={{ color: 'red' }}>{`Please Enter ${For === 'COF'? 'Cof' :'Service'} ID`}</small> : ''}
                    </>
                    ) : (
                    <T5Normaltext sx={{ color: themeColor.palette.OpportunityID, fontWeight: 600 }}>
                        {For === 'COF' ? opp_stage?.oppo_details?.cofdetails?.third_party_cof_id : opp_stage?.oppo_details?.servicedetails?.third_party_service_id}
                    </T5Normaltext>
                    )}
                </Grid> :<></>}
                
                {opp_stage?.oppo_details?.commercials?.billed_by !== 'Mobigic' ? 
                    <Grid item lg={3} xs={3} md={3} sm={3} sx={{ flex: 1 }}>
                        <Button
                            id="validate" variant="contained" disabled={otherCafID?.length === 0 || validate} onClick={handleChangedThirdPartyId}
                            sx={{
                                // backgroundColor: themeColor.palette.backgroundColor,
                                borderRadius: "10px", mt:3, pl: 1, pr: 1, fontSize: '14px',
                                textTransform: 'none', 
                            }}
                            >
                            {loaderForValidate ? <CircularProgress size={20} color="inherit" /> : 'Validate'}
                        </Button>
                    </Grid>
                :''}

                {For === 'Service' ? <><Grid item lg={4} xs={3} md={3} sm={3} sx={{ flex: 1 }}>
                    <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb: 0.2 }}>
                        Billing Start Date <span style={{ color: 'red' }}>*</span>
                    </H7TablePrimeContent>
                    <DatePicker
                        name='billing start date'
                        // openTo="day"
                        value={billingStartDate}
                        onChange={handleChangedBillingStartDate}
                        minDate={pastDate}
                        // disabled={billingStartDate}
                        style={{minWidth:'100%', background:'#FFFFFF',fontSize:'14px', marginTop:'8px'}}
                    />
                    {errormsgBilling?.value === true ? <small style={{ color: 'red' }}>Please Enter Billing Start Date</small> : ''}
                </Grid>
                <Grid item lg={4} xs={3} md={3} sm={3} sx={{ flex: 1 }}>
                    <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel, pb: 0.2 }}>
                        Billing End Date
                    </H7TablePrimeContent>
                    {/* <DatePickerOpenTo
                        name='billing start date'
                        openTo="day"
                        value={billingEndDate}
                        disabled={displayEndDate}
                        minDate={new Date()}
                        style={{minWidth:'100%',background:'#FFFFFF',fontSize:'14px',marginTop:'8px'}}
                    /> */}
                                    <DatePicker
                                        name='billing start date'
                                        value={billingEndDate}
                                        disabled={displayEndDate}
                                        minDate={new Date()}
                                        // defaultValue={dayjs()}
                                        //  disabled={billingStartDate}
                                        style={{ minWidth: '100%', background: '#FFFFFF', fontSize: '14px', marginTop: '8px' }}
                                    />
                                </Grid>
                               
                            </> : <></>}
                </Grid>
                     
                {(opp_stage?.oppo_details?.cofdetails?.status == 'Rejected' || opp_stage?.oppo_details?.servicedetails?.status == 'Rejected') ?  <Grid item lg={12} md={12} >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt:1}}>
                    <H7TablePrimeContent sx={{ color: themeColor.palette.InputLabel }}>
                        Request Remark<span style={{ color: 'red' }}>*</span>
                    </H7TablePrimeContent>
                </Box>
                <CardInfo sx={{ pr: 1}}>
                    <TextArea onChange={handleRequestRemark} name='reqremark' value={reqremark?.replace(/\s\s+/g, " ")} style={{ fontFamily: 'Montserrat', marginTop: '5px', fontStyle: 'normal', width: '100%', height: "80px", color: '#444444', fontSize: '14px', lineHeight: '17px', borderRadius: '8px', border: '0.5px solid #B0AEFF', background: '#FEFEFE', p: 0.1, paddingLeft: '10px' }} placeholder="Write a note about reject remark."></TextArea>
                </CardInfo>
                <div style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginLeft: 'auto', fontSize: '12px' }}>
                    <span>{reqremark.length}/1000</span>
                </div>
                <DisplayError error={remarkerror} fortagid='reqremark' />
                    </Grid> : ''
                }

                <Grid item lg={12} md={12} sx={{display:'flex', justifyContent:'flex-end'}}>
                    <Grid sx={{padding:'5px', mt:1 }} container>  
                        <Grid item lg={12} md={12} >
                            <H7TablePrimeContent style={{ color: themeColor.palette.InputLabel }}>
                                Upload Document <span style={{ color: 'red' }}>*</span>
                            </H7TablePrimeContent>
                            <Box sx={{width:'auto', display:'flex', justifyContent:'center', alignItems:'center', mt:1, height:'100px', borderRadius:'10px', border:'2px dotted var(--dark-grey, #858585)'}}>
                                {!validate && opp_stage?.oppo_details?.commercials?.billed_by !== 'Mobigic' ? <H7TablePrimeContent style={{ color: themeColor.palette.InputLabel }}>{`Please Validate third party ${For === 'COF' ? 'COF' : 'Service'} id`}</H7TablePrimeContent> : <DocumentUpload disableButton='ServiceID' setRemarkError ={setRemarkError}  Forreject = {opp_stage?.oppo_details?.cofdetails?.status == 'Rejected' || opp_stage?.oppo_details?.servicedetails?.status == 'Rejected' ? 'reject' :'' } For = {For} opportunity_id = {opportunity_id} opp_stage={opp_stage} req_remark={reqremark}/>}
                            </Box>
                        </Grid>
                        {/* <Grid item lg={12} md={12} sx={{mt:4, display:'flex', justifyContent:'flex-end'}}>
                            <CustemBtn id='save' onClick={handleClickUploadPdf} variant='contained' sx={{ borderRadius: "10px", pl: 4, pr: 4, fontSize: '16px'}} text="Save" />
                        </Grid>*/}
                    </Grid>           
                </Grid>
        </> : <>
            {opp_stage?.oppo_stages?.length === 0 ? <H7TablePrimeContent style={{ color: themeColor.palette.InputLabel, display: 'flex', justifyContent: 'center' }}>
                {`No ${For === 'COF' ? 'Cof' : 'Service'} Document Uploaded Yet.`}
            </H7TablePrimeContent> : <></>}
        </>
            }

        </>
    )

}

export default DisplayActions
