import React from 'react'

const WhiteLeadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.57422 4.76953L13.2865 8.48184" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.209 11.915L19.9213 15.6273" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.8796 11.6896C9.45764 12.1115 9.22059 12.6838 9.22059 13.2805C9.22059 13.8773 9.45764 14.4496 9.8796 14.8715C10.3016 15.2935 10.8739 15.5305 11.4706 15.5305C12.0673 15.5305 12.6396 15.2935 13.0616 14.8715L18.8952 9.0379C19.0359 8.89725 19.2266 8.81823 19.4255 8.81823C19.6245 8.81823 19.8152 8.89725 19.9559 9.0379L22.6075 11.6896C22.7482 11.8302 22.8272 12.021 22.8272 12.2199C22.8272 12.4188 22.7482 12.6096 22.6075 12.7502L16.8402 18.5176C13.9167 21.441 9.1504 21.5272 6.20706 18.6236C5.50204 17.9291 4.94144 17.1019 4.55758 16.1897C4.17372 15.2775 3.97419 14.2984 3.97049 13.3087C3.9668 12.3191 4.159 11.3384 4.53604 10.4234C4.91307 9.50839 5.46747 8.67702 6.16729 7.97724L12.0009 2.14361C12.1416 2.00296 12.3323 1.92394 12.5312 1.92394C12.7302 1.92394 12.9209 2.00296 13.0616 2.14361L15.7132 4.79526C15.8539 4.93591 15.9329 5.12668 15.9329 5.32559C15.9329 5.5245 15.8539 5.71527 15.7132 5.85592L9.8796 11.6896Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default WhiteLeadIcon
