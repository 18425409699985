import React ,{useEffect, useState} from 'react'
import Header from './Header'
import { Box, Divider, Grid, Typography, styled } from '@mui/material'
import CafseridDetails from './CafseridDetails'
import CafSerIDFlow from './CafSerIDFlow'
import { Sidebar } from './CAF.Ser.IDcss'
import DisplayActions from './DisplayActions'
import { useDispatch, useSelector } from 'react-redux'
import { getCofDetails } from '../../../Redux/Thunk/createopportunitythunk'
import { DisplayErrorMsgCAF, storeOtherCAFID } from '../../../Redux/slices/createOpportunitySlice'
import { TailSpin } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import { H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent, T1ButtonText } from '../../../Theme/theme';
import themeColor from '../../../Theme/themeColor'
import { InputBox, NotificationIcon } from '../../../components/custom'
import swal from 'sweetalert'
import ReminderIcon from '../../../components/custom/ReminderIcon'
import { getresettime, sendreminder } from '../../../Redux/Thunk/reminders'

const CardBody = styled('div')({
    backgroundColor: '#FFFFFF',
    padding: '20px',
    borderRadius: '10px',
})

const LoginContainer = styled('div')({
    // minHeight: '100vh',
    overflowY: 'auto',
    // marginTop: "0.2px",
    // marginBottom:'0.2px',
    //  marginRight:'20px',
    paddingTop: "10px",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
})

const CAFDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {state} = useLocation()
    const [cofDetails, setCofDetails] = useState({})
    const {cafService} = useSelector((state)=>state.opportunity)
    const [loader, setLoader] = useState(true)
    const [otherCafID, setOtherCafID] = useState('')
    const {errormsgCaf} = useSelector((state)=>state.opportunity)
    const {storeOtherCafID} = useSelector((state)=>state?.opportunity)
    const { user } = useSelector((state) => state.auth)
    const [resettime, setResetTime] = useState('')
    const [disable, setDisable] = useState(false)

    const permissionsDetails = user?.data?.user.role.permissions
    console.log(errormsgCaf,otherCafID.length, "errormsgCaf")
   console.log(storeOtherCafID, "storeOtherCafID")

     useEffect(()=>{
        // setLoader(true)
        if(!state || state === 'undefined') {
            navigate('/dashboard')
            return
          } else {
            dispatch(getCofDetails(state?.cofDetails)).then((res)=>{
                if (res.payload.errormsg) {
                    sweetalert('error', res.payload.errormsg)
                    setLoader(false)
                }else{
                    setCofDetails(res?.payload)
                    dispatch(getresettime({id : res?.payload?.oppo_details?.id, stage : 'COF'})).then((res) => {
                        setResetTime(res.payload.data)
                        setLoader(false)
                    })
                }
            })
        }
        //  dispatch(DisplayErrorMsgCAF({value:false}))   
     },[cafService])

     
   
    const handleReminder = (id, stage) => {
        setDisable(true)
        dispatch(sendreminder({opportunity_id : id, stage:stage})).then((res) => {
            if (res.payload.errormsg) {
               sweetalert('error', res.payload.errormsg)
            } else {
               sweetalert('success', res.payload.data)
               dispatch(getresettime({id : cofDetails?.oppo_details?.id, stage : 'COF'})).then((res) => {
                   setResetTime(res.payload.data)
               }) 
               
            }
        })
       }


     let show_display = cofDetails?.oppo_stages?.filter((ele) => (ele.status == 'Approved' || ele.status == 'Pending'))
  
     const handleChangedOtherCafID =(e)=>{
        setOtherCafID(e.target.value)
        dispatch(storeOtherCAFID(e.target.value))
      }
      
    const sweetalert = (variant, text) => {
        swal({
           icon: variant === 'error' ? 'error' : 'success',
           title: text,
           button: "Ok!",
        });
    };

    return (
        <Box sx={{ padding: '10px' }}>
            {loader ? <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
                <TailSpin
                    height="30"
                    width="30"
                    color="blue"
                    ariaLabel="tail-spin-loading"
                    radius="3"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                /> </Box> : <>
                
            <Header name="Customer Order Form Details" />
            
            <LoginContainer>
                
            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                <Grid item xs={12} md={10} sm={10} lg={5}>
                    <CafseridDetails name = 'cof' cofDetails={cofDetails} opportunitydetails={cofDetails?.oppo_details}/>
                </Grid >
                {/* <Grid item lg={1} xs={12} md={10} sm={10}></Grid> */}
                <Grid item lg={7} xs={12} md={10} sm={10}>
                    <Box sx={{mr:{xs:1,sm:1,xl:2, lg:2}, display:'flex',justifyContent:'end' }}>

                    {
                        (cofDetails?.oppo_details?.cofdetails?.status != 'Pending' || (permissionsDetails.perm_approve_edit)) ? '' :((resettime?.length > 0 ? new Date().getTime() > resettime[0]?.reset_time : true) &&  ((user?.data?.user?.id == cofDetails?.oppo_details?.assigned_to))) ?  <Typography sx={{pl:2, cursor:'pointer'}} onClick={disable ? '' : () => handleReminder(cofDetails?.oppo_details?.id, 'COF')} ><ReminderIcon /></Typography> : ""
                    }
                   
                    </Box>
                  <CardBody sx={{mt:(cofDetails?.oppo_details?.cofdetails?.status != 'Pending' || (permissionsDetails.perm_approve_edit)) ? 3.7 : 1, ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl: 0, lg: 0 } }}>
                    {(show_display?.length === 0 || cofDetails?.oppo_stages?.length === 0) ? (
                      <DisplayActions opp_stage={cofDetails} opportunity_id={cofDetails?.oppo_details?.id} For='COF' />
                    ) : null}
                  
                    <CafSerIDFlow oppo_stages={cofDetails} ids={cofDetails?.all_ids} For='COF' />
                  </CardBody>
                </Grid>

            </Grid>
            </LoginContainer>
            </>}
        </Box>
    )
}

export default CAFDetails
