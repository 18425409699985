import { Grid, IconButton, Tooltip, Typography} from "@mui/material"
import { experimentalStyled as styled } from '@mui/material/styles';
import InputBox from "./InputBox";
import AddPlusIcon from "./AddPlusIcon";

const Search = styled('div')({
    display:'flex',
    padding:'0px 10px',
    borderRadius:'10px',
    justifyItems:'center',
    border:'1px solid #B0AEFF ',
    flexDirection:'row',
    height:'34px',
    backgroundColor:'#ffffff',
  })
  
  
  const TableListSearch =({tooltipTitle, placeholderTitle, onClick, onchange, permissionsDetails, For}) =>{
   
    return( 
        <>
         <Search>
                <InputBox sx={{fontSize:'14px'}} onchange={onchange} placeholder={placeholderTitle} />
                <Typography sx={{pl:4, pt:1}}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#7673f6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M13.7051 13.6978L17.502 17.4946" stroke="#7673f6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </Typography>
            </Search>

          {permissionsDetails ? <>
            { For == 'Oppo' ? '' :
              <Tooltip title={tooltipTitle}>
                  <IconButton id='create' sx={{ml:1}} onClick={onClick}> 
                    <AddPlusIcon/> 
                  </IconButton>
              </Tooltip>
            }
            </> :<></>}
    </>)
  }    

export default TableListSearch 