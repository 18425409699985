import React, {useEffect, useState} from 'react';
import { Typography,Card,CardContent, Grid, Box} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import IndustryTypesList from './IndustryTypesList';
import ServiceProviderList from './ServiceProviderList';
import {CardBody} from './SettingMasterList.style'
import ProductFamilyList from './ProductFamilyList';
// import LeadSourceList from './LeadSourceList';
import ProductList from './ProductList';
import OpportunityList from './OpportunityList';
import OrdertypesList from './OrderTypesList';
import { TailSpin} from 'react-loader-spinner';
import { getIndustryTypesList, getOpportunityList, getServiceProviderTypesList } from '../../Redux/Thunk/createsettinglist';

export default function LeadList() {
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth)
  const permissionsDetails = user?.data?.user?.role?.permissions
  const {update} = useSelector(state => state.settingList)
  const [loader, setLoader] = useState(true) 

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getIndustryTypesList()).then((res)=>{
            console.log(res,"res")
            setLoader(false);
          }),
          dispatch(getServiceProviderTypesList()).then((res)=>{
            console.log(res,"res")
            setLoader(false);
          }),
        ]);
      } catch (error) {
      
      } finally {
        // setLoader(false);
      }
    };

    fetchData();
  }, [dispatch]);

  console.log(loader,"loader")
  return (
    <>
      { loader ? 
        <Box style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999}}>
          <TailSpin
            height="30"
            width="30"
            color="blue"
            ariaLabel="tail-spin-loading"
            radius="3"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /> </Box>:<>
            <Grid sx={{ borderBottom:'1px solid #e4e4e4',mt:1, ml:4, mr:4,display:"flex",justifyContent:'space-between',alignItems:'flex-end'}}>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{fontSize:'28px',fontWeight:600}}>Settings</Typography>
              </Grid>
            </Grid>

            <CardBody sx={{ml:{xs:1, sm:1, xl:3, lg:3},mr:{xs:1, sm:1, xl:3, lg:3}}}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3}} columns={{ xs: 2, sm: 2, md: 8, lg:12 }}>
                    <Grid item xs={2} sm={4} md={4} >
                      <IndustryTypesList permissionsDetails={permissionsDetails} />    
                    </Grid>
                    {/* <Grid item xs={2} sm={4} md={4}>
                        <OpportunityList permissionsDetails={permissionsDetails} setLoader={setLoader}/> 
                    </Grid> */}

                    {/* <Grid item xs={2} sm={4} md={4}>
                        <OrdertypesList permissionsDetails={permissionsDetails} setLoader={setLoader}/>
                    </Grid> */}
                    <Grid item xs={2} sm={4} md={4} >
                      <ServiceProviderList permissionsDetails={permissionsDetails} />    
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} >
                      <ProductFamilyList permissionsDetails={permissionsDetails} />    
                    </Grid> 
                    <Grid item xs={2} sm={4} md={4} >
                      <ProductList permissionsDetails={permissionsDetails} />    
                    </Grid>
                  {/* ))} */}
                </Grid>
              </Box>
            </CardBody>
      </>}
    </>
  );
}