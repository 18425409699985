import { Card, CardContent, styled, Typography } from '@mui/material';
import { Box } from '@mui/material';
import {BirthdayIcon} from '../custom';
import { useDispatch } from 'react-redux';
import { sendwishemailthunk } from '../../Redux/Thunk/dashboardthunk';
import swal from 'sweetalert';
const CardBody = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center'
})

export default function BirthdayWishe({ name, id }) {
    const dispatch = useDispatch()
    const handleSendWish = () => {
    dispatch(sendwishemailthunk({id:id})).then((res) => {
        if(res?.payload.errormsg) {
            return sweetalert('error', res?.payload.errormsg)
            }
            sweetalert('success', res?.payload?.data.message)
    })
    }

    const sweetalert = (variant, text) => {
        swal({
          icon: variant==='error' ? 'error' :'success',
          title: text , 
          button: "Ok!",
        }); 
      };
    return (
        <>
            <Card sx={{
                width: "250px",
                background: "#FEFBFF",
                borderRadius: "12px",
                boxShadow: "0px 4px 20px #efefef"
            }}>
                <Box sx={{p:2}}>
                    <CardBody >
                        <Box sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            height:'3rem',
                            width:'3rem',
                            boxShadow: '0px 4px 20px #e4e4e4',
                            background:'linear-gradient(135.71deg, #FFFFFF 4.26%, #E7B3FF 95.75%)',
                            borderRadius: '10px',
                             }}>
                            <BirthdayIcon height={40} width={40}/>
                        </Box>
                        <Box sx={{ flex: 1.9,  alignSelf: "center",textAlign:"center", fontWeight: "600" }}>
                            <Typography sx={{fontWeight: "600", fontSize:'10px'}}>Birthday Wishes</Typography>
                            <Box sx={{fontSize:'10px'}}>To</Box>
                            <Typography sx={{color: "#FF0000",fontWeight: "600", fontSize:'8px'}}>{name}</Typography>
                        </Box>
                        <Box sx={{ flex:1.2,alignSelf:"flex-end"}}>
                            <Box  sx={{background:"lightgrey",padding:"6px", borderRadius:"5px",boxShadow: "0px 4px 20px #efefef"}}>
                            <Typography sx={{color: "#E64DFF",fontSize:"8px",fontWeight:"600", cursor:'pointer', textAlign:'center'}}>Send Wishes</Typography></Box>
                        </Box>
                    </CardBody>
                </Box>  
            </Card>
        </>
    )
}