import React,{useState, useEffect} from 'react';
import { Box, Button, Card, Grid, Paper, Tooltip, Snackbar, Typography, styled, IconButton} from '@mui/material';
import {H1FormTitle, T6TableText, H3SubHeader, T4MedNormalText, T5Normaltext, H7TablePrimeContent} from '../../Theme/theme'; 
import themeColor from '../../Theme/themeColor'
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckCircle } from '@mui/icons-material';
import CallIcon from './CallIcon';
import EmailIcon from './EmailIcon';
import LeadDetailsContactIcon from '../../pages/LeadDetails/LeadDetailsContactIcon';
import LinkedinIcon from './LinkedinIcon';
import TwitterIcon from './TwitterIcon';

const CardBody = styled('div')({
    backgroundColor:'#FFFFFF',
    padding:'20px 0px 20px 10px',
    borderRadius:'10px',
})

export default function ContactNameDetails ({contactDetails, title}) {
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [displayMsg, setDisplayMsg] = useState('')

    const handleCopyEmail = (email) => {
        navigator.clipboard.writeText(email)
          .then(() => {
            setSnackbarOpen(true)
            setDisplayMsg("Email copied")
          })
          .catch((error) => {
            setSnackbarOpen(false)
          });
    }

    const handleCopyPhoneNumber = (phone) =>{
        navigator.clipboard.writeText(phone)
        .then(() => {
          setSnackbarOpen(true)
          setDisplayMsg("Phone Number copied")
        })
        .catch((error) => {
          setSnackbarOpen(false)
        });
    }

    const handleLinkedInClick = (contact) => {
        if (contact?.linkdin?.length > 0) {
            window.open(contact?.linkdin, '_blank');
        }
    }

    const handleTwitterInClick =(contact) =>{
        if (contact?.twitter?.length > 0) {
            window.open(contact?.twitter, '_blank');
        }
    }

    return(
        <>
            <Grid sx={{pl:2, mt: 2, mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Grid item xs={10}>
                    <H3SubHeader sx={{color: themeColor.palette.Primarytext1}}>
                        Contact
                    </H3SubHeader>
                </Grid>
                        {/* <Grid item xs={2} sx={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end'}} >
                        {permissionsDetails?.perm_contact_edit ? <><Typography onClick={() => handleClickEditContact(contactDetails?.contact)}><EditIcon w={20} h={20} /></Typography></>:<></>}
                        </Grid> */}
                </Grid>

                <CardBody sx={{ mt: 1, ml: { xs: 1, sm: 1, xl: 1, lg: 1 }, mr: { xs: 1, sm: 1, xl: 1, lg: 1 } }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <LeadDetailsContactIcon />
                        </Grid>
                        <Grid item xs={10}>
                            <Tooltip title={`${contactDetails?.contact?.first_name} ${contactDetails?.contact?.last_name}`} placement="top-start">
                                <H3SubHeader sx={{ color: themeColor.palette.primarytext2,
                                    maxWidth: '300px', 
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    ":hover":{cursor:'pointer'},
                                    "&:hover": {
                                        maxWidth: 'none', 
                                    } }}>
                                    {contactDetails?.contact?.first_name} {contactDetails?.contact?.last_name}</H3SubHeader>
                            </Tooltip>
                            <T4MedNormalText sx={{ mt:1, color: themeColor.palette.InputLabel }}>{contactDetails?.contact?.designation}</T4MedNormalText>
                        </Grid>
                    </Grid>

                    <Grid sx={{mt:1, ml:{ xs: 1, sm: 1, xl: 5, lg: 5}, mr: { xs: 1, sm: 1, xl: 5, lg: 5},display: 'flex', justifyContent: 'space-around' }}>
                    {/* call icon */}
                        <Tooltip title={contactDetails?.contact?.phone} placement="top-start">
                            <IconButton id='call_icon' onClick={()=> handleCopyPhoneNumber(contactDetails?.contact?.phone)}>
                                <CallIcon />
                            </IconButton>
                        </Tooltip>

                        {/* email icon */}
                        <Tooltip title={contactDetails?.contact?.email} placement="top-start">
                            <IconButton id='email_icon' onClick={()=> handleCopyEmail(contactDetails?.contact?.email)}>
                                <EmailIcon />
                            </IconButton>
                        </Tooltip>

                        {/* linkdin icon */}
                        <Tooltip title={contactDetails?.contact?.linkdin?.length === 0 ? 'LinkedIn Link Unavailable':'LinkedIn Profile Link'} placement="top-start">
                            <IconButton id='linkedin_icon' onClick={()=>handleLinkedInClick(contactDetails?.contact)}>
                               <LinkedinIcon linkedin={contactDetails?.contact?.linkdin?.length} enabled={contactDetails?.contact?.enabled}/> 
                            </IconButton>
                        </Tooltip>

                        {/* Twitter icon */}
                        <Tooltip title={contactDetails?.contact?.twitter?.length === 0 ? 'Twitter Link Unavailable':'Twitter Profile Link'} placement="top-start">
                            <IconButton id='twitter_icon' onClick={()=>handleTwitterInClick(contactDetails?.contact)}>  
                                <TwitterIcon twitter={contactDetails?.contact?.twitter?.length} enabled={contactDetails?.contact?.enabled} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </CardBody>

        {/* Display Msg*/}
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={2000} 
            onClose={() => setSnackbarOpen(false)}
            message= {displayMsg}
            action={
            <IconButton id='copy_msg' size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
                <CheckCircle />
            </IconButton>
            }
        />

        </>       
    )
}
