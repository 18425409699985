import React,{useState, useEffect} from 'react';
import { Box, Card, Grid, Paper, Typography, styled} from '@mui/material';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import LeadOpenStatusIcon from '../../components/custom/LeadOpenStatusIcon';
import LeadWIPStatusIcon from '../../components/custom/LeadWIPStatusIcon';
import LeadNoStatusIcon from '../../components/custom/LeadNoStatusIcon';
import LeadLostStatusIcon from '../../components/custom/LeadLostStatusIcon';
import LeadConvertedStatusIcon from '../../components/custom/LeadConvertedStatusIcon';
import LeadClosedNoActionStatusIcon from '../../components/custom/LeadClosedNoActionStatusIcon';

const CardBody = styled('div')({
   borderRadius:'10px',
})

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    // backgroundImage:
    //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme, ownerState }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#630000',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#630000',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, leadStatus, className } = props;

const icons = {
  1: <LeadOpenStatusIcon />,
  2: <LeadWIPStatusIcon />,
  3: leadStatus?.leaddetails?.status === 'Open' || leadStatus?.leaddetails?.status === 'WIP' ? <LeadNoStatusIcon /> : leadStatus?.leaddetails?.status === 'Lost' ? <LeadLostStatusIcon /> : leadStatus?.leaddetails?.status === 'Converted' ? <LeadConvertedStatusIcon /> :<LeadClosedNoActionStatusIcon/> ,
};

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}


export default function UpdateStatus({leadStatus}) {

  const steps = [
    'Open',
    'WIP',
  ];
  
  const dynamicValues = []
  if(leadStatus?.leaddetails?.status === 'Lost'){
    dynamicValues.push('Lost')
  }else if(leadStatus?.leaddetails?.status === 'Converted'){
    dynamicValues.push('Converted')
  }else if(leadStatus?.leaddetails?.status === 'Closed-No-Action'){
    dynamicValues.push('Closed-No-Action')
  }else{
    dynamicValues.push('')
  }

 const combinedArray = [...steps, ...dynamicValues];
//  console.log(combinedArray,"combinedArray")
//   console.log(leadStatus?.leaddetails?.status,"console.log")
  const getStatusIndex = (status) => {
    switch (status) {
      case 'Open':
        return 0;
      case 'WIP':
        return 1;
      case (leadStatus?.leaddetails?.status === 'Lost' || leadStatus?.leaddetails?.status === 'Converted' || leadStatus?.leaddetails?.status === 'Closed-No-Action'):
        return 2;
      default:
        return 0;
    }
  };

  let activeStep = 0;
  if (leadStatus?.leaddetails?.status === 'Open') {
    activeStep = 1;
  } else if (leadStatus?.leaddetails?.status === 'WIP') {
    activeStep = 2;
  } else if (leadStatus?.leaddetails?.status === 'Lost' || leadStatus?.leaddetails?.status === 'Converted' || leadStatus?.leaddetails?.status === 'Closed-No-Action') {
    activeStep = 3;
  }

  return (
    <Grid sx={{ mb: 2, display: 'flex' }}>
      <CardBody sx={{ width: '100%', mt: 0.5 }}>
        <Stack sx={{ width: '100%'}} spacing={4}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
            {combinedArray.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(stepProps) => (
                  <ColorlibStepIcon {...stepProps} leadStatus={leadStatus} />)}
                  completed={index < getStatusIndex(leadStatus?.leaddetails?.status)}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          </Stack>
        </CardBody>
      </Grid>
    )
}
