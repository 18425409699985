import { AppBar, Avatar, Badge,Box, Button, styled, Toolbar, Typography,InputBase, Menu,MenuItem, Alert } from '@mui/material'
import React, { useEffect,useState } from 'react'
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import { AddIcCallRounded } from '@mui/icons-material';
import Logo from './Logo';
import { useNavigate } from 'react-router-dom';

const StyledToolbar = styled(Toolbar)({
      display:'flex',
      justifyContent:'center',
      // color:'#444444',
      letterSpacing:'0.04rem'
})


const CompanyName = ({sx, style, label, companyID}) => {
      const navigate = useNavigate()
      const wordsToRemove = ["TRUST", "NGO", "OTHERS", "PROPRIETOR", "FOREIGN ENTITY"];
//    const jointName = new RegExp(wordsToRemove.join("|"), "g");
//    const remainingNames = label?.replace(jointName, "")?.trim()

//    const wordsToRemove = ["PRIVATE LIMITED", "LIMITED", "LLP"];

//    const remainingNames = label.split(' ')
//   .map(word => (wordsToRemove.includes(word) ? `(${word})` : word))
//   .join(' ');

      const pattern = new RegExp(wordsToRemove.join("|"), "gi");

// Replace the words from the company name with the same words enclosed in parentheses
      const remainingNames = label.replace(pattern, (match) => `(${match})`).trim();
// const remainingNames = label?.split(' ')?.map(word => (wordsToRemove.includes(word) ? `(${word})` : word))?.join(' ');

const handleClickCompanyProfile =()=>{
      if(companyID !==""){
            navigate('/dashboard/profile', { state: companyID})
      }else{
            console.log(companyID)
      }    
}

  return (
        <>
        <AppBar sx={{...style, backgroundColor: '#3107AA', zIndex: 0, position:'sticky'}} >
         <StyledToolbar onClick={handleClickCompanyProfile}>
            <Typography variant='h5' sx={{fontWeight:600, color: '#FFFFFF', letterSpacing: "0.04em",...sx }}>
                  {remainingNames}
            </Typography>
         </StyledToolbar> 
        </AppBar>
        </>
  )
}

export default CompanyName


