import React from 'react'

const TableIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clip-path="url(#clip0_3949_16106)">
      <path d="M1.30469 3.25H22.6941V19.7763C22.6941 20.0341 22.6002 20.2814 22.4331 20.4637C22.266 20.646 22.0393 20.7484 21.8029 20.7484H2.19591C1.95955 20.7484 1.73286 20.646 1.56572 20.4637C1.39858 20.2814 1.30469 20.0341 1.30469 19.7763V3.25Z" stroke="#3107AA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.30469 9.08398H22.6941" stroke="#3107AA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M1.30469 14.9141H22.6941" stroke="#3107AA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.54297 9.08398V20.7496" stroke="#3107AA" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_3949_16106">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
  )
}

export default TableIcon
