import React from 'react'
import {styled, Box, Button, Typography, Modal, Card, CardContent,Radio } from '@mui/material';
import CustemBtn from './CustemBtn';
// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: "559px",
//     height: "536px",
//     bgcolor: 'background.paper',
//     boxShadow: 24,
//     p: 4,
//     background: "#FFFFFF",
//     borderRadius: "20px",
// };

const CardHeaders = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #BBBBBB'
})

const CardBody = styled('div')({
    display: 'flex',
    justifyContent: 'start',
    alignItems:"center",
    gap:10,
    marginTop:"10px"
})

export default function ExecutiveModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
               sx={{m:10}}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box >
                    {/* <Box sx={{
                        display: "flex",
                        padding: "10px 40px 40px",
                        gap: "10px",
                        width: "400px",
                        height: "440px",
                        background: "#F5F4FF",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                        boxShadow: "none"
                    }}> */}
                        <Box sx={{
                            padding: "20px 40px 40px",
                            justifyContent:"center",
                            alignItems:"center",
                            width: "383px",
                            // height: "390px",
                            background: "#F8FCFF",
                            boxShadow: "0px 4px 20px #8f8f8f",
                            borderRadius: "8px",
                        }}>
                            <Box>
                                <CardContent>
                                    <CardHeaders>
                                        <Typography sx={{ color: '#555555', fontSize: '20px', fontWeight: "600" }}>
                                        Select Executive
                                        </Typography>
                                    </CardHeaders>
                                    <CardBody>
                                        <Radio/>
                                        <Typography >Executive</Typography>
                                        <Typography>Present</Typography>
                                        <Typography sx={{color:"#FE8731",fontSize:'12px'}}>Opportunity</Typography>
                                        <Typography sx={{color:"#FE8731"}}>15</Typography>
                                    </CardBody>
                                    <CardBody>
                                        <Radio/>
                                        <Typography>Executive</Typography>
                                        <Typography>Present</Typography>
                                        <Typography sx={{color:"#FE8731",fontSize:'12px'}}>Opportunity</Typography>
                                        <Typography sx={{color:"#FE8731"}}>15</Typography>
                                    </CardBody>
                                    <CardBody>
                                        <Radio/>
                                        <Typography>Executive</Typography>
                                        <Typography>Present</Typography>
                                        <Typography sx={{color:"#FE8731",fontSize:'12px'}}>Opportunity</Typography>
                                        <Typography sx={{color:"#FE8731"}}>15</Typography>
                                    </CardBody>
                                    <CardBody>
                                        <Radio/>
                                        <Typography>Executive</Typography>
                                        <Typography>Present</Typography>
                                        <Typography sx={{color:"#FE8731",fontSize:'12px'}}>Opportunity</Typography>
                                        <Typography sx={{color:"#FE8731"}}>15</Typography>
                                    </CardBody>
                                    <CardBody>
                                        <Radio/>
                                        <Typography>Executive</Typography>
                                        <Typography>Present</Typography>
                                        <Typography sx={{color:"#FE8731",fontSize:'12px'}}>Opportunity</Typography>
                                        <Typography sx={{color:"#FE8731"}}>15</Typography>
                                    </CardBody>
                                    <CardBody style={{justifyContent:"end",marginTop:"20px"}}>
                                    <CustemBtn style={{background:"#C1C1C1",color:"#FFFFFF",borderRadius:"20px",fontSize:"16px"}}  text="Assign"/>
                                    </CardBody>
                                    </CardContent>
                            </Box>
                        </Box>
                    </Box>

                {/* </Box> */}
            </Modal>
        </div>
    );
}