import { createSlice } from '@reduxjs/toolkit';
import {getProductTypesList, getOrderTypesList, getServiceProviderTypesList, getProductFamilyTypesList, getOpportunityList, createOrderTypes, createOpportunityTypes, createIndustryTypesList, getIndustryTypesList, createServiceProviderTypes, createProductFamilyTypes, createProductTypes, CreateEditFollowup} from '../Thunk/createsettinglist';

const initialState = {
   industryTypes: null,
   displayIndustryList:[],
   update: false,
   service: false,
   serviceProviderTypes: null,
   getproductFamilyList:[],
   productFamilyTypes: null,
   productFamily:false,
   productTypes: null,
   product:false,
   opportunityTypes:null,
   opportunity:false,
   order:false,
   getOpportunityTypesList:[],
   getServiceProviderTypes:{},
   productTypesList:[],
   getOrderTypes:[],
   createEditFollowup:null,
   updateCreateEditFollowup:false
}

export const createSettingListSlice = createSlice({
    name:'createSettingList',
    initialState,
    reducers:{
       //Save the state 
         saveServiceProvider(state,action){
          state.getServiceProviderTypes = action.payload
         },

         saveProductFamily(state,action){
          console.log(action.payload)
          state.getproductFamilyList = action.payload
         },

         saveProductTypesList(state,action){
          state.productTypesList = action.payload
         },
          
         saveOrderType(state, action){
          state.getOrderTypes = action.payload
         },
        
         saveOpportunityType(state, action){
          state.getOpportunityTypesList = action.payload 
         }
    },

    extraReducers:(builder) =>{

    //  Create, edit and delete industry types 
      builder.addCase(createIndustryTypesList.fulfilled, (state,action)=>{
        state.industryTypes = action.payload
        state.update =! state.update
      })
   
      builder.addCase(getIndustryTypesList.fulfilled, (state,action)=>{
        state.displayIndustryList = action.payload
      })
      
      // Create, edit and delete service provider types 
      builder.addCase(createServiceProviderTypes.fulfilled, (state,action)=>{
        state.serviceProviderTypes = action.payload
        state.service =! state.service
      })
      
      builder.addCase(getServiceProviderTypesList.fulfilled, (state,action)=>{
        state.getServiceProviderTypes = action.payload
      })

      //get Product family list
      builder.addCase(getProductFamilyTypesList.fulfilled, (state, action)=>{
        state.getproductFamilyList = action.payload
      })

      // Create, edit and delete product family types
      builder.addCase(createProductFamilyTypes.fulfilled, (state, action)=>{
        state.productFamilyTypes = action.payload
        state.productFamily =! state.productFamily
      })

      builder.addCase(createProductTypes.fulfilled, (state, action)=>{
        state.productTypes = action.payload
        state.product =! state.product
      })
     
      builder.addCase(getProductTypesList.fulfilled, (state, action)=>{
        state.productTypesList = action.payload
      }) 

      //Create, edit and delete opportunity types
      builder.addCase(createOpportunityTypes.fulfilled, (state, action)=>{
        state.opportunityTypes = action.payload
        state.opportunity =! state.opportunity
      })
     
      //get Opportunity Types List
      builder.addCase(getOpportunityList.fulfilled, (state, action)=>{
        state.getOpportunityTypesList = action.payload
      })

      //get order types list 
      builder.addCase(getOrderTypesList.fulfilled, (state, action)=>{
        state.getOrderTypes = action.payload
      })

      //Create, edit and delete order types
      builder.addCase(createOrderTypes.fulfilled, (state, action)=>{
        // state.opportunityTypes = action.payload
        state.order =! state.order
      })
      
      builder.addCase(CreateEditFollowup.fulfilled, (state, action)=>{
        state.createEditFollowup= action.payload
        state.updateCreateEditFollowup =! state.updateCreateEditFollowup
      })
   },
})


export const { saveServiceProvider, saveProductFamily, saveProductTypesList, saveOrderType, saveOpportunityType} = createSettingListSlice.actions
export default createSettingListSlice.reducer