import React from 'react'

const ThermometerIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
    <path d="M6.97674 11.3023V2.32558C6.97674 1.02326 5.95349 0 4.65116 0C3.34884 0 2.32558 1.02326 2.32558 2.32558V11.3023C0.930233 12.093 0 13.6279 0 15.3488C0 17.907 2.09302 20 4.65116 20C7.2093 20 9.30233 17.907 9.30233 15.3488C9.30233 13.6279 8.37209 12.1395 6.97674 11.3023Z" fill="#FF6B00" fill-opacity="0.3"/>
    <path d="M4.6523 18.6038C2.83834 18.6038 1.39648 17.162 1.39648 15.348C1.39648 14.1852 2.00114 13.1155 3.02439 12.5108L3.72207 12.0922V2.32476C3.72207 1.81314 4.14067 1.39453 4.6523 1.39453C5.16393 1.39453 5.58253 1.81314 5.58253 2.32476V12.1387L6.28021 12.5573C7.30346 13.162 7.90811 14.2317 7.90811 15.3945C7.90811 17.162 6.46625 18.6038 4.6523 18.6038Z" fill="#FF6B00"/>
    <path d="M6.32644 12.6504C6.65203 13.162 6.88458 13.7667 6.88458 14.4178C6.88458 16.2318 5.44272 17.6736 3.62877 17.6736C2.97761 17.6736 2.37296 17.4876 1.86133 17.1155C2.46598 17.9992 3.44272 18.6039 4.559 18.6039C6.37296 18.6039 7.81482 17.162 7.81482 15.3481C7.81482 14.2318 7.21016 13.2085 6.32644 12.6504Z" fill="#FF6B00"/>
  </svg>
  )
}

export default ThermometerIcon
