import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function ProgressBar(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1}}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{fontSize:'18px',textAlign:'center',fontWeight:600}} color="#C7CB0B">{`${Math.round(
          props.value/20,
        )}`}</Typography>
      </Box>
    </Box>
  );
}

ProgressBar.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({time, For}) {
  const date = new Date().getTime()

  const days = new Date(Number(time)) - date

  const date_diff = Math.floor(days / 86400000)
  const [progress, setProgress] = React.useState(date_diff)
  
  
 
  
  React.useEffect(() => {
      date > time ? setProgress(0): setProgress(date_diff)
      
      //setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
  }, [time]);

  return (
    <Box sx={{ width: '100%'}}>
      <ProgressBar sx={{ height:`${For=='dash' ?'20px' : '24px'}`,borderRadius:'28px',backgroundColor:'#ffffff',border:`${date > time ?'2px solid red' :'1px solid #C7CB0B'}` }} value={progress*20} />
    </Box>
  );
}