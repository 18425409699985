import { createSlice } from '@reduxjs/toolkit'
import { createTaskForLead, followupAssigned, getTaskList } from '../Thunk/createTaskthunk'

const initialState = {
   create_task: null,
   gettasklist:[],
   followupassign:'',
   createTask:false
}

export const createTaskSlice = createSlice({
   name: 'task',
   initialState,

   extraReducers: (builder) => {
      builder.addCase(createTaskForLead.fulfilled, (state, action) => {
         state.create_task = action.payload
         state.createTask = ! state.createTask
      })

      builder.addCase(getTaskList.fulfilled, (state, action) => {
         state.gettasklist = action.payload
      })

      builder.addCase(followupAssigned.fulfilled, (state, action) => {
         state.followupassign = action.payload
      })
   }
})


export default createTaskSlice.reducer
